import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Message,
} from '@arco-design/web-react';
import cs from 'classnames';
import OtpInput from 'react-otp-input';
import styled, { createGlobalStyle } from 'styled-components';
import { IconLoading } from '@arco-design/web-react/icon';
import { FORGOT_PASSWORD_URL, SETUP } from 'constants/routes';
import {
  LOGIN_PAGE_INVALID_CREDS_ERROR,
  LOGIN_PAGE_INVALID_CREDS_FORGOT_PASSWORD_LINK,
  createMessage,
} from '@appsmith/constants/messages';
import FormMessage from 'components/ads/formFields/FormMessage';
import api from './api';
import { isEmail, isPhone } from 'utils/formhelpers';
import { SpacedSubmitForm, FormActions } from 'pages/UserAuth/StyledComponents';
import {
  LOGIN_SUBMIT_PATH,
  LOGIN_CODE_SUBMIT_PATH,
} from '@appsmith/constants/ApiConstants';
import { getIsSafeRedirectURL } from 'utils/helpers';
import { getCurrentUser } from 'selectors/usersSelectors';
import Icon from 'components/icon';
import { debounce } from 'lodash';
import MobileLogin from './MobileLogin';
import SkeletonImg from 'components/designSystems/appsmith/SkeletonImg';

const LoginPageWrap = styled.div`
  min-height: 420px;
  input::-webkit-input-placeholder {
    font-size: 12px;
  }
  @keyframes shaking {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .checkBox-animate {
    animation: shaking 300ms ease-in-out;
  }
  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }
  .arco-input-inner-wrapper {
    padding-left: 25px;
    padding-right: 16px;
  }
  .arco-input-group-wrapper.arco-input-custom-height
    .arco-input-inner-wrapper
    .arco-input-group-suffix
    > svg {
    font-size: 18px;
    color: #999999;
  }
  input::-webkit-input-placeholder {
    font-size: 14px;
  }
  .fs-link {
    color: #1375ff !important;
    span {
      color: #1375ff;
      font-weight: normal;
    }
  }
  .arco-divider-text {
    color: #cccccc;
  }
  .arco-divider-horizontal.arco-divider-with-text {
    margin-bottom: 24px !important;
  }
  .code {
    .arco-input {
      padding-left: 24px;
    }
  }
  .line {
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #999;
      position: absolute;
      left: 0;
      bottom: -3px;
    }
  }
  /* .arco-checkbox-mask {
    background: #1375ff !important;
  }
  .arco-checkbox-mask-wrapper {
    background: transparent !important;
  } */
`;

const SubmitButton = styled.button`
  height: 48px;
  align-self: center;
  /* border-radius: 4px; */
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  background: #1375ff;

  &:hover {
    background: #4080ff;
  }

  &:active {
    background: #4080ff;
  }
  &.bg-disable,
  &:disabled {
    background: var(--disable) !important;
  }
  /* .arco-spin-icon {
    display: flex;
    align-items: center;
    height: 48px;
    margin-left: 4px;
    color: #fff;
  } */
`;

const CheckboxWrap = styled.div`
  .arco-checkbox-mask-icon {
    width: 12px;
    height: 10px;
  }
  .arco-checkbox-mask-wrapper .arco-checkbox-mask {
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
  .arco-icon-hover:hover::before {
    background-color: transparent !important;
  }
  .arco-checkbox {
    padding-left: 0;
  }
`;

const BackButton = styled.button`
  height: 40px;
  width: 100px;
  align-self: center;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  background: var(--primary-linear-gradient);
`;

const HtmlStyle = createGlobalStyle`
  @media (max-width: 640px){
    html{
      font-size: 50px;
    }
  }
  `;

type LoginType = 'phone' | 'account' | 'ding' | 'wechat';
type PhoneType = 'telephone' | 'graphCode' | 'verification' | 'password';
type AccountType = 'account' | 'password';
const OtherLoginData = ['phone', 'account', 'wechat'];
const initWidth = screen.width;
export function Login() {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
  });
  enum RESPONSECODE {
    'HAS_ACCOUNT_AND_PASSWORD' = '1',
    'HAS_ACCOUNT_BUT_NO_PASSWORD' = '2',
    'NO_ACCOUNT_AND_PASSWORD' = '3',
  }

  enum OtherLogin {
    'phone' = 'phone_color',
    'account' = 'account_color',
    'ding' = 'register_ding',
    'wechat' = 'register_wechat',
  }
  const [pageSize, setSize] = useState(initWidth);

  const [loginType, setLoginType] = useState<LoginType>('account');
  const [phoneActive, setPhoneActive] = useState<PhoneType>('telephone');
  const [accountActive, setAccountActive] = useState<AccountType>('account');
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const currentUser = useSelector(getCurrentUser);
  const [codeTime, setCodeTime] = useState(60);
  const [showCode, setShowCode] = useState(false);
  const [telNumber, setTelNumber] = useState('');
  const [validImg, setValidImg] = useState('');
  const [codekey, setCodekey] = useState('');
  const [smsCodekey, setSmsCodekey] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [code, setCode] = useState('');
  const [isRsgister, setRsgister] = useState(false);
  const [isRsgisterPage, setIsRsgisterPage] = useState(false);
  const [isShowShake, setShowShake] = useState(false);
  const [spin, setSpin] = useState(false);
  const [radio, setRadio] = useState(false);
  const [loginUrl, setLoginUrl] = useState('/api/v1/' + LOGIN_SUBMIT_PATH);
  const [loginCodeUrl, setLoginCodeUrl] = useState(
    '/api/v1/' + LOGIN_CODE_SUBMIT_PATH
  );

  useEffect(() => {
    const meta = document.createElement('meta');
    const head = document.querySelector('#fsHead');
    meta.name = 'viewport';
    meta.content =
      'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no';
    head.append(meta);
    const listener = debounce(() => {
      setSize(document.body.offsetWidth);
    });
    window.addEventListener('resize', listener);
    return () => {
      head.removeChild(meta);
      window.removeEventListener('resize', listener);
    };
  }, []);

  const [passwordText, setPasswordText] = useState('请创建账户密码');
  useEffect(() => {
    if (queryParams.get('error')) {
      setErrorMessage(
        queryParams.get('message') || queryParams.get('error') || ''
      );
      setShowError(true);
    }
    if (queryParams.get('loginType')) {
      setLoginType(queryParams.get('loginType'));
    }

    if (queryParams.get('code') == '4052') {
      setRsgister(true);
    }
    if (queryParams.get('isRsgisterPage')) {
      handleRegister();
    }
    const redirectUrl = queryParams.get('redirectUrl');
    if (redirectUrl != null && getIsSafeRedirectURL(redirectUrl)) {
      const encodedRedirectUrl = encodeURIComponent(redirectUrl);
      setLoginUrl(loginUrl + `?redirectUrl=${encodedRedirectUrl}`);
    }
  }, []);

  function handleEmailLogin() {
    const form = document.getElementById('login-form');
    setShowShake(false);
    form.submit();
    setSubmitting(true);
  }

  // function handleEmailLogin() {
  //   const form = document.getElementById('login-form');
  //   if (!radio) {
  //     setShowShake(true);
  //     const timer = setTimeout(() => {
  //       setShowShake(false);
  //       clearTimeout(timer);
  //     }, 300);
  //   } else {
  //     setShowShake(false);
  //     // if (accountActive === 'account') {
  //     //   setAccountActive('password');
  //     // } else {
  //     setSubmitting(true);
  //     form.submit();
  //     setSubmitting(false);
  //     // }
  //   }
  // }
  function handlePhoneLogin(values) {
    if (!radio && isRsgisterPage && values) {
      setShowShake(true);
      const timer = setTimeout(() => {
        setShowShake(false);
        clearTimeout(timer);
      }, 300);
      return;
    }
    setTelNumber(values.phone);
    if (phoneActive === 'telephone') {
      setPhoneActive('graphCode');
      setSubmitting(true);
      api.getVerity().then((res) => {
        setValidImg(res.data.codePic);
        setCodekey(res.data.codekey);
      });
    } else {
      if (values) {
        api
          .checkVerity({ code: code, codekey: codekey })
          .then((res) => {
            if (res.data) {
              setSmsCodekey(res.data);
              handSendCode(res.data, values.phone);
            } else {
              api.getVerity().then((res) => {
                setValidImg(res.data.codePic);
                setCodekey(res.data.codekey);
                setCode('');
              });
              Message.error(res.responseMeta.error.message);
            }
          })
          .catch((err) => {
            Message.error(err.message);
          });
      }
    }
  }

  function handSendCode(dataCode, tel) {
    setSpin(true);
    setSmsCode('');
    api
      .getCode({
        phone: tel || telNumber,
        smsCodekey: dataCode ? dataCode : smsCodekey,
      })
      .then((res) => {
        if (res.data) {
          setSpin(false);
          setPhoneActive('verification');
          let time = 60;
          setShowCode(true);
          const timer = setInterval(() => {
            time--;
            setCodeTime(time);
            if (time <= 0) {
              setShowCode(false);
              clearInterval(timer);
              setCodeTime(60);
            }
          }, 1000);
        } else {
          Message.error(res.responseMeta.error.message);
          setSpin(false);
        }
      });
  }

  function handleCodeLogin(otp) {
    const form = document.getElementById('phone-form');
    api
      .checkCode({ code: otp, phone: form.phone.value })
      .then((res) => {
        if (res.data === RESPONSECODE.HAS_ACCOUNT_AND_PASSWORD) {
          //login
          setSubmitting(true);
          form.submit();
          setSubmitting(false);
        } else if (
          res.data === RESPONSECODE.HAS_ACCOUNT_BUT_NO_PASSWORD ||
          res.data === RESPONSECODE.NO_ACCOUNT_AND_PASSWORD
        ) {
          // fill password
          setPhoneActive('password');
          res.data === RESPONSECODE.NO_ACCOUNT_AND_PASSWORD
            ? setPasswordText('此手机号未注册，请创建账户密码')
            : setPasswordText('请创建账户密码');
        } else {
          Message.error(res.responseMeta.error.message);
        }
      })
      .catch((err) => {
        Message.error(err.message);
      });
  }

  function handlePasswordSet() {
    //set password and login
    const form = document.getElementById('password-form');
    setSubmitting(true);
    form.submit();
    setSubmitting(false);
  }

  function handleChange(otp) {
    setSmsCode(otp);
    if (otp.length === 6) {
      handleCodeLogin(otp);
    }
  }

  if (currentUser?.emptyInstance) {
    return <Redirect to={SETUP} />;
  }

  function handleRegister() {
    setLoginType('phone');
    setPhoneActive('graphCode');
    setRsgister(true);
    setIsRsgisterPage(true);
    setSubmitting(true);
    api.getVerity().then((res) => {
      setValidImg(res.data.codePic);
      setCodekey(res.data.codekey);
    });
  }

  function renderLoginForm(): JSX.Element {
    const handSendCodeDelay = debounce(() => handSendCode('', ''), 500);
    const nextFocus = () => {
      const nextInput = document.getElementById('nextInput');
      nextInput.focus();
    };

    if (loginType === 'account') {
      return (
        <SpacedSubmitForm
          action={loginUrl}
          id="login-form"
          method="post"
          onSubmit={handleSubmit(handleEmailLogin)}
        >
          <div className="text-[24px] text-[#3D3D3D] mb-[24px] font-bold leading-[32px]">
            登录
          </div>
          <div className="text-[#999999] text-[16px] mb-[16px]">
            欢迎使用紫微医院软件定制平台
          </div>
          <div className="mb-[24px]">
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <Input
                  height={48}
                  onPressEnter={(e) => {
                    nextFocus();
                  }}
                  placeholder="用户名"
                  prefix={
                    <Icon
                      className="text-[#999] !w-[18px] !h-[20px]"
                      name={
                        accountActive === 'account'
                          ? 'login_user_icon'
                          : 'login_phone_unactive'
                      }
                      size={16}
                    />
                  }
                  suffix={
                    <span
                      className={cs('text-12 text-orange cursor-pointer', {
                        hidden: accountActive === 'account',
                      })}
                      onClick={() => {
                        if (accountActive === 'password') {
                          setAccountActive('account');
                          setSubmitting(false);
                        }
                      }}
                    >
                      更换账号
                    </span>
                  }
                  {...field}
                  readOnly={accountActive === 'password'}
                />
              )}
              rules={{
                required: '请输入用户名',
                validate: {
                  username: (v) =>
                    isPhone(v) || isEmail(v) || '请输入正确的手机号或邮箱',
                },
              }}
            />
            {errors.username && (
              <p className="text-red absolute text-12">
                {errors.username.message}
              </p>
            )}
          </div>
          <div className="h-[76.5px]">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input.Password
                  height={48}
                  id="nextInput"
                  placeholder="密码"
                  prefix={
                    <Icon
                      className="text-[#999] !w-[18px] !h-[20px]"
                      name="iconLock"
                      size={16}
                    />
                  }
                  type="password"
                  {...field}
                />
              )}
              rules={{
                required: '请输入密码',
                validate: {
                  password: (v) =>
                    v.length > 6 || v.length == 6 || '密码长度不能少于6',
                },
              }}
            />
            {errors.password && (
              <p className="text-red absolute text-12">
                {errors.password.message}
              </p>
            )}
            <div className="mt-[8px] text-right">
              <Link
                className="text-content !text-[14px]"
                to="/user/resetPassword"
              >
                忘记密码
              </Link>
            </div>
          </div>

          {/* <div className={cs('h-[24px]')}>
            <div className={cs({ hidden: accountActive === 'password' })}>
              <Controller
                control={control}
                name="radio"
                render={({ field }) => (
                  <CheckboxWrap
                    className={cs({ 'checkBox-animate': isShowShake })}
                  >
                    <Checkbox
                      className="text-12"
                      {...field}
                      onChange={(val, event) => {
                        setRadio(event.target.checked);
                      }}
                      value={radio}
                    >
                      我已阅读
                      <span
                        className="text-[#FF5800] mx-1 font-[700] cursor-pointer text-12"
                        onClick={() => {
                          const features =
                            'height=800, width=800, top=300, left=500, toolbar=no, menubar=no,scrollbars=yes,resizable=no, location=yes, status=no,fullscreen=no';
                          window.open('/support', 'protocol', features);
                        }}
                      >
                        用户隐私协议
                      </span>
                      并明确违规行为所带来的法律风险
                    </Checkbox>
                  </CheckboxWrap>
                )}
                rules={{
                  required: false,
                }}
              />
            </div>
          </div> */}

          <FormActions>
            {submitting}
            <SubmitButton
              className={cs(
                'font-bold text-16 !mt-[12px] flex items-center justify-center',
                {
                  'bg-disable': submitting,
                }
              )}
              disabled={submitting}
              onClick={handleSubmit(handleEmailLogin)}
              type="submit"
            >
              登录
            </SubmitButton>
          </FormActions>
        </SpacedSubmitForm>
      );
    }

    if (loginType === 'phone') {
      if (phoneActive === 'telephone' || phoneActive === 'graphCode') {
        // console.log(isRsgister, 'isRsgister');
        return (
          <SpacedSubmitForm
            action={loginUrl}
            id="phone-form"
            method="POST"
            onSubmit={handleSubmit(handlePhoneLogin)}
          >
            <div className="text-24  text-[#3D3D3D] mb-[24px] font-bold leading-[32px]text-left">
              {isRsgister
                ? isRsgisterPage
                  ? '注册'
                  : '您的手机未注册，请创建账号'
                : '手机号登录'}
            </div>
            <div className="text-[#999999] text-[16px] mb-[16px]">
              欢迎使用紫微医院软件定制平台
            </div>
            <div className="mb-[24px]">
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <Input
                    height={48}
                    placeholder="请输入手机号"
                    prefix={
                      <Icon
                        className="text-[#999] !w-[18px] !h-[20px]"
                        name={
                          phoneActive === 'telephone'
                            ? 'login_phone_icon'
                            : 'login_phone_unactive'
                        }
                        size={16}
                      />
                    }
                    // suffix={
                    //   isRsgisterPage ? undefined : (
                    //     <span
                    //       className={cs('text-12 text-orange cursor-pointer', {
                    //         hidden: phoneActive === 'telephone',
                    //       })}
                    //       onClick={() => {
                    //         if (phoneActive === 'graphCode') {
                    //           setPhoneActive('telephone');
                    //           setSubmitting(false);
                    //         }
                    //       }}
                    //     >
                    //       更换账号
                    //     </span>
                    //   )
                    // }
                    {...field}
                    readOnly={!phoneActive && !isRsgisterPage}
                  />
                )}
                rules={{
                  required: '请输入手机号',
                  validate: {
                    phone: (v) => isPhone(v) || '手机号码错误',
                  },
                }}
              />
              {errors.phone && (
                <p className="text-red absolute text-12">
                  {errors.phone.message}
                </p>
              )}
            </div>
            <div className="h-[40px]">
              <div
                className={cs('flex justify-between items-center', {
                  hidden: phoneActive !== 'graphCode',
                })}
              >
                <div className="flex-auto mr-1 code">
                  <Controller
                    control={control}
                    name="code"
                    render={({ field }) => (
                      <Input
                        height={48}
                        placeholder="请输入右侧字符"
                        {...field}
                        onChange={(val, event) => {
                          const value = event.target.value;
                          setCode(value);
                          if (value.length >= 4) {
                            setSubmitting(false);
                          } else {
                            setSubmitting(true);
                          }
                        }}
                        value={code}
                      />
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                  {errors.code && (
                    <p className="text-red absolute text-12">
                      {errors.code.message}
                    </p>
                  )}
                </div>
                <div
                  className="flex-none rounded w-[210px] h-[48px] flex items-center justify-center"
                  onClick={() => {
                    api.getVerity().then((res) => {
                      setValidImg(res.data.codePic);
                      setCodekey(res.data.codekey);
                    });
                  }}
                >
                  <SkeletonImg
                    imgProps={{
                      className: 'w-full h-full relative',
                      src: validImg,
                      preview: false,
                    }}
                    skeletonProps={{
                      text: {
                        rows: 1,
                        width: ['100%'],
                      },
                      className: 'absolute inset-0 z-3 p-[12px] bg-white',
                      animation: true,
                    }}
                  />
                </div>
              </div>
            </div>

            <FormActions>
              <SubmitButton
                className={cs('font-bold text-16 mt-[50px]', {
                  'bg-disable': submitting,
                })}
                disabled={submitting}
                onSubmit={handleSubmit(handlePhoneLogin)}
                type="submit"
              >
                下一步
              </SubmitButton>
            </FormActions>
            <div
              className={cs('my-[17px] h-[24px]', {
                hidden: !isRsgisterPage,
              })}
            >
              <div>
                {isRsgisterPage ? (
                  <Controller
                    control={control}
                    name="radio"
                    render={({ field }) => (
                      <CheckboxWrap
                        className={cs({ 'checkBox-animate': isShowShake })}
                      >
                        <Checkbox
                          className="text-14"
                          style={{}}
                          {...field}
                          onChange={(val, event) => {
                            setRadio(event.target.checked);
                          }}
                          value={radio}
                        >
                          <span className="text-[#999]">我已阅读</span>
                          <span className="text-[#999] mx-1 line">
                            服务协议
                          </span>
                          <span className="text-[#999]">和</span>
                          <span
                            className="text-[#999] mx-1 cursor-pointer text-14 line"
                            onClick={() => {
                              const features =
                                'height=800, width=800, top=300, left=500, toolbar=no, menubar=no,scrollbars=yes,resizable=no, location=yes, status=no,fullscreen=no';
                              window.open('/support', 'protocol', features);
                            }}
                          >
                            隐私协议
                          </span>
                        </Checkbox>
                      </CheckboxWrap>
                    )}
                    rules={{
                      required: false,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </SpacedSubmitForm>
        );
      }
      if (phoneActive === 'verification') {
        return (
          <SpacedSubmitForm
            action={loginCodeUrl}
            id="phone-form"
            method="POST"
            onSubmit={handleSubmit(handleCodeLogin)}
          >
            <div className="hidden">
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <Input
                    placeholder="输入手机号"
                    prefix={<Icon name="login_phone_icon" size={16} />}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="hidden">
              <Controller
                control={control}
                name="source"
                render={({ field }) => (
                  <Input placeholder="来源" {...field} value="sms" />
                )}
              />
            </div>
            <div className="text-24 text-[#3D3D3D] font-bold text-left mb-[24px]">
              <div className="flex items-center mb-[146px]">
                <Icon
                  name="codeBack"
                  onClick={() => {
                    setPhoneActive('graphCode');
                  }}
                  style={{
                    fontSize: 26,
                    marginRight: '16px',
                    color: '#666666',
                  }}
                />
                <span>请输入验证码</span>
              </div>

              <div className="text-16 text-content">
                已发送至
                <span className="text-title-primary font-bold ml-1">
                  {telNumber}
                </span>
              </div>
            </div>
            <div className="codeNumberWrap mb-[40px]">
              <OtpInput
                inputStyle={{
                  width: '40px',
                  height: '40px',
                  marginRight: '28px',
                  fontSize: '24px',
                  borderRadius: 4,
                  border: '1px solid rgba(0,0,0,0.3)',
                }}
                isInputNum
                numInputs={6}
                onChange={handleChange}
                separator={''}
                shouldAutoFocus
                value={smsCode}
              />
              <div className="opacity-0">
                <Controller
                  control={control}
                  name="smsCode"
                  render={({ field }) => (
                    <Input
                      height={40}
                      maxLength={6}
                      placeholder="输入验证码"
                      {...field}
                      readOnly
                      value={smsCode}
                    />
                  )}
                  rules={{
                    required: '请输入验证码',
                  }}
                />
              </div>
              <div>
                {showCode ? (
                  <div className="text-14 text-center">
                    <div className="text-[#1375FF] inline-block">
                      {codeTime}秒
                    </div>
                    后可重新发送短信验证码
                  </div>
                ) : (
                  <div
                    className="text-center text-14 text-[#1375FF] cursor-pointer"
                    onClick={() => {
                      if (!spin) handSendCodeDelay();
                    }}
                  >
                    {spin ? <IconLoading /> : '重新发送'}
                  </div>
                )}
              </div>
            </div>
          </SpacedSubmitForm>
        );
      }

      if (phoneActive === 'password') {
        return (
          <SpacedSubmitForm
            action={loginCodeUrl}
            id="password-form"
            method="POST"
            onSubmit={handleSubmit(handlePasswordSet)}
          >
            <div className="hidden">
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <Input
                    placeholder="输入手机号"
                    prefix={<Icon name="login_phone_icon" size={16} />}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="hidden">
              <Controller
                control={control}
                name="smsCode"
                render={({ field }) => (
                  <Input placeholder="验证码" {...field} value={smsCode} />
                )}
                rules={{
                  required: false,
                }}
              />
            </div>
            <div className="hidden">
              <Controller
                control={control}
                name="source"
                render={({ field }) => (
                  <Input placeholder="来源" {...field} value="sms" />
                )}
              />
            </div>
            <div className="mb-[64px]">
              <div className="text-20 text-black-400 text-left mb-[32px]">
                {passwordText}
              </div>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <Input.Password
                    height={40}
                    placeholder="输入密码"
                    prefix={<Icon name="iconLock" size={16} />}
                    type="password"
                    {...field}
                  />
                )}
                rules={{
                  required: '请输入密码',
                  validate: {
                    password: (v) =>
                      v.length > 6 || v.length == 6 || '密码长度不能少于6',
                  },
                }}
              />
              {errors.password && (
                <p className="text-red absolute text-12">
                  {errors.password.message}
                </p>
              )}
            </div>
            <FormActions>
              <SubmitButton
                className="font-bold text-16"
                disabled={submitting}
                onClick={() => {
                  handleSubmit(handlePasswordSet);
                }}
                type="submit"
              >
                {submitting ? '请求中..' : '登录'}
              </SubmitButton>
            </FormActions>
          </SpacedSubmitForm>
        );
      }
    }
  }

  // console.log(pageSize, 'pageSize');
  return (
    <>
      <HtmlStyle />
      {pageSize <= 640 ? (
        <MobileLogin className={undefined} />
      ) : (
        <LoginPageWrap>
          {/* <div className=" text-left flex justify-between h-[100px] relative">
            <span className="text-[20px] mt-[15px]  text-[#333] font-bold">
              登录
            </span>
            <span className="text-[80px] text-[#999999] absolute top-[-12px] right-0">
              <Icon name="logo-ft" />
            </span>
          </div> */}
          {showError && (
            <FormMessage
              actions={
                !!errorMessage
                  ? []
                  : [
                      {
                        url: FORGOT_PASSWORD_URL,
                        text: createMessage(
                          LOGIN_PAGE_INVALID_CREDS_FORGOT_PASSWORD_LINK
                        ),
                        intent: 'success',
                      },
                    ]
              }
              intent="danger"
              message={
                !!errorMessage && errorMessage !== 'true'
                  ? errorMessage
                  : createMessage(LOGIN_PAGE_INVALID_CREDS_ERROR)
              }
            />
          )}
          {renderLoginForm()}
          <div
            className={cs(
              'flex mt-[16px] flex-row justify-between items-center text mb-[32px]',
              {
                hidden: phoneActive === 'verification',
              }
            )}
          >
            {!code && !isRsgisterPage ? (
              <span className="text-[#3D3D3D] text-14 flex items-center h-[18px]">
                还没有账号?
                <Button
                  className="fs-link !text-14 h-[18px] flex items-center"
                  onClick={() => {
                    setLoginType('phone');
                    setPhoneActive('graphCode');
                    // console.log(isRsgisterPage, 'isRsgisterPage');
                    if (!isRsgisterPage) {
                      setRsgister(true);
                      setIsRsgisterPage(true);
                      setSubmitting(true);
                      handlePhoneLogin('');
                    } else {
                      setPhoneActive('telephone');
                      setRsgister(false);
                      setIsRsgisterPage(false);
                      setSubmitting(false);
                    }
                    reset();
                    setCode('');
                    api.getVerity().then((res) => {
                      setValidImg(res.data.codePic);
                      setCodekey(res.data.codekey);
                    });
                  }}
                  type="primary"
                >
                  {!isRsgisterPage ? '注册' : '登录'}
                </Button>
              </span>
            ) : (
              <span />
            )}
          </div>
          {/* icon */}
          <div
            className={cs({
              hidden: phoneActive === 'verification',
            })}
          >
            <Divider orientation="center">
              {!isRsgisterPage ? '用其他方式登录' : '已有账号使用以下方式登录'}
            </Divider>
            <div className="flex justify-center text-[24px]">
              {OtherLoginData.map((item) => {
                // console.log(item, 'item');
                return item === 'wechat' ? (
                  <a
                    className="flex justify-center items-center bg-bg-primary w-[40px] h-[40px] p-[8px] rounded ml-4 cursor-pointer text-[#69bb65] hover:text-[#69bb65]"
                    href={`${location.protocol}/oauth2/authorization/wechat`}
                    key={item}
                  >
                    <Icon name="register_wechat" size={24} />
                  </a>
                ) : (
                  <div
                    className={cs(
                      'flex justify-center items-center bg-bg-primary w-[40px] h-[40px] p-[8px] rounded ml-4 cursor-pointer',
                      {
                        hidden:
                          (loginType === item || item === 'ding') &&
                          !isRsgisterPage,
                      }
                    )}
                    key={item}
                    onClick={() => {
                      setLoginType(item);
                      setIsRsgisterPage(false);
                      setRsgister(false);
                      setPhoneActive('graphCode');
                      handlePhoneLogin('');
                      setAccountActive('account');
                      reset();
                      setSubmitting(false);
                    }}
                  >
                    <Icon name={OtherLogin[item]} size={24} />
                  </div>
                );
              })}
            </div>
          </div>
        </LoginPageWrap>
      )}
    </>
  );
}

export default Login;
