import React from 'react';
import { TableColumnProps } from '@arco-design/web-react';

import { LoginThemeEnum, ModalRenderParams } from './interface';
import { CreateUserForm } from './UserManage/CreateUserForm';
import { CreateDepartmentForm } from './UserManage/CreateDepartmentForm';
import { ConfigureDepartmentOrUserForm } from './UserManage/ConfigureDepartmentOrUserForm';
// import { LoginViewTheme } from './LandingPageManage';
import { find } from 'lodash';

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_PAGE_NO = 1;

export const CONFIG_APP_LANDING_PAGE_NAME = '__CONFIG_APP_LANDING_PAGE_NAME__';

export const DEFAULT_LOGIN_TIPS = '欢迎使用紫微医院软件定制平台';

export const DEFAULT_APP_NAME = '紫微医院软件定制平台';

export const userFormItemLayout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

export const roleFormItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export const INIT_COLOR_CONFIGS: Record<
  LoginThemeEnum,
  {
    textColor: string;
    btnColor: string;
  }
> = {
  [LoginThemeEnum.GeneralYellowish]: {
    // one
    textColor: '#fff',
    btnColor: '#FF8E10',
  },
  [LoginThemeEnum.GeneralBlue]: {
    //two
    textColor: '#fff',
    btnColor: '#1375FF',
  },
  [LoginThemeEnum.GeneralWathet]: {
    // three
    textColor: '#fff',
    btnColor: '#5D4FFF',
  },
  [LoginThemeEnum.GeneralOrigin]: {
    // four
    textColor: '#FF8E10',
    btnColor: '#fff',
  },
  [LoginThemeEnum.VisitGreen]: {
    // five
    textColor: '#fff',
    btnColor: '#34DB93',
  },
  [LoginThemeEnum.BudgetPurple]: {
    // six
    textColor: '#fff',
    btnColor: '#646EF6',
  },
  [LoginThemeEnum.PerformanceOrigin]: {
    // seven
    textColor: '#fff',
    btnColor: '#FF6843',
  },
  [LoginThemeEnum.LogisticsOrigin]: {
    // eight
    textColor: '#fff',
    btnColor: '#FF9447',
  },
  [LoginThemeEnum.BadnessBlue]: {
    // nine
    textColor: '#fff',
    btnColor: '#0468FD',
  },
  [LoginThemeEnum.AssetCyan]: {
    // ten
    textColor: '#fff',
    btnColor: '#01C1C1',
  },
};

export const userFormStatusOptions = [
  {
    value: 0,
    label: '停用',
  },
  {
    value: 1,
    label: '启用',
  },
];

export const dataRightRangeOptions = [
  {
    value: 1,
    label: '查看所有数据',
  },
  {
    value: 2,
    label: '查看本部门和下级部门数据',
  },
  {
    value: 3,
    label: '查看本部门数据',
  },
];

export const userFormGenderOptions = [
  {
    value: 1,
    label: '男',
  },
  {
    value: 2,
    label: '女',
  },
  {
    value: 3,
    label: '未知',
  },
];

export const UserListFixedColumns: TableColumnProps[] = [
  {
    title: '登录账号',
    dataIndex: 'username',
    width: 120,
    fixed: 'left',
  },
  {
    title: '姓名',
    dataIndex: 'emp_name',
    width: 80,
    fixed: 'left',
  },
  {
    title: '性别',
    dataIndex: 'gender',
    width: 60,
    fixed: 'left',
    render: (col) => {
      return (
        find(userFormGenderOptions, (el) => el.value === col)?.label || '-'
      );
    },
  },
  {
    title: '职工号',
    dataIndex: 'emp_id',
    fixed: 'left',
    width: 100,
  },
  {
    title: '科室',
    dataIndex: 'dept_name',
    fixed: 'left',
    width: 100,
  },
  {
    title: '联系电话',
    dataIndex: 'mobile',
    fixed: 'right',
  },
  {
    title: '角色',
    dataIndex: 'role_name',
    fixed: 'right',
  },
  {
    title: '状态',
    dataIndex: 'status',
    fixed: 'right',
    render: (col) => {
      return (
        find(userFormStatusOptions, (el) => el.value === col)?.label || '-'
      );
    },
  },
];

export const roleListFixedColumns: TableColumnProps[] = [
  {
    title: '角色名称(ID)',
    dataIndex: 'name',
    render: (col, row) => {
      return `${col}(${row.id})`;
    },
  },
  {
    title: '描述',
    dataIndex: 'remark',
  },
];

export const MODAL_MAP = {
  newOrEditUser: {
    value: 'newOrEditUser',
    title: '新建用户',
    editTitle: '更新用户',
    renderChildren: ({
      departmentList,
      form,
      initialValue,
      isEdit,
      onQueryRoles,

      roleList,
    }: ModalRenderParams) => (
      <CreateUserForm
        departmentList={departmentList}
        form={form}
        initialValue={initialValue}
        isEdit={isEdit}
        onQueryRoles={onQueryRoles}
        roleList={roleList}
      />
    ),
  },
  newOrEditDepartment: {
    value: 'newOrEditDepartment',
    title: '新建科室',
    editTitle: '更新科室',
    renderChildren: ({
      departmentList,
      form,
      initialValue,
      isEdit,
    }: ModalRenderParams) => (
      <CreateDepartmentForm
        departmentList={departmentList}
        form={form}
        initialValue={initialValue}
        isEdit={isEdit}
      />
    ),
  },
  configOrEditDataSource: {
    value: 'configOrEditDataSource',
    title: '配置科室数据',
    editTitle: '更新科室数据',
    renderChildren: ({
      dataSourceOptions,
      form,
      initialValue,
      isEdit,
    }: ModalRenderParams) => (
      <ConfigureDepartmentOrUserForm
        dataSourceOptions={dataSourceOptions}
        form={form}
        initialValue={initialValue}
        isEdit={isEdit}
      />
    ),
  },
  configOrEditEmployee: {
    value: 'configOrEditEmployee',
    title: '配置用户数据',
    editTitle: '更新用户数据',
    renderChildren: ({
      dataSourceOptions,
      form,
      initialValue,
      isEdit,
    }: ModalRenderParams) => (
      <ConfigureDepartmentOrUserForm
        dataSourceOptions={dataSourceOptions}
        form={form}
        initialValue={initialValue}
        isEdit={isEdit}
      />
    ),
  },
} as const;
