import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Spin, Button, Space } from '@arco-design/web-react';
import { filter, get, isFunction } from 'lodash';

import { ModalPreviewSubAppProps } from './interface';
import { useInterval } from 'hooks/useInterval';
import {
  CHILD_REPLY_PAGE_LOADED_MESSAGE,
  CHILD_REPLY_STORE_APP_PROFILE,
  getSubAppUrl,
  PARENT_POLL_PAGE_LOADED_MESSAGE,
  SUB_APP_READY_FLAG,
} from './utils';
import { CONFIG_APP_LANDING_PAGE_NAME } from '../AppSysConfigDrawer/constants';

const POLL_INTERVAL = 3000;

export function ModalPreviewSubApp(props: ModalPreviewSubAppProps) {
  const {
    onCancel,
    onCheckSubUrlAvailable,
    onFetchRemoteAppUrl,
    onGetSubAppProfile,
  } = props;
  const [startCheck, setStartCheck] = useState(null);
  const [checkSubAppReady, setCheckSubAppReady] = useState(null);
  const [subAppUrl, setSubAppUrl] = useState(null);
  const subWinRef = useRef<Window | null>(null);
  const appProfileRef = useRef<any>(null);

  const handleOpenSubApp = (appName: string, theme: string) => {
    // const tempUrl = '127.0.0.1:8008';
    subWinRef.current = window.open(getSubAppUrl(subAppUrl, appName, theme));

    setCheckSubAppReady(POLL_INTERVAL);
  };

  const listenPostMessage = useCallback((ev: MessageEvent) => {
    if (ev?.data === CHILD_REPLY_PAGE_LOADED_MESSAGE) {
      setCheckSubAppReady(null);

      if (appProfileRef.current) {
        subWinRef.current.postMessage(appProfileRef.current, '*');
      }
    }

    if (ev?.data === CHILD_REPLY_STORE_APP_PROFILE) {
      onCancel();
    }
  }, []);

  useInterval(() => {
    if (onCheckSubUrlAvailable) {
      onCheckSubUrlAvailable(subAppUrl).then((val) => {
        if (val === SUB_APP_READY_FLAG) {
          setStartCheck(null);

          if (isFunction(onGetSubAppProfile)) {
            onGetSubAppProfile()
              .then((data) => {
                appProfileRef.current = data;

                const configLandingPage = filter(
                  get(data, 'pageList', []),
                  (p) => {
                    const pageName = get(p, 'unpublishedPage.name', '');

                    if (pageName === CONFIG_APP_LANDING_PAGE_NAME) {
                      return true;
                    }

                    return false;
                  }
                )[0];

                const landingPageConfigurations = get(
                  configLandingPage,
                  'unpublishedPage.layouts[0].dsl',
                  null
                );
                const { appName, theme } = landingPageConfigurations || {};

                handleOpenSubApp(appName, theme);
              })
              .catch(() => {
                //
              });
          }
        }
      });
    }
  }, startCheck);

  useInterval(() => {
    if (subWinRef.current) {
      subWinRef.current.postMessage(PARENT_POLL_PAGE_LOADED_MESSAGE, '*');
    }
  }, checkSubAppReady);

  useEffect(() => {
    if (isFunction(onFetchRemoteAppUrl)) {
      onFetchRemoteAppUrl().then((val) => {
        if (val) {
          setSubAppUrl(val);
          setStartCheck(POLL_INTERVAL);
        }
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', listenPostMessage, false);

    return () => {
      window.removeEventListener('message', listenPostMessage, false);
      subWinRef.current = null;
    };
  }, []);

  return (
    <div className="flex flex-col items-center">
      <Spin className="my-4" dot />
      <p>
        应用正在生成中，预计耗时1~2分钟，请稍作等待
        <span className="truncate ml-[2px]">...</span>
      </p>

      <Space className="self-end mt-4" size={16}>
        <Button onClick={onCancel}>取消</Button>
        <Button type="primary">重试</Button>
      </Space>
    </div>
  );
}
