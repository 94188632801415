import { get, isArray, nth } from 'lodash';
import zhConstants from './zh/constants-map';

const lang =
  (navigator.language || navigator.browserLanguage).toLowerCase() || 'zh';

enum Languages {
  zh = '中文',
  en = 'English',
}

const languageDetectionMethod = {
  zh: (lang) => /^zh(-cn)?$/.test(lang),
  en: (lang) => /^en?$/.test(lang),
};

const languagePack = {
  zh: {
    ...zhConstants,
  },
};

function getUserLanguage(lang: string) {
  for (const key in languageDetectionMethod) {
    if (languageDetectionMethod[key](lang)) {
      return key;
    }
  }
  return 'zh';
}

// const getCurrentLangPage = async () =>
//   map(getUserLanguage(lang), async (v) => await v);

const currentLangPage = get(
  languagePack,
  getUserLanguage(lang),
  languageDetectionMethod.zh
);

// console.log(Languages[getUserLanguage(lang)], 'currentLanguage');

export default function getLang(
  path: string | string[],
  defaultValue?: string
) {
  return get(
    currentLangPage,
    path,
    defaultValue || isArray(path) ? nth(path, -1) : path.split('.').at(-1)
  );
}
