import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  // ApplicationPayload,
  ReduxAction,
  // ReduxActionErrorTypes,
  ReduxActionTypes,
} from '@appsmith/constants/ReduxActionConstants';
import { initCanvasLayout } from 'actions/pageActions';
import { ApiResponse } from 'api/ApiResponses';
import DepApi from 'api/DepApi';
import CanvasWidgetsNormalizer from 'normalizers/CanvasWidgetsNormalizer';
import defaultTemplate from 'templates/default';
import { transformDSL } from 'utils/DSLMigrations';
import {
  actionTypes,
  // fetchDepAppJson,
  // fetchDepAppMenus,
  // setDepAppJson,
  setDepAppMenus,
} from '../redux/actions';
import initializeAppAfterLogin from './InitializedSaga';

// function* fetchDepAppJsonSaga() {
//   const cacheKey = 'ft-app-json'; // fixme
//   const appJson = localStorage.getItem(cacheKey);
//   if (appJson) {
//     yield put(setDepAppJson(JSON.parse(appJson)));
//     return;
//   }
//
//   try {
//     const resp: ApiResponse<string> = yield call(DepApi.getAppJson);
//     // data is raw app json string
//     const validJson = JSON.parse(resp.data);
//     if (resp.data && validJson) {
//       localStorage.setItem(cacheKey, resp.data);
//       yield put(setDepAppJson(validJson));
//     }
//   } catch (err) {
//     yield put({
//       type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
//       payload: {
//         err,
//       },
//     });
//   }
// }

export function transformDepPageDsl(dsl) {
  const extractDsl = transformDSL(dsl || { ...defaultTemplate }, false);
  const normalizeDsl = CanvasWidgetsNormalizer.normalize(extractDsl);
  return normalizeDsl.entities.canvasWidgets;
}

function* changeDepPage(action: ReduxAction<{ pageId: string; dsl: unknown }>) {
  const { dsl, pageId } = action.payload;
  const widgets = transformDepPageDsl(dsl);

  // todo: attach current page id, app id
  yield put(initCanvasLayout({ widgets }));

  yield put({
    type: ReduxActionTypes.INITIALIZE_PAGE_VIEWER_SUCCESS,
  });
}

function* fetchDepAppMenusSaga() {
  const cacheKey = 'ft-app-menus';
  const menus = localStorage.getItem(cacheKey);
  if (menus) {
    yield put(setDepAppMenus(JSON.parse(menus)));
    return;
  }

  const resp: ApiResponse = yield call(DepApi.allMenu);
  if (resp.data) {
    localStorage.setItem(cacheKey, JSON.stringify(resp.data));
  }
  yield put(setDepAppMenus(resp.data || []));
}

export default function* appInstallSagas() {
  yield all([
    // takeLatest(actionTypes.FETCH_DEP_APP_JSON, fetchDepAppJsonSaga),
    // takeLatest(actionTypes.FETCH_DEP_APP_MENUS, fetchDepAppMenusSaga),
    takeLatest(actionTypes.INITIALIZE_APP_AFTER_LOGIN, initializeAppAfterLogin),
    takeLatest(actionTypes.CHANGE_DEP_APP_PAGE, changeDepPage),
  ]);
}
