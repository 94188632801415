import { createReducer } from 'utils/AppsmithUtils';
import { ReduxAction } from '@appsmith/constants/ReduxActionConstants';
import {
  actionTypes,
  MenuItem,
  ActiveUserProfile,
  InitializedInfo,
} from './actions';

export interface AppInstallState {
  installMode?: boolean;
  isAppInitializing?: boolean;
  previewSubAppMode?: boolean;
  applicationId: string;
  defaultPageId: string;
  appJson?: any;
  appMenus?: MenuItem[];
  fetchingAppJson?: boolean;
  fetchingAppMenus?: boolean;
  userProfile: ActiveUserProfile;
}

const initialState: AppInstallState = {
  installMode: false,
  isAppInitializing: false,
  previewSubAppMode: false,
  applicationId: '',
  defaultPageId: '',
  appJson: undefined,
  fetchingAppJson: false,
  appMenus: [],
  fetchingAppMenus: false,
  userProfile: null,
};

const reducer = createReducer(initialState, {
  [actionTypes.SET_INITIALIZED_INFO]: (
    state: AppInstallState,
    action: ReduxAction<InitializedInfo>
  ) => {
    return { ...state, ...action.payload };
  },
  [actionTypes.FETCH_DEP_APP_JSON]: (state: AppInstallState) => {
    return { ...state, fetchingAppJson: true };
  },
  [actionTypes.SET_DEP_APP_JSON]: (
    state: AppInstallState,
    action: ReduxAction<any>
  ) => {
    return { ...state, appJson: action.payload, fetchingAppJson: false };
  },
  [actionTypes.FETCH_DEP_APP_MENUS]: (state: AppInstallState) => {
    return { ...state, fetchingAppMenus: true };
  },
  [actionTypes.SET_DEP_APP_MENUS]: (
    state: AppInstallState,
    action: ReduxAction<any>
  ) => {
    return { ...state, appMenus: action.payload || [] };
  },
  [actionTypes.SET_ACTIVE_USER_PROFILE]: (
    state: AppInstallState,
    action: ReduxAction<any>
  ) => {
    return {
      ...state,
      userProfile: action.payload.profile || {},
    };
  },
});

export default reducer;
