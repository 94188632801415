import { call, put, select } from 'redux-saga/effects';
import { ReduxActionTypes } from 'ce/constants/ReduxActionConstants';
import { PrintByTemplateActionDescription } from 'entities/DataTree/actionTriggers';
import { getCurrentApplicationId } from 'selectors/editorSelectors';
import { getAppInstallMode } from 'selectors/applicationSelectors';
import { NavigationTargetType } from 'components/editorComponents/ActionCreator/interfaces';
import storeValueLocally from './StoreActionSaga';
import navigateActionSaga from './NavigateActionSaga';

export const PRINT_BY_TEMPLATE_PRAMS_KEY = '__PRINT_BY_TEMPLATE_PRAMS_KEY__';

export default function* printByTemplateSaga(
  action: PrintByTemplateActionDescription['payload']
) {
  const applicationId = yield select(getCurrentApplicationId);
  const installMode = yield select(getAppInstallMode);

  // TODO maybe use history state replace
  yield call(storeValueLocally, {
    key: PRINT_BY_TEMPLATE_PRAMS_KEY,
    value: action.params,
    persist: true,
  });

  if (installMode) {
    yield call(navigateActionSaga, {
      pageIdOrUrl: action.templateId,
      target: NavigationTargetType.NEW_WINDOW,
    });
  } else {
    yield put({
      type: ReduxActionTypes.PUBLISH_APPLICATION_INIT,
      payload: {
        applicationId,
        pageId: action.templateId,
      },
    });
  }
}
