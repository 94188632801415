/* eslint-disable @typescript-eslint/ban-types */
/* TODO: Function and object types need to be updated to enable the lint rule */
import { ActionType, FieldType, ViewTypes } from './constants';
import { TreeDropdownOption } from './Dropdown';

export enum NavigationTargetType {
  SAME_WINDOW = 'SAME_WINDOW',
  NEW_WINDOW = 'NEW_WINDOW',
}

export enum NavigationUrlType {
  INPUT_URL = 'URL_INPUT',
  SELECT_URL = 'URL_SELECT',
}

export type IActionType = typeof ActionType[keyof typeof ActionType];

type IViewTypes = typeof ViewTypes[keyof typeof ViewTypes];

export type FieldConfig = {
  getter: Function;
  setter: Function;
  view: IViewTypes;
};

export type FieldConfigs = Partial<Record<FieldType, FieldConfig>>;

export type ViewProps = {
  label: string;
  get: Function;
  set: Function;
  value: string;
};

export type SelectorViewProps = ViewProps & {
  options: TreeDropdownOption[];
  defaultText: string;
  getDefaults?: (value?: any) => any;
  displayValue?: string;
  selectedLabelModifier?: (
    option: TreeDropdownOption,
    displayValue?: string
  ) => React.ReactNode;
  index?: number;
};

export type KeyValueViewProps = ViewProps;

export type TextViewProps = ViewProps & {
  index?: number;
  additionalAutoComplete?: Record<string, Record<string, unknown>>;
};

export type INavigetUrlViewProps = {
  urlType?: NavigationUrlType;
  onUrlTypeChange?: (value: NavigationUrlType) => void;
};

export type NavigetUrlViewProps = SelectorViewProps &
  TextViewProps &
  INavigetUrlViewProps;
