import { Descriptions } from '@arco-design/web-react';
import { error } from 'loglevel';
import { getColorWithOpacity } from './utils';
import {
  editorBottomBar,
  gitSyncModal,
  ColorType,
  numberedStep,
  tabItemBackgroundFill,
  notifications,
  displayImageUpload,
  showcaseCarousel,
  mentionSuggestion,
  reactionsComponent,
  mentionsInput,
  globalSearch,
  comments,
  lightShades,
  darkShades,
  guidedTour,
  actionSidePane,
  pagesEditor,
  auth,
  formMessage,
} from './common';
import { Colors } from 'constants/Colors';

export const light: ColorType = {
  editorBottomBar,
  gitSyncModal,
  numberedStep,
  tabItemBackgroundFill,
  overlayColor: '#090707cc',
  notifications,
  displayImageUpload,
  showcaseCarousel,
  mentionSuggestion,
  reactionsComponent,
  mentionsInput,
  helpModal: {
    itemHighlight: '#EBEBEB',
    background: '#FFFFFF',
  },
  globalSearch: {
    ...globalSearch,
    helpBarBackground: '#F0F0F0',
    helpBarText: '#A9A7A7',
    helpButtonBackground: '#F0F0F0',
    helpIcon: '#939090',
  },
  comments: {
    ...comments,
    activeModeBackground: '#EBEBEB',
    activeModeIcon: '#4B4848',
    modeIcon: '#858282',
    modeIconCircleStroke: '#fff',
    activeModeIconCircleStroke: '#EBEBEB',
  },
  navigationMenu: {
    contentActive: '#090707',
    backgroundActive: '#EBEBEB',
    contentInactive: '#4B4848',
    backgroundInactive: '#FFFFFF',
    label: '#A9A7A7',
    warning: '#F22B2B',
    warningBackground: '#FFFFFF',
  },
  selected: lightShades[12],
  header: {
    separator: '#E0DEDE',
    appName: lightShades[8],
    background: lightShades[0],
    deployToolTipText: lightShades[8],
    deployToolTipBackground: '#FFF',
    shareBtnHighlight: '#F86A2B',
    shareBtn: '#4B4848',
    tabsHorizontalSeparator: '#EFEFEF',
    tabText: '#6F6D6D',
    activeTabBorderBottom: '#FF6D2D',
    activeTabText: '#000',
  },
  button: {
    disabledText: lightShades[15],
    boxShadow: {
      default: {
        variant1: Colors.BOX_SHADOW_DEFAULT_VARIANT1,
        variant2: Colors.BOX_SHADOW_DEFAULT_VARIANT2,
        variant3: Colors.BOX_SHADOW_DEFAULT_VARIANT3,
        variant4: Colors.BOX_SHADOW_DEFAULT_VARIANT4,
        variant5: Colors.BOX_SHADOW_DEFAULT_VARIANT5,
      },
    },
    disabled: {
      bgColor: Colors.GREY_1,
      textColor: Colors.GREY_8,
    },
    primary: {
      primary: {
        bgColor: Colors.GREEN,
        hoverColor: Colors.PRIMARY_SOLID_HOVER,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.GREEN,
        hoverColor: Colors.PRIMARY_OUTLINE_HOVER,
        textColor: Colors.GREEN,
      },
      tertiary: {
        hoverColor: Colors.PRIMARY_GHOST_HOVER,
      },
    },
    warning: {
      primary: {
        bgColor: Colors.WARNING_SOLID,
        hoverColor: Colors.WARNING_SOLID_HOVER,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.WARNING_SOLID,
        hoverColor: Colors.WARNING_OUTLINE_HOVER,
        textColor: Colors.WARNING_SOLID,
      },
      tertiary: {
        hoverColor: Colors.WARNING_GHOST_HOVER,
      },
    },
    danger: {
      primary: {
        bgColor: Colors.DANGER_SOLID,
        hoverColor: Colors.DANGER_SOLID_HOVER,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.DANGER_SOLID,
        hoverColor: Colors.DANGER_NO_SOLID_HOVER,
        textColor: Colors.DANGER_SOLID,
      },
      tertiary: {
        hoverColor: Colors.DANGER_NO_SOLID_HOVER,
      },
    },
    info: {
      primary: {
        bgColor: Colors.INFO_SOLID,
        hoverColor: Colors.INFO_SOLID_HOVER,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.INFO_SOLID,
        hoverColor: Colors.INFO_NO_SOLID_HOVER,
        textColor: Colors.INFO_SOLID,
      },
      tertiary: {
        hoverColor: Colors.INFO_NO_SOLID_HOVER,
      },
    },
    secondary: {
      primary: {
        bgColor: Colors.GRAY,
        hoverColor: Colors.CHARCOAL,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.GRAY,
        hoverColor: Colors.Gallery,
        textColor: Colors.GRAY,
      },
      tertiary: {
        hoverColor: Colors.MERCURY,
      },
    },
    custom: {
      solid: {
        dark: {
          textColor: Colors.CUSTOM_SOLID_DARK_TEXT_COLOR,
        },
        light: {
          textColor: Colors.WHITE,
        },
      },
    },
    link: {
      main: '#716E6E',
      hover: '#090707',
      active: '#4B4848',
      disabled: '#858282',
    },
  },
  tertiary: {
    main: '#606065',
    light: '#090707',
    dark: '#FAFAFA',
    darker: '#EDEDED',
    darkest: '#A9A7A7',
  },
  info: {
    main: '#F86A2B',
    light: '#DC5B21',
    dark: '#FF5800',
    darker: '#FEEDE5',
    darkest: '#F7EBE6',
  },
  success: {
    main: '#03B365',
    light: '#007340',
    dark: '#00693B',
    darker: '#CBF4E2',
    darkest: '#D9FDED',
  },
  warning: {
    main: '#FEB811',
    light: '#EFA903',
    dark: '#EFA903',
    darker: '#FBEED0',
    darkest: '#FFFAE9',
  },
  danger: {
    main: '#F22B2B',
    light: '#B90707',
    dark: '#C60707',
    darker: '#FDE4E4',
    darkest: '#FFE9E9',
  },
  homepageBackground: '#ffffff',
  card: {
    hoverBG: Colors.WHITE,
    hoverBGOpacity: 0.7,
    hoverBorder: lightShades[2],
    iconColor: lightShades[11],
  },
  text: {
    normal: lightShades[8],
    heading: lightShades[9],
    highlight: lightShades[11],
  },
  icon: {
    normal: lightShades[4],
    hover: lightShades[8],
    active: lightShades[9],
  },
  appIcon: {
    normal: lightShades[7],
    background: lightShades[1],
  },
  menu: {
    background: lightShades[11],
    shadow: 'rgba(0, 0, 0, 0.32)',
  },
  menuItem: {
    normalText: lightShades[8],
    normalIcon: lightShades[6],
    hoverIcon: lightShades[8],
    hoverText: lightShades[10],
    hoverBg: lightShades[2],
    warning: {
      color: '#D2A500',
      bg: '#FDFAF2',
    },
  },
  colorSelector: {
    shadow: lightShades[3],
    checkmark: lightShades[16],
  },
  checkbox: {
    disabled: lightShades[3],
    unchecked: lightShades[5],
    disabledCheck: lightShades[6],
    normalCheck: lightShades[11],
    labelColor: lightShades[9],
  },
  dropdown: {
    header: {
      text: lightShades[8],
      disabledText: darkShades[6],
      defaultBg: lightShades[11],
      bg: lightShades[14],
      disabledBg: lightShades[1],
    },
    menu: {
      border: lightShades[13],
      bg: lightShades[0],
      text: lightShades[8],
      hover: lightShades[2],
      hoverText: lightShades[10],
      subText: lightShades[15],
    },
    menuShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
    selected: {
      text: lightShades[10],
      bg: lightShades[14],
      icon: lightShades[15],
      subtext: lightShades[7],
    },
    hovered: {
      text: lightShades[10],
      bg: lightShades[14],
      icon: lightShades[11],
    },
    icon: lightShades[7],
  },
  toggle: {
    bg: lightShades[13],
    hover: {
      on: '#FF5800',
      off: lightShades[5],
    },
    disable: {
      on: '#FEEDE5',
      off: lightShades[13],
    },
    disabledSlider: {
      off: lightShades[11],
      on: lightShades[11],
    },
    spinner: lightShades[5],
    spinnerBg: lightShades[3],
  },
  textInput: {
    disable: {
      bg: lightShades[2],
      text: darkShades[5],
      border: lightShades[2],
    },
    normal: {
      bg: lightShades[11],
      text: lightShades[10],
      border: lightShades[13],
    },
    placeholder: lightShades[5],
    helper: lightShades[15],
    icon: lightShades[7],
    readOnly: {
      bg: lightShades[2],
      border: lightShades[2],
      text: lightShades[7],
    },
    hover: {
      bg: lightShades[0],
    },
    caretColor: Colors.BLACK,
  },
  menuBorder: lightShades[3],
  editableText: {
    color: lightShades[8],
    bg: lightShades[2],
    dangerBg: 'rgba(242, 43, 43, 0.06)',
  },
  radio: {
    disable: lightShades[4],
    border: lightShades[3],
    text: lightShades[10],
  },
  searchInput: {
    placeholder: lightShades[6],
    text: lightShades[10],
    border: lightShades[13],
    bg: lightShades[11],
    icon: {
      focused: lightShades[10],
      normal: lightShades[7],
    },
  },
  spinner: lightShades[6],
  tableDropdown: {
    bg: lightShades[11],
    selectedBg: lightShades[2],
    selectedText: lightShades[9],
    shadow: 'rgba(0, 0, 0, 0.32)',
  },
  tabs: {
    normal: lightShades[15],
    icon: lightShades[10],
    hover: lightShades[8],
    border: lightShades[3],
    countBg: lightShades[3],
    selected: Colors.CRUSTA,
  },
  settingHeading: lightShades[16],
  table: {
    headerBg: lightShades[1],
    headerText: lightShades[6],
    rowData: lightShades[7],
    rowTitle: lightShades[9],
    border: lightShades[3],
    hover: {
      headerColor: lightShades[9],
      rowBg: lightShades[2],
      rowTitle: lightShades[10],
      rowData: lightShades[9],
    },
  },
  applications: {
    bg: lightShades[3],
    textColor: lightShades[7],
    orgColor: lightShades[7],
    iconColor: lightShades[7],
    hover: {
      bg: lightShades[5],
      textColor: lightShades[8],
      orgColor: lightShades[9],
    },
    cardMenuIcon: lightShades[17],
  },
  switch: {
    border: lightShades[5],
    bg: lightShades[11],
    hover: {
      bg: lightShades[11],
    },
    lightText: lightShades[11],
    darkText: lightShades[6],
  },
  queryTemplate: {
    bg: lightShades[11],
    color: lightShades[8],
  },
  profileDropdown: {
    name: lightShades[10],
    userName: lightShades[7],
  },
  modal: {
    bg: lightShades[11],
    headerText: lightShades[10],
    iconColor: lightShades[5],
    iconBg: lightShades[18],
    user: {
      textColor: lightShades[9],
    },
    email: {
      message: lightShades[9],
      desc: lightShades[7],
    },
    manageUser: lightShades[19],
    scrollbar: lightShades[5],
    separator: lightShades[4],
    title: lightShades[8],
    link: '#F86A2B',
    hoverState: lightShades[3],
  },
  tagInput: {
    bg: lightShades[11],
    tag: {
      text: lightShades[11],
    },
    text: lightShades[9],
    placeholder: darkShades[7],
    shadow: 'none',
  },
  callout: {
    info: {
      color: '#D44100',
      bgColor: '#F8F3F0',
    },
    success: {
      color: '#03B365',
      bgColor: '#E4F4ED',
    },
    danger: {
      color: '#F22B2B',
      bgColor: '#F9E9E9',
    },
    error: {
      color: '#F22B2B',
      bgColor: '#F9E9E9',
    },
    warning: {
      color: '#FEB811',
      bgColor: '#FAF3E3',
    },
  },
  loader: {
    light: lightShades[2],
    dark: lightShades[4],
  },
  filePicker: {
    bg: lightShades[2],
    color: lightShades[7],
    progress: lightShades[6],
    shadow: {
      from: 'rgba(253, 253, 253, 0.0001)',
      to: 'rgba(250, 250, 250, 0.898847)',
    },
  },
  formFooter: {
    cancelBtn: lightShades[9],
  },
  toast: {
    undo: '#F86A2B',
    undoRedoColor: '#F8682B',
    warningColor: '#DCAD00',
    dangerColor: '#F22B2B',
    textColor: '#F7F7F7',
    bg: lightShades[10],
  },
  multiSwitch: {
    bg: lightShades[3],
    selectedBg: lightShades[11],
    text: lightShades[8],
    border: '#E0DEDE',
  },
  apiPane: {
    bg: lightShades[11],
    tabBg: lightShades[11],
    text: lightShades[16],
    dividerBg: lightShades[13],
    iconHoverBg: lightShades[1],
    requestTree: {
      bg: lightShades[11],
      header: {
        text: lightShades[8],
        icon: lightShades[8],
        bg: lightShades[2],
      },
      row: {
        hoverBg: lightShades[2],
        key: lightShades[7],
        value: lightShades[8],
      },
    },
    closeIcon: lightShades[10],
    responseBody: {
      bg: lightShades[11],
    },
    codeEditor: {
      placeholderColor: lightShades[15],
    },
    body: {
      text: '#A9A7A7',
    },
    settings: {
      textColor: lightShades[10],
    },
    pagination: {
      label: lightShades[8],
      description: lightShades[5],
      stepTitle: lightShades[10],
      numberBg: '#E0DEDE',
      bindingBg: lightShades[3],
      numberColor: lightShades[10],
    },
  },
  codeMirror: {
    background: {
      defaultState: lightShades[0],
      hoverState: lightShades[12],
    },
    text: '#090707',
    dataType: {
      shortForm: '#858282',
      fullForm: '#6D6D6D',
    },
  },
  floatingBtn: {
    tagBackground: '#e22c2c',
    backgroundColor: lightShades[0],
    iconColor: lightShades[7],
    borderColor: lightShades[14],
  },
  auth,
  formMessage,
  gif: {
    overlay: '#ffffff',
    text: '#6f6f6f',
    iconPath: '#c4c4c4',
    iconCircle: '#090707',
  },
  treeDropdown: {
    targetBg: '#FFFFFF',
    targetIcon: {
      normal: '#939090',
      hover: '#4B4848',
    },
    menuShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
    menuBg: {
      normal: lightShades[0],
      hover: lightShades[12],
      selected: lightShades[3],
    },
    menuText: {
      normal: lightShades[8],
      hover: lightShades[0],
      selected: lightShades[8],
    },
  },
  propertyPane: {
    title: darkShades[11],
    bg: lightShades[2],
    label: lightShades[8],
    jsIconBg: lightShades[5],
    buttonBg: lightShades[8],
    buttonText: lightShades[11],
    radioGroupBg: lightShades[0],
    radioGroupText: lightShades[8],
    deleteIconColor: '#A3B3BF',
    zoomButtonBG: lightShades[13],
    activeButtonText: lightShades[12],
    jsButtonHoverBG: lightShades[2],
    dropdownSelectBg: lightShades[14],
    multiDropdownBoxHoverBg: lightShades[11],
    iconColor: lightShades[5],
    ctaTextColor: '#202223',
    ctaBackgroundColor: 'rgb(248, 106, 43, 0.1)',
    ctaLearnMoreTextColor: '#f86a2b',
    connections: {
      error: '#f22b2b',
      connectionsCount: darkShades[11],
      optionBg: 'rgba(246,71,71, 0.2)',
    },
  },
  scrollbar: getColorWithOpacity(Colors.CHARCOAL, 0.5),
  scrollbarBG: 'transparent',
  debugger: {
    background: '#FFFFFF',
    messageTextColor: '#716e6e',
    time: '#4b4848',
    label: '#4b4848',
    entity: 'rgba(75, 72, 72, 0.7)',
    entityLink: '#6d6d6d',
    jsonIcon: '#a9a7a7',
    message: '#4b4848',
    evalDebugButton: {
      hover: '#fafafaaa',
      active: '#fafafaff',
    },
    floatingButton: {
      background: '#2b2b2b',
      color: '#d4d4d4',
      shadow: '0px 12px 28px -6px rgba(0, 0, 0, 0.32)',
      errorCount: '#F22B2B',
      noErrorCount: '#03B365',
      warningCount: '#DCAD00',
    },
    inspectElement: {
      color: '#090707',
    },
    blankState: {
      color: '#090707',
      shortcut: 'black',
    },
    info: {
      borderBottom: 'rgba(0, 0, 0, 0.05)',
    },
    warning: {
      iconColor: '#f3cc3e',
      hoverIconColor: '#e0b30e',
      borderBottom: 'white',
      backgroundColor: 'rgba(254, 184, 17, 0.1)',
    },
    error: {
      iconColor: '#f56060',
      hoverIconColor: '#F22B2B',
      borderBottom: 'white',
      backgroundColor: 'rgba(242, 43, 43, 0.08)',
    },
  },
  guidedTour,
  widgetGroupingContextMenu: {
    border: '#2494f4',
    actionActiveBg: '#e1e1e1',
  },
  actionSidePane,
  pagesEditor,
  link: '#f86a2b',
  welcomePage: {
    text: lightShades[5],
  },
  settings: {
    link: '#716E6E',
  },
};
