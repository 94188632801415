import { ReduxActionTypes } from '@appsmith/constants/ReduxActionConstants';

/**
 * action that sets preview mode
 *
 * @param payload
 * @returns
 */

export const setPreviewModeAction = (payload: boolean) => ({
  type: ReduxActionTypes.SET_PREVIEW_MODE,
  payload,
});

/**
 * action that update canvas layout
 *
 * @param width
 * @param height
 * @returns
 */
export const updateCanvasLayoutAction = (
  width: number,
  height: number | undefined
) => {
  return {
    type: ReduxActionTypes.UPDATE_CANVAS_LAYOUT,
    payload: {
      height,
      width,
    },
  };
};

/**
 * This action when executed updates the status of saving entity to true
 * This function was created to add a sync to the entity update and shortcut command being fired to execute any command.
 */

export const startingEntityUpdation = () => ({
  type: ReduxActionTypes.ENTITY_UPDATE_STARTED,
});

export const setZoomLevel = (level: number) => ({
  type: ReduxActionTypes.SET_ZOOM_LEVEL,
  payload: parseFloat(level).toFixed(2),
});

// todo: move to constant file
export const minZoom = 0.2;
export const maxZoom = 3;
export const zoomStep = 0.2;
