import { caches, multipleReq, req } from 'components/icon';
import React from 'react';

export interface Props extends React.SVGProps<SVGSVGElement> {
  name: string;
  size?: number;
}

function _Icon(
  { className, name, size = 16, style, ...props }: Props,
  ref?: React.Ref<SVGSVGElement>
): JSX.Element {
  const id = `#ft-icon-${name}`;
  const path = id.replace('#ft-icon-', './') + '.svg';
  if (!caches[path]) {
    try {
      req(path);
    } catch (e) {
      console.log('找不到svg:', path);
      try {
        multipleReq(path);
      } catch (e) {
        console.log('找不到彩色svg:', path);
      }
    }
    caches[path] = true;
  }
  const _style: React.CSSProperties = {
    ...style,
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <svg
      {...props}
      className={className}
      data-name={name}
      ref={ref}
      style={_style}
    >
      <use xlinkHref={id} />
    </svg>
  );
}

const Icon = React.forwardRef(_Icon);

export default Icon;
