import React from 'react';
import { Layout, Menu } from '@arco-design/web-react';
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import UserCenter from './UserCenter';
import OrderManage from './OrderManage';
import { EnterpriseAuthentication } from './EnterpriseAuthentication';
import { Container, MenuContainer } from './styles';
import Icon from 'components/icon';
import { APPLICATIONS_URL_APP } from 'constants/routes';
import { PATH_MAP_MENU_KEY } from './constants';
import AccountInterests from './AccountInterests';

const Sider = Layout.Sider;
const Header = Layout.Header;
const Content = Layout.Content;
const MenuItem = Menu.Item;

export function ServiceCenter() {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Container>
      <Layout className="h-full">
        <Header className="flex items-center h-[64px] px-[16px]">
          <Link
            className="flex items-center hover:no-underline"
            to={APPLICATIONS_URL_APP}
          >
            <Icon className="text-[#666] !w-[14px] !h-[14px]" name="back" />
            <span className="text-[#666] text-[20px] pl-[8px] font-bold">
              用户中心
            </span>
          </Link>
        </Header>
        <Layout className="flex-1 px-0">
          <Sider width={246}>
            <MenuContainer>
              <Menu
                className="h-full"
                defaultSelectedKeys={[PATH_MAP_MENU_KEY[pathname] || 'profile']}
                mode="pop"
              >
                <MenuItem key="profile">
                  <Link className="flex items-center" to={path}>
                    <Icon name="shield" />
                    <span className="pl-[12px]">基本信息</span>
                  </Link>
                </MenuItem>
                <MenuItem key="order">
                  <Link className="flex items-center" to={`${path}/order`}>
                    <Icon name="order" />
                    <span className="pl-[12px]">订单管理</span>
                  </Link>
                </MenuItem>
                {!process.env.REACT_APP_DISABLE_ENTERPRISE_CERTIFICATION ? (
                  <MenuItem key="auth">
                    <Link
                      className="flex items-center"
                      to={`${path}/enterprise-auth`}
                    >
                      <Icon name="diamond" />
                      <span className="pl-[12px]">企业认证</span>
                    </Link>
                  </MenuItem>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
                {/* <MenuItem key="account">
                  <Link
                    className="flex items-center"
                    to={`${path}/accountInterests`}
                  >
                    <Icon name="accountInterests" />
                    <span className="pl-[12px]">账号权益</span>
                  </Link>
                </MenuItem> */}
              </Menu>
            </MenuContainer>
          </Sider>
          <Content className="bg-[#F7F7F7]">
            <Switch>
              <Route exact path={path}>
                <UserCenter />
              </Route>
              <Route path={`${path}/order`}>
                <OrderManage />
              </Route>
              <Route path={`${path}/enterprise-auth`}>
                <EnterpriseAuthentication />
              </Route>
              <Route path={`${path}/accountInterests`}>
                <AccountInterests />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Container>
  );
}
