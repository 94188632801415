import { reduxBatch } from '@manaflair/redux-batch';
import { createStore, applyMiddleware, compose, Middleware } from 'redux';
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import appReducer, { AppState } from './reducers';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from 'sagas';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import * as Sentry from '@sentry/react';
import {
  ReduxAction,
  ReduxActionTypes,
} from '@appsmith/constants/ReduxActionConstants';
import { getRouteBuilderParams, updateURLFactory } from 'RouteBuilder';
import { updateSlugNamesInURL } from 'utils/helpers';
import logger from 'utils/redux-logger';
import { debounce } from 'lodash';

export const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (
      action.type === ReduxActionTypes.SET_EVALUATED_TREE ||
      action.type === ReduxActionTypes.EXECUTE_PLUGIN_ACTION_SUCCESS
    ) {
      // Return null to not log the action to Sentry
      action.payload = null;
    }
    return action;
  },
});

// applyMiddleware:接受fn数组,执行后返回 function：接受一个创建createStore的fn：返回一个fn。
// compose: 接受fn[]，返回fn：迭代function(applyMiddleware返回的function：接受一个创建createStore的fn), 使用右侧结果为参数：(执行创建createStore的fn)。
// createStore: 创建store，有中间价调用fn(compose的返回)，参数为createStore。

const routeParamsMiddleware: Middleware = () => {
  return (next: any) => {
    let needTriggerAction = [];
    const trigger = debounce(() => {
      next(needTriggerAction);
      needTriggerAction = [];
    }, 20);

    function _next(action) {
      needTriggerAction.push(action);
      trigger.cancel();
      trigger();
      return action;
    }

    return (action: ReduxAction<any>) => {
      switch (action.type) {
        case ReduxActionTypes.FETCH_APPLICATION_SUCCESS: {
          const { applicationVersion, id, slug } = action.payload;
          updateURLFactory({
            applicationId: id,
            applicationSlug: slug,
            applicationVersion,
          });
          break;
        }
        case ReduxActionTypes.CURRENT_APPLICATION_NAME_UPDATE: {
          const { slug } = action.payload;
          updateURLFactory({ applicationSlug: slug });
          updateSlugNamesInURL({
            applicationSlug: slug,
          });
          break;
        }
        case ReduxActionTypes.SWITCH_CURRENT_PAGE_ID: {
          const id = action.payload.id;
          const slug = action.payload.slug;
          updateURLFactory({ pageId: id, pageSlug: slug });
          break;
        }
        case ReduxActionTypes.UPDATE_PAGE_SUCCESS: {
          const id = action.payload.id;
          const slug = action.payload.slug;
          const { pageId } = getRouteBuilderParams();
          // Update route params and page slug in URL only if the current page is updated
          if (pageId === id) {
            updateURLFactory({ pageSlug: slug });
            updateSlugNamesInURL({
              pageSlug: slug,
            });
          }
          break;
        }
        case ReduxActionTypes.UPDATE_APPLICATION_SUCCESS:
          const { applicationVersion } = action.payload;
          updateURLFactory({ applicationVersion });
          break;
        default:
          break;
      }
      //TODO ⏰ It should with action priority,Because each commit both take it render.
      // if (
      //   action.type === 'EXECUTE_BATCH' ||
      //   action.type === 'ADD_WIDGET_CONFIG' ||
      //   action.type === 'DEBUGGER_ADD_ERROR_LOG' ||
      //   action.type === 'DEBUGGER_ADD_ERROR_LOG_INIT' ||
      //   action.type === 'DEBUGGER_LOG_INIT' ||
      //   action.type === 'DEBUGGER_ERROR_ANALYTICS' ||
      //   action.type === 'DEBUGGER_ERROR_ANALYTICS' ||
      //   action.type === 'DEBUGGER_DELETE_ERROR_LOG_INIT' ||
      //   action.type === 'DEBUGGER_DELETE_ERROR_LOG' ||
      //   action.type === 'DEBUGGER_LOG' ||
      //   action.type === 'BATCHED_UPDATE'
      // ) {
      //   return _next(action);
      // }
      return next(action);
    };
  };
};

const applyMiddlewareArgs = [sagaMiddleware, routeParamsMiddleware];
if (process.env.REACT_APP_REDUCE_LOGGER) {
  applyMiddlewareArgs.push(logger);
}
const store = createStore(
  appReducer,
  __INSTALL_MODE__
    ? compose(reduxBatch, applyMiddleware(...applyMiddlewareArgs), reduxBatch)
    : composeWithDevTools(
        reduxBatch,
        applyMiddleware(...applyMiddlewareArgs),
        sentryReduxEnhancer,
        reduxBatch
      )
);

if (process.env.NODE_ENV === 'development') {
  window.store = store;
}

export default store;

export const testStore = (initialState: Partial<AppState>) =>
  createStore(
    appReducer,
    initialState,
    compose(
      reduxBatch,
      applyMiddleware(sagaMiddleware, routeParamsMiddleware),
      reduxBatch
    )
  );

sagaMiddleware.run(rootSaga);

export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector;
