import React, { JSXElementConstructor } from 'react';
import { IconProps, IconWrapper } from 'constants/IconConstants';
import WidgetsIcon from 'remixicon-react/FunctionLineIcon';
import PageIcon from 'remixicon-react/PagesLineIcon';
import DefaultHomeIcon from 'remixicon-react/Home3LineIcon';
import CurrentPageIcon from 'remixicon-react/CheckLineIcon';
import EyeOff from 'remixicon-react/EyeOffLineIcon';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';
import SortIcon from 'remixicon-react/ArrowUpDownLineIcon';
import Icon from 'components/icon';

const StyledDataSourcesIconV2 = styled(Icon)`
  g {
    stroke: ${Colors.CHARCOAL};
  }
`;

const StyledJSIconV2 = styled(Icon)`
  path {
    fill: ${Colors.CHARCOAL};
  }
`;

const StyledQueryMain = styled(Icon)`
  path {
    fill: ${Colors.CHARCOAL};
  }
`;

export const MenuIcons: {
  [id: string]: JSXElementConstructor<IconProps>;
} = {
  WIDGETS_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <WidgetsIcon />
    </IconWrapper>
  ),
  JS_ICON: (props: IconProps) => <Icon {...props} name={'js-group'} />,
  JS_FILE_ICON: (props: IconProps) => <Icon {...props} name={'js-file-icon'} />,
  JS_FUNCTION_ICON: (props: IconProps) => (
    <Icon {...props} name={'js-function'} />
  ),
  APIS_ICON: (props: IconProps) => <Icon {...props} name={'api'} />,
  ORG_ICON: (props: IconProps) => <Icon {...props} name={'org'} />,
  PAGES_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <PageIcon />
    </IconWrapper>
  ),
  PAGE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <PageIcon />
    </IconWrapper>
  ),
  DATASOURCES_ICON: (props: IconProps) => (
    <Icon {...props} name={'data-sources'} />
  ),
  QUERIES_ICON: (props: IconProps) => <Icon {...props} name={'queries'} />,
  HOMEPAGE_ICON: (props: IconProps) => <Icon {...props} name={'homepage'} />,
  EXPLORER_ICON: (props: IconProps) => <Icon {...props} name={'explorer'} />,
  DOCS_ICON: (props: IconProps) => <Icon {...props} name="help" />,
  WIDGETS_COLORED_ICON: (props: IconProps) => (
    <Icon {...props} name={'widgets-colored'} />
  ),
  APIS_COLORED_ICON: (props: IconProps) => (
    <Icon {...props} name={'api-colored'} />
  ),
  DATASOURCES_COLORED_ICON: (props: IconProps) => (
    <Icon {...props} name={'datasource-colored'} />
  ),
  DATASOURCES_TABLE_ICON: (props: IconProps) => (
    <Icon {...props} name={'datasource-table'} />
  ),
  PRIMARY_KEY_ICON: (props: IconProps) => (
    <Icon {...props} name={'primary-key'} />
  ),
  FOREIGN_KEY_ICON: (props: IconProps) => (
    <Icon {...props} name={'foreign-key'} />
  ),
  DATASOURCE_COLUMN_ICON: (props: IconProps) => (
    <Icon {...props} name={'datasource-column'} />
  ),
  LINK_ICON: (props: IconProps) => <Icon {...props} name={'link'} />,
  DATASOURCE_ICON_v2: (props: IconProps) => (
    <StyledDataSourcesIconV2 {...props} name={'datasources-2'} />
  ),
  JS_ICON_V2: (props: IconProps) => (
    <StyledJSIconV2 {...props} name={'js-icon'} />
  ),
  DEFAULT_HOMEPAGE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <DefaultHomeIcon />
    </IconWrapper>
  ),
  EYES_OFF_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <EyeOff />
    </IconWrapper>
  ),
  QUERY_MAIN: (props: IconProps) => (
    <StyledQueryMain {...props} name={'query-main'} />
  ),
  CURRENT_PAGE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <CurrentPageIcon />
    </IconWrapper>
  ),
  SORT_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <SortIcon />
    </IconWrapper>
  ),
  CURL_ICON: (props: IconProps) => <Icon {...props} name={'Curl-logo'} />,
};
