import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Input, Message } from '@arco-design/web-react';
import OtpInput from 'react-otp-input';
import styled from 'styled-components';
import cs from 'classnames';
import { LOGIN_CODE_SUBMIT_PATH } from '@appsmith/constants/ApiConstants';
import { isPhone } from 'utils/formhelpers';
import { SpacedSubmitForm, FormActions } from 'pages/UserAuth/StyledComponents';
import { getCurrentUser } from 'selectors/usersSelectors';
import { SETUP } from 'constants/routes';
import api from './api';
import Icon from 'components/icon';
import debounce from 'lodash/debounce';
import { IconLoading } from '@arco-design/web-react/icon';

const LoginPageWrap = styled.div`
  min-height: 420px;
  input::-webkit-input-placeholder {
    font-size: 12px;
  }
  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const SubmitButton = styled.button`
  height: 40px;
  align-self: center;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  background: #1375ff;
  /* background: var(--primary-linear-gradient); */

  &:hover {
    /* background: var(--primary-gradient-hover); */
    background: #4080ff;
  }

  &:active {
    /* background: var(--primary-gradient-active); */
    background: #4080ff;
  }
  &.bg-disable {
    background: var(--disable) !important;
  }
`;

type bindType = 'bindPhone' | 'graphCode' | 'verification' | 'bindPassword';

export function BindTel() {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
  });
  const currentUser = useSelector(getCurrentUser);
  const [submitting, setSubmitting] = useState(false);
  const [loginCodeUrl, setLoginCodeUrl] = useState(
    '/api/v1/' + LOGIN_CODE_SUBMIT_PATH
  );
  const [showCode, setShowCode] = useState(false);
  const [codeTime, setCodeTime] = useState(60);
  const [active, setActive] = useState<bindType>('bindPhone');
  const [validImg, setValidImg] = useState('');
  const [codekey, setCodekey] = useState('');
  const [smsCodekey, setSmsCodekey] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [phoneNum, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [spin, setSpin] = useState(false);

  enum RESPONSECODE {
    'HAS_ACCOUNT_AND_PASSWORD' = '1',
    'HAS_ACCOUNT_BUT_NO_PASSWORD' = '2',
    'NO_ACCOUNT_AND_PASSWORD' = '3',
  }

  if (currentUser?.emptyInstance) {
    return <Redirect to={SETUP} />;
  }

  function handleBindTel(values) {
    if (active === 'bindPhone') {
      setActive('graphCode');
      setSubmitting(true);
      api.getVerity().then((res) => {
        setValidImg(res.data.codePic);
        setCodekey(res.data.codekey);
      });
    } else {
      api
        .checkVerity({ code: code, codekey: codekey })
        .then((res) => {
          if (res.data) {
            setSmsCodekey(res.data);
            handSendCode(res.data);
          } else {
            Message.error(res.responseMeta.error.message);
          }
        })
        .catch((err) => {
          Message.error(err.message);
        });
    }
    setPhone(values.phone);
  }

  function handleCodeLogin(otp) {
    const form = document.getElementById('phone-form');
    api
      .checkCode({ code: otp, phone: form.phone.value })
      .then((res) => {
        if (res.data === RESPONSECODE.HAS_ACCOUNT_AND_PASSWORD) {
          //login
          setSubmitting(true);
          form.submit();
          setSubmitting(false);
        } else if (
          res.data === RESPONSECODE.HAS_ACCOUNT_BUT_NO_PASSWORD ||
          res.data === RESPONSECODE.NO_ACCOUNT_AND_PASSWORD
        ) {
          // fill password
          setActive('bindPassword');
        } else {
          Message.error(res.responseMeta.error.message);
        }
      })
      .catch((err) => {
        Message.error(err.message);
      });
    setSubmitting(false);
  }

  function handSendCode(dataCode) {
    const form = document.getElementById('phone-form');
    const phone = form.phone.value;
    if (phone) {
      setSpin(true);
      api
        .getCode({ phone: phone, smsCodekey: dataCode ? dataCode : smsCodekey })
        .then((res) => {
          if (res.data) {
            setSpin(false);
            setShowCode(true);
            let time = 60;
            setActive('verification');
            const timer = setInterval(() => {
              time--;
              setCodeTime(time);
              if (time <= 0) {
                setShowCode(false);
                clearInterval(timer);
                setCodeTime(60);
              }
            }, 1000);
          } else {
            Message.error(res.responseMeta.error.message);
            setSpin(false);
          }
        });
    } else {
      Message.error('请输入手机号');
    }
  }

  function handleChange(otp) {
    setSmsCode(otp);
    if (otp.length === 6) {
      handleCodeLogin(otp);
    }
  }

  function handleBindPwd() {
    const form = document.getElementById('phone-form');
    if (form.passwordVaild.value === form.password.value) {
      setSubmitting(true);
      form.submit();
      setSubmitting(false);
    } else {
      Message.error('两次密码不一致');
    }
  }

  function renderLoginForm(): JSX.Element {
    const handSendCodeDelay = debounce(() => handSendCode(''), 500);
    if (active === 'bindPhone' || active === 'graphCode') {
      return (
        <SpacedSubmitForm
          action={loginCodeUrl}
          id="phone-form"
          method="POST"
          onSubmit={handleSubmit(handleBindTel)}
        >
          <div className="text-20 text-black-400 mb-[37px]">请绑定手机号</div>
          <div className="hidden">
            <Controller
              control={control}
              name="source"
              render={({ field }) => (
                <Input placeholder="来源" {...field} value="WECHAT" />
              )}
            />
          </div>
          <div className="mb-[24px]">
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <Input
                  height={40}
                  placeholder="输入手机号码"
                  prefix={
                    <Icon
                      name={
                        active === 'bindPhone'
                          ? 'login_phone_icon'
                          : 'login_phone_unactive'
                      }
                      size={16}
                    />
                  }
                  suffix={
                    <span
                      className={cs('text-12 text-orange cursor-pointer', {
                        hidden: active === 'bindPhone',
                      })}
                      onClick={() => {
                        if (active === 'graphCode') {
                          setActive('bindPhone');
                          setSubmitting(false);
                        }
                      }}
                    >
                      更换账号
                    </span>
                  }
                  {...field}
                  readOnly={active === 'graphCode'}
                />
              )}
              rules={{
                required: '请输入手机号码',
                validate: {
                  phone: (v) => isPhone(v) || '手机号码错误',
                },
              }}
            />
            {errors.phone && (
              <p className="text-red absolute text-12">
                {errors.phone.message}
              </p>
            )}
          </div>
          <div className="h-[40px] mb-[36px]">
            <div
              className={cs('flex justify-between items-center', {
                hidden: active === 'bindPhone',
              })}
            >
              <div className="flex-auto mr-1">
                <Controller
                  control={control}
                  name="code"
                  render={({ field }) => (
                    <Input
                      height={40}
                      placeholder="请输入右侧字符"
                      {...field}
                      onChange={(val, event) => {
                        const value = event.target.value;
                        setCode(value);
                        if (value.length >= 4) {
                          setSubmitting(false);
                        } else {
                          setSubmitting(true);
                        }
                      }}
                      value={code}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors.code && (
                  <p className="text-red absolute text-12">
                    {errors.code.message}
                  </p>
                )}
              </div>
              <div
                className="flex-none rounded w-[210px] h-[40px] flex items-center justify-center"
                onClick={() => {
                  api.getVerity().then((res) => {
                    setValidImg(res.data.codePic);
                    setCodekey(res.data.codekey);
                  });
                }}
              >
                <img src={validImg} />
              </div>
            </div>
          </div>
          <FormActions>
            <SubmitButton
              className={cs('font-bold text-16', {
                'bg-disable': submitting,
              })}
              disabled={submitting}
              onClick={() => {
                handleSubmit(handleBindTel);
              }}
              type="submit"
            >
              下一步
            </SubmitButton>
          </FormActions>
        </SpacedSubmitForm>
      );
    }
    if (active === 'verification') {
      return (
        <SpacedSubmitForm
          action={loginCodeUrl}
          id="phone-form"
          method="POST"
          onSubmit={handleSubmit(handleCodeLogin)}
        >
          <div className="hidden">
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <Input
                  placeholder="输入手机号"
                  prefix={<Icon name="login_phone_icon" size={16} />}
                  {...field}
                />
              )}
            />
          </div>
          <div className="hidden">
            <Controller
              control={control}
              name="source"
              render={({ field }) => (
                <Input placeholder="来源" {...field} value="WECHAT" />
              )}
            />
          </div>
          <div className="text-20 text-black-400 text-left mb-[32px]">
            请输入验证码
            <div className="text-14 text-content mt-4">
              已发送至
              <span className="text-title-primary font-bold ml-1">
                {phoneNum}
              </span>
            </div>
          </div>
          <div className="codeNumberWrap">
            <OtpInput
              inputStyle={{
                width: '40px',
                height: '40px',
                marginRight: '28px',
                fontSize: '24px',
                borderRadius: 4,
                border: '1px solid rgba(0,0,0,0.3)',
              }}
              isInputNum
              numInputs={6}
              onChange={handleChange}
              separator={''}
              shouldAutoFocus
              value={smsCode}
            />
            <div className="opacity-0">
              <Controller
                control={control}
                name="smsCode"
                render={({ field }) => (
                  <Input
                    height={40}
                    maxLength={6}
                    placeholder="输入验证码"
                    {...field}
                    readOnly
                    value={smsCode}
                  />
                )}
                rules={{
                  required: '请输入验证码',
                }}
              />
            </div>
            <div>
              {showCode ? (
                <div className="text-12 text-center mt-[32px]">
                  <div className="text-orange inline-block">{codeTime}秒</div>
                  后可重新发送短信验证码
                </div>
              ) : (
                <div
                  className="mt-[32px] text-center text-12 text-orange cursor-pointer"
                  onClick={() => {
                    if (!spin) handSendCodeDelay();
                  }}
                >
                  {spin ? <IconLoading /> : '重新发送'}
                </div>
              )}
            </div>
          </div>
        </SpacedSubmitForm>
      );
    }
    if (active === 'bindPassword') {
      return (
        <SpacedSubmitForm
          action={loginCodeUrl}
          id="phone-form"
          method="POST"
          onSubmit={handleSubmit(handleBindPwd)}
        >
          <div className="text-20 text-black-400 mb-[37px]">
            为您的账号设置密码
          </div>
          <div className="hidden">
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <Input
                  placeholder="输入手机号"
                  prefix={<Icon name="login_phone_icon" size={16} />}
                  {...field}
                  value={phoneNum}
                />
              )}
            />
          </div>
          <div className="hidden">
            <Controller
              control={control}
              name="smsCode"
              render={({ field }) => (
                <Input placeholder="验证码" {...field} value={smsCode} />
              )}
              rules={{
                required: false,
              }}
            />
          </div>
          <div className="hidden">
            <Controller
              control={control}
              name="source"
              render={({ field }) => (
                <Input placeholder="来源" {...field} value="WECHAT" />
              )}
            />
          </div>
          <div className="mb-[24px]">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input.Password
                  height={40}
                  placeholder="输入密码"
                  prefix={<Icon name="iconLock" size={16} />}
                  type="password"
                  {...field}
                />
              )}
              rules={{
                required: '请输入密码',
                validate: {
                  password: (v) =>
                    v.length > 6 || v.length == 6 || '密码长度不能少于6',
                },
              }}
            />
            {errors.password && (
              <p className="text-red absolute text-12">
                {errors.password.message}
              </p>
            )}
          </div>
          <div className="mb-[40px]">
            <Controller
              control={control}
              name="passwordVaild"
              render={({ field }) => (
                <Input.Password
                  height={40}
                  placeholder="确认密码"
                  prefix={<Icon name="iconRetype" size={16} />}
                  type="password"
                  {...field}
                />
              )}
              rules={{
                required: '请确认密码',
              }}
            />
            {errors.passwordVaild && (
              <p className="text-red absolute text-12">
                {errors.passwordVaild.message}
              </p>
            )}
          </div>
          <FormActions>
            <SubmitButton
              className={cs('font-bold text-16', {
                'bg-disable': submitting,
              })}
              disabled={submitting}
              onClick={() => {
                handleSubmit(handleBindPwd);
              }}
              type="submit"
            >
              {submitting ? '请求中..' : '登录'}
            </SubmitButton>
          </FormActions>
        </SpacedSubmitForm>
      );
    }
  }

  return (
    <LoginPageWrap>
      {/* <div className="mb-[66px] text-left flex justify-between items-center">
        <Icon className="!h-[48px]" name="app_logo_login" size={142} />
        <Icon className="!h-[24px]" name="logo-ft" size={101} />
      </div> */}
      <div className=" text-left flex justify-between items-center mb-[33px]">
        <span className="text-[80px]">
          <Icon name="logo" />
        </span>
        <span className="text-[100px] text-[#999999]">
          <Icon name="logo-ft" />
        </span>
      </div>
      {renderLoginForm()}
    </LoginPageWrap>
  );
}

export default BindTel;
