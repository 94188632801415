import React, { useEffect } from 'react';
import { Form, Input, Select, Grid, TreeSelect } from '@arco-design/web-react';

import { CommonFormProps } from './ConfigureDepartmentOrUserForm';
import {
  userFormItemLayout,
  userFormStatusOptions,
  userFormGenderOptions,
} from '../constants';
import { map } from 'lodash';

const FormItem = Form.Item;
const Row = Grid.Row;
const Col = Grid.Col;
const Option = Select.Option;

type CreateUserFromProps = CommonFormProps & {
  onQueryRoles?: VoidFunction;
  roleList?: any[];
};

export function CreateUserForm(props: CreateUserFromProps) {
  const {
    form,
    departmentList = [],
    initialValue,
    isEdit = false,
    onQueryRoles,
    roleList,
  } = props;
  const {
    dept_id,
    emp_id,
    emp_name,
    gender,
    mobile,
    password,
    role_id,
    status,
    username,
  } = initialValue || {};

  useEffect(() => {
    onQueryRoles?.();
  }, []);

  useEffect(() => {
    form.setFieldsValue(initialValue || {});
  }, [initialValue]);

  return (
    <Form form={form} {...userFormItemLayout}>
      <Row gutter={[60, 0]}>
        <Col span={12}>
          <FormItem
            field="empName"
            initialValue={emp_name}
            label="姓名"
            rules={[
              { required: true, message: '姓名不能为空' },
              { maxLength: 20, message: '姓名不超过20位' },
            ]}
          >
            <Input placeholder="请输入用户姓名" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            disabled={isEdit}
            field="username"
            initialValue={username}
            label="登录账号"
            rules={[
              {
                required: true,
                message: '登录账号不能为空',
              },
              {
                maxLength: 20,
                message: '登录账号不超过20位',
              },
            ]}
          >
            <Input placeholder="请输入登录账号" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            field="password"
            initialValue={password}
            label="密码"
            rules={[
              {
                required: true,
                message: '密码不能为空',
              },
              {
                minLength: 6,
                message: '密码长度不能小于6位',
              },
              // { maxLength: 20, message: '密码不超过20位' },
            ]}
          >
            <Input.Password placeholder="请输入密码" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem field="empId" initialValue={emp_id} label="职工号">
            <Input placeholder="请输入职工号" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            field="mobile"
            initialValue={mobile}
            label="手机号"
            rules={[
              {
                match: /^1\d{10}$/,
                message: '请输入11位手机号',
              },
            ]}
          >
            <Input placeholder="请输入手机号" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            field="deptId"
            initialValue={dept_id}
            label="科室"
            rules={[
              {
                required: true,
                message: '科室不能为空',
              },
            ]}
          >
            <TreeSelect
              allowClear
              filterTreeNode={(keyword, node) =>
                node.props.title.toLowerCase().indexOf(keyword.toLowerCase()) >
                -1
              }
              placeholder="请选择科室"
              showSearch
              style={{ width: 250 }}
              treeData={departmentList}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            field="gender"
            initialValue={gender}
            label="性别"
            rules={[
              {
                required: true,
                message: '性别不能为空',
              },
            ]}
          >
            <Select placeholder="请选择用户性别">
              {userFormGenderOptions.map((g) => (
                <Option key={g.value} value={g.value}>
                  {g.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            field="roles"
            initialValue={role_id}
            label="角色"
            rules={[
              {
                required: true,
                message: '角色不能为空',
              },
            ]}
          >
            <Select
              allowClear
              filterOption={(keyword, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(keyword.toLowerCase()) >= 0
              }
              mode="multiple"
              placeholder="请选择角色"
            >
              {map(roleList, (role) => (
                <Option key={role.id} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem field="status" initialValue={status} label="账号状态">
            <Select placeholder="请选择账号状态">
              {userFormStatusOptions.map((s) => (
                <Option key={s.value} value={s.value}>
                  {s.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}
