import React, {
  Component,
  createRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import PageError from 'components/error-page';
import * as Sentry from '@sentry/react';
import log from 'loglevel';
import store from 'store';
import { setAppCrashed } from 'ce/actions/settingsAction';
import { matchPath } from 'react-router';
import { BUILDER_PATH } from 'constants/routes';
import ReactJson from 'react-json-view';
import { Alert, Button, Layout, Spin } from '@arco-design/web-react';
import { getCurrentDSLPageId } from 'selectors/appViewSelectors';
import { useQuery } from 'react-query';
import PageApi from 'api/PageApi';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getEditorConfigs } from 'sagas/selectors';
const Sider = Layout.Sider;
const Content = Layout.Content;

function FactoryEditor({ dsl }) {
  const [showView, setShowView] = useState(true);
  const dslData = useQuery(dsl, () => PageApi.fetchPage({ id: dsl }));
  const editorConfigs = useSelector(getEditorConfigs);
  const json = useMemo(() => get(dslData, 'data.data.layouts[0].dsl', {}), [
    dslData,
  ]);
  const [newDsl, setNewDsl] = useState(json);
  const sava = useCallback((dsl) => {
    // const savePageRequest = getLayoutSavePayload(widgets, editorConfigs);
    dsl?.updated_src && setNewDsl(dsl?.updated_src);
  }, []);

  const refresh = useCallback(() => {
    // const savePageRequest = getLayoutSavePayload(widgets, editorConfigs);
    PageApi.savePage({
      dsl: newDsl,
      ...editorConfigs,
    }).then(() => location.reload());
  }, [newDsl]);

  return (
    <div className="w-full h-[100vh] flex">
      <Layout>
        <Sider
          resizeBoxProps={{
            onMovingStart: () => setShowView(false),
            onMovingEnd: () => setShowView(true),
          }}
          resizeDirections={['right']}
          style={{
            width: '30%',
          }}
        >
          {showView ? (
            <iframe
              className="w-full h-full"
              id="jsoncrackEmbed"
              onLoad={() => {
                const jsonCrackEmbed: unknown = document.getElementById(
                  'jsoncrackEmbed'
                );
                const jsonString = JSON.stringify(json);
                setTimeout(() => {
                  (jsonCrackEmbed as HTMLIFrameElement).contentWindow.postMessage(
                    {
                      json: jsonString,
                    },
                    '*'
                  );
                }, 500);
              }}
              src="https://jsoncrack.com/widget"
            />
          ) : (
            <Spin
              className="w-full h-full flex justify-center items-center"
              size={40}
            />
          )}
        </Sider>
        <Content>
          <Alert
            className="flex justify-between"
            content={
              <div>
                请做好数据备份后再谨慎修改,数据丢失概不负责!
                <Button className="mx-[30px]" onClick={refresh} type="primary">
                  提交页面数据
                </Button>
                <Button onClick={() => dslData.refetch()}>重新获取数据</Button>
              </div>
            }
            type="warning"
          />
          <ReactJson
            onAdd={sava}
            onDelete={sava}
            onEdit={sava}
            src={json}
            style={{
              height: 'calc(100% - 50px)',
              width: '100%',
              overflow: 'overlay',
            }}
          />
        </Content>
      </Layout>
    </div>
  );
}

class AppErrorBoundary extends Component {
  state = {
    hasError: false,
    dsl: null,
    factoryMode: false,
  };

  static getDerivedStateFromError() {
    let dsl;
    if (matchPath(location.pathname, { path: BUILDER_PATH })) {
      dsl = getCurrentDSLPageId(store.getState());
    } else {
      dsl = null;
    }
    return {
      hasError: true,
      dsl,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    log.error({ error, errorInfo });
    Sentry.captureException(error);
    /**
     * NOTE: In here, we set global state "isAppCrashed" to true
     */
    store.dispatch(setAppCrashed());
  }

  render() {
    if (this.state.hasError) {
      if (this.state.dsl && this.state.factoryMode) {
        return <FactoryEditor dsl={this.state.dsl} />;
      }
      return (
        <>
          <Button
            className="fixed right-[20px] top-[20px]"
            hidden={!this.state.dsl}
            onClick={() => {
              this.setState({
                factoryMode: true,
              });
            }}
            size="mini"
            type="text"
          >
            进入页面工厂模式
          </Button>
          <PageError />
        </>
      );
    }
    return this.props.children;
  }
}

export default AppErrorBoundary;
