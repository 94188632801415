import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Message,
  Radio,
  Upload,
  Grid,
} from '@arco-design/web-react';
import { EnterpriseAuthForm } from '../interface';
import { AuthStatusEnum, MAX_LICENSE_SIZE, ORG_TYPES } from '../constants';
import ResourceApi from 'api/ResourceApi';
import { UploadItem } from '@arco-design/web-react/es/Upload';
import { validateResponseHelper } from 'pages/Editor/AppSysConfigDrawer/utils';
import { get, pick } from 'lodash';
import { UploadWrapper } from '../styles';

const FormItem = Form.Item;
const Row = Grid.Row;
const Col = Grid.Col;

interface Props {
  initFormValues?: EnterpriseAuthForm;
  onRegisterCompany?: (values: EnterpriseAuthForm) => void;
  loading?: boolean;
}

export function AuthForm(props: Props) {
  const { initFormValues, loading = false, onRegisterCompany } = props;
  const [form] = Form.useForm<EnterpriseAuthForm | null>(null);
  const [manualFileList, setManualFileList] = useState<UploadItem[]>([]);

  const { status = AuthStatusEnum.init } = initFormValues || {};

  // const isAcceptFile = (file, accept) => {
  //   if (accept && file) {
  //     const accepts = Array.isArray(accept)
  //       ? accept
  //       : accept
  //           .split(',')
  //           .map((x) => x.trim())
  //           .filter((x) => x);
  //     const fileExtension =
  //       file.name.indexOf('.') > -1 ? file.name.split('.').pop() : '';

  //     return accepts.some((type) => {
  //       const text = type && type.toLowerCase();
  //       const fileType = (file?.type || '').toLowerCase();
  //       if (text === fileType) {
  //         // 类似excel文件这种
  //         // 比如application/vnd.ms-excel和application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  //         // 本身就带有.字符的，不能走下面的.jpg等文件扩展名判断处理
  //         // 所以优先对比input的accept类型和文件对象的type值
  //         return true;
  //       }
  //       // if (//*/.test(text)) {
  //       //   // image/* 这种通配的形式处理
  //       //   return fileType.replace(//.*$/, '') === text.replace(//.*$/, '');
  //       // }

  //       if (/..*/.test(text)) {
  //         // .jpg 等后缀名
  //         return text === `.${fileExtension && fileExtension.toLowerCase()}`;
  //       }
  //       return false;
  //     });
  //   }

  //   return !!file;
  // };

  const handleCheckBeforeUpload = (file: File) => {
    return new Promise((resolve, reject) => {
      if (file.size > MAX_LICENSE_SIZE) {
        Message.info('图片尺寸不能超过2M');

        return reject(false);
      }

      resolve(true);
    });
  };

  const handleRegisterCompany = async () => {
    await form.validate();

    const fieldValues = form.getFieldsValue();

    onRegisterCompany?.(fieldValues);
  };

  const handleUploadFile = async (files: UploadItem[]) => {
    try {
      if (!files?.length) return;

      const curFile: Record<string, any> = files[0];
      const { name, originFile } = curFile || {};

      const postData = {
        fileName: name,
        file: originFile,
      };

      const res = await ResourceApi.createResource(postData);

      if (validateResponseHelper(res?.responseMeta)) {
        Message.success('图片上传成功');

        const uploadedUrl = get(res, 'data.url', null);

        if (uploadedUrl) {
          setManualFileList([
            {
              uid: `${Date.now()}`,
              url: uploadedUrl,
            },
          ]);
          form.setFieldValue('license', uploadedUrl);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    if (initFormValues) {
      form.setFieldsValue(
        pick(initFormValues, [
          'address',
          'contactName',
          'contactNo',
          'orgType',
          'orgName',
          'orgScale',
          'phone',
          'license',
        ])
      );

      if (initFormValues.license) {
        setManualFileList([
          {
            uid: `${Date.now()}`,
            url: initFormValues.license,
          },
        ]);
      }
    }
  }, [initFormValues]);
  const isDisableEdit =
    status === AuthStatusEnum.authenticated ||
    status === AuthStatusEnum.authenticating;

  return (
    <Row className="pt-6">
      <Col span={16}>
        <Form disabled={isDisableEdit} form={form} layout="vertical">
          <FormItem
            field="orgType"
            initialValue={ORG_TYPES[0].value}
            label="机构类型"
          >
            <Radio.Group name="button-radio-group">
              {ORG_TYPES.map((item) => {
                return (
                  <Radio key={item.value} value={item.value}>
                    {({ checked }) => {
                      return (
                        <Button
                          key={item.value}
                          tabIndex={-1}
                          type={checked ? 'primary' : 'default'}
                        >
                          {item.label}
                        </Button>
                      );
                    }}
                  </Radio>
                );
              })}
            </Radio.Group>
          </FormItem>
          <FormItem field="orgName" label="机构名称">
            <Input placeholder="请输入机构名称" />
          </FormItem>
          <FormItem field="orgScale" label="机构规模(人数)">
            <Input placeholder="请输入机构员工人数" />
          </FormItem>
          <FormItem field="address" label="机构地址">
            <Input placeholder="请输入机构详细地址" />
          </FormItem>
          <FormItem field="contactName" label="联系人姓名">
            <Input placeholder="请输入联系人姓名" />
          </FormItem>
          <FormItem field="contactNo" label="联系人身份证">
            <Input placeholder="请输入联系人身份证号" />
          </FormItem>
          <FormItem field="phone" label="联系人电话(座机/手机)">
            <Input placeholder="请输入联系人电话" />
          </FormItem>

          <FormItem
            extra={
              <span className="text-[#999] text-[12px]">
                请提交加盖公章的营业执照，支持JPG、PNG、PDF格式，2M以内
              </span>
            }
            field="license"
            // initialValue={initialValues?.license}
            label="营业执照"
          >
            <UploadWrapper>
              <Upload
                accept="image/*"
                autoUpload={false}
                beforeUpload={handleCheckBeforeUpload}
                disabled={isDisableEdit}
                fileList={manualFileList}
                imagePreview
                limit={1}
                listType="picture-card"
                onChange={handleUploadFile}
                onRemove={() => setManualFileList([])}
                style={{
                  width: 160,
                  height: 160,
                }}
              />
            </UploadWrapper>
          </FormItem>
        </Form>
      </Col>
      <Col className="pt-10 text-right" span={24}>
        <Button
          disabled={!!initFormValues}
          loading={loading}
          onClick={handleRegisterCompany}
          type="primary"
        >
          提交
        </Button>
        {/* <Button type="primary">撤销</Button> */}
      </Col>
    </Row>
  );
}
