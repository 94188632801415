import { useEffect, useRef } from 'react';
import { isNull } from 'lodash';

export const useInterval = (cb: CallableFunction, delay?: number | null) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const savedCallback = useRef<CallableFunction>(() => {});

  useEffect(() => {
    savedCallback.current = cb;
  });

  useEffect(() => {
    if (!isNull(delay)) {
      const intervalId = setInterval(() => savedCallback.current(), delay || 0);

      return () => clearInterval(intervalId);
    }

    return undefined;
  }, [delay]);
};
