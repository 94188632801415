import Guide from 'byte-guide';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'selectors/usersSelectors';

function FSGuide(props) {
  const { localKey } = props;
  const user = useSelector(getCurrentUser);
  const [isStartGuide, setIsStartGuide] = useState(false);
  useEffect(() => {
    if (user) {
      setIsStartGuide(!localStorage.getItem(user?.keyId + '-' + localKey));
      localStorage.setItem(user?.keyId + '-' + localKey, 'true');
    }
  }, [user]);
  return isStartGuide ? (
    <Guide
      {...props}
      closeEle={' '}
      localKey={undefined}
      visible={isStartGuide}
    />
  ) : null;
}

export default FSGuide;
