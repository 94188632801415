import { TreeDataType } from '@arco-design/web-react/es/Tree/interface';
import { FormInstance } from '@arco-design/web-react';
import { MODAL_MAP } from './constants';

/**
 * common
 */
export const enum QueryActionTableNameParams {
  MG_USER = 'MG_USER',
  MG_DEPT = 'MG_DEPT',
}
/**
 * manage exception page
 */

/**
 * manage static files
 */

/**
 * manage landing page
 */

/**
 * manage role
 */

/**
 * manage theme
 */

/**
 * manage user
 */
export interface DepartmentItem {
  parentId: string;
  deptId: string;
  deptName: string;
  children?: any[];
}

export type ModalType = keyof typeof MODAL_MAP;

export interface UserListSearchFormFields {
  emp_name: string;
  emp_id: string;
}

export interface ModalRenderParams {
  form: FormInstance;
  initialValue: Record<string, any>;
  isEdit?: boolean;
  departmentList?: TreeDataType[];
  dataSourceOptions?: any[];
  roleList?: any[];
  onQueryRoles?: VoidFunction;
}

export const enum LoginThemeEnum {
  GeneralYellowish = 'one',
  GeneralBlue = 'two',
  GeneralWathet = 'three',
  GeneralOrigin = 'four',
  VisitGreen = 'five', // 随访绿
  BudgetPurple = 'six', // 预算紫
  PerformanceOrigin = 'seven', // 绩效橘
  LogisticsOrigin = 'eight', // 后勤橙
  BadnessBlue = 'nine', // 不良蓝
  AssetCyan = 'ten', // 资产青
}

export interface LoginViewProps {
  theme: LoginThemeEnum;
  // arco form fields
  isApplyLogo?: boolean;
  appName?: string;
  textColor?: string;
  loginTips?: string;
  btnColor?: string;

  logoUrl?: string;
  loginBg?: string;
}

export interface LoginPropertyProps {
  enableSSO?: boolean;
}
