import { Button, Checkbox, Input, Switch, Tag } from '@arco-design/web-react';
import {
  cloneDeep,
  filter,
  forEach,
  get,
  includes,
  map,
  uniqueId,
} from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Select } from '@arco-design/web-react';
import { IconMinus, IconPlus } from '@arco-design/web-react/icon';
const Option = Select.Option;
const InputSearch = Input.Search;

class Group {
  op;
  groupOp;
  list: GroupList[];
  id;
  constructor() {
    this.op = 'and';
    this.groupOp = '';
    this.list = [new GroupList()];
    this.id = uniqueId();
  }
}

class GroupList {
  field;
  alias;
  op;
  column;
  columnEnabled;
  timeunit;
  value;
  type;
  id;
  constructor() {
    this.id = uniqueId();
  }
}

export default function DuplicateRemovalContent({ data, fields, meta, modal }) {
  const { fields: tableFields, sql, templateSQL } = get(meta, 'tableData');
  const { id, type } = data.previousData.nodeProperty;

  const [fieldsList, setFieldsList] = useState(cloneDeep(tableFields));
  const selectedFields = useMemo(
    () => filter(fieldsList, (v) => v.checked && !v.hidden),
    [fieldsList]
  );

  const filterID = useRef(meta.FormInfo?.filterID || uniqueId('util_')).current;

  useEffect(() => {
    if (meta.FormInfo?.selectedFields) {
      const selectedFieldsMap = [];
      forEach(meta.FormInfo?.selectedFields, (v) => {
        selectedFieldsMap.push(v.field);
      });
      forEach(fieldsList, (v) => {
        // includes(meta.FormInfo?.selectedFields, {});
        if (includes(selectedFieldsMap, v.field)) {
          v.checked = true;
        }
      });
      setFieldsList([...fieldsList]);
    }
  }, []);
  useEffect(() => {
    meta.FormInfo = {
      selectedFields,
      filterID,
    };
  }, [selectedFields, filterID]);
  const { name } = fields;

  const queryParams = {
    inputs: [
      {
        id: id,
        sql: sql,
        tableName: name,
        templateSQL: templateSQL,
        type: type,
      },
    ],
    current: {
      type: 'distinct',
      id: filterID,
      select: fieldsList.map((v) => {
        const field = filterID + '.' + v.field;
        return {
          ...v,
          field,
        };
      }),
      settings: JSON.stringify({
        select: selectedFields.map((v) => {
          const field = filterID + '.' + v.field;
          return {
            ...v,
            field,
          };
        }),
        select2: [],
      }),
    },
  };

  const [sreachText, setSreachText] = useState('');

  return (
    <div className="filter-content">
      <div className="flex">
        <div className="w-[100%] font-hm-16-bold ">
          <div>
            <span>显示列设置</span>
          </div>
          <div>
            <p className="mt-[10px]">{name}</p>
            <div className="flex items-center">
              <InputSearch
                onChange={(val) => setSreachText(val)}
                onSearch={() => {
                  setFieldsList(
                    fieldsList.map((v) => {
                      return {
                        ...v,
                        hidden: !v.alias.includes(sreachText),
                      };
                    })
                  );
                }}
                placeholder="搜素字段名"
                searchButton
                style={{ width: 200, margin: 12 }}
              />
              <Checkbox
                onChange={(flag: boolean) => {
                  setFieldsList(
                    map(fieldsList, (v) => ({ ...v, checked: flag }))
                  );
                }}
              >
                全选
              </Checkbox>
              <Checkbox
                onChange={() => {
                  setFieldsList(
                    map(fieldsList, (v) => ({
                      ...v,
                      checked: !v.checked,
                    }))
                  );
                }}
              >
                反选
              </Checkbox>
            </div>
          </div>
          <div className="filed-h my-[10px]">
            {fieldsList.map((v) =>
              v.hidden ? null : (
                <Tag
                  checkable
                  checked={v.checked}
                  className="m-[5px] border border-primary"
                  color="#FF5800"
                  key={v.field}
                  onCheck={(flag) => {
                    v.checked = flag;
                    setFieldsList([...fieldsList]);
                  }}
                >
                  {v.alias}
                </Tag>
              )
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="mr-[30px]"
          onClick={() => {
            modal.current.close();
            modal.ok(queryParams);
          }}
          type="primary"
        >
          提交
        </Button>
        <Button onClick={() => modal.current.close()} type="secondary">
          取消
        </Button>
      </div>
    </div>
  );
}
