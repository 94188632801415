import { Image, Skeleton } from '@arco-design/web-react';
import React, { useState } from 'react';
import styled from 'styled-components';
const ImageBox = styled(Image)`
  .arco-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export default function SkeletonImg({ skeletonProps = {}, imgProps = {} }) {
  return (
    <ImageBox {...imgProps} loader={<Skeleton {...skeletonProps} />} simple />
  );
}
