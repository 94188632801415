export function createMessage(
  format: (...strArgs: any[]) => string,
  ...args: any[]
) {
  return format(...args);
}
export * from './lang/app';
export * from './lang/commons';

enum TypeString {
  STRING = '字符串',
  UNDEFINED = '空',
}

export const PAGE_PROERTIES = () => '页面配置';
export const YES = () => `对`;
export const ERROR_MESSAGE_SELECT_ACTION = () => `请选择一个操作`;
export const ERROR_MESSAGE_SELECT_ACTION_TYPE = () => `请选择一种操作类型`;
export const ERROR_ADD_API_INVALID_URL = () =>
  `无法创建API。尝试向数据源添加URL`;
export const ERROR_MESSAGE_NAME_EMPTY = () => `请选择一个名称`;
export const ERROR_MESSAGE_CREATE_APPLICATION = () => `我们无法创建应用程序`;
export const APPLICATION_NAME_UPDATE = () => `应用程序名称已更新`;
export const ERROR_EMPTY_APPLICATION_NAME = () => `应用程序名称不能为空`;
export const API_PATH_START_WITH_SLASH_ERROR = () => `路径不能以开头/`;
export const FIELD_REQUIRED_ERROR = () => `此字段为必填字段`;
export const INPUT_DEFAULT_TEXT_MAX_CHAR_ERROR = () =>
  `默认文本长度必须小于允许的最大字符数`;
export const VALID_FUNCTION_NAME_ERROR = () =>
  `必须是有效的变量名（camelCase）`;
export const UNIQUE_NAME_ERROR = () => `名称必须是唯一的`;
export const NAME_SPACE_ERROR = () => `名称不能有空格`;

export const FORM_VALIDATION_EMPTY_EMAIL = () => `请输入电子邮件`;
export const FORM_VALIDATION_INVALID_EMAIL = () => `请提供有效的电子邮件地址`;
export const ENTER_VIDEO_URL = () => `请提供有效的url`;
export const ENTER_AUDIO_URL = () => `请提供有效的url`;

export const FORM_VALIDATION_EMPTY_PASSWORD = () => `请输入密码`;
export const FORM_VALIDATION_PASSWORD_RULE = () => `请提供6到42个字符的密码`;
export const FORM_VALIDATION_INVALID_PASSWORD = FORM_VALIDATION_PASSWORD_RULE;

export const LOGIN_PAGE_SUBTITLE = () => `使用你的组织电子邮件`;
export const LOGIN_PAGE_TITLE = () => `福鑫紫薇平台`;
export const LOGIN_PAGE_EMAIL_INPUT_LABEL = () => `邮箱`;
export const LOGIN_PAGE_PASSWORD_INPUT_LABEL = () => `密码`;
export const LOGIN_PAGE_EMAIL_INPUT_PLACEHOLDER = () => `输入您的电子邮件地址`;
export const LOGIN_PAGE_PASSWORD_INPUT_PLACEHOLDER = () => `输入密码`;
export const LOGIN_PAGE_INVALID_CREDS_ERROR = () =>
  `帐号或密码错误，请重试或重置密码`;
export const LOGIN_PAGE_INVALID_CREDS_FORGOT_PASSWORD_LINK = () => `重置密码`;
export const NEW_TO_APPSMITH = () => `阿普史密斯是新来的吗`;

export const LOGIN_PAGE_LOGIN_BUTTON_TEXT = () => `登录`;
export const LOGIN_PAGE_FORGOT_PASSWORD_TEXT = () => `忘记密码`;
export const LOGIN_PAGE_REMEMBER_ME_LABEL = () => `记得吗`;
export const LOGIN_PAGE_SIGN_UP_LINK_TEXT = () => `注册`;
export const SIGNUP_PAGE_TITLE = () => `创建你的免费帐户`;
export const SIGNUP_PAGE_SUBTITLE = () => `使用你的组织电子邮件`;
export const SIGNUP_PAGE_EMAIL_INPUT_LABEL = () => `邮箱`;
export const SIGNUP_PAGE_EMAIL_INPUT_PLACEHOLDER = () => `邮箱`;
export const SIGNUP_PAGE_NAME_INPUT_PLACEHOLDER = () => `名字`;
export const SIGNUP_PAGE_NAME_INPUT_LABEL = () => `名字`;
export const SIGNUP_PAGE_PASSWORD_INPUT_LABEL = () => `密码`;
export const SIGNUP_PAGE_PASSWORD_INPUT_PLACEHOLDER = () => `密码`;
export const SIGNUP_PAGE_LOGIN_LINK_TEXT = () => `登录`;
export const SIGNUP_PAGE_NAME_INPUT_SUBTEXT = () => `我们该怎么称呼你`;
export const SIGNUP_PAGE_SUBMIT_BUTTON_TEXT = () => `注册`;
export const ALREADY_HAVE_AN_ACCOUNT = () => `已经有账户了吗`;

export const SIGNUP_PAGE_SUCCESS = () => `太棒了！您已成功注册`;
export const SIGNUP_PAGE_SUCCESS_LOGIN_BUTTON_TEXT = () => `登录`;

export const RESET_PASSWORD_PAGE_PASSWORD_INPUT_LABEL = () => `新密码`;
export const RESET_PASSWORD_PAGE_PASSWORD_INPUT_PLACEHOLDER = () => `新密码`;
export const RESET_PASSWORD_LOGIN_LINK_TEXT = () => `返回登录`;
export const RESET_PASSWORD_PAGE_TITLE = () => `找回密码`;
export const RESET_PASSWORD_SUBMIT_BUTTON_TEXT = () => `重置`;
export const RESET_PASSWORD_PAGE_SUBTITLE = () => `为您的帐户创建新密码`;

export const RESET_PASSWORD_RESET_SUCCESS = () => `您的密码已被重置`; //`您的密码已被重置。请登录` (see next entry));
export const RESET_PASSWORD_RESET_SUCCESS_LOGIN_LINK = () => `登录`;

export const RESET_PASSWORD_EXPIRED_TOKEN = () =>
  `密码重置链接已过期。请尝试生成一个新链接`;
export const RESET_PASSWORD_INVALID_TOKEN = () =>
  `密码重置链接无效。请尝试生成一个新链接`;
export const RESET_PASSWORD_FORGOT_PASSWORD_LINK = () => `忘记密码`;

export const FORGOT_PASSWORD_PAGE_EMAIL_INPUT_LABEL = () => `邮箱`;
export const FORGOT_PASSWORD_PAGE_EMAIL_INPUT_PLACEHOLDER = () => `邮箱`;
export const FORGOT_PASSWORD_PAGE_TITLE = () => `重置密码`;
export const FORGOT_PASSWORD_PAGE_SUBTITLE = () =>
  `我们将向下面的电子邮件发送重置链接`;
export const FORGOT_PASSWORD_PAGE_SUBMIT_BUTTON_TEXT = () => `重置`;
export const FORGOT_PASSWORD_SUCCESS_TEXT = (email: string) =>
  `密码重置链接已发送到您在紫微平台注册的电子邮件地址${email}`;

export const PRIVACY_POLICY_LINK = () => `隐私政策`;
export const TERMS_AND_CONDITIONS_LINK = () => `条款和条件`;

export const ERROR_500 = () => `服务器开小差了，我们正在解决`;
export const ERROR_0 = () => `我们无法连接到服务器。请检查您的网络连接`;
export const ERROR_401 = () => `我们无法确认你的身份。请重新登录`;
export const ERROR_403 = () => `拒绝许可。请联系您的管理员以获取访问权限`;
export const URL_HTTP_VALIDATION_ERROR = () => `请输入有效的URL`;
export const NAVIGATE_TO_VALIDATION_ERROR = () => `请输入有效的URL或页面名称`;
export const PAGE_NOT_FOUND_ERROR = () => `您要查找的页面不存在或找不到`;
export const INVALID_URL_ERROR = () => `无效的URL`;

export const INVITE_USERS_VALIDATION_EMAIL_LIST = () =>
  `发现无效的电子邮件地址`;
export const INVITE_USERS_VALIDATION_ROLE_EMPTY = () => `请选择一个角色`;

export const INVITE_USERS_EMAIL_LIST_PLACEHOLDER = () => `逗号分隔的电子邮件`;
export const INVITE_USERS_ROLE_SELECT_PLACEHOLDER = () => `选择角色`;
export const INVITE_USERS_ROLE_SELECT_LABEL = () => `角色`;
export const INVITE_USERS_EMAIL_LIST_LABEL = () => `用户电子邮件`;
export const INVITE_USERS_ADD_EMAIL_LIST_FIELD = () => `添加更多`;
export const INVITE_USERS_SUBMIT_BUTTON_TEXT = () => `邀请用户`;
export const INVITE_USERS_SUBMIT_ERROR = () => `我们无法邀请用户，请稍后再试`;
export const INVITE_USERS_SUBMIT_SUCCESS = () => `用户已被成功邀请`;
export const INVITE_USER_SUBMIT_SUCCESS = () => `已成功邀请用户`;
export const INVITE_USERS_VALIDATION_EMAILS_EMPTY = () => `请输入用户电子邮件`;

export const CREATE_PASSWORD_PAGE_PASSWORD_INPUT_LABEL = () => `新密码`;
export const CREATE_PASSWORD_PAGE_PASSWORD_INPUT_PLACEHOLDER = () => `新密码`;
export const CREATE_PASSWORD_LOGIN_LINK_TEXT = () => `你已经知道密码了吗？登录`;
export const CREATE_PASSWORD_PAGE_TITLE = () => `设置密码`;
export const CREATE_PASSWORD_SUBMIT_BUTTON_TEXT = () => `创造`;
export const CREATE_PASSWORD_PAGE_SUBTITLE = () => `为你的帐户设置新密码`;

export const CREATE_PASSWORD_RESET_SUCCESS = () => `你的密码已经设置好了`;
export const CREATE_PASSWORD_RESET_SUCCESS_LOGIN_LINK = () => `登录`;

export const CREATE_PASSWORD_EXPIRED_TOKEN = () =>
  `邀请链接已过期。请尝试请求新的邀请`;
export const CREATE_PASSWORD_INVALID_TOKEN = () =>
  `邀请链接无效。请尝试请求新邀请`;

export const DELETING_APPLICATION = () => `应用已删除`;
export const DUPLICATING_APPLICATION = () => `复制应用程序`;
export const CREATE_AN_APPLICATION_WITH_A_TEMPLATE = () => `以模版创建应用程序`;
export const PUBLISH_APPLICATION_TO_THE_APPLICATION_MARKET = () =>
  `发布应用程序到应用市场`;

export const CURL_IMPORT_SUCCESS = () => `Curl命令导入成功`;
export const FORGOT_PASSWORD_PAGE_LOGIN_LINK = () => `返回登录`;
export const ADD_API_TO_PAGE_SUCCESS_MESSAGE = (actionName: string) =>
  `${actionName}API已添加到页面`;
// export const INPUT_WIDGET_DEFAULT_VALIDATION_ERROR = () => `无效输入`;
export const INPUT_WIDGET_DEFAULT_VALIDATION_ERROR = () => `输入不合法`;
export const EMAIL_INPUT_WIDGET_DEFAULT_VALIDATION_ERROR = () =>
  `邮箱格式不合法`;

export const AUTOFIT_ALL_COLUMNS = () => `自动调整所有列`;
export const AUTOFIT_THIS_COLUMN = () => `自动调整此列`;
export const AUTOFIT_COLUMN = () => `自动装配柱`;

export const DATE_WIDGET_DEFAULT_VALIDATION_ERROR = () => `日期超出范围`;

export const TIMEZONE = () => `时区`;
export const ENABLE_TIME = () => `启用时间`;

export const EDIT_APP = () => `编辑应用`;
export const FORK_APP = () => `Fork应用程序`;
export const SIGN_IN = () => `登录`;

// Homepage
export const CREATE_NEW_APPLICATION = () => `创造新的`;
export const SEARCH_APPS = () => `搜索应用程序`;
export const GETTING_STARTED = () => `开始`;
export const ORGANIZATIONS_HEADING = () => `团队项目`;
export const WELCOME_TOUR = () => `新手教程`;
export const NO_APPS_FOUND = () => `未找到此应用`;

// Lightning menu
export const LIGHTNING_MENU_DATA_API = () => `使用API中的数据`;
export const LIGHTNING_MENU_DATA_QUERY = () => `使用查询中的数据`;
export const LIGHTNING_MENU_DATA_TOOLTIP = () => `快速启动数据绑定`;
export const LIGHTNING_MENU_DATA_WIDGET = () => `使用小组件中的数据`;
export const LIGHTNING_MENU_QUERY_CREATE_NEW = () => `创建新查询`;
export const LIGHTNING_MENU_API_CREATE_NEW = () => `创建新的API`;

export const LIGHTNING_MENU_OPTION_TEXT = () => `纯文本`;
export const LIGHTNING_MENU_OPTION_JS = () => `写JS`;
export const LIGHTNING_MENU_OPTION_HTML = () => `编写HTML`;
export const CHECK_REQUEST_BODY = () => `请检查要调试的请求配置`;
export const DONT_SHOW_THIS_AGAIN = () => `不要再展示了`;
export const SHOW_REQUEST = () => `显示请求`;
export const MEMBERS = () => '成员';
export const TABLE_FILTER_COLUMN_TYPE_CALLOUT = () =>
  `更改列数据类型以查看筛选器运算符`;

export const SAVE_HOTKEY_TOASTER_MESSAGE = () => `已保存`;

export const WIDGET_SIDEBAR_TITLE = () => `小组件`;
export const WIDGET_SIDEBAR_CAPTION = () => `拖动组件到画布`;
export const GOOGLE_RECAPTCHA_KEY_ERROR = () =>
  `谷歌重新验证码令牌生成失败！请检查重新验证码站点密钥`;
export const GOOGLE_RECAPTCHA_DOMAIN_ERROR = () =>
  `谷歌重新验证码令牌生成失败！请检查允许的域`;

export const SERVER_API_TIMEOUT_ERROR = () =>
  `服务器的响应时间太长。请稍后再试`;
export const DEFAULT_ERROR_MESSAGE = () => `发生了一个未知的错误`;
export const REMOVE_FILE_TOOL_TIP = () => `删除上传`;
export const ERROR_FILE_TOO_LARGE = (fileSize: string) =>
  `文件大小应小于${fileSize}`;
export const ERROR_DATEPICKER_MIN_DATE = () =>
  `最小日期不能大于当前窗口小组件值`;
export const ERROR_DATEPICKER_MAX_DATE = () =>
  `最小日期不能大于当前窗口小组件值`;
export const ERROR_WIDGET_DOWNLOAD = (err: string) => `下载失败${err}`;
export const ERROR_PLUGIN_ACTION_EXECUTE = (actionName: string) =>
  `${actionName}未能执行`;
export const ERROR_FAIL_ON_PAGE_LOAD_ACTIONS = () =>
  `无法在页面加载期间执行操作`;
export const ERROR_ACTION_EXECUTE_FAIL = (actionName: string) =>
  `${actionName}操作返回了错误响应`;
export const ACTION_MOVE_SUCCESS = (actionName: string, pageName: string) =>
  `${actionName}操作已成功移动到 ${pageName}`;
export const ERROR_ACTION_MOVE_FAIL = (actionName: string) =>
  `移动操作${actionName}时出错`;
export const ACTION_COPY_SUCCESS = (actionName: string, pageName: string) =>
  `${actionName}操作已成功复制到页面‘${pageName}’`;
export const ERROR_ACTION_COPY_FAIL = (actionName: string) =>
  `复制操作${actionName}时出错`;
export const ERROR_ACTION_RENAME_FAIL = (actionName: string) =>
  `无法将操作名称更新为${actionName}`;

// Action Names Messages
export const ACTION_NAME_PLACEHOLDER = (type: string) =>
  `camelCase中${type}的名称`;
export const ACTION_INVALID_NAME_ERROR = () => `请输入有效的名称`;
export const ACTION_NAME_CONFLICT_ERROR = (name: string) =>
  `${name}已被使用或是受限关键字`;
export const ENTITY_EXPLORER_ACTION_NAME_CONFLICT_ERROR = (name: string) =>
  `${name}已被使用`;

export const DATASOURCE_CREATE = (dsName: string) => `${dsName}已创建数据源`;
export const DATASOURCE_DELETE = (dsName: string) =>
  `${dsName}数据源已成功删除`;
export const DATASOURCE_UPDATE = (dsName: string) =>
  `${dsName}数据源已成功更新`;
export const DATASOURCE_COPY = (dsName: string) => `${dsName}数据源已成功复制`;
export const DATASOURCE_VALID = (dsName: string) => `${dsName}数据源有效`;
export const EDIT_DATASOURCE = () => `编辑数据源`;
export const SAVE_DATASOURCE = () => `保存数据源`;
export const SAVE_DATASOURCE_MESSAGE = () => `将API另存为数据源以设置身份验证`;
export const EDIT_DATASOURCE_MESSAGE = () => `编辑数据源以访问身份验证设置`;
export const OAUTH_ERROR = () => `OAuth错误`;
export const OAUTH_2_0 = () => `OAuth 2.0`;
export const ENABLE = () => `使能`;
export const UPGRADE = () => `升级`;
export const EDIT = () => `编辑`;

export const ERROR_EVAL_ERROR_GENERIC = () => `评估应用程序时发生意外错误`;

export const ERROR_EVAL_TRIGGER = (message: string) =>
  `计算触发器时出错：${message}`;

export const WIDGET_COPY = (widgetName: string) => `已复制${widgetName}`;
export const ERROR_WIDGET_COPY_NO_WIDGET_SELECTED = () =>
  `请选择要复制的小组件`;
export const ERROR_WIDGET_COPY_NOT_ALLOWED = () => `无法复制此选定的小组件`;
export const WIDGET_CUT = (widgetName: string) => `剪切${widgetName}`;
export const ERROR_WIDGET_CUT_NO_WIDGET_SELECTED = () => `请选择要剪切的小组件`;
export const SELECT_ALL_WIDGETS_MSG = () =>
  `此页面中包括modals在内的所有小组件都已被选中`;
export const ERROR_ADD_WIDGET_FROM_QUERY = () => `未能添加小组件`;

export const REST_API_AUTHORIZATION_SUCCESSFUL = () => `授权成功`;
export const REST_API_AUTHORIZATION_FAILED = () =>
  `授权失败。请检查您的详细信息或重试`;
// Todo: improve this for 紫薇平台_error error message
export const REST_API_AUTHORIZATION_APPSMITH_ERROR = () => `出了点问题`;

export const OAUTH_AUTHORIZATION_SUCCESSFUL = `授权成功`;
export const OAUTH_AUTHORIZATION_FAILED = `授权失败。请检查您的详细信息或重试`;
// Todo: improve this for 紫薇平台_error error message
export const OAUTH_AUTHORIZATION_APPSMITH_ERROR = `出了点问题`;
export const OAUTH_APPSMITH_TOKEN_NOT_FOUND = `找不到token`;

export const LOCAL_STORAGE_QUOTA_EXCEEDED_MESSAGE = () =>
  `在localStorage中保存密钥时出错。您已超过允许的存储大小限制`;
export const LOCAL_STORAGE_NO_SPACE_LEFT_ON_DEVICE_MESSAGE = () =>
  `在localStorage中保存密钥时出错。磁盘空间不足`;
export const LOCAL_STORAGE_NOT_SUPPORTED_APP_MIGHT_NOT_WORK_AS_EXPECTED = () =>
  `您的设备不支持本地存储。包括紫微平台商店在内的一些功能无法使用`;

export const OMNIBAR_PLACEHOLDER = () => `搜索小组件、查询、文档`;
export const OMNIBAR_PLACEHOLDER_SNIPPETS = () => `搜索代码片段`;
export const OMNIBAR_PLACEHOLDER_NAV = () => `搜索小组件和查询`;
export const OMNIBAR_PLACEHOLDER_DOC = () => `搜索文档`;
export const CREATE_NEW_OMNIBAR_PLACEHOLDER = () =>
  `创建新的查询、API或JSObject`;
export const HELPBAR_PLACEHOLDER = () => `快速搜索和导航`;
export const NO_SEARCH_DATA_TEXT = () => `没有发现结果`;

export const WIDGET_BIND_HELP = () => `从小组件获取输入时遇到问题`;

export const BACK_TO_HOMEPAGE = () => `返回主页`;

export const PAGE_NOT_FOUND = () => `页面不存在`;

export const RESOLVE = () => `决心`;
export const UNRESOLVE = () => `未解决`;

// comments
export const ADD_COMMENT_PLACEHOLDER = () => `添加评论。使用@来提及`;
export const PIN_COMMENT = () => `别针评论`;
export const UNPIN_COMMENT = () => `Unpin评论`;
export const COPY_LINK = () => `复制链接`;
export const DELETE_COMMENT = () => `删除评论`;
export const COMMENT_HAS_BEEN_DELETED = () => `未找到评论`;
export const DELETE_THREAD = () => `删除线程`;
export const EDIT_COMMENT = () => `编辑评论`;
export const COMMENTS = () => `评论`;
export const VIEW_LATEST = () => `查看最新信息`;
export const POST = () => `确定`;
export const CANCEL = () => `取消`;
export const NO_COMMENTS_CLICK_ON_CANVAS_TO_ADD = () =>
  `没有评论。\n单击画布上的任意位置\n开始对话`;
export const LINK_COPIED_SUCCESSFULLY = () => `链接已复制到剪贴板`;
export const FULL_NAME = () => `全名`;
export const DISPLAY_NAME = () => `显示名称`;
export const EMAIL_ADDRESS = () => `电子邮件地址`;
export const FIRST_AND_LAST_NAME = () => `名字和姓氏`;
export const MARK_ALL_AS_READ = () => `将所有标记为已读`;
export const INVITE_A_NEW_USER = () => `邀请新用户`;
export const REMOVE = () => `移除`;
export const NO_NOTIFICATIONS_TO_SHOW = () => `没有要显示的通知`;
export const UNREAD_MESSAGE = () => `未读对话`;
export const UNSUBSCRIBE_EMAIL_SUCCESS = () =>
  `您已成功从相应的评论线程取消订阅`;
export const UNSUBSCRIBE_EMAIL_MSG_1 = () =>
  `您将不会再收到相应评论线程的任何电子邮件通知`;
export const UNSUBSCRIBE_EMAIL_MSG_2 = () =>
  `请注意，如果有人在评论中标记您或您回复评论，您将再次订阅`;
export const UNSUBSCRIBE_EMAIL_CONFIRM_MSG = () => `你确定要取消订阅吗`;
export const UNSUBSCRIBE_BUTTON_LABEL = () => `取消订阅我`;

// Showcase Carousel
export const NEXT = () => `下一个`;
export const BACK = () => `回来`;
export const SKIP = () => `跳过`;

// Debugger
export const CLICK_ON = () => `🙌 点击`;
export const PRESS = () => `🎉 快捷键`;
export const OPEN_THE_DEBUGGER = () => `显示/隐藏`;
export const DEBUGGER_QUERY_RESPONSE_SECOND_HALF = () =>
  `在错误日志中查看更多信息`;
export const NO_LOGS = () => `没有日志显示`;
export const NO_ERRORS = () => `暂无错误`;
export const DEBUGGER_ERRORS = () => `错误`;
export const DEBUGGER_LOGS = () => `日志`;
export const INSPECT_ENTITY = () => `审查元素`;
export const INSPECT_ENTITY_BLANK_STATE = () => `选择要检查的实体`;
export const VALUE_IS_INVALID = (propertyPath: string) =>
  `${propertyPath}处的值无效`;
export const ACTION_CONFIGURATION_UPDATED = () => `配置更新`;
export const WIDGET_PROPERTIES_UPDATED = () => `小组件属性已更新`;
export const EMPTY_RESPONSE_FIRST_HALF = () => `🙌 点击`;
export const EMPTY_RESPONSE_LAST_HALF = () => `得到运行结果`;
export const INVALID_EMAIL = () => `请输入有效的电子邮件`;
export const DEBUGGER_INTERCOM_TEXT = (text: string) =>
  `您好，\n我在紫微平台上遇到以下错误，您能帮忙吗？\n\n${text}`;
export const DEBUGGER_TRIGGER_ERROR = (propertyName: string) =>
  `计算触发器${propertyName}时出错`;

export const TROUBLESHOOT_ISSUE = () => `解决问题`;
export const DEBUGGER_SEARCH_GOOGLE = () => `问问谷歌`;
export const DEBUGGER_COPY_MESSAGE = () => `复制`;
export const DEBUGGER_OPEN_DOCUMENTATION = () => `开放文档`;
export const DEBUGGER_SEARCH_SNIPPET = () => `浏览代码片段`;
export const DEBUGGER_APPSMITH_SUPPORT = () => `获得紫微平台支持`;

//action creator menu
export const NO_ACTION = () => `无操作`;
export const UPLOAD = () => `上传至项目`;
export const EXECUTE_A_QUERY = () => `执行查询`;
export const NAVIGATE_TO = () => `跳转到页面`;
export const SHOW_MESSAGE = () => `显示消息`;
export const OPEN_MODAL = () => `打开一个弹窗`;
export const CLOSE_MODAL = () => `关闭一个弹窗`;
export const STORE_VALUE = () => `存储到浏览器localStorage`;
export const DOWNLOAD = () => `下载`;
export const COPY_TO_CLIPBOARD = () => `复制到剪贴板`;
export const RESET_WIDGET = () => `重置组件状态`;
export const EXECUTE_JS_FUNCTION = () => `执行JS函数`;
export const SET_INTERVAL = () => `设置一个定时器`;
export const CLEAR_INTERVAL = () => `清除一个定时器`;
export const GET_GEO_LOCATION = () => `获取地理位置`;
export const WATCH_GEO_LOCATION = () => `监听地理位置变化`;
export const STOP_WATCH_GEO_LOCATION = () => `清除地址位置监听`;
export const PRINT_PDF = () => `打印PDF`;
export const PRINT_BY_TEMPLATE = () => `打印PDF模版`;

//js actions
export const JS_ACTION_COPY_SUCCESS = (actionName: string, pageName: string) =>
  `${actionName}已成功复制到 ${pageName}`;
export const ERROR_JS_ACTION_COPY_FAIL = (actionName: string) =>
  `复制${actionName}时出错`;
export const JS_ACTION_DELETE_SUCCESS = (actionName: string) =>
  `${actionName}已成功删除`;
export const JS_ACTION_CREATED_SUCCESS = (actionName: string) =>
  `${actionName}已成功创建`;
export const JS_ACTION_MOVE_SUCCESS = (actionName: string, pageName: string) =>
  `${actionName}已成功移动到 ${pageName}`;
export const ERROR_JS_ACTION_MOVE_FAIL = (actionName: string) =>
  `移动${actionName}时出错`;
export const ERROR_JS_COLLECTION_RENAME_FAIL = (actionName: string) =>
  `无法将js集合名称更新为${actionName}`;
export const PARSE_JS_FUNCTION_ERROR = (message: string) =>
  `语法错误：${message}`;

export const EXECUTING_FUNCTION = () => `执行功能`;
export const EMPTY_JS_OBJECT = () => `请编写JS函数进行调试`;
export const EXPORT_DEFAULT_BEGINNING = () => `使用导出默认值启动对象`;
export const JS_EXECUTION_SUCCESS = () => `JS函数已成功执行`;
export const JS_EXECUTION_FAILURE = () => `JS函数执行失败`;
export const JS_EXECUTION_FAILURE_TOASTER = () => `执行函数时出错`;
export const JS_SETTINGS_ONPAGELOAD = () => `在页面加载时运行函数（测试版）`;
export const JS_SETTINGS_ONPAGELOAD_SUBTEXT = () =>
  `将在每次重新加载页面时刷新数据`;
export const JS_SETTINGS_CONFIRM_EXECUTION = () => `在调用函数之前请求确认`;
export const JS_SETTINGS_CONFIRM_EXECUTION_SUBTEXT = () =>
  `每次刷新数据前，都需要用户确认`;
export const JS_SETTINGS_EXECUTE_TIMEOUT = () => `函数超时（以毫秒为单位）`;

// Import/Export Application features
export const IMPORT_APPLICATION_MODAL_TITLE = () => `导入应用程序`;
export const IMPORT_APPLICATION_MODAL_LABEL = () => `您想从哪里导入应用程序`;
export const IMPORT_APP_FROM_FILE_TITLE = () => `从文件导入`;
export const IMPORT_APP_FROM_GIT_TITLE = () => `从Git回购中导入（测试版）`;
export const IMPORT_APP_FROM_FILE_MESSAGE = () => `拖放文件或从计算机上载`;
export const IMPORT_APP_FROM_GIT_MESSAGE = () =>
  `使用应用程序的SSH URL从其git存储库导入应用程序`;
export const IMPORT_FROM_GIT_REPOSITORY = () => `从Git存储库导入`;
export const IMPORT_FROM_GIT_REPOSITORY_MESSAGE = () =>
  `导入紫微平台时，不会导入数据源凭据以防止违反。成功导入后，您可以手动添加凭据，以便应用程序正常运行`;
export const RECONNECT_MISSING_DATASOURCE_CREDENTIALS = () =>
  `重新连接丢失的数据源凭据`;
export const RECONNECT_MISSING_DATASOURCE_CREDENTIALS_DESCRIPTION = () =>
  `请务必小心填写，否则应用程序将无法正常运行`;
export const RECONNECT_DATASOURCE_SUCCESS_MESSAGE1 = () =>
  `这些数据源已成功导入`;
export const RECONNECT_DATASOURCE_SUCCESS_MESSAGE2 = () => `请填写缺失的数据源`;
export const ADD_MISSING_DATASOURCES = () => `添加缺失的数据源`;
export const SKIP_TO_APPLICATION_TOOLTIP_HEADER = () => `这一行动是不可逆转的`;
export const SKIP_TO_APPLICATION_TOOLTIP_DESCRIPTION = () =>
  `您随时可以稍后重新连接数据源，但在此之前，应用程序可能无法使用`;
export const SKIP_TO_APPLICATION = () => `跳到应用程序`;
export const SELECT_A_METHOD_TO_ADD_CREDENTIALS = () => `选择添加凭据的方法`;
export const DELETE_CONFIRMATION_MODAL_TITLE = () => `你确定吗`;
export const DELETE_CONFIRMATION_MODAL_SUBTITLE = (name?: string | null) =>
  `您想从此组织中删除${name}`;
export const PARSING_ERROR = () =>
  `语法错误：无法分析代码，请检查错误日志以进行调试`;
export const PARSING_WARNING = () =>
  `起毛错误：请在使用这些功能之前解决起毛错误`;
export const JS_FUNCTION_CREATE_SUCCESS = () => `成功添加新的JS函数`;
export const JS_FUNCTION_UPDATE_SUCCESS = () => `JS函数已成功更新`;
export const JS_FUNCTION_DELETE_SUCCESS = () => `已成功删除JS函数`;
export const JS_OBJECT_BODY_INVALID = () => `无法分析JS对象`;

//Editor Page
export const EDITOR_HEADER_SAVE_INDICATOR = () => ``;

//undo redo
export const WIDGET_REMOVED = (widgetName: string) => `${widgetName}已删除`;
export const WIDGET_ADDED = (widgetName: string) => `${widgetName}被重新添加`;
export const BULK_WIDGET_REMOVED = (widgetName: string) =>
  `${widgetName}小组件被删除`;
export const BULK_WIDGET_ADDED = (widgetName: string) =>
  `${widgetName}小组件被重新添加`;

// Generate page from DB Messages

export const UNSUPPORTED_PLUGIN_DIALOG_TITLE = () =>
  `无法从此数据源自动生成页面`;

export const UNSUPPORTED_PLUGIN_DIALOG_SUBTITLE = () =>
  `您可以使用我们的拖放构建器继续构建您的应用程序  `;
export const UNSUPPORTED_PLUGIN_DIALOG_MAIN_HEADING = () => `注意`;

export const BUILD_FROM_SCRATCH_ACTION_SUBTITLE = () =>
  `从0开始，拖拽组件自定义生成界面`;

export const BUILD_FROM_SCRATCH_ACTION_TITLE = () => `创建空白界面`;

export const GENERATE_PAGE_ACTION_TITLE = () => `快速创建表单`;

export const GENERATE_PAGE_ACTION_SUBTITLE = () =>
  `连接数据库（源），快速生成一个表单界面`;

export const GENERATE_PAGE_FORM_TITLE = () => `快速生成“增删改查”页面`;

export const GEN_CRUD_SUCCESS_MESSAGE = () => `应用已成功生成`;
export const GEN_CRUD_INFO_DIALOG_TITLE = () => `它是如何工作的`;
export const GEN_CRUD_INFO_DIALOG_SUBTITLE = () =>
  `CRUD页面是从选定的数据源生成的。您可以使用表单修改数据。由于所有数据都已连接，因此可以添加更多查询并修改绑定`;
export const GEN_CRUD_COLUMN_HEADER_TITLE = () => `获取的列标题`;
export const GEN_CRUD_NO_COLUMNS = () => `找不到任何列`;
export const GEN_CRUD_DATASOURCE_DROPDOWN_LABEL = () => `选择数据库`;
export const GEN_CRUD_TABLE_HEADER_LABEL = () => `表头索引`;
export const GEN_CRUD_TABLE_HEADER_TOOLTIP_DESC = () =>
  `工作表中列标题的行索引`;
// Actions Right pane
export const SEE_CONNECTED_ENTITIES = () => `查看所有连接的实体`;
export const INCOMING_ENTITIES = () => `传入实体`;
export const NO_INCOMING_ENTITIES = () => `没有传入实体`;
export const OUTGOING_ENTITIES = () => `传出实体`;
export const NO_OUTGOING_ENTITIES = () => `没有传出实体`;
export const NO_CONNECTIONS = () => `这里没有连接`;
export const BACK_TO_CANVAS = () => `回到画布`;
export const SUGGESTED_WIDGET_DESCRIPTION = () =>
  `这将向画布添加一个新的小组件`;
export const ADD_NEW_WIDGET = () => `添加新组件`;
export const SUGGESTED_WIDGETS = () => `建议的小组件`;
export const SUGGESTED_WIDGET_TOOLTIP = () => `添加到画布`;
export const WELCOME_TOUR_STICKY_BUTTON_TEXT = () => `下一个任务`;

// Data Sources pane
export const EMPTY_ACTIVE_DATA_SOURCES = () => `请去添加数据源`;

export const SNIPPET_EXECUTION_SUCCESS = () => `代码段已成功执行`;

export const SNIPPET_EXECUTION_FAILED = () => `代码段执行失败`;

export const SNIPPET_INSERT = () => `击中⏎ 插入`;
export const SNIPPET_COPY = () => `击中⏎ 复制`;
export const SNIPPET_EXECUTE = () => `击中⏎ 跑`;
export const APPLY_SEARCH_CATEGORY = () => `⏎ 跳跃`;

// Git sync
export const CONNECTED_TO_GIT = () => `连接到git`;

export const GIT_DISCONNECT_POPUP_TITLE = () =>
  `这将断开git存储库与此应用程序的连接`;

export const GIT_DISCONNECT_POPUP_SUBTITLE = () =>
  `此应用程序将不再显示Git功能`;
export const GIT_DISCONNECT_POPUP_MAIN_HEADING = () => `你确定吗`;

export const GIT_CONNECTION = () => `Git连接`;
export const GIT_IMPORT = () => `Git导入`;
export const MERGE = () => `合并`;
export const GIT_SETTINGS = () => `Git设置`;
export const CONNECT_TO_GIT = () => `连接到git存储库`;
export const CONNECT_TO_GIT_SUBTITLE = () => `签出分支、提交并部署应用程序`;
export const REMOTE_URL = () => `远程URL`;
export const REMOTE_URL_INFO = () =>
  `创建一个空的git存储库，并将远程URL粘贴到此处`;
export const REMOTE_URL_VIA = () => `远程URL通过`;

export const USER_PROFILE_SETTINGS_TITLE = () => `用户设置`;

export const USE_DEFAULT_CONFIGURATION = () => `使用默认配置`;
export const AUTHOR_NAME = () => `用户名`;
export const AUTHOR_NAME_CANNOT_BE_EMPTY = () => `名称不能为空`;
export const AUTHOR_EMAIL = () => `电子邮件`;

export const NAME_YOUR_NEW_BRANCH = () => `命名你的新分支`;
export const SWITCH_BRANCHES = () => `交换分支`;

export const DOCUMENTATION = () => `文档`;
export const DOCUMENTATION_TOOLTIP = () => `在Omnibar中打开文档`;
export const CONNECT = () => `连接`;
export const LATEST_DP_TITLE = () => `最新部署预览`;
export const LATEST_DP_SUBTITLE = () => `最后部署`;
export const CHECK_DP = () => `检查`;
export const DEPLOY_TO_CLOUD = () => `部署到云端`;
export const DEPLOY_WITHOUT_GIT = () => `在没有版本控制的情况下部署应用程序`;
export const COMMIT_CHANGES = () => `提交更改`;
export const COMMIT_TO = () => `致力于`;
export const COMMIT_AND_PUSH = () => `提交和推送`;
export const PULL_CHANGES = () => `拉动变化`;
export const DEPLOY_KEY_TITLE = () => `部署密钥`;
export const REGENERATE_SSH_KEY = () => `重新生成SSH密钥`;
export const SSH_KEY = () => `SSH密钥`;
export const COPY_SSH_KEY = () => `复制SSH密钥`;
export const SSH_KEY_GENERATED = () => `生成SSH密钥`;
export const REGENERATE_KEY_CONFIRM_MESSAGE = () =>
  `这可能会导致应用程序中断。这个密钥也需要在你的Git回购中更新`;
export const DEPLOY_KEY_USAGE_GUIDE_MESSAGE = () =>
  `将此密钥粘贴到存储库设置中，并授予其写入权限`;
export const COMMITTING_AND_PUSHING_CHANGES = () => `提交和推动更改`;
export const IS_MERGING = () => `合并更改`;

export const MERGE_CHANGES = () => `合并更改`;
export const SELECT_BRANCH_TO_MERGE = () => `选择要合并的分支`;
export const CONNECT_GIT = () => `连接Git`;
export const CONNECT_GIT_BETA = () => `下载应用`;
export const RETRY = () => `重试`;
export const CREATE_NEW_BRANCH = () => `创建新分支`;
export const ERROR_WHILE_PULLING_CHANGES = () => `拉取更改时出错`;
export const SUBMIT = () => `确定`;
export const GIT_USER_UPDATED_SUCCESSFULLY = () => `Git用户已成功更新`;
export const REMOTE_URL_INPUT_PLACEHOLDER = () =>
  `git://example.com:user/repo.git`;
export const GIT_COMMIT_MESSAGE_PLACEHOLDER = () => `此处是您的提交消息`;
export const COPIED_SSH_KEY = () => `复制的SSH密钥`;
export const INVALID_USER_DETAILS_MSG = () => `请输入有效的用户详细信息`;
export const PASTE_SSH_URL_INFO = () => `请输入存储库的有效SSH URL`;
export const GENERATE_KEY = () => `生成密钥`;
export const UPDATE_CONFIG = () => `更新配置`;
export const CONNECT_BTN_LABEL = () => `连接`;
export const IMPORT_BTN_LABEL = () => `进口`;
export const FETCH_GIT_STATUS = () => `正在获取状态`;
export const FETCH_MERGE_STATUS = () => `正在检查可合并性`;
export const NO_MERGE_CONFLICT = () => `此分支与基分支没有冲突`;
export const MERGE_CONFLICT_ERROR = () => `发现合并冲突`;
export const FETCH_MERGE_STATUS_FAILURE = () => `无法获取合并状态`;
export const GIT_UPSTREAM_CHANGES = () =>
  `看起来有一些悬而未决的上游变更。我们将把这些变化推送到你的回购协议中`;
export const GIT_CONFLICTING_INFO = () => `请在存储库中手动解决冲突`;
export const CANNOT_PULL_WITH_LOCAL_UNCOMMITTED_CHANGES = () =>
  `您有未提交的更改。请在拉取远程更改之前提交`;
export const CANNOT_MERGE_DUE_TO_UNCOMMITTED_CHANGES = () =>
  `您当前的分支有未提交的更改。请在继续合并之前提交`;

export const DISCONNECT_SERVICE_SUBHEADER = () =>
  `对此部分的更改可能会中断用户身份验证。小心行事`;
export const DISCONNECT_SERVICE_WARNING = () => `将作为身份验证的主要方法删除`;
export const AUTHENTICATION_METHOD_ENABLED = (methodName: string) => `
  ${methodName} authentication method is enabled
`;

export const DISCONNECT_EXISTING_REPOSITORIES = () => `断开现有存储库的连接`;
export const DISCONNECT_EXISTING_REPOSITORIES_INFO = () =>
  `要为更新的存储库腾出空间，可以删除现有的存储库`;
export const CONTACT_SUPPORT = () => `联系支持部门`;
export const CONTACT_SALES_MESSAGE_ON_INTERCOM = (orgName: string) =>
  `嘿，谢谢联系！我们了解到您希望延长${orgName}的私人回购数量。你能告诉我们你需要多少私人回购吗？为什么？我们一会儿给你回电`;
export const REPOSITORY_LIMIT_REACHED = () => `已达到存储库限制`;
export const REPOSITORY_LIMIT_REACHED_INFO = () =>
  `添加和使用多达3个存储库是免费的。要添加更多存储库，请升级`;
export const NONE_REVERSIBLE_MESSAGE = () => `这种行为是不可逆的。小心行事`;
export const CONTACT_SUPPORT_TO_UPGRADE = () =>
  `请联系支持人员进行升级。您可以在升级计划中添加无限的私有存储库`;
export const DISCONNECT_CAUSE_APPLICATION_BREAK = () =>
  `断开连接可能会导致应用程序中断`;
export const DISCONNECT_GIT = () => `撤销访问权`;
export const DISCONNECT = () => `断开`;
export const REVOKE = () => `撤销`;
export const REVOKE_ACCESS = () => `撤销访问权`;
export const GIT_DISCONNECTION_SUBMENU = () => `Git连接>断开连接`;
export const DISCONNECT_FROM_GIT = (name: string) => `断开${name}与Git的连接`;
export const GIT_REVOKE_ACCESS = (name: string) => `撤销对${name}的访问权`;
export const GIT_TYPE_REPO_NAME_FOR_REVOKING_ACCESS = (name: string) =>
  `在输入框中键入“${name}”以撤销访问权`;
export const APPLICATION_NAME = () => `应用程序名称`;
export const NOT_OPTIONS = () => `没有选择`;
export const OPEN_REPO = () => `公开回购`;
export const CONNECTING_REPO = () => `连接到GIT回购`;
export const IMPORTING_APP_FROM_GIT = () => `从GIT导入应用程序`;
export const ERROR_CONNECTING = () => `连接时出错`;
export const ERROR_COMMITTING = () => `提交时出错`;
export const CONFIRM_SSH_KEY = () => `确保您的SSH密钥具有写访问权限`;
export const READ_DOCUMENTATION = () => `阅读文档`;
export const LEARN_MORE = () => `了解更多`;
export const GIT_NO_UPDATED_TOOLTIP = () => `没有要推送的新更新`;

export const FIND_OR_CREATE_A_BRANCH = () => `查找或创建分支`;
export const SYNC_BRANCHES = () => `同步分支`;

export const CONFLICTS_FOUND = () => `发现冲突，请解决并重新拉`;
export const UNCOMMITTED_CHANGES = () => `您有未提交的更改`;
export const NO_COMMITS_TO_PULL = () => `没有人承诺拉。与远程存储库同步`;
export const CONFLICTS_FOUND_WHILE_PULLING_CHANGES = () => `拉取更改时发现冲突`;
export const NOT_LIVE_FOR_YOU_YET = () => `它还没有为你而活`;
export const COMING_SOON = () => `马上就来`;
export const CONNECTING_TO_REPO_DISABLED = () => `无法连接到git回购`;
export const DURING_ONBOARDING_TOUR = () => `在入职之旅期间`;
export const MERGED_SUCCESSFULLY = () => `合并成功`;

// GIT DEPLOY begin
export const DEPLOY = () => `部署`;
export const DEPLOY_YOUR_APPLICATION = () => `部署应用程序`;
export const CHANGES_ONLY_USER = () => `自上次提交以来的更改`;
export const CHANGES_ONLY_MIGRATION = () => `紫微平台更新自上次提交以来的更改`;
export const CHANGES_USER_AND_MIGRATION = () =>
  `自上次提交以来的紫微平台更新和用户更改`;
// GIT DEPLOY end

// GIT ERRORS begin
export const ERROR_GIT_AUTH_FAIL = () =>
  `请确保添加了重新生成的SSH密钥，并且具有对repo的写入权限`;
export const ERROR_GIT_INVALID_REMOTE = () => `远程回购不存在或无法访问`;
// GIT ERRORS end

// JS Snippets
export const SNIPPET_DESCRIPTION = () => `搜索并插入代码片段以快速执行复杂操作`;
export const DOC_DESCRIPTION = () => `通过紫微平台文档查找答案`;
export const NAV_DESCRIPTION = () => `导航到此项目中的任何页面、小组件或文件`;
export const ACTION_OPERATION_DESCRIPTION = () => `创建新的查询、API或JS对象`;

export const TRIGGER_ACTION_VALIDATION_ERROR = (
  functionName: string,
  argumentName: string,
  expectedType: string,
  received: string
) => {
  /**
   * NOTE: To fix issue: http://10.10.10.135:8081/zentao/bug-view-5009.html
   */
  return `${functionName}期望的参数为${TypeString[expectedType]}，但接收到的是${TypeString[received]}`;
};

// Comment card tooltips
export const MORE_OPTIONS = () => `更多选择`;
export const ADD_REACTION = () => `加反作用`;
export const RESOLVE_THREAD = () => `解析线程`;
export const RESOLVED_THREAD = () => `解析线程`;
export const EMOJI = () => `表情符号`;

// Sniping mode messages
export const SNIPING_SELECT_WIDGET_AGAIN = () =>
  `无法检测小组件，请重新选择小组件`;

export const SNIPING_NOT_SUPPORTED = () => `此类型的小组件不支持选择绑定`;

//First Time User Onboarding
//Checklist page
export enum ONBOARDING_CHECKLIST_ACTIONS {
  CONNECT_A_DATASOURCE = `连接数据源`,
  CREATE_A_QUERY = `创建查询`,
  ADD_WIDGETS = `添加小组件`,
  CONNECT_DATA_TO_WIDGET = `将数据连接到小组件`,
  DEPLOY_APPLICATIONS = `部署应用程序`,
}

export const ONBOARDING_CHECKLIST_BANNER_HEADER = () =>
  `太棒了！您已经了解了紫微平台的基础知识`;
export const ONBOARDING_CHECKLIST_BANNER_BODY = () =>
  `你可以在这里继续，或者浏览主页，看看你的项目是如何存储的`;
export const ONBOARDING_CHECKLIST_BANNER_BUTTON = () => `浏览主页`;

export const ONBOARDING_CHECKLIST_HEADER = () => `👋 欢迎来到紫微平台`;
export const ONBOARDING_CHECKLIST_BODY = () =>
  `让我们开始您的第一个应用程序，亲自探索紫微平台，或者按照下面的指南了解紫薇平台可以做什么`;
export const ONBOARDING_CHECKLIST_COMPLETE_TEXT = () => `完整的`;

export const ONBOARDING_CHECKLIST_CONNECT_DATA_SOURCE = {
  bold: () => `连接数据源`,
  normal: () => `开始构建应用程序`,
};

export const ONBOARDING_CHECKLIST_CREATE_A_QUERY = {
  bold: () => `创建查询`,
  normal: () => `你的数据源`,
};

export const ONBOARDING_CHECKLIST_ADD_WIDGETS = {
  bold: () => `开始可视化你的应用程序`,
  normal: () => `使用小组件`,
};

export const ONBOARDING_CHECKLIST_CONNECT_DATA_TO_WIDGET = {
  bold: () => `将数据连接到小组件`,
  normal: () => `使用JavaScript`,
};

export const ONBOARDING_CHECKLIST_DEPLOY_APPLICATIONS = {
  bold: () => `部署应用程序`,
  normal: () => `，观看你的创作现场`,
};

export const ONBOARDING_CHECKLIST_FOOTER = () =>
  `不知道从哪里开始？参加欢迎之旅`;

//Introduction modal
export const HOW_APPSMITH_WORKS = () => `下面是紫微平台工作原理的简要概述`;
export const ONBOARDING_INTRO_CONNECT_YOUR_DATABASE = () => `连接到数据库或API`;
export const DRAG_AND_DROP = () => `拖放预构建的小组件以构建UI`;
export const CUSTOMIZE_WIDGET_STYLING = () =>
  `定制每个小组件的样式。然后将数据绑定到小组件，并使用JS编写任何逻辑`;
export const ONBOARDING_INTRO_PUBLISH = () => `使用权限发布和共享`;
export const CHOOSE_ACCESS_CONTROL_ROLES = () =>
  `立即发布应用程序并与用户共享。从预定义的访问控制角色中进行选择`;
export const BUILD_MY_FIRST_APP = () => `建立在我自己的基础上`;
export const ONBOARDING_INTRO_FOOTER = () => `让我们开始构建您的第一个应用程序`;
export const START_TUTORIAL = () => `开始教程`;
export const WELCOME_TO_APPSMITH = () => `欢迎来到紫微平台`;
export const QUERY_YOUR_DATABASE = () =>
  `在紫微平台中查询自己的数据库或API。编写JS来构造动态查询`;

//Statusbar
export const ONBOARDING_STATUS_STEPS_FIRST = () => `首先：添加一个数据源`;
export const ONBOARDING_STATUS_STEPS_FIRST_ALT = () => `下一步：添加数据源`;
export const ONBOARDING_STATUS_STEPS_SECOND = () => `下一步：创建查询`;
export const ONBOARDING_STATUS_STEPS_THIRD = () => `下一步：添加一个小组件`;
export const ONBOARDING_STATUS_STEPS_THIRD_ALT = () => `首先：添加一个小组件`;
export const ONBOARDING_STATUS_STEPS_FOURTH = () =>
  `下一步：将数据连接到小组件`;
export const ONBOARDING_STATUS_STEPS_FIVETH = () => `下一步：部署应用程序`;
export const ONBOARDING_STATUS_STEPS_SIXTH = () => `完成的🎉`;
export const ONBOARDING_STATUS_GET_STARTED = () => `开始吧`;

//Tasks
//1. datasource
export const ONBOARDING_TASK_DATASOURCE_HEADER = () => `从添加第一个数据源开始`;
export const ONBOARDING_TASK_DATASOURCE_BODY = () =>
  `添加数据源可以使创建应用程序更加强大。如果您手头没有任何数据，请不要担心，我们有一个示例数据集供您使用`;
export const ONBOARDING_TASK_DATASOURCE_BUTTON = () => `+添加数据源`;
export const ONBOARDING_TASK_DATASOURCE_FOOTER_ACTION = () => `添加一个小组件`;
export const ONBOARDING_TASK_DATASOURCE_FOOTER = () => `首先`;
//2. query
export const ONBOARDING_TASK_QUERY_HEADER = () => `接下来，创建一个查询`;
export const ONBOARDING_TASK_QUERY_BODY = () =>
  `添加数据源真是太棒了！接下来可以做的事情是对数据创建查询`;
export const ONBOARDING_TASK_QUERY_BUTTON = () => `+创建查询`;
export const ONBOARDING_TASK_QUERY_FOOTER_ACTION = () => `添加一个小组件`;
//2. widget
export const ONBOARDING_TASK_WIDGET_HEADER = () =>
  `接下来，添加一个小组件开始显示数据`;
export const ONBOARDING_TASK_WIDGET_BODY = () =>
  `添加数据源真是太棒了！下一步你可以做的是添加小组件来开始可视化你的数据`;
export const ONBOARDING_TASK_WIDGET_BUTTON = () => `+添加一个小组件`;
export const ONBOARDING_TASK_WIDGET_FOOTER_ACTION = () => `部署应用程序`;
export const ONBOARDING_TASK_FOOTER = () => `或者，你也可以`;

export const USE_SNIPPET = () => `片段`;
export const SNIPPET_TOOLTIP = () => `搜索代码片段`;

//Welcome page
export const WELCOME_HEADER = () => `欢迎`;
export const WELCOME_BODY = () =>
  `让我们设置你的帐户，这样你就可以制作很棒的应用程序了`;
export const WELCOME_ACTION = () => `开始吧`;

// API Editor
export const API_EDITOR_TAB_TITLES = {
  HEADERS: () => `请求头`,
  PARAMS: () => `参数`,
  BODY: () => `请求体`,
  PAGINATION: () => `分页`,
  AUTHENTICATION: () => `鉴权`,
  SETTINGS: () => `设置`,
};

export const WELCOME_FORM_HEADER = () => `让我们更好地了解你`;
export const WELCOME_FORM_FULL_NAME = () => `用户名称`;
export const WELCOME_FORM_EMAIL_ID = () => `电子邮箱`;
export const WELCOME_FORM_CREATE_PASSWORD = () => `创建密码`;
export const WELCOME_FORM_VERIFY_PASSWORD = () => `验证密码`;
export const WELCOME_FORM_ROLE_DROPDOWN = () => `你的身份`;
export const WELCOME_FORM_ROLE = () => `角色`;
export const WELCOME_FORM_CUSTOM_USE_CASE = () => `用例`;
export const WELCOME_FORM_USE_CASE = () => `使用场景`;
export const WELCOME_FORM_DATA_COLLECTION_HEADER = () => `使用数据首选项`;
export const WELCOME_FORM_DATA_COLLECTION_BODY = () =>
  `共享匿名使用数据以帮助改进产品`;
export const WELCOME_FORM_DATA_COLLECTION_LINK = () => `看看什么是共享的`;
export const WELCOME_FORM_DATA_COLLECTION_LABEL_ENABLE = () =>
  `共享数据，让紫微平台变得更好`;
export const WELCOME_FORM_DATA_COLLECTION_LABEL_DISABLE = () =>
  `不要共享任何数据`;
export const WELCOME_FORM_NEWLETTER_HEADER = () => `保持联系`;
export const WELCOME_FORM_NEWLETTER_LABEL = () =>
  `获取有关我们烹饪的最新信息。我们不会给你发垃圾邮件`;
export const WELCOME_FORM_SUBMIT_LABEL = () => `制作你的第一个应用`;

//help tooltips
export const ACCOUNT_TOOLTIP = () => `你的账户`;
export const RENAME_APPLICATION_TOOLTIP = () => `重命名应用程序`;
export const LOGO_TOOLTIP = () => `返回主页`;
export const ADD_PAGE_TOOLTIP = () => `添加新页面`;
export const ADD_DATASOURCE_TOOLTIP = () => `添加数据源或创建新查询`;
export const ADD_WIDGET_TOOLTIP = () => `查找并添加小组件`;
export const HELP_RESOURCE_TOOLTIP = () => `帮助和资源`;
export const COPY_ELEMENT = () => `复制元素`;
export const LAYOUT_DROPDOWN_TOOLTIP = () =>
  `选择应用程序在桌面、平板电脑和移动设备上的外观`;
export const DEPLOY_BUTTON_TOOLTIP = () => `部署应用程序的当前版本`;
export const SHARE_BUTTON_TOOLTIP = () => `邀请您的团队参加紫微平台`;
export const SHARE_BUTTON_TOOLTIP_WITH_USER = (length: number) => () =>
  `与${length}其他共享`;
export const DEBUGGER_TOOLTIP = () => `打开调试器`;
export const PAGE_PROPERTIES_TOOLTIP = () => `菜单配置`;
export const CLEAR_LOG_TOOLTIP = () => `清除日志`;
export const ADD_JS_ACTION = () => `添加一个新的JS对象`;
export const ENTITY_MORE_ACTIONS_TOOLTIP = () => `更多`;
export const NOTIFICATIONS_TOOLTIP = () => `通知`;

// Navigation Menu
export const DEPLOY_MENU_OPTION = () => `部署`;
export const CURRENT_DEPLOY_PREVIEW_OPTION = () => `当前部署的版本`;
export const CONNECT_TO_GIT_OPTION = () => `连接到Git存储库`;
//
export const GO_TO_PAGE = () => `转到第页`;
export const DEFAULT_PAGE_TOOLTIP = () => `默认页面`;
export const HIDDEN_TOOLTIP = () => `隐藏的`;
export const CLONE_TOOLTIP = () => `克隆`;
export const DELETE_TOOLTIP = () => `删除`;
export const SETTINGS_TOOLTIP = () => `背景`;
//settings
export const ADMIN_SETTINGS = () => `管理设置`;
export const RESTART_BANNER_BODY = () => `坚持住。这应该很快完成`;
export const RESTART_BANNER_HEADER = () => `重新启动服务器`;
export const RESTART_ERROR_BODY = () => `您可以尝试重新启动服务器以进行设置`;
export const RESTART_ERROR_HEADER = () => `重新启动失败`;
export const RETRY_BUTTON = () => `重试`;
export const INFO_VERSION_MISMATCH_FOUND_RELOAD_REQUEST = () =>
  `嘿！有一个新版本的紫微平台可用。请考虑一下你的窗户。`;
export const TEST_EMAIL_SUCCESS = (email: string) => () =>
  `已发送测试电子邮件，请查看${email}的收件箱`;
export const TEST_EMAIL_SUCCESS_TROUBLESHOOT = () => `故障排除`;
export const TEST_EMAIL_FAILURE = () => `发送测试电子邮件失败`;
//Reflow Beta Screen
export const REFLOW_BETA_CHECKBOX_LABEL = () => `开启新的拖放体验`;
export const REFLOW_INFO_CARD_HEADER = () => `新的拖放体验`;
export const REFLOW_INFO_CARD_CONTENT_1 = () =>
  `当删除一个新的小组件时，其他小组件现在会自动移开`;
export const REFLOW_INFO_CARD_CONTENT_2 = () =>
  `画布边缘旁边的小组件将缩小，为新小组件腾出空间`;
export const REFLOW_LEARN_MORE = () => `了解更多`;
//
export const WELCOME_FORM_NON_SUPER_USER_ROLE_DROPDOWN = () =>
  `告诉我们更多关于你在工作中所做的事情`;
export const WELCOME_FORM_NON_SUPER_USER_ROLE = () => `角色`;
export const WELCOME_FORM_NON_SUPER_USER_USE_CASE = () =>
  `你打算用紫微平台做什么`;
export const QUERY_CONFIRMATION_MODAL_MESSAGE = () => `你确定要执行吗`;
export const ENTITY_EXPLORER_TITLE = () => `航行`;
export const MULTI_SELECT_PROPERTY_PANE_MESSAGE = () =>
  `选择一个小组件以查看其属性`;
export const LOCK_ENTITY_EXPLORER_MESSAGE = () => `锁定侧边栏打开`;
export const CLOSE_ENTITY_EXPLORER_MESSAGE = () => `关闭侧边栏`;

// API Pane
export const API_PANE_NO_BODY = () => `此请求没有正文`;

export const TABLE_WIDGET_TOTAL_RECORD_TOOLTIP = () =>
  `它存储表中的行总数。帮助计算允许在分页中启用或禁用下一个/上一个控件的页数`;
export const CREATE_DATASOURCE_TOOLTIP = () => `添加新的数据源`;
export const ADD_QUERY_JS_TOOLTIP = () => `创造新的`;

export const DELETE_ORG_SUCCESSFUL = () => `已成功删除组织`;

export const UPGRADE_TO_EE = (authLabel: string) =>
  `您好，我想升级并开始使用${authLabel}身份验证`;

export const DELETE_ITEM_BY_NAME = (label: string) => `删除${label}`;
export const ORGANIZATION = () => '团队';

export const LEAVE_ITEM_BY_NAME = (label: string) => `离开${label}`;
export const ADMIN_AUTH_SETTINGS_TITLE = () => `选择身份验证方法`;
export const ADMIN_AUTH_SETTINGS_SUBTITLE = () =>
  `选择要对用户进行身份验证的协议`;
export const DANGER_ZONE = () => `危险区`;
export const DISCONNECT_AUTH_METHOD = () => `断开`;
export const DISCONNECT_CONFIRMATION = () => `你确定吗`;

// Guided tour
// -- STEPS ---
export const STEP_ONE_TITLE = () =>
  `第一步是查询数据库。这里我们查询的是一个由客户数据填充的Postgres数据库`;
export const STEP_ONE_SUCCESS_TEXT = () =>
  `太棒了！您成功地查询了数据库，您可以在下面看到查询的响应`;
export const STEP_ONE_BUTTON_TEXT = () => `继续下一步`;
export const STEP_TWO_TITLE = () =>
  `让我们在表格中显示这个响应。选择我们为您添加的表格小组件`;
export const STEP_THREE_TITLE = () => `在表中显示查询的响应`;
export const STEP_THREE_SUCCESS_TEXT = () =>
  `干得好！该表现在显示查询的响应。您可以在任何输入字段中使用{{}将数据绑定到小组件`;
export const STEP_THREE_SUCCESS_BUTTON_TEXT = () => `继续下一步`;
export const STEP_FOUR_TITLE = () => `让我们构建一个表单来更新客户记录`;
export const STEP_FOUR_HINT_BUTTON_TEXT = () => `继续`;
export const STEP_FOUR_SUCCESS_TEXT = () =>
  `太棒了！您已将输入小组件连接到表的选定行。输入将始终显示所选行的数据`;
export const STEP_FOUR_SUCCESS_BUTTON_TEXT = () => `继续下一步`;
export const STEP_FIVE_TITLE = () =>
  `将客户更新表单中的所有输入字段与表连接起来`;
export const STEP_FIVE_HINT_TEXT = () =>
  `现在，让我们将容器中的其余小组件连接到表的选定行`;
export const STEP_FIVE_SUCCESS_TEXT = () =>
  `干得好！所有输入现在都连接到表的选定行`;
export const STEP_FIVE_SUCCESS_BUTTON_TEXT = () => `继续下一步`;
export const STEP_SIX_TITLE = () => `添加更新按钮以触发更新查询`;
export const STEP_SIX_SUCCESS_TEXT = () =>
  `太好了！“更新”按钮已准备好触发更新查询`;
export const STEP_SIX_SUCCESS_BUTTON_TEXT = () => `继续下一步`;
export const STEP_SEVEN_TITLE = () =>
  `通过绑定到button小组件触发updateCustomerInfo查询`;
export const STEP_EIGHT_TITLE = () => `成功触发更新查询后，获取更新的客户数据`;
export const STEP_EIGHT_SUCCESS_TEXT = () =>
  `出色的工作！现在，您已经建立了查看客户数据并进行更新的方法`;
export const STEP_NINE_TITLE = () => `最后一步：测试并部署应用程序`;
export const CONTINUE = () => `继续`;
export const PROCEED_TO_NEXT_STEP = () => `继续下一步`;
export const PROCEED = () => `继续`;
export const COMPLETE = () => `完整的`;
// -- Modal --
export const DEVIATION = () => `你偏离了教程`;
export const END_CONFIRMATION = () => `你确定要结束吗`;
export const CANCEL_DIALOG = () => `取消`;
// -- End Tutorial --
export const END_TUTORIAL = () => `结束教程`;
// -- Intro content --
export const TITLE = () => `在本教程中，我们将构建一个工具来显示客户信息`;
export const DESCRIPTION = () =>
  `该工具有一个显示客户数据的表和一个更新特定客户记录的表单。在开始构建之前，请尝试下面的工具`;
export const BUTTON_TEXT = () => `开始构建`;
// -- Rating --
export const RATING_TITLE = () =>
  `恭喜你！你刚刚在紫微平台中创建了第一个应用程序`;
export const RATING_DESCRIPTION = () => `您现在可以邀请其他人加入此应用程序`;
export const RATING_TEXT = () => `评价你的经历`;
// -- End Message --
export const END_TITLE = () => `接下来呢？开始构建自己的应用程序`;
export const END_DESCRIPTION = () => `检查查询、组件等的属性`;
export const END_BUTTON_TEXT = () => `开始构建应用程序`;

export const CONTEXT_EDIT_NAME = () => `编辑名称`;
export const CONTEXT_SHOW_BINDING = () => `显示绑定`;
export const CONTEXT_MOVE = () => `移到其他页面`;
export const CONTEXT_COPY = () => `复制到其他页面`;
export const CONTEXT_DELETE = () => `删除`;
export const CONFIRM_CONTEXT_DELETE = () => `确定删除？`;
export const CONTEXT_NO_PAGE = () => `暂无页面`;
export const CONTEXT_REFRESH = () => `刷新`;
export const CONTEXT_CLONE = () => `克隆`;
export const CONTEXT_SET_AS_HOME_PAGE = () => `设置为主页`;

// Entity explorer
export const ADD_DATASOURCE_BUTTON = () => `添加数据源`;
export const ADD_WIDGET_BUTTON = () => `添加组件`;
export const ADD_QUERY_JS_BUTTON = () => `添加查询/JS`;
export const EMPTY_WIDGET_MAIN_TEXT = () => `没有要显示的组件`;
export const EMPTY_WIDGET_BUTTON_TEXT = () => `新组件`;
export const EMPTY_QUERY_JS_MAIN_TEXT = () => `没有要显示的查询/JS`;
export const EMPTY_QUERY_JS_BUTTON_TEXT = () => `新查询/JS`;
export const EMPTY_DATASOURCE_MAIN_TEXT = () => `没有要显示的数据源`;
export const EMPTY_DATASOURCE_BUTTON_TEXT = () => `新数据源`;

// Templates
export const MORE = () => `更多`;
export const SHOW_LESS = () => `少展示`;
export const CHOOSE_WHERE_TO_FORK = () => `选择模板的克隆位置`;
export const SELECT_ORGANISATION = () => `选择组织`;
export const FORK_TEMPLATE = () => `克隆模板`;
export const TEMPLATES = () => `模板`;
export const FORK_THIS_TEMPLATE = () => `克隆这个模板`;
export const COULDNT_FIND_TEMPLATE = () => `暂无模板`;
export const COULDNT_FIND_TEMPLATE_DESCRIPTION = () => `暂无模板描述`;
export const REQUEST_TEMPLATE = () => `申请模板`;
export const SEARCH_TEMPLATES = () => `搜索模板`;
export const INTRODUCING_TEMPLATES = () => `介绍模板`;
export const TEMPLATE_NOTIFICATION_DESCRIPTION = () =>
  `使用这些模板可以更快地学习、创建和构建应用程序`;
export const GO_BACK = () => `返回`;
export const OVERVIEW = () => `概述`;
export const FUNCTION = () => `功能`;
export const INDUSTRY = () => `工业`;
export const DATASOURCES = () => `数据源`;
export const NOTE = () => `注：`;
export const NOTE_MESSAGE = () => `您还可以添加数据源`;
export const WIDGET_USED = () => `使用的小组件`;
export const SIMILAR_TEMPLATES = () => `类似的模板`;
export const VIEW_ALL_TEMPLATES = () => `查看所有模板`;
export const FILTERS = () => `过滤器`;

export const IMAGE_LOAD_ERROR = () => `无法显示图像`;

export const REDIRECT_URL_TOOLTIP = () =>
  `此URL将在配置身份提供程序的回调/重定向URL时使用`;
export const ENTITY_ID_TOOLTIP = () =>
  `此URL将在配置身份提供商的实体ID URL时使用`;

export const FORK_APP_MODAL_LOADING_TITLE = () => `吸引组织前往`;
export const FORK_APP_MODAL_EMPTY_TITLE = () => `没有可供选择的组织`;
export const FORK_APP_MODAL_SUCCESS_TITLE = () => `选择应用的版本节点`;
export const FORK = () => `创建`;

export const CLEAN_URL_UPDATE = {
  name: () => `更新网址`,
  shortDesc: () =>
    `应用程序中的所有URL都将更新为新的可读格式，其中包括应用程序和页面名称`,
  description: [
    () =>
      `应用程序中的所有URL都将更新，以匹配我们的新样式。这将使你的应用程序更容易找到，URL更容易记住`,
    (url: string) =>
      `当前应用程序的URL将是：<br/><code style=“换行符：任意位置；填充：2px 4px；行高：22px”>${url}</code>`,
  ],
  disclaimer: () =>
    `对紫薇平台的现有引用。网址。完整路径和紫薇平台。网址。路径名</strong>属性的行为将不同`,
};
