import {
  ApplicationPayload,
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from '@appsmith/constants/ReduxActionConstants';
import ApplicationApi, {
  ApplicationObject,
  ApplicationPagePayload,
  ApplicationResponsePayload,
  ChangeAppViewAccessRequest,
  CreateApplicationRequest,
  CreateApplicationResponse,
  DeleteApplicationRequest,
  DuplicateApplicationRequest,
  FetchApplicationPayload,
  FetchApplicationResponse,
  FetchUnconfiguredDatasourceListResponse,
  FetchUsersApplicationsOrgsResponse,
  ForkApplicationRequest,
  ImportApplicationRequest,
  OrganizationApplicationObject,
  PublishApplicationRequest,
  PublishApplicationResponse,
  ReleaseTemplateApplicationRequest,
  SetDefaultPageRequest,
  ToggleFullScreenRequest,
  UpdateApplicationRequest,
} from 'api/ApplicationApi';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  CREATE_AN_APPLICATION_WITH_A_TEMPLATE,
  DELETING_APPLICATION,
  DUPLICATING_APPLICATION,
  PUBLISH_APPLICATION_TO_THE_APPLICATION_MARKET,
  createMessage,
} from '@appsmith/constants/messages';
import {
  ApplicationVersion,
  fetchApplication,
  fetchTemplateApplications,
  getAllApplications,
  importApplicationSuccess,
  initDatasourceConnectionDuringImportSuccess,
  resetCurrentApplication,
  setDefaultApplicationPageSuccess,
  setIsReconnectingDatasourcesModalOpen,
  setOrgIdForImport,
  showReconnectDatasourceModal,
  togglePageFullscreenSuccess,
} from 'actions/applicationActions';
import { ApiResponse } from 'api/ApiResponses';
import { AppIconName } from 'components/ads/AppIcon';
import { Toaster } from 'components/ads/Toast';
import { Variant } from 'components/ads/common';
import { AppColorCode } from 'constants/DefaultTheme';
import { Organization } from 'constants/orgConstants';
import { APP_MODE } from 'entities/App';
import { getUserApplicationsOrgsList } from 'selectors/applicationSelectors';
import {
  getCurrentApplication,
  getCurrentApplicationId,
  getCurrentPageId,
  selectURLSlugs,
} from 'selectors/editorSelectors';
import AnalyticsUtil from 'utils/AnalyticsUtil';
import history from 'utils/history';
import { validateResponse } from './ErrorSagas';

import {
  reconnectAppLevelWebsocket,
  reconnectPageLevelWebsocket,
} from 'actions/websocketActions';
import { Org } from 'constants/orgConstants';
import { getCurrentOrg } from 'selectors/organizationSelectors';
import {
  deleteRecentAppEntities,
  setPostWelcomeTourState,
} from 'utils/storage';

import { getDepAppJson, getDepPageList } from '@appInstall/redux/selectors';
import { Message } from '@arco-design/web-react';
import { CacheWhiteList } from 'QueryClient';
import { builderURL, generateTemplateURL, viewerURL } from 'RouteBuilder';
import {
  fetchDatasources,
  setUnconfiguredDatasourcesDuringImport,
} from 'actions/datasourceActions';
import { fetchPluginFormConfigs, fetchPlugins } from 'actions/pluginActions';
import DatasourcesApi from 'api/DatasourcesApi';
import PageApi from 'api/PageApi';
import { getConfigInitialValues } from 'components/formControls/utils';
import { PLACEHOLDER_APP_SLUG, PLACEHOLDER_PAGE_SLUG } from 'constants/routes';
import { Datasource } from 'entities/Datasource';
import { identity, merge, pickBy } from 'lodash';
import { GUIDED_TOUR_STEPS } from 'pages/Editor/GuidedTour/constants';
import { AppState } from 'reducers';
import { getPageList, getPluginForm } from 'selectors/entitiesSelector';
import {
  getCurrentStep,
  getEnableFirstTimeUserOnboarding,
  getFirstTimeUserOnboardingApplicationId,
  inGuidedTour,
} from 'selectors/onboardingSelectors';
import { useCallApiWithCache } from 'utils/CacheHelper';
import { traverseTree } from 'utils/treeUtils';
import { failFastApiCalls } from './InitSagas';
import { checkAndGetPluginFormConfigsSaga } from './PluginSagas';
import { getDefaultPageId as selectDefaultPageId } from './selectors';

export const getDefaultPageId = (
  pages?: ApplicationPagePayload[]
): string | undefined => {
  let defaultPage: ApplicationPagePayload | undefined = undefined;
  if (pages) {
    defaultPage = pages.find((page) => page.isDefault);
    if (!defaultPage) {
      defaultPage = pages[0];
    }
  }
  return defaultPage ? defaultPage.id : undefined;
};

// const windowReference: Window | null = null;

// 使用applicationId维护一个窗口
let nextWin: Window = null;
export function* publishApplicationSaga(
  requestAction: ReduxAction<PublishApplicationRequest>
) {
  try {
    const request = requestAction.payload;
    const applicationId: string = yield select(getCurrentApplicationId);
    const currentPageId: string = yield select(getCurrentPageId);
    if (!request.onopen) {
      //src/AppRouter.tsx
      if (nextWin?.name === (request.applicationId || applicationId)) {
        // nextWin.location.replace(applicationViewPageUrl);
        nextWin.hotLoad && nextWin.hotLoad(true);
        nextWin.focus();
      } else {
        const { applicationSlug } = yield select(selectURLSlugs);

        const applicationViewPageUrl = viewerURL({
          applicationSlug,
          pageId: request.pageId || currentPageId,
          applicationId: request.applicationId || applicationId,
        });
        nextWin = window.open(
          applicationViewPageUrl,
          request.applicationId || applicationId
        );
      }
    }
    const response: PublishApplicationResponse = yield call(
      ApplicationApi.publishApplication,
      request
    );

    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.PUBLISH_APPLICATION_SUCCESS,
      });
      const guidedTour: boolean = yield select(inGuidedTour);
      const currentStep: number = yield select(getCurrentStep);
      const currentApp = yield select(getCurrentApplication);

      const { applicationSlug } = yield select(selectURLSlugs);

      // let applicationViewPageUrl = viewerURL({
      //   applicationSlug,
      //   pageId: currentPageId || request.pageId,
      //   applicationId: applicationId || request.applicationId,
      // });
      let applicationViewPageUrl = viewerURL({
        applicationSlug,
        pageId: request.pageId || currentPageId,
        applicationId: request.applicationId || applicationId,
      });

      if (guidedTour && currentStep === GUIDED_TOUR_STEPS.DEPLOY) {
        applicationViewPageUrl += '?&guidedTourComplete=true';
        yield call(setPostWelcomeTourState, true);
      }

      yield put(
        fetchApplication({
          applicationId: applicationId || request.applicationId,
          pageId: currentPageId || request.pageId,
          mode: APP_MODE.EDIT,
        })
      );
      // if (!(request.isCustom || currentApp?.isCustom)) {
      //   PageApi.putAppCover({
      //     viewApplicationURL: applicationViewPageUrl,
      //     id: applicationId,
      //   });
      // }
      //写错了 noopen
      if (!request.onopen) {
        if (nextWin?.name === (request.applicationId || applicationId)) {
          // nextWin.location.replace(applicationViewPageUrl);
          if (
            nextWin.location.href.replace(nextWin.location.origin, '') !==
            applicationViewPageUrl
          ) {
            nextWin.setUrl && nextWin.setUrl(applicationViewPageUrl);
          }
          nextWin.hotLoad && nextWin.hotLoad(false);
        } else {
          nextWin = window.open(
            applicationViewPageUrl,
            request.applicationId || applicationId
          );
        }
      } else {
        request?.win?.location.reload();
      }

      request.fx && request.fx();
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.PUBLISH_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* getAllApplicationSaga() {
  try {
    const next = function* (response) {
      const isValidResponse = yield validateResponse(response);
      if (isValidResponse) {
        const organizationApplication: OrganizationApplicationObject[] = response.data.organizationApplications.map(
          (userOrgs: OrganizationApplicationObject) => ({
            organization: userOrgs.organization,
            userRoles: userOrgs.userRoles,
            applications: !userOrgs.applications
              ? []
              : userOrgs.applications.map((application: ApplicationObject) => {
                  return {
                    ...application,
                    defaultPageId: getDefaultPageId(application.pages),
                  };
                }),
          })
        );
        yield put({
          type: ReduxActionTypes.FETCH_USER_APPLICATIONS_ORGS_SUCCESS,
          payload: organizationApplication,
        });
        const { newReleasesCount, releaseItems } = response.data || {};
        yield put({
          type: ReduxActionTypes.FETCH_RELEASES_SUCCESS,
          payload: { newReleasesCount, releaseItems },
        });
      }
    };

    yield call(
      useCallApiWithCache,
      CacheWhiteList.getAllApplication,
      ApplicationApi.getAllApplication,
      next
    );
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_USER_APPLICATIONS_ORGS_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* fetchTemplateApplicationsSaga() {
  const templateApplicationsResponse: PublishApplicationResponse = yield call(
    ApplicationApi.fetchTemplateApplications
  );
  const isTemplateApplicationsResponseValidResponse = yield validateResponse(
    templateApplicationsResponse
  );
  if (isTemplateApplicationsResponseValidResponse) {
    const organizationApplication: OrganizationApplicationObject[] = (templateApplicationsResponse.data as {
      organizationApplications: OrganizationApplicationObject[];
    }).organizationApplications.map(
      (userOrgs: OrganizationApplicationObject) => ({
        organization: userOrgs.organization,
        userRoles: userOrgs.userRoles,
        applications: !userOrgs.applications
          ? []
          : userOrgs.applications.map((application: ApplicationObject) => {
              return {
                ...application,
                defaultPageId: getDefaultPageId(application.pages),
              };
            }),
      })
    );
    yield put({
      type: ReduxActionTypes.FETCH_TEMPLATE_APPLICATIONS_RESPONSE_ORGS_SUCCESS,
      payload: organizationApplication,
    });
  }
}

export function* fetchAppAndPagesSaga(
  action: ReduxAction<FetchApplicationPayload>
) {
  const params = pickBy(action.payload, identity);

  if (params.pageId && params.applicationId) {
    delete params.applicationId;
  }

  try {
    if (__INSTALL_MODE__) {
      return;
      // updated by @caron 2023-11-22
      // move app initial process before AppViewer page rendered
      const appJson = yield select(getDepAppJson);
      const { exportedApplication = {} } = appJson || {};
      const pageList = yield select(getDepPageList);

      yield put({
        type: ReduxActionTypes.FETCH_APPLICATION_SUCCESS,
        payload: { ...exportedApplication, pages: pageList },
      });

      yield put({
        type: ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS,
        payload: {
          pages: pageList,
          applicationId: exportedApplication?.id,
        },
      });

      yield put({
        type: ReduxActionTypes.SET_CURRENT_ORG_ID,
        payload: {
          orgId: exportedApplication.organizationId,
        },
      });

      yield put({
        type: ReduxActionTypes.SET_APP_VERSION_ON_WORKER,
        payload: exportedApplication?.evaluationVersion,
      });
    } else {
      const response: FetchApplicationResponse = yield call(
        PageApi.fetchAppAndPages,
        params
      );
      const isValidResponse: boolean = yield call(validateResponse, response);
      if (isValidResponse) {
        yield put({
          type: ReduxActionTypes.FETCH_APPLICATION_SUCCESS,
          payload: { ...response.data.application, pages: response.data.pages },
        });
        yield put({
          type: ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS,
          payload: {
            pages: response.data.pages.map((page) => ({
              pageName: page.name,
              pageId: page.id,
              isDefault: page.isDefault,
              isScreen: page.isScreen,
              isHidden: !!page.isHidden,
              icon: page.icon,
              slug: page.slug,
              id: page.id,
              name: page.name,
              order: page.order,
              pageType: page.pageType,
              parentId: page.parentId,
              isPrint: page.isPrint,
              // This field is equal to current pageId when app is made template or app is retrieved from template
              // which will generate a new pageId replace old pageId
              // then store old pageId to temPageId
              // if there is already a tempPageId, ignore
              tempPageId: page.tempPageId || page.id,
              children: page.children?.map((collection) =>
                traverseTree(
                  collection,
                  (value) => (value.tempPageId = value.tempPageId || value.id)
                )
              ),
              refPageId: page.refPageId,
              index: page.index,
              pageIndex: page.pageIndex,
            })),
            applicationId: response.data.application?.id,
          },
        });

        yield put({
          type: ReduxActionTypes.SET_CURRENT_ORG_ID,
          payload: {
            orgId: response.data.organizationId,
          },
        });

        yield put({
          type: ReduxActionTypes.SET_APP_VERSION_ON_WORKER,
          payload: response.data.application?.evaluationVersion,
        });
      } else {
        yield call(handleFetchApplicationError, response.responseMeta?.error);
      }
    }
  } catch (error) {
    yield call(handleFetchApplicationError, error);
  }
}

function* handleFetchApplicationError(error: unknown) {
  yield put({
    type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
    payload: {
      error,
    },
  });
  yield put({
    type: ReduxActionErrorTypes.FETCH_PAGE_LIST_ERROR,
    payload: {
      error,
    },
  });
}

export function* setDefaultApplicationPageSaga(
  action: ReduxAction<SetDefaultPageRequest>
) {
  try {
    const defaultPageId: string = yield select(selectDefaultPageId);
    const pages = yield select(getPageList);

    if (defaultPageId === action.payload.id) {
      if (pages.length > 1) {
        if (action.payload.id === pages[0].id) {
          action.payload.id = pages[1].id;
        } else {
          action.payload.id = pages[0].id;
        }
      } else {
        Message.info('至少需要一个主页，但当前只存在一个页面。');
      }
    }

    const request: SetDefaultPageRequest = action.payload;
    const response: ApiResponse = yield call(
      ApplicationApi.setDefaultApplicationPage,
      request
    );
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      yield put(
        setDefaultApplicationPageSuccess(request.id, request.applicationId)
      );
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.SET_DEFAULT_APPLICATION_PAGE_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* togglePageFullscreenSaga(
  action: ReduxAction<ToggleFullScreenRequest>
) {
  try {
    const request: ToggleFullScreenRequest = action.payload;
    const response: ApiResponse = yield call(
      ApplicationApi.togglePageFullScreen,
      request
    );
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      yield put(togglePageFullscreenSuccess(request));
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.SET_APPLICATION_PAGE_FULLSCREEN_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* updateApplicationLayoutSaga(
  action: ReduxAction<UpdateApplicationRequest>
) {
  try {
    yield call(updateApplicationSaga, action);
    yield put({
      type: ReduxActionTypes.CURRENT_APPLICATION_LAYOUT_UPDATE,
      payload: action.payload.appLayout,
    });
    yield put({
      type: ReduxActionTypes.CURRENT_APPLICATION_THEME_UPDATE,
      payload: {
        color: action.payload.color,
        themeObject: action.payload.themeObject,
      },
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_APP_LAYOUT_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* updateApplicationSaga(
  action: ReduxAction<UpdateApplicationRequest>
) {
  try {
    const request: UpdateApplicationRequest = action.payload;
    const response: ApiResponse = yield call(
      ApplicationApi.updateApplication,
      request
    );

    const isValidResponse: boolean = yield validateResponse(response);
    // as the redux store updates the app only on success.
    // we have to run this
    if (isValidResponse) {
      if (request && request.applicationVersion) {
        if (request.applicationVersion === ApplicationVersion.SLUG_URL) {
          request.callback?.();
          return;
        }
      }
      if (request) {
        yield put({
          type: ReduxActionTypes.UPDATE_APPLICATION_SUCCESS,
          payload: action.payload,
        });
      }
      if (request.currentApp) {
        yield put({
          type: ReduxActionTypes.CURRENT_APPLICATION_NAME_UPDATE,
          payload: response.data,
        });
        !request.noTip && Message.success('修改名称成功');
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* releaseTemplateApplicationSaga(
  action: ReduxAction<ReleaseTemplateApplicationRequest>
) {
  try {
    Toaster.show({
      text: createMessage(PUBLISH_APPLICATION_TO_THE_APPLICATION_MARKET),
    });
    const request: ReleaseTemplateApplicationRequest = action.payload;
    const response: ApiResponse = yield call(
      ApplicationApi.releaseTemplateApplication,
      request
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put(fetchTemplateApplications());
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.DELETE_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* deleteApplicationSaga(
  action: ReduxAction<DeleteApplicationRequest>
) {
  try {
    Toaster.show({
      text: createMessage(DELETING_APPLICATION),
    });
    const request: DeleteApplicationRequest = action.payload;
    const response: ApiResponse = yield call(
      ApplicationApi.deleteApplication,
      request
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.DELETE_APPLICATION_SUCCESS,
        payload: response.data,
      });
      yield call(deleteRecentAppEntities, request.applicationId);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.DELETE_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* duplicateApplicationSaga(
  action: ReduxAction<DeleteApplicationRequest>
) {
  try {
    const request: DuplicateApplicationRequest = action.payload;
    const { type } = request;
    Toaster.show({
      text: createMessage(
        type === 2
          ? CREATE_AN_APPLICATION_WITH_A_TEMPLATE
          : DUPLICATING_APPLICATION
      ),
    });
    const response: ApiResponse = yield call(
      ApplicationApi.duplicateApplication,
      request
    );
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      const application: ApplicationPayload = {
        ...response.data,
        defaultPageId: getDefaultPageId(response.data.pages),
      };
      yield put({
        type: ReduxActionTypes.DUPLICATE_APPLICATION_SUCCESS,
        payload: response.data,
      });
      const { slug } = application;
      const defaultPage = application.pages.find((page) => page.isDefault);
      const pageURL = builderURL({
        applicationVersion: application.applicationVersion,
        applicationId: application.id,
        applicationSlug: slug || PLACEHOLDER_APP_SLUG,
        pageSlug: defaultPage?.slug || PLACEHOLDER_PAGE_SLUG,
        pageId: application.defaultPageId as string,
      });
      history.push(pageURL);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.DUPLICATE_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* changeAppViewAccessSaga(
  requestAction: ReduxAction<ChangeAppViewAccessRequest>
) {
  try {
    const request = requestAction.payload;
    const response: ApiResponse = yield call(
      ApplicationApi.changeAppViewAccess,
      request
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.CHANGE_APPVIEW_ACCESS_SUCCESS,
        payload: {
          id: response.data.id,
          isPublic: response.data.isPublic,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CHANGE_APPVIEW_ACCESS_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* createApplicationSaga(
  action: ReduxAction<{
    applicationName: string;
    icon: AppIconName;
    color: AppColorCode;
    orgId: string;
    resolve: any;
    reject: any;
    isDemoApp: boolean;
    params: Record<string, string>;
  }>
) {
  const {
    applicationName,
    color,
    icon,
    isDemoApp,
    orgId,
    params,
    reject,
  } = action.payload;
  try {
    const userOrgs = yield select(getUserApplicationsOrgsList);
    const existingOrgs = userOrgs.filter(
      (org: Organization) => org.organization.id === orgId
    )[0];
    const existingApplication = existingOrgs
      ? existingOrgs.applications.find(
          (application: ApplicationPayload) =>
            application.name === applicationName
        )
      : null;
    if (existingApplication) {
      yield call(reject, {
        _error: 'An application with this name already exists',
      });
      yield put({
        type: ReduxActionErrorTypes.CREATE_APPLICATION_ERROR,
        payload: {
          error: 'Could not create application',
          show: false,
        },
      });
    } else {
      yield put(resetCurrentApplication());

      const request: CreateApplicationRequest = {
        name: applicationName,
        icon: icon,
        color: color,
        orgId,
      };
      const response: CreateApplicationResponse = yield call(
        ApplicationApi.createApplication,
        request
      );
      const isValidResponse = yield validateResponse(response);
      if (isValidResponse) {
        const application: ApplicationPayload = {
          ...response.data,
          defaultPageId: getDefaultPageId(response.data.pages) as string,
        };
        AnalyticsUtil.logEvent('CREATE_APP', {
          appName: application.name,
        });
        const defaultPage = response.data.pages.find((page) => page.isDefault);
        const defaultPageSlug = defaultPage?.slug || PLACEHOLDER_PAGE_SLUG;
        // This sets ui.pageWidgets = {} to ensure that
        // widgets are cleaned up from state before
        // finishing creating a new application
        yield put({
          type: ReduxActionTypes.RESET_APPLICATION_WIDGET_STATE_REQUEST,
        });
        yield put({
          type: ReduxActionTypes.CREATE_APPLICATION_SUCCESS,
          payload: {
            orgId,
            application,
          },
        });
        const isFirstTimeUserOnboardingEnabled = yield select(
          getEnableFirstTimeUserOnboarding
        );
        const FirstTimeUserOnboardingApplicationId = yield select(
          getFirstTimeUserOnboardingApplicationId
        );
        let pageURL;
        if (
          isDemoApp ||
          (isFirstTimeUserOnboardingEnabled &&
            FirstTimeUserOnboardingApplicationId === '')
        ) {
          yield put({
            type:
              ReduxActionTypes.SET_FIRST_TIME_USER_ONBOARDING_APPLICATION_ID,
            payload: application.id,
          });
          pageURL = builderURL({
            applicationId: application.id,
            applicationVersion: application.applicationVersion,
            applicationSlug: application.slug as string,
            pageSlug: defaultPageSlug,
            pageId: application.defaultPageId as string,
            params: isDemoApp ? { appType: 'demo' } : {},
          });
        } else {
          pageURL = generateTemplateURL({
            applicationId: application.id,
            applicationVersion: application.applicationVersion,
            applicationSlug: application.slug as string,
            pageSlug: defaultPageSlug,
            pageId: application.defaultPageId as string,
            params,
          });
        }
        // history.push(pageURL);
        const pageSlug = defaultPageSlug;
        const applicationSlug = application.slug;
        const pageId = application.defaultPageId;
        type routeId = {
          applicationSlug: string;
          pageId: string;
          pageSlug: string;
        };
        const routeToEmptyEditorFromGenPage = ({
          applicationSlug,
          pageId,
          pageSlug,
        }: routeId): void => {
          AnalyticsUtil.logEvent('BUILD_FROM_SCRATCH_ACTION_CARD_CLICK');
          history.push(
            builderURL({ applicationSlug, pageSlug, pageId, params })
          );
        };

        routeToEmptyEditorFromGenPage({
          applicationSlug,
          pageSlug,
          pageId,
        });

        // subscribe to newly created application
        // users join rooms on connection, so reconnecting
        // ensures user receives the updates in the app just created
        yield put(reconnectAppLevelWebsocket());
        yield put(reconnectPageLevelWebsocket());
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_APPLICATION_ERROR,
      payload: {
        error,
        show: false,
        orgId,
      },
    });
  }
}

export function* forkApplicationSaga(
  action: ReduxAction<ForkApplicationRequest>
) {
  try {
    const response: ApiResponse = yield call(
      ApplicationApi.forkApplication,
      action.payload
    );
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      yield put(resetCurrentApplication());
      const application: ApplicationPayload = {
        ...response.data,
        defaultPageId: getDefaultPageId(response.data.pages),
      };
      yield put({
        type: ReduxActionTypes.FORK_APPLICATION_SUCCESS,
        payload: {
          orgId: action.payload.organizationId,
          application,
        },
      });
      const defaultPage = response.data.pages.find(
        (page: ApplicationPagePayload) => page.isDefault
      );
      const pageURL = builderURL({
        applicationVersion: application.applicationVersion,
        applicationId: application.id,
        applicationSlug: application.slug || PLACEHOLDER_APP_SLUG,
        pageSlug: defaultPage.slug || PLACEHOLDER_PAGE_SLUG,
        pageId: application.defaultPageId as string,
      });
      history.push(pageURL);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FORK_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* showReconnectDatasourcesModalSaga(
  action: ReduxAction<{
    application: ApplicationResponsePayload;
    unConfiguredDatasourceList: Array<Datasource>;
    orgId: string;
  }>
) {
  const { application, orgId, unConfiguredDatasourceList } = action.payload;
  yield put(getAllApplications());
  yield put(importApplicationSuccess(application));
  yield put(fetchPlugins({ orgId }));

  yield put(
    setUnconfiguredDatasourcesDuringImport(unConfiguredDatasourceList || [])
  );

  yield put(setOrgIdForImport(orgId));
  yield put(setIsReconnectingDatasourcesModalOpen({ isOpen: true }));
}

export function* importApplicationSaga(
  action: ReduxAction<ImportApplicationRequest>
) {
  try {
    let response: ApiResponse = yield call(
      ApplicationApi.importApplicationToOrg,
      action.payload
    );

    response = {
      ...response,
      data: {
        application: {
          ...response.data,
        },
        isPartialImport: false,
      },
    };
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      const allOrgs: Org[] = yield select(getCurrentOrg);
      const currentOrg = allOrgs.filter(
        (el: Org) => el.id === action.payload.orgId
      );
      if (currentOrg.length > 0) {
        const {
          application: { applicationVersion, id, pages, slug: applicationSlug },
          isPartialImport,
        }: {
          application: {
            id: string;
            slug: string;
            applicationVersion: number;
            pages: {
              default?: boolean;
              id: string;
              isDefault?: boolean;
              slug: string;
            }[];
          };
          isPartialImport: boolean;
        } = response.data;

        yield put(importApplicationSuccess(response.data?.application));

        if (isPartialImport) {
          yield put(
            showReconnectDatasourceModal({
              application: response.data?.application,
              unConfiguredDatasourceList:
                response?.data.unConfiguredDatasourceList,
              orgId: action.payload.orgId,
            })
          );
        } else {
          const defaultPage = pages.filter((eachPage) => !!eachPage.isDefault);
          const pageURL = builderURL({
            applicationSlug: applicationSlug ?? PLACEHOLDER_APP_SLUG,
            applicationId: id,
            applicationVersion: ApplicationVersion.SLUG_URL,
            pageSlug: defaultPage[0].slug || PLACEHOLDER_PAGE_SLUG,
            pageId: defaultPage[0].id,
          });
          history.push(pageURL);
          const guidedTour: boolean = yield select(inGuidedTour);

          if (guidedTour) return;

          Toaster.show({
            text: 'Application imported successfully',
            variant: Variant.success,
          });
        }
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.IMPORT_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* fetchReleases() {
  try {
    const response: FetchUsersApplicationsOrgsResponse = yield call(
      ApplicationApi.getAllApplication
    );
    const isValidResponse = yield validateResponse(response);
    if (isValidResponse) {
      const { newReleasesCount, releaseItems } = response.data || {};
      yield put({
        type: ReduxActionTypes.FETCH_RELEASES_SUCCESS,
        payload: { newReleasesCount, releaseItems },
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_RELEASES_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* fetchUnconfiguredDatasourceList(
  action: ReduxAction<{
    applicationId: string;
    orgId: string;
  }>
) {
  try {
    // Get endpoint based on app mode
    const response: FetchUnconfiguredDatasourceListResponse = yield call(
      ApplicationApi.fetchUnconfiguredDatasourceList,
      action.payload
    );

    yield put(setUnconfiguredDatasourcesDuringImport(response.data || []));
  } catch (error) {
    yield put(setUnconfiguredDatasourcesDuringImport([]));
    yield put({
      type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* initializeDatasourceWithDefaultValues(datasource: Datasource) {
  if (!datasource.datasourceConfiguration) {
    yield call(checkAndGetPluginFormConfigsSaga, datasource.pluginId);
    const formConfig = yield select(getPluginForm, datasource.pluginId);
    const initialValues = yield call(getConfigInitialValues, formConfig);
    const payload = merge(initialValues, datasource);
    payload.isConfigured = false; // imported datasource as not configured yet
    const response = yield DatasourcesApi.updateDatasource(
      payload,
      datasource.id
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.UPDATE_DATASOURCE_IMPORT_SUCCESS,
        payload: response.data,
      });
    }
  }
}

function* initDatasourceConnectionDuringImport(action: ReduxAction<string>) {
  const orgId = action.payload;

  const pluginsAndDatasourcesCalls: boolean = yield failFastApiCalls(
    [fetchPlugins({ orgId }), fetchDatasources({ orgId })],
    [
      ReduxActionTypes.FETCH_PLUGINS_SUCCESS,
      ReduxActionTypes.FETCH_DATASOURCES_SUCCESS,
    ],
    [
      ReduxActionErrorTypes.FETCH_PLUGINS_ERROR,
      ReduxActionErrorTypes.FETCH_DATASOURCES_ERROR,
    ]
  );
  if (!pluginsAndDatasourcesCalls) return;

  const pluginFormCall: boolean = yield failFastApiCalls(
    [fetchPluginFormConfigs()],
    [ReduxActionTypes.FETCH_PLUGIN_FORM_CONFIGS_SUCCESS],
    [ReduxActionErrorTypes.FETCH_PLUGIN_FORM_CONFIGS_ERROR]
  );
  if (!pluginFormCall) return;

  const datasources: Array<Datasource> = yield select((state: AppState) => {
    return state.entities.datasources.list;
  });

  yield all(
    datasources.map((datasource: Datasource) =>
      call(initializeDatasourceWithDefaultValues, datasource)
    )
  );

  yield put(initDatasourceConnectionDuringImportSuccess());
}

export default function* applicationSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.PUBLISH_APPLICATION_INIT,
      publishApplicationSaga
    ),
    takeLatest(ReduxActionTypes.UPDATE_APP_LAYOUT, updateApplicationLayoutSaga),
    takeLatest(ReduxActionTypes.UPDATE_APPLICATION, updateApplicationSaga),
    takeLatest(
      ReduxActionTypes.CHANGE_APPVIEW_ACCESS_INIT,
      changeAppViewAccessSaga
    ),
    takeLatest(
      ReduxActionTypes.GET_ALL_APPLICATION_INIT,
      getAllApplicationSaga
    ),
    takeLatest(ReduxActionTypes.FETCH_APPLICATION_INIT, fetchAppAndPagesSaga),
    takeLatest(ReduxActionTypes.FORK_APPLICATION_INIT, forkApplicationSaga),
    takeLatest(ReduxActionTypes.CREATE_APPLICATION_INIT, createApplicationSaga),
    takeLatest(
      ReduxActionTypes.SET_DEFAULT_APPLICATION_PAGE_INIT,
      setDefaultApplicationPageSaga
    ),
    takeLatest(
      ReduxActionTypes.SET_APPLICATION_PAGE_FULLSCREEN_INIT,
      togglePageFullscreenSaga
    ),
    takeLatest(ReduxActionTypes.DELETE_APPLICATION_INIT, deleteApplicationSaga),
    takeLatest(
      ReduxActionTypes.DUPLICATE_APPLICATION_INIT,
      duplicateApplicationSaga
    ),
    takeLatest(ReduxActionTypes.IMPORT_APPLICATION_INIT, importApplicationSaga),
    takeLatest(ReduxActionTypes.FETCH_RELEASES, fetchReleases),
    takeLatest(
      ReduxActionTypes.INIT_DATASOURCE_CONNECTION_DURING_IMPORT_REQUEST,
      initDatasourceConnectionDuringImport
    ),
    takeLatest(
      ReduxActionTypes.SHOW_RECONNECT_DATASOURCE_MODAL,
      showReconnectDatasourcesModalSaga
    ),
    takeLatest(
      ReduxActionTypes.FETCH_UNCONFIGURED_DATASOURCE_LIST,
      fetchUnconfiguredDatasourceList
    ),
    takeLatest(
      ReduxActionTypes.RELEASE_TEMPLATE_APPLICATION_INIT,
      releaseTemplateApplicationSaga
    ),
    // takeLatest(
    //   ReduxActionTypes.FETCH_TEMPLATE_APPLICATIONS,
    //   fetchTemplateApplicationsSaga
    // ),
  ]);
}
function installURL(arg0: {
  applicationSlug: any;
  pageId: string;
  applicationId: string;
}): string {
  throw new Error('Function not implemented.');
}
