import { v4 as uuid } from 'uuid';
// import fullJoinIcon from '@/assets/full.svg';
// import leftJoinIcon from '@/assets/left.svg';
// import rightJoinIcon from '@/assets/right.svg';
// import innerJoinIcon from '@/assets/inner.svg';

export const NodeTypes = {
  table: 'table',
  filter: 'filter',
  join: 'join',
  union: 'union',
  group: 'group',
  groupConcat: 'groupConcat',
  except: 'except',
  delta: 'delta',
  alter: 'alter',
  sort: 'sort',
  distinct: 'distinct',
};

export const TwoInputsNodeTypes = [
  NodeTypes.join,
  NodeTypes.union,
  NodeTypes.except,
  NodeTypes.delta,
];

export const NodeTypeLabels = {
  table: '表',
  filter: '筛选',
  join: '交集',
  union: '合并',
  group: '分组',
  delta: '偏差',
  except: '差集',
  alter: '数据加工',
  sort: '字段排序',
  distinct: '数据去重',
};

export const ProcessTypesKeys = {
  Substring: 'substring',
  Replace: 'replace',
  Convert: 'convert',
  ColumnOp: 'columnOp',
  Trim: 'trim',
  Concat: 'concat',
  DayOfWeek: 'dayOfWeek',
  DateOp: 'dateOp',
  EmptyReplace: 'emptyReplace',
  NewColumn: 'newColumn',
  SameRingRatio: 'sameRingRatio',
  UnifiedFormat: 'unifiedFormat',
  TimeConvert: 'timeConvert',
  IdCardConvert: 'idCardConvert',
};

export const ColumnOpSubTypes = {
  column_value: 'column_value',
  column_column: 'column_column',
};

export const DateOpSubTypes = {
  plain: 'plain',
  interval: 'interval',
};

export const ProcessTypes = {
  [ProcessTypesKeys.Substring]: '截取',
  [ProcessTypesKeys.Replace]: '内容替换',
  [ProcessTypesKeys.Convert]: '字段类型变更',
  [ProcessTypesKeys.Trim]: '首尾去空格',
  [ProcessTypesKeys.Concat]: '内容拼接',
  [ProcessTypesKeys.TimeConvert]: '时间转换',
  [ProcessTypesKeys.IdCardConvert]: '身份证转换',
};

export const ProcessTypes2 = {
  [ProcessTypesKeys.DayOfWeek]: '星期几',
  [ProcessTypesKeys.DateOp]: '日期加减',
};

export const ProcessTypes3 = {
  [ProcessTypesKeys.ColumnOp]: '算数运算',
};

export const ProcessTypes4 = {
  [ProcessTypesKeys.EmptyReplace]: '空值替换',
  [ProcessTypesKeys.NewColumn]: '添加列',
  [ProcessTypesKeys.SameRingRatio]: '同环比',
  [ProcessTypesKeys.UnifiedFormat]: '统一格式',
};

export const ModelTypes = {
  My: 1,
  Favorite: 2,
};

export const ReplaceContent = 'replace_content';
export const ReplacePosition = 'replace_position';

export const ReplaceTypes = {
  [ReplaceContent]: '内容替换',
  [ReplacePosition]: '位置替换',
};

export const AlterInitObj = {
  id: uuid(),
  name: '加工1',
  fields: [],
  type: '',
  config: {},
};

export const EditMode = { create: 'create', edit: 'edit' };

export const SelectedStyle = {
  color: '#fff',
  background: 'linear-gradient(0deg,rgba(56,116,226,1),rgba(96,156,255,1))',
  boxShadow: '0px 8px 25px 0px rgba(38,96,178,0.5)',
};

export const ExcludePanClass = 'custom-excluded-class';

export const OpType = {
  and: '且',
  or: '或',
};

//分、小时、天、周、月
export const TimeUnitType = {
  minute: '分',
  hour: '时',
  day: '天',
  week: '周',
  month: '月',
};

export const JoinTypes = {
  inner: '精准连接',
  full: '全外连接',
  left: '左外连接',
  right: '右外连接',
};

export const groupFilter = [
  {
    id: 47,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'aggr_type',
    code: 'count',
    name: '求次数',
    sort: 10,
    icon: null,
  },
  {
    id: 48,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'aggr_type',
    code: 'avg',
    name: '求平均值',
    sort: 20,
    icon: null,
  },
  {
    id: 49,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'aggr_type',
    code: 'max',
    name: '求最大值',
    sort: 30,
    icon: null,
  },
  {
    id: 50,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'aggr_type',
    code: 'min',
    name: '求最小值',
    sort: 40,
    icon: null,
  },
  {
    id: 51,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'aggr_type',
    code: 'sum',
    name: '求和',
    sort: 50,
    icon: null,
  },
  {
    id: 157,
    createTime: '2021-05-08T16:21:18.000+0800',
    updateTime: '2021-05-08T16:21:18.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'aggr_type',
    code: 'max_freq',
    name: '求最大次数值的数据',
    sort: 60,
    icon: null,
  },
  {
    id: 159,
    createTime: '2021-05-08T16:22:36.000+0800',
    updateTime: '2021-05-08T16:22:36.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'aggr_type',
    code: 'max_col',
    name: '求最大值的行',
    sort: 70,
    icon: null,
  },
  {
    id: 167,
    createTime: '2021-05-11T14:52:00.000+0800',
    updateTime: '2021-05-11T14:52:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'aggr_type',
    code: 'classify',
    name: '求类别',
    sort: 80,
    icon: null,
  },
];

export const sortType = [
  {
    code: 'asc',
    name: '升序',
  },
  {
    code: 'desc',
    name: '降序',
  },
];
export const deviationType = [
  {
    id: 57,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'bias_condition',
    code: 'abs',
    name: '绝对值',
    sort: 10,
    icon: null,
  },
  {
    id: 58,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'bias_condition',
    code: '>',
    name: '正值',
    sort: 20,
    icon: null,
  },
  {
    id: 59,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'bias_condition',
    code: '<',
    name: '负值',
    sort: 30,
    icon: null,
  },
];

// export const JoinTypeIcons = {
//   full: fullJoinIcon,
//   left: leftJoinIcon,
//   right: rightJoinIcon,
//   inner: innerJoinIcon,
// };

export const TreeNodeTypes = {
  model: 'model',
  folder: 'folder',
};

export const MaxNoOmitColumn = 8;

// 是否已推送到cbi
export const PUSH_CBI_STATUS = {
  UNPUSHED: { value: 0, label: '推送' },
  PUSHED: { value: 1, label: '已推送' },
  CANCEL: { value: 2, label: '取消推送' },
};

// 是否可以推送
export const PUSH_AVAILABLE = 2;

// response codes
export const RespStatus = { success: 0 };

// TODO: switch off this one
export const Debug = false;

export const fieldTypes = [
  {
    id: 90,
    createTime: '2020-01-13T16:27:24.000+0800',
    updateTime: '2020-01-13T16:27:24.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'data_type',
    code: 'string',
    name: '文本',
    sort: 10,
    icon: null,
  },
  {
    id: 92,
    createTime: '2020-01-13T16:28:08.000+0800',
    updateTime: '2020-01-13T16:28:08.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'data_type',
    code: 'date',
    name: '日期',
    sort: 20,
    icon: null,
  },
  {
    id: 91,
    createTime: '2020-01-13T16:27:47.000+0800',
    updateTime: '2020-01-13T16:27:47.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'data_type',
    code: 'int',
    name: '数值(整数)',
    sort: 30,
    icon: null,
  },
  {
    id: 103,
    createTime: '2020-01-15T11:21:13.000+0800',
    updateTime: '2020-01-15T11:21:13.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'data_type',
    code: 'double',
    name: '数值(保留两位小数)',
    sort: 40,
    icon: null,
  },
];

export const formatTypes = [
  {
    id: 127,
    createTime: '2020-04-15T17:02:51.000+0800',
    updateTime: '2020-04-15T17:02:51.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyyMMdd$%Y%m%d$YYYYMMDD',
    name: '20200123',
    sort: 10,
    icon: null,
  },
  {
    id: 128,
    createTime: '2020-04-15T17:02:51.000+0800',
    updateTime: '2020-04-15T17:02:51.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyy-MM-dd$%Y-%m-%d$YYYY-MM-DD',
    name: '2020-01-23',
    sort: 20,
    icon: null,
  },
  {
    id: 129,
    createTime: '2020-04-15T17:02:52.000+0800',
    updateTime: '2020-04-15T17:02:52.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyyMMddHHmmss$%Y%m%d%H%i%s$YYYYMMDDHH24miss',
    name: '20200123100000',
    sort: 30,
    icon: null,
  },
  {
    id: 130,
    createTime: '2020-04-15T17:02:52.000+0800',
    updateTime: '2020-04-15T17:02:52.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyy-MM-dd HH:mm:ss.S$%Y-%m-%d %H:%i:%s$YYYY-MM-DD HH24:mi:SS.MS',
    name: '2020-01-23 10:00:00.0',
    sort: 40,
    icon: null,
  },
  {
    id: 131,
    createTime: '2020-04-15T17:02:53.000+0800',
    updateTime: '2020-04-15T17:02:53.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyy-MM-dd HH:mm:ss$%Y-%m-%d %H:%i:%s$YYYY-MM-DD HH24:mi:ss',
    name: '2020-01-23 10:00:00',
    sort: 50,
    icon: null,
  },
  {
    id: 132,
    createTime: '2020-04-15T17:02:53.000+0800',
    updateTime: '2020-04-15T17:02:53.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyyMMdd HH:mm:ss$%Y%m%d %H:%i:%s$YYYYMMDD HH24:mi:ss',
    name: '20200123 10:00:00',
    sort: 60,
    icon: null,
  },
  {
    id: 133,
    createTime: '2020-04-15T17:02:54.000+0800',
    updateTime: '2020-04-15T17:02:54.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyy-MM-dd HH:mm$%Y-%m-%d %H:%i$YYYY-MM-DD HH24:mi',
    name: '2020-01-23 10:00',
    sort: 70,
    icon: null,
  },
  {
    id: 134,
    createTime: '2020-04-15T17:02:54.000+0800',
    updateTime: '2020-04-15T17:02:54.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyyMMddHHmm$%Y%m%d%H%i$YYYYMMDDHH24mi',
    name: '202001231000',
    sort: 80,
    icon: null,
  },
  {
    id: 135,
    createTime: '2020-04-15T17:02:55.000+0800',
    updateTime: '2020-04-15T17:02:55.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyy/MM/dd$%Y/%m/%d$YYYY/MM/DD',
    name: '2020/01/23',
    sort: 90,
    icon: null,
  },
  {
    id: 142,
    createTime: '2020-04-15T17:02:55.000+0800',
    updateTime: '2020-04-15T17:02:55.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'hive_date_format',
    code: 'yyyy/MM/dd HH:mm:ss$%Y/%m/%d %H:%i:%s$YYYY/MM/DD HH24:mi:ss',
    name: '2020/01/23 10:00:00',
    sort: 90,
    icon: '',
  },
];

export const plainTypes = [
  {
    id: 137,
    createTime: '2020-04-17T18:42:43.000+0800',
    updateTime: '2020-04-17T18:42:43.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'date_op',
    code: '+',
    name: '加',
    sort: 10,
    icon: null,
  },
  {
    id: 138,
    createTime: '2020-04-17T18:42:59.000+0800',
    updateTime: '2020-04-17T18:42:59.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'date_op',
    code: '-',
    name: '减',
    sort: 20,
    icon: null,
  },
];

export const mathTypes = [
  {
    id: 42,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'math',
    code: '+',
    name: '加',
    sort: 10,
    icon: null,
  },
  {
    id: 43,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'math',
    code: '-',
    name: '减',
    sort: 20,
    icon: null,
  },
  {
    id: 44,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'math',
    code: '*',
    name: '乘',
    sort: 30,
    icon: null,
  },
  {
    id: 45,
    createTime: '2019-12-26T16:41:00.000+0800',
    updateTime: '2019-12-26T16:41:00.000+0800',
    createUser: 0,
    updateUser: null,
    isDelete: 0,
    type: 'math',
    code: '/',
    name: '除',
    sort: 40,
    icon: null,
  },
];

export const formatTypes_1 = [
  {
    id: 139,
    createTime: '2020-04-21T14:37:01.000+0800',
    updateTime: '2020-04-21T14:37:01.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'format_type',
    code: 'percent',
    name: '百分比',
    sort: 10,
    icon: null,
  },
  {
    id: 140,
    createTime: '2020-04-21T14:37:02.000+0800',
    updateTime: '2020-04-21T14:37:02.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'format_type',
    code: 'lower',
    name: '大写转小写\t',
    sort: 20,
    icon: null,
  },
  {
    id: 141,
    createTime: '2020-04-21T14:37:03.000+0800',
    updateTime: '2020-04-21T14:37:03.000+0800',
    createUser: null,
    updateUser: null,
    isDelete: 0,
    type: 'format_type',
    code: 'upper',
    name: '小写转大写\t',
    sort: 30,
    icon: null,
  },
];
