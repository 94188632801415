import React, { useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxActionTypes } from 'ce/constants/ReduxActionConstants';
import { useReactToPrint } from 'react-to-print';
import { AppState } from 'reducers/index';

import ComponentToPrint from './ComponentToPrint';
import ErrorBoundary from 'components/editorComponents/ErrorBoundry';

export default function FunctionalComponentWithHook() {
  const dispatch = useDispatch();
  const componentRef = useRef(null);

  const printInfo = useSelector(
    (state: AppState) => state.entities.widgetConfig.pdfPrintInfo
  );

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleAfterPrint = useCallback(() => {
    dispatch({
      type: ReduxActionTypes.ADD_PDF_PRINT_INFO,
      payload: {},
    });
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    // documentTitle: 'AwesomeFileName',
    // onBeforeGetContent<Promise>: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  useEffect(() => {
    Object.keys(printInfo || {}).length && handlePrint();
  }, [printInfo]);

  return (
    <ErrorBoundary>
      <div className="hidden">
        <div ref={componentRef}>
          <ComponentToPrint {...printInfo} />
        </div>
      </div>
    </ErrorBoundary>
  );
}
