import { Log, Severity } from 'entities/AppsmithConsole';
import React from 'react';
import styled from 'styled-components';
import { getTypographyByKey } from 'constants/DefaultTheme';
import {
  createMessage,
  OPEN_THE_DEBUGGER,
  PRESS,
} from '@appsmith/constants/messages';
import { DependencyMap } from 'utils/DynamicBindingUtils';
import {
  matchBuilderPath,
  matchApiPath,
  matchQueryPath,
} from 'constants/routes';
import { getEntityNameAndPropertyPath } from 'workers/evaluationUtils';
import { modText } from 'utils/helpers';

const BlankStateWrapper = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.debugger.blankState.color};
  ${(props) => getTypographyByKey(props, 'p1')}

  .debugger-shortcut {
    color: ${(props) => props.theme.colors.debugger.blankState.shortcut};
    ${(props) => getTypographyByKey(props, 'h5')}
  }
`;

export function BlankState(props: {
  placeholderText?: string;
  hasShortCut?: boolean;
}) {
  const shortcut = <>{modText()} D</>;

  return (
    <BlankStateWrapper>
      {props.hasShortCut ? (
        <span>
          {createMessage(PRESS)}
          <span className="debugger-shortcut">{shortcut}</span>
          {createMessage(OPEN_THE_DEBUGGER)}
        </span>
      ) : (
        <span className="text-[12px] text-content">
          {props.placeholderText}
        </span>
      )}
    </BlankStateWrapper>
  );
}

export enum DEBUGGER_TAB_KEYS {
  ERROR_TAB = 'ERROR',
  LOGS_TAB = 'LOGS_TAB',
  INSPECT_TAB = 'INSPECT_TAB',
}

export const SeverityIcon: Record<Severity, string> = {
  [Severity.INFO]: 'success',
  [Severity.ERROR]: 'error',
  [Severity.WARNING]: 'warning',
};

export function getDependenciesFromInverseDependencies(
  deps: DependencyMap,
  entityName: string | null
) {
  if (!entityName) return null;

  const directDependencies = new Set<string>();
  const inverseDependencies = new Set<string>();
  const directDependenciesEncypropertyPath = new Map<string, string>();
  const inverseDependenciesEncypropertyPath = new Map<string, string>();

  Object.entries(deps).forEach(([dependant, dependencies]) => {
    const {
      entityName: entity,
      propertyPath: entityEncypropertyPath,
    } = getEntityNameAndPropertyPath(dependant);
    (dependencies as any).map((dependency: any) => {
      const {
        entityName: entityDependency,
        propertyPath: entityDependencyEncypropertyPath,
      } = getEntityNameAndPropertyPath(dependency);
      if (entity !== entityName && entityDependency === entityName) {
        // 依赖的query
        directDependencies.add(entity);
        directDependenciesEncypropertyPath.set(
          // 组件自身指向依赖的属性
          entityDependencyEncypropertyPath,
          //数据名称
          entity
        );
      } else if (entity === entityName && entityDependency !== entityName) {
        inverseDependencies.add(entityDependency);
        inverseDependenciesEncypropertyPath.set(
          // 被依赖组件指向自身的属性
          entityEncypropertyPath,
          // 被依赖组件
          entityDependency
        );
      }
    });
  });

  return {
    inverseDependencies: Array.from(inverseDependencies),
    directDependencies: Array.from(directDependencies),
    directDependenciesEncypropertyPath,
    inverseDependenciesEncypropertyPath,
  };
}

// Recursively find out dependency chain from
// the inverse dependency map
export function getDependencyChain(
  propertyPath: string,
  inverseMap: DependencyMap
) {
  let currentChain: string[] = [];
  const dependents = inverseMap[propertyPath];

  if (!dependents) return currentChain;

  const dependentInfo = getEntityNameAndPropertyPath(propertyPath);

  // TODO RangeError: Maximum call stack size exceeded
  dependents.map((e) => {
    if (!e.includes(dependentInfo.entityName)) {
      currentChain.push(e);
    }

    if (e !== dependentInfo.entityName) {
      currentChain = currentChain.concat(getDependencyChain(e, inverseMap));
    }
  });
  return currentChain;
}

export const doesEntityHaveErrors = (
  entityId: string,
  debuggerErrors: Record<string, Log>
) => {
  const ids = Object.keys(debuggerErrors);

  return ids.some((e: string) => e.includes(entityId));
};

export const onApiEditor = () => {
  return matchApiPath(window.location.pathname);
};

export const onQueryEditor = () => {
  return matchQueryPath(window.location.pathname);
};

export const onCanvas = () => {
  return matchBuilderPath(window.location.pathname);
};
