import { Colors } from 'constants/Colors';
import styled from 'styled-components';

export default styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${Colors.CHARCOAL};
  margin-bottom: 8px;
`;
