// Color Names generated from http://chir.ag/projects/name-that-color
export const Colors = {
  EBONY_CLAY: '#2A2F3D',
  EBONY_CLAY_2: '#22223B',
  MOUNTAIN_MEADOW: '#27CA40',
  PERSIMMON: '#FF6058',
  WHITE: '#FFFFFF',
  WHITE_SMOKE: '#F4F4F4',
  POLAR: '#E9FAF3',
  GEYSER: '#D3DEE3',
  GEYSER_LIGHT: '#E7E7E7',
  ATHENS_GRAY: '#EBEFF2',
  CONCRETE: '#F3F3F3',
  MYSTIC: '#E1E8ED',
  AQUA_HAZE: '#EEF2F5',
  GRAY_CHATEAU: '#A2A6A8',
  DARK_GRAY: '#A9A7A7',
  LIGHT_GREYISH_BLUE: '#B0BFCB',
  SUNGLOW: '#FFCB33',
  SUNGLOW_2: '#FFC130',
  SOFT_ORANGE: '#f7c75b',
  BINDING_COLOR: '#ffcc99',
  BINDING_COLOR_LT: '#22a21f',
  PURE_ORANGE: '#ffb100',
  WHITE_CLOUD: '#D3DEE3',
  GOLD: '#FFD300',
  BLACK: '#000000',
  BLACK_PEARL: '#040627',
  CODE_GRAY: '#090707',
  DIESEL: '#0C0000',
  SHARK: '#21282C',
  SHARK2: '#232324',
  _2: '#262626',
  DEEP_SPACE: '#272E32',
  OUTER_SPACE: '#363E44',
  TUNDORA: '#404040',
  DOVE_GRAY: '#6D6D6D',
  SLATE_GRAY: '#2494F4',
  SILVER_CHALICE: '#A9A7A7',
  PORCELAIN: '#EBEEF0',
  HIT_GRAY: '#A1ACB3',
  JUNGLE_MIST: '#BCCCD9',
  MERCURY: '#E8E8E8',
  MERCURY_1: '#E3E3E3',
  MERCURY_2: '#E7E7E7',
  MAKO: '#464D53',
  ALTO: '#DFDFDF',

  FOAM: '#D9FDED',
  GREEN: 'var(--highlight)',
  LIGHT_GREEN_CYAN: '#e5f6ec',
  JUNGLE_GREEN: '#24BA91',
  JUNGLE_GREEN_DARKER: '#30A481',
  FERN_GREEN: '#50AF6C',
  NARVIK_GREEN: '#ECF9F3',
  JAGGED_ICE: '#CAECDC',
  EUCALYPTUS: '#218358',
  RED: '#B30338',
  ERROR_RED: '#E22C2C',
  PURPLE: '#6871EF',
  OXFORD_BLUE: '#2E3D49',
  FRENCH_PASS: '#BBE8FE',
  CADET_BLUE: '#A3B3BF',
  JAFFA: '#F2994A',
  BLUE_BAYOUX: '#4E5D78',
  MINT_TULIP: '#B5F1F1',
  AZURE_RADIANCE: '#0384FE',
  OCEAN_GREEN: '#36AB80',
  BUTTER_CUP: '#F7AF22',
  BLUE_CHARCOAL: '#23292E',
  TROUT: '#4C565E',
  JAFFA_DARK: '#0055FF',
  BURNING_ORANGE: '#FF7742',
  TIA_MARIA: '#CB4810',
  SOLID_MERCURY: '#E5E5E5',
  TROUT_DARK: '#535B62',
  ALABASTER: '#F9F8F8',
  WATUSI: '#83ACFF',
  GRAY: '#858282',
  GRAY2: '#939090',
  DOVE_GRAY2: '#716E6E',
  ATHENS_GRAY_DARKER: '#F8F9FA',
  POMEGRANATE: '#F44336',
  POMEGRANATE2: '#F22B2B',
  RIVER_BED: '#4A545B',
  BALTIC_SEA: '#1A191C',
  THUNDER: '#231F20',
  WHITE_SNOW: '#F7F7F7',
  NERO: '#2B2B2B',
  CHARCOAL: '#4B4848',
  LIGHT_GREY: '#D4D4D4',
  LIGHT_GREY2: '#C4C4C4',
  Gallery: '#F0F0F0',
  GALLERY_1: '#EBEBEB',
  GALLERY_2: '#EDEDED',
  Galliano: '#E0B30E',
  ROYAL_BLUE: '#457AE6',
  ALTO2: '#E0DEDE',
  SEA_SHELL: '#F1F1F1',
  DANUBE: '#6A86CE',
  FAIR_PINK: '#FFE9E9',
  OPAQ_BLUE: 'rgba(106, 134, 206, 0.1)',
  PALE_BLUE: '#E8F5FA',
  CURIOUS_BLUE: '#1D9BD1',
  RATE_ACTIVE: '#FFCB45',
  MALIBU: '#7DBCFF',
  ALABASTER_ALT: '#FAFAFA',
  THUNDER_ALT: '#1D1C1D',
  CRUSTA: '#F86A2B',

  WARNING_SOLID: '#FEB811',
  WARNING_SOLID_HOVER: '#EFA903',
  WARNING_ORANGE: '#FFF8E2',
  WARNING_OUTLINE_HOVER: '#FFFAE9',
  WARNING_GHOST_HOVER: '#FBEED0',

  DANGER_SOLID: '#F22B2B',
  DANGER_SOLID_HOVER: '#B90707',
  DANGER_NO_SOLID_HOVER: '#FDE4E4',

  INFO_SOLID: '#6698FF',
  INFO_SOLID_HOVER: '#1A65FF',
  INFO_NO_SOLID_HOVER: '#CEDCFF',

  PRIMARY_SOLID_HOVER: '#00693B',
  PRIMARY_OUTLINE_HOVER: '#D9FDED',
  PRIMARY_GHOST_HOVER: '#CBF4E2',

  CUSTOM_SOLID_DARK_TEXT_COLOR: '#333',

  BOX_SHADOW_DEFAULT_VARIANT1: 'rgba(0, 0, 0, 0.25)',
  BOX_SHADOW_DEFAULT_VARIANT2: 'rgba(0, 0, 0, 0.25)',
  BOX_SHADOW_DEFAULT_VARIANT3: 'rgba(0, 0, 0, 0.5)',
  BOX_SHADOW_DEFAULT_VARIANT4: 'rgba(0, 0, 0, 0.25)',
  BOX_SHADOW_DEFAULT_VARIANT5: 'rgba(0, 0, 0, 0.25)',

  BUTTON_CUSTOM_SOLID_DARK_TEXT_COLOR: '#333',
  BUTTON_DISABLED: '#c2c5c7',

  SELECT_DISABLED: '#ced9e080',
  SELECT_PLACEHOLDER: '#bfbfbf',
  SELECT_COLOR: '#182026',

  INPUT_TEXT_DISABLED: 'rgba(92, 112, 128, 0.6)',
  INPUT_DISABLED: 'rgba(206, 217, 224, 0.5)',
  //  Following Design System colors
  GREY_1: '#FAFAFA',
  GREY_2: '#F0F0F0',
  GREY_3: '#EBEBEB',
  GREY_4: '#E8E8E8',
  GREY_5: '#E0DEDE',
  GREY_6: '#A9A7A7',
  GREY_7: '#858282',
  GREY_8: '#716E6E',
  GREY_9: '#4B4848',
  GREY_10: '#090707',
  GREY_11: '#9F9F9F',
  GREY_200: '#E7E7E7',
  GREY_800: '#393939',

  PRIMARY_ORANGE: '#F86A2B',

  GREEN_SOLID: '#50AF6C',
  MIRAGE: '#182026',
  MIRAGE_2: '#121826',

  GREEN_SOLID_HOVER: '#D5EFE3',
  GREEN_SOLID_LIGHT_HOVER: '#ECF9F3',
  GREEN_1: '#50AF6C',
  GREEN_2: '#D5EFE3',
  GREEN_3: '#ECF9F3',
  MASALA: '#43403D',
  // error warning
  CRIMSON: '#D71010',
  ALTO_3: '#D6D6D6',
  YELLOW_LIGHT: '#F4AF0A',
  BROWN: '#864C17',

  GRAY_700: '#575757',
  GRAY_800: '#393939',
  GRAY_900: '#101828',
  SCORPION: '#575757',

  COD_GRAY: '#191919',
  MINE_SHAFT_2: '#333333',
  SECOND: '#FFA474',
  MAIN_COLOR: '#FF5800',
};
export type Color = typeof Colors[keyof typeof Colors];
