import React, { useMemo } from 'react';
import Icon from 'components/icon';

import ArrowDownIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpIcon from 'remixicon-react/ArrowUpSLineIcon';
import ArrowLeftIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightIcon from 'remixicon-react/ArrowRightSLineIcon';
import HelpIcon from 'remixicon-react/QuestionLineIcon';
import OpenNewTabIcon from 'remixicon-react/ShareBoxLineIcon';
import ServerLineIcon from 'remixicon-react/ServerLineIcon';

import styled from 'styled-components';
import { Size } from './Button';
import { CommonComponentProps, Classes } from './common';

export const AppIconCollection = [
  'bag',
  'product',
  'book',
  'camera',
  'file',
  'chat',
  'calender',
  'flight',
  'frame',
  'globe',
  'shopper',
  'heart',
  'alien',
  'bar-graph',
  'basketball',
  'bicycle',
  'bird',
  'bitcoin',
  'burger',
  'bus',
  'call',
  'car',
  'card',
  'cat',
  'chinese-remnibi',
  'cloud',
  'coding',
  'couples',
  'cricket',
  'diamond',
  'dog',
  'dollar',
  'earth',
  'email',
  'euros',
  'family',
  'flag',
  'football',
  'hat',
  'headphones',
  'hospital',
  'joystick',
  'laptop',
  'line-chart',
  'location',
  'lotus',
  'love',
  'medal',
  'medical',
  'money',
  'moon',
  'mug',
  'music',
  'pants',
  'pie-chart',
  'pizza',
  'plant',
  'rainy-weather',
  'restaurant',
  'rocket',
  'rose',
  'rupee',
  'saturn',
  'server',
  'server-line',
  'shake-hands',
  'shirt',
  'shop',
  'single-person',
  'smartphone',
  'snowy-weather',
  'stars',
  'steam-bowl',
  'sunflower',
  'system',
  'team',
  'tree',
  'uk-pounds',
  'website',
  'yen',
  'airplane',
  'arrow-down',
  'arrow-up',
  'arrow-left',
  'arrow-right',
  'help',
  'open-new-tab',
] as const;

export type AppIconName = typeof AppIconCollection[number];

type cssAttributes = {
  width: number;
  height: number;
  padding: number;
};

const appSizeHandler = (size: Size): cssAttributes => {
  let width, height, padding;

  switch (size) {
    case Size.small:
      width = 20;
      height = 20;
      padding = 5;
      break;
    case Size.medium:
      width = 32;
      height = 32;
      padding = 20;
      break;
    case Size.large:
      width = 50;
      height = 50;
      padding = 50;
      break;
    default:
      width = 20;
      height = 20;
      padding = 5;
      break;
  }
  return { width, height, padding };
};

const IconWrapper = styled.a<AppIconProps & { styledProps: cssAttributes }>`
  width: ${(props) => props.styledProps.width}px;
  height: ${(props) => props.styledProps.height}px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: default;
  }
  svg {
    width: ${(props) => props.styledProps.width}px;
    height: ${(props) => props.styledProps.height}px;
    path {
      fill: ${(props) => props.theme.colors.appIcon.normal};
    }
  }
`;

export type AppIconProps = CommonComponentProps & {
  size?: Size;
  name: AppIconName;
  onClick?: (e: any) => void;
};

function AppIcon(props: AppIconProps) {
  const styledProps = useMemo(() => appSizeHandler(props.size || Size.medium), [
    props,
  ]);

  let returnIcon;
  switch (props.name) {
    case 'server-line':
      returnIcon = <ServerLineIcon />;
      break;
    case 'arrow-down':
      returnIcon = <ArrowDownIcon />;
      break;
    case 'arrow-up':
      returnIcon = <ArrowUpIcon />;
      break;
    case 'arrow-left':
      returnIcon = <ArrowLeftIcon />;
      break;
    case 'arrow-right':
      returnIcon = <ArrowRightIcon />;
      break;
    case 'help':
      returnIcon = <HelpIcon />;
      break;
    case 'open-new-tab':
      returnIcon = <OpenNewTabIcon />;
      break;
    default:
      returnIcon = (
        <Icon name={props.name} size={styledProps.width} viewBox="0 0 64 64" />
      );
      break;
  }
  return returnIcon ? (
    <IconWrapper
      data-cy={props.cypressSelector}
      {...props}
      className={[Classes.APP_ICON, props.className].join(' ')}
      styledProps={styledProps}
    >
      {returnIcon}
    </IconWrapper>
  ) : null;
}

export default AppIcon;
