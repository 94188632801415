import { QUERY_EDITOR_FORM_NAME } from 'constants/forms';
import { find, get, uniqBy } from 'lodash';
import {
  applyEdgeChanges,
  applyNodeChanges,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  ReactFlowInstance,
} from 'react-flow-renderer';

// export function getConnectNode() {}

export function removeNode(
  id: string,
  reactFlowInstance: ReactFlowInstance
): void {
  const allNodes = reactFlowInstance.getNodes();
  const allEdges = reactFlowInstance.getEdges();

  let removeIncomersNodes = [reactFlowInstance.getNode(id)].concat(
    getIncomers(reactFlowInstance.getNode(id), allNodes, allEdges)
  );
  const removeIncomersEdegs = getConnectedEdges(
    [reactFlowInstance.getNode(id)],
    allEdges
  );

  const IncomersRightQueryId = find(removeIncomersEdegs, (v) => {
    return v.sourceHandle === 'B';
  })?.target; // query id
  if (IncomersRightQueryId && reactFlowInstance.getNode(id).type !== 'query') {
    return removeNode(IncomersRightQueryId, reactFlowInstance);
  }

  const PerNode =
    find(removeIncomersEdegs, (v) => {
      return v.target === id && !v.targetHandle;
    })?.source || id;

  let node;
  let removeNodes: any = [...removeIncomersNodes];
  let removeEdegs: any = [...removeIncomersEdegs];

  while ((node = removeIncomersNodes.pop())) {
    const nodeTemp = uniqBy(
      getOutgoers(
        reactFlowInstance.getNode(node.id),
        reactFlowInstance.getNodes(),
        reactFlowInstance.getEdges()
      ),
      'id'
    );
    const idelNode = nodeTemp.filter((v) => {
      const nextQuery =
        node.id !== PerNode ||
        v.type !== 'query' ||
        v.id === IncomersRightQueryId;

      if (!nextQuery) {
        get(v, 'data.refresh', () => false)();
      }
      return nextQuery;
    });
    removeIncomersNodes = uniqBy(removeIncomersNodes.concat(idelNode), 'id');
    removeNodes = uniqBy(removeNodes.concat(idelNode), 'id').map((v: any) => {
      return {
        id: v.id,
        type: 'remove',
      };
    });

    removeEdegs = uniqBy(
      removeEdegs.concat(
        getConnectedEdges([reactFlowInstance.getNode(node.id)], allEdges)
      ),
      'id'
    ).map((v: any) => {
      return {
        id: v.id,
        type: 'remove',
      };
    });
  }
  reactFlowInstance.setNodes(applyNodeChanges(removeNodes, allNodes));
  reactFlowInstance.setEdges(applyEdgeChanges(removeEdegs, allEdges));
}
