/* eslint-disable  @typescript-eslint/ban-ts-comment */
import _, { each } from 'lodash';
import { put, debounce, takeEvery, all } from 'redux-saga/effects';
import {
  ReduxAction,
  ReduxActionTypes,
} from '@appsmith/constants/ReduxActionConstants';
import { batchActionSuccess } from 'actions/batchActions';
import * as log from 'loglevel';

const BATCH_PRIORITY = {
  [ReduxActionTypes.SET_META_PROP]: {
    priority: 0,
    needsSaga: false,
  },
  [ReduxActionTypes.RESET_WIDGET_META]: {
    priority: 0,
    needsSaga: false,
  },
  [ReduxActionTypes.UPDATE_WIDGET_PROPERTY]: {
    priority: 0,
    needsSaga: false,
  },
  [ReduxActionTypes.UPDATE_ACTION_PROPERTY]: {
    priority: 0,
    needsSaga: false,
  },
  [ReduxActionTypes.EXECUTE_TRIGGER_REQUEST]: {
    priority: 1,
    needsSaga: true,
  },
  [ReduxActionTypes.EXECUTE_PAGE_LOAD_ACTIONS]: {
    priority: 1,
    needsSaga: true,
  },
  [ReduxActionTypes.UPDATE_ACTION_INIT]: {
    priority: 1,
    needsSaga: true,
  },
};

const batches: ReduxAction<any>[][] = [];

/**
 * example
 * batches = [action1[],action2[]]
 * action1.type 为上面priority == 0 不含batch action
 * action2.type 为上面priority == 0
 */
function* storeUpdatesSaga(action: ReduxAction<ReduxAction<any>>) {
  try {
    // 找优先级
    const priority = BATCH_PRIORITY[action.payload.type].priority;
    const currentPriorityBatch = batches[priority] || [];
    currentPriorityBatch.push(action.payload);
    _.set(batches, `[${priority}]`, currentPriorityBatch);
    yield put({ type: ReduxActionTypes.EXECUTE_BATCH });
  } catch (e) {
    log.error(`${action.payload.type} action priority not set`);
  }
}

function* executeBatchSaga() {
  for (let priority = 0; priority < batches.length; priority++) {
    const batch = batches[priority]; // [{action}]
    if (Array.isArray(batch) && batch.length) {
      const needsSaga = [];
      const canBatch = [];
      each(batch, (b) => {
        BATCH_PRIORITY[b.type].needsSaga ? needsSaga.push(b) : canBatch.push(b);
      });
      batches[priority] = [];
      // @ts-ignore: No types available,不存在依赖关系
      yield put(canBatch);
      if (needsSaga.length) {
        for (const sagaAction of needsSaga) {
          yield put(sagaAction);
        }
      }
      // debugger;
      yield put(batchActionSuccess(batch));
    }
  }
}

export default function* root() {
  yield all([
    debounce(20, ReduxActionTypes.EXECUTE_BATCH, executeBatchSaga),
    takeEvery(ReduxActionTypes.BATCHED_UPDATE, storeUpdatesSaga),
  ]);
}
