export const APP_MODE_KEY = '__APP_MODE__';

export const INSTALL_MODE_VALUE = 'INSTALL';

export const IS_PREVIEW_MODE_KEY = '__IS_PREVIEW_MODE__';

export const IS_PREVIEW_MODE_VALUE = 'true';

export const PREVIEW_SEARCH_PARAM = 'preview';

export const GLOBAL_STATE_PREFIX = 'APPSMITH_LOCAL_STORE-';

export const INSTALL_APP_PROFILE = '__INSTALL_APP_PROFILE__';

export const IS_SUCCESS_CODE = 0;
