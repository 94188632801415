import React, { useRef } from 'react';
import styled from 'styled-components';
import { EditorTheme, EditorModes } from './EditorConfig';
import Icon from 'components/icon';
import {
  PreparedStatementViewer,
  PreparedStatementValue,
} from './EvaluatedValuePopup';
import _ from 'lodash';
import { CurrentValueViewer } from 'components/editorComponents/CodeEditor/EvaluatedValuePopup';

import writeToClipboard from 'utils/hooks/useClipboard';
import { Typography, Divider } from '@arco-design/web-react';
import { isString } from 'lodash';

const Wrapper = styled.span<{
  visible: boolean;
  bottomOffset: number;
  customMessage: boolean;
  editorTheme?: EditorTheme;
  hasError?: boolean;
}>`
  /* padding: ${(props) => (props.customMessage ? 6 : 8)}px; */
  font-size: 12px;
  color: ${(props) => (props.hasError ? '#c32230' : '#2a7e49')};
  border-radius: 0 0 4px 4px;
  padding: 0 4px 4px;
  background-color: ${(props) => (props.hasError ? '#fdedee' : '#e0f5e8')};
  border: 1px solid;
  border-color: ${(props) => (props.hasError ? '#fadbde' : '#71d094')};
  position: absolute;
  bottom: 2px;
  transform: translateY(100%);
  width: 100%;
  line-height: 13px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  z-index: 3;
  max-height: 150px;
  overflow: auto;
  word-break: break-all;
`;

const StyledIcon = styled.div`
  color: #777;
  cursor: pointer;
  transition: transform 0.3s;
  will-change: transform;

  &:hover {
    svg {
      transform: translateY(-2px);
    }
  }
`;

const CurlyBraces = styled.span`
  color: ${(props) => props.theme.colors.codeMirror.background.hoverState};
  background-color: #ffffff;
  border-radius: 2px;
  padding: 2px;
  margin: 0px 2px;
`;

function RenderInner(props) {
  const { hasError = false, errors = [], evaluatedValue = '' } = props;

  if (hasError) {
    return errors.map((i) => i.errorMessage);
  }

  if (props.mode === EditorModes.SQL_WITH_BINDING) {
    return _.isString(props.evaluatedValue) ? (
      props.evaluatedValue
    ) : (
      <PreparedStatementViewer
        evaluatedValue={props.evaluatedValue as PreparedStatementValue}
      />
    );
  }
  //return JSON.stringify(evaluatedValue, null, 2);

  if (isString(evaluatedValue))
    return (
      <Typography>
        <Typography.Paragraph copyable>
          {JSON.stringify(evaluatedValue, null, 2)}
        </Typography.Paragraph>
      </Typography>
    );
  else
    return (
      <Typography>
        <Typography.Paragraph copyable>
          {JSON.stringify(evaluatedValue, null, 2)}
        </Typography.Paragraph>
      </Typography>
    );
}

function BindingPrompt(props: {
  promptMessage?: React.ReactNode | string;
  isOpen: boolean;
  editorTheme?: EditorTheme;
  showLightningMenu?: boolean;
  expected: any;
  hasError: boolean;
  evaluatedValue: any;
  errors: any;
  mode: EditorModes;
}): JSX.Element {
  const promptRef = useRef<HTMLDivElement>(null);
  const customMessage = !!props.promptMessage;
  const bottomOffset = -2;
  const { hasError = false, errors = [], evaluatedValue } = props;

  function handleClick(e) {
    //console.log("Clipboard navigator", navigator);
    //console.log("Clipboard clipboard", navigator.clipboard);
    //console.log("Clipboard writeText", navigator.clipboard.writeText);
    const promise = navigator.clipboard.writeText(props.evaluatedValue);
    //writeToClipboard(props.evaluatedValue);
  }

  return (
    <Wrapper
      bottomOffset={bottomOffset}
      className="t--no-binding-prompt"
      customMessage={customMessage}
      editorTheme={props.editorTheme}
      hasError={hasError}
      ref={promptRef}
      visible
    >
      <div className="flex justify-between py-[4px]">
        <div className="font-[700]">
          {hasError
            ? 'Error'
            : props?.mode === EditorModes.SQL_WITH_BINDING
            ? 'SQL Prepared Statement'
            : props?.expected?.type}
        </div>
        {/*
        <StyledIcon>
          <Icon name="copy-icon" onClick={handleClick} />
        </StyledIcon>
        */}
      </div>
      <RenderInner
        errors={errors}
        evaluatedValue={evaluatedValue}
        hasError={hasError}
        mode={props.mode}
      />
      {/* {msg || ''} */}
      {/* {hasError && error && (
        <ErrorText>
          <span className="t--evaluatedPopup-error">
        
            {error.errorMessage || `此值不符合类型 "${expected?.type}".`}
          </span>
          <EvaluatedValueDebugButton
            entity={props.entity}
            error={{ type: error.errorType, message: error.errorMessage }}
          />
        </ErrorText>
      )}
      {props.expected && props.expected.type !== UNDEFINED_VALIDATION && (
        <>
          <StyledTitle onClick={toggleExpectedDataType}>
            期望数据结构
            <CollapseToggle isOpen={openExpectedDataType} />
          </StyledTitle>
          <Collapse isOpen={openExpectedDataType}>
            <TypeText $colorTheme={props.theme} padded ref={typeTextRef}>
              {props.expected.type}
            </TypeText>
          </Collapse>
        </>
      )}
      {props.expected && props.expected.type !== UNDEFINED_VALIDATION && (
        <>
          <StyledTitle onClick={toggleExpectedExample}>
            期望数据结构 - 示例
            <CollapseToggle isOpen={openExpectedExample} />
          </StyledTitle>
          <Collapse isOpen={openExpectedExample}>
            <TypeText $colorTheme={props.theme} ref={typeTextRef}>
              <CurrentValueViewer
                evaluatedValue={props.expected.example}
                hideLabel
                theme={props.theme}
              />
            </TypeText>
          </Collapse>
        </>
      )}
      {!props.hideEvaluatedValue && (
        <CurrentValueViewer
          evaluatedValue={props.evaluatedValue}
          preparedStatementViewer={props.preparedStatementViewer}
          theme={props.theme}
        />
      )} */}
    </Wrapper>
  );
}

export default BindingPrompt;
