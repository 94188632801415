import { WidgetProps } from 'utils/widgetBridge';
import { DSLWidget } from 'utils/widgetBridge';

export const migrateMapWidgetIsClickedMarkerCentered = (
  currentDSL: DSLWidget
) => {
  currentDSL.children = currentDSL.children?.map((child: WidgetProps) => {
    if (child.type === 'MAP_WIDGET') {
      if (!('isClickedMarkerCentered' in child)) {
        child.isClickedMarkerCentered = true;
      }
    } else if (child.children && child.children.length > 0) {
      child = migrateMapWidgetIsClickedMarkerCentered(child);
    }
    return child;
  });
  return currentDSL;
};
