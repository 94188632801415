import {
  Button,
  Checkbox,
  Input,
  Message,
  Switch,
  Tag,
} from '@arco-design/web-react';
import {
  cloneDeep,
  each,
  filter,
  find,
  get,
  map,
  nth,
  set,
  uniqueId,
} from 'lodash';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Select } from '@arco-design/web-react';
import { IconMinus, IconPlus } from '@arco-design/web-react/icon';
import { JoinTypes } from '../constants';
const Option = Select.Option;
const InputSearch = Input.Search;

type GroupListArr = GroupList[] & { id?: string };

class Group {
  list: GroupListArr[];
  id;
  constructor(initLength) {
    const listItem = map(new Array(initLength), (v) => new GroupList());
    set(listItem, 'id', uniqueId());
    this.list = [listItem];
    this.id = uniqueId();
  }
}

class GroupList {
  alias;
  field;
  id;
  unionAlias;
  type;
  fillField;
  filterCondition;
  constructor() {
    this.id = uniqueId();
  }
}

export default function UnionContent({ data, fields, meta, modal }) {
  const typesData = get(meta, 'typesData');
  const { fields: tableFields, sql, templateSQL } = get(meta, 'tableData');
  const { id, name: mianTable, type } = data.previousData.nodeProperty;
  const associatedNode = get(data, 'associatedNode');
  const [fieldsList, setFieldsList] = useState(cloneDeep(tableFields || []));
  const [associatedNodeMirror, setAssociatedNodeMirror] = useState(
    cloneDeep(associatedNode)
  );

  const [refresh, refreshFx] = useReducer((state) => state + 1, 0);

  const unionID = useRef(meta.FormInfo?.unionID || uniqueId('util_')).current;
  const [groupData, setGroupData] = useState(
    meta.FormInfo?.groupData || [new Group(associatedNode.length + 1)]
  );

  const selectedFields = useMemo(
    () => map(get(groupData, '[0].list', []), (v) => get(v, '[0]')),
    [groupData, refresh]
  );

  const { name } = fields;

  const unionSelect: GroupListArr[] = [];
  each(get(groupData, '[0].list', []) as GroupListArr[], (v) => {
    each(v, (item, i) => {
      unionSelect[i] = unionSelect[i] || [];
      unionSelect[i].push(item);
    });
  });

  const queryParams = {
    inputs: [
      {
        id: id,
        sql: sql,
        tableName: name,
        templateSQL: templateSQL,
        type: type,
      },
      ...map(associatedNodeMirror, (v) => ({
        id: v.dataId,
        sql: get(v, 'mate.tableData.sql'),
        tableName: v.name,
        templateSQL: get(v, 'mate.tableData.templateSQL'),
        type: v.nodeProperty.type,
      })),
    ],
    current: {
      id: unionID,
      type: 'union',
      settings: JSON.stringify({
        select: selectedFields,
        unionSelect: unionSelect,
      }),
    },
  };

  useEffect(() => {
    meta.FormInfo = {
      groupData,
      selectedFields,
      unionSelect,
      unionID,
    };
  }, [groupData, selectedFields, unionSelect]);

  return (
    <div className="filter-content">
      <div className="font-hm-16-bold pb-[20px]">并集</div>
      <div className="flex">
        <div className="group w-[100%]">
          <div className="font-hm-16-bold pb-[20px]">条件设置</div>
          <div className="tool">
            <div className="mt-[10px]">
              <span className="mr-[10px]">连接方式</span>
            </div>
          </div>
          {groupData.map((v) => (
            <div
              className="flex item my-[10px] flex-col border mr-[10px] rounded"
              key={v.id}
            >
              {v.list.map((group) => {
                const { column_condition } = typesData;
                const defaultSelectVal =
                  find(fieldsList, {
                    field: nth(group[0].field?.split('.') || [], -1),
                  }) || get(fieldsList, '[0]');
                const tempGroup = group[0];
                tempGroup.field = unionID + '.' + defaultSelectVal.field;
                tempGroup.alias = defaultSelectVal.alias;
                tempGroup.unionAlias =
                  tempGroup.unionAlias || defaultSelectVal.alias;
                tempGroup.type = defaultSelectVal.type;
                return (
                  <div className="group-Item flex m-[10px]" key={group.id}>
                    <div className="condition flex w-[80%] items-end">
                      <div>
                        <div className="mb-[5px]">{mianTable}</div>
                        <Select
                          defaultValue={defaultSelectVal}
                          onChange={(value) => {
                            const tempGroup = group[0];
                            tempGroup.field = unionID + '.' + value.field;
                            tempGroup.alias = value.alias;
                            // tempGroup.unionAlias = value.alias;
                            tempGroup.type = value.type;
                            setFieldsList([...fieldsList]);
                          }}
                          placeholder="请选择"
                          style={{ width: 154 }}
                        >
                          {fieldsList.map((option) => (
                            <Option key={option.field} value={option}>
                              {option.alias}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div>
                        <Input
                          defaultValue={tempGroup.unionAlias}
                          onChange={(value) => {
                            const tempGroup = group[0];

                            tempGroup.unionAlias = value;
                            setGroupData([...groupData]);
                          }}
                          style={{
                            width: 154,
                          }}
                        />
                      </div>
                      {/* <Select
                        defaultValue={get(column_condition, '[0].code')}
                        disabled
                        style={{ width: 154 }}
                      >
                        {[column_condition[0]].map((r) => (
                          <Option key={r.code} value={r.code}>
                            {r.name}
                          </Option>
                        ))}
                      </Select> */}
                      {associatedNode.map((v, i) => {
                        const tempGroup = group[i + 1] || {};

                        const defaultSelectVal =
                          find(get(v, 'mate.tableData.fields', []), {
                            field: nth(tempGroup.field?.split('.') || [], -1),
                          }) ||
                          get(get(v, 'mate.tableData.fields', []), '[0]', {});
                        tempGroup.field =
                          unionID + '.' + defaultSelectVal.field;
                        tempGroup.alias = defaultSelectVal.alias;
                        tempGroup.unionAlias = defaultSelectVal.alias;
                        tempGroup.type = defaultSelectVal.type;
                        return (
                          <div key={v.dataId}>
                            <div className="mb-[5px]">{v.alias}</div>
                            <Select
                              defaultValue={defaultSelectVal}
                              onChange={(value) => {
                                const tempGroup = group[i + 1] || {};
                                tempGroup.field = unionID + '.' + value.field;
                                tempGroup.alias = value.alias;
                                tempGroup.unionAlias = value.alias;
                                tempGroup.type = value.type;
                                group[i + 1] = tempGroup;
                                setFieldsList([...fieldsList]);
                              }}
                              placeholder="请选择"
                              style={{ width: 154 }}
                            >
                              {get(v, 'mate.tableData.fields', []).map(
                                (option) => (
                                  <Option key={option.field} value={option}>
                                    {option.alias}
                                  </Option>
                                )
                              )}
                            </Select>
                          </div>
                        );
                      })}
                    </div>
                    <div className="config-group flex w-[20%] ml-[20px]">
                      <div className="item">
                        <IconPlus
                          onClick={() => {
                            const listItem = map(
                              new Array(associatedNode.length + 1),
                              () => new GroupList()
                            );
                            set(listItem, 'id', uniqueId());
                            v.list.push(listItem);
                            setGroupData(
                              filter(groupData, (v) => v.list.length) as Group[]
                            );
                          }}
                        />
                      </div>
                      <div className="item">
                        <IconMinus
                          onClick={() => {
                            if (v.list.length <= 1) {
                              return Message.warning('至少保留一个条件');
                            }
                            v.list = filter(
                              v.list,
                              (item) => group.id !== item.id
                            );
                            setGroupData(
                              filter(groupData, (v) => v.list.length) as Group[]
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="mr-[30px]"
          onClick={() => {
            modal.current.close();
            modal.ok(queryParams);
          }}
          type="primary"
        >
          提交
        </Button>
        <Button onClick={() => modal.current.close()} type="secondary">
          取消
        </Button>
      </div>
    </div>
  );
}
