import PrintAPI from 'api/PrintAPI';
import { ReduxActionTypes } from 'ce/constants/ReduxActionConstants';
import { PrintPDFActionDescription } from 'entities/DataTree/actionTriggers';
import { put, call } from 'redux-saga/effects';

export default function* printPdfActionSaga(
  action: PrintPDFActionDescription['payload']
) {
  const printPayload = action.params || {};
  // type = 4 大悟医德医风-考评表
  if (printPayload.type === '4') {
    const response = yield call(PrintAPI.getPrintPDF, { ...printPayload });

    const pdfData = new Blob([response], { type: 'application/pdf' });

    const pdfUrl = window.URL.createObjectURL(pdfData);

    const iframe = document.createElement('iframe');
    iframe.src = pdfUrl;
    iframe.style.display = 'none';

    document.body.appendChild(iframe);

    iframe.onload = function () {
      iframe.contentWindow.print();
    };
  } else {
    // 体检系统-导检单
    yield put({
      type: ReduxActionTypes.ADD_PDF_PRINT_INFO,
      payload: printPayload,
    });
  }
}
