import styled from 'styled-components';

import { Profile } from 'pages/common/ProfileImage';
import { Colors } from 'constants/Colors';
import { getTypographyByKey } from 'constants/DefaultTheme';
import Button from 'components/ads/Button';
import { Classes as Popover2Classes } from '@blueprintjs/popover2';

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-left: 12px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.header.background};
  height: ${(props) => props.theme.defaultHeaderHeight};
  flex-direction: row;
  box-shadow: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.menuBorder};
  & .editable-application-name {
    ${(props) => getTypographyByKey(props, 'h4')}
    color: ${(props) => props.theme.colors.header.appName};
    width: 100% !important;
  }
  & ${Profile} {
    width: 24px;
    height: 24px;
  }
`;

export const HeaderSection = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: visible;
  align-items: center;
  .arco-icon-down {
    transition: all 0.2s ease;
  }
  .arco-icon {
    vertical-align: middle !important;
  }
  .arco-dropdown-popup-visible .arco-icon-down {
    transform: rotate(180deg);
  }
  :nth-child(1) {
    justify-content: flex-start;
    max-width: 30%;
  }
  :nth-child(2) {
    justify-content: center;
  }
  :nth-child(3) {
    justify-content: flex-end;
  }
  > .${Popover2Classes.POPOVER2_TARGET} {
    /* max-width: calc(100% - 50px); */
    /* min-width: 100px; */
  }
`;

export const DeploySection = styled.div`
  display: flex;
`;

export const StyledInviteButton = styled(Button)`
  margin-right: ${(props) => props.theme.spaces[9]}px;
  height: ${(props) => props.theme.smallHeaderHeight};
  ${(props) => getTypographyByKey(props, 'btnLarge')}
  padding: ${(props) => props.theme.spaces[2]}px;
`;

export const StyledDeployButton = styled(StyledInviteButton)`
  border-right: solid 1px rgba(255, 255, 255, 0.27) !important;
  margin-right: 0;
  padding: 9px 20px;
  height: 32px;
  border-radius: 4px;
  background: var(--primary-linear-gradient);
  &:hover {
    background: var(--primary-gradient-hover);
    color: #fff;
  }
  &:active {
    background: var(--primary-gradient-active);
    color: #fff;
  }
`;

export const BindingBanner = styled.div`
  position: fixed;
  width: 199px;
  height: 36px;
  left: 50%;
  top: ${(props) => props.theme.smallHeaderHeight};
  transform: translate(-50%, 0);
  text-align: center;
  background: ${Colors.DANUBE};
  color: ${Colors.WHITE};
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* Depth: 01 */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  z-index: 9999;
`;

// .theme.colors.reactionsComponent.textActive
export const ShareButton = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.colors.reactionsComponent.textActive};
  padding: 9px 20px;
  height: 32px;
  background: #f7f7f7;
  border-radius: 4px;
  &:hover {
    background: #e6dede;
  }
`;

export const StyledShareText = styled.span`
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
`;
