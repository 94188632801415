import { Colors } from '../Colors';
import { getColorWithOpacity } from './utils';
import {
  editorBottomBar,
  gitSyncModal,
  ColorType,
  numberedStep,
  tabItemBackgroundFill,
  notifications,
  displayImageUpload,
  showcaseCarousel,
  mentionSuggestion,
  reactionsComponent,
  mentionsInput,
  globalSearch,
  comments,
  lightShades,
  darkShades,
  guidedTour,
  actionSidePane,
  pagesEditor,
  auth,
  formMessage,
} from './common';

const navigationMenu = {
  contentActive: '#F0F0F0',
  backgroundActive: '#222222',
  contentInactive: '#858282',
  backgroundInactive: '#090707',
  label: '#A9A7A7',
  warning: '#EABB0C',
  warningBackground: '#3A3628',
};

const helpModal = {
  itemHighlight: '#231f20',
  background: '#262626',
};

export const dark: ColorType = {
  editorBottomBar,
  gitSyncModal,
  numberedStep,
  tabItemBackgroundFill,
  overlayColor: '#090707cc',
  notifications,
  displayImageUpload,
  showcaseCarousel,
  mentionSuggestion,
  reactionsComponent,
  mentionsInput,
  helpModal,
  globalSearch,
  comments,
  navigationMenu,
  selected: darkShades[10],
  header: {
    separator: darkShades[4],
    appName: darkShades[7],
    background: darkShades[2],
    deployToolTipBackground: lightShades[10],
    deployToolTipText: darkShades[7],
    shareBtnHighlight: '#F86A2B',
    shareBtn: '#fff',
    tabsHorizontalSeparator: '#EFEFEF',
    tabText: '#6F6D6D',
    activeTabBorderBottom: '#FF6D2D',
    activeTabText: '#000',
  },
  button: {
    disabledText: darkShades[6],
    boxShadow: {
      default: {
        variant1: Colors.BOX_SHADOW_DEFAULT_VARIANT1,
        variant2: Colors.BOX_SHADOW_DEFAULT_VARIANT2,
        variant3: Colors.BOX_SHADOW_DEFAULT_VARIANT3,
        variant4: Colors.BOX_SHADOW_DEFAULT_VARIANT4,
        variant5: Colors.BOX_SHADOW_DEFAULT_VARIANT5,
      },
    },
    disabled: {
      bgColor: Colors.GREY_1,
      textColor: Colors.GREY_4,
    },
    primary: {
      primary: {
        bgColor: Colors.GREEN,
        hoverColor: Colors.PRIMARY_SOLID_HOVER,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.GREEN,
        hoverColor: Colors.PRIMARY_OUTLINE_HOVER,
        textColor: Colors.GREEN,
      },
      tertiary: {
        hoverColor: Colors.PRIMARY_GHOST_HOVER,
      },
    },
    warning: {
      primary: {
        bgColor: Colors.WARNING_SOLID,
        hoverColor: Colors.WARNING_SOLID_HOVER,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.WARNING_SOLID,
        hoverColor: Colors.WARNING_OUTLINE_HOVER,
        textColor: Colors.WARNING_SOLID,
      },
      tertiary: {
        hoverColor: Colors.WARNING_GHOST_HOVER,
      },
    },
    danger: {
      primary: {
        bgColor: Colors.DANGER_SOLID,
        hoverColor: Colors.DANGER_SOLID_HOVER,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.DANGER_SOLID,
        hoverColor: Colors.DANGER_NO_SOLID_HOVER,
        textColor: Colors.DANGER_SOLID,
      },
      tertiary: {
        hoverColor: Colors.DANGER_NO_SOLID_HOVER,
      },
    },
    info: {
      primary: {
        bgColor: Colors.INFO_SOLID,
        hoverColor: Colors.INFO_SOLID_HOVER,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.INFO_SOLID,
        hoverColor: Colors.INFO_NO_SOLID_HOVER,
        textColor: Colors.INFO_SOLID,
      },
      tertiary: {
        hoverColor: Colors.INFO_NO_SOLID_HOVER,
      },
    },
    secondary: {
      primary: {
        bgColor: Colors.GRAY,
        hoverColor: Colors.CHARCOAL,
        textColor: Colors.WHITE,
      },
      secondary: {
        borderColor: Colors.GRAY,
        hoverColor: Colors.Gallery,
        textColor: Colors.GRAY,
      },
      tertiary: {
        hoverColor: Colors.MERCURY,
      },
    },
    custom: {
      solid: {
        dark: {
          textColor: Colors.CUSTOM_SOLID_DARK_TEXT_COLOR,
        },
        light: {
          textColor: Colors.WHITE,
        },
      },
    },
    link: {
      main: '#D4D4D4',
      hover: '#FFFFFF',
      active: '#2B2B2B',
      disabled: '#202021',
    },
  },
  tertiary: {
    main: '#D4D4D4',
    light: '#FFFFFF',
    dark: '#2B2B2B',
    darker: '#202021',
    darkest: '#1A191C',
  },
  info: {
    main: '#CB4810',
    light: '#F86A2B',
    dark: '#8B2E05',
    darker: '#A03C12',
    darkest: '#2B2B2B',
  },
  success: {
    main: '#218358',
    light: '#30CF89',
    dark: '#0F4B30',
    darker: '#17211E',
    darkest: '#293835',
  },
  warning: {
    main: '#EABB0C',
    light: '#FFD32E',
    dark: '#886B00',
    darker: '#2C271A',
    darkest: '#2F2A1B',
  },
  danger: {
    main: '#E22C2C',
    light: '#FF4D4D',
    dark: '#830C0C',
    darker: '#2B1A1D',
    darkest: '#462F32',
  },
  homepageBackground: '#fff',
  card: {
    hoverBG: Colors.BLACK,
    hoverBGOpacity: 0.5,
    hoverBorder: darkShades[4],
    iconColor: darkShades[9],
  },
  text: {
    normal: darkShades[6],
    heading: darkShades[7],
    highlight: darkShades[9],
  },
  icon: {
    normal: darkShades[6],
    hover: darkShades[8],
    active: darkShades[9],
  },
  appIcon: {
    normal: darkShades[9],
    background: darkShades[1],
  },
  menu: {
    background: darkShades[3],
    shadow: 'rgba(0, 0, 0, 0.75)',
  },
  menuItem: {
    normalText: darkShades[7],
    normalIcon: darkShades[6],
    hoverIcon: darkShades[8],
    hoverText: darkShades[9],
    hoverBg: darkShades[4],
    warning: {
      color: '#EABB0C',
      bg: '#3A3628',
    },
  },
  colorSelector: {
    shadow: darkShades[4],
    checkmark: darkShades[9],
  },
  checkbox: {
    disabled: darkShades[3],
    unchecked: darkShades[4],
    disabledCheck: darkShades[5],
    normalCheck: darkShades[9],
    labelColor: darkShades[7],
  },
  dropdown: {
    header: {
      text: darkShades[7],
      disabledText: darkShades[6],
      defaultBg: '#090707',
      bg: '#090707',
      disabledBg: darkShades[2],
    },
    menu: {
      border: darkShades[3],
      bg: darkShades[3],
      text: darkShades[9],
      hover: darkShades[4],
      hoverText: darkShades[9],
      subText: darkShades[9],
    },
    menuShadow: '0px 12px 28px -8px rgba(0, 0, 0, 0.75)',
    selected: {
      text: darkShades[9],
      bg: darkShades[4],
      icon: darkShades[8],
    },
    hovered: {
      text: darkShades[9],
      bg: darkShades[10],
      icon: darkShades[8],
    },
    icon: darkShades[6],
  },
  toggle: {
    bg: darkShades[4],
    hover: {
      on: '#F56426',
      off: '#5E5E5E',
    },
    disable: {
      on: '#3D2219',
      off: darkShades[3],
    },
    disabledSlider: {
      on: darkShades[9],
      off: darkShades[5],
    },
    spinner: darkShades[6],
    spinnerBg: darkShades[4],
  },
  textInput: {
    disable: {
      bg: darkShades[2],
      text: darkShades[6],
      border: darkShades[2],
    },
    normal: {
      bg: lightShades[10],
      border: darkShades[0],
      text: darkShades[7],
    },
    placeholder: darkShades[5],
    helper: darkShades[5],
    icon: darkShades[7],
    readOnly: {
      bg: darkShades[0],
      border: darkShades[0],
      text: darkShades[7],
    },
    hover: {
      bg: darkShades[0],
    },
    caretColor: Colors.WHITE,
  },
  menuBorder: darkShades[4],
  editableText: {
    color: darkShades[9],
    bg: darkShades[1],
    dangerBg: 'rgba(226, 44, 44, 0.08)',
  },
  radio: {
    disable: darkShades[5],
    border: darkShades[4],
    text: lightShades[11],
  },
  searchInput: {
    placeholder: darkShades[5],
    text: darkShades[9],
    border: darkShades[4],
    bg: darkShades[2],
    icon: {
      focused: darkShades[7],
      normal: darkShades[5],
    },
  },
  spinner: darkShades[6],
  tableDropdown: {
    bg: darkShades[3],
    selectedBg: darkShades[4],
    selectedText: darkShades[9],
    shadow: 'rgba(0, 0, 0, 0.75)',
  },
  tabs: {
    normal: darkShades[6],
    icon: darkShades[6],
    hover: darkShades[7],
    border: darkShades[3],
    countBg: darkShades[4],
    selected: Colors.CRUSTA,
  },
  settingHeading: darkShades[9],
  table: {
    headerBg: darkShades[2],
    headerText: darkShades[5],
    rowData: darkShades[6],
    rowTitle: darkShades[7],
    border: darkShades[3],
    hover: {
      headerColor: darkShades[7],
      rowBg: darkShades[4],
      rowTitle: darkShades[9],
      rowData: darkShades[7],
    },
  },
  applications: {
    bg: darkShades[4],
    textColor: darkShades[7],
    orgColor: darkShades[7],
    iconColor: darkShades[7],
    hover: {
      bg: darkShades[5],
      textColor: darkShades[8],
      orgColor: darkShades[9],
    },
    cardMenuIcon: darkShades[7],
  },
  switch: {
    border: darkShades[5],
    bg: darkShades[0],
    hover: {
      bg: darkShades[0],
    },
    lightText: darkShades[9],
    darkText: darkShades[6],
  },
  queryTemplate: {
    bg: darkShades[3],
    color: darkShades[7],
  },
  profileDropdown: {
    name: darkShades[10],
    userName: darkShades[7],
  },
  modal: {
    bg: darkShades[1],
    headerText: darkShades[9],
    iconColor: '#6D6D6D',
    iconBg: darkShades[12],
    user: {
      textColor: darkShades[7],
    },
    email: {
      message: darkShades[8],
      desc: darkShades[6],
    },
    manageUser: darkShades[6],
    scrollbar: darkShades[5],
    separator: darkShades[4],
    title: darkShades[8],
    link: '#F86A2B',
    hoverState: darkShades[3],
  },
  tagInput: {
    bg: '#090707',
    tag: {
      text: darkShades[9],
    },
    text: darkShades[9],
    placeholder: darkShades[5],
    shadow: 'none',
  },
  callout: {
    info: {
      color: '#EE5A1A',
      bgColor: '#241C1B',
    },
    success: {
      color: '#30CF89',
      bgColor: '#17211E',
    },
    danger: {
      color: '#FF4D4D',
      bgColor: '#2B1A1D',
    },
    error: {
      color: '#FF4D4D',
      bgColor: '#2B1A1D',
    },
    warning: {
      color: '#E0B30E',
      bgColor: '#29251A',
    },
  },
  loader: {
    light: darkShades[2],
    dark: darkShades[4],
  },
  filePicker: {
    bg: darkShades[1],
    color: darkShades[7],
    progress: darkShades[6],
    shadow: {
      from: 'rgba(21, 17, 17, 0.0001)',
      to: 'rgba(9, 7, 7, 0.883386)',
    },
  },
  formFooter: {
    cancelBtn: darkShades[9],
  },
  toast: {
    undo: '#CB4810',
    undoRedoColor: '#F8682B',
    warningColor: '#E0B30E',
    dangerColor: '#E22C2C',
    textColor: '#090707',
    bg: darkShades[8],
  },
  multiSwitch: {
    bg: darkShades[2],
    selectedBg: lightShades[10],
    text: darkShades[8],
    border: darkShades[3],
  },
  apiPane: {
    bg: darkShades[0],
    tabBg: lightShades[10],
    text: darkShades[6],
    dividerBg: darkShades[4],
    iconHoverBg: darkShades[1],
    requestTree: {
      bg: lightShades[10],
      header: {
        text: darkShades[7],
        icon: darkShades[7],
        bg: darkShades[1],
      },
      row: {
        hoverBg: darkShades[1],
        key: darkShades[6],
        value: darkShades[7],
      },
    },
    closeIcon: darkShades[9],
    responseBody: {
      bg: '#090707',
    },
    codeEditor: {
      placeholderColor: darkShades[5],
    },
    body: {
      text: '#6D6D6D',
    },
    settings: {
      textColor: '#FFFFFF',
    },
    pagination: {
      label: darkShades[7],
      description: darkShades[5],
      stepTitle: darkShades[9],
      numberBg: darkShades[3],
      bindingBg: darkShades[4],
      numberColor: lightShades[11],
    },
  },
  codeMirror: {
    background: {
      defaultState: '#262626',
      hoverState: darkShades[10],
    },
    text: '#FFFFFF',
    dataType: {
      shortForm: '#858282',
      fullForm: '#6D6D6D',
    },
  },
  floatingBtn: {
    tagBackground: '#e22c2c',
    backgroundColor: darkShades[3],
    iconColor: darkShades[6],
    borderColor: darkShades[7],
  },
  auth,
  formMessage,
  gif: {
    overlay: '#000000',
    text: '#d4d4d4',
    iconPath: '#2b2b2b',
    iconCircle: '#d4d4d4',
  },
  treeDropdown: {
    targetBg: '#090707',
    targetIcon: {
      normal: '#9F9F9F',
      hover: '#9F9F9F',
    },
    menuShadow: '0px 12px 28px -8px rgba(0, 0, 0, 0.75)',
    menuBg: {
      normal: darkShades[3],
      hover: darkShades[4],
      selected: darkShades[4],
    },
    menuText: {
      normal: darkShades[7],
      hover: darkShades[9],
      selected: darkShades[7],
    },
  },
  propertyPane: {
    title: darkShades[11],
    bg: darkShades[1],
    label: darkShades[7],
    jsIconBg: darkShades[5],
    buttonBg: darkShades[7],
    buttonText: lightShades[10],
    radioGroupBg: darkShades[0],
    radioGroupText: darkShades[7],
    deleteIconColor: '#A3B3BF',
    zoomButtonBG: darkShades[3],
    activeButtonText: lightShades[12],
    jsButtonHoverBG: darkShades[2],
    dropdownSelectBg: darkShades[2],
    multiDropdownBoxHoverBg: darkShades[0],
    iconColor: darkShades[5],
    ctaTextColor: '#202223',
    ctaBackgroundColor: 'rgb(248, 106, 43, 0.1)',
    ctaLearnMoreTextColor: '#f86a2b',
    connections: {
      error: '#f22b2b',
      connectionsCount: darkShades[11],
      optionBg: 'rgba(246,71,71, 0.2)',
    },
  },
  scrollbar: getColorWithOpacity(Colors.LIGHT_GREY, 0.5),
  scrollbarBG: getColorWithOpacity(Colors.CODE_GRAY, 0.5),
  debugger: {
    background: darkShades[11],
    messageTextColor: '#D4D4D4',
    time: '#D4D4D4',
    label: '#D4D4D4',
    entity: 'rgba(212, 212, 212, 0.5)',
    entityLink: '#D4D4D4',
    jsonIcon: '#9F9F9F',
    message: '#D4D4D4',
    evalDebugButton: {
      hover: '#fafafaaa',
      active: '#fafafaff',
    },
    floatingButton: {
      background: '#2b2b2b',
      color: '#d4d4d4',
      shadow: '0px 12px 28px -6px rgba(0, 0, 0, 0.32)',
      errorCount: '#F22B2B',
      noErrorCount: '#03B365',
      warningCount: '#DCAD00',
    },
    inspectElement: {
      color: '#D4D4D4',
    },
    blankState: {
      color: '#D4D4D4',
      shortcut: '#D4D4D4',
    },
    info: {
      borderBottom: 'black',
    },
    warning: {
      iconColor: '#f3cc3e',
      hoverIconColor: '#e0b30e',
      borderBottom: 'black',
      backgroundColor: '#29251A',
    },
    error: {
      iconColor: '#f56060',
      hoverIconColor: '#F22B2B',
      borderBottom: 'black',
      backgroundColor: '#291B1D',
    },
  },
  guidedTour,
  widgetGroupingContextMenu: {
    border: '#2494f4',
    actionActiveBg: '#e1e1e1',
  },
  actionSidePane,
  pagesEditor,
  link: '#f86a2b',
  welcomePage: {
    text: lightShades[5],
  },
  settings: {
    link: '#716E6E',
  },
};
