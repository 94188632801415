import styled from 'styled-components';
import { animated } from 'react-spring';

import { Layers } from 'constants/Layers';
import { Colors } from 'constants/Colors';

const StyledDraggableWrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 480px;
  height: 260px;
  max-width: 900px;
  max-height: 776px;
  min-width: 250px;
  min-height: 250px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--white);
  border: 1px solid ${Colors.ALTO};
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
  pointer-events: auto;
  z-index: ${Layers.expressionEditorPane};
`;

export const Container = styled(StyledDraggableWrapper)``;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 4px 12px;
  cursor: move;
`;

export const Main = styled.main`
  flex: 1;
  width: 100%;
`;
