import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import { Action, Handle, Remove } from '../components';
import styled from 'styled-components';

const Li = styled.li`
  &.Wrapper {
    list-style: none;
    box-sizing: border-box;
    padding-left: var(--spacing);
    margin-bottom: -1px;

    &.clone {
      display: inline-block;
      pointer-events: none;
      padding: 0;
      margin-left: 10px;
      margin-top: 5px;

      .TreeItem {
        --vertical-padding: 5px;

        padding-right: 24px;
        border-radius: 4px;
        box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
      }
    }

    &.ghost {
      &.indicator {
        opacity: 1;
        position: relative;
        z-index: 1;
        margin-bottom: -1px;

        .TreeItem {
          position: relative;
          padding: 0;
          height: 6px;
          border-color: #2389ff;
          background-color: #56a1f8;
          width: calc(100% - 4px);
          margin-left: 4px;

          &:before {
            position: absolute;
            left: -4px;
            top: -3px;
            display: block;
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid #2389ff;
            background-color: #ffffff;
          }

          > * {
            /* Items are hidden using height and opacity to retain focus */
            opacity: 0;
            height: 0;
          }
        }
      }

      &:not(.indicator) {
        opacity: 0.5;
      }

      .TreeItem > * {
        box-shadow: none;
        background-color: transparent;
      }
    }
  }

  .TreeItem {
    --vertical-padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* border: 1px solid transparent; */
    color: #666666;
    font-weight: bold;
    transition: all 0.5s ease;
    box-sizing: border-box;
    margin: 10px 0;
    background: var(--bg-primary);
    border-radius: var(--border-radius-small);
    &:hover {
      background: var(--color-fill-3);
    }
  }

  .Text {
    flex-grow: 1;
    padding-left: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }

  .Count {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #2389ff;
    font-size: 0.8rem;
    font-weight: 600;
    color: #fff;
  }

  .disableInteraction {
    pointer-events: none;
  }

  .disableSelection,
  .clone {
    .Text,
    .Count {
      user-select: none;
      -webkit-user-select: none;
    }
  }

  .Collapse {
    svg {
      transition: transform 250ms ease;
    }

    &.collapsed svg {
      transform: rotate(-90deg);
    }
  }
`;

export interface Props extends HTMLAttributes<HTMLLIElement> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: ReactNode;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
}

function TreeItemRaw(
  {
    childCount,
    className,
    clone,
    collapsed,
    depth,
    disableInteraction,
    disableSelection,
    ghost,
    handleProps,
    indentationWidth,
    indicator,
    onCollapse,
    onRemove,
    style,
    value,
    wrapperRef,
    ...props
  }: any,
  ref: any
) {
  return (
    <Li
      className={`${classNames(
        'Wrapper',
        clone && 'clone',
        ghost && 'ghost',
        indicator && 'indicator',
        disableSelection && 'disableSelection',
        disableInteraction && 'disableInteraction'
      )} ${className}`}
      ref={wrapperRef}
      style={
        {
          '--spacing': `${indentationWidth * depth}px`,
        } as React.CSSProperties
      }
      {...props}
    >
      <div className={'TreeItem'} ref={ref} style={style}>
        <Handle {...handleProps} />
        {onCollapse && (
          <Action
            className={classNames('Collapse', collapsed && 'collapsed')}
            onClick={onCollapse}
          >
            {collapseIcon}
          </Action>
        )}
        <span className={'Text'}>{value}</span>
        {!clone && onRemove && <Remove onClick={onRemove} />}
        {clone && childCount && childCount > 1 ? (
          <span className={'Count'}>{childCount}</span>
        ) : null}
      </div>
    </Li>
  );
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(TreeItemRaw);

const collapseIcon = (
  <svg viewBox="0 0 70 41" width="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z" />
  </svg>
);
