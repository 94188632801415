import React from 'react';
import Image404 from 'assets/images/404-page.svg';
import ErrorPage from 'components/error-page';

function PageNotFound() {
  return (
    <ErrorPage
      buttonText="返回主页"
      description="页面不存在"
      imageUrl={Image404}
    />
  );
}

export default PageNotFound;
