import { WidgetProps } from 'utils/widgetBridge';
import {
  PropertyPaneConfig,
  ValidationConfig,
} from 'constants/PropertyControlConstants';
import { get, isObject, isUndefined, omitBy } from 'lodash';
import { FlattenedWidgetProps } from 'reducers/entityReducers/canvasWidgetsReducer';
import { EvaluationSubstitutionType } from 'entities/DataTree/dataTreeFactory';

/**
 * @typedef {Object} Paths
 * @property {Object} configBindingPaths - The Binding Path
 * @property {Object} configReactivePaths - The Dynamic Property Path
 * @property {Object} configTriggerPaths - The Trigger Path
 * @property {Object} configValidationPaths - The Validation Path
 */

/**
 * All widget's property or paths where user can write javaScript bindings using mustache syntax are called bindingPaths.
 * Widget's meta and derived paths aren't binding paths as user can't add or remove binding for those value.
 */
type BindingPaths = Record<string, EvaluationSubstitutionType>;
/**
 * Binding paths and non-binding paths of widget/action together form reactivePaths.
 */
type ReactivePaths = Record<string, EvaluationSubstitutionType>;

/**
 * This function gets the binding validation and trigger paths from a config
 * 此函数从配置中获取绑定验证和触发路径。
 * @param config
 * @param path
 * @returns {Paths} Paths
 */
const checkPathsInConfig = (
  config: any,
  path: string,
  configBindingPaths: BindingPaths,
  configReactivePaths: BindingPaths,
  configTriggerPaths: Record<string, true>,
  configValidationPaths: Record<any, ValidationConfig>
) => {
  // Purely a Binding Path
  if (config.isBindProperty && !config.isTriggerProperty) {
    configReactivePaths[path] = configBindingPaths[path] =
      config.evaluationSubstitutionType || EvaluationSubstitutionType.TEMPLATE;
    if (config.validation) {
      configValidationPaths[path] = config.validation;
    }
  } else if (config.isBindProperty && config.isTriggerProperty) {
    configTriggerPaths[path] = true;
  }
};

// "originalWidget" param here always contains the complete widget props
// as this function's widget parameter tends to change in each iteration
const childHasPanelConfig = (
  config: any,
  widget: WidgetProps,
  basePath: string,
  originalWidget: WidgetProps,
  bindingPaths: BindingPaths = {},
  reactivePaths: ReactivePaths = {},
  triggerPaths: Record<string, true> = {},
  validationPaths: Record<any, ValidationConfig> = {}
) => {
  const panelPropertyPath = config.propertyName;
  const widgetPanelPropertyValues = get(widget, panelPropertyPath); //取出子配置的属性

  if (widgetPanelPropertyValues) {
    Object.values(widgetPanelPropertyValues).forEach(
      (widgetPanelPropertyValue: any) => {
        const { panelIdPropertyName } = config.panelConfig;
        const propertyPath = `${basePath}.${widgetPanelPropertyValue[panelIdPropertyName]}`;
        //
        config.panelConfig.children.forEach((panelColumnConfig: any) => {
          let isSectionHidden = false;
          if ('hidden' in panelColumnConfig) {
            isSectionHidden = panelColumnConfig.hidden(
              originalWidget,
              propertyPath
            );
          }
          if (!isSectionHidden) {
            panelColumnConfig.children.forEach(
              (panelColumnControlConfig: any) => {
                const panelPropertyConfigPath = `${propertyPath}.${panelColumnControlConfig.propertyName}`;
                let isControlHidden = false;
                if ('hidden' in panelColumnControlConfig) {
                  isControlHidden = panelColumnControlConfig.hidden(
                    originalWidget,
                    panelPropertyConfigPath
                  );
                }
                if (!isControlHidden) {
                  checkPathsInConfig(
                    panelColumnControlConfig,
                    panelPropertyConfigPath,
                    bindingPaths,
                    reactivePaths,
                    triggerPaths,
                    validationPaths
                  );
                  // Has child Panel Config
                  if (panelColumnControlConfig.panelConfig) {
                    childHasPanelConfig(
                      panelColumnControlConfig,
                      widgetPanelPropertyValue,
                      panelPropertyConfigPath,
                      originalWidget,
                      bindingPaths,
                      reactivePaths,
                      triggerPaths,
                      validationPaths
                    );
                  }
                }
              }
            );
          }
        });
      }
    );
  }
  return { reactivePaths, triggerPaths, validationPaths, bindingPaths };
};

export const getAllPathsFromPropertyConfig = (
  widget: WidgetProps,
  widgetConfig: readonly PropertyPaneConfig[],
  defaultProperties: Record<string, any>
): {
  bindingPaths: BindingPaths;
  reactivePaths: ReactivePaths;
  triggerPaths: Record<string, true>;
  validationPaths: Record<string, ValidationConfig>;
} => {
  const bindingPaths: BindingPaths = {};
  const reactivePaths: ReactivePaths = {};
  Object.keys(defaultProperties).forEach((property) => {
    reactivePaths[property] = EvaluationSubstitutionType.TEMPLATE;
  });
  const triggerPaths: Record<string, true> = {};
  const validationPaths: Record<any, ValidationConfig> = {};

  widgetConfig.forEach((config) => {
    if (config.children) {
      config.children.forEach((controlConfig: any) => {
        const basePath = controlConfig.propertyName;
        let isHidden = false;
        if ('hidden' in controlConfig) {
          isHidden = controlConfig.hidden(widget, basePath);
        }
        if (!isHidden) {
          const path = controlConfig.propertyName;
          checkPathsInConfig(
            controlConfig,
            path,
            bindingPaths,
            reactivePaths,
            triggerPaths,
            validationPaths
          );
        }
        // Has child Panel Config
        if (controlConfig.panelConfig) {
          childHasPanelConfig(
            controlConfig,
            widget,
            basePath,
            widget,
            bindingPaths,
            reactivePaths,
            triggerPaths,
            validationPaths
          );
        }
        if (controlConfig.children) {
          const basePropertyPath = controlConfig.propertyName;
          const widgetPropertyValue = get(widget, basePropertyPath, []);
          // Property in object structure
          if (
            !isUndefined(widgetPropertyValue) &&
            isObject(widgetPropertyValue)
          ) {
            Object.keys(widgetPropertyValue).forEach((key: string) => {
              const objectIndexPropertyPath = `${basePropertyPath}.${key}`;
              controlConfig.children.forEach((childPropertyConfig: any) => {
                const childArrayPropertyPath = `${objectIndexPropertyPath}.${childPropertyConfig.propertyName}`;
                checkPathsInConfig(
                  childPropertyConfig,
                  childArrayPropertyPath,
                  bindingPaths,
                  reactivePaths,
                  triggerPaths,
                  validationPaths
                );
              });
            });
          }
        }
      });
    }
  });

  return { reactivePaths, triggerPaths, validationPaths, bindingPaths };
};

/**
 * this function gets the next available row for pasting widgets
 * NOTE: this function excludes modal widget when calculating next available row
 *
 * @param parentContainerId
 * @param canvasWidgets
 * @returns
 */
export const nextAvailableRowInContainer = (
  parentContainerId: string,
  canvasWidgets: { [widgetId: string]: FlattenedWidgetProps }
) => {
  const filteredCanvasWidgets = omitBy(canvasWidgets, (widget) => {
    return widget.type === 'MODAL_WIDGET';
  });

  return (
    Object.values(filteredCanvasWidgets).reduce(
      (prev: number, next: any) =>
        next?.parentId === parentContainerId && next.bottomRow > prev
          ? next.bottomRow
          : prev,
      0
    ) + 1
  );
};
