import React from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@arco-design/web-react';
import { flushErrorsAndRedirect } from 'actions/errorActions';
import imgUrl from 'assets/images/page-error.svg';
import { LOGIN_URL_INSTALL_MODE, APPLICATIONS_URL_APP } from 'constants/routes';
import history from 'utils/history';
import { isInstallMode } from '@appInstall/redux/selectors';

const Wrapper = styled.div`
  text-align: center;
  margin-top: 5%;

  .page-img {
    width: 435px;
    height: 412px;
  }

  .button-position {
    margin: auto;
  }
`;

interface Props {
  imageUrl?: string;
  buttonText?: string;
  description?: React.ReactNode;
  toPage?: string;
  flushErrorsAndRedirect?: any;
}

// const SPECIFIC_PATH_NAMES = ['/api/', '/api'];

function ErrorPage({
  imageUrl = imgUrl,
  buttonText = '返回主页',
  description = '哎呀！出问题了',
  toPage = LOGIN_URL_INSTALL_MODE,
  flushErrorsAndRedirect,
}: Props) {
  const isInstall = useSelector(isInstallMode);

  const handleLink = () => {
    const location = history.location;
    const { pathname } = location || {};

    let targetPath = toPage;

    if (isInstall) {
      targetPath = `/api${toPage}`;
    } else {
      targetPath = APPLICATIONS_URL_APP;
    }

    flushErrorsAndRedirect(targetPath);
  };

  return (
    <Wrapper className="space-y-6">
      <img alt="Page error" className="mx-auto page-img" src={imageUrl} />
      <div className="space-y-2 text-center">
        <p className="font-hm-32-bold font-medium text-[#999]">{description}</p>
        <Button
          className="fs-btn-primary relative rounded w-[364px] h-[64px]"
          onClick={handleLink}
        >
          <span className="font-bold text-white text-16">{buttonText}</span>
        </Button>
      </div>
    </Wrapper>
  );
}

export default connect(null, {
  flushErrorsAndRedirect,
})(ErrorPage);
