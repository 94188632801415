import { Tooltip } from '@arco-design/web-react';
import { IconLoading } from '@arco-design/web-react/icon';
import { runAction } from 'actions/pluginActionActions';
import NoSqlApi from 'api/NosqlAPI';
import { ReduxActionTypes } from 'ce/constants/ReduxActionConstants';
import Icon from 'components/icon';
import { QUERY_EDITOR_FORM_NAME } from 'constants/forms';
import { find, get, set, uniqueId } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import {
  getConnectedEdges,
  getIncomers,
  Handle,
  Position,
  useNodes,
  useReactFlow,
  useStoreApi,
} from 'react-flow-renderer';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { AlterInitObj, NodeTypes } from '../constants';
import NodeMenu from '../NodeMenu';
import { queryNodeMenu } from '../nodeMenuConfig';

function QueryNode(props) {
  const { current, id, inputs } = props;
  const [refreshStatus, refresh] = useReducer((state) => ++state, 1);
  const {
    data,
    data: { name, nodeProperty: propertyData },
    selected,
  } = props;

  const queryParams = get(data, 'queryParams', {});
  const dispatch = useDispatch();
  const [tableSql, setTableSql] = useState('');
  const { getState } = useStoreApi();
  const typesData = get(getState(), 'typesData.data.data.data');
  const currentDbType = get(getState(), 'currentDbType');

  const { data: res, isSuccess } = useQuery(id, () =>
    NoSqlApi.getPreview({ ...queryParams, dbType: currentDbType })
  );

  const nodes = useNodes().length;

  useEffect(() => {
    const allEdges = reactFlowInstance.getEdges();
    const addEdges = getConnectedEdges(
      [reactFlowInstance.getNode(id)],
      allEdges
    );
    const node = find(addEdges, (v) => v.targetHandle === 'B');
    if (node?.source) {
      const rightEdges = getConnectedEdges(
        [reactFlowInstance.getNode(node?.source)],
        allEdges
      );
      if (!find(rightEdges, (v) => v.sourceHandle === 'B')) {
        dispatch(
          change(QUERY_EDITOR_FORM_NAME, 'actionConfiguration.body', tableSql)
        );
      }
    } else {
      dispatch(
        change(QUERY_EDITOR_FORM_NAME, 'actionConfiguration.body', tableSql)
      );
    }
    // console.log(node, 'addEdges');
  }, [tableSql, refreshStatus, nodes]);

  const { x: hoverClientX, y: hoverClientY } = data;
  //基本属性
  const nameQuery = uniqueId(`query_`);
  const uid = id;
  const ukey = uniqueId(`key_${name}_`);
  const nodeProperty = {
    id: uid,
    key: ukey,
    type: 'query_table', // 用于判断在画布渲染的时候是什么组件
    width: 60,
    height: 60,
    name: nameQuery,
    alias: nameQuery,
    srcTableId: `${id}`,
    data_name: nameQuery,
  };
  const { alias, data_name, height, srcTableId, type, width } = nodeProperty;

  //最后的节点属性
  const newInfo = {
    id: nodeProperty.id,
    name,
    alias,
    type,
    width,
    height,
    x: hoverClientX,
    y: hoverClientY,
    srcTableId: srcTableId || id,
    data_name: data_name || name,
    // icon: icon || 'icon-zf-system',
    tableName: name, // 在最开始的表名设置为空
    // iconColor,
    input: [],
    out: [],
    sql: '',
    templateSQL: '',
    fields: [],
    // points: [],
    request: {
      // 用于请求的参数对象
      inputs: [],
      current: {
        id: nodeProperty.id,
        type,
        sql: '',
        templateSQL: '',
        tableName: type === NodeTypes.table ? name : '', // 在最开始的表名设置为空
        srcTableId: type === NodeTypes.table ? srcTableId : '',
        settings: type === NodeTypes.alter ? [AlterInitObj] : {},
      },
    },
  };
  const reactFlowInstance = useReactFlow();
  const [addNode, setAddNode] = useState({
    nodeProperty: newInfo,
    dataId: newInfo.id,
    x: hoverClientX,
    y: hoverClientY,
    type,
    previewData: null,
  });

  const {
    position: { x, y },
  } = reactFlowInstance.getNode(id);

  useEffect(() => {
    setAddNode((node) => {
      return {
        ...node,
        x,
        y,
      };
    });
  }, [x, y]);

  const tableData = get(res, 'data.data');

  const createTempleteQueryId = useSelector(
    (state: AppState) => state.ui.globalSearch.createTempleteQueryId
  );
  const sql = get(
    useSelector((state) => getFormValues(QUERY_EDITOR_FORM_NAME)(state)),
    'actionConfiguration.body'
  );

  const [perviewLoading, setPerviewLoading] = useState(false);

  const isRunning = useSelector(
    (state: AppState) =>
      state.ui.queryPane.isRunning[state.ui.globalSearch.createTempleteQueryId]
  );

  useEffect(() => {
    addNode.previewData = () => {
      dispatch({
        type: ReduxActionTypes.PREVIEW_QUERY_TABLE_DATA,
        payload: {
          actionIdAction: runAction(createTempleteQueryId),
          tableSqlActicon: change(
            QUERY_EDITOR_FORM_NAME,
            'actionConfiguration.body',
            tableSql
          ),
          fxParmas: change(
            QUERY_EDITOR_FORM_NAME,
            'actionConfiguration.body',
            sql
          ),
        },
      });
      setTimeout(() => {
        dispatch(runAction(createTempleteQueryId));
        setPerviewLoading(true);
      }, 1000);
    };
  }, [tableSql, createTempleteQueryId, sql]);

  useEffect(() => {
    if (!isRunning && perviewLoading) {
      setPerviewLoading(false);
    }
  }, [isRunning]);

  set(
    tableData,
    'fields',
    get(res, 'data.data.fields', []).map((v) => ({
      ...v,
      field: v.field?.split('.')[1] || v.field,
    }))
  );

  const tableMeta = get(tableData, 'fields');

  const meta = { tableMeta, typesData, tableData };

  data.refresh = refresh;
  data.meta = meta;

  useEffect(() => {
    setTableSql(get(res, 'data.data.templateSQL'));
  }, [isSuccess, res]);

  const isShowMenu =
    getIncomers(
      reactFlowInstance.getNode(props.id),
      reactFlowInstance.getNodes(),
      reactFlowInstance.getEdges()
    ).length < 2;
  const MenuWrapper = Tooltip;

  return (
    <MenuWrapper
      content={
        <NodeMenu
          data={addNode}
          meta={meta}
          nodeMenu={isShowMenu ? queryNodeMenu : queryNodeMenu.slice(-2)}
        />
      }
      position="bottom"
      trigger="hover"
    >
      <div className="table-node text-center">
        <div
          className={`relative cursor-move w-[56px] h-[56px] bg-white rounded flex justify-center items-center m-auto border-1 border-solid ${
            selected ? 'border-link' : ''
          }`}
          // onClick={() => {
          //   tableSql &&
          //     dispatch(
          //       change(
          //         QUERY_EDITOR_FORM_NAME,
          //         'actionConfiguration.body',
          //         tableSql
          //       )
          //     );
          // }}
        >
          <span className="text-link text-[24px]">
            {perviewLoading ? <IconLoading /> : <Icon name={'query1'} />}
          </span>
          <Handle position={Position.Left} type="target" />
          <Handle id="B" position={Position.Right} type="target" />
        </div>
        <div className="mt-[8px] font-hm-12">{name}</div>
      </div>
    </MenuWrapper>
  );
}

export default QueryNode;
