import { FILTER_SYS_TABLES } from './../pages/Editor/IntegrationEditor/PageDatasourceDetail/constants';
import { DEFAULT_TEST_DATA_SOURCE_TIMEOUT_MS } from '@appsmith/constants/ApiConstants';
import API from 'api/Api';
import { GenericApiResponse, PartialMetaApiResponse } from './ApiResponses';
import { AxiosPromise } from 'axios';

import { DatasourceAuthentication, Datasource } from 'entities/Datasource';
import { get } from 'lodash';
export interface CreateDatasourceConfig {
  name: string;
  pluginId: string;
  saveFlag?: number;
  datasourceConfiguration: {
    url: string;
    databaseName?: string;
    authentication?: DatasourceAuthentication;
  };
  //Passed for logging purposes.
  appName?: string;
  onSuccessCallback?: () => any;
}

export interface EmbeddedRestDatasourceRequest {
  datasourceConfiguration: { url: string };
  invalids: Array<string>;
  isValid: boolean;
  name: string;
  organizationId: string;
  pluginId: string;
}

type executeQueryData = Array<{ key?: string; value?: string }>;

export interface ParamsCreateTable {
  id: string; // datasource id
  names: string[]; // files path
  newAppId: string; // current application id
}

export type ParamsImportTableData = ParamsCreateTable;

export type ParamsImportDataForMultiTable = ParamsCreateTable & {
  tableName: string[];
};

export type UpdateDatasourceInstallModeParams = {
  id?: string;
  username?: string;
  password?: string;
  host?: string;
  isMaster?: boolean;
};

/**
 * 1. 更新表名称，传参tableName,newTableName
 * 2. 更新表注释,tableName,tableExegesis
 * 3. 表字段名称,tableName,fieldName,newFieldName
 * 4. 表字段类型,tableName,fieldName,fieldType
 * 5. 更新表字段注释,tableName,fieldName,fieldExegesis
 *
 */
export const enum TableUpdateType {
  UPDATE_TABLE_NAME = 1,
  UPDATE_TABLE_EXEGESIS = 2,
  UPDATE_FIELD_NAME = 3,
  UPDATE_FIELD_TYPE = 4,
  UPDATE_FIELD_EXEGESIS = 5,
}
export interface ParamsUpdateDatasourceTable {
  datasourceId: string;
  fieldExegesis?: string; // 字段注释
  fieldName?: string; // 字段名称
  fieldType?: string; // 字段类型
  newFieldName?: string; // 新的字段名称
  newTableName?: string; // 新的表名称
  tableExegesis?: string; // 标注释
  tableName?: string; // 表名称
  updateType?: TableUpdateType;
}

export interface executeDatasourceQueryRequest {
  datasourceId: string;
  data: executeQueryData;
}

class DatasourcesApi {
  static url = 'v1/datasources';

  static fetchDatasources(
    orgId: string
  ): AxiosPromise<GenericApiResponse<Datasource[]>> {
    return API.get(DatasourcesApi.url + `?organizationId=${orgId}`);
  }

  static fetchDatasourcesForCurrentApp(
    appID: string
  ): AxiosPromise<GenericApiResponse<Datasource[]>> {
    return API.get(DatasourcesApi.url + `?applicationId=${appID}`);
  }

  static createDatasource(datasourceConfig: Partial<Datasource>): Promise<any> {
    return API.post(DatasourcesApi.url, datasourceConfig);
  }

  static copyDatasource(id: string, appId: string): Promise<any> {
    return API.post(DatasourcesApi.url + `/clone/${id}/${appId}`);
  }

  /**
   * NOTE: creating datasource from excel
   */
  static createExcelDatasource(data: {
    names: string[];
    newAppId: string;
  }): Promise<any> {
    return API.post(DatasourcesApi.url + `/createTableByExcel`, data);
  }

  static buildDatasourceViaExcel(
    appId: string
  ): Promise<PartialMetaApiResponse> {
    // return fakeAPI.success();
    return API.post(
      DatasourcesApi.url + `/v2/createDataSourceByExcel/?newAppId=${appId}`
    );
  }

  static createTableViaExcel(
    params: ParamsCreateTable
  ): Promise<PartialMetaApiResponse> {
    // return fakeAPI.success();
    return API.post(DatasourcesApi.url + `/v2/createTableByExcel`, params);
  }

  static importDataViaExcel(
    params: ParamsImportTableData
  ): Promise<PartialMetaApiResponse> {
    // return fakeAPI.success();
    return API.post(DatasourcesApi.url + `/v2/importTableByExcel`, params);
  }

  static updateExcelDatasourceTable(
    params: ParamsUpdateDatasourceTable
  ): Promise<PartialMetaApiResponse> {
    const { datasourceId, ...restParams } = params;
    return API.put(
      DatasourcesApi.url + `/updateTable/${datasourceId}`,
      restParams
    );
  }

  /**
   * NOTE: import data via excel for MultiTable
   */
  static importDataViaExcelForMultiTable(
    params: ParamsImportDataForMultiTable
  ): Promise<PartialMetaApiResponse> {
    return API.post(
      DatasourcesApi.url + '/importTableByExcelAndTableName',
      params
    );
  }

  static testDatasource(datasourceConfig: Partial<Datasource>): Promise<any> {
    return API.post(`${DatasourcesApi.url}/test`, datasourceConfig, undefined, {
      timeout: DEFAULT_TEST_DATA_SOURCE_TIMEOUT_MS,
    });
  }

  static updateDatasource(
    datasourceConfig: Partial<Datasource>,
    id: string
  ): Promise<any> {
    return API.put(DatasourcesApi.url + `/${id}`, datasourceConfig);
  }

  static updateDatasourceName(name: string, id: string): Promise<any> {
    return API.post(DatasourcesApi.url + '/updateDataName', { name, id });
  }

  static deleteDatasource(id: string): Promise<any> {
    return API.delete(DatasourcesApi.url + `/${id}`);
  }

  static async fetchDatasourceStructure(
    id: string,
    ignoreCache = false
  ): Promise<any> {
    const data = await API.get(
      DatasourcesApi.url + `/${id}/structure?ignoreCache=${ignoreCache}`
    );
    // const omitObj = {};
    // FILTER_SYS_TABLES.forEach((v) => {
    //   omitObj[v] = true;
    // });
    // data.data.tables = data.data.tables.filter((v) => !omitObj[get(v, 'name')]);
    return data;
  }

  static fetchMockDatasources(): AxiosPromise<
    GenericApiResponse<Datasource[]>
  > {
    return API.get(DatasourcesApi.url + '/mocks');
  }

  static addMockDbToDatasources(
    name: string,
    organizationId: string,
    pluginId: string,
    packageName: string,
    applicationId: string,
    saveFlag?: 1
  ): Promise<any> {
    return API.post(DatasourcesApi.url + '/mocks', {
      name,
      organizationId,
      pluginId,
      packageName,
      applicationId,
      saveFlag: saveFlag,
    });
  }

  static executeDatasourceQuery({
    data,
    datasourceId,
  }: executeDatasourceQueryRequest) {
    return API.put(
      DatasourcesApi.url + `/datasource-query/${datasourceId}`,
      data
    );
  }

  /**
   * NOTE: For multi datasources in install mode
   */
  static getAllDatasourceInstallMode(): Promise<PartialMetaApiResponse> {
    return API.get(DatasourcesApi.url + '/getAllDatasource');
  }

  static updateDatasourceInstallMode(
    data: UpdateDatasourceInstallModeParams
  ): Promise<PartialMetaApiResponse> {
    return API.post(DatasourcesApi.url + '/updateDatasource', data);
  }

  static getAppInfoInstallMode() {
    return API.get(DatasourcesApi.url + '/getAppInfo');
  }

  /**
   * get master Datasource in SysConfig
   * spliced param {1} means isMaster = 1
   * @param appID
   * @returns
   */
  static fetchDSForSysConfig(
    appID: string
  ): Promise<PartialMetaApiResponse<Datasource[]>> {
    return API.get(`${DatasourcesApi.url}/all/${appID}/1`);
  }

  /**
   * set master DataSource in SysConfig
   * spliced param {1} means isMaster = 1
   * @param appID
   * @param id datasource id
   * @returns
   */
  static updateDSForSysConfig(
    appID: string,
    id: string
  ): Promise<PartialMetaApiResponse<'' | undefined>> {
    return API.post(`${DatasourcesApi.url}/update/${appID}/${id}/1`);
  }
}

export default DatasourcesApi;
