import React from 'react';
import Icon from 'components/icon';
import { Handle, Position, useReactFlow } from 'react-flow-renderer';
import { Tooltip } from '@arco-design/web-react';
import NodeMenu from '../NodeMenu';

export default function Operations(props) {
  const { data, id, selected } = props;
  const reactFlowInstance = useReactFlow();
  const {
    position: { x, y },
  } = reactFlowInstance.getNode(id);

  return (
    <div className={'table-node text-center' + ` ${data.type}`}>
      <Tooltip
        content={
          <NodeMenu
            data={{
              ...data,
              x,
              y,
            }}
            meta={data.meta}
            nodeMenu={data.menu || []}
          />
        }
        position="bottom"
        trigger="hover"
      >
        <div
          className={`relative cursor-move w-[32px] h-[32px] bg-white rounded-full flex justify-center items-center m-auto border-1 border-solid ${
            selected ? 'bg-link' : ''
          }`}
        >
          <Handle id="A" position={Position.Left} type={'source'} />
          <Handle id="B" position={Position.Right} type={'source'} />
          <span
            className={`
            ${selected ? 'text-white' : 'text-link'} text-[12px]
          `}
          >
            <Icon name={data.icon} />
          </span>
        </div>
      </Tooltip>
    </div>
  );
}
