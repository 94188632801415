import {
  Button,
  Checkbox,
  Input,
  Message,
  Switch,
  Tag,
} from '@arco-design/web-react';
import {
  assign,
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  includes,
  map,
  nth,
  uniqueId,
} from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Select } from '@arco-design/web-react';
import { groupFilter } from '../constants';
import { IconMinus } from '@arco-design/web-react/icon';
const Option = Select.Option;
const InputSearch = Input.Search;

class Group {
  having: {
    aggUUID: string;
    alias: string;
    field: string;
    op: string;
    operator: string;
    selectField: string;
    value: string;
  };
  id;
  constructor() {
    this.id = uniqueId();
  }
}

export default function GroupContent({ data, fields, meta, modal }) {
  const { fields: tableFields, sql, templateSQL } = get(meta, 'tableData');
  const { id, type } = data.previousData.nodeProperty;

  const [fieldsList, setFieldsList] = useState(cloneDeep(tableFields));
  const selectedFields = useMemo(
    () => filter(fieldsList, (v) => v.checked && !v.hidden),
    [fieldsList]
  );

  const groupID = useRef(meta.FormInfo?.groupID || uniqueId('util_')).current;
  const [groupData, setGroupData] = useState(
    meta.FormInfo?.groupData || [new Group()]
  );
  const { name } = fields;

  const [operation, setOperation] = useState(
    meta.FormInfo?.operation || 'count'
  );
  const [operationField, setOperationField] = useState(
    meta.FormInfo?.operationField || {
      aggUUID: uniqueId(),
      alias: '',
      field: '',
      op: '',
      operator: '',
      selectField: '',
      value: '',
    }
  );

  useEffect(() => {
    groupData[0].having = {
      aggUUID: operationField.aggUUID,
      alias: `${operationField.alias}_${operation}`,
      field: `${operation}(${groupID}.${operationField.field})`,
      op: '=',
      operator: operation,
      selectField: `${groupID}.${operationField.field}`,
      value: '',
    };
    setGroupData([...groupData]);
  }, [operation, operationField]);

  useEffect(() => {
    if (meta.FormInfo?.selectedFields) {
      const selectedFieldsMap = [];
      forEach(meta.FormInfo?.selectedFields, (v) => {
        selectedFieldsMap.push(v.field);
      });
      forEach(fieldsList, (v) => {
        // includes(meta.FormInfo?.selectedFields, {});
        if (includes(selectedFieldsMap, v.field)) {
          v.checked = true;
        }
      });
      setFieldsList([...fieldsList]);
    }
  }, []);
  const settings = selectedFields.map((v) => {
    const field = groupID + '.' + v.field;
    return {
      ...v,
      field,
    };
  });
  const queryParams = {
    inputs: [
      {
        id: id,
        sql: sql,
        tableName: name,
        templateSQL: templateSQL,
        type: type,
      },
    ],
    current: {
      type: 'group',
      id: groupID,
      settings: JSON.stringify({
        select: settings,
        select2: [],
        groupBy: settings,
        having: groupData[0].having,
      }),
    },
  };

  const [sreachText, setSreachText] = useState('');

  useEffect(() => {
    meta.FormInfo = {
      groupData,
      selectedFields,
      operationField,
      operation,
      groupID,
    };
  }, [groupData, selectedFields, operationField]);

  return (
    <div className="filter-content">
      <div className="font-hm-16-bold pb-[20px]">分组</div>
      <div className="flex">
        <div className="group w-[60%]">
          <div className="font-hm-16-bold pb-[20px]">条件设置</div>
          <div className="tool">
            {/* <Button onClick={() => setGroupData([...groupData, new Group()])}>
              添加组
            </Button> */}
          </div>
          {groupData.map((v) => {
            const defaultValue =
              find(fieldsList, {
                field: nth(operationField.field?.split('.') || [], -1),
              }) || get(fieldsList, '[0]');
            assign(operationField, defaultValue);
            return (
              <div
                className="flex item my-[10px] flex-col border mr-[10px] rounded"
                key={v.id}
              >
                <div className="group-Item flex m-[10px]">
                  <div className="condition w-[70%] grid gap-[20px]">
                    <div>
                      <p>聚合字段</p>
                      <Select
                        defaultValue={defaultValue}
                        onChange={(value) => {
                          setOperationField({
                            ...operationField,
                            ...value,
                          });
                        }}
                        placeholder="请选择"
                      >
                        {fieldsList.map((option) => (
                          <Option key={option.field} value={option}>
                            {option.alias}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <p>聚合操作</p>
                      <Select
                        defaultValue={operation}
                        onChange={(value) => {
                          setOperation(value);
                        }}
                        value={operation}
                      >
                        {groupFilter.map((r) => (
                          <Option key={r.code} value={r.code}>
                            {r.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-[40%] font-hm-16-bold ">
          <div>
            <span>显示列设置</span>
          </div>
          <div>
            <p className="mt-[10px]">{name}</p>
            <div className="flex items-center">
              <InputSearch
                onChange={(val) => setSreachText(val)}
                onSearch={() => {
                  setFieldsList(
                    fieldsList.map((v) => {
                      return {
                        ...v,
                        hidden: !v.alias.includes(sreachText),
                      };
                    })
                  );
                }}
                placeholder="搜素字段名"
                searchButton
                style={{ width: 200, margin: 12 }}
              />
              <Checkbox
                onChange={(flag: boolean) => {
                  setFieldsList(
                    map(fieldsList, (v) => ({ ...v, checked: flag }))
                  );
                }}
              >
                全选
              </Checkbox>
              <Checkbox
                onChange={() => {
                  setFieldsList(
                    map(fieldsList, (v) => ({
                      ...v,
                      checked: !v.checked,
                    }))
                  );
                }}
              >
                反选
              </Checkbox>
            </div>
          </div>
          <div className="filed-h my-[10px]">
            {fieldsList.map((v) =>
              v.hidden ? null : (
                <Tag
                  checkable
                  checked={v.checked}
                  className="m-[5px] border border-primary"
                  color="#FF5800"
                  key={v.field}
                  onCheck={(flag) => {
                    v.checked = flag;
                    setFieldsList([...fieldsList]);
                  }}
                >
                  {v.alias}
                </Tag>
              )
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="mr-[30px]"
          onClick={() => {
            modal.current.close();
            modal.ok(queryParams);
          }}
          type="primary"
        >
          提交
        </Button>
        <Button onClick={() => modal.current.close()} type="secondary">
          取消
        </Button>
      </div>
    </div>
  );
}
