import React from 'react';
import { Form, Input, Select, FormInstance } from '@arco-design/web-react';
import { TreeDataType } from '@arco-design/web-react/es/Tree/interface';
import { map } from 'lodash';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

export interface CommonFormProps {
  form?: FormInstance<any, any, string | number | symbol>;
  initialValue?: Record<string, any> | null;
  isEdit?: boolean;
  departmentList?: TreeDataType[];
  dataSourceOptions?: any[];
}

export function ConfigureDepartmentOrUserForm(props: CommonFormProps) {
  const { form, initialValue, dataSourceOptions = [] } = props;
  const { datasourceId, sql } = initialValue || {};

  return (
    <Form form={form} layout="vertical">
      <FormItem
        field="datasourceId"
        initialValue={datasourceId}
        label="选择数据源"
        rules={[{ required: true, message: '数据源不能为空' }]}
      >
        <Select placeholder="请选择数据源">
          {map(dataSourceOptions, (option) => (
            <Option key={option.id} value={option.id}>
              {option.label}
            </Option>
          ))}
        </Select>
      </FormItem>

      <FormItem
        field="sql"
        initialValue={sql}
        label="查询SQL"
        rules={[
          {
            required: true,
            message: '查询SQL不能为空',
          },
        ]}
      >
        <TextArea
          placeholder="请输入查询科室列表的SQL"
          style={{ minHeight: 64, width: '100%' }}
        />
      </FormItem>
    </Form>
  );
}
