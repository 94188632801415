import { queryClient } from 'QueryClient';
import { Task } from 'redux-saga';
import { call, fork } from 'redux-saga/effects';
import { sagaMiddleware } from 'store';

/**
 * @param cache: string
 * @param Api: () => Promise<any>
 * @param before GeneratorFunction
 */
export function* useCallApiWithCache(cache, Api, before): any {
  const queryCache = queryClient.getQueryData(cache);
  const response: Response = yield queryCache ||
    call(() => queryClient.fetchQuery(cache, Api));
  if (queryCache) {
    const updateTask: Task = yield fork(() =>
      queryClient.fetchQuery('getAllApplication', Api)
    );
    updateTask.toPromise().then((response) => {
      sagaMiddleware.run(before, response);
    });
  }
  yield call(before, response);
}
