import { isArray } from 'lodash';
import { IS_SUCCESS_CODE } from './constants';
import history from 'utils/history';

const pagesNoNeedAuth = ['/api/page/login', '/api/page/verify'];

type SetCookieOptions = {
  path?: string;
  expires?: Date | string;
  httpOnly?: boolean;
  [key: string]: unknown;
};

export const installModeCookieName = 'G-Token';

const mainCookieName = 'SESSION';

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

export function setCookie(name, value, options: SetCookieOptions = {}) {
  options = {
    path: '/',
    // add other defaults here if necessary
    ...options,
  };

  if (typeof options.expires === 'string') {
    options.expires = new Date(options.expires);
  }
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function clearCookie() {
  setCookie(installModeCookieName, '', { expires: '-1' });
  setCookie(mainCookieName, '', { expires: '-1' });
}

export function checkLogin(): boolean {
  const token = getCookie('G-Token');
  const skipAuth = pagesNoNeedAuth.includes(location.pathname);
  if (skipAuth) {
    return true;
  }
  return !!token;
}

export function encodePayload(payload: string): string {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(payload));

  // return btoa(
  //   encodeURIComponent(payload).replace(/%([0-9A-F]{2})/g, (match, p1) => {
  //     return String.fromCharCode(Number('0x' + p1));
  //   })
  // );
}

export function decodePayload(payload: string): any {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(payload));

  // return decodeURIComponent(
  //   atob(payload)
  //     .split('')
  //     .map((c) => {
  //       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //     })
  //     .join('')
  // );
}

export const removeImgPrefix = (imgUrl: string) => imgUrl?.split('/')?.[1];

export const isValidResponse = (resMeta) => {
  if (resMeta?.code === IS_SUCCESS_CODE) {
    return true;
  }

  return false;
};

export const isSuccessResponse = (resMeta) => {
  if (resMeta?.success === true) {
    return true;
  }

  return false;
};

export const formatDataToTreeData = (
  data: any[],
  mapKey = 'id',
  mapTitle = 'name'
) => {
  function loop(sourceData: any[]) {
    const res = [];
    for (let i = 0; i < sourceData.length; i++) {
      const item = sourceData[i];

      res.push({
        key: item[mapKey],
        title: item[mapTitle],
        children: isArray(item?.children) ? loop(item.children) : [],
      });
    }
    return res;
  }

  return loop(data);
};

export const redirectLoginPage = (params?: {
  hash?: string;
  search?: string;
  url?: string;
}) => {
  const { hash = '', search = '', url = '/api/page/login' } = params || {};
  const redirectUrl = `${url}${search}${hash}`;
  history.push(redirectUrl);
  localStorage.clear();
};
