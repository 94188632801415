import React, { useLayoutEffect, useRef, useState } from 'react';
import GradientParser from 'gradient-parser';
import { findLastIndex } from 'lodash';

function LinearGradient(props: { id: string; fill: string }) {
  const { fill, id } = props;

  const { colorStops, orientation } = GradientParser.parse(fill)[0];

  const renderColorStop = (colorStop, index) => {
    const key = `color-stop-${index}`;
    const offset = (index / (colorStops.length - 1)) * 100 + '%';
    let stopColor = 'rgb(0,0,0)';
    let stopOpacity = 1.0;

    switch (colorStop.type) {
      case 'rgb': {
        const [r, g, b] = colorStop.value;
        stopColor = `rgb(${r},${g},${b})`;
        break;
      }

      case 'rgba': {
        const [r, g, b, a] = colorStop.value;
        stopColor = `rgb(${r},${g},${b})`;
        stopOpacity = Number(a);
        break;
      }

      case 'hex': {
        stopColor = `#${colorStop.value}`;
        break;
      }

      case 'literal': {
        stopColor = colorStop.value;
        break;
      }

      default:
        break;
    }

    const stopProps = {
      key,
      offset,
      stopColor,
      stopOpacity: undefined,
    };

    if (stopOpacity < 1.0) {
      stopProps.stopOpacity = stopOpacity;
    }

    return <stop {...stopProps} />;
  };

  return (
    <linearGradient
      gradientTransform={`rotate(${orientation.value - 90})`}
      id={id}
    >
      {colorStops.map(renderColorStop)}
    </linearGradient>
  );
}

function TextGradient({
  fill = `linear-gradient(
          92deg,
          #ff71ad 4%,
          #ff71ad 4%,
          #ffa852 96%,
          #ffa852 96%
        )`,
  ...other
}: {
  fill?: string;
  [k: string]: any;
}) {
  let style;
  const isWebkit = 'WebkitTextFillColor' in document.documentElement.style;

  const [textStyle, setTextStyle] = useState({});
  const [leadHeight, setLeadHeight] = useState(14);
  const ref = useRef(null);
  useLayoutEffect(() => {
    if (!isWebkit && ref.current) {
      const { font, fontSize } = getComputedStyle(ref.current);
      setTextStyle({ font });
      const num = Number(
        fontSize.slice(
          0,
          findLastIndex(fontSize.split(''), (v) => /\d/.test(v)) + 1
        )
      );
      setLeadHeight(num - 1 + ((ref.current.offsetHeight as number) - num) / 2);
    }
  }, []);

  if (isWebkit) {
    style = {
      display: 'inline',
      color: 'transparent',
      backgroundImage: fill,
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
    };
  } else {
    style = {
      position: 'relative',
      display: 'inline',
      overflow: 'hidden',
    };
  }
  if (isWebkit) {
    return (
      <span {...other} style={style}>
        {other.children}
      </span>
    );
  } else
    return (
      <span {...other} style={style}>
        <svg className="absolute top-0">
          <defs>
            <LinearGradient fill={fill} id="LinearGradient" />
          </defs>
          <text fill="url(#LinearGradient)" style={textStyle} y={leadHeight}>
            {other.children}
          </text>
        </svg>
        <span ref={ref} style={{ color: 'rgba(0,0,0,0)' }}>
          {other.children}
        </span>
      </span>
    );
}

export default TextGradient;
