import { loading } from 'selectors/onboardingSelectors';
import { createReducer } from 'utils/AppsmithUtils';
import {
  ReduxAction,
  ReduxActionTypes,
} from '@appsmith/constants/ReduxActionConstants';
import {
  filterCategories,
  RecentEntity,
  SearchCategory,
  SEARCH_CATEGORY_ID,
} from 'components/editorComponents/GlobalSearch/utils';
import { SysConfigMenuName } from 'pages/Editor/Explorer/GlobalSysConfig/constants';

export enum SnippetAction {
  INSERT,
  COPY,
}

export type QueryDrawerType = 'api' | 'js' | 'sql';

const initialState: GlobalSearchReduxState = {
  query: '', // used to prefill when opened via contextual help links
  modalOpen: false,
  recentEntities: [],
  recentEntitiesRestored: false,
  queryDrawerWrapperRef: null,
  showEditQueryDrawer: false,
  showAppSysConfigDrawer: false,
  appSysConfigPageName: null,
  showQueryNameEditor: false,
  showCreateQuery: false,
  createTempleteQueryParams: {
    applicationSlug: '',
    pageSlug: '',
    applicationId: '',
  },
  queryDrawerType: 'sql',
  firstGuide: false,
  createTempleteQueryId: '',
  showGraph: false,
  loading: false,
  filterContext: {
    category: filterCategories[SEARCH_CATEGORY_ID.DOCUMENTATION],
    fieldMeta: {},
    refinements: {},
    evaluatedSnippet: '',
    executionInProgress: false,
    evaluatedArguments: {},
    onEnter: SnippetAction.COPY,
    hideOuterBindings: false,
  },
};

const globalSearchReducer = createReducer(initialState, {
  [ReduxActionTypes.GRAPH_VISIBLE]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<boolean>
  ) => ({
    ...state,
    showGraph: action.payload,
  }),
  [ReduxActionTypes.Graph_Loading]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<boolean>
  ) => ({
    ...state,
    loading: action.payload,
  }),
  [ReduxActionTypes.CREATE_TEMPLETE_QUERY_ID]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<string>
  ) => ({
    ...state,
    createTempleteQueryId: action.payload,
  }),
  [ReduxActionTypes.CREATE_TEMPLETE_QUERY_PARAMS]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<{
      applicationSlug: string;
      pageSlug: string;
      applicationId: string;
    }>
  ) => ({
    ...state,
    createTempleteQueryParams: action.payload,
  }),
  [ReduxActionTypes.MODIFY_QUERY_DRAWER_TYPE]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<QueryDrawerType>
  ) => ({
    ...state,
    queryDrawerType: action.payload,
  }),
  [ReduxActionTypes.SET_QUERY_DRAWER_WRAPPER_REF]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<HTMLDivElement>
  ) => ({
    ...state,
    queryDrawerWrapperRef: action.payload,
  }),
  [ReduxActionTypes.SHOW_EDIT_QUERY_DRAWER]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<boolean>
  ) => ({
    ...state,
    showEditQueryDrawer: action.payload,
  }),
  [ReduxActionTypes.SHOW_APP_SYS_CONFIG_DRAWER]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<boolean>
  ) => ({
    ...state,
    showAppSysConfigDrawer: action.payload,
  }),
  [ReduxActionTypes.SET_APP_SYS_CONFIG_PAGE]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<SysConfigMenuName | null>
  ) => ({
    ...state,
    appSysConfigPageName: action.payload,
  }),
  [ReduxActionTypes.SHOW_EDIT_QUERY_DRAWER_CREATE]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<boolean>
  ) => ({
    ...state,
    showEditQueryDrawerCreate: action.payload,
  }),
  [ReduxActionTypes.TOGGLE_EDIT_QUERY_NAME_EDITOR]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<boolean>
  ) => ({
    ...state,
    showQueryNameEditor: action.payload,
  }),
  [ReduxActionTypes.SHOW_CREATE_QUERY]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<boolean>
  ) => ({
    ...state,
    showCreateQuery: action.payload,
  }),
  [ReduxActionTypes.SET_GLOBAL_SEARCH_QUERY]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<string>
  ) => ({ ...state, query: action.payload }),
  [ReduxActionTypes.TOGGLE_SHOW_GLOBAL_SEARCH_MODAL]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<SearchCategory>
  ) => ({
    ...state,
    modalOpen: !state.modalOpen,
    filterContext: state.modalOpen
      ? initialState.filterContext
      : {
          ...state.filterContext,
          category: action.payload,
          onEnter: SnippetAction.COPY,
        },
  }),
  [ReduxActionTypes.SET_SEARCH_FILTER_CONTEXT]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<Partial<GlobalSearchReduxState['filterContext']>>
  ) => ({
    ...state,
    filterContext: {
      ...state.filterContext,
      ...action.payload,
    },
  }),
  [ReduxActionTypes.SET_EVALUATED_SNIPPET]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<Partial<GlobalSearchReduxState['filterContext']>>
  ) => ({
    ...state,
    filterContext: {
      ...state.filterContext,
      evaluatedSnippet: action.payload,
    },
  }),
  [ReduxActionTypes.SET_EVALUATED_ARGUMENT]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<Partial<GlobalSearchReduxState['filterContext']>>
  ) => ({
    ...state,
    filterContext: {
      ...state.filterContext,
      evaluatedArguments: {
        ...state.filterContext.evaluatedArguments,
        ...action.payload,
      },
    },
  }),
  [ReduxActionTypes.UNSET_EVALUATED_ARGUMENT]: (
    state: GlobalSearchReduxState
  ) => ({
    ...state,
    filterContext: {
      ...state.filterContext,
      evaluatedArguments: {},
    },
  }),
  [ReduxActionTypes.SET_RECENT_ENTITIES]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<Array<RecentEntity>>
  ) => ({
    ...state,
    recentEntities: (action.payload || []).filter((a: RecentEntity) => a),
  }),
  [ReduxActionTypes.RESET_RECENT_ENTITIES]: (
    state: GlobalSearchReduxState
  ) => ({
    ...state,
    recentEntities: [],
    recentEntitiesRestored: false,
  }),
  [ReduxActionTypes.RESTORE_RECENT_ENTITIES_SUCCESS]: (
    state: GlobalSearchReduxState
  ) => ({
    ...state,
    recentEntitiesRestored: true,
  }),
  [ReduxActionTypes.FIRST_GUIDE]: (
    state: GlobalSearchReduxState,
    action: ReduxAction<Array<RecentEntity>>
  ) => ({
    ...state,
    firstGuide: action.payload,
  }),
});
export interface GlobalSearchReduxState {
  query: string;
  modalOpen: boolean;
  recentEntities: Array<RecentEntity>;
  recentEntitiesRestored: boolean;
  queryDrawerWrapperRef: HTMLDivElement;
  showEditQueryDrawer: boolean;
  showAppSysConfigDrawer: boolean;
  appSysConfigPageName: SysConfigMenuName;
  showQueryNameEditor: boolean;
  showCreateQuery: boolean;
  createTempleteQueryParams: {
    applicationSlug: string;
    pageSlug: string;
    applicationId: string;
  };
  loading: boolean;
  showGraph: boolean;
  queryDrawerType: QueryDrawerType;
  createTempleteQueryId: string;
  filterContext: {
    category: SearchCategory;
    refinements: {
      entities?: [string];
    };
    fieldMeta?: {
      dataType?: string;
      field?: string;
    };
    onEnter: SnippetAction;
    evaluatedSnippet: string;
    executionInProgress: boolean;
    evaluatedArguments: any;
    hideOuterBindings: boolean;
  };
  firstGuide: boolean | number;
}

export default globalSearchReducer;
