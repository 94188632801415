import React from 'react';

import { useGuide } from 'guide/guide';

type Props = {
  children: React.ReactNode;
  type: string;
  step: number;
  onStart?: () => void;
  onEnd?: () => void;
};

function CodeEditorContainer({
  children,
  onEnd,
  onStart,
  step,
  type,
}: Props): JSX.Element {
  const guideUseRef = React.useRef<HTMLDivElement>();

  const { isCurrent, isPrev } = useGuide(guideUseRef, step, type);

  React.useEffect(() => {
    if (isCurrent) onStart?.();
  }, [isCurrent, onStart]);

  React.useEffect(() => {
    if (isPrev) onEnd?.();
  }, [isPrev, onEnd]);

  return (
    <div className="h-full" ref={guideUseRef}>
      {children}
    </div>
  );
}

export default CodeEditorContainer;
