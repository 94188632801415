import React, { useEffect } from 'react';
import JsBarcode from 'jsbarcode';
import { Checkbox, Table, TableColumnProps } from '@arco-design/web-react';
import styled from 'styled-components';
import dayjs from 'dayjs';

enum PHYSICAL_EXAMINATION {
  TITLE = 'title', // 打印标题
  ID = 'id', // 体检编号
  NAME = 'name', // 姓名
  GENDER = 'gender', //性别
  AGE = 'age', // 年龄
  PHONE = 'phone', // 电话
  ID_CARD = 'idCard', // 身份证号码
  PLAN = 'plan', // 体检套餐
  BELONGS = 'belongs', // 单位
  REG_TIME = 'regTime', // 注册时间

  ROW_KEY = 'id',
  TERM_TYPE = 'termType', // 体检项目分类 1.医生检查项目 2.抽血及其他检验项目 3.功能检查
  EXAM_TERMS = 'examTerms', //

  TERM_NAME = 'termName', // 项目名称
}

interface IPrintTemplateProps {
  [PHYSICAL_EXAMINATION.TITLE]?: string;
  [PHYSICAL_EXAMINATION.ID]?: string;
  [PHYSICAL_EXAMINATION.NAME]?: string;
  [PHYSICAL_EXAMINATION.GENDER]?: string;
  [PHYSICAL_EXAMINATION.AGE]?: string | number;
  [PHYSICAL_EXAMINATION.PHONE]?: string;
  [PHYSICAL_EXAMINATION.ID_CARD]?: string;
  [PHYSICAL_EXAMINATION.PLAN]?: string;
  [PHYSICAL_EXAMINATION.BELONGS]?: string;
  [PHYSICAL_EXAMINATION.REG_TIME]?: string;

  [PHYSICAL_EXAMINATION.EXAM_TERMS]?: Array<Record<string, any>>;
}

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0;
`;

const StyledInfoItem = styled.div<{ $isBarcode?: boolean }>`
  display: flex;
  padding-bottom: 12px;
  font-size: 12px;
  align-items: ${(props) => (props.$isBarcode ? 'flex-start' : 'center')};
`;

const columnTypeMap = {
  1: '医生检查项目',
  2: '抽血及其他检验项目',
  3: '功能检查',
};

const getColumns = (type: string | number): TableColumnProps[] => [
  {
    title: columnTypeMap[type],
    dataIndex: 'ZHMC',
    bodyCellStyle: { border: 'none' },
    render: (col, record) => (
      <span className="flex">
        <StyledCheckbox>
          {record[PHYSICAL_EXAMINATION.TERM_NAME]}
        </StyledCheckbox>
      </span>
    ),
  },
  {
    title: '提示信息',
    dataIndex: 'tip',
    bodyCellStyle: { border: 'none' },
  },
  {
    title: '科室地址',
    dataIndex: 'address',
    bodyCellStyle: { border: 'none' },
  },
  {
    title: '检查医生',
    dataIndex: 'doctor',
    bodyCellStyle: { border: 'none' },
  },
];

export default function ComponentToPrint(props: IPrintTemplateProps) {
  const physicalId = props[PHYSICAL_EXAMINATION.ID];
  const doctorTerms = props[PHYSICAL_EXAMINATION.EXAM_TERMS]?.filter((row) =>
    [1, '1'].includes(row[PHYSICAL_EXAMINATION.TERM_TYPE])
  );
  const bloodTerms = props[PHYSICAL_EXAMINATION.EXAM_TERMS]?.filter((row) =>
    [2, '2'].includes(row[PHYSICAL_EXAMINATION.TERM_TYPE])
  );
  const performTerms = props[PHYSICAL_EXAMINATION.EXAM_TERMS]?.filter((row) =>
    [3, '3'].includes(row[PHYSICAL_EXAMINATION.TERM_TYPE])
  );

  useEffect(() => {
    if (!physicalId) return;
    JsBarcode('#barcode', physicalId, {
      width: 1,
      height: 40,
      margin: 0,
      fontSize: 10,
    });
  }, [physicalId]);

  return (
    <div className="w-screen h-screen px-16">
      <style media="print" type="text/css">
        {'\
          @page { size: portrait; }\
        '}
      </style>
      <div className="relative w-full h-full mx-auto text-black text-14">
        <div className="pt-2 font-bold text-center text-26">
          {props[PHYSICAL_EXAMINATION.TITLE] || '紫薇市医院体检指引单'}
        </div>
        <div className="pt-3 text-right text-16">
          登记日期：{' '}
          {dayjs(props[PHYSICAL_EXAMINATION.REG_TIME] || Date.now()).format(
            'YYYY-MM-DD'
          )}
        </div>
        <div className="py-2 border-b border-black">
          <div className="flex justify-between">
            <StyledInfoItem>
              <div className="mr-1">体检编号:</div>
              <div className="ml-0.5 h-5 px-2 leading-5 border-black border-b">
                {props[PHYSICAL_EXAMINATION.ID]}
              </div>
            </StyledInfoItem>
            <StyledInfoItem>
              <div className="mr-1">姓名:</div>
              <div className="ml-0.5 h-5 px-2 leading-5 border-black border-b">
                {props[PHYSICAL_EXAMINATION.NAME]}
              </div>
            </StyledInfoItem>
            <StyledInfoItem>
              <div className="mr-1">性别:</div>
              <div className="ml-0.5 h-5 px-2 leading-5 border-black border-b">
                {props[PHYSICAL_EXAMINATION.GENDER]}
              </div>
            </StyledInfoItem>
            <StyledInfoItem>
              <div className="mr-1">年龄:</div>
              <div className="ml-0.5 h-5 px-2 leading-5 border-black border-b">
                {props[PHYSICAL_EXAMINATION.AGE]}
              </div>
            </StyledInfoItem>
          </div>
          <div className="flex items-start">
            <div className="flex flex-wrap">
              <StyledInfoItem>
                <div className="mr-1">手机号码:</div>
                <div className="ml-0.5 h-5 px-2 leading-5 border-black border-b">
                  {props[PHYSICAL_EXAMINATION.PHONE]}
                </div>
              </StyledInfoItem>
              <StyledInfoItem>
                <div className="mr-1">体检套餐:</div>
                <div className="ml-0.5 h-5 px-2 leading-5 border-black border-b">
                  {props[PHYSICAL_EXAMINATION.PLAN]}
                </div>
              </StyledInfoItem>
              <StyledInfoItem>
                <div className="mr-1">身份证号码:</div>
                <div className="ml-0.5 h-5 px-2 leading-5 border-black border-b">
                  {props[PHYSICAL_EXAMINATION.ID_CARD]}
                </div>
              </StyledInfoItem>
              <StyledInfoItem>
                <div className="mr-1">单位:</div>
                <div className="ml-0.5 h-5 px-2 leading-5 border-black border-b">
                  {props[PHYSICAL_EXAMINATION.BELONGS]}
                </div>
              </StyledInfoItem>
            </div>
            <div>
              <StyledInfoItem $isBarcode>
                <div className="mr-1">编号:</div>
                <svg id="barcode" />
              </StyledInfoItem>
            </div>
          </div>
        </div>
        <div className="flex items-center pt-1 border-b border-black">
          <div className="mr-2">温馨提示</div>
          <div>
            <p className="mb-0">1.体检前正常休息，避免过度饮酒劳累</p>
            <p className="mb-0">2.空腹进行采血和超声（肝胆脾）检查</p>
            <p className="mb-0">
              3.女性体检尿化检，妇科检查应避免月经期；妇科检查仅限于已婚女性；怀孕及可
              <br />
              能已受孕检查者勿做放射线检查、宫颈涂片检查
            </p>
          </div>
        </div>
        {doctorTerms?.length ? (
          <div className="pt-2">
            <Table
              border={false}
              columns={getColumns(1)}
              data={doctorTerms}
              pagination={false}
              rowKey={PHYSICAL_EXAMINATION.ROW_KEY}
              size="mini"
            />
          </div>
        ) : null}
        {bloodTerms?.length ? (
          <div className="pt-2">
            <Table
              border={false}
              columns={getColumns(2)}
              data={bloodTerms}
              pagination={false}
              rowKey={PHYSICAL_EXAMINATION.ROW_KEY}
              size="mini"
            />
          </div>
        ) : null}
        {performTerms?.length ? (
          <div className="pt-2">
            <Table
              border={false}
              columns={getColumns(3)}
              data={performTerms}
              pagination={false}
              rowKey={PHYSICAL_EXAMINATION.ROW_KEY}
              size="mini"
            />
          </div>
        ) : null}

        <div className="pt-10 font-semibold text-right pr-36 text-16">
          本人签字:
        </div>

        <div className="flex justify-between font-medium pt-0.5 pb-8 border-t border-black absolute bottom-0 w-full">
          <div>{dayjs().format('YYYY-MM-DD')}</div>
          <div>第1页</div>
          <div>福鑫科创</div>
        </div>
      </div>
    </div>
  );
}
