import Api from 'api/Api';

export default class LoginApi {
  static url = 'v1';
  static contentType = 'application/x-www-form-urlencoded';

  static emailSignup = (params) => {
    return Api.post(LoginApi.url + '/users', { ...params });
  };

  static getCode = (params) => {
    return Api.get(
      LoginApi.url + '/sms/send',
      { ...params },
      {
        headers: {
          'Content-Type': LoginApi.contentType,
        },
      }
    );
  };
  static getVerity = () => {
    return Api.get(LoginApi.url + '/sms/getRandNumPic', null, {
      headers: {
        'Content-Type': LoginApi.contentType,
      },
    });
  };

  static checkVerity = (params) => {
    return Api.get(
      LoginApi.url + '/sms/checkPicCode',
      { ...params },
      {
        headers: {
          'Content-Type': LoginApi.contentType,
        },
      }
    );
  };

  static checkCode = (params) => {
    return Api.get(
      LoginApi.url + '/sms/checkSmsCode',
      { ...params },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };

  static resetPwd = (params) => {
    return Api.post(LoginApi.url + '/users/resetNewPassword', { ...params });
  };
}
