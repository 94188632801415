import { Form, Input, Radio } from '@arco-design/web-react';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Select } from '@arco-design/web-react';
import {
  fieldTypes,
  formatTypes,
  formatTypes_1,
  mathTypes,
  plainTypes,
} from '../constants';
const Option = Select.Option;
const FormItem = Form.Item;
function SubstringForm() {
  return (
    <div>
      <FormItem
        field="alias"
        label="设置名称"
        rules={[{ required: true, message: '名称是必填项' }]}
      >
        <Input placeholder="newField" />
      </FormItem>
      <FormItem
        field="start"
        label="起"
        rules={[{ required: true, message: '起始位是必填项' }]}
      >
        <Input placeholder="请输入起始位" type="number" />
      </FormItem>
      <FormItem
        field="end"
        label="止"
        rules={[{ required: true, message: '截至位是必填项' }]}
      >
        <Input placeholder="请输入截止位" type="number" />
      </FormItem>
    </div>
  );
}

function ReplaceForm({ selectData }) {
  const [subType, setSubType] = useState(
    selectData?.config?.subType || 'replace_content'
  );

  return (
    <div>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="newField" />
      </FormItem>
      <FormItem field="subType" label="替换类型" rules={[{ required: true }]}>
        <Radio.Group
          onChange={(v) => {
            setSubType(v);
          }}
        >
          <Radio value="replace_content">内容替换</Radio>
          <Radio value="replace_position">位置替换</Radio>
        </Radio.Group>
      </FormItem>
      {subType === 'replace_content' ? (
        <FormItem field="content" label="指定内容" rules={[{ required: true }]}>
          <Input placeholder="请输入指定内容" />
        </FormItem>
      ) : (
        <FormItem label="指定位置">
          <FormItem field="start" rules={[{ required: true }]}>
            <Input placeholder="开始" />
          </FormItem>
          <FormItem field="end" rules={[{ required: true }]}>
            <Input placeholder="结束" />
          </FormItem>
        </FormItem>
      )}
      <FormItem field="replacement" label="替换为">
        <Input placeholder="请输入替换为" />
      </FormItem>
    </div>
  );
}

function ConvertForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  const [type, setType] = useState(selectData?.config?.convertType);
  return (
    <div>
      <FormItem field="alias" label="字段名" rules={[{ required: true }]}>
        <Input placeholder="newField" />
      </FormItem>
      <FormItem
        disabled
        field="fieldType"
        formatter={() => select_1.type}
        label="原字段类型"
        rules={[{ required: true }]}
      >
        <Input value={select_1?.type} />
      </FormItem>
      <FormItem
        field="convertType"
        label="变更类型"
        rules={[{ required: true }]}
      >
        <Select allowClear onChange={setType} placeholder="请选择">
          {fieldTypes.map((v) => (
            <Option key={v.code} value={v.code}>
              {v.name}
            </Option>
          ))}
        </Select>
      </FormItem>
      {type === 'date' ? (
        <FormItem field="format" label="日期类型" rules={[{ required: true }]}>
          <Select allowClear placeholder="请选择">
            {formatTypes.map((v) => (
              <Option key={v.code} value={v.code}>
                {v.name}
              </Option>
            ))}
          </Select>
        </FormItem>
      ) : null}
    </div>
  );
}

function TrimForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  return (
    <div>
      <FormItem
        field="fieldType"
        formatter={() => select_1.alias}
        initialValue={select_1.type}
        label="选择字段"
        rules={[{ required: true }]}
      >
        <Input disabled placeholder="newField" />
      </FormItem>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="请输入设置名称" />
      </FormItem>
    </div>
  );
}

function ConcatForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  const select_2 = get(selectData, 'fields[1]', {});
  return (
    <div>
      <FormItem
        field={'field1'}
        formatter={() => select_1.alias}
        initialValue={select_1.field}
        label="选择字段"
        rules={[{ required: true }]}
      >
        <Input disabled placeholder="newField" />
      </FormItem>
      <FormItem
        field={'field2'}
        formatter={() => select_2.alias}
        initialValue={select_2.field}
        label="选择字段"
        rules={[{ required: true }]}
      >
        <Input disabled placeholder="newField" />
      </FormItem>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="请输入设置名称" />
      </FormItem>
      <FormItem
        field="fieldType"
        hidden
        initialValue={'VARCHAR2'}
        label="设置名称"
        rules={[{ required: true }]}
      >
        <Input placeholder="请输入设置名称" />
      </FormItem>
    </div>
  );
}

function TimeConvertForm({ selectData }) {
  return (
    <div>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="请输入设置名称" />
      </FormItem>
      <FormItem
        field="fieldType"
        hidden
        initialValue={'VARCHAR2'}
        label="设置名称"
        rules={[{ required: true }]}
      >
        <Input placeholder="请输入设置名称" />
      </FormItem>
      <FormItem
        field="timeConvertType"
        initialValue={1}
        label="转换类型"
        rules={[{ required: true }]}
      >
        <Select allowClear placeholder="请选择">
          <Select.Option key={1} value={1}>
            小时
          </Select.Option>
          <Select.Option key={2} value={2}>
            年月日
          </Select.Option>
        </Select>
      </FormItem>
    </div>
  );
}

function IdCardConvertForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  const select_2 = get(selectData, 'fields[1]', {});
  return (
    <div>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="请输入设置名称" />
      </FormItem>
      <FormItem
        field="fieldType"
        hidden
        initialValue={'VARCHAR2'}
        label="设置名称"
        rules={[{ required: true }]}
      >
        <Input placeholder="请输入设置名称" />
      </FormItem>
      <FormItem
        field="idCardConvertType"
        initialValue={1}
        label="转换类型"
        rules={[{ required: true }]}
      >
        <Select allowClear placeholder="请选择">
          <Select.Option key={1} value={1}>
            出生日期
          </Select.Option>
          <Select.Option key={2} value={2}>
            年龄
          </Select.Option>
        </Select>
      </FormItem>
    </div>
  );
}

function DayOfWeekForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  const select_2 = get(selectData, 'fields[1]', {});
  return (
    <div>
      <FormItem
        field="fieldType"
        formatter={() => select_1.alias}
        initialValue={select_1.type}
        label="选择字段"
        rules={[{ required: true }]}
      >
        <Input disabled placeholder="newField" />
      </FormItem>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="请输入设置名称" />
      </FormItem>
    </div>
  );
}
function DateOpForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  const select_2 = get(selectData, 'fields[1]', {});
  const subType = select_1.field && select_2.field ? 'interval' : 'plain';
  return (
    <div>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="newField" value="id" />
      </FormItem>
      <FormItem label="设置条件">
        <div style={{ display: 'flex' }}>
          <FormItem
            field="subType"
            hidden
            initialValue={subType}
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入设置名称" />
          </FormItem>
          <FormItem
            className="mr-[10px]"
            disabled
            field="convertType"
            initialValue={select_1?.alias}
            label=""
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem>
          <FormItem
            className="mr-[10px]"
            field="op"
            initialValue={'+'}
            label=""
            rules={[{ required: true }]}
          >
            <Select placeholder="请选择">
              {plainTypes.map((v) => (
                <Option key={v.code} value={v.code}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem field="value" label="" rules={[{ required: true }]}>
            <Input placeholder="请输入" />
          </FormItem>
        </div>
      </FormItem>
    </div>
  );
}

function ColumnOpForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  const select_2 = get(selectData, 'fields[1]', {});
  const subType = 'column_value';
  return (
    <div>
      <FormItem
        field="subType"
        hidden
        initialValue={subType}
        rules={[{ required: true }]}
      >
        <Input placeholder="请输入设置名称" />
      </FormItem>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="newField" value="id" />
      </FormItem>
      <FormItem label="设置条件">
        <div style={{ display: 'flex' }}>
          <FormItem
            className="mr-[10px]"
            disabled
            field="convertType"
            initialValue={select_1?.alias}
            label=""
            rules={[{ required: true }]}
          >
            <Input />
          </FormItem>
          <FormItem
            className="mr-[10px]"
            field="op"
            initialValue={'+'}
            label=""
            rules={[{ required: true }]}
          >
            <Select placeholder="请选择">
              {mathTypes.map((v) => (
                <Option key={v.code} value={v.code}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem field="value" label="" rules={[{ required: true }]}>
            <Input placeholder="请输入设置名称" />
          </FormItem>
        </div>
      </FormItem>
    </div>
  );
}

function EmptyReplaceForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  return (
    <div>
      <FormItem
        disabled
        field="convertType"
        initialValue={select_1?.type}
        label="选择字段"
        rules={[{ required: true }]}
      >
        <Input />
      </FormItem>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="newField" value="id" />
      </FormItem>
      <FormItem field="value" label="输入值" rules={[{ required: true }]}>
        <Input placeholder="请输入设置内容" />
      </FormItem>
    </div>
  );
}

function NewColumnForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  return (
    <div>
      <FormItem field="alias" label="字段名" rules={[{ required: true }]}>
        <Input placeholder="newField" />
      </FormItem>
      <FormItem field="dataType" label="字段类型" rules={[{ required: true }]}>
        <Select allowClear placeholder="请选择">
          {fieldTypes.map((v) => (
            <Option key={v.code} value={v.code}>
              {v.name}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem field="value" label="输入值" rules={[{ required: true }]}>
        <Input placeholder="请输入值" />
      </FormItem>
    </div>
  );
}

function SameRingRatioForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  return (
    <div>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="newField" value="id" />
      </FormItem>
      <FormItem label="同环比设置" rules={[{ required: true }]}>
        <FormItem
          field="fieldType"
          formatter={() => select_1.alias}
          initialValue={select_1.type}
          label=""
          rules={[{ required: true }]}
        >
          <Input className="mb-[10px]" disabled placeholder="newField" />
        </FormItem>
        <FormItem
          field="subType"
          formatter={() => '减去'}
          initialValue="percent"
          label=""
          rules={[{ required: true }]}
        >
          <Input disabled />
        </FormItem>
      </FormItem>
    </div>
  );
}

function UnifiedFormatForm({ selectData }) {
  const select_1 = get(selectData, 'fields[0]', {});
  return (
    <div>
      <FormItem
        field="fieldType"
        formatter={() => select_1.alias}
        initialValue={select_1.type}
        label="选择字段"
        rules={[{ required: true }]}
      >
        <Input disabled placeholder="newField" />
      </FormItem>
      <FormItem field="alias" label="设置名称" rules={[{ required: true }]}>
        <Input placeholder="请输入设置名称" />
      </FormItem>
      <FormItem
        field="subType"
        initialValue={selectData?.config?.subType}
        label="统一格式"
        rules={[{ required: true }]}
      >
        <Select allowClear placeholder="请选择">
          {formatTypes_1.map((v) => (
            <Option key={v.code} value={v.code}>
              {v.name}
            </Option>
          ))}
        </Select>
      </FormItem>
    </div>
  );
}

export function GetTypeForm({ selectData, type }) {
  switch (type) {
    case 'substring':
      return <SubstringForm />;
    case 'replace':
      return <ReplaceForm selectData={selectData} />;
    case 'convert':
      return <ConvertForm selectData={selectData} />;
    case 'trim':
      return <TrimForm selectData={selectData} />;
    case 'concat':
      return <ConcatForm selectData={selectData} />;
    case 'timeConvert':
      return <TimeConvertForm selectData={selectData} />;
    case 'idCardConvert':
      return <IdCardConvertForm selectData={selectData} />;
    case 'dayOfWeek':
      return <DayOfWeekForm selectData={selectData} />;
    case 'dateOp':
      return <DateOpForm selectData={selectData} />;
    // 算数运算
    case 'columnOp':
      return <ColumnOpForm selectData={selectData} />;
    case 'emptyReplace':
      return <EmptyReplaceForm selectData={selectData} />;
    case 'newColumn':
      return <NewColumnForm selectData={selectData} />;
    case 'sameRingRatio':
      return <SameRingRatioForm selectData={selectData} />;
    case 'unifiedFormat':
      return <UnifiedFormatForm selectData={selectData} />;
  }
  return <div>无</div>;
}
