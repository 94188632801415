import React, { useEffect, useState } from 'react';
import { ReactComponent as ProfileImagePlaceholder } from 'assets/images/profile-placeholder.svg';
import Uppy from '@uppy/core';
import Dialog from 'components/ads/DialogComponent';

import { Dashboard, useUppy } from '@uppy/react';
import { getTypographyByKey } from 'constants/DefaultTheme';

import styled from 'styled-components';
import ImageEditor from '@uppy/image-editor';
import { REMOVE, createMessage } from '@appsmith/constants/messages';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import { get } from 'lodash';

type Props = {
  onChange: (file: File) => void;
  onRemove?: () => void;
  submit: (uppy: Uppy.Uppy) => void;
  value: string;
  label?: string;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & .input-component {
    display: none;
  }

  & .view {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    .image-view {
      width: 64px;
      height: 64px;

      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) =>
        props.theme.colors.displayImageUpload.background};
      border-radius: 50%;
      /* margin-bottom: ${(props) => props.theme.spaces[7]}px; */

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .label {
      ${(props) => getTypographyByKey(props, 'h6')}
      margin-left: 24px;
      color: var(--highlight);
    }
  }
`;

const defaultLabel = '上传你的头像';

export default function DisplayImageUpload({
  onChange,
  onRemove,
  submit,
  value,
}: Props) {
  const [loadError, setLoadError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uppy = useUppy(() => {
    const uppy = Uppy({
      id: 'uppy',
      autoProceed: false,
      allowMultipleUploads: false,
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 3145728, // 3 MB
        allowedFileTypes: ['.jpg', '.jpeg', '.png'],
      },
      infoTimeout: 5000,
      locale: {
        strings: {},
      },
    });

    uppy.setOptions({
      locale: {
        strings: {
          done: 'Cancel',
          dropPasteFiles: '拖拽文件到这或者 %{browseFiles}',
          browseFiles: '导入',
          editFile: '编辑',
          editing: '编辑 %{file}',
          save: '保存',
          cancel: '取消',
          xFilesSelected: {
            0: '%{smart_count}个文件已选择',
            1: '%{smart_count}个文件已选择',
          },
          uploadXFiles: {
            0: '上传%{smart_count}个文件',
            1: '上传%{smart_count}个文件',
          },
        },
      },
    });

    const editor = uppy.use(ImageEditor, {
      id: 'ImageEditor',
      quality: 0.3,
      cropperOptions: {
        viewMode: 1,
        aspectRatio: 1,
        background: false,
        responsive: true,
        autoCropArea: 0.8,
        autoCrop: true,
      },
      actions: {
        revert: false,
        rotate: false,
        flip: false,
        zoomIn: false,
        zoomOut: false,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
    });

    uppy.on('file-added', (file: File) => {
      onChange(file);
      // TO trigger edit modal
      const dashboard = uppy.getPlugin('uppy-img-upload-dashboard');
      setTimeout(() => {
        (dashboard as any).openFileEditor(file);
      });
    });

    uppy.on('upload', () => {
      submit(uppy);
      setIsModalOpen(false);
    });

    uppy.on('file-editor:complete', (updatedFile) => {
      onChange(updatedFile);
    });

    const rawSave = get(
      editor.getPlugin('ImageEditor'),
      'save',
      () => undefined
    );

    editor.getPlugin('ImageEditor').save = (...args) => {
      if (args.length) {
        rawSave(...args);
      }
    };

    return uppy;
  });

  useEffect(() => {
    if (value) setLoadError(false);
  }, [value]);

  return (
    <Container onClick={() => setIsModalOpen(true)}>
      <Dialog
        canEscapeKeyClose
        canOutsideClickClose
        className="file-picker-dialog"
        isOpen={isModalOpen}
        trigger={
          <div className="view">
            <div className="image-view">
              {!value || loadError ? (
                <ProfileImagePlaceholder />
              ) : (
                <img
                  onError={() => {
                    setLoadError(true);
                  }}
                  onLoad={() => setLoadError(false)}
                  src={value}
                />
              )}
            </div>
            {(!value || loadError) && (
              <span className="label">{defaultLabel}</span>
            )}
            {value && !loadError && (
              <span
                className="label"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (onRemove) onRemove();
                }}
              >
                {createMessage(REMOVE)}
              </span>
            )}
          </div>
        }
      >
        <Dashboard
          id="uppy-img-upload-dashboard"
          note="文件大小不得超过 3 MB"
          plugins={['ImageEditor']}
          uppy={uppy}
        />
      </Dialog>
    </Container>
  );
}
