import React, {
  Context,
  createContext,
  RefObject,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import SvgIcon from 'components/icon';
import DebuggerLogs from './DebuggerLogs';
import { useDispatch } from 'react-redux';
import { showDebugger } from 'actions/debuggerActions';
import Errors from './Errors';
// import Resizer, { ResizerCSS } from './Resizer';
import EntityDeps from './EntityDependecies';
import {
  createMessage,
  DEBUGGER_ERRORS,
  DEBUGGER_LOGS,
  INSPECT_ENTITY,
} from '@appsmith/constants/messages';
import { stopEventPropagation } from 'utils/AppsmithUtils';
import { DEBUGGER_TAB_KEYS } from './helpers';
import { Colors } from 'constants/Colors';
import EntityBottomTabs from '../EntityBottomTabs';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { Layers } from 'constants/Layers';
import Resizer from './Resizable';
import {
  RESIZE_POSITIONS_BORDER,
  RESIZE_POSITIONS_CORNER,
  IDimensions,
} from './Resizable/constants';

const TABS_HEADER_HEIGHT = 36;

const StyledDragWrap = styled(animated.div)`
  position: fixed;
  user-select: auto;
  width: 618px;
  height: 250px;
  display: inline-block;
  top: 0px;
  left: 0px;
  cursor: auto;
  max-width: 900px;
  max-height: 776px;
  min-width: 250px;
  min-height: 250px;
  box-sizing: border-box;
  flex-shrink: 0;
  border: 1px solid ${Colors.ALTO};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
  pointer-events: auto;
  z-index: ${Layers.debugger};
`;

const Container = styled(StyledDragWrap)`
  /* position: sticky; */
  /* bottom: 0; */
  /* height: 25%; */
  /* min-height: ${TABS_HEADER_HEIGHT}px;
  background-color: ${(props) => props.theme.colors.debugger.background};
  border-top: 1px solid ${Colors.ALTO}; */
  /* overflow: auto; */

  &&& .react-tabs__tab-list-wrap {
    padding: 0;
  }

  ul.react-tabs__tab-list {
    /* padding: 0px ${(props) => props.theme.spaces[12]}px; */
  }
  .react-tabs__tab-panel {
    height: calc(100% - ${TABS_HEADER_HEIGHT}px);
  }

  .close-debugger {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 12px 15px;
  }
`;

const StyledCloseIconWrap = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s ease;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  &:hover {
    background-color: #eceded;
    svg {
      color: var(--orange);
    }
  }

  svg {
    color: var(--content);
    font-size: 8px;
  }
`;

type DebuggerTabsProps = {
  defaultIndex: number;
};

const DEBUGGER_TABS = [
  {
    key: DEBUGGER_TAB_KEYS.ERROR_TAB,
    title: createMessage(DEBUGGER_ERRORS),
    panelComponent: <Errors hasShortCut />,
  },
  {
    key: DEBUGGER_TAB_KEYS.LOGS_TAB,
    title: createMessage(DEBUGGER_LOGS),
    panelComponent: <DebuggerLogs hasShortCut />,
  },
  {
    key: DEBUGGER_TAB_KEYS.INSPECT_TAB,
    title: createMessage(INSPECT_ENTITY),
    panelComponent: <EntityDeps />,
  },
];

export const DebuggerContext: Context<{
  from?: string;
  dragRef?: RefObject<HTMLDivElement>;
  bind?: any;
}> = createContext({});

function DebuggerTabs(props: DebuggerTabsProps) {
  const resizeRef: RefObject<HTMLDivElement> = useRef(null);
  const dragRef: RefObject<HTMLDivElement> = useRef(null);

  const dispatch = useDispatch();

  const onClose = () => dispatch(showDebugger(false));

  const [styles, animate] = useSpring(() => ({
    x: 196,
    y: 91,
    width: 618,
    height: 250,
  }));

  const bind: any = useDrag(
    ({ dragging, offset: [x, y] }) => {
      if (dragging) {
        animate.set({ x, y });
      }
    },
    {
      target: dragRef,
      from: () => [styles.x.get(), styles.y.get()],
      bounds: () => {
        const containerWidth = document.body.clientWidth;
        const containerHeight = document.body.clientHeight;
        return {
          top: 0,
          left: 0,
          right: containerWidth - styles.width.get(),
          bottom: containerHeight - styles.height.get(),
        };
      },
      // rubberband: true,
    }
  );

  const handleResizing = (dimensions: IDimensions) => {
    animate.set({ ...dimensions });
  };

  const debuggerCtx = useMemo(() => ({ from: 'DEBUGGER', dragRef, bind }), [
    dragRef,
    bind,
  ]);
  const resizePositions = [
    ...RESIZE_POSITIONS_BORDER,
    ...RESIZE_POSITIONS_CORNER,
  ];

  return (
    <DebuggerContext.Provider value={debuggerCtx}>
      <Container onClick={stopEventPropagation} ref={resizeRef} style={styles}>
        <EntityBottomTabs
          defaultIndex={props.defaultIndex}
          tabs={DEBUGGER_TABS}
        />
        <StyledCloseIconWrap onClick={onClose}>
          <SvgIcon name="close-modal" />
        </StyledCloseIconWrap>

        <span>
          {resizePositions.map((el) => (
            <Resizer
              dimensions={styles}
              key={el}
              onResizing={handleResizing}
              position={el}
            />
          ))}
        </span>
      </Container>
    </DebuggerContext.Provider>
  );
}

export default DebuggerTabs;
