import { match } from 'constants/routes';

export const SAAS_BASE_PATH = `/saas`;
export const SAAS_EDITOR_PATH = `${SAAS_BASE_PATH}/:pluginPackageName`;
export const SAAS_EDITOR_DATASOURCE_ID_PATH = `${SAAS_EDITOR_PATH}/datasources/:datasourceId`;
export const SAAS_EDITOR_API_ID_PATH = `${SAAS_EDITOR_PATH}/api/:apiId`;

export const matchSaasPath = match(SAAS_EDITOR_API_ID_PATH);

export const APPSMITH_TOKEN_STORAGE_KEY = 'APPSMITH_AUTH_TOKEN';
