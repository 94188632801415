import { call, select } from 'redux-saga/effects';
import { getCurrentPageId, getPageList } from 'selectors/editorSelectors';
import { Page } from '@appsmith/constants/ReduxActionConstants';
import AnalyticsUtil from 'utils/AnalyticsUtil';
import { getAppMode } from 'selectors/applicationSelectors';
import { APP_MODE } from 'entities/App';
import { convertToQueryParams, installerURL } from 'RouteBuilder';
import history from 'utils/history';
import { setDataUrl } from 'sagas/PageSagas';
import AppsmithConsole from 'utils/AppsmithConsole';
import { NavigateActionDescription } from 'entities/DataTree/actionTriggers';
import { builderURL, viewerURL } from 'RouteBuilder';
import { isInstallMode } from '@appInstall/redux/selectors';
import { Toaster, Variant } from 'components/ads';
import {
  NavigationUrlType,
  NavigationTargetType,
} from 'components/editorComponents/ActionCreator/interfaces';
import { find, flattenDeep } from 'lodash';
import { flattenPages } from 'components/editorComponents/ActionCreator/utils';

const isValidUrlScheme = (url: string): boolean => {
  return (
    // Standard http call
    url.startsWith('http://') ||
    // Secure http call
    url.startsWith('https://') ||
    // Mail url to directly open email app prefilled
    url.startsWith('mailto:') ||
    // Tel url to directly open phone app prefilled
    url.startsWith('tel:')
  );
};

export default function* navigateActionSaga(
  action: NavigateActionDescription['payload']
) {
  const pageList: Page[] = yield select(getPageList);

  const flattenPageList: Page[] = flattenDeep(flattenPages(pageList));

  const {
    pageIdOrUrl,
    params,
    urlType = NavigationUrlType.SELECT_URL,
    target = NavigationTargetType.SAME_WINDOW,
  } = action;
  /**
   * NOTE: check current environment is install mode
   */
  const installMode = yield select(isInstallMode);

  // pageIdOrUrl:
  // This id was retrieved from DSL data after executed Action[navigateTo]
  // Use this id to get target page by [pageIdOrUrl = tempPageId] mapping
  // The ultimate route redirect depends on [pageId or Id]
  const selectedPage: Page = find(flattenPageList, {
    tempPageId: pageIdOrUrl,
  });

  if (urlType === NavigationUrlType.SELECT_URL && selectedPage) {
    // if (selectedPage.pageType === 'DIR') {
    //   Toaster.show({
    //     text: '跳转页面不存在, 请检查页面或URL输入',
    //     variant: Variant.danger,
    //   });
    //   return;
    // }

    const currentPageId: string = yield select(getCurrentPageId);
    // NOTE: there is no pageId property on sub page
    const mergePageId = selectedPage.id;

    AnalyticsUtil.logEvent('NAVIGATE', {
      pageName: pageIdOrUrl,
      pageParams: params,
    });
    /**
     * NOTE: we can't get 'INSTALL' variable in here.
     */
    let appMode: APP_MODE = yield select(getAppMode);
    if (installMode) {
      appMode = APP_MODE.INSTALL;
    }

    const path =
      appMode === APP_MODE.INSTALL
        ? installerURL({
            pageId: mergePageId,
            params,
          })
        : appMode === APP_MODE.EDIT
        ? builderURL({
            pageId: mergePageId,
            params,
          })
        : viewerURL({
            pageId: mergePageId,
            params,
          });

    if (target === NavigationTargetType.SAME_WINDOW) {
      history.push(path);
      if (currentPageId === mergePageId) {
        yield call(setDataUrl);
      }
    } else if (target === NavigationTargetType.NEW_WINDOW) {
      window.open(path, '_blank');
    }

    AppsmithConsole.info({
      text: `navigateTo('${selectedPage.pageName}') was triggered`,
      state: {
        params,
      },
    });
  } else {
    AnalyticsUtil.logEvent('NAVIGATE', {
      navUrl: pageIdOrUrl,
    });
    const url = pageIdOrUrl + convertToQueryParams(params);
    // Add a default protocol if it doesn't exist.
    if (!isValidUrlScheme(url)) {
      // url = 'https://' + url;

      Toaster.show({
        text: '跳转页面不存在, 请检查页面或URL输入',
        variant: Variant.danger,
      });
      return;
    }
    if (target === NavigationTargetType.SAME_WINDOW) {
      window.location.assign(url);
    } else if (target === NavigationTargetType.NEW_WINDOW) {
      window.open(url, '_blank');
    }
  }
}
