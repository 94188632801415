import axios from 'axios';
import Api from './Api';
import { ApiResponse, GenericApiResponse } from './ApiResponses';
import { each, entries } from 'lodash';

interface CreateResourcePostData {
  fileName?: string;
  parentId?: string;
  fileType?: number; // 1 是图片 2是视频
  file?: File;
  url?: string;
  onProgress?: (progressEvent: ProgressEvent) => void;
}

function setResultType<T = any>(p) {
  return (p as unknown) as GenericApiResponse<T>;
}

export interface Folder {
  id: string;
  fileName: string;
}

interface UpdateFileI {
  id: string;
  materialName?: string;
  parentId?: string;
}

interface GetFileListI {
  parentId?: string;
  current?: number;
  pageSize?: number;
  keyWord?: string;
  fileType?: number;
}

export interface FileItem {
  id: string;
  materialName: string;
  url: string;
  parentId: string;
  fileType: number;
}

class ResourceApi {
  static source = axios.CancelToken.source();

  static createFolder(folderName: string, id?: string) {
    const url = '/v1/folder';
    const postData: Record<string, string> = {
      fileName: folderName,
    };
    if (id) {
      postData.id = id;
    }
    return setResultType(Api.post(url, postData));
  }
  static deleteFile(ids: string[]) {
    const url = '/v1/material/delete';
    return setResultType(
      Api.post(url, {
        ids,
      })
    );
  }

  static deleteGroup(id: string) {
    const url = '/v1/folder/' + id;
    return setResultType(Api.delete(url));
  }

  static getFileList(data: GetFileListI) {
    const url = '/v1/material/listPage';
    return setResultType(Api.post(url, data));
  }

  static updateFileData(data: UpdateFileI) {
    const url = '/v1/material/update';

    return setResultType(Api.post(url, data));
  }

  static getFolderList() {
    const url = '/v1/folder/list';
    return setResultType<Folder[]>(Api.get(url));
  }

  static createResource(
    postData: CreateResourcePostData
  ): Promise<ApiResponse> {
    const url = __INSTALL_MODE__ ? '/file/upload' : '/v1/material/upload';
    const formData = new FormData();

    each(postData, (val, key) => {
      if (key === 'progress') {
        return;
      }
      return formData.append(key, val as Blob);
    });

    return Api.post(url, formData, null, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: postData.onProgress,
      cancelToken: ResourceApi.source.token,
    });
  }
}

export default ResourceApi;
