// action types
export const actionTypes = {
  INITIALIZE_APP_AFTER_LOGIN: 'INITIALIZE_APP_AFTER_LOGIN',
  SET_INITIALIZED_INFO: 'SET_INITIALIZED_INFO',
  FETCH_DEP_APP_JSON: 'FETCH_DEP_APP_JSON',
  FETCH_DEP_APP_MENUS: 'FETCH_DEP_APP_MENUS',
  SET_DEP_APP_JSON: 'SET_DEP_APP_JSON',
  SET_DEP_APP_MENUS: 'SET_DEP_APP_MENUS',
  CHANGE_DEP_APP_PAGE: 'CHANGE_DEP_APP_PAGE',
  SET_ACTIVE_USER_PROFILE: 'SET_ACTIVE_USER_PROFILE',
};

export enum PageType {
  dynamicPage = 1,
  staticPage = 2,
}

export type ActiveUserProfile =
  | {
      avatar?: string;
      userId: string;
      username: string;
      deptId?: string;
      deptName?: string;
      empId: string;
      empName?: string;
      hosCode: string;
      name?: string;
    }
  | Record<string, never>;

export type MenuItem = {
  id: string;
  pid: string;
  name: string;
  title?: string;
  icon?: string;
  sort?: number;
  slug?: string;
  path?: string;
  status?: number;
  type: number; // PageType ?
  realType: PageType;
  component?: string;
  isScreen?: boolean;
  isPrint?: boolean;
  isHidden?: boolean;
  permissions: string;
  outId?: string;
  tempPageId?: string;
  children?: Array<MenuItem>;
};

export type LoginSuccessPayload = {
  token?: { expires: string; token: string };
  isPreviewSubAppMode?: boolean;
};

export type InitializedInfo = {
  installMode?: boolean;
  previewSubAppMode?: boolean;
  isAppInitializing?: boolean;
  applicationId?: string;
  defaultPageId?: string;
};

// actions
export const initializeAppAfterLogin = (payload?: LoginSuccessPayload) => ({
  type: actionTypes.INITIALIZE_APP_AFTER_LOGIN,
  payload,
});

export const setInitializedInfo = (payload: InitializedInfo) => ({
  type: actionTypes.SET_INITIALIZED_INFO,
  payload,
});

export const fetchDepAppJson = () => ({
  type: actionTypes.FETCH_DEP_APP_JSON,
});

export const setDepAppJson = (appJson: any) => ({
  type: actionTypes.SET_DEP_APP_JSON,
  payload: appJson,
});

export const fetchDepAppMenus = () => ({
  type: actionTypes.FETCH_DEP_APP_MENUS,
});

export const setDepAppMenus = (menus: any) => ({
  type: actionTypes.SET_DEP_APP_MENUS,
  payload: menus,
});

export const changeDepPage = (pageId: string, dsl: unknown) => ({
  type: actionTypes.CHANGE_DEP_APP_PAGE,
  payload: {
    pageId,
    dsl,
  },
});

export const setActiveUserProfile = (profile: ActiveUserProfile) => ({
  type: actionTypes.SET_ACTIVE_USER_PROFILE,
  payload: {
    profile,
  },
});
