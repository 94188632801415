import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';

import Driver from './driver';
import { getIsFirstTimeUserOnboardingEnabled } from 'selectors/onboardingSelectors';

import './index.css';

export const Ctx = React.createContext({});

export const GuideType = {
  APPLICATION_GUIDE: 'APPLICATION_GUIDE',
  SITE_GUIDE: 'SITE_GUIDE',
};

const SITE_GUIDE_OPTION = [
  {
    popover: {
      title: '数据查询',
      description:
        '对数据库数据进行查询、修改、新增等操作，支持在线编写SQL、提供可视化查询模板，0代码即可操作',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '知道了',
    },
  },
  {
    popover: {
      title: '页面目录',
      description: '您可以为应用快速创建页面目录，并定义页面的层级',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '知道了',
    },
  },
  {
    popover: {
      title: '组件库',
      description:
        '支持基础组件与医疗行业组件，选中组件并“拖拽”即可将组件放置于“页面画布”中，支持自定义组件的各种属性',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '知道了',
    },
  },
  {
    popover: {
      title: '组件属性',
      description:
        '选中组件后，可以针对组件的美观样式、交互动作、数据来源等进行定义',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '知道了',
    },
  },
  {
    popover: {
      title: '数据源',
      description:
        '为您的应用连接到数据库，支持连接ORACLE、MYSQL等数据库，也支持导入内网数据库表结构',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '知道了',
    },
  },
  {
    popover: {
      title: '页面概要',
      description: '查看当前页面所有的组件排布及前端代码',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '知道了',
    },
  },
];

const APPLICATION_GUIDE_OPTION = [
  {
    popover: {
      title: '准备数据源',
      description:
        '我们准备好了一份示例数据库，您可以看到数据库中的表结构及数据，支持连接Oracle、Mysql、MongoDB等数据库',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '下一步',
    },
  },
  {
    popover: {
      title: '创建数据查询',
      description:
        '根据不同业务需要，您可以进行自定义编写基于数据库表的查询、插入、删除等SQL语句',
      position: 'bottom',
      className: 'guide-customer-popover',
      closeBtnText: '下一步',
    },
  },
  {
    popover: {
      title: '运行数据查询',
      description: '点击运行，将会从“示例数据库”中取到您想要的表单数据',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '下一步',
    },
  },
  {
    popover: {
      title: '将“数据”运用到表单',
      description:
        '这时数据可以快速运用到表格、图表、文本等页面组件上，您会发现生成一个表单页面将如此简单',
      position: 'left',
      className: 'guide-customer-popover',
      closeBtnText: '下一步',
    },
  },
];

export const AppGuide = {
  type: GuideType.APPLICATION_GUIDE,
  current: 0,
  max: 6,
  guide: APPLICATION_GUIDE_OPTION,
};

export const SiteGuide = {
  type: GuideType.SITE_GUIDE,
  current: 0,
  max: 5,
  guide: SITE_GUIDE_OPTION,
  guideName: 'INIT_GUIDE',
};

function useGlobalCtx() {
  const [guide, setGuide] = React.useState(null);

  // const startAppGuide = React.useCallback(() => setGuide(AppGuide), [setGuide]);
  const startAppGuide = () => {
    noop();
  };

  const next = React.useCallback(() => {
    setGuide((currentGuide) => {
      if (!currentGuide) return null;

      if (currentGuide.current >= currentGuide.max) return null;

      return {
        ...currentGuide,
        current: currentGuide.current + 1,
      };
    });
  }, [setGuide]);

  const driverRef = React.useRef(
    new Driver({
      allowClose: false,
      onHighlighted: () => {
        window.document.body.style.overflow = 'hidden';
      },
      onDeselected: () => {
        window.document.body.style.overflow = 'unset';
        next();
      },
    })
  );

  const hightLight = useCallback(
    (dom, delay = 0, option) => {
      return;
      if (!dom) return;
      setTimeout(() => {
        dom.scrollIntoView({
          behavior: 'auto', // 可以是smooth
        });
        driverRef.current?.highlight({
          ...option,
          element: dom,
        });
      }, delay);
    },
    [driverRef]
  );

  const reset = useCallback(() => {
    // driverRef.current?.reset();
  }, [driverRef]);

  return { hightLight, guide, startAppGuide, next, reset, setGuide };
}

export function useGuide(
  ref: React.MutableRefObject<HTMLElement>,
  step: number,
  type: string,
  delay?: number
) {
  const { guide, hightLight, next, reset, startAppGuide } = React.useContext(
    Ctx
  );
  const [isCurrent, setCurrent] = React.useState(false);
  const [isPrev, setPrev] = React.useState(false);
  React.useEffect(() => {
    const _isCurrent =
      !!ref?.current &&
      !!guide &&
      guide.type === type &&
      guide.current === step;

    const _isPrev =
      step !== -1 &&
      !!guide &&
      guide.type === type &&
      guide.current - 1 === step;

    setPrev(_isPrev);
    setCurrent(_isCurrent);
  }, [ref?.current, guide, step]);

  React.useEffect(() => {
    if (!ref?.current || !guide) return;

    if (guide.type !== type) return;

    if (guide.current === step) {
      hightLight(ref.current, delay || 0, guide.guide[step]);
    }
  }, [ref.current, guide, next, step]);

  return { isCurrent, isPrev, guide };
}

export function useInitGuide() {
  const { setGuide } = React.useContext(Ctx);
  const isFirstTimeUserOnboardingComplete = useSelector(
    getIsFirstTimeUserOnboardingEnabled
  );

  React.useEffect(() => {
    if (isFirstTimeUserOnboardingComplete) setGuide(SiteGuide);
  }, [isFirstTimeUserOnboardingComplete]);
}

export default useGlobalCtx;
