import { Query, QueryClient } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createAsyncStoragePersistor } from 'react-query/createAsyncStoragePersistor-experimental';
import localforage from 'localforage';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

export const ApiCacheStore: any & Storage = localforage.createInstance({
  name: 'ApiCache',
});

const localStoragePersistor = createAsyncStoragePersistor({
  storage: ApiCacheStore,
  throttleTime: 0,
});

export enum CacheWhiteList {
  'getAllApplication' = 'getAllApplication',
}

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
  maxAge: 1000 * 60 * 60 * 24, // 24 hours
  dehydrateOptions: {
    shouldDehydrateQuery: (query: Query & { queryKey: string }) => {
      return !!CacheWhiteList[query.queryKey];
    },
  },
});

export function clear() {
  queryClient.clear();
  queryClient.getQueryCache().clear();
  ApiCacheStore.clear();
  ApiCacheStore.removeItem('REACT_QUERY_OFFLINE_CACHE');
  localforage.dropInstance({ name: 'ApiCache' });
}
