import React, { ReactNode } from 'react';
import styled from 'styled-components';

const CustomWidgetStyleContainer = styled.div``;

type Props = { children: ReactNode; widgetName: string };

export default function CustomWidgetStyle(props: Props) {
  return (
    <CustomWidgetStyleContainer className={props.widgetName}>
      {props.children}
    </CustomWidgetStyleContainer>
  );
}
