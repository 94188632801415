import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Tooltip, Notification } from '@arco-design/web-react';

import { AppState } from 'reducers';
import {
  globalPrefix,
  selectFilesForExplorer,
} from 'selectors/entitiesSelector';
import Svg from 'components/utils/Svg';
import { getCurrentPageId } from 'selectors/editorSelectors';
import { saveEditActionName } from 'actions/pluginActionActions';
import { saveJSObjectName } from 'actions/jsActionActions';
import styled from 'styled-components';
import { controlQueryNameEditor } from 'actions/globalSearchActions';
import { Toaster, Variant } from 'components/ads';

const EditorWrap = styled.div<{ mode?: 'simple' | 'edit' }>`
  display: flex;
  align-items: center;

  &:hover svg[data-name='editor'] {
    display: ${(props) => (props.mode === 'edit' ? 'none' : 'block')};
  }
  svg[data-name='editor'] {
    display: none;
    margin-left: 8px;
    width: 12px !important;
    height: 12px !important;
    cursor: pointer;
  }
`;

function NameEditor() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const files = useSelector(selectFilesForExplorer);
  const { createTempleteQueryId: queryId, showQueryNameEditor } = useSelector(
    (state: AppState) => state.ui.globalSearch
  );

  const currentPageId = useSelector(getCurrentPageId);

  const editValue = React.useRef('');

  const current = files.find((itm) => itm?.entity?.id === queryId);

  if (!current) return null;

  const setEdit = () => {
    dispatch(controlQueryNameEditor(true));
    editValue.current = current.entity?.name;
  };

  const setSimple = () => {
    dispatch(controlQueryNameEditor(false));
    if (editValue.current !== current.entity?.name) {
      if (current.type === 'JS') {
        if (!editValue.current) {
          Toaster.show({
            text: 'js模块名称不能为空',
            variant: Variant.danger,
          });
          return;
        }
        dispatch(saveJSObjectName({ id: queryId, name: editValue.current }));
      } else {
        // DB、API
        dispatch(
          saveEditActionName({
            id: queryId,
            pageId: currentPageId,
            oldName: current.entity?.name,
            newName: editValue.current,
          })
        );
      }
    }
  };

  return (
    <EditorWrap
      className="flex items-center"
      key={queryId}
      mode={showQueryNameEditor ? 'edit' : 'simple'}
    >
      {showQueryNameEditor ? (
        <Tooltip
          content="全局查询无法删除“GLOBAL_”"
          popupVisible={visible}
          position="bottom"
        >
          <Input
            autoFocus
            defaultValue={current.entity?.name}
            onBlur={setSimple}
            onChange={(e, el) => {
              // console.log(e.length, editValue);
              if (
                editValue.current.startsWith(globalPrefix) &&
                e == globalPrefix
              ) {
                setVisible(true);
              } else if (
                editValue.current.startsWith(globalPrefix) &&
                e.length < globalPrefix.length
              ) {
                editValue.current = globalPrefix;
                el.target.blur();
              } else {
                setVisible(false);
                editValue.current = e;
              }

              // if (
              //   editValue.current.startsWith(globalPrefix) &&
              //   e.length < globalPrefix.length
              // ) {
              //   setVisible(true);
              //   editValue.current = globalPrefix;
              //   el.target.blur();
              // } else {
              //   editValue.current = e;
              // }
            }}
            onFocus={(e) => (editValue.current = e.target.value)}
          />
        </Tooltip>
      ) : (
        <span className="inline-block" onDoubleClick={setEdit}>
          {current.entity?.name}
        </span>
      )}

      <Svg name="editor" onClick={setEdit} />
    </EditorWrap>
  );
}

export default NameEditor;
