import React from 'react';
import styled from 'styled-components';
import { iconSizes, theme } from 'constants/DefaultTheme';
export const req = require.context('../../assets/svgIcon', true, /\.svg$/);
export const multipleReq = require.context(
  '../../assets/multiple-color-svg',
  true,
  /\.svg$/
);
//路径、是否渗入子目录 匹配规则
// const requireAll = (requireContext) =>
//   requireContext.keys().map(requireContext);
// requireAll(req);
// requireAll(multipleReq);

//路径、是否渗入子目录 匹配规则

export enum IconSize {
  XXS = 'extraExtraSmall',
  XS = 'extraSmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XL = 'extraLarge',
  XXL = 'extraExtraLarge',
  XXXL = 'extraExtraExtraLarge',
  XXXXL = 'extraExtraExtraExtraLarge',
}

// todo
export type IconName = string;

// compat with legacy icon component
export type IconProps = {
  size?: IconSize;
  name?: string;
  invisible?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  fillColor?: string;
  hoverFillColor?: string;
  keepColors?: boolean;
  loaderWithIconWrapper?: boolean;
  clickable?: boolean;
};

const ICON_SIZE_LOOKUP = {
  [IconSize.XXS]: theme.iconSizes.XXS,
  [IconSize.XS]: theme.iconSizes.XS,
  [IconSize.SMALL]: theme.iconSizes.SMALL,
  [IconSize.MEDIUM]: theme.iconSizes.MEDIUM,
  [IconSize.LARGE]: theme.iconSizes.LARGE,
  [IconSize.XL]: theme.iconSizes.XL,
  [IconSize.XXL]: theme.iconSizes.XXL,
  [IconSize.XXXL]: theme.iconSizes.XXXL,
  [IconSize.XXXXL]: theme.iconSizes.XXXXL,
  undefined: theme.iconSizes.SMALL,
};

export const IconWrapper = styled.svg<IconProps>`
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
`;

export const sizeHandler = (size?: IconSize | number | string): number => {
  if (typeof size === 'number' || !isNaN(parseInt(size)))
    return parseInt(size as string);

  return (
    ICON_SIZE_LOOKUP[size as keyof typeof ICON_SIZE_LOOKUP] ||
    theme.iconSizes.XL
  );
};

export interface Props extends React.SVGProps<SVGSVGElement> {
  name: string;
  color?: 'white' | 'primary';
  size?: number | string;
  disabled?: boolean;
  changeable?: boolean;
  clickable?: boolean;
  fillColor?: string;
  hoverFillColor?: string;
  isNeedPerfix?: boolean;
}

export const caches = {};

function Icon(
  {
    changeable,
    clickable = true,
    color,
    disabled,
    isNeedPerfix = true,
    name,
    size = iconSizes.XL,
    ...props
  }: Props,
  ref?: React.Ref<SVGSVGElement>
): JSX.Element {
  const idPrefix = isNeedPerfix ? 'ft-icon-' : '';
  const id = `#${idPrefix}${name}`;
  const path = id.replace('#ft-icon-', './') + '.svg';
  if (!caches[path]) {
    try {
      req(path);
    } catch (e) {
      console.log('找不到svg:', path);
      try {
        multipleReq(path);
      } catch (e) {
        console.log('找不到彩色svg:', path);
      }
    }
    caches[path] = true;
  }
  return (
    <IconWrapper ref={ref} {...props}>
      <use href={id} />
    </IconWrapper>
  );
}

export default React.forwardRef(Icon);
