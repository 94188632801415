export const REQUEST_TIMEOUT_MS = 60000;
export const DEFAULT_ACTION_TIMEOUT = 10000;
export const DEFAULT_EXECUTE_ACTION_TIMEOUT_MS = 15000;
export const DEFAULT_TEST_DATA_SOURCE_TIMEOUT_MS = 30000;

export enum API_STATUS_CODES {
  REQUEST_NOT_AUTHORISED = 401,
  RESOURCE_NOT_FOUND = 404,
  SERVER_ERROR = 502,
  SERVER_UNAVAILABLE = 503,
  APP_NOT_REGISTERED = 10001,
  DATA_SOURCE_UNAVAILABLE = 10009,
}

export enum SERVER_ERROR_CODES {
  INCORRECT_BINDING_LIST_OF_WIDGET = 4022,
  RESOURCE_NOT_FOUND = 4028,
  UNABLE_TO_FIND_PAGE = 4027,
}

export enum ERROR_CODES {
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  REQUEST_NOT_AUTHORISED = 'REQUEST_NOT_AUTHORIZED',
  REQUEST_TIMEOUT = 'REQUEST_TIMEOUT',
  FAILED_TO_CORRECT_BINDING = 'FAILED_TO_CORRECT_BINDING',
}

export const errorCode = {
  [API_STATUS_CODES.APP_NOT_REGISTERED]: '应用未注册，请先获取license',
};

export const OAuthURL = '/oauth2/authorization';
export const GoogleOAuthURL = `${OAuthURL}/google`;
export const GithubOAuthURL = `${OAuthURL}/github`;

export const LOGIN_SUBMIT_PATH = 'login';
export const LOGIN_CODE_SUBMIT_PATH = 'code-login';
export const SIGNUP_SUBMIT_PATH = 'users';
export const SUPER_USER_SUBMIT_PATH = `${SIGNUP_SUBMIT_PATH}/super`;

export const getExportAppAPIRoute = (applicationId: string) =>
  `/api/v1/applications/export/${applicationId}`;
