import React from 'react';
import { Button as AButton, Input, Form } from '@arco-design/web-react';

import type { ModalRenameAppProps } from './interface';
import { isFunction } from 'lodash';

const FormItem = Form.Item;

export function ModalRenameApp(props: ModalRenameAppProps) {
  const { initialAppName, onCancel, onConfirm } = props;
  const [form] = Form.useForm<{
    name: string;
  }>();

  const handleUpdateAppName = async () => {
    try {
      await form.validate();

      const appName = form.getFieldValue('name');

      if (isFunction(onConfirm)) {
        onConfirm(appName);
      }
    } catch (err) {
      //
    }
  };

  return (
    <>
      <div className="text-[#3D3D3D] font-bold text-center text-[16px]">
        重命名应用
      </div>
      <Form className="w-full mb-6" form={form} wrapperCol={{ span: 24 }}>
        <FormItem
          extra="名称不能为空，且不能超过50个字符"
          field="name"
          initialValue={initialAppName}
          rules={[
            {
              required: true,
              message: '应用名称不能空',
            },
            {
              max: 50,
              message: '应用名称不能超过50个字符',
            },
          ]}
        >
          <Input className="mb-1 mt-[24px]" placeholder="请输入应用名称" />
        </FormItem>
      </Form>
      <div className="flex justify-center">
        <AButton className="mr-[16px]" onClick={onCancel}>
          <span className="px-[16px]">取消</span>
        </AButton>
        <AButton
          className="fs-btn-primary"
          onClick={handleUpdateAppName}
          type="primary"
        >
          <span className="px-[16px]">确认</span>
        </AButton>
      </div>
    </>
  );
}
