import { SpringValue } from 'react-spring';
import { StyledComponent } from 'styled-components';

export const RESIZE_POSITIONS_BORDER = [
  'TOP',
  'BOTTOM',
  'LEFT',
  'RIGHT',
] as const;

export const RESIZE_POSITIONS_CORNER = [
  'TOPLEFT',
  'TOPRIGHT',
  'BOTTOMLEFT',
  'BOTTOMRIGHT',
] as const;

export type IPositionBorder = typeof RESIZE_POSITIONS_BORDER[number];
export type IPositionCorner = typeof RESIZE_POSITIONS_CORNER[number];

export type IDimensions = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
};

export type IStyledResizerComp = StyledComponent<
  'div',
  any,
  {
    $position: IPositionBorder | IPositionCorner;
  },
  never
>;

export type IResizerProps = {
  dimensions: {
    x: SpringValue<number>;
    y: SpringValue<number>;
    width: SpringValue<number>;
    height: SpringValue<number>;
  };
  position: IPositionBorder | IPositionCorner;
  onResizing: (IDimensions: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
  }) => void;
  onResizeStart?: () => void;
  onResizeEnd?: () => void;
};
