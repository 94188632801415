import React, {
  Context,
  createContext,
  PropsWithChildren,
  RefObject,
  useMemo,
  useRef,
} from 'react';
import { Button } from '@arco-design/web-react';
import Icon from 'components/icon';

import { Container, Header, Main } from './styles';
import { useSpring } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import {
  IDimensions,
  RESIZE_POSITIONS_BORDER,
  RESIZE_POSITIONS_CORNER,
} from 'components/editorComponents/Debugger/Resizable/constants';
import { stopEventPropagation } from 'utils/AppsmithUtils';
import Resizer from 'components/editorComponents/Debugger/Resizable';
import { isArray } from 'lodash';

interface Props {
  onFinishedEdit?: (e: Event) => void;
  title?: string;
  initPosition?: [number, number];
}

export const DraggablePaneContext: Context<{
  from?: string;
  dragRef?: RefObject<HTMLDivElement>;
  bind?: any;
}> = createContext({});

export function DraggablePane(props: PropsWithChildren<Props>) {
  const { initPosition, onFinishedEdit, title } = props;
  const resizeRef: RefObject<HTMLDivElement> = useRef(null);
  const dragRef: RefObject<HTMLDivElement> = useRef(null);

  const [styles, animate] = useSpring(() => {
    const curPosition = isArray(initPosition) ? initPosition : [144, 1500];

    return {
      x: curPosition[1],
      y: curPosition[0],
      width: 480,
      height: 260,
    };
  });
  const bind: any = useDrag(
    ({ dragging, offset: [x, y] }) => {
      if (dragging) {
        animate.set({
          x,
          y,
        });
      }
    },
    {
      target: dragRef,
      from: () => [styles.x.get(), styles.y.get()],
      bounds: () => {
        const { clientHeight, clientWidth } = document.body;

        return {
          top: 0,
          left: 0,
          right: clientWidth - styles.width.get(),
          bottom: clientHeight - styles.height.get(),
        };
      },
    }
  );

  const handleResizing = (dimensions: IDimensions) => {
    animate.set({ ...dimensions });
  };

  const draggablePaneCtx = useMemo(
    () => ({
      from: 'DRAGGABLE_PANE',
      dragRef,
      bind,
    }),
    [dragRef, bind]
  );

  const resizePositions = [
    ...RESIZE_POSITIONS_BORDER,
    ...RESIZE_POSITIONS_CORNER,
  ];

  return (
    <DraggablePaneContext.Provider value={draggablePaneCtx}>
      <Container onClick={stopEventPropagation} ref={resizeRef} style={styles}>
        <Header ref={dragRef}>
          <div className="flex items-center text-[#999]">
            <div className="pr-3">
              <Icon className="w-[12px]! h-[12px]!" name="draghandler" />
            </div>
            {title && <span className="text-[12px]">{title}</span>}
          </div>
          <div className="flex">
            <Button onClick={onFinishedEdit} size="mini" type="primary">
              <Icon className="inline-block" name="shrink" size={12} />
              完成
            </Button>
          </div>
        </Header>
        <Main>{props.children}</Main>

        <div>
          {resizePositions.map((el) => (
            <Resizer
              dimensions={styles}
              key={el}
              onResizing={handleResizing}
              position={el}
            />
          ))}
        </div>
      </Container>
    </DraggablePaneContext.Provider>
  );
}
