import {
  Page,
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from '@appsmith/constants/ReduxActionConstants';
import { call, put, select } from 'redux-saga/effects';

import { Toaster } from 'components/ads/Toast';
import { Variant } from 'components/ads/common';

import {
  LoginSuccessPayload,
  setActiveUserProfile,
  setDepAppJson,
  setDepAppMenus,
  setInitializedInfo,
} from '@appInstall/redux/actions';
import { flattenPages, getDepPageList } from '@appInstall/redux/selectors';
import { setCookie } from '@appInstall/utils';
import DepApi, { FetfchUserProileResponse } from 'api/DepApi';
import { APP_STORE_NAMESPACE } from 'constants/AppConstants';
import { find, flattenDeep, omit } from 'lodash';

export default function* initializeAppAfterLogin(
  action: ReduxAction<LoginSuccessPayload>
) {
  yield put(
    setInitializedInfo({
      installMode: true,
      previewSubAppMode: action.payload?.isPreviewSubAppMode,
      isAppInitializing: true,
    })
  );

  if (action.payload?.token) {
    const { expires, token } = action.payload.token;
    setCookie('G-Token', token, { expires });
  }

  try {
    const { code, data, msg }: FetfchUserProileResponse = yield call(
      DepApi.getUserInfo
    );
    if (code === 0 && data) {
      const curUserProfile = omit(data, 'data.permissions');
      // TODO whether to move hide userProfile in localStorage
      localStorage.setItem(
        APP_STORE_NAMESPACE,
        JSON.stringify({ curUserProfile })
      );
      yield put(setActiveUserProfile(curUserProfile));
    } else {
      Toaster.show({
        text: msg || '获取用户信息失败',
        variant: Variant.danger,
        icon: true,
      });
    }

    let appJson = null;
    if (action.payload.isPreviewSubAppMode) {
      appJson = yield call(DepApi.getPreviewModeAppJson);
    } else {
      appJson = yield call(DepApi.getAppInfoInstallMode, {});
    }

    yield put(setDepAppJson(appJson));

    const appMenus = yield call(DepApi.getAppMenus);
    yield put(setDepAppMenus(appMenus));

    const pageList = yield select(getDepPageList);
    const { exportedApplication = {} } = appJson || {};

    // when current application created from template, or duplicated, or imported from data.json
    // field `publishedDefaultPageId` from appJson is old
    // should ensure compatibility by `tempPageId`
    let publishedDefaultPageId = appJson?.publishedDefaultPageId;

    // 后端改动了app id字段
    if (!exportedApplication?.id) {
      exportedApplication.id = exportedApplication._applicationId;

      const allPages = flattenDeep(flattenPages(pageList));
      const defaultPage = find(
        allPages,
        (page: Page) => page.id === publishedDefaultPageId
      );

      if (!defaultPage) {
        const newDefaultPage = (find(
          allPages,
          (page: Page) => page.tempPageId === publishedDefaultPageId
        ) || {}) as Page;
        publishedDefaultPageId =
          newDefaultPage.id || newDefaultPage.pageId || newDefaultPage.outId;
      }

      exportedApplication.publishedDefaultPageId = publishedDefaultPageId;
    }

    yield put({
      type: ReduxActionTypes.FETCH_APPLICATION_SUCCESS,
      payload: { ...exportedApplication, pages: pageList },
    });

    yield put({
      type: ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS,
      payload: {
        pages: pageList,
        applicationId: exportedApplication?.id,
      },
    });

    yield put({
      type: ReduxActionTypes.SET_CURRENT_ORG_ID,
      payload: {
        orgId: exportedApplication.organizationId,
      },
    });

    yield put({
      type: ReduxActionTypes.SET_APP_VERSION_ON_WORKER,
      payload: exportedApplication?.evaluationVersion,
    });

    yield put(
      setInitializedInfo({
        applicationId: exportedApplication.id,
        isAppInitializing: false,
        defaultPageId: publishedDefaultPageId,
      })
    );
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_APPLICATION_ERROR,
      payload: {
        error,
      },
    });
    yield put({
      type: ReduxActionErrorTypes.FETCH_PAGE_LIST_ERROR,
      payload: {
        error,
      },
    });
  } finally {
    yield put(setInitializedInfo({ isAppInitializing: false }));
  }
}
