import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';
import { ControlIcons, ControlIconName } from 'icons/ControlIcons';
import { Typography, Tooltip } from '@arco-design/web-react';

const ItemWrapper = styled.div<{ selected: boolean }>`
  min-width: 32px;
  height: 32px;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0 !important;
  background-color: ${(props) =>
    props.selected ? Colors.GREY_4 : Colors.WHITE_SNOW};

  cursor: pointer;
  & {
    margin-right: 4px;
  }
  & > div {
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
  &&& svg {
    path {
      fill: ${Colors.GREY_7} !important;
    }
  }
`;

const FlexWrapper = styled.div`
  display: inline-flex;
`;

export interface ButtonTabOption {
  icon: string;
  value: string;
  width?: number;
  title?: string;
}

interface ButtonTabComponentProps {
  options: ButtonTabOption[];
  values: Array<string>;
  selectButton: (value: string) => void;
}

function ButtonTabComponent(props: ButtonTabComponentProps) {
  const valueSet = new Set(props.values);
  let firstValueIndex = 0;
  for (const [i, x] of props.options.entries()) {
    if (valueSet.has(x.value)) {
      firstValueIndex = i;
      break;
    }
  }

  const [focusedIndex, setFocusedIndex] = useState<number>(-1);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowRight':
      case 'Right':
        setFocusedIndex((prev) =>
          prev === props.options.length - 1 ? 0 : prev + 1
        );
        break;
      case 'ArrowLeft':
      case 'Left':
        setFocusedIndex((prev) =>
          prev === 0 ? props.options.length - 1 : prev - 1
        );
        break;
      case 'Enter':
      case ' ':
        props.selectButton(props.options[focusedIndex].value);
        e.preventDefault();
        break;
    }
  };

  return (
    <FlexWrapper
      onBlur={() => setFocusedIndex(-1)}
      onFocus={() => setFocusedIndex(firstValueIndex)}
      onKeyDown={handleKeyDown}
      role="tablist"
      tabIndex={0}
    >
      {props.options.map(
        (
          { icon, title, value, width = 24 }: ButtonTabOption,
          index: number
        ) => {
          const controlIconName: ControlIconName = icon;
          const ControlIcon = ControlIcons[controlIconName];
          const isSelected = valueSet.has(value);
          return (
            <Tooltip content={title} disabled={!title} key={index}>
              <Typography.Text style={{ marginLeft: 10 }}>
                <ItemWrapper
                  aria-selected={isSelected}
                  className={`t--button-tab-${value} ${
                    index === focusedIndex ? 'focused' : ''
                  }`}
                  key={index}
                  onClick={() => {
                    props.selectButton(value);
                    setFocusedIndex(index);
                  }}
                  role="tab"
                  selected={isSelected}
                >
                  <ControlIcon height={24} width={width} />
                </ItemWrapper>
              </Typography.Text>
            </Tooltip>
          );
        }
      )}
    </FlexWrapper>
  );
}

export default ButtonTabComponent;
