import styled, { css } from 'styled-components';
import { IPositionBorder, IPositionCorner } from './constants';

const StyledTopResizer = css`
  width: 100%;
  height: 10px;
  top: -5px;
  left: 0px;
  cursor: ns-resize;
`;

const StyledBottomResizer = css`
  width: 100%;
  height: 10px;
  bottom: -5px;
  left: 0px;
  cursor: ns-resize;
`;

const StyledLeftResizer = css`
  width: 10px;
  height: 100%;
  top: 0px;
  left: -5px;
  cursor: ew-resize;
`;

const StyledRightResizer = css`
  width: 10px;
  height: 100%;
  top: 0px;
  right: -5px;
  cursor: ew-resize;
`;

const StyledTopLeftResizer = css`
  width: 20px;
  height: 20px;
  left: -10px;
  top: -10px;
  cursor: nwse-resize;
`;

const StyledTopRightResizer = css`
  width: 20px;
  height: 20px;
  right: -10px;
  top: -10px;
  cursor: nesw-resize;
`;

const StyledBottomLeftResizer = css`
  width: 20px;
  height: 20px;
  left: -10px;
  bottom: -10px;
  cursor: nesw-resize;
`;

const StyledBottomRightResizer = css`
  width: 20px;
  height: 20px;
  right: -10px;
  bottom: -10px;
  cursor: nwse-resize;
`;

const styleBorders = {
  TOP: StyledTopResizer,
  BOTTOM: StyledBottomResizer,
  LEFT: StyledLeftResizer,
  RIGHT: StyledRightResizer,
};

const styledCorners = {
  TOPLEFT: StyledTopLeftResizer,
  TOPRIGHT: StyledTopRightResizer,
  BOTTOMLEFT: StyledBottomLeftResizer,
  BOTTOMRIGHT: StyledBottomRightResizer,
};

export const StyledBorderResizer = styled.div<{ $position: IPositionBorder }>`
  position: absolute;
  user-select: none;
  ${(props) => styleBorders[props.$position]}
`;

export const StyledCornerResizer = styled.div<{ $position: IPositionCorner }>`
  position: absolute;
  user-select: none;
  cursor: nwse-resize;
  ${(props) => styledCorners[props.$position]}
`;
