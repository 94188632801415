export interface EnterpriseAuthForm {
  orgType?: string; // 机构类型，枚举值：enterprise - 企业，hospital - 医院（默认）
  orgName?: string; // 机构名称
  orgScale?: string; // 机构规模
  address?: string; // 机构地址
  contactName?: string; // 联系人姓名
  contactNo?: string; // 联系人身份证
  phone?: string; // 联系人电话(座机/手机)
  license?: string; // 营业执照
  status?: string;
}

export const enum EnterpriseAuthStatus {
  init = 'init', // 待认证
  authenticated = 'authenticated', // 已认证
  authenticating = 'authenticating', // 认证中
  failed = 'failed', // 认证失败
}

export const enum OrgTypeEnum {
  person = '1', // 个人
  organization = '2', // 组织
}
