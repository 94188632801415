import React from 'react';
import Icon from 'components/icon';
import { Handle, Position } from 'react-flow-renderer';
import { useDrop } from 'react-dnd';
import { replace, set } from 'lodash';
import styled from 'styled-components';

const Wrarpper = styled.div`
  &.border-dot {
    background: repeating-linear-gradient(
      135deg,
      transparent,
      transparent 4px,
      #ccc 4px,
      #ccc 8px
    );
    /* overflow: hidden; // 新建一个BFC，解决margin在垂直方向上折叠的问题 */
    animation: move 0.8s infinite linear;
    &::after {
      content: '';
      display: block;
      margin: auto;
      top: 11px;
      bottom: 11px;
      left: 11px;
      right: 11px;
      background-color: var(--link);
      opacity: 0.5;
      position: absolute;
      border-radius: 8px;
    }
    &::before {
      content: '';
      display: block;
      margin: auto;
      top: 1.2px;
      bottom: 1.2px;
      left: 1.2px;
      right: 1.2px;
      background-color: var(--white);
      position: absolute;
      border-radius: 8px;
      z-index: 1;
      /* animation: space 1s infinite linear; */
      transform-origin: center center;
    }
  }
  @keyframes move {
    from {
      background-position: -1px;
    }
    to {
      background-position: -12px;
    }
  }
  @keyframes space {
    from {
      transform: scale(0.92);
    }
    to {
      transform: scale(1);
    }
  }
`;

export default function PlaceholderNode(props) {
  const { data, id, selected } = props;
  const [collectedProps, drop] = useDrop({
    accept: 'query',
    drop: (
      item: {
        props: Record<string, any>;
        type: 'query';
      },
      monitor
    ) => {
      set(item, 'id', id);
      set(item, 'type', 'replace');
    },
  });
  return (
    <div className={'table-node text-center' + ` ${data.type}`} ref={drop}>
      <Wrarpper
        className={`after:animate-ping relative cursor-move w-[56px] h-[56px] bg-white rounded flex justify-center items-center m-auto border-dot ${
          selected ? 'border-Light' : ''
        }`}
      >
        <Handle id="A" position={Position.Right} type="target" />
        <span className="text-link text-[24px] relative z-[99]">
          <div className="animate-ping text-[12px] -translate-y-[1px] whitespace-nowrap">
            将表拖到此处
          </div>
        </span>
      </Wrarpper>
    </div>
  );
}
