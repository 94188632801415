import { noop } from 'lodash';
import React from 'react';
import styled from 'styled-components';
// import Button, { Category, Size } from 'components/ads/Button';
import Toggle from 'components/ads/Toggle';
import {
  AllowToggle,
  AllowToggleLabel,
  AllowToggleWrapper,
  ButtonWrapper,
  FormBodyWrapper,
  FormHeaderIndex,
  FormHeaderLabel,
  FormHeaderWrapper,
} from './common';
import { memo } from 'react';
import {
  createMessage,
  WELCOME_FORM_NEWLETTER_HEADER,
  WELCOME_FORM_NEWLETTER_LABEL,
  WELCOME_FORM_SUBMIT_LABEL,
} from '@appsmith/constants/messages';
import { Button } from '@arco-design/web-react';

export const StyledButton = styled(Button)`
  width: 201px;
  height: 38px;
`;

const NewsletterContainer = styled.div`
  width: 100%;
  position: relative;
  padding-left: ${(props) => props.theme.spaces[17] * 2}px;
  margin-top: ${(props) => props.theme.spaces[12] * 2}px;
`;

const StyledFormHeader = styled.div`
  width: 100%;
  h4 {
    width: 313px;
    height: 28px;
    font-size: 12px;
    color: #999999;
  }
`;

const StyledButtoncontent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 64px;
`;

const StyledButtonBanner = styled(Button).attrs({
  htmlType: 'submit',
})`
  width: 128px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
`;

const StyleSwitchbutton = styled(Toggle)`
  width: 46px;
  &&&& input:checked + .slider {
    background: linear-gradient(
      106deg,
      #ff71ad 11%,
      #ff71ad 11%,
      #ffa852 95%,
      #ffa852 95%
    );
  }
`;

export default memo(function NewsletterForm() {
  return (
    <NewsletterContainer>
      <StyledFormHeader className=" font-hm">
        <h4 className="mb-[24px]">保持联系</h4>
        <div className="flex justify-between items-center">
          <h3>获取我们的最新信息(不会给你发垃圾邮件)</h3>
          <AllowToggleWrapper>
            <AllowToggle>
              <StyleSwitchbutton
                className="t--welcome-form-newsletter"
                name="signupForNewsletter"
                onToggle={() => noop}
                value
              />
            </AllowToggle>
          </AllowToggleWrapper>
        </div>
      </StyledFormHeader>
      <StyledButtoncontent>
        <StyledButtonBanner className="fs-btn-primary" type="primary">
          完成
        </StyledButtonBanner>
      </StyledButtoncontent>

      {/*  */}

      {/* <FormHeaderWrapper className="relative flex-col items-start">
        <FormHeaderIndex className="absolute -left-6">3.</FormHeaderIndex>
        <FormHeaderLabel>
          {createMessage(WELCOME_FORM_NEWLETTER_HEADER)}
        </FormHeaderLabel>
      </FormHeaderWrapper>
      <FormBodyWrapper>
        <AllowToggleWrapper>
          <AllowToggle>
            <Toggle
              className="t--welcome-form-newsletter"
              name="signupForNewsletter"
              onToggle={() => noop}
              value
            />
          </AllowToggle>
          <AllowToggleLabel>
            {createMessage(WELCOME_FORM_NEWLETTER_LABEL)}
          </AllowToggleLabel>
        </AllowToggleWrapper>
        <ButtonWrapper>
          <StyledButton
            category={Category.primary}
            className="t--welcome-form-create-button"
            size={Size.medium}
            tag="button"
            text={createMessage(WELCOME_FORM_SUBMIT_LABEL)}
            type="submit"
          />
        </ButtonWrapper>
      </FormBodyWrapper> */}

      {/*  */}
    </NewsletterContainer>
  );
});
