import { Intent as BlueprintIntent } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';

export interface DropdownOption {
  label: string;
  value: string;
  icon?: IconName;
  subText?: string;
  id?: string;
  onSelect?: (option: DropdownOption) => void;
  children?: DropdownOption[];
  intent?: BlueprintIntent;
}

export const InputTypes: { [key: string]: string } = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  INTEGER: 'INTEGER',
  PHONE_NUMBER: 'PHONE_NUMBER',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  CURRENCY: 'CURRENCY',
  SEARCH: 'SEARCH',
};

export type InputType = typeof InputTypes[keyof typeof InputTypes];

export enum ButtonBorderRadiusTypes {
  SHARP = 'SHARP',
  ROUNDED = 'ROUNDED',
  CIRCLE = 'CIRCLE',
}
export type ButtonBorderRadius = keyof typeof ButtonBorderRadiusTypes;

export enum ButtonBoxShadowTypes {
  NONE = 'NONE',
  VARIANT1 = 'VARIANT1',
  VARIANT2 = 'VARIANT2',
  VARIANT3 = 'VARIANT3',
  VARIANT4 = 'VARIANT4',
  VARIANT5 = 'VARIANT5',
}

export enum ButtonBoxShadowStyle {
  NONE = 'none',
  VARIANT1 = '0px 0px 5px 2px rgba(0, 0, 0, 0.1)',
  VARIANT2 = '5px 5px 4px rgba(0, 0, 0, 0.1)',
  VARIANT3 = '0px 3px 4px rgba(0, 0, 0, 0.1)',
  VARIANT4 = '4px 4px 4px 0px rgba(0, 0, 0, 0.1)',
  VARIANT5 = '-3px -3px 8px rgba(0, 0, 0, 0.1)',
}

export type ButtonBoxShadow = keyof typeof ButtonBoxShadowTypes;

export type ButtonStyle =
  | 'PRIMARY_BUTTON'
  | 'SECONDARY_BUTTON'
  | 'SUCCESS_BUTTON'
  | 'DANGER_BUTTON';

export enum ButtonStyleTypes {
  PRIMARY = 'PRIMARY',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  INFO = 'INFO',
  SECONDARY = 'SECONDARY',
  CUSTOM = 'CUSTOM',
}
export type ButtonStyleType = keyof typeof ButtonStyleTypes;

export enum ButtonVariantTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

export enum ButtonStatusType {
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT',
}

export enum ArcoButtonType {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  COLOUR = 'colour',
  DASHED = 'dashed',
  TEXT = 'text',
  OUTLINE = 'outline',
}

export type ArcoButtonTypeValues = Exclude<
  ArcoButtonType,
  ArcoButtonType.COLOUR
>;

export enum ArcoButtonStatus {
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
  DEFAULT = 'default',
}

export type ButtonVariant = keyof typeof ButtonVariantTypes;

export type ButtonStatus = keyof typeof ButtonStatusType;

export enum RecaptchaTypes {
  V3 = 'V3',
  V2 = 'V2',
}
export type RecaptchaType = keyof typeof RecaptchaTypes;

export enum CheckboxGroupAlignmentTypes {
  START = 'flex-start',
  END = 'flex-end',
  CENTER = 'center',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  NONE = 'unset',
}
export type CheckboxGroupAlignment = keyof typeof CheckboxGroupAlignmentTypes;
export enum ButtonPlacementTypes {
  START = 'START',
  BETWEEN = 'BETWEEN',
  CENTER = 'CENTER',
}

export enum ButtonPositionTypes {
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
  LEFT = 'LEFT',
}

export type ButtonPlacement = keyof typeof ButtonPlacementTypes;
