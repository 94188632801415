import React from 'react';
import { Form, Input, Grid, TreeSelect } from '@arco-design/web-react';

import { CommonFormProps } from './ConfigureDepartmentOrUserForm';

const FormItem = Form.Item;
const Row = Grid.Row;
const Col = Grid.Col;

const formItemLayout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

export function CreateDepartmentForm(props: CommonFormProps) {
  const { form, departmentList = [], initialValue } = props;
  const { deptId, deptName, parentId } = initialValue || {};

  return (
    <Form form={form} {...formItemLayout}>
      <Row gutter={[80, 0]}>
        <Col span={12}>
          <FormItem
            field="deptName"
            initialValue={deptName}
            label="科室名称"
            rules={[{ required: true, message: '科室名称不能为空' }]}
          >
            <Input placeholder="请输入科室名称" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            field="deptId"
            initialValue={deptId}
            label="科室ID"
            rules={[
              { required: true, message: '科室ID不能为空' },
              { maxLength: 10, message: '科室ID不超过10位' },
            ]}
          >
            <Input placeholder="请输入科室ID" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem field="parentId" initialValue={parentId} label="上级科室">
            <TreeSelect
              allowClear
              placeholder="请选择上级科室"
              style={{ width: 250 }}
              treeData={departmentList}
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}
