import React, { useRef, useEffect, useState, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import cs from 'classnames';
import {
  ApplicationPayload,
  PageListPayload,
  Page,
} from '@appsmith/constants/ReduxActionConstants';
import { PLACEHOLDER_APP_SLUG, PLACEHOLDER_PAGE_SLUG } from 'constants/routes';
import { isEllipsisActive } from 'utils/helpers';
import TooltipComponent from 'components/ads/Tooltip';
import { getTypographyByKey } from 'constants/DefaultTheme';
import { Position } from '@blueprintjs/core';
import { flatten } from 'components/sortTreeList/utilities';
import { getAppMode } from 'selectors/applicationSelectors';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

import { trimQueryString } from 'utils/helpers';
import { getPageURL, getStandalonePageLink } from 'utils/AppsmithUtils';
import { viewerURL } from 'RouteBuilder';
import { isInstallMode, getDepAppJson } from '@appInstall/redux/selectors';

const PageTab = styled(NavLink)`
  display: flex;
  max-width: 170px;
  align-self: flex-end;
  cursor: pointer;
  text-decoration: none;
  margin-right: 6px;
  background: #fff;
  padding: 0 ${(props) => props.theme.spaces[7]}px;
  &:hover {
    text-decoration: none;
  }
`;

const LinkWrap = styled.a`
  &.active {
    > div {
      background: var(--color-primary-light-1);
    }
  }
`;

/* ${(props) => */
/* props.theme.colors.header.activeTabBorderBottom}; */
const StyledBottomBorder = styled.div`
  position: relative;
  transition: all 0.3s ease-in-out;
  height: 2px;
  width: 100%;
  left: -100%;
  top: 9px;
  background-color: rgb(var(--primary-6));
  ${PageTab}:hover & {
    position: relative;
    width: 100%;
    left: 0;
  }
`;

const StyleTabText = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => getTypographyByKey(props, 'h6')}
  color: ${(props) => props.theme.colors.header.tabText};
  height: ${(props) => `calc(${props.theme.smallHeaderHeight})`};
  & span {
    height: 100%;
    max-width: 138px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  ${PageTab}.is-active & {
    /* color: ${(props) => props.theme.colors.header.activeTabText}; */
    color: rgb(var(--primary-6));
    ${StyledBottomBorder} {
      left: 0;
    }
  }
`;

function PageTabName({ name }: { name: string }) {
  const tabNameRef = useRef<HTMLSpanElement>(null);
  const [ellipsisActive, setEllipsisActive] = useState(false);
  const tabNameText = (
    <StyleTabText>
      <div className="relative flex ">
        <div className="relative flex items-center justify-center flex-grow">
          <span ref={tabNameRef}>{name}</span>
        </div>
        {ellipsisActive && '...'}
      </div>
      <StyledBottomBorder />
    </StyleTabText>
  );

  useEffect(() => {
    setTimeout(() => {
      if (isEllipsisActive(tabNameRef?.current)) {
        setEllipsisActive(true);
      }
    }, 50);
  }, []);

  return (
    <TooltipComponent
      boundary="viewport"
      content={name}
      disabled={!ellipsisActive}
      maxWidth="400px"
      position={Position.BOTTOM}
    >
      {tabNameText}
    </TooltipComponent>
  );
}

function PageTabContainer({
  children,
  isTabActive,
  setShowScrollArrows,
  tabsScrollable,
}: {
  children: React.ReactNode;
  isTabActive: boolean;
  tabsScrollable: boolean;
  setShowScrollArrows: () => void;
}) {
  const tabContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTabActive) {
      tabContainerRef.current?.scrollIntoView(false);
      setShowScrollArrows();
    }
  }, [isTabActive, tabsScrollable]);

  return <div ref={tabContainerRef}>{children}</div>;
}

type Props = {
  currentApplicationDetails?: ApplicationPayload;
  appPages: PageListPayload;
  measuredTabsRef: (ref: HTMLElement | null) => void;
  tabsScrollable: boolean;
  setShowScrollArrows: () => void;
};

export function PageTabs(props: Props) {
  const { appPages, currentApplicationDetails } = props;
  const location = useLocation();
  const { pathname } = location;
  const appMode = useSelector(getAppMode);
  const installMode = useSelector(isInstallMode);
  const installAppJson = useSelector(getDepAppJson);
  const [query, setQuery] = useState('');
  useEffect(() => {
    setQuery(window.location.search);
  }, [location]);

  const pageList = useMemo(() => {
    return flatten(appPages).filter((i) => i.pageType !== 'DIR');
  }, [appPages]);

  return (
    <div
      className="flex w-full overflow-hidden scrollbar-none"
      ref={props.measuredTabsRef}
    >
      {pageList.map((page, idx) => {
        const link = installMode
          ? getStandalonePageLink(page as Page, installAppJson)
          : getPageURL(page as Page, appMode, currentApplicationDetails);

        return (
          <PageTabContainer
            isTabActive={
              pathname ===
              (installMode
                ? getStandalonePageLink(page as Page, installAppJson)
                : trimQueryString(
                    viewerURL({
                      applicationSlug:
                        currentApplicationDetails?.slug || PLACEHOLDER_APP_SLUG,
                      pageSlug: page.slug || PLACEHOLDER_PAGE_SLUG,
                      pageId: page.id,
                    })
                  ))
            }
            key={nanoid()}
            setShowScrollArrows={props.setShowScrollArrows}
            tabsScrollable={props.tabsScrollable}
          >
            {link.startsWith('http') ? (
              <LinkWrap
                className={cs(
                  't--page-switch-tab',
                  window.location.href.indexOf(link) === 0 ? 'active' : ''
                )}
                href={link}
                rel="noreferrer noopener"
              >
                <PageTabName name={page.name || page.pageName} />
              </LinkWrap>
            ) : (
              <div
                onClickCapture={(e) => {
                  if (!page.isScreen) return;
                  window.open(link, '_blank');
                  e.preventDefault();
                }}
              >
                <PageTab
                  activeClassName="is-active"
                  className="t--page-switch-tab"
                  to={{
                    pathname: link,
                    search: query,
                  }}
                >
                  <PageTabName name={page.name || page.pageName} />
                </PageTab>
              </div>
            )}
          </PageTabContainer>
        );
      })}
    </div>
  );
}

export default PageTabs;
