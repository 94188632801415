import { Button, Divider, Form, Message, Radio } from '@arco-design/web-react';
import { cloneDeep, filter, find, get, map, nth, uniqueId } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Select } from '@arco-design/web-react';
import {
  ProcessTypes,
  ProcessTypes2,
  ProcessTypes3,
  ProcessTypes4,
  ProcessTypesKeys,
} from '../constants';
import { GetTypeForm } from '.';
import { IconMinus } from '@arco-design/web-react/icon';
const Option = Select.Option;

class Group {
  config;
  fields: GroupList[];
  id;
  type: string;
  constructor(initLength) {
    this.fields = map(new Array(initLength), (v) => new GroupList());
    this.id = uniqueId();
  }
}

class GroupList {
  type;
  alias;
  field;
  tableId;
  tableName;
  id;
  constructor() {
    this.id = uniqueId();
  }
}

export default function DataProcessingContent({ data, fields, meta, modal }) {
  const { fields: tableFields, sql, templateSQL } = get(meta, 'tableData');
  const { id, name: mianTable, type } = data.previousData.nodeProperty;
  const [fieldsList, setFieldsList] = useState(cloneDeep(tableFields));

  const handleChangeComponent = (val, index) => {
    groupData[index].type = val;
    setGroupData([...groupData]);
  };

  const checkDisabled = (key, groupIndex) => {
    const [field1Value, field2Value] = groupData[groupIndex].fields;
    if (
      !field1Value.field &&
      !field2Value.field &&
      ProcessTypesKeys.NewColumn === key
    ) {
      return false;
    }
    // 如果选择了两列, 并且中间组件选择了列与列，则它是可以操作的
    if (
      field1Value.field &&
      field2Value.field &&
      [
        ProcessTypesKeys.Concat,
        ProcessTypesKeys.ColumnOp,
        ProcessTypesKeys.DateOp,
        ProcessTypesKeys.SameRingRatio,
      ].includes(key)
    ) {
      return false;
    }

    // 如果左边只选择了一列，并且当前 key 不是列与列，则它是可以操作的
    if (
      (field1Value.field && !field2Value.field) ||
      (!field2Value.field && field2Value.field)
    ) {
      return false;
    }

    return true;
  };

  const filterID = useRef(meta.FormInfo?.filterID || uniqueId('util_')).current;
  const [groupData, setGroupData] = useState(
    meta.FormInfo?.groupData || [new Group(2)]
  );

  const { name } = fields;

  useEffect(() => {
    meta.FormInfo = {
      groupData,
      filterID,
    };
  }, [groupData, filterID]);

  const formRef = useRef();

  const queryParams = {
    inputs: [
      {
        id: id,
        sql: sql,
        tableName: name,
        templateSQL: templateSQL,
        type: type,
      },
    ],
    current: {
      id: filterID,
      type: 'alter',
      alterInnerPreview: false,
      select: fieldsList.map((v) => ({
        alias: v.alias,
        field: filterID + '.' + v.field,
        type: v.type,
      })),
      settings: JSON.stringify(
        groupData.map((v) => ({
          ...v,
          fields: filter(v.fields, (v) => v.field),
        }))
      ),
    },
  };

  return (
    <div className="filter-content">
      <div className="font-hm-16-bold pb-[20px]">数据加工设置</div>
      <div className="flex">
        <div className="group w-[100%]">
          <div className="font-hm-16-bold pb-[20px]">条件</div>

          <div className="tool">
            <div className="mt-[10px]">
              <Button
                onClick={() => setGroupData([...groupData, new Group(2)])}
              >
                添加组
              </Button>
            </div>
          </div>
          {groupData.map((groupDataItem, i) => {
            const tempGroup = groupDataItem.fields[0];
            const defaultValue =
              find(fieldsList, {
                field: nth(tempGroup.field?.split('.') || [], -1),
              }) || get(fieldsList, '[0]');
            tempGroup.field = filterID + '.' + defaultValue.field;
            tempGroup.alias = defaultValue.alias;
            tempGroup.tableId = id;
            tempGroup.tableName = mianTable;
            tempGroup.type = defaultValue.type;
            return (
              <div
                className="flex item my-[10px] flex-col border mr-[10px] rounded"
                key={groupDataItem.id}
              >
                <div className="group-Item flex m-[10px]">
                  <div className="condition flex w-[80%] items-center">
                    <div className="config-group flex w-[20%] ml-[20px] mt-[15px]">
                      <div className="item">
                        <IconMinus
                          onClick={() => {
                            if (groupData.length <= 1) {
                              return Message.warning('至少保留一个条件');
                            }
                            setGroupData(
                              filter(
                                groupData,
                                (item) => groupDataItem.id !== item.id
                              ) as Group[]
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mb-[5px]">字段1</div>
                      <Select
                        defaultValue={defaultValue}
                        onChange={(value) => {
                          const tempGroup = groupDataItem.fields[0];
                          tempGroup.field = filterID + '.' + value.field;
                          tempGroup.alias = value.alias;
                          tempGroup.tableId = id;
                          tempGroup.tableName = mianTable;
                          tempGroup.type = value.type;
                          setGroupData([...groupData]);
                        }}
                        placeholder="请选择"
                      >
                        {fieldsList.map((option) => (
                          <Option key={option.field} value={option}>
                            {option.alias}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {[fieldsList].map((v, i) => {
                      const tempGroup = groupDataItem.fields[i + 1];
                      const defaultValue = find(fieldsList, {
                        field: nth(tempGroup.field?.split('.') || [], -1),
                      });
                      if (defaultValue) {
                        tempGroup.field = filterID + '.' + defaultValue.field;
                        tempGroup.alias = defaultValue.alias;
                        tempGroup.tableId = id;
                        tempGroup.tableName = mianTable;
                        tempGroup.type = defaultValue.type;
                      }
                      return (
                        <div key={v.dataId}>
                          <div className="mb-[5px]">字段2</div>
                          <Select
                            defaultValue={defaultValue}
                            onChange={(value) => {
                              const tempGroup = groupDataItem.fields[i + 1];
                              if (value) {
                                tempGroup.field = filterID + '.' + value.field;
                                tempGroup.alias = value.alias;
                                tempGroup.tableId = v.dataId;
                                tempGroup.tableName = v.name;
                                tempGroup.type = value.type;
                              } else {
                                tempGroup.field = undefined;
                                tempGroup.alias = undefined;
                                tempGroup.type = undefined;
                              }

                              setGroupData([...groupData]);
                            }}
                            placeholder="请选择"
                          >
                            <Option value={undefined}>不选择</Option>
                            {fieldsList.map((option) => (
                              <Option key={option.field} value={option}>
                                {option.alias}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <div>选择组件:</div>
                    <Radio.Group
                      onChange={(val) => handleChangeComponent(val, i)}
                      value={groupData[i].type}
                    >
                      {Object.keys(ProcessTypes).map((key) => (
                        <Radio
                          disabled={checkDisabled(key, i)}
                          key={key}
                          value={key}
                        >
                          {ProcessTypes[key]}
                        </Radio>
                      ))}
                      <Divider />
                      {Object.keys(ProcessTypes2).map((key) => (
                        <Radio
                          disabled={checkDisabled(key, i)}
                          key={key}
                          value={key}
                        >
                          {ProcessTypes2[key]}
                        </Radio>
                      ))}
                      <Divider />
                      {Object.keys(ProcessTypes3).map((key) => (
                        <Radio
                          disabled={checkDisabled(key, i)}
                          key={key}
                          value={key}
                        >
                          {ProcessTypes3[key]}
                        </Radio>
                      ))}
                      <Divider />
                      {Object.keys(ProcessTypes4).map((key) => (
                        <Radio
                          disabled={checkDisabled(key, i)}
                          key={key}
                          value={key}
                        >
                          {ProcessTypes4[key]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                  <div className="w-[500px] ml-[20px]">
                    <Form
                      initialValues={groupData[i].config || {}}
                      layout="vertical"
                      onValuesChange={(value, values) => {
                        groupData[i].config = {
                          fieldType: groupDataItem.fields[0].type,
                          ...values,
                        };
                        setGroupData([...groupData]);
                      }}
                      ref={formRef}
                    >
                      <GetTypeForm
                        selectData={groupData[i]}
                        type={groupData[i].type}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="mr-[30px]"
          onClick={() => {
            formRef.current &&
              formRef.current.validate((err) => {
                if (!err) {
                  modal.current.close();
                  modal.ok(queryParams);
                }
              });
          }}
          type="primary"
        >
          提交
        </Button>
        <Button onClick={() => modal.current.close()} type="secondary">
          取消
        </Button>
      </div>
    </div>
  );
}
