import { Button } from '@arco-design/web-react';
import React from 'react';
import { useReactFlow } from 'react-flow-renderer';
import SvgIcon from 'components/icon';
import { QUERY_EDITOR_FORM_NAME } from 'constants/forms';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxActionTypes } from 'ce/constants/ReduxActionConstants';
import { AppState } from 'reducers/index';
import { change } from 'redux-form';

export default function Tool(props) {
  const RF = useReactFlow();
  const dispatch = useDispatch();
  const createTempleteQueryId = useSelector(
    (state: AppState) => state.ui.globalSearch.createTempleteQueryId
  );

  return (
    <Button
      onClick={() => {
        RF.setEdges([]);
        RF.setNodes([]);
        dispatch({
          type: ReduxActionTypes.PREVIEW_QUERY_TABLE_DATA,
          payload: {
            actionIdAction: createTempleteQueryId,
            tableSqlActicon: change(
              QUERY_EDITOR_FORM_NAME,
              'actionConfiguration.body',
              ''
            ),
          },
        });
      }}
    >
      <SvgIcon className="text-[14px]" name="delete" />
      清除
    </Button>
  );
}
