import React, { forwardRef, Ref } from 'react';

import IconV2 from 'components/icon';
import SearchIcon from 'remixicon-react/SearchLineIcon';
import { ReactComponent as LogoIcon } from 'assets/icons/ads/logo.svg'; // keep

import styled from 'styled-components';
import { Classes, CommonComponentProps } from './common';
import { noop } from 'lodash';
import { theme } from 'constants/DefaultTheme';
import Spinner from './Spinner';
import { ControlIcons } from 'icons/ControlIcons';

// remix icons
import AddMoreIcon from 'remixicon-react/AddCircleLineIcon';
import AddMoreFillIcon from 'remixicon-react/AddCircleFillIcon';
// import AlertFillIcon from "remixicon-react/AlertFillIcon";
import ArrowLeftRightIcon from 'remixicon-react/ArrowLeftRightLineIcon';
import ArrowDownLineIcon from 'remixicon-react/ArrowDownLineIcon';
import BookIcon from 'remixicon-react/BookOpenLineIcon';
import ChevronRight from 'remixicon-react/ArrowRightSFillIcon';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import CloseCircleIcon from 'remixicon-react/CloseCircleFillIcon';
import CommentContextMenu from 'remixicon-react/More2FillIcon';
import More2FillIcon from 'remixicon-react/More2FillIcon';
import CompassesLine from 'remixicon-react/CompassesLineIcon';
import ContextMenuIcon from 'remixicon-react/MoreFillIcon';
import CreateNewIcon from 'remixicon-react/AddLineIcon';
import Database2Line from 'remixicon-react/Database2LineIcon';
import DatasourceIcon from 'remixicon-react/CloudFillIcon';
import DeleteBin7 from 'remixicon-react/DeleteBin7LineIcon';
import DiscordIcon from 'remixicon-react/DiscordLineIcon';
import DownArrow from 'remixicon-react/ArrowDownSFillIcon';
import Download from 'remixicon-react/DownloadCloud2LineIcon';
import DuplicateIcon from 'remixicon-react/FileCopyLineIcon';
import EditIcon from 'remixicon-react/PencilFillIcon';
import EditLineIcon from 'remixicon-react/EditLineIcon';
import EditUnderlineIcon from 'remixicon-react/EditLineIcon';
import Emoji from 'remixicon-react/EmotionLineIcon';
import ExpandMore from 'remixicon-react/ArrowDownSLineIcon';
import DownArrowIcon from 'remixicon-react/ArrowDownSLineIcon';
import ExpandLess from 'remixicon-react/ArrowUpSLineIcon';
import EyeOn from 'remixicon-react/EyeLineIcon';
import EyeOff from 'remixicon-react/EyeOffLineIcon';
import FileTransfer from 'remixicon-react/FileTransferLineIcon';
import FileLine from 'remixicon-react/FileLineIcon';
import Filter from 'remixicon-react/Filter2FillIcon';
import GitMerge from 'remixicon-react/GitMergeLineIcon';
import GitCommit from 'remixicon-react/GitCommitLineIcon';
import GitPullRequst from 'remixicon-react/GitPullRequestLineIcon';
import GuideIcon from 'remixicon-react/GuideFillIcon';
import HelpIcon from 'remixicon-react/QuestionMarkIcon';
// import HelpFillIcon from "remixicon-react/QuestionFillIcon";
import LightbulbFlashLine from 'remixicon-react/LightbulbFlashLineIcon';
import InfoIcon from 'remixicon-react/InformationLineIcon';
import KeyIcon from 'remixicon-react/Key2LineIcon';
import LeftArrowIcon2 from 'remixicon-react/ArrowLeftSLineIcon';
import Link2 from 'remixicon-react/LinkIcon';
import LeftArrowIcon from 'remixicon-react/ArrowLeftLineIcon';
import NewsPaperLine from 'remixicon-react/NewspaperLineIcon';
import OvalCheck from 'remixicon-react/CheckboxCircleLineIcon';
import OvalCheckFill from 'remixicon-react/CheckboxCircleFillIcon';
import Pin3 from 'remixicon-react/Pushpin2FillIcon';
import QueryIcon from 'remixicon-react/CodeSSlashLineIcon';
import RightArrowIcon from 'remixicon-react/ArrowRightLineIcon';
import RightArrowIcon2 from 'remixicon-react/ArrowRightSLineIcon';
import RocketIcon from 'remixicon-react/RocketLineIcon';
import ShareBoxFillIcon from 'remixicon-react/ShareBoxFillIcon';
import ShareForwardIcon from 'remixicon-react/ShareForwardFillIcon';
import Trash from 'remixicon-react/DeleteBinLineIcon';
import UpArrow from 'remixicon-react/ArrowUpSFillIcon';
import WarningIcon from 'remixicon-react/ErrorWarningFillIcon';
import WarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
import LogoutIcon from 'remixicon-react/LogoutBoxRLineIcon';
import ShareLineIcon from 'remixicon-react/ShareLineIcon';
import LoaderLineIcon from 'remixicon-react/LoaderLineIcon';
import WidgetIcon from 'remixicon-react/FunctionLineIcon';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import GitBranchLineIcon from 'remixicon-react/GitBranchLineIcon';
import EditBoxLineIcon from 'remixicon-react/EditBoxLineIcon';
import StarLineIcon from 'remixicon-react/StarLineIcon';
import StarFillIcon from 'remixicon-react/StarFillIcon';
import Settings2LineIcon from 'remixicon-react/Settings2LineIcon';
import UploadCloud2LineIcon from 'remixicon-react/UploadCloud2LineIcon';
import DownloadLineIcon from 'remixicon-react/DownloadLineIcon';
import FileListLineIcon from 'remixicon-react/FileListLineIcon';
import HamburgerIcon from 'remixicon-react/MenuLineIcon';
import MagicLineIcon from 'remixicon-react/MagicLineIcon';
import UserHeartLineIcon from 'remixicon-react/UserHeartLineIcon';
import DvdLineIcon from 'remixicon-react/DvdLineIcon';

export enum IconSize {
  XXS = 'extraExtraSmall',
  XS = 'extraSmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XL = 'extraLarge',
  XXL = 'extraExtraLarge',
  XXXL = 'extraExtraExtraLarge',
  XXXXL = 'extraExtraExtraExtraLarge',
}

const ICON_SIZE_LOOKUP = {
  [IconSize.XXS]: theme.iconSizes.XXS,
  [IconSize.XS]: theme.iconSizes.XS,
  [IconSize.SMALL]: theme.iconSizes.SMALL,
  [IconSize.MEDIUM]: theme.iconSizes.MEDIUM,
  [IconSize.LARGE]: theme.iconSizes.LARGE,
  [IconSize.XL]: theme.iconSizes.XL,
  [IconSize.XXL]: theme.iconSizes.XXL,
  [IconSize.XXXL]: theme.iconSizes.XXXL,
  [IconSize.XXXXL]: theme.iconSizes.XXXXL,
  undefined: theme.iconSizes.SMALL,
};

export const sizeHandler = (size?: IconSize): number => {
  return (
    ICON_SIZE_LOOKUP[size as keyof typeof ICON_SIZE_LOOKUP] ||
    theme.iconSizes.SMALL
  );
};

export const IconWrapper = styled.span<IconProps>`
  &:focus {
    outline: none;
  }

  display: flex;
  align-items: center;

  svg {
    width: ${(props) => sizeHandler(props.size)}px;
    height: ${(props) => sizeHandler(props.size)}px;
    ${(props) =>
      !props.keepColors
        ? `
          fill: ${props.fillColor || ''};
          circle {
            fill: ${props.fillColor || ''};
          }
          path {
            fill: ${props.fillColor || ''};
          }
          `
        : ''};
    ${(props) => (props.invisible ? `visibility: hidden;` : null)};

    &:hover {
      cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
      ${(props) =>
        !props.keepColors
          ? `
            fill: ${props.hoverFillColor || ''};
            path {
              fill: ${props.hoverFillColor || ''};
            }
          `
          : ''}
    }
  }
`;

function getControlIcon(iconName: string) {
  const ControlIcon = ControlIcons[iconName];
  return <ControlIcon height={24} width={24} />;
}

const ICON_LOOKUP = {
  undefined: null,
  HEADING_ONE: getControlIcon('HEADING_ONE'),
  HEADING_THREE: getControlIcon('HEADING_THREE'),
  HEADING_TWO: getControlIcon('HEADING_TWO'),
  PARAGRAPH: getControlIcon('PARAGRAPH'),
  PARAGRAPH_TWO: getControlIcon('PARAGRAPH_TWO'),
  logo: <LogoIcon />,
  page: <IconV2 name="page" />,
  folder: <IconV2 name="folder" />,
  'add-more': <AddMoreIcon />,
  'add-more-fill': <AddMoreFillIcon />,
  'arrow-forward': <IconV2 name={'arrow-forward'} />,
  'arrow-left': <IconV2 name="arrow-left" />,
  'book-line': <IconV2 name="book-line" />,
  'cap-dot': <IconV2 name="cap-dot" />,
  'cap-solid': <IconV2 name="cap-solid" />,
  'card-context-menu': <IconV2 name={'card-context-menu'} />,
  'check-line': <CheckLineIcon />,
  'chevron-left': <IconV2 name="chevron-left" />,
  'chevron-right': <ChevronRight />,
  'close-circle': <CloseCircleIcon />,
  'close-modal': <CloseLineIcon />,
  'close-x': <CloseLineIcon />,
  'comment-context-menu': <CommentContextMenu />,
  'compasses-line': <CompassesLine />,
  'context-menu': <ContextMenuIcon />,
  'database-2-line': <Database2Line />,
  'delete-blank': <DeleteBin7 />,
  'double-arrow-right': <IconV2 name="double-arrow-right" />,
  'down-arrow': <DownArrowIcon />,
  'down-arrow-2': <ArrowDownLineIcon />,
  'download-line': <DownloadLineIcon />,
  'edit-box-line': <EditBoxLineIcon />,
  'edit-line': <EditLineIcon />,
  'edit-underline': <EditUnderlineIcon />,
  'expand-less': <ExpandLess />,
  'expand-more': <ExpandMore />,
  'eye-off': <EyeOff />,
  'eye-on': <EyeOn />,
  'file-line': <FileLine />,
  'file-list-line': <FileListLineIcon />,
  'fork-2': <IconV2 name="fork-2" />,
  'file-transfer': <FileTransfer />,
  'git-branch': <GitBranchLineIcon />,
  'git-commit': <GitCommit />,
  'git-pull-request': <GitPullRequst />,
  'invite-user': <IconV2 name="invite-user" />,
  'left-arrow-2': <LeftArrowIcon2 />,
  'lightbulb-flash-line': <LightbulbFlashLine />,
  'line-dashed': <IconV2 name="line-dashed" />,
  'line-dotted': <IconV2 name="line-dotted" />,
  'link-2': <Link2 />,
  'magic-line': <MagicLineIcon />,
  'more-2-fill': <More2FillIcon />,
  'news-paper': <NewsPaperLine />,
  'no-response': <IconV2 name="no-response" />,
  'oval-check': <OvalCheck />,
  'oval-check-fill': <OvalCheckFill />,
  'pin-3': <Pin3 />,
  'reaction-2': <IconV2 name="reaction-2" />,
  'read-pin': <IconV2 name="read-pin" />,
  'right-arrow': <RightArrowIcon />,
  'right-arrow-2': <RightArrowIcon2 />,
  'send-button': <IconV2 name="send-button" />,
  'settings-2-line': <Settings2LineIcon />,
  'share-2': <IconV2 name="share-2" />,
  'share-box': <ShareBoxFillIcon />,
  'share-line': <ShareLineIcon />,
  'star-fill': <StarFillIcon />,
  'star-line': <StarLineIcon />,
  'swap-horizontal': <ArrowLeftRightIcon />,
  'trash-outline': <IconV2 name="trash-outline" />,
  'trending-flat': <IconV2 name="trending-flat" />,
  'unread-pin': <IconV2 name="unread-pin" />,
  'upload-cloud': <UploadCloud2LineIcon />,
  'user-2': <IconV2 name="user-2" />,
  'user-heart-line': <UserHeartLineIcon />,
  'view-all': <RightArrowIcon />,
  'view-less': <LeftArrowIcon />,
  'warning-line': <WarningLineIcon />,
  'warning-triangle': <IconV2 name="warning-triangle" />,
  'chat-help': <IconV2 name="chat-help" />,
  billing: <IconV2 name="billing" />,
  book: <BookIcon />,
  bug: <IconV2 name="bug" />,
  cancel: <IconV2 name="cancel" />,
  chat: <IconV2 name="chat" />,
  close: <IconV2 name="close" />,
  column: <IconV2 name="column" />,
  cross: <IconV2 name="cross" />,
  danger: <IconV2 name="danger" />,
  datasource: <DatasourceIcon />,
  delete: <Trash />,
  desktop: <IconV2 name={'desktop'} />,
  discord: <DiscordIcon />,
  downArrow: <DownArrow />,
  download: <Download />,
  dropdown: <IconV2 name={'dropdown'} />,
  duplicate: <DuplicateIcon />,
  edit: <EditIcon />,
  emoji: <Emoji />,
  enterprise: <MagicLineIcon />,
  error: <IconV2 name={'error'} />,
  filter: <Filter />,
  fluid: <IconV2 name={'fluid'} />,
  fork: <GitMerge />,
  gear: <IconV2 name={'gear'} />,
  general: <IconV2 name={'general'} />,
  guide: <GuideIcon />,
  hamburger: <HamburgerIcon />,
  help: <HelpIcon />,
  info: <InfoIcon />,
  js: <IconV2 name={'js'} />,
  key: <KeyIcon />,
  lightning: <IconV2 name={'lightning'} />,
  link: <IconV2 name={'link'} />,
  loader: <LoaderLineIcon />,
  logout: <LogoutIcon />,
  manage: <IconV2 name={'manage'} />,
  member: <UserHeartLineIcon />,
  mobile: <IconV2 name={'mobile'} />,
  open: <IconV2 name={'open'} />,
  pin: <IconV2 name={'pin'} />,
  play: <IconV2 name={'play'} />,
  plus: <CreateNewIcon />,
  query: <QueryIcon />,
  reaction: <IconV2 name={'reaction'} />,
  refresh: <RefreshLineIcon />,
  rocket: <RocketIcon />,
  search: <SearchIcon />,
  setting: <IconV2 name={'setting'} />,
  share: <ShareForwardIcon />,
  shine: <IconV2 name={'shine'} />,
  snippet: <IconV2 name={'snippet'} />,
  success: <IconV2 name={'success'} />,
  support: <IconV2 name={'support'} />,
  tables: <IconV2 name={'tables'} />,
  tablet: <IconV2 name={'tablet'} />,
  trash: <Trash />,
  unpin: <IconV2 name={'unpin'} />,
  upArrow: <UpArrow />,
  upload: <IconV2 name={'upload'} />,
  user: <IconV2 name={'user'} />,
  wand: <IconV2 name={'wand'} />,
  warning: <WarningIcon />,
  widget: <WidgetIcon />,
  workspace: <IconV2 name={'workspace'} />,
  upgrade: <DvdLineIcon />,
};

export const IconCollection = Object.keys(ICON_LOOKUP);

export type IconName = typeof IconCollection[number];

export type IconProps = {
  size?: IconSize;
  name?: IconName;
  invisible?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  fillColor?: string;
  hoverFillColor?: string;
  keepColors?: boolean;
  loaderWithIconWrapper?: boolean;
  clickable?: boolean;
};

const Icon = forwardRef(
  (props: IconProps & CommonComponentProps, ref: Ref<HTMLSpanElement>) => {
    const iconName = props.name;
    const returnIcon =
      ICON_LOOKUP[iconName as keyof typeof ICON_LOOKUP] || null;

    const clickable = props.clickable === undefined ? true : props.clickable;

    let loader = <Spinner size={props.size} />;
    if (props.loaderWithIconWrapper) {
      loader = (
        <IconWrapper className={Classes.ICON} clickable={clickable} {...props}>
          <Spinner size={props.size} />
        </IconWrapper>
      );
    }

    return returnIcon && !props.isLoading ? (
      <IconWrapper
        className={`${Classes.ICON} ${props.className}`}
        clickable={clickable}
        data-cy={props.cypressSelector}
        ref={ref}
        {...props}
        onClick={props.onClick || noop}
      >
        {returnIcon}
      </IconWrapper>
    ) : props.isLoading ? (
      loader
    ) : null;
  }
);

Icon.displayName = 'Icon';

export default React.memo(Icon);
