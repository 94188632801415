import { createMessage, UPLOAD } from 'ce/constants/messages';
import { NavigationTargetType, NavigationUrlType } from './interfaces';

export const ActionType = {
  none: 'none',
  integration: 'integration',
  showModal: 'showModal',
  closeModal: 'closeModal',
  navigateTo: 'navigateTo',
  showAlert: 'showAlert',
  storeValue: 'storeValue',
  download: 'download',
  copyToClipboard: 'copyToClipboard',
  resetWidget: 'resetWidget',
  jsFunction: 'jsFunction',
  setInterval: 'setInterval',
  clearInterval: 'clearInterval',
  getGeolocation: 'fusiontech.geolocation.getCurrentPosition',
  watchGeolocation: 'fusiontech.geolocation.watchPosition',
  stopWatchGeolocation: 'fusiontech.geolocation.clearWatch',
  upload: createMessage(UPLOAD),
  printPDF: 'printPdf',
  printByTemplate: 'printBy',
};

export enum FieldType {
  ACTION_SELECTOR_FIELD = 'ACTION_SELECTOR_FIELD',
  JS_ACTION_SELECTOR_FIELD = 'JS_ACTION_SELECTOR_FIELD',
  ON_SUCCESS_FIELD = 'ON_SUCCESS_FIELD',
  ON_ERROR_FIELD = 'ON_ERROR_FIELD',
  SHOW_MODAL_FIELD = 'SHOW_MODAL_FIELD',
  CLOSE_MODAL_FIELD = 'CLOSE_MODAL_FIELD',
  PAGE_SELECTOR_FIELD = 'PAGE_SELECTOR_FIELD',
  KEY_VALUE_FIELD = 'KEY_VALUE_FIELD',
  URL_FIELD = 'URL_FIELD',
  ALERT_TEXT_FIELD = 'ALERT_TEXT_FIELD',
  ALERT_TYPE_SELECTOR_FIELD = 'ALERT_TYPE_SELECTOR_FIELD',
  KEY_TEXT_FIELD = 'KEY_TEXT_FIELD',
  VALUE_TEXT_FIELD = 'VALUE_TEXT_FIELD',
  QUERY_PARAMS_FIELD = 'QUERY_PARAMS_FIELD',
  DOWNLOAD_DATA_FIELD = 'DOWNLOAD_DATA_FIELD',
  DOWNLOAD_FILE_NAME_FIELD = 'DOWNLOAD_FILE_NAME_FIELD',
  DOWNLOAD_FILE_TYPE_FIELD = 'DOWNLOAD_FILE_TYPE_FIELD',
  COPY_TEXT_FIELD = 'COPY_TEXT_FIELD',
  NAVIGATION_TARGET_FIELD = 'NAVIGATION_TARGET_FIELD',
  WIDGET_NAME_FIELD = 'WIDGET_NAME_FIELD',
  RESET_CHILDREN_FIELD = 'RESET_CHILDREN_FIELD',
  ARGUMENT_KEY_VALUE_FIELD = 'ARGUMENT_KEY_VALUE_FIELD',
  CALLBACK_FUNCTION_FIELD = 'CALLBACK_FUNCTION_FIELD',
  DELAY_FIELD = 'DELAY_FIELD',
  ID_FIELD = 'ID_FIELD',
  CLEAR_INTERVAL_ID_FIELD = 'CLEAR_INTERVAL_ID_FIELD',
  PDF_PARAMS_FIELD = 'PDF_PARAMS_FIELD',

  PRINT_BY_TEMPLATE_SELECT_FIELD = 'PRINT_BY_TEMPLATE_SELECT_FIELD',
  PRINT_BY_TEMPLATE_PARAMS_FIELD = 'PRINT_BY_TEMPLATE_PARAMS_FIELD',
}

export const ViewTypes = {
  SELECTOR_VIEW: 'SELECTOR_VIEW',
  KEY_VALUE_VIEW: 'KEY_VALUE_VIEW',
  TEXT_VIEW: 'TEXT_VIEW',
  BOOL_VIEW: 'BOOL_VIEW',
  NAVIGATE_URL_VIEW: 'NAVIGATE_URL_VIEW',
};

export const ALERT_STYLE_OPTIONS = [
  { label: 'Info', value: "'info'", id: 'info' },
  { label: 'Success', value: "'success'", id: 'success' },
  { label: 'Error', value: "'error'", id: 'error' },
  { label: 'Warning', value: "'warning'", id: 'warning' },
];

export const RESET_CHILDREN_OPTIONS = [
  { label: 'true', value: 'true', id: 'true' },
  { label: 'false', value: 'false', id: 'false' },
];

export const FILE_TYPE_OPTIONS = [
  { label: '选择文件类型（可选）', value: '', id: '' },
  { label: '纯文本', value: "'text/plain'", id: 'text/plain' },
  { label: 'HTML', value: "'text/html'", id: 'text/html' },
  { label: 'CSV', value: "'text/csv'", id: 'text/csv' },
  { label: 'JSON', value: "'application/json'", id: 'application/json' },
  { label: 'JPEG', value: "'image/jpeg'", id: 'image/jpeg' },
  { label: 'PNG', value: "'image/png'", id: 'image/png' },
  { label: 'SVG', value: "'image/svg+xml'", id: 'image/svg+xml' },
];

export const NAVIGATION_TARGET_FIELD_OPTIONS = [
  {
    label: '当前窗口',
    value: `'${NavigationTargetType.SAME_WINDOW}'`,
    id: NavigationTargetType.SAME_WINDOW,
  },
  {
    label: '新的窗口',
    value: `'${NavigationTargetType.NEW_WINDOW}'`,
    id: NavigationTargetType.NEW_WINDOW,
  },
];

export const NAVIGATION_URL_FIELD_OPTIONS = [
  {
    label: '选择',
    value: `'${NavigationUrlType.SELECT_URL}'`,
    id: NavigationUrlType.SELECT_URL,
  },
  {
    label: '输入',
    value: `'${NavigationUrlType.INPUT_URL}'`,
    id: NavigationUrlType.INPUT_URL,
  },
];

export const FUNC_ARGS_REGEX = /((["][^"]*["])|([\[][\s\S]*[\]])|([\{][\s\S]*[\}])|(['][^']*['])|([\(][\s\S]*[\)][ ]*=>[ ]*[{][\s\S]*[}])|([^'",][^,"+]*[^'",]*))*/gi;
export const ACTION_TRIGGER_REGEX = /^{{([\s\S]*?)\(([\s\S]*?)\)}}$/g;
//Old Regex:: /\(\) => ([\s\S]*?)(\([\s\S]*?\))/g;
export const ACTION_ANONYMOUS_FUNC_REGEX = /\(\) => (({[\s\S]*?})|([\s\S]*?)(\([\s\S]*?\)))/g;
export const IS_URL_OR_MODAL = /^'.*'$/;
