/**
 * NOTE: Temporary file
 */
import { TreeDataType } from '@arco-design/web-react/es/Tree/interface';
import { ResponseMeta } from 'api/ApiResponses';
import { forEach, isArray, map } from 'lodash';

import { DepartmentItem } from './interface';

export const PARENT_ID_KEY = '0';

/**
 * TODO: fix it
 */
export const formatDepartmentListData = (
  data: DepartmentItem[]
): TreeDataType[] => {
  if (!isArray(data)) return [];

  const levelOneDept: TreeDataType[] = map(data, (dept) => {
    if (dept.deptId === PARENT_ID_KEY) {
      return {
        key: dept.deptId,
        title: dept.deptName,
        children: [],
      };
    }
  }).filter(Boolean);

  const leafDept = data.filter((dept) => dept.deptId !== PARENT_ID_KEY);

  return map(levelOneDept, (parentDept) => {
    forEach(leafDept, (childDept) => {
      if (childDept.parentId === parentDept.key) {
        parentDept.children.push({
          key: childDept.deptId,
          title: childDept.deptName,
          children: [],
        });
      }
    });

    return parentDept;
  });
};

/**
 * @description To covert an array to tree data
 * @export
 * @param {DepartmentItem[]} sourceData
 * @param {string} parentId
 * @return {*}
 */
export function arrToTree(
  sourceData: DepartmentItem[],
  parentId: string,
  mapTitle = 'deptName',
  mapKey = 'deptId'
) {
  function loop(parentId) {
    return sourceData.reduce<TreeDataType[]>((acc, cur) => {
      if (cur.parentId === parentId) {
        const children = loop(cur[mapKey]);

        const formatItem: TreeDataType = {
          title: cur[mapTitle],
          key: cur[mapKey],
        };

        if (children.length > 0) {
          formatItem.children = children;
        }

        acc.push(formatItem);
      }
      return acc;
    }, []);
  }

  return loop(parentId);
}

export function formatArrayToThree(
  sourceData: DepartmentItem[],
  parentId: string
) {
  function loop(parentId) {
    const res = [];

    for (let i = 0; i < sourceData.length; i++) {
      const item = sourceData[i];

      if (item.deptId !== parentId) {
        continue;
      }

      item.children = loop(item.deptId);

      res.push(item);
    }
    return res;
  }

  return loop(parentId);
}

export const validateResponseHelper = (resMeta: ResponseMeta) => {
  if (resMeta?.success) {
    return true;
  }

  return false;
};
