import { AppState } from 'reducers';
import { User } from 'constants/userConstants';
import { PropertyPanePositionConfig } from 'reducers/uiReducers/usersReducer';
import { OrgTypeEnum } from 'pages/ServiceCenter/interface';
import { AuthStatusEnum } from 'pages/ServiceCenter/constants';

export const getCurrentUser = (state: AppState): User | undefined =>
  state.ui.users.currentUser;

export const getAuthenticatedEnterprise = (state: AppState): boolean => {
  const { currentUser } = state.ui.users || {};

  if (process.env.REACT_APP_DISABLE_ENTERPRISE_CERTIFICATION) {
    return true;
  }

  if (
    currentUser?.type === OrgTypeEnum.organization &&
    currentUser?.companyStatus === AuthStatusEnum.authenticated
  ) {
    return true;
  }

  return false;
};

export const getUserAuthError = (state: AppState): string =>
  state.ui.users.error;
export const getUsers = (state: AppState): User[] => state.ui.users.users;
export const getProppanePreference = (
  state: AppState
): PropertyPanePositionConfig | undefined => state.ui.users.propPanePreferences;
export const getFeatureFlagsFetched = (state: AppState) =>
  state.ui.users.featureFlag.isFetched;

export const selectFeatureFlags = (state: AppState) =>
  state.ui.users.featureFlag.data;
