import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Input } from '@arco-design/web-react';

import { getCurrentUser } from 'selectors/usersSelectors';
import UserProfileImagePicker from 'components/ads/UserProfileImagePicker';
import { updateUserDetails } from 'actions/userActions';
import {
  UserContainer,
  ProfileTitle,
  ProfileWrapper,
  ProfileLabel,
  FieldErrorTips,
} from '../styles';

const DEBOUNCE_INTERVAL = 1000;

function UserProfile() {
  const [username, setUsername] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [isNameError, setIsNameError] = useState(false);
  const curUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const curUsername = curUser?.name;
  const curEmail = curUser?.email;

  const handleDisplayName = (val: string) => {
    setUsername(val);
    if (!val || val.length > 50) {
      setIsNameError(true);
      return;
    } else {
      setIsNameError(false);
    }
  };

  const handleChangeName = debounce((ev: any) => {
    const newName = ev.target.value;

    if (isNameError || newName === curUser?.name) {
      return false;
    }

    dispatch(
      updateUserDetails({
        name: newName,
      })
    );
  }, DEBOUNCE_INTERVAL);

  useEffect(() => {
    if (curUsername) {
      setUsername(curUsername);
    }
  }, [curUsername]);

  useEffect(() => {
    if (curEmail) {
      setEmail(curEmail);
    }
  }, [curEmail]);

  // useEffect(() => {
  //   dispatch(refreshCurrentUser());
  // }, []);

  return (
    <UserContainer>
      <ProfileTitle>基本信息</ProfileTitle>
      <ProfileWrapper>
        <ProfileLabel>我的头像</ProfileLabel>
        <div className="flex mt-[8px]">
          <UserProfileImagePicker />
        </div>
        <div className="mt-6 relative">
          <ProfileLabel>我的账号</ProfileLabel>
          <Input
            className="w-[444px] mt-[8px]"
            onBlur={handleChangeName}
            onChange={handleDisplayName}
            onPressEnter={handleChangeName}
            placeholder="请输入个人/企业账号，不能为空且不超过50个字符"
            // suffix={isFetchingUser ? <Spin /> : null}
            value={username}
          />
          {isNameError && (
            <FieldErrorTips className="arco-form-message">
              账号不合法，请重新输入
            </FieldErrorTips>
          )}
        </div>
        <div className="mt-6">
          <ProfileLabel>个人/企业邮箱</ProfileLabel>
          <Input
            className="w-[444px] mt-[8px]"
            disabled
            placeholder="请输入个人/企业邮箱"
            value={email}
          />
        </div>
      </ProfileWrapper>
    </UserContainer>
  );
}

export default UserProfile;
