import Dexie, { Table } from 'dexie';

import type { AppProfile } from './interface';

export class AppProfileDB extends Dexie {
  appProfile!: Table<AppProfile>;

  constructor() {
    super('appProfile');

    this.version(1).stores({
      appProfile: 'id',
    });
  }
}

export const profileDB = new AppProfileDB();
