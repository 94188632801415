import React from 'react';
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import { Card } from '@blueprintjs/core';
import { assign } from 'lodash';

const OutputContainer = styled.div`
  background: #f5f6f7;
  border: 1px solid #d0d7dd;
  box-sizing: border-box;
  padding: 6px;
`;

const ResponseContent = styled.div`
  overflow: auto;
`;

const Record = styled(Card)`
  margin: 5px;
  border-radius: 0;
  span.string-value {
    overflow-wrap: anywhere;
  }
`;

type JSONOutputProps = {
  src: Record<string, any>[];
  style: Record<string, any>;
};

type Props = JSONOutputProps;

class JSONOutput extends React.Component<Props> {
  render() {
    const { src, style } = this.props;
    const reactJsonProps = {
      name: null,
      enableClipboard: true,
      displayObjectSize: true,
      displayDataTypes: false,
      style: assign(
        {
          fontSize: '14px',
        },
        style
      ),
      collapsed: 1,
      collapseStringsAfterLength: 10,
      shouldCollapse: (field: any) => {
        const index = field.name * 1;
        return index >= 2;
      },
    };

    if (typeof src !== 'object') {
      return (
        <ResponseContent className="p-[5px]">
          {/* <OutputContainer> */}
          {/* <Record> */}
          <div style={style}>{src}</div>
          {/* </Record> */}
          {/* </OutputContainer> */}
        </ResponseContent>
      );
    }

    // if (src.length) {
    return (
      <ResponseContent>
        {/* <OutputContainer> */}
        {/* <Record> */}
        <ReactJson src={src} {...reactJsonProps} />
        {/* </Record> */}
        {/* </OutputContainer> */}
      </ResponseContent>
    );
    // }

    // return (
    //   <ResponseContent>
    //     {/* <OutputContainer> */}
    //     {src.map((record, index) => {
    //       return (
    //         <Record key={index}>
    //           <ReactJson src={record} {...reactJsonProps} />
    //         </Record>
    //       );
    //     })}
    //     {/* </OutputContainer> */}
    //   </ResponseContent>
    // );
  }
}

export default JSONOutput;
