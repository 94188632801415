import {
  Button,
  Checkbox,
  Input,
  Message,
  Switch,
  Tag,
} from '@arco-design/web-react';
import {
  cloneDeep,
  each,
  filter,
  find,
  forEach,
  get,
  includes,
  map,
  nth,
  uniqueId,
} from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Select } from '@arco-design/web-react';
import { IconMinus, IconPlus } from '@arco-design/web-react/icon';
import { JoinTypes } from '../constants';
const Option = Select.Option;
const InputSearch = Input.Search;

class Group {
  op;
  list: GroupList[][];
  id;
  constructor(initLength) {
    this.op = 'inner';
    this.list = [map(new Array(initLength), (v) => new GroupList())];
    this.id = uniqueId();
  }
}

class GroupList {
  alias;
  field;
  tableId;
  tableName;
  id;
  constructor() {
    this.id = uniqueId();
  }
}

export default function DifferenceContent({ data, fields, meta, modal }) {
  const typesData = get(meta, 'typesData');
  const { fields: tableFields, sql, templateSQL } = get(meta, 'tableData');
  const { id, name: mianTable, type } = data.previousData.nodeProperty;
  const associatedNode = get(data, 'associatedNode');
  const [fieldsList, setFieldsList] = useState(cloneDeep(tableFields));
  const [associatedNodeMirror, setAssociatedNodeMirror] = useState(
    cloneDeep(associatedNode)
  );

  const selectedFields = useMemo(
    () => filter(fieldsList, (v) => v.checked && !v.hidden),
    [fieldsList]
  );

  const otherSelectedFields = useMemo(
    () =>
      map(associatedNodeMirror, (nodeItem) => {
        return filter(get(nodeItem, 'mate.tableData.fields', []), (v) => {
          v.tableId = nodeItem.dataId;
          return v.checked && !v.hidden;
        });
      }),
    [associatedNodeMirror]
  );

  const filterID = useRef(meta.FormInfo?.filterID || uniqueId('util_')).current;
  const [groupData, setGroupData] = useState(
    meta.FormInfo?.groupData || [new Group(associatedNode.length + 1)]
  );

  useEffect(() => {
    if (meta.FormInfo?.selectedFields) {
      const selectedFieldsMap = [];
      const otherSelectedFieldsMap = [];
      forEach(meta.FormInfo?.selectedFields, (v) => {
        selectedFieldsMap.push(v.field);
      });
      forEach(meta.FormInfo?.otherSelectedFields, (item, i) => {
        if (!otherSelectedFieldsMap[i]) {
          otherSelectedFieldsMap[i] = [];
        }
        forEach(item, (v) => {
          otherSelectedFieldsMap[i].push(v.field);
        });
      });
      forEach(fieldsList, (v) => {
        // includes(meta.FormInfo?.selectedFields, {});
        if (includes(selectedFieldsMap, v.field)) {
          v.checked = true;
        }
      });
      forEach(associatedNodeMirror, (nodeItem, i) => {
        forEach(get(nodeItem, 'mate.tableData.fields', []), (v) => {
          if (includes(otherSelectedFieldsMap[i], v.field)) {
            v.checked = true;
          }
        });
        // includes(meta.FormInfo?.selectedFields, {});
      });
      setFieldsList([...fieldsList]);
      setAssociatedNodeMirror([...associatedNodeMirror]);
    }
  }, []);

  useEffect(() => {
    meta.FormInfo = {
      groupData,
      selectedFields,
      otherSelectedFields,
      filterID,
    };
  }, [groupData, selectedFields, otherSelectedFields]);

  const { name } = fields;

  const queryParams = {
    inputs: [
      {
        id: id,
        sql: sql,
        tableName: name,
        templateSQL: templateSQL,
        type: type,
      },
      ...map(associatedNodeMirror, (v) => ({
        id: v.dataId,
        sql: get(v, 'mate.tableData.sql'),
        tableName: v.name,
        templateSQL: get(v, 'mate.tableData.templateSQL'),
        type: v.nodeProperty.type,
      })),
    ],
    current: {
      id: filterID,
      type: 'except',
      settings: JSON.stringify({
        select: selectedFields
          .map((v) => {
            const field = id + '.' + v.field;
            return {
              ...v,
              field,
            };
          })
          .concat(
            otherSelectedFields.flat(2).map((v) => {
              const field = v.tableId + '.' + v.field;
              return {
                ...v,
                field,
              };
            })
          ),
        select2: [],
        except: groupData[0].list.map((v) => {
          const obj: Record<string, string> = {};
          each(v, (item, i) => {
            const index = i + 1;
            obj['alias' + index] = item.alias;
            obj['field' + index] = item.field;
            obj['tableId' + index] = item.tableId;
            obj['tableName' + index] = item.tableName;
          });
          return obj;
        }),
      }),
    },
  };

  const [sreachText, setSreachText] = useState('');

  return (
    <div className="filter-content">
      <div className="font-hm-16-bold pb-[20px]">差集</div>
      <div className="flex">
        <div className="group w-[60%]">
          <div className="font-hm-16-bold pb-[20px]">条件设置</div>
          {groupData.map((v) => (
            <div
              className="flex item my-[10px] flex-col border mr-[10px] rounded"
              key={v.id}
            >
              {v.list.map((group) => {
                const { column_condition } = typesData;
                const defaultSelectVal =
                  find(fieldsList, {
                    field: nth(group[0].field?.split('.') || [], -1),
                  }) || get(fieldsList, '[0]');
                const tempGroup = group[0];
                tempGroup.field = id + '.' + defaultSelectVal.field;
                tempGroup.alias = defaultSelectVal.alias;
                tempGroup.tableId = id;
                tempGroup.tableName = mianTable;
                return (
                  <div className="group-Item flex m-[10px]" key={uniqueId()}>
                    <div className="condition flex w-[80%] items-end">
                      <div>
                        <div className="mb-[5px]">{mianTable}</div>
                        <Select
                          defaultValue={defaultSelectVal}
                          onChange={(value) => {
                            const tempGroup = group[0];
                            tempGroup.field = id + '.' + value.field;
                            tempGroup.alias = value.alias;
                            tempGroup.tableId = id;
                            tempGroup.tableName = mianTable;
                            setFieldsList([...fieldsList]);
                          }}
                          placeholder="请选择"
                          style={{ width: 154 }}
                        >
                          {fieldsList.map((option) => (
                            <Option key={option.field} value={option}>
                              {option.alias}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <Select
                        defaultValue={get(column_condition, '[0].code')}
                        disabled
                        style={{ width: 154 }}
                      >
                        {[column_condition[0]].map((r) => (
                          <Option key={r.code} value={r.code}>
                            {r.name}
                          </Option>
                        ))}
                      </Select>
                      {associatedNode.map((v, i) => {
                        const tempGroup = group[i + 1] || {};
                        const defaultSelectVal =
                          find(get(v, 'mate.tableData.fields', []), {
                            field: nth(tempGroup.field?.split('.') || [], -1),
                          }) ||
                          get(get(v, 'mate.tableData.fields', []), '[0]', {});
                        tempGroup.field =
                          v.dataId + '.' + defaultSelectVal.field;
                        tempGroup.alias = defaultSelectVal.alias;
                        tempGroup.tableId = v.dataId;
                        tempGroup.tableName = v.name;
                        return (
                          <div key={v.dataId}>
                            <div className="mb-[5px]">{v.alias}</div>
                            <Select
                              defaultValue={defaultSelectVal}
                              onChange={(value) => {
                                const tempGroup = group[i + 1] || {};
                                tempGroup.field = v.dataId + '.' + value.field;
                                tempGroup.alias = value.alias;
                                tempGroup.tableId = v.dataId;
                                tempGroup.tableName = v.name;
                                group[i + 1] = tempGroup;
                                setFieldsList([...fieldsList]);
                              }}
                              placeholder="请选择"
                              style={{ width: 154 }}
                            >
                              {get(v, 'mate.tableData.fields', []).map(
                                (option) => (
                                  <Option key={option.field} value={option}>
                                    {option.alias}
                                  </Option>
                                )
                              )}
                            </Select>
                          </div>
                        );
                      })}
                    </div>
                    <div className="config-group flex w-[20%] ml-[20px]">
                      <div className="item">
                        <IconPlus
                          onClick={() => {
                            v.list.push(
                              map(
                                new Array(associatedNode.length + 1),
                                (v) => new GroupList()
                              )
                            );
                            setGroupData(
                              filter(groupData, (v) => v.list.length) as Group[]
                            );
                          }}
                        />
                      </div>
                      <div className="item">
                        <IconMinus
                          onClick={() => {
                            if (v.list.length <= 1) {
                              return Message.warning('至少保留一个条件');
                            }
                            v.list = filter(v.list, (item) => group !== item);
                            setGroupData(
                              filter(groupData, (v) => v.list.length) as Group[]
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="w-[40%] font-hm-16-bold ">
          <div>
            <span>显示列设置</span>
          </div>
          <div>
            <div>
              <div className="flex items-center">
                <InputSearch
                  onChange={(val) => setSreachText(val)}
                  onSearch={() => {
                    setFieldsList(
                      fieldsList.map((v) => {
                        return {
                          ...v,
                          hidden: !v.alias.includes(sreachText),
                        };
                      })
                    );
                  }}
                  placeholder="搜素字段名"
                  searchButton
                  style={{ width: 200, margin: 12 }}
                />
                <Checkbox
                  onChange={(flag: boolean) => {
                    setAssociatedNodeMirror(
                      map(associatedNodeMirror, (nodeItem) => {
                        map(get(nodeItem, 'mate.tableData.fields', []), (v) => {
                          v.checked = flag;
                        });
                        return nodeItem;
                      })
                    );
                    setFieldsList(
                      map(fieldsList, (v) => ({ ...v, checked: flag }))
                    );
                  }}
                >
                  全选
                </Checkbox>
                <Checkbox
                  onChange={() => {
                    setAssociatedNodeMirror(
                      map(associatedNodeMirror, (nodeItem) => {
                        map(get(nodeItem, 'mate.tableData.fields', []), (v) => {
                          v.checked = !v.checked;
                        });
                        return nodeItem;
                      })
                    );
                    setFieldsList(
                      map(fieldsList, (v) => ({
                        ...v,
                        checked: !v.checked,
                      }))
                    );
                  }}
                >
                  反选
                </Checkbox>
              </div>
            </div>
            <div className="filed-h my-[10px] overflow-scroll">
              <p className="mt-[10px]">{name}</p>
              {fieldsList.map((v) =>
                v.hidden ? null : (
                  <Tag
                    checkable
                    checked={v.checked}
                    className="m-[5px] border border-primary"
                    color="#FF5800"
                    key={v.field}
                    onCheck={(flag) => {
                      v.checked = flag;
                      setFieldsList([...fieldsList]);
                    }}
                  >
                    {v.alias}
                  </Tag>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="mr-[30px]"
          onClick={() => {
            if (!get(selectedFields, 'length')) {
              return Message.warning('至少需要选中一个字段');
            }
            modal.current.close();
            modal.ok(queryParams);
          }}
          type="primary"
        >
          提交
        </Button>
        <Button onClick={() => modal.current.close()} type="secondary">
          取消
        </Button>
      </div>
    </div>
  );
}
