import React, { useCallback, useEffect, lazy, Suspense, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import classNames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import {
  Menu,
  Dropdown,
  Trigger,
  Modal,
  Message,
  Button,
  Comment,
  Radio,
  List,
  Typography,
  Tooltip,
  Select,
} from '@arco-design/web-react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  IconObliqueLine,
  IconDown,
  IconMore,
  IconLeft,
  IconRight,
  IconRobot,
  IconSend,
  IconMenu,
  IconMenuUnfold,
  IconPlus,
  IconCloseCircle,
} from '@arco-design/web-react/icon';
import { useMutation } from 'react-query';
import axios from 'axios';

import { ReduxActionTypes } from '@appsmith/constants/ReduxActionConstants';
import AnalyticsUtil from 'utils/AnalyticsUtil';
import { AppState } from 'reducers';
import {
  getCurrentApplicationId,
  getCurrentPageId,
  getIsPublishingApplication,
  previewModeSelector,
} from 'selectors/editorSelectors';
import { getAllUsers, getCurrentOrgId } from 'selectors/organizationSelectors';
import {
  getAuthenticatedEnterprise,
  getCurrentUser,
  selectFeatureFlags,
} from 'selectors/usersSelectors';
import { Size } from 'components/ads/Button';
import { getTheme, ThemeMode } from 'selectors/themeSelectors';
import { snipingModeSelector } from 'selectors/editorSelectors';
import { setSnipingMode as setSnipingModeAction } from 'actions/propertyPaneActions';
import { showConnectGitModal } from 'actions/gitSyncActions';
import RealtimeAppEditors from '../RealtimeAppEditors';
import { EditorSaveIndicator } from '../EditorSaveIndicator';
import { getNextEntityName, retryPromise } from 'utils/AppsmithUtils';
import { APPLICATIONS_URL, ENTERPRISE_AUTHENTICATION } from 'constants/routes';
import { fetchUsersForOrg } from 'actions/orgActions';
import { GitSyncModalTab } from 'entities/GitSync';
import { getIsGitConnected } from 'selectors/gitSyncSelectors';
import { builderURL, deployURL, deployRecordURL } from 'RouteBuilder';
import { createMessage, APP_SHARE } from '@appsmith/constants/messages';
import Boxed from '../GuidedTour/Boxed';
import EndTour from '../GuidedTour/EndTour';
import { GUIDED_TOUR_STEPS } from '../GuidedTour/constants';
import { setIsGitSyncModalOpen } from 'actions/gitSyncActions';
import { selectAllPages } from 'selectors/entitiesSelector';
import { Page } from '@appsmith/constants/ReduxActionConstants';
import {
  createPage,
  generateTemplateToUpdatePage,
  redoAction,
  undoAction,
} from 'actions/pageActions';
import { updateApplication } from 'actions/applicationActions';
import history from 'utils/history';
import QueryDrawerHeader from '../EditorQueryDrawer/queryDrawerHeader';
import { linkToWizardOrDeployRecordListPage } from 'pages/Applications/helpers';
import { isSimple } from '../guideAtom';
import {
  HeaderWrapper,
  HeaderSection,
  DeploySection,
  StyledDeployButton,
  BindingBanner,
  ShareButton,
  StyledShareText,
} from './styles';
import type { EditorHeaderProps, ModalState } from './interface';
import type { UpdateApplicationPayload } from 'api/ApplicationApi';
import { ModalRenameApp } from './ModalRenameApp';
import { ModalPreviewSubApp } from './ModalPreviewSubApp';
import ApplicationApi from 'api/ApplicationApi';
import { validateResponseHelper } from '../AppSysConfigDrawer/utils';
import { isURL } from 'utils/TypeHelpers';
// import { Toaster, Variant } from 'components/ads';
import { getCheckSubAppUrl } from './utils';
import AI, { extractCodeBlocks } from '../AITool';
import { extractCurrentDSL } from 'utils/WidgetPropsUtils';
import { GENERATE_PAGE_MODE } from '../GeneratePage/components/constants';
import {
  getFirstTimeUserOnboardingComplete,
  getIsFirstTimeUserOnboardingEnabled,
} from 'selectors/onboardingSelectors';
import { GeneratePagePayload } from '../GeneratePage/components/GeneratePageForm/types';
import ActionAPI from 'api/ActionAPI';
import { fetchDatasourceStructure } from 'actions/datasourceActions';
import { fetchDatasources } from '../../../actions/datasourceActions';
import { getDatasources } from '../../../selectors/entitiesSelector';
import MessageItem from '../AITool/MessageItem';
import { paddingTop } from 'styled-system';
import TextGradient from 'components/ads/TextGradient';
import Icon from 'components/icon';
import AiHotBg from 'assets/images/aiHotBg.png';
import AiHotBg2 from 'assets/images/aiHotBg2.png';
import { divide } from 'lodash';
import { pagefields } from './pagefield';
import PageLoding from 'assets/gifs/normal.gif';

const ListContainer = styled(List)`
  .arco-list-item {
    border-bottom: 0 !important;
  }
`;

const GlobalSearch = lazy(() => {
  return retryPromise(() => import('components/editorComponents/GlobalSearch'));
});

function unformatSql(formattedSql = '') {
  const unformattedSql = formattedSql.replace(/\n/g, '').replace(/\s+/g, ' ');
  return unformattedSql;
}

export function ShareButtonComponent() {
  return (
    <ShareButton className="flex items-center t--application-share-btn header__application-share-btn rounded-sm">
      {/* <StyledSharedIcon name="share-line" /> */}
      <StyledShareText>{createMessage(APP_SHARE)}</StyledShareText>
    </ShareButton>
  );
}

export function EditorHeader(props: EditorHeaderProps) {
  const {
    applicationId,
    currentApplication,
    isPublishing,
    orgId,
    publishApplication,
  } = props;
  const [modalState, setModalState] = useState<ModalState>({
    visible: false,
    type: null,
  });
  const isSnipingMode = useSelector(snipingModeSelector);
  const isGitConnected = useSelector(getIsGitConnected);
  const isPreviewMode = useSelector(previewModeSelector);
  const isEnterpriseAuthenticated = useSelector(getAuthenticatedEnterprise);
  const pages: Page[] = useSelector(selectAllPages);
  const { showEditQueryDrawer } = useSelector(
    (state: AppState) => state.ui.globalSearch
  );
  const params = useParams<{ pageId: string }>();
  const simple = useRecoilValue(isSimple);
  const dispatch = useDispatch();
  const location = useLocation();
  const getPreviewSubAppInfo = useMutation(ApplicationApi.getPreviewSubAppInfo);
  const getSubAppDataJson = useMutation(
    ApplicationApi.getPreviewSubAppDataJson
  );

  useEffect(() => {
    if (window.location.href) {
      const searchParams = new URL(window.location.href).searchParams;
      const isSnipingMode = searchParams.get('isSnipingMode');
      const updatedIsSnipingMode = isSnipingMode === 'true';
      dispatch(setSnipingModeAction(updatedIsSnipingMode));
    }
  }, [location]);

  const handlePublish = () => {
    if (applicationId) {
      publishApplication(applicationId);

      // const appName = currentApplication ? currentApplication.name : '';
      // AnalyticsUtil.logEvent('PUBLISH_APP', {
      //   appId: applicationId,
      //   appName,
      // });
    }
  };

  const featureFlags = useSelector(selectFeatureFlags);

  const handleClickDeploy = useCallback(
    (fromDeploy?: boolean) => {
      if (featureFlags.GIT && isGitConnected) {
        dispatch(showConnectGitModal());
        AnalyticsUtil.logEvent('GS_DEPLOY_GIT_CLICK', {
          source: fromDeploy
            ? 'Deploy button'
            : 'Application name menu (top left)',
        });
      } else {
        handlePublish();
      }
    },
    [featureFlags.GIT, dispatch, handlePublish]
  );

  useEffect(() => {
    if (orgId) {
      dispatch(fetchUsersForOrg(orgId));
    }
  }, [orgId]);

  const routes = React.useMemo(() => {
    const list = treeFindPath(pages, (data) => data.id === params.pageId);
    return list;
  }, [pages, params.pageId]);

  function treeFindPath(tree = [], func: (item: Page) => boolean, path = []) {
    if (!tree) return [];
    for (const data of tree) {
      path.push(data);
      if (func(data)) return path;
      if (data.children) {
        const findChildren = treeFindPath(data.children, func, path);
        if (findChildren.length) return findChildren;
      }
      path.pop();
    }
    return [];
  }

  const switchPage = useCallback((slug: string, id: string) => {
    history.push(
      builderURL({
        pageSlug: slug,
        pageId: id,
      })
    );
  }, []);

  const handleBack = () => {
    history.push(APPLICATIONS_URL);
  };

  const deleteApplication = () => {
    if (applicationId && applicationId.length > 0) {
      props.dispatchDeleteAppAction(applicationId);
      history.push(APPLICATIONS_URL);
    } else {
      Message.warning('删除应用程序时出错');
    }
  };
  const handlePreview = () => {
    window.open(`https://preview.fusiontech.cn/create/${applicationId}`);
    // window.open(`http://10.10.10.161:32497/create/${applicationId}`);
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test'
    ) {
      window.open(`http://10.10.10.161:32497/create/${applicationId}`);
    } else {
      window.open(`https://preview.fusiontech.cn/create/${applicationId}`);
    }
  };

  const closeModal = () => {
    setModalState({
      type: null,
      visible: false,
    });
  };

  const handleEditName = (appName: string) => {
    props.updateApplicationDispatch(applicationId, {
      name: appName.trim(),
      currentApp: true,
    });

    closeModal();
  };

  function openDeleteConfirm() {
    Modal.confirm({
      title: '确认删除',
      content: '是否删除应用？删除后，您可在回收站中找到被删除的应用。',
      okButtonProps: { status: 'danger' },
      onOk: () => {
        deleteApplication();
      },
    });
  }

  function handleDeployAction() {
    if (
      !isEnterpriseAuthenticated &&
      props.currentApplication?.configExtension?.channel !== '抖音'
    ) {
      Modal.confirm({
        icon: null,
        title: '申请企业认证',
        content:
          '您当前账号还未认证，认证后可解锁超多高级功能，定制各种个性化需求，立即认证吧~',
        okText: '我要申请',
        cancelText: '稍后申请',
        onOk: () => {
          history.push(ENTERPRISE_AUTHENTICATION);
        },
      });

      return;
    }
    const deployApplicationURL = deployURL({
      applicationSlug: currentApplication.slug as string,
      applicationVersion: currentApplication.applicationVersion,
      applicationId: currentApplication.id,
      pageSlug: 'page',
      pageId: '',
    });

    const deployRecordListURL = deployRecordURL({
      applicationSlug: currentApplication.slug as string,
      applicationId: currentApplication.id,
      pageSlug: 'page',
      pageId: '',
    });

    linkToWizardOrDeployRecordListPage(
      deployApplicationURL,
      deployRecordListURL
    );
  }

  const handleCheckSubUrlAvailable = useCallback(async (subUrl: string) => {
    try {
      if (subUrl) {
        const subAppIsReady = await axios.get(getCheckSubAppUrl(subUrl));
        // NOTE: Just for testing the below code segment
        // const subAppIsReady = await axios.get(
        //   `https://63d734d200eda14f641cd4f1.app.fusiontech.cn/api/ping`
        // );
        return subAppIsReady?.data;
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleFetchSubAppProfile = useCallback(async () => {
    const { id } = currentApplication || {};

    try {
      const appInfoRes = await getSubAppDataJson.mutateAsync(id);
      /**
       * TODO: to check `appInfoRes`
       */
      return {
        id,
        ...appInfoRes,
      };
    } catch (err) {
      console.error(err);
      // throw Error(err);
    }
  }, [currentApplication]);

  const handleFetchRemoteAppUrl = useCallback(async () => {
    const { id } = currentApplication || {};

    try {
      const result = await getPreviewSubAppInfo.mutateAsync(id);

      if (validateResponseHelper(result?.responseMeta) && isURL(result?.data)) {
        return result?.data;
      }

      return null;
    } catch (err) {
      return null;
    }
  }, [currentApplication]);

  const moreList = [
    // {
    //   name: '预览系统',
    //   action: () => {
    //     setModalState({
    //       visible: true,
    //       type: 'PREVIEW',
    //     });
    //   },
    // },
    {
      name: '预览系统',
      action: handlePreview,
    },
    {
      name: '修改应用名称',
      action: () => {
        setModalState({
          visible: true,
          type: 'RENAME',
        });
      },
    },
    {
      name: '数据源',
      action: () => {
        Message.warning('敬请等待！');
      },
    },
    {
      name: '部署',
      action: handleDeployAction,
    },
    // {
    //   name: '分享',
    //   action: () => {
    //     Message.warning('敬请等待！');
    //   },
    // },
    {
      name: '操作',
      action: () => null,
      icon: <IconRight />,
      dropList: [
        {
          name: '撤销',
          expand: 'Ctrl + Z',
          action: props.dispatchUndoAction,
        },
        {
          name: '恢复',
          expand: 'Ctrl+Shift+Z',
          action: props.dispatchRedoAction,
        },
        // {
        //   name: '复制',
        //   expand: 'Ctrl + C',
        //   // action: handleBack,
        // },
        // {
        //   name: '剪切',
        //   expand: 'Ctrl + X',
        //   // action: handleBack,
        // },
        // {
        //   name: '粘贴',
        //   expand: 'Ctrl + V',
        //   // action: handleBack,
        // },
      ],
    },
    // {
    //   name: '帮助',
    //   action: () => {
    //     Message.warning('敬请等待！');
    //   },
    // },
    {
      name: '返回至应用列表',
      action: handleBack,
    },
    {
      name: '删除应用',
      action: openDeleteConfirm,
      isDanger: true,
    },
  ];

  const isFirstTimeUserOnboardingEnabled = useSelector(
    getIsFirstTimeUserOnboardingEnabled
  );
  const isFirstTimeUserOnboardingComplete = useSelector(
    getFirstTimeUserOnboardingComplete
  );
  const generatePageAction = useCallback(
    (data: GeneratePagePayload) => {
      let extraParams = {};
      if (data.pluginSpecificParams) {
        extraParams = {
          pluginSpecificParams: data.pluginSpecificParams,
        };
      }

      const payload = {
        applicationId: applicationId || '',
        pageId: data.pageId || '',
        columns: data.columns || [],
        searchColumn: data.searchColumn || [],
        tableName: data.tableName,
        datasourceId: data.datasourceId || '',
        mode: GENERATE_PAGE_MODE.REPLACE_EMPTY,
        ...extraParams,
      };

      AnalyticsUtil.logEvent('GEN_CRUD_PAGE_FORM_SUBMIT');
      dispatch(generateTemplateToUpdatePage(payload));

      if (isFirstTimeUserOnboardingEnabled) {
        dispatch({
          type: ReduxActionTypes.SET_FIRST_TIME_USER_ONBOARDING_APPLICATION_ID,
          payload: '',
        });
      }
      if (isFirstTimeUserOnboardingComplete) {
        dispatch({
          type: ReduxActionTypes.SET_FIRST_TIME_USER_ONBOARDING_COMPLETE,
          payload: false,
        });
      }
    },
    [applicationId, isFirstTimeUserOnboardingEnabled]
  );
  const allDb = useSelector(getDatasources);
  const [pageloading, setPageLoading] = useState(false);
  const [tab, setTab] = useState('生成页面');
  // const [popVisible, setPopVisible] = useState(true);
  const renderHeader = () => (
    <HeaderWrapper className="pr-3" data-testid="t--ft-editor-header">
      <HeaderSection className="space-x-3">
        <div
          className="rounded w-[26px] h-[26px]  text-center hover:bg-[#eceded] cursor-pointer transition"
          onClick={handleBack}
        >
          <IconLeft />
        </div>
        <div className="text-12 text-content">{currentApplication?.name}</div>
        <div className="text-12 mx-1 text-content">/</div>
        <div className="flex items-center text-[#4E5969] text-[12px]">
          {routes.map((item, index) => {
            return item?.children?.length ? (
              <React.Fragment key={item.id}>
                {!!index && <IconObliqueLine className="mx-1" />}
                <Dropdown
                  droplist={
                    <Menu
                      onClickMenuItem={(key) => {
                        const [id, slug] = key.split('-');
                        switchPage(slug, id);
                      }}
                    >
                      {item?.children?.map((i) => {
                        return (
                          <Menu.Item
                            disabled={
                              i.pageType === 'DIR' && !i.children.length
                            }
                            key={
                              i.pageType === 'DIR' && i.children.length
                                ? `${i.children?.[0].id}-${i.children?.[0].slug}`
                                : `${i?.id}-${i?.slug}`
                            }
                          >
                            {i.name}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }
                  position="bl"
                >
                  <span className="cursor-pointer">
                    {item.name}
                    <IconDown className="ml-[4px]" />
                  </span>
                </Dropdown>
              </React.Fragment>
            ) : (
              <React.Fragment key={item?.id}>
                {!!index && <IconObliqueLine className="mx-1" />}
                <span
                  className={classNames({
                    'font-bold text-[#666666]': index === routes.length - 1,
                  })}
                >
                  {item.name}
                </span>
              </React.Fragment>
            );
          })}
        </div>
      </HeaderSection>
      <HeaderSection
        className={classNames({
          '-translate-y-full opacity-0': isPreviewMode,
          'translate-y-0 opacity-100': !isPreviewMode,
          'transition-all transform duration-400': true,
        })}
      >
        {/* <Zoomer /> */}
      </HeaderSection>
      <HeaderSection className="space-x-3">
        <AI
          getQuestion={(question) =>
            `要求：1. 你是一个sql代码生成助手,根据我的问题不同的业务需求，你需要将需求分析并生成一个数据库表 2. 你仅只能分别输出这个创建表的sql代码（需要给表结构字段添加注释，sql 必须是标注oracle 语法（示例：CREATE TABLE "LIJIE_BZ_DICT" ("BZ_CODE" VARCHAR2(255 BYTE),"BZ_NAME" VARCHAR2(255 BYTE));
COMMENT ON COLUMN "LIJIE_BZ_DICT"."BZ_CODE" IS '病种编号';
COMMENT ON COLUMN "LIJIE_BZ_DICT"."BZ_NAME" IS '病种名称';,sql必须使用markdown语法,sql创建的数据库表的表名必须为英文）和表结构中字段所对应表格并展示,禁止在sql代码中有任何注释,禁止回复其他任何文字。我的问题${question}`
          }
          popup={({ closeTool, setValue }) => {
            return (
              <div className="rounded-[10px] bg-white overflow-hidden mr-[5px]  shadow h-[600px] w-[240px]">
                <div
                  className="h-[40px] flex items-center px-[15px] "
                  style={{ background: `url(${AiHotBg2}) no-repeat` }}
                >
                  <Icon name="aiHot" />
                  <div className=" font-hm-14-bold ml-[6px]">热门需求TOP10</div>
                </div>
                <div className="p-[12px]">
                  <ListContainer
                    // dataSource={new Array(100).fill(null).map((_, index) => {
                    //   return {
                    //     title: '需求管理',
                    //     description:
                    //       '包含:x字段、x字段、x字段、x字段、x字段、x字段、x字段、x字段...' +
                    //       index,
                    //     views: '1280',
                    //   };
                    // })}
                    dataSource={pagefields}
                    render={(item, index) => (
                      <div
                        className="cursor-pointer w-full h-[115px] border-[#f0f0f0] border-1 rounded-[10px] mb-[8px] px-[12px] py-[8px] "
                        key={index}
                      >
                        <div className="flex items-center">
                          <Tooltip content={item.title}>
                            <div className="truncate">{item.title}</div>
                          </Tooltip>
                          <div className="w-[1px] h-[10px] bg-[#eee] mx-[8px]" />
                          <Icon className="text-[#999]" name="eye" />
                          <div className="ml-[5px]  text-[#999] ">
                            {item.views}
                          </div>
                        </div>
                        <div className="text-[#999] h-[40px] ">
                          <Typography.Paragraph
                            ellipsis={{
                              rows: 2,
                              showTooltip: true,
                              // expandable: true,
                              wrapper: 'span',
                              // onExpand: () =>
                              //   setValue(
                              //     `制作${item.title},${item.description}`
                              //   ),
                              expanded: false,
                              // expandNodes: [
                              //   <span key="1">应用</span>,
                              //   <span key="2">应用</span>,
                              // ],
                            }}
                            style={{ color: '#999' }}
                          >
                            {item.description}
                          </Typography.Paragraph>
                        </div>
                        <div className="flex flex-row-reverse mt-[8px]">
                          <Button
                            className="h-[26px] w-[75px] !px-[2px]"
                            onClick={() => {
                              setValue(`制作${item.title},${item.description}`);
                            }}
                          >
                            立即使用
                          </Button>
                        </div>
                      </div>
                    )}
                    size="small"
                    style={{ width: 216, maxHeight: 500, border: 0 }}
                  />
                  <div className="flex justify-center p-[10px]">
                    <IconMenuUnfold
                      className="cursor-pointer"
                      fontSize={20}
                      onClick={closeTool}
                    />
                  </div>
                </div>
              </div>
            );
          }}
          renderResult={({ isTem, message }) => {
            if (isTem) {
              return null;
            }
            const sql = extractCodeBlocks(message)[0];
            const create = (datasourceId?: string) => {
              const name = getNextEntityName(
                '页面',
                pages.map((page: Page) => page.pageName)
              );
              // Default layout is extracted by adding dynamically computed properties like min-height.
              const defaultPageLayouts = [
                {
                  dsl: extractCurrentDSL(),
                  layoutOnLoadActions: [],
                },
              ];
              if (!sql) {
                Message.error('创建失败，请修改需求后重试。');
                return;
              }
              setPageLoading(true);
              ActionAPI.getActionExecuteResult({
                applicationId,
                sql: unformatSql(sql).replace(/^sql/gi, ''),
                datasourceId,
              }).then(
                (res: any) => {
                  if (res?.responseMeta?.success === false) {
                    setPageLoading(false);
                    Message.error('创建失败，请修改需求后重试。');
                    return;
                  }
                  if (
                    res &&
                    res.applicationId &&
                    res.datasourceId &&
                    res.tableName
                  ) {
                    dispatch([
                      fetchDatasourceStructure(res.datasourceId, true, () => {
                        dispatch(
                          createPage({
                            applicationId: applicationId,
                            pageName: name,
                            pageType: 'PAGE',
                            parentId: '0',
                            layouts: defaultPageLayouts,
                            blockNavigation: true,
                            onSuccess: (data) => {
                              if (data) {
                                const pageInf0 = data || {};
                                const pageId = pageInf0.id;
                                generatePageAction({
                                  pageId,
                                  ...res,
                                });
                                setPageLoading(false);
                              }
                            },
                          })
                        );
                      }),
                      fetchDatasources(),
                    ]);
                  }
                },
                (err) => {
                  setPageLoading(false);
                  Message.error('创建失败，请修改需求后重试。');
                }
              );
            };
            return (
              <MessageItem
                menu={
                  !pageloading ? (
                    <div className="mt-[5px] flex !w-[350px]">
                      <Menu
                        style={{
                          backgroundColor: '#fff',
                        }}
                      >
                        <Menu.Item className=" bg-[#F7F7FC]" key="new">
                          <div className="text-[#444]">
                            收到您的需求，选择新数据库或已有数据库创建页面
                          </div>
                          <div className="bg-[#F7F7FC] rounded-[10px] px-[12px]">
                            <div
                              className="  flex justify-between items-center "
                              onClick={() => create()}
                            >
                              <div className="text-[#444]">使用新数据库</div>
                              <IconPlus className="!text-[#9092FF]" />
                            </div>
                            {allDb.map((v) => {
                              return (
                                <div
                                  className="flex justify-between items-center  border-t-1 border-[#fff] truncate"
                                  key={v.id}
                                  onClick={() => create(v.id)}
                                >
                                  <div className=" text-[#444]"> {v.name}</div>
                                  <IconPlus className="!text-[#9092FF]" />
                                </div>
                              );
                            })}
                          </div>
                        </Menu.Item>
                      </Menu>
                    </div>
                  ) : (
                    <>
                      {/* <div className="h-12 my-4 flex items-center justify-center bg-slate bg-op-15 text-slate rounded-sm tip-container">
                        页面创建中...
                      </div> */}
                      <div className=" bg-[#fff] rounded-[10px] p-[12px]">
                        <div className=" text-[#666] text-16 text-slate">
                          页面创建中...
                        </div>
                        <div className="flex flex-row-reverse">
                          <Button onClick={() => setPageLoading(false)}>
                            取消
                          </Button>
                        </div>
                      </div>
                      {/* <div>
                        <div className=" text-center text-18 bg-slate bg-op-15 text-slate tip-container">
                          页面创建中...
                        </div>
                        <div
                          className="flex flex-row-reverse text-[#9092FF] "
                          onClick={() => setPageLoading(false)}
                        >
                          <IconCloseCircle style={{ fontSize: '26px' }} />
                        </div>
                      </div> */}
                    </>
                  )
                }
                message=""
                role={'assistant'}
              />
            );
          }}
          tools={
            <div>
              {/* <Radio.Group
                className={'mb-[5px]'}
                name="position"
                onChange={setTab}
                options={['生成页面', '统计分析']}
                size="mini"
                type="button"
                value={tab}
              /> */}
              <Select
                defaultValue="生成页面"
                onChange={setTab}
                options={['生成页面', '统计分析']}
                style={{ width: 90 }}
                value={tab}
              />
            </div>
          }
          welcome={({ setShowTool, setValue }) => (
            <div className="w-[400px] h-[178px] bg-[#fff] rounded-[10px] p-[12px] text-12">
              <div className="text-[#666] leading-[20px] mb-[4px]">
                描述您的需求，5秒快速生成页面
              </div>
              <div className="w-full h-[130px] bg-[#F5F6FF] rounded-[10px]">
                <div
                  className="h-[28px] rounded-t-[10px] px-[12px] flex justify-between items-center"
                  style={{ background: `url(${AiHotBg})` }}
                >
                  <div className="flex items-center h-[28px]">
                    <Icon
                      name="aiHot"
                      style={{ fontSize: '16px', marginRight: '4px' }}
                    />
                    <div className=" leading-[28px] text-[#333] font-hm-12-bold">
                      热门需求TOP10
                    </div>
                  </div>
                  <div
                    className="text-[#9092FF] cursor-pointer"
                    onClick={() => {
                      setShowTool(true);
                    }}
                  >
                    更多
                  </div>
                </div>
                <div>
                  {pagefields.slice(0, 3).map((item, index) => {
                    return (
                      <div
                        className="flex pl-[8px] pr-[12px] text-[12px] h-[33px] items-center"
                        key={index}
                      >
                        <div className="text-[#F8B840] font-hm-12-bold mr-[6px] flex">
                          {index + 1}
                        </div>
                        <div className="flex border-b-1 border-[#fff] items-center h-[32px] flex-1">
                          <div className="text-[#444] w-[63px] mr-[6px] truncate">
                            {item.title}
                          </div>
                          <div
                            className="flex items-center text-[#999]"
                            onClick={() => {
                              setValue(`制作${item.title},${item.description}`);
                            }}
                          >
                            <div className="truncate w-[270px]">
                              {item.description}
                            </div>
                            <IconRight />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        />
        <EditorSaveIndicator />
        <Trigger
          popup={() => (
            <ul className="w-[128px] rounded text-[12px] bg-[#fff] shadow">
              {moreList.map((item) => {
                const inner = (
                  <li
                    className={classNames({
                      'h-[32px] relative  px-4 leading-[32px]  cursor-pointer hover:bg-[#f7f7f7] hover:font-bold flex justify-between items-center': true,
                      'text-[#FF0000]': item.isDanger,
                    })}
                    key={item.name}
                    onClick={item.action || null}
                  >
                    {item.name}
                    {item.icon && item.icon}
                  </li>
                );
                return item.dropList ? (
                  <Trigger
                    key={item.name}
                    popup={() => (
                      <ul className="bg-[#fff] text-[12px] w-[160px] rounded shadow">
                        {item.dropList.map((dropItem) => {
                          return (
                            <li
                              className="h-[32px] px-4 leading-[32px]  cursor-pointer hover:bg-[#f7f7f7] hover:font-bold flex justify-between items-center"
                              key={dropItem.name}
                              onClick={dropItem.action || null}
                            >
                              <span>{dropItem.name}</span>
                              <span>{dropItem.expand}</span>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    popupAlign={{ left: [8, 18] }}
                    position="left"
                  >
                    {inner}
                  </Trigger>
                ) : (
                  inner
                );
              })}
            </ul>
          )}
          popupAlign={{ bottom: [30, 10] }}
        >
          <div
            className="rounded  text-[#666666] w-[26px] h-[26px] text-center hover:bg-[#eceded] cursor-pointer transition"
            id="step-guide2-1"
          >
            <IconMore />
          </div>
        </Trigger>
        <Boxed
          alternative={<EndTour />}
          step={GUIDED_TOUR_STEPS.BUTTON_ONSUCCESS_BINDING}
        >
          <RealtimeAppEditors applicationId={applicationId} />
          <DeploySection className={classNames('leading-[0]')}>
            <StyledDeployButton
              className={classNames({
                '!h-[15px] pointer-events-none': simple,
              })}
              data-guided-tour-iid="deploy"
              isLoading={isPublishing}
              onClick={() => handleClickDeploy(true)}
              size={Size.small}
              text={'预览'}
            />
          </DeploySection>
        </Boxed>
      </HeaderSection>
      <Suspense fallback={null}>
        <GlobalSearch />
      </Suspense>
      {isSnipingMode && (
        <BindingBanner className="t--sniping-mode-banner">
          选择要绑定的组件
        </BindingBanner>
      )}
    </HeaderWrapper>
  );

  const renderModalChildren = () => {
    if (modalState.type === 'PREVIEW') {
      return (
        <ModalPreviewSubApp
          onCancel={closeModal}
          onCheckSubUrlAvailable={handleCheckSubUrlAvailable}
          onFetchRemoteAppUrl={handleFetchRemoteAppUrl}
          onGetSubAppProfile={handleFetchSubAppProfile}
        />
      );
    } else if (modalState.type === 'RENAME') {
      return (
        <ModalRenameApp
          initialAppName={currentApplication.name}
          onCancel={closeModal}
          onConfirm={handleEditName}
        />
      );
    }

    return null;
  };

  // nameModalState, setModalState
  return (
    <ThemeProvider theme={theme}>
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        visible={modalState.visible}
      >
        {renderModalChildren()}
      </Modal>

      {showEditQueryDrawer ? (
        <HeaderWrapper className="pr-3" data-testid="t--ft-editor-header">
          <QueryDrawerHeader />
        </HeaderWrapper>
      ) : (
        renderHeader()
      )}
    </ThemeProvider>
  );
}

const theme = getTheme(ThemeMode.LIGHT);

const mapStateToProps = (state: AppState) => ({
  pageName: state.ui.editor.currentPageName,
  orgId: getCurrentOrgId(state),
  applicationId: getCurrentApplicationId(state),
  currentApplication: state.ui.applications.currentApplication,
  isPublishing: getIsPublishingApplication(state),
  pageId: getCurrentPageId(state) as string,
  sharedUserList: getAllUsers(state),
  currentUser: getCurrentUser(state),
  // currentApplicationName: state.ui.applications.currentApplication.name,
});

const mapDispatchToProps = (dispatch: any) => ({
  publishApplication: (applicationId: string) => {
    dispatch({
      type: ReduxActionTypes.PUBLISH_APPLICATION_INIT,
      payload: {
        applicationId,
      },
    });
  },
  openGitModal: () => {
    dispatch(
      setIsGitSyncModalOpen({
        isOpen: true,
        tab: GitSyncModalTab.GIT_CONNECTION,
      })
    );
  },
  dispatchUndoAction: () => {
    dispatch(undoAction());
  },
  dispatchRedoAction: () => {
    dispatch(redoAction());
  },
  updateApplicationDispatch: (id: string, data: UpdateApplicationPayload) => {
    dispatch(updateApplication(id, data));
  },
  dispatchDeleteAppAction: (applicationId: string) => {
    dispatch({
      type: ReduxActionTypes.DELETE_APPLICATION_INIT,
      payload: {
        applicationId: applicationId,
      },
    });
  },
});

// dispatchRedoAction dispatchUndoAction
// EditorHeader.whyDidYouRender = {
//   logOnDifferentValues: false,
// };

export default connect(mapStateToProps, mapDispatchToProps)(EditorHeader);
