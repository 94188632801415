import React, { CSSProperties } from 'react';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TreeItem, Props as TreeItemProps } from './TreeItem';
import { iOS } from '../../utilities';

interface Props extends TreeItemProps {
  id: string;
}

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

export function SortableTreeItem({ depth, id, ...props }: Props) {
  const {
    attributes,
    isDragging,
    isOver,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform) || '',
    transition,
    borderColor: `${isOver && !isDragging ? 'pink' : 'unset'}`,
  };

  // console.log(isOver, isDragging, id, 'drop');

  return (
    <TreeItem
      className={isOver && !isDragging ? 'animate-pulse' : ''}
      depth={depth}
      disableInteraction={isSorting}
      disableSelection={iOS}
      ghost={isDragging}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      ref={setDraggableNodeRef}
      style={style}
      wrapperRef={setDroppableNodeRef}
      {...props}
    />
  );
}
