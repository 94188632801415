import { GridDefaults } from 'constants/WidgetConstants';

export default {
  widgetName: 'MainContainer',
  backgroundColor: 'none',
  rightColumn: 1242,
  snapColumns: GridDefaults.DEFAULT_GRID_COLUMNS,
  widgetId: '0',
  topRow: 0,
  bottomRow: 1292,
  parentRowSpace: 1,
  type: 'CANVAS_WIDGET',
  detachFromLayout: true,
  minHeight: 1292,
  dynamicBindingPathList: {},
  parentColumnSpace: 1,
  leftColumn: 0,
  children: [],
};
