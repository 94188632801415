import { Colors, Color } from '../Colors';

export type ShadeColor = typeof darkShades[number] | typeof lightShades[number];

import { AlertIcons } from 'icons/AlertIcons';
import { IconProps } from 'constants/IconConstants';
import { JSXElementConstructor } from 'react';

export const IntentColors: Record<string, Color> = {
  primary: Colors.GREEN,
  success: Colors.PURPLE,
  secondary: Colors.BLACK_PEARL,
  danger: Colors.ERROR_RED,
  none: Colors.GEYSER_LIGHT,
  warning: Colors.JAFFA,
  successLight: Colors.GREEN,
};

export type Intent = typeof IntentColors[keyof typeof IntentColors];

export const IntentIcons: Record<Intent, JSXElementConstructor<IconProps>> = {
  primary: AlertIcons.SUCCESS,
  success: AlertIcons.SUCCESS,
  secondary: AlertIcons.INFO,
  danger: AlertIcons.ERROR,
  none: AlertIcons.INFO,
  warning: AlertIcons.WARNING,
};

export type buttonVariant = {
  main: string;
  light: string;
  dark: string;
  darker: string;
  darkest: string;
};

export type ButtonVariantColor = {
  primary: {
    bgColor?: Color;
    borderColor?: Color;
    hoverColor: Color;
    textColor: Color;
  };
  secondary: {
    bgColor?: Color;
    borderColor?: Color;
    hoverColor: Color;
    textColor: Color;
  };
  tertiary: {
    bgColor?: Color;
    borderColor?: Color;
    hoverColor: Color;
    textColor?: Color;
  };
};

export type ColorType = {
  overlayColor: string;
  button: {
    disabledText: ShadeColor;
    boxShadow: {
      default: {
        variant1: Color;
        variant2: Color;
        variant3: Color;
        variant4: Color;
        variant5: Color;
      };
    };
    disabled: {
      bgColor: Color;
      textColor: Color;
    };
    /**
     * PRIMARY style
     */
    primary: ButtonVariantColor;

    /**
     * WARNING style
     */
    warning: ButtonVariantColor;
    /**
     * DANGER style
     */
    danger: ButtonVariantColor;
    /**
     * INFO style
     */
    info: ButtonVariantColor;
    /**
     * SECONDARY style
     */
    secondary: ButtonVariantColor;
    /**
     * CUSTOM style
     */
    custom: {
      solid: {
        dark: {
          textColor: Color;
        };
        light: {
          textColor: Color;
        };
      };
    };
    /**
     * LINK button style
     */
    link: {
      main: Color;
      hover: Color;
      active: Color;
      disabled: Color;
    };
  };
  tertiary: buttonVariant;
  info: buttonVariant;
  success: buttonVariant;
  warning: buttonVariant;
  danger: buttonVariant;
  homepageBackground: string;
  selected: ShadeColor;
  card: {
    hoverBG: Color;
    hoverBGOpacity: number;
    hoverBorder: ShadeColor;
    iconColor: ShadeColor;
  };
  text: {
    normal: ShadeColor;
    heading: ShadeColor;
    highlight: ShadeColor;
  };
  icon: {
    normal: ShadeColor;
    hover: ShadeColor;
    active: ShadeColor;
  };
  appIcon: {
    normal: ShadeColor;
    background: ShadeColor;
  };
  menu: {
    background: ShadeColor;
    shadow: string;
  };
  menuItem: {
    normalText: ShadeColor;
    normalIcon: ShadeColor;
    hoverIcon: ShadeColor;
    hoverText: ShadeColor;
    hoverBg: ShadeColor;
    warning: {
      color: string;
      bg: string;
    };
  };
  navigationMenu: {
    contentActive: string;
    backgroundActive: string;
    contentInactive: string;
    backgroundInactive: string;
    label: string;
    warning: string;
    warningBackground: string;
  };
  colorSelector: {
    shadow: ShadeColor;
    checkmark: ShadeColor;
  };
  checkbox: {
    disabled: ShadeColor;
    unchecked: ShadeColor;
    disabledCheck: ShadeColor;
    normalCheck: ShadeColor;
    labelColor: ShadeColor;
  };
  dropdown: {
    header: {
      text: ShadeColor;
      disabledText: ShadeColor;
      defaultBg: ShadeColor;
      bg: ShadeColor;
      disabledBg: ShadeColor;
    };
    menu: {
      border: ShadeColor;
      bg: ShadeColor;
      hover: ShadeColor;
      text: ShadeColor;
      hoverText: ShadeColor;
      subText: ShadeColor;
    };
    menuShadow: string;
    selected: {
      text: ShadeColor;
      bg: ShadeColor;
      icon: ShadeColor;
      subtext?: ShadeColor;
    };
    hovered: {
      text: ShadeColor;
      bg: ShadeColor;
      icon: ShadeColor;
    };
    icon: ShadeColor;
  };
  toggle: {
    bg: ShadeColor;
    hover: {
      on: string;
      off: string;
    };
    disable: {
      on: string;
      off: ShadeColor;
    };
    disabledSlider: {
      on: ShadeColor;
      off: ShadeColor;
    };
    spinner: ShadeColor;
    spinnerBg: ShadeColor;
  };
  textInput: {
    disable: {
      bg: ShadeColor;
      text: ShadeColor;
      border: ShadeColor;
    };
    normal: {
      bg: ShadeColor;
      text: ShadeColor;
      border: ShadeColor;
    };
    placeholder: ShadeColor;
    helper: ShadeColor;
    icon: ShadeColor;
    readOnly: {
      bg: ShadeColor;
      border: ShadeColor;
      text: ShadeColor;
    };
    hover: {
      bg: ShadeColor;
    };
    caretColor: string;
  };
  menuBorder: ShadeColor;
  editableText: {
    color: ShadeColor;
    bg: ShadeColor;
    dangerBg: string;
  };
  radio: {
    disable: ShadeColor;
    border: ShadeColor;
    text: ShadeColor;
  };
  searchInput: {
    placeholder: ShadeColor;
    text: ShadeColor;
    border: ShadeColor;
    bg: ShadeColor;
    icon: {
      focused: ShadeColor;
      normal: ShadeColor;
    };
  };
  spinner: ShadeColor;
  tableDropdown: {
    bg: ShadeColor;
    selectedBg: ShadeColor;
    selectedText: ShadeColor;
    shadow: string;
  };
  tabs: {
    normal: ShadeColor;
    icon: ShadeColor;
    hover: ShadeColor;
    border: ShadeColor;
    countBg: ShadeColor;
    selected: string;
  };
  settingHeading: ShadeColor;
  table: {
    headerBg: ShadeColor;
    headerText: ShadeColor;
    rowData: ShadeColor;
    rowTitle: ShadeColor;
    border: ShadeColor;
    hover: {
      headerColor: ShadeColor;
      rowBg: ShadeColor;
      rowTitle: ShadeColor;
      rowData: ShadeColor;
    };
  };
  applications: {
    bg: ShadeColor;
    textColor: ShadeColor;
    orgColor: ShadeColor;
    iconColor: ShadeColor;
    hover: {
      bg: ShadeColor;
      textColor: ShadeColor;
      orgColor: ShadeColor;
    };
    cardMenuIcon: ShadeColor;
  };
  switch: {
    border: ShadeColor;
    bg: ShadeColor;
    hover: {
      bg: ShadeColor;
    };
    lightText: ShadeColor;
    darkText: ShadeColor;
  };
  queryTemplate: {
    bg: ShadeColor;
    color: ShadeColor;
  };
  profileDropdown: {
    name: ShadeColor;
    userName: ShadeColor;
  };
  modal: {
    bg: ShadeColor;
    headerText: ShadeColor;
    iconColor: string;
    iconBg: ShadeColor;
    user: {
      textColor: ShadeColor;
    };
    email: {
      message: ShadeColor;
      desc: ShadeColor;
    };
    manageUser: ShadeColor;
    scrollbar: ShadeColor;
    separator: ShadeColor;
    title: ShadeColor;
    link: string;
    hoverState: ShadeColor;
  };
  tagInput: {
    bg: ShadeColor;
    tag: {
      text: ShadeColor;
    };
    text: ShadeColor;
    placeholder: ShadeColor;
    shadow: string;
  };
  callout: {
    info: {
      color: string;
      bgColor: string;
    };
    error: {
      color: string;
      bgColor: string;
    };
    success: {
      color: string;
      bgColor: string;
    };
    danger: {
      color: string;
      bgColor: string;
    };
    warning: {
      color: string;
      bgColor: string;
    };
  };
  loader: {
    light: ShadeColor;
    dark: ShadeColor;
  };
  filePicker: {
    bg: ShadeColor;
    color: ShadeColor;
    progress: ShadeColor;
    shadow: {
      from: string;
      to: string;
    };
  };
  formFooter: {
    cancelBtn: ShadeColor;
  };
  toast: {
    undo: string;
    undoRedoColor: string;
    warningColor: string;
    dangerColor: string;
    textColor: string;
    bg: ShadeColor;
  };
  multiSwitch: {
    bg: ShadeColor;
    selectedBg: ShadeColor;
    text: ShadeColor;
    border: string;
  };
  apiPane: {
    bg: ShadeColor;
    text: ShadeColor;
    dividerBg: ShadeColor;
    iconHoverBg: ShadeColor;
    tabBg: ShadeColor;
    requestTree: {
      bg: string;
      header: {
        text: string;
        icon: string;
        bg: string;
      };
      row: {
        hoverBg: string;
        key: string;
        value: string;
      };
    };
    closeIcon: ShadeColor;
    responseBody: {
      bg: ShadeColor;
    };
    codeEditor: {
      placeholderColor: ShadeColor;
    };
    body: {
      text: string;
    };
    settings: {
      textColor: ShadeColor;
    };
    pagination: {
      label: ShadeColor;
      description: ShadeColor;
      stepTitle: ShadeColor;
      numberBg: string;
      bindingBg: ShadeColor;
      numberColor: ShadeColor;
    };
  };
  codeMirror: {
    background: {
      defaultState: string;
      hoverState: string;
    };
    text: string;
    dataType: {
      shortForm: string;
      fullForm: string;
    };
  };
  floatingBtn: any;
  auth: any;
  formMessage: Record<string, Record<Intent, string>>;
  header: {
    separator: string;
    appName: ShadeColor;
    background: string;
    deployToolTipBackground: string;
    deployToolTipText: ShadeColor;
    shareBtnHighlight: string;
    shareBtn: string;
    tabsHorizontalSeparator: string;
    tabText: string;
    activeTabBorderBottom: string;
    activeTabText: string;
  };
  guidedTour: {
    runButton: string;
    cancelButton: {
      color: string;
      borderColor: string;
      hoverBackgroundColor: string;
    };
    endButton: {
      backgroundColor: string;
      borderColor: string;
      hoverBackgroundColor: string;
    };
    endTourButton: {
      color: string;
      hoverColor: string;
    };
    card: {
      borderBottom: string;
      background: string;
    };
    stepCountBackground: string;
  };
  globalSearch: {
    containerBackground: string;
    activeSearchItemBackground: string;
    activeCategory: string;
    searchInputText: string;
    containerShadow: string;
    separator: string;
    searchItemHighlight: string;
    searchItemAltText: string;
    searchItemText: string;
    searchItemSubText: string;
    highlightedTextUnderline: string;
    documentationCtaBackground: string;
    documentationCtaText: string;
    emptyStateText: string;
    navigateUsingEnterSection: string;
    codeBackground: string;
    documentationCodeBackground: string;
    documentLink: string;
    helpBarText: string;
    helpBarBackground: string;
    helpButtonBackground: string;
    helpIcon: string;
    sectionTitle: string;
    navigateToEntityEnterkey: string;
    primaryBgColor: string;
    primaryTextColor: string;
    secondaryTextColor: string;
    primaryBorderColor: string;
    defaultIconsColor: string;
    snippets: {
      refinementPillsColor: string;
      refinementPillsBg: string;
      filterListBackground: string;
      filterBtnText: string;
      codeContainerBorder: string;
    };
    searchInputBorder: string;
  };
  gif: {
    overlay: string;
    text: string;
    iconPath: string;
    iconCircle: string;
  };
  comments: {
    profileUserName: string;
    threadTitle: string;
    commentBody: string;
    profileImageBorder: string;
    mention: string;
    threadContainerBorder: string;
    addCommentInputBorder: string;
    sendButton: string;
    addCommentInputBackground: string;
    pin: string;
    activeModeBackground: string;
    activeModeIcon: string;
    modeIcon: string;
    emojiPicker: string;
    resolved: string;
    unresolved: string;
    resolvedFill: string;
    unresolvedFill: string;
    resolvedPath: string;
    childCommentsIndent: string;
    commentBackground: string;
    contextMenuTrigger: string;
    contextMenuItemHover: ShadeColor;
    contextMenuIcon: ShadeColor;
    contextMenuIconHover: ShadeColor;
    contextMenuIconStroke: ShadeColor;
    contextMenuIconStrokeHover: ShadeColor;
    contextMenuTitle: ShadeColor;
    contextMenuTitleHover: ShadeColor;
    appCommentsHeaderTitle: ShadeColor;
    appCommentsClose: ShadeColor;
    viewLatest: string;
    commentTime: string;
    pinId: string;
    commentsFilter: string;
    appCommentsHeaderBorder: string;
    unreadIndicator: string;
    unreadIndicatorCommentCard: string;
    pinnedByText: string;
    pinnedThreadBackground: string;
    visibleThreadBackground: string;
    cardOptionsIcon: string;
    appCommentsPlaceholderText: string;
    cardHoverBackground: string;
    introTitle: string;
    introContent: string;
    modeIconCircleStroke: string;
    activeModeIconCircleStroke: string;
  };
  mentionSuggestion: {
    nameText: string;
    usernameText: string;
    hover: string;
  };
  reactionsComponent: {
    reactionBackground: string;
    reactionBackgroundActive: string;
    text: string;
    textActive: string;
  };
  treeDropdown: {
    targetBg: string;
    targetIcon: {
      normal: string;
      hover: string;
    };
    menuShadow: string;
    menuBg: {
      normal: ShadeColor;
      hover: ShadeColor;
      selected: ShadeColor;
    };
    menuText: {
      normal: ShadeColor;
      hover: ShadeColor;
      selected: ShadeColor;
    };
  };
  propertyPane: {
    title: ShadeColor;
    bg: ShadeColor;
    label: ShadeColor;
    jsIconBg: ShadeColor;
    buttonBg: ShadeColor;
    buttonText: ShadeColor;
    radioGroupBg: ShadeColor;
    radioGroupText: ShadeColor;
    deleteIconColor: string;
    zoomButtonBG: ShadeColor;
    activeButtonText: ShadeColor;
    jsButtonHoverBG: ShadeColor;
    dropdownSelectBg: ShadeColor;
    multiDropdownBoxHoverBg: ShadeColor;
    iconColor: ShadeColor;
    ctaTextColor: string;
    ctaBackgroundColor: string;
    ctaLearnMoreTextColor: string;
    connections: {
      error: string;
      connectionsCount: ShadeColor;
      optionBg: string;
    };
  };
  scrollbar: string;
  scrollbarBG: string;
  debugger: {
    background: string;
    messageTextColor: string;
    time: string;
    label: string;
    entity: string;
    entityLink: string;
    evalDebugButton: {
      hover: string;
      active: string;
    };
    inspectElement: {
      color: string;
    };
    floatingButton: {
      background: string;
      color: string;
      shadow: string;
      errorCount: string;
      noErrorCount: string;
      warningCount: string;
    };
    blankState: {
      shortcut: string;
      color: string;
    };
    info: {
      borderBottom: string;
    };
    warning: {
      borderBottom: string;
      backgroundColor: string;
      iconColor: string;
      hoverIconColor: string;
    };
    error: {
      borderBottom: string;
      backgroundColor: string;
      iconColor: string;
      hoverIconColor: string;
    };
    jsonIcon: string;
    message: string;
  };
  helpModal: {
    itemHighlight: string;
    background: string;
  };
  mentionsInput: Record<string, string>;
  showcaseCarousel: Record<string, string>;
  displayImageUpload: Record<string, string>;
  notifications: Record<string, string>;
  widgetGroupingContextMenu: {
    border: string;
    actionActiveBg: string;
  };
  actionSidePane: {
    noConnections: string;
    noConnectionsText: string;
    connectionBorder: string;
    connectionHover: string;
    collapsibleIcon: string;
  };
  tabItemBackgroundFill: {
    highlightBackground: string;
    highlightTextColor: string;
    textColor: string;
  };
  pagesEditor: {
    iconColor: string;
  };
  numberedStep: {
    line: string;
  };
  gitSyncModal: GitSyncModalColors;
  editorBottomBar: {
    background: string;
    buttonBackgroundHover: string;
    branchBtnText: string;
  };
  link: string;
  welcomePage?: {
    text: string;
  };
  settings: {
    link: string;
  };
};

export type GitSyncModalColors = typeof gitSyncModal;

export const darkShades = [
  '#1A191C',
  '#232324',
  '#262626',
  '#2B2B2B',
  '#404040',
  '#6D6D6D',
  '#9F9F9F',
  '#D4D4D4',
  '#E9E9E9',
  '#FFFFFF',
  '#157A96',
  '#090707',
  '#FFDEDE',
] as const;

export const lightShades = [
  '#ffffff',
  '#F7F7F7',
  '#F0F0F0',
  '#E8E8E8',
  '#C5C5C5',
  '#A9A7A7',
  '#939090',
  '#716E6E',
  '#4B4848',
  '#302D2D',
  '#090707',
  '#FFFFFF',
  '#6A86CE',
  '#E0DEDE',
  '#EBEBEB',
  '#858282',
  '#000000',
  '#F86A2B',
  '#FFDEDE',
  '#575757',
] as const;

export const editorBottomBar = {
  background: Colors.WHITE,
  buttonBackgroundHover: Colors.Gallery,
  branchBtnText: Colors.CHARCOAL,
};

export const gitSyncModal = {
  menuBackgroundColor: Colors.ALABASTER_ALT,
  separator: Colors.ALTO2,
  closeIcon: 'rgba(29, 28, 29, 0.7);',
};

export const tabItemBackgroundFill = {
  highlightBackground: Colors.Gallery,
  highlightTextColor: Colors.CODE_GRAY,
  textColor: Colors.CHARCOAL,
};

export const notifications = {
  time: '#858282',
  listHeaderTitle: '#090707',
  markAllAsReadButtonBackground: '#f0f0f0',
  markAllAsReadButtonText: '#716E6E',
  unreadIndicator: '#F86A2B',
  bellIndicator: '#E22C2C',
  label: '#858282',
};

export const displayImageUpload = {
  background: '#AEBAD9',
  label: '#457AE6',
};

export const showcaseCarousel = {
  activeStepDot: '#F86A2B',
  inactiveStepDot: '#FEEDE5',
};

export const reactionsComponent = {
  reactionBackground: lightShades[2],
  reactionBackgroundActive: '#FEEDE5',
  text: lightShades[7],
  textActive: '#FF5800',
  borderActive: '#FF5800',
};

export const mentionSuggestion = {
  nameText: '#090707',
  usernameText: '#716E6E',
  hover: '#EBEBEB',
};

export const pagesEditor = {
  iconColor: '#A2A6A8',
};

export const comments = {
  introTitle: '#090707',
  introContent: '#716E6E',
  commentsFilter: '#6A86CE',
  profileUserName: darkShades[11],
  threadTitle: darkShades[8],
  commentBody: darkShades[8],
  profileImageBorder: Colors.JAFFA_DARK,
  mention: '#F86A2B',
  threadContainerBorder: lightShades[5],
  addCommentInputBorder: lightShades[13],
  sendButton: '#6A86CE',
  addCommentInputBackground: '#FAFAFA',
  pin: '#EF4141',
  activeModeBackground: '#090707',
  emojiPicker: lightShades[5],
  resolved: Colors.BLACK,
  unresolved: lightShades[5],
  resolvedFill: Colors.BLACK,
  unresolvedFill: 'transparent',
  resolvedPath: Colors.WHITE,
  childCommentsIndent: lightShades[13],
  commentBackground: lightShades[2],
  contextMenuTrigger: darkShades[6],
  contextMenuItemHover: lightShades[2],
  contextMenuIcon: darkShades[6],
  contextMenuIconHover: darkShades[11],
  contextMenuIconStroke: darkShades[6],
  contextMenuIconStrokeHover: darkShades[11],
  contextMenuTitle: lightShades[8],
  contextMenuTitleHover: darkShades[11],
  appCommentsHeaderTitle: darkShades[11],
  appCommentsClose: lightShades[15],
  viewLatest: '#F86A2B',
  commentTime: lightShades[7],
  pinId: lightShades[8],
  appCommentsHeaderBorder: lightShades[3],
  unreadIndicator: '#E00D0D',
  unreadIndicatorCommentCard: '#F86A2B',
  pinnedByText: lightShades[7],
  pinnedThreadBackground: '#FFFAE9',
  visibleThreadBackground: '#FBEED0',
  cardOptionsIcon: '#777272',
  appCommentsPlaceholderText: lightShades[8],
  activeModeIcon: '#F0F0F0',
  modeIcon: '#6D6D6D',
  cardHoverBackground: '#FBEED0',
  modeIconCircleStroke: '#222222',
  activeModeIconCircleStroke: '#090707',
};

export const auth = {
  background: lightShades[11],
  cardBackground: lightShades[0],
  btnPrimary: Colors.CRUSTA,
  inputBackground: lightShades[11],
  headingText: darkShades[11],
  link: Colors.CRUSTA,
  text: '#000',
  placeholder: lightShades[8],
  socialBtnText: '#000',
  socialBtnBorder: lightShades[13],
  socialBtnHighlight: lightShades[2],
};

export const formMessage = {
  background: {
    danger: 'rgba(226,44,44,0.08)',
    success: '#172320',
    warning: 'rgba(224, 179, 14, 0.08)',
    lightSuccess: '#EFFFF4',
  },
  text: {
    danger: '#E22C2C',
    success: '#03B365',
    warning: '#E0B30E',
    lightSuccess: '#00693B',
  },
};

export const globalSearch = {
  containerBackground:
    'linear-gradient(0deg, rgba(43, 43, 43, 0.9), rgba(43, 43, 43, 0.9)), linear-gradient(119.61deg, rgba(35, 35, 35, 0.01) 0.43%, rgba(49, 49, 49, 0.01) 100.67%);',
  activeSearchItemBackground: '#EBEBEB',
  activeCategory: '#090707',
  searchInputText: '#090707',
  searchInputBorder: '#F86A2B',
  containerShadow: '0px 0px 32px 8px rgba(0, 0, 0, 0.25)',
  separator: '#424242',
  searchItemHighlight: '#fff',
  searchItemAltText: '#fff',
  searchItemText: '#090707',
  searchItemSubText: '#4B4848;',
  highlightedTextUnderline: '#03B365',
  helpBarText: '#C2C2C2',
  documentationCtaBackground: 'rgba(3, 179, 101, 0.1)',
  documentationCtaText: '#03B365',
  emptyStateText: '#A9A7A7',
  navigateUsingEnterSection: 'white',
  codeBackground: '#ffffff',
  documentationCodeBackground: '#f0f0f0',
  documentLink: '#F86A2B',
  helpBarBackground: '#000',
  helpButtonBackground: '#000',
  helpIcon: '#D4D4D4',
  sectionTitle: '#716E6E',
  navigateToEntityEnterkey: '#090707',
  primaryBgColor: '#ffffff',
  primaryTextColor: '#090707',
  secondaryTextColor: '#4b4848',
  primaryBorderColor: '#E0DEDE',
  defaultIconsColor: '#716e6e',
  snippets: {
    refinementPillsColor: '#4b4848',
    refinementPillsBg: 'white',
    filterListBackground: lightShades[0],
    filterBtnText: lightShades[8],
    filterBtnBg: '#FAFAFA',
    codeContainerBorder: '#E0DEDE',
  },
};

export const mentionsInput = {
  suggestionsListBackground: '#fff',
  suggestionsListBorder: 'rgba(0,0,0,0.15)',
  focusedItemBackground: '#cee4e5',
  itemBorderBottom: '#cee4e5',
  mentionBackground: '#cee4e5',
  mentionsInviteBtnPlusIcon: '#6A86CE',
};

export const actionSidePane = {
  noConnections: '#f0f0f0',
  noConnectionsText: '#e0dede',
  connectionBorder: 'rgba(0, 0, 0, 0.5)',
  connectionHover: '#6a86ce',
  collapsibleIcon: Colors.CODE_GRAY,
};

export const guidedTour = {
  runButton: '#f86a2b',
  cancelButton: {
    color: '#716e6e',
    borderColor: '#716e6e',
    hoverBackgroundColor: '#f1f1f1',
  },
  endButton: {
    backgroundColor: '#f22b2b',
    borderColor: '#f22b2b',
    hoverBackgroundColor: '#f34040',
  },
  endTourButton: {
    color: '#4b4848',
    hoverColor: '#928f8f',
  },
  card: {
    borderBottom: '#eeeeee',
    background: '#ffefdb',
  },
  stepCountBackground: '#090707',
};

export const numberedStep = {
  line: Colors.ALTO2,
  number: Colors.BLACK,
};
