import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { Datasource } from 'entities/Datasource';
import { QueryEditorRouteParams } from 'constants/routes';
import { QueryDrawerType } from 'reducers/uiReducers/globalSearchReducer';
import { setActionProperty } from 'actions/pluginActionActions';
import { QueryAction, SaaSAction } from 'entities/Action';
import {
  controlQueryDrawer,
  editGraphVisible,
} from 'actions/globalSearchActions';
import {
  getAction,
  getDatasourceByPluginId,
  getDBAndRemoteDatasources,
  getPluginImages,
  selectFilesForExplorer,
} from 'selectors/entitiesSelector';
import { getCurrentPageId, getPageById } from 'selectors/editorSelectors';
import { IconObliqueLine } from '@arco-design/web-react/icon';
import { Select, Tooltip } from '@arco-design/web-react';
import NameEditor from '../NameEditor';
import Svg from 'components/utils/Svg';
import IconSvg from 'components/icon';
import styled from 'styled-components';
import { find, get } from 'lodash';
import AI from '../AITool';

const IconWrap = styled.div`
  border-radius: 8px;
  width: 26px;
  height: 26px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  &:hover {
    background-color: #eceded;
  }
`;

type ReduxStateProps = {
  actionId: string;
  pluginImages: Record<string, string>;
  createTempleteQueryId: string;
  queryDrawerType: QueryDrawerType;
  showGraph: boolean;
  dataSources: Datasource[];
};

function QueryDrawerHeader(props: ReduxStateProps) {
  const dispatch = useDispatch();

  const {
    actionId,
    createTempleteQueryId,
    pluginImages,
    queryDrawerType,
    showGraph,
  } = props;

  const files = useSelector(selectFilesForExplorer);
  const currentQuery = find(files, { entity: { id: createTempleteQueryId } });
  const currentQueryGroup = find(props.dataSources, {
    name: currentQuery?.group,
  });

  const currentPageId = useSelector(getCurrentPageId);
  const currentPage = useSelector(getPageById(currentPageId));

  const isSql = queryDrawerType === 'sql';
  const titleText = showGraph ? '切换到文本模式' : '切换到图形模式';

  const queryId = useSelector(
    (state: AppState) => state.ui.globalSearch.createTempleteQueryId
  );

  const currentAction = useSelector((state: AppState) =>
    getAction(state, queryId)
  );

  const handleChangeGraph = () => {
    dispatch(editGraphVisible(!showGraph));
  };

  const close = () => dispatch(controlQueryDrawer(false));
  const handleDataSourceSelectChange = (id) => {
    dispatch(
      setActionProperty({
        propertyName: 'datasource',
        value: find(props.dataSources, { id }),
        actionId,
      })
    );
  };

  function renderQueryHeaderLeft() {
    return (
      <div className="flex items-center">
        <IconWrap className="transition" onClick={close}>
          <IconSvg name="back" />
        </IconWrap>
        <div className="flex items-center ml-3 text-xs text-title-sub">
          <span>{currentPage?.name}</span>
          <IconObliqueLine className="mx-1" />
          <span className="font-bold">
            <NameEditor />
          </span>
        </div>
      </div>
    );
  }

  const isPatternModel = get(currentAction, 'isPatternModel', false);
  const GraphDisableTip = isPatternModel ? Tooltip : React.Fragment;

  return isSql ? (
    <div className="flex items-center justify-between w-full">
      {renderQueryHeaderLeft()}
      <div className="flex items-center">
        <AI />
        <GraphDisableTip
          {...(isPatternModel ? { content: '图形模式不支持数据库切换' } : {})}
        >
          <Select
            className="w-48"
            disabled={isPatternModel}
            onChange={handleDataSourceSelectChange}
            value={currentQueryGroup?.id}
          >
            {props.dataSources.map((source) => (
              <Select.Option key={source.id} value={source.id}>
                <Tooltip content={!isPatternModel && source.name}>
                  <div className="flex items-center w-full text-[12px]">
                    <span className="text-[20px]">
                      <IconSvg name={pluginImages[source.pluginId]} />
                    </span>
                    <span className="truncate ml-[8px]">{source.name}</span>
                  </div>
                </Tooltip>
              </Select.Option>
            ))}
          </Select>
        </GraphDisableTip>
        {/* <div
          className="flex items-center justify-start ml-5 cursor-pointer"
          onClick={handleChangeGraph}
        >
          {showGraph ? (
            <IconSvg className="mr-[8px]" name="code_square" />
          ) : (
            <Svg className="mr-[8px]" name="graph_view" />
          )}
          <div className="text-xs font-bold text-title-sub">{titleText}</div>
        </div> */}
      </div>
    </div>
  ) : (
    <div
      className="flex items-center justify-between w-full"
      onClick={handleChangeGraph}
    >
      {renderQueryHeaderLeft()}
      <AI />
    </div>
  );
}

const mapStateToProps = (
  state: AppState,
  props: RouteComponentProps<QueryEditorRouteParams>
): ReduxStateProps => {
  const {
    createTempleteQueryId,
    queryDrawerType,
    showGraph,
  } = state.ui.globalSearch;

  const { apiId, queryId } = props.match.params;
  const actionId = queryId || apiId || createTempleteQueryId;
  const action = getAction(state, actionId) as QueryAction | SaaSAction;

  return {
    actionId,
    pluginImages: getPluginImages(state),
    createTempleteQueryId,
    queryDrawerType,
    showGraph,
    dataSources: !!apiId
      ? getDatasourceByPluginId(state, action?.pluginId)
      : getDBAndRemoteDatasources(state),
  };
};

export default withRouter(connect(mapStateToProps)(QueryDrawerHeader));
