import {
  Button,
  Checkbox,
  Input,
  Message,
  Switch,
  Tag,
} from '@arco-design/web-react';
import {
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  includes,
  map,
  nth,
  uniqueId,
} from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Select } from '@arco-design/web-react';
import { IconMinus, IconPlus } from '@arco-design/web-react/icon';
import { sortType } from '../constants';
const Option = Select.Option;
const InputSearch = Input.Search;

class Group {
  op;
  groupOp;
  list: GroupList[];
  id;
  constructor() {
    this.op = 'and';
    this.groupOp = '';
    this.list = [new GroupList()];
    this.id = uniqueId();
  }
}

class GroupList {
  field;
  alias;
  sort;
  id;
  constructor() {
    this.id = uniqueId();
    this.sort = 'asc';
  }
}

export default function SortContent({ data, fields, meta, modal }) {
  const { fields: tableFields, sql, templateSQL } = get(meta, 'tableData');
  const { id, type } = data.previousData.nodeProperty;

  const [fieldsList, setFieldsList] = useState(cloneDeep(tableFields));

  useEffect(() => {
    if (meta.FormInfo?.selectedFields) {
      const selectedFieldsMap = [];
      forEach(meta.FormInfo?.selectedFields, (v) => {
        selectedFieldsMap.push(v.field);
      });
      forEach(fieldsList, (v) => {
        if (includes(selectedFieldsMap, v.field)) {
          v.checked = true;
        }
      });
      setFieldsList([...fieldsList]);
    }
  }, []);

  const sortID = useRef(meta.FormInfo?.sortID || uniqueId('util_')).current;
  const [groupData, setGroupData] = useState(
    meta.FormInfo?.groupData || [new Group()]
  );
  const { name } = fields;

  const queryParams = {
    inputs: [
      {
        id: id,
        sql: sql,
        tableName: name,
        templateSQL: templateSQL,
        type: type,
      },
    ],
    current: {
      type: 'sort',
      id: sortID,
      settings: JSON.stringify({
        select: fieldsList.map((v) => {
          const field = sortID + '.' + v.field;
          return {
            ...v,
            field,
          };
        }),
        orderBy: groupData[0].list,
      }),
    },
  };

  useEffect(() => {
    meta.FormInfo = {
      groupData,
      sortID,
    };
  }, [groupData, sortID]);

  return (
    <div className="filter-content">
      <div className="font-hm-16-bold pb-[20px]">排序</div>
      <div className="flex">
        <div className="group w-[100%]">
          <div className="font-hm-16-bold pb-[20px]">条件设置</div>

          {groupData.map((v) => (
            <div
              className="flex item my-[10px] flex-col border mr-[10px] rounded"
              key={v.id}
            >
              <div className="px-[10px] pt-[10px] flex">
                <span className="w-[154px] mr-[15px]">选择字段</span>
                <span className="w-[154px]">排序方式</span>
              </div>
              {v.list.map((group) => {
                const defaultValue =
                  find(fieldsList, {
                    field: nth(group.field?.split('.') || [], -1),
                  }) || get(fieldsList, '[0]');
                group.field = sortID + '.' + defaultValue.field;
                group.alias = defaultValue.alias;
                return (
                  <div className="group-Item flex m-[10px]" key={group.id}>
                    <div className="condition flex w-[70%]">
                      <Select
                        defaultValue={defaultValue}
                        onChange={(value) => {
                          group.field = sortID + '.' + value.field;
                          group.alias = value.alias;
                          setGroupData([...groupData]);
                        }}
                        placeholder="请选择"
                        style={{ width: 154 }}
                      >
                        {fieldsList.map((option) => (
                          <Option key={option.field} value={option}>
                            {option.alias}
                          </Option>
                        ))}
                      </Select>
                      <Select
                        defaultValue={group.sort || 'asc'}
                        onChange={(vlaue) => {
                          group.sort = vlaue;
                          setGroupData([...groupData]);
                        }}
                        style={{ width: 154 }}
                      >
                        {sortType.map((r) => (
                          <Option key={r.code} value={r.code}>
                            {r.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="config-group flex w-[30%]">
                      <div className="item">
                        <IconPlus
                          onClick={() => {
                            v.list.push(new GroupList());
                            setGroupData(
                              filter(groupData, (v) => v.list.length) as Group[]
                            );
                          }}
                        />
                      </div>
                      <div className="item">
                        <IconMinus
                          onClick={() => {
                            if (v.list.length <= 1) {
                              return Message.warning('至少保留一个条件');
                            }
                            v.list = filter(
                              v.list,
                              (item) => group.id !== item.id
                            );
                            setGroupData(
                              filter(groupData, (v) => v.list.length) as Group[]
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
          <div className="mt-[30px] text-[12px] text-[#666]">
            <p>
              说明：字段默认为文本类型，如需对数值和日期字段进行排序，需先转换对应的字段类型
            </p>
            <p>例如：对数据进行排序，需要先用数据加工组件转换成数值类型</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="mr-[30px]"
          onClick={() => {
            modal.current.close();
            modal.ok(queryParams);
          }}
          type="primary"
        >
          提交
        </Button>
        <Button onClick={() => modal.current.close()} type="secondary">
          取消
        </Button>
      </div>
    </div>
  );
}
