import Api from 'api/Api';

import axios, { AxiosResponse } from 'axios';
import { ApiResponse } from './ApiResponses';

export class UserCenterApi extends Api {
  static url = 'v1/companies';

  static getCompanyProfile() {
    return Api.get(UserCenterApi.url);
  }

  static registerCompanyInfo(params): Promise<ApiResponse> {
    return Api.post(UserCenterApi.url, params);
  }

  static getUserInterest() {
    return Api.get('/v1/plan/list');
  }

  static getInterestInfo() {
    return Api.get('/v1/user-plan/info');
  }

  static getOrderList() {
    return Api.get('/v1/users/orders');
  }
}
