import React, { useCallback, useEffect, useState } from 'react';
import { Message, Tag } from '@arco-design/web-react';
import { useMutation, useQuery } from 'react-query';
import { get } from 'lodash';

import {
  EnterpriseAuthContainer,
  AuthHeader,
  AuthMain,
  AuthContent,
  AuthBrand,
} from '../styles';
import { UserCenterApi } from 'api/UserCenterApi';
import { AuthStatusEnum, AUTH_STATUS } from '../constants';
import { validateResponseHelper } from 'pages/Editor/AppSysConfigDrawer/utils';
import { EnterpriseAuthForm } from '../interface';
import { AuthForm } from './AuthForm';

export function EnterpriseAuthentication() {
  const [
    initFormValues,
    setInitFormValues,
  ] = useState<EnterpriseAuthForm | null>(null);

  const registerCompanyMutation = useMutation(
    UserCenterApi.registerCompanyInfo
  );

  const queryCompanyInfo = useQuery({
    queryKey: ['query_company_info'],
    queryFn: () => UserCenterApi.getCompanyProfile(),
  });

  const curCompanyInfo = get(queryCompanyInfo, 'data.data', null);
  const authCurStatus = get(curCompanyInfo, 'status', '0');

  const handleRegisterCompany = useCallback(
    async (fieldValues: EnterpriseAuthForm) => {
      try {
        const result = await registerCompanyMutation.mutateAsync(fieldValues);

        if (validateResponseHelper(result?.responseMeta)) {
          Message.success('信息提交成功');
          // location.reload(); // to refresh current page
          queryCompanyInfo.refetch();
        }
      } catch (err) {
        console.error(err);
      }
    },
    []
  );

  useEffect(() => {
    if (curCompanyInfo) {
      setInitFormValues(curCompanyInfo);
    }
  }, [curCompanyInfo]);

  return (
    <EnterpriseAuthContainer>
      <AuthHeader>
        <AuthBrand>
          <span className="text-[#B15306]">限时福利:</span>
          <span className="pl-2 text-white">
            认证企业账号，即可获得免费部署软件资格
          </span>
        </AuthBrand>
        {authCurStatus === AuthStatusEnum.authenticating && (
          <div className="py-[13px] text-[14px] text-[#FC5800] text-center bg-[#FFEDDA]">
            企业资格认证中，需要1-3天，如有任间题请联系客服: 027-87705383
          </div>
        )}
      </AuthHeader>
      <AuthMain>
        <AuthContent>
          <header>
            <div>
              <span className="font-bold text-[#3D3D3D]">企业认证</span>
              <Tag
                className="ml-4"
                size="small"
                style={{
                  color: AUTH_STATUS[authCurStatus].textColor,
                  background: AUTH_STATUS[authCurStatus].bgColor,
                }}
              >
                {AUTH_STATUS[authCurStatus].label}
              </Tag>
            </div>
          </header>
          <AuthForm
            initFormValues={initFormValues}
            loading={registerCompanyMutation.isLoading}
            onRegisterCompany={handleRegisterCompany}
          />
        </AuthContent>
      </AuthMain>
    </EnterpriseAuthContainer>
  );
}
