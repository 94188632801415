import * as styledComponents from 'styled-components';
import { Colors, Color } from '../Colors';
import * as FontFamilies from '../Fonts';
import tinycolor from 'tinycolor2';
import { Classes } from '@blueprintjs/core';

import { Typography, TypographyKeys } from '../typography';
import { getColorWithOpacity } from './utils';
import { dark } from './dark';
import { IntentColors, Intent, IntentIcons } from './common';
export type FontFamily = typeof FontFamilies[keyof typeof FontFamilies];
export { light } from './light';
export { dark, IntentColors, getColorWithOpacity };
export type { Intent, IntentIcons };

const {
  createGlobalStyle,
  css,
  default: styled,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export enum Skin {
  LIGHT,
  DARK,
}

export const truncateTextUsingEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const getTypographyByKey = (
  props: Record<string, any>,
  key: TypographyKeys
) => `
  font-weight: ${props.theme.typography[key].fontWeight};
  font-size: ${props.theme.typography[key].fontSize}px;
  line-height: ${props.theme.typography[key].lineHeight}px;
  letter-spacing: ${props.theme.typography[key].letterSpacing}px;
`;

export const BlueprintControlTransform = css`
  && {
    .${Classes.CONTROL}.${Classes.DISABLED} {
      color: ${Colors.GREY_8};
    }
    .${Classes.CONTROL} {
      & input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${(props) => props.theme.colors.primaryOld};
        box-shadow: none;
        border: 1px solid ${(props) => props.theme.colors.primaryOld};
      }
      input:disabled ~ .${Classes.CONTROL_INDICATOR} {
        opacity: 0.5;
      }
      & input:disabled:not(:checked) ~ .${Classes.CONTROL_INDICATOR} {
        border: 1px solid ${Colors.GREY_5};
      }
    }

    .${Classes.SWITCH} {
      & .${Classes.CONTROL_INDICATOR} {
        &::before {
          box-shadow: -2px 2px 5px rgba(67, 86, 100, 0.1);
        }
      }
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        &::before {
          left: calc(105% - 1em);
        }
      }
      input:not(:checked) ~ .${Classes.CONTROL_INDICATOR} {
        background: ${Colors.GREY_3};
        border: 1px solid ${Colors.GREY_5};
      }
    }

    .${Classes.CONTROL_INDICATOR} {
      &::before {
        position: absolute;
        left: -1px;
        top: -1px;
      }
    }
  }
`;

export const invisible = css`
  && > * {
    opacity: 0.6;
  }
`;

export const disable = css`
  & {
    cursor: not-allowed;

    & > * {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;

export const BlueprintCSSTransform = css`
  &&&& {
    .${Classes.BUTTON} {
      box-shadow: none;
      border-radius: 0;
      background: white;
    }
    .${Classes.INTENT_PRIMARY} {
      background: ${IntentColors.primary};
    }
    .${Classes.INTENT_SUCCESS} {
      background: ${IntentColors.success};
    }
    .${Classes.INTENT_DANGER} {
      background: ${IntentColors.danger};
    }
    .${Classes.INTENT_WARNING} {
      background: ${IntentColors.warning};
    }
  }
`;

export const darken = (color: Color, intensity: number) => {
  return new tinycolor(color).darken(intensity).toString();
};

export const darkenHover = (color: Color) => {
  return darken(color, 8);
};

export const darkenActive = (color: Color) => {
  return darken(color, 16);
};

const getButtonHoverAndActiveStyles = (color: Color, filled = true) => {
  return css`
    background: ${color};
    border-color: ${filled ? color : 'auto'};
    color: ${filled ? Colors.WHITE : 'auto'};
    &:hover {
      background: ${darkenHover(color)};
      border-color: ${darkenHover(color)};
      box-shadow: none;
    }
    &:active {
      background: ${darkenActive(color)};
      border-color: ${darkenActive(color)};
      box-shadow: none;
    }
  `;
};

export const BlueprintButtonIntentsCSS = css`
  &&.${Classes.BUTTON} {
    box-shadow: none;
    display: flex;
    border-width: 1px;
    border-style: solid;
    outline: none;
    min-width: 50px;
    color: ${IntentColors.secondary};
    border-color: ${IntentColors.none};
    & span.bp3-icon {
      color: ${IntentColors.none};
    }
    & span {
      font-weight: ${(props) => props.theme.fontWeights[3]};
    }
    background: ${Colors.WHITE};
  }
  &&&.${Classes.BUTTON}.${Classes.INTENT_PRIMARY}:not(.${Classes.MINIMAL}) {
    background: ${IntentColors.primary};
    ${getButtonHoverAndActiveStyles(IntentColors.primary)}
  }
  &&&.${Classes.BUTTON}.bp3-intent-secondary:not(.${Classes.MINIMAL}) {
    background: ${IntentColors.secondary};
    ${getButtonHoverAndActiveStyles(IntentColors.secondary)}
  }
  &&&.${Classes.BUTTON}.${Classes.INTENT_DANGER}:not(.${Classes.MINIMAL}) {
    background: ${IntentColors.danger};
    ${getButtonHoverAndActiveStyles(IntentColors.danger)}
  }
  &&&.${Classes.BUTTON}.${Classes.INTENT_SUCCESS}:not(.${Classes.MINIMAL}) {
    background: ${IntentColors.success};
    ${getButtonHoverAndActiveStyles(IntentColors.success)}
  }
  &&&.${Classes.BUTTON}.${Classes.INTENT_WARNING}:not(.${Classes.MINIMAL}) {
    background: ${IntentColors.warning};
    ${getButtonHoverAndActiveStyles(IntentColors.warning)}
  }

  &&.${Classes.MINIMAL}.${Classes.BUTTON} {
    border: none;
    border-color: ${IntentColors.none};
    & span.bp3-icon {
      color: ${IntentColors.none};
    }
  }
  &&&.${Classes.MINIMAL}.${Classes.INTENT_PRIMARY} {
    color: ${IntentColors.primary};
    border-color: ${IntentColors.primary};
  }
  &&&.${Classes.MINIMAL}.bp3-intent-secondary {
    color: ${IntentColors.secondary};
    border-color: ${IntentColors.secondary};
  }
  &&&.${Classes.MINIMAL}.${Classes.INTENT_DANGER} {
    color: ${IntentColors.danger};
    border-color: ${IntentColors.danger};
  }
  &&&.${Classes.MINIMAL}.${Classes.INTENT_WARNING} {
    color: ${IntentColors.warning};
    border-color: ${IntentColors.warning};
  }
  &&&.${Classes.MINIMAL}.${Classes.INTENT_SUCCESS} {
    color: ${IntentColors.success};
    border-color: ${IntentColors.success};
  }

  &&&&&&.${Classes.DISABLED} {
    color: ${Colors.SLATE_GRAY};
    background: ${Colors.MERCURY};
    border-color: ${Colors.MERCURY};
  }
`;

export const BlueprintInputTransform = css`
  && {
    .${Classes.INPUT} {
      border-radius: ${(props) => props.theme.radii[1]}px;
      box-shadow: none;
      border: ${(props) => getBorderCSSShorthand(props.theme.borders[2])};
      &:focus {
        border: ${(props) => getBorderCSSShorthand(props.theme.borders[2])};
        box-shadow: none;
      }
    }
  }
`;

export const iconSizes = {
  XXS: 8,
  XS: 10,
  SMALL: 12,
  MEDIUM: 14,
  LARGE: 15,
  XL: 16,
  XXL: 18,
  XXXL: 20,
  XXXXL: 22,
};

type IconSizeType = typeof iconSizes;

export type ThemeBorder = {
  thickness: number;
  style: 'dashed' | 'solid';
  color: Color;
};

type PropertyPaneTheme = {
  width: number;
  height: number;
  dividerColor: Color;
  titleHeight: number;
  connectionsHeight: number;
};

export type NestedObjectOrArray<T> =
  | Record<string, T | T[] | Record<string, T | T[]>>
  | T
  | T[];
export type Theme = {
  radii: Array<number>;
  fontSizes: Array<number>;
  drawerWidth: string;
  spaces: Array<number>;
  fontWeights: Array<number>;
  colors: any;
  typography: Typography;
  lineHeights: Array<number>;
  fonts: {
    code: FontFamily;
    text: FontFamily;
  };
  borders: ThemeBorder[];
  evaluatedValuePopup: {
    width: number;
    height: number;
  };
  propertyPane: PropertyPaneTheme;
  headerHeight: string;
  smallHeaderHeight: string;
  defaultHeaderHeight: string;
  bottomBarHeight: string;
  integrationsPageUnusableHeight: string;
  backBanner: string;
  homePage: any;
  sidebarWidth: string;
  canvasBottomPadding: number;
  navbarMenuHeight: string;
  navbarMenuLineHeight: string;
  actionsBottomTabInitialHeight: string;
  sideNav: {
    minWidth: number;
    maxWidth: number;
    bgColor: Color;
    fontColor: Color;
    activeItemBGColor: Color;
    navItemHeight: number;
  };
  card: {
    minWidth: number;
    minHeight: number;
    titleHeight: number;
    divider: ThemeBorder;
  };
  dropdown: {
    [Skin.LIGHT]: {
      hoverBG: ShadeColor;
      hoverText: ShadeColor;
      inActiveBG: ShadeColor;
      inActiveText: ShadeColor;
      border: Color;
      background: Color;
    };
    [Skin.DARK]: {
      hoverBG: ShadeColor;
      hoverText: ShadeColor;
      inActiveBG: ShadeColor;
      inActiveText: ShadeColor;
      border: Color;
      background: Color;
    };
  };
  authCard: {
    width: number;
    dividerSpacing: number;
    formMessageWidth: number;
  };
  shadows: string[];
  widgets: {
    tableWidget: {
      selectHighlightColor: Color;
    };
  };
  pageContentWidth: number;
  tabPanelHeight: number;
  alert: Record<string, { color: Color }>;
  lightningMenu: {
    [Skin.DARK]: {
      default: {
        color: Color;
        background: Color;
      };
      active: {
        color: Color;
        background: Color;
      };
      hover: {
        color: Color;
        background: Color;
      };
      none: {
        color: string;
        background: string;
      };
    };
    [Skin.LIGHT]: {
      default: {
        color: string;
        background: string;
      };
      active: {
        color: string;
        background: string;
      };
      hover: {
        color: string;
        background: string;
      };
      none: {
        color: string;
        background: string;
      };
    };
  };
  iconSizes: IconSizeType;
  actionSidePane: {
    width: number;
  };
  onboarding: {
    statusBarHeight: number;
  };
  settings: {
    footerHeight: number;
    footerShadow: string;
    linkBg: string;
  };
};

export const getBorderCSSShorthand = (border?: ThemeBorder): string => {
  const values: string[] = [];
  if (border) {
    for (const [key, value] of Object.entries(border)) {
      values.push(key === 'thickness' ? value + 'px' : value.toString());
    }
  }
  return values.join(' ');
};

export const labelStyle = css`
  font-weight: ${(props) => props.theme.fontWeights[3]};
`;

export const hideScrollbar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
`;

export const thinScrollbar = css`
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: ${getColorWithOpacity(Colors.CHARCOAL, 0.5)};
      border-radius: 10px;
    }
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${getColorWithOpacity(Colors.CHARCOAL, 0.5)};
  }
`;
// export const adsTheme: any = {
//   space: [0, 3, 14, 7, 16, 11, 26, 10, 4, 26, 30, 36, 4, 6, 11],
// };
// 3, 7, 11, 26

export const smallButton = css`
  font-size: ${(props) => props.theme.typography.btnSmall.fontSize}px;
  font-weight: ${(props) => props.theme.typography.btnSmall.fontWeight};
  line-height: ${(props) => props.theme.typography.btnSmall.lineHeight}px;
  letter-spacing: ${(props) => props.theme.typography.btnSmall.letterSpacing}px;
`;

export const mediumButton = css`
  font-size: ${(props) => props.theme.typography.btnMedium.fontSize}px;
  font-weight: ${(props) => props.theme.typography.btnMedium.fontWeight};
  line-height: ${(props) => props.theme.typography.btnMedium.lineHeight}px;
  letter-spacing: ${(props) =>
    props.theme.typography.btnMedium.letterSpacing}px;
`;

export const largeButton = css`
  font-size: ${(props) => props.theme.typography.btnLarge.fontSize}px;
  font-weight: ${(props) => props.theme.typography.btnLarge.fontWeight};
  line-height: ${(props) => props.theme.typography.btnLarge.lineHeight}px;
  letter-spacing: ${(props) => props.theme.typography.btnLarge.letterSpacing}px;
`;

export const appColors = [
  '#FFEFDB',
  '#D9E7FF',
  '#FFDEDE',
  '#E3DEFF',
  '#C7F3E3',
  '#F1DEFF',
  '#F4FFDE',
  '#C7F3F0',
  '#C2DAF0',
  '#F5D1D1',
  '#ECECEC',
  '#CCCCCC',
  '#F3F1C7',
  '#E4D8CC',
  '#EAEDFB',
  '#D6D1F2',
  '#FBF4ED',
  '#FFEBFB',
] as const;

export type AppColorCode = typeof appColors[number];

const darkShades = [
  '#1A191C',
  '#232324',
  '#262626',
  '#2B2B2B',
  '#404040',
  '#6D6D6D',
  '#9F9F9F',
  '#D4D4D4',
  '#E9E9E9',
  '#FFFFFF',
  '#157A96',
  '#090707',
  '#FFDEDE',
] as const;

const lightShades = [
  '#ffffff',
  '#F7F7F7',
  '#F0F0F0',
  '#E8E8E8',
  '#C5C5C5',
  '#A9A7A7',
  '#939090',
  '#716E6E',
  '#4B4848',
  '#302D2D',
  '#090707',
  '#FFFFFF',
  '#6A86CE',
  '#E0DEDE',
  '#EBEBEB',
  '#858282',
  '#000000',
  '#F86A2B',
  '#FFDEDE',
  '#575757',
] as const;

type ShadeColor = typeof darkShades[number] | typeof lightShades[number];
export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
