import Api from 'api/Api';
import { AxiosPromise } from 'axios';
import { ApiResponse } from 'api/ApiResponses';

export interface FetchPrintResponse extends ApiResponse {
  data: string;
}

class PrintAPI {
  static baseUrl = 'v1/pdf';

  static getPrintPDF(body = {}): AxiosPromise<FetchPrintResponse> {
    return Api.post(
      `${PrintAPI.baseUrl}/creatReport`,
      body,
      {},
      { responseType: 'blob' }
    );
  }
}

export default PrintAPI;
