import React, { useState, useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabComponent, TabProp } from 'components/ads/Tabs';
import { getCurrentDebuggerTab } from 'selectors/debuggerSelectors';
import AnalyticsUtil from 'utils/AnalyticsUtil';
import { DEBUGGER_TAB_KEYS } from './Debugger/helpers';
import styled from 'styled-components';

const Warp = styled.div`
  height: 100%;

  .react-tabs__tab-list-wrap {
    margin: 0;
    padding: 0 24px;
    cursor: move;
  }
`;

type EntityBottomTabsProps = {
  defaultIndex: number;
  tabs: TabProp[];
  responseViewer?: boolean;
  onSelect?: (tab: any) => void;
  selectedTabIndex?: number; // this is used in the event you want to directly control the index changes.
  classNames?: string;
  tabRightContent?: ReactNode;
  spin?: boolean;
  skeleton?: JSX.Element;
};

// Using this if there are debugger related tabs
function EntityBottomTabs(props: EntityBottomTabsProps) {
  const [selectedIndex, setSelectedIndex] = useState(props.defaultIndex);
  const currentTab = useSelector(getCurrentDebuggerTab);
  const dispatch = useDispatch();
  const onTabSelect = (index: number) => {
    // dispatch(setCurrentTab(props.tabs[index].key));
    props.onSelect && props.onSelect(props.tabs[index]);
    setIndex(index);
  };

  const setIndex = (index: number) => {
    const tabKey = props.tabs[index]?.key;
    setSelectedIndex(index);
    if (Object.values<string>(DEBUGGER_TAB_KEYS).includes(tabKey)) {
      AnalyticsUtil.logEvent('DEBUGGER_TAB_SWITCH', {
        tabName: tabKey,
      });
    }
  };

  useEffect(() => {
    const index = props.tabs.findIndex((tab) => tab.key === currentTab);
    if (index >= 0) {
      setIndex(index);
    } else {
      setIndex(props.defaultIndex);
    }
  }, [currentTab]);

  return (
    <Warp>
      <div className={props?.classNames} style={{ height: '100%' }}>
        <TabComponent
          onSelect={onTabSelect}
          responseViewer={props.responseViewer}
          selectedIndex={
            props.selectedTabIndex ? props.selectedTabIndex : selectedIndex
          }
          skeleton={props.skeleton}
          spin={props.spin}
          tabRightContent={props.tabRightContent}
          tabs={props.tabs}
        />
      </div>
    </Warp>
  );
}

export default EntityBottomTabs;
