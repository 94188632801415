import React, {
  ReactNode,
  Context,
  createContext,
  memo,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import isEqual from 'fast-deep-equal';
import { WidgetProps } from 'utils/widgetBridge';
import { getCanvasSnapRows } from 'utils/WidgetPropsUtils';
import {
  MAIN_CONTAINER_WIDGET_ID,
  GridDefaults,
} from 'constants/WidgetConstants';
import { calculateDropTargetRows } from './DropTargetUtils';
import DragLayerComponent from './DragLayerComponent';
import { AppState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  useShowPropertyPane,
  useCanvasSnapRowsUpdateHook,
} from 'utils/hooks/dragResizeHooks';
import { getOccupiedSpacesSelectorForContainer } from 'selectors/editorSelectors';
import { useWidgetSelection } from 'utils/hooks/useWidgetSelection';
import { getDragDetails } from 'sagas/selectors';
import { Steps } from '@arco-design/web-react';
import {
  IconCheckCircle,
  IconLoading,
  IconThumbUp,
  IconThunderbolt,
} from '@arco-design/web-react/icon';
import {
  setPanelState,
  toggleInOnboardingWidgetSelection,
} from 'actions/onboardingActions';
import { PANELSTATE } from 'pages/Editor/sideNavContext';
import {
  useAppWideAndOtherDatasource,
  useGoToAddDatasource,
} from 'pages/Editor/Explorer/hooks';
import { selectFilesForExplorer } from 'selectors/entitiesSelector';
import { getIsFirstTimeUserOnboardingEnabled } from 'selectors/onboardingSelectors';
import { builderURL } from 'RouteBuilder';
import history from 'utils/history';
import { controlQueryDrawer, FIRST_GUIDE } from 'actions/globalSearchActions';
import { getSetAddModeFx } from 'pages/Editor/EditorQueryDrawer/EditorQueryLeftPane';
import { PingBox } from 'pages/Editor/EditorQueryDrawer/emptyQuery';

type DropTargetComponentProps = WidgetProps & {
  children?: ReactNode;
  snapColumnSpace: number;
  snapRowSpace: number;
  minHeight: number;
  noPad?: boolean;
};

const StyledDropTarget = styled.div`
  transition: height 100ms ease-in;
  width: 100%;
  position: relative;
  background: none;
  user-select: none;
  z-index: 1;
`;

const Step = Steps.Step;
const OnboardingWrapper = styled.div`
  z-index: 999;
  .arco-steps-icon {
    background: transparent;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .arco-steps-item {
    /* display: inline-flex; */
    align-items: center;
  }
  .arco-steps-item-active {
  }
  .arco-steps-item-wait {
  }
  .arco-btn-text {
    /* padding-left: 5px; */
    background-color: transparent !important;
    cursor: pointer;
    :hover {
      text-decoration-line: underline;
    }
  }
`;
const stateIcon = {
  complete: <IconCheckCircle style={{ color: '#FFC2A2' }} />,
  progress: <IconThunderbolt style={{ color: '#FC5800' }} />,
  success: <IconThumbUp />,
  await: <IconLoading />,
};

const state = [
  {
    state: 'progress',
    text: '创建数据',
    children: [
      {
        state: 'progress',
        text: '连接数据库',
      },
      {
        state: 'await',
        text: '创建数据',
      },
    ],
  },
  {
    state: 'progress',
    text: '创建数据',
  },
  {
    state: 'progress',
    text: '创建数据',
  },
];

function useState() {
  const { appWideDS, otherDS } = useAppWideAndOtherDatasource();
  const allDS = [...appWideDS, ...otherDS];
  const files: any[] = useSelector(selectFilesForExplorer).filter(
    (itm) => itm.type !== 'group'
  );
  let state = 1;
  if (allDS.length === 0) {
    state = 1;
  } else {
    state = 2;
    if (files.length) {
      state = 4;
    }
  }
  return state;
}

function Onboarding() {
  const dispatch = useDispatch();
  const isFirstTimeUserOnboardingEnabled = useSelector(
    getIsFirstTimeUserOnboardingEnabled
  );
  const showWidgetsSidebar = useCallback(() => {
    history.push(builderURL());
    dispatch(setPanelState(PANELSTATE.COMPONENTS));
    if (isFirstTimeUserOnboardingEnabled) {
      dispatch(toggleInOnboardingWidgetSelection(true));
    }
  }, [getIsFirstTimeUserOnboardingEnabled]);

  const state = useState();

  const firstGuide = useSelector(
    (state: AppState) => state.ui.globalSearch.firstGuide
  );

  useEffect(() => {
    dispatch(FIRST_GUIDE(state));
  }, [state]);

  const addDb = useGoToAddDatasource(); //添加数据源
  const [isTip, setIsTip] = React.useState(false);
  return (
    <OnboardingWrapper className="absolute top-0 left-0 right-0 flex items-end h-108 justify-center text-2xl font-bold text-gray-300">
      <div>
        <img src="/canvas-empty.svg" />
        <div className="mt-[15px] text-center">在此处拖放组件</div>
      </div>
    </OnboardingWrapper>
  );
}

/*
  This context will provide the function which will help the draglayer and resizablecomponents trigger
  an update of the main container's rows
*/
export const DropTargetContext: Context<{
  updateDropTargetRows?: (
    widgetIdsToExclude: string[],
    widgetBottomRow: number
  ) => number | false;
}> = createContext({});

export function DropTargetComponent(props: DropTargetComponentProps) {
  const canDropTargetExtend = props.canExtend;
  const snapRows = getCanvasSnapRows(props.bottomRow, props.canExtend);

  const isResizing = useSelector(
    (state: AppState) => state.ui.widgetDragResize.isResizing
  );
  const isDragging = useSelector(
    (state: AppState) => state.ui.widgetDragResize.isDragging
  );

  // dragDetails contains of info needed for a container jump:
  // which parent the dragging widget belongs,
  // which canvas is active(being dragged on),
  // which widget is grabbed while dragging started,
  // relative position of mouse pointer wrt to the last grabbed widget.
  const dragDetails = useSelector(getDragDetails);

  const { draggedOn } = dragDetails;

  const childWidgets: string[] | undefined = useSelector(
    (state: AppState) => state.entities.canvasWidgets[props.widgetId]?.children
  );

  const selectOccupiedSpaces = useCallback(
    getOccupiedSpacesSelectorForContainer(props.widgetId),
    [props.widgetId]
  );

  const occupiedSpacesByChildren = useSelector(selectOccupiedSpaces, isEqual);

  const rowRef = useRef(snapRows);

  const showPropertyPane = useShowPropertyPane();
  const { deselectAll, focusWidget } = useWidgetSelection();
  const updateCanvasSnapRows = useCanvasSnapRowsUpdateHook();
  const dispatch = useDispatch();
  const showDragLayer =
    (isDragging && draggedOn === props.widgetId) || isResizing;
  const firstGuide = useSelector(
    (state: AppState) => state.ui.globalSearch.firstGuide
  );

  useEffect(() => {
    const snapRows = getCanvasSnapRows(props.bottomRow, props.canExtend);
    if (rowRef.current !== snapRows) {
      rowRef.current = snapRows;
      updateHeight();
      if (canDropTargetExtend) {
        updateCanvasSnapRows(props.widgetId, snapRows);
      }
    }
  }, [props.bottomRow, props.canExtend]);
  useEffect(() => {
    if (!isDragging || !isResizing) {
      // bottom row of canvas can increase by any number as user moves/resizes any widget towards the bottom of the canvas
      // but canvas height is not lost when user moves/resizes back top.
      // it is done that way to have a pleasant building experience.
      // post drop the bottom most row is used to appropriately calculate the canvas height and lose unwanted height.
      rowRef.current = snapRows;
      updateHeight();
    }
  }, [isDragging, isResizing]);

  const updateHeight = () => {
    if (dropTargetRef.current) {
      const height = canDropTargetExtend
        ? `${Math.max(rowRef.current * props.snapRowSpace, props.minHeight)}px`
        : '100%';
      dropTargetRef.current.style.height = height;
    }
  };
  const updateDropTargetRows = (
    widgetIdsToExclude: string[],
    widgetBottomRow: number
  ) => {
    if (canDropTargetExtend) {
      const newRows = calculateDropTargetRows(
        widgetIdsToExclude,
        widgetBottomRow,
        props.minHeight / GridDefaults.DEFAULT_GRID_ROW_HEIGHT - 1,
        occupiedSpacesByChildren
      );
      if (rowRef.current < newRows) {
        rowRef.current = newRows;
        updateHeight();
        return newRows;
      }
      return false;
    }
    return false;
  };

  const handleFocus = (e: any) => {
    if (!isResizing && !isDragging) {
      if (!props.parentId) {
        deselectAll();
        focusWidget && focusWidget(props.widgetId);
        showPropertyPane && showPropertyPane();
      }
    }
    // commenting this out to allow propagation of click events
    // e.stopPropagation();
    e.preventDefault();
  };
  const height = canDropTargetExtend
    ? `${Math.max(rowRef.current * props.snapRowSpace, props.minHeight)}px`
    : '100%';
  const boxShadow =
    (isResizing || isDragging) && props.widgetId === MAIN_CONTAINER_WIDGET_ID
      ? 'inset 0px 0px 0px 1px #DDDDDD'
      : '0px 0px 0px 1px transparent';
  const dropTargetRef = useRef<HTMLDivElement>(null);

  // memoizing context values
  const contextValue = useMemo(() => {
    return {
      updateDropTargetRows,
    };
  }, [updateDropTargetRows, occupiedSpacesByChildren]);
  useEffect(() => {
    if (childWidgets.length && firstGuide) {
      dispatch(FIRST_GUIDE(false));
    }
  }, [childWidgets.length]);
  return (
    <DropTargetContext.Provider value={contextValue}>
      <StyledDropTarget
        className="t--drop-target"
        onClick={handleFocus}
        ref={dropTargetRef}
        style={{
          height,
          boxShadow,
        }}
      >
        {props.children}
        {!(childWidgets && childWidgets.length) &&
          !isDragging &&
          !props.parentId && <Onboarding />}
        {showDragLayer && (
          <DragLayerComponent
            noPad={props.noPad || false}
            parentColumnWidth={props.snapColumnSpace}
            parentRowHeight={props.snapRowSpace}
          />
        )}
      </StyledDropTarget>
    </DropTargetContext.Provider>
  );
}

const MemoizedDropTargetComponent = memo(DropTargetComponent);

export default MemoizedDropTargetComponent;
