import React, { useLayoutEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AppState } from 'reducers';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RESET_PASSWORD_FORM_NAME } from 'constants/forms';
import { useForm, Controller } from 'react-hook-form';
import { ReduxActionTypes } from '@appsmith/constants/ReduxActionConstants';
import { getIsTokenValid, getIsValidatingToken } from 'selectors/authSelectors';
import FormMessage, {
  MessageAction,
  FormMessageProps,
} from 'components/ads/formFields/FormMessage';
import { Input, Message } from '@arco-design/web-react';
import { isEmptyString, isStrongPassword } from 'utils/formhelpers';
import { ResetPasswordFormValues } from './helpers';
import { AuthCardHeader } from './StyledComponents';
import { AUTH_LOGIN_URL, FORGOT_PASSWORD_URL } from 'constants/routes';
import styled, { withTheme } from 'styled-components';
import { Theme } from 'constants/DefaultTheme';
import cs from 'classnames';
import { SpacedSubmitForm, FormActions } from 'pages/UserAuth/StyledComponents';

import {
  RESET_PASSWORD_PAGE_TITLE,
  FORM_VALIDATION_INVALID_PASSWORD,
  FORM_VALIDATION_EMPTY_PASSWORD,
  RESET_PASSWORD_EXPIRED_TOKEN,
  RESET_PASSWORD_FORGOT_PASSWORD_LINK,
  RESET_PASSWORD_INVALID_TOKEN,
  RESET_PASSWORD_RESET_SUCCESS,
  RESET_PASSWORD_RESET_SUCCESS_LOGIN_LINK,
  createMessage,
} from '@appsmith/constants/messages';
import { isPhone } from 'utils/formhelpers';
import api from 'ce/pages/UserAuth/api';
import { LOGIN_SUBMIT_PATH } from '@appsmith/constants/ApiConstants';
import Icon from 'components/icon';
import history from 'utils/history';
import { debounce } from 'lodash';
import { IconLoading } from '@arco-design/web-react/icon';

const ResetPwsPageWrap = styled.div`
  input::-webkit-input-placeholder {
    font-size: 14px;
  }
  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }
  .arco-input-inner-wrapper {
    padding-left: 25px;
    padding-right: 16px;
  }
  .arco-input-group-wrapper.arco-input-custom-height
    .arco-input-inner-wrapper
    .arco-input-group-prefix
    > svg {
    font-size: 18px;
    color: #999999;
  }
`;

const SubmitButton = styled.button`
  height: 48px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  background: #1375ff;
  margin-top: 52px !important;
  /* margin-left: 30px; */

  &:hover {
    background: #4080ff;
  }

  &:active {
    background: #4080ff;
  }
  &.bg-disable {
    background: var(--disable) !important;
  }
`;

const ReturnButton = styled.button`
  height: 40px;
  border-radius: 4px;
  font-size: 12px;
  background-color: #f7f7f7 !important;
  margin-right: 30px;
`;

const validate = (values: ResetPasswordFormValues) => {
  const errors: ResetPasswordFormValues = {};
  if (!values.password || isEmptyString(values.password)) {
    errors.password = createMessage(FORM_VALIDATION_EMPTY_PASSWORD);
  } else if (!isStrongPassword(values.password)) {
    errors.password = createMessage(FORM_VALIDATION_INVALID_PASSWORD);
  }
  return errors;
};

type ResetPasswordProps = InjectedFormProps<
  ResetPasswordFormValues,
  {
    verifyToken: (token: string) => void;
    isTokenValid: boolean;
    validatingToken: boolean;
  }
> & {
  verifyToken: (token: string) => void;
  isTokenValid: boolean;
  validatingToken: boolean;
  theme: Theme;
} & RouteComponentProps<{ email: string; token: string }>;

export function ResetPassword(props: ResetPasswordProps) {
  const {
    error,
    // handleSubmit,
    initialValues,
    isTokenValid,
    pristine,
    submitFailed,
    submitSucceeded,
    // submitting,
    validatingToken,
    verifyToken,
  } = props;

  useLayoutEffect(() => {
    if (initialValues.token) verifyToken(initialValues.token);
  }, [initialValues.token, verifyToken]);

  const showInvalidMessage = !initialValues.token;
  const showExpiredMessage = !isTokenValid && !validatingToken;
  const showSuccessMessage = submitSucceeded && !pristine;
  const showFailureMessage = submitFailed && !!error;

  let message = '';
  let messageActions: MessageAction[] | undefined = undefined;
  if (showExpiredMessage || showInvalidMessage) {
    messageActions = [
      {
        url: FORGOT_PASSWORD_URL,
        text: createMessage(RESET_PASSWORD_FORGOT_PASSWORD_LINK),
        intent: 'primary',
      },
    ];
  }
  if (showExpiredMessage) {
    message = createMessage(RESET_PASSWORD_EXPIRED_TOKEN);
  }
  if (showInvalidMessage) {
    message = createMessage(RESET_PASSWORD_INVALID_TOKEN);
  }
  if (showSuccessMessage) {
    message = createMessage(RESET_PASSWORD_RESET_SUCCESS);
    messageActions = [
      {
        url: AUTH_LOGIN_URL,
        text: createMessage(RESET_PASSWORD_RESET_SUCCESS_LOGIN_LINK),
        intent: 'success',
      },
    ];
  }
  if (showFailureMessage) {
    message = error;
    if (
      message
        .toLowerCase()
        .includes(
          createMessage(RESET_PASSWORD_FORGOT_PASSWORD_LINK).toLowerCase()
        )
    ) {
      messageActions = [
        {
          url: FORGOT_PASSWORD_URL,
          text: createMessage(RESET_PASSWORD_FORGOT_PASSWORD_LINK),
          intent: 'primary',
        },
      ];
    }
  }

  const [active, setActive] = useState(0);
  const [loginUrl, setLoginUrl] = useState('/api/v1/' + LOGIN_SUBMIT_PATH);
  const [submitting, setSubmitting] = useState(false);
  const [codeTime, setCodeTime] = useState(60);
  const [showCode, setShowCode] = useState(false);
  const [telNumber, setTelNumber] = useState('');
  const [validImg, setValidImg] = useState('');
  const [codekey, setCodekey] = useState('');
  const [smsCodekey, setSmsCodekey] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [graphCode, setGraphCode] = useState('isHidden');
  const [spin, setSpin] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
  });
  const { path } = useRouteMatch();

  const messageTagProps: FormMessageProps = {
    intent:
      showInvalidMessage || showExpiredMessage || showFailureMessage
        ? 'danger'
        : 'lightSuccess',
    message,
    actions: messageActions,
  };

  // if (showInvalidMessage || showExpiredMessage) {
  //   return <FormMessage {...messageTagProps} />;
  // }

  // if (!isTokenValid && validatingToken) {
  //   return <Spinner />;
  // }

  function handlePhone(values) {
    if (graphCode === 'isHidden') {
      api.getVerity().then((res) => {
        setValidImg(res.data.codePic);
        setCodekey(res.data.codekey);
        setGraphCode('isShow');
        setTelNumber(values.phone);
      });
    } else {
      api
        .checkVerity({
          code: values.code,
          codekey: codekey,
          phone: values.phone,
        })
        .then((res) => {
          if (res.data) {
            setSmsCodekey(res.data);
            handSendCode(res.data);
          } else {
            if (res.responseMeta.error.code === 4052) {
              // account not register
              history.push(`${AUTH_LOGIN_URL}?code=4052`);
            }
            Message.error(res.responseMeta.error.message);
          }
        })
        .catch((err) => {
          Message.error(err.message);
        });
    }
  }

  function handleCode(value) {
    api
      .checkCode({ code: value, phone: telNumber })
      .then((res) => {
        if (res.data) {
          setActive(2);
        } else {
          Message.error(res.responseMeta.error.message);
        }
      })
      .catch((err) => {
        Message.error(err.message);
      });
  }

  function handleChange(otp) {
    setSmsCode(otp);
    if (otp.length === 6) {
      handleCode(otp);
    }
  }

  function handleReset(values) {
    if (values.passwordVaild === values.password) {
      api
        .resetPwd({
          phone: values.phone,
          password: values.password,
          smsCode: values.smsCode,
        })
        .then((res) => {
          if (res.data) {
            setActive(3);
          } else {
            Message.error(res.responseMeta.error.message);
          }
        });
    } else {
      Message.error('两次密码不一致');
    }
  }

  function handSendCode(codeData) {
    if (telNumber) {
      setSpin(true);
      api
        .getCode({
          phone: telNumber,
          smsCodekey: codeData ? codeData : smsCodekey,
        })
        .then((res) => {
          if (res.data) {
            setSpin(false);
            setShowCode(true);
            setActive(1);
            let time = 60;
            const timer = setInterval(() => {
              time--;
              setCodeTime(time);
              if (time <= 0) {
                setShowCode(false);
                clearInterval(timer);
                setCodeTime(60);
              }
            }, 1000);
          } else {
            Message.error(res.responseMeta.error.message);
            setSpin(false);
          }
        })
        .catch((err) => {
          Message.error(err);
        });
    } else {
      Message.error('请输入电话号码');
    }
  }

  function renderResetForm(): JSX.Element {
    const handSendCodeDelay = debounce(() => handSendCode(''), 500);
    if (active === 0) {
      return (
        <SpacedSubmitForm id="phone-form" onSubmit={handleSubmit(handlePhone)}>
          <div className="mb-[24px]">
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <Input
                  height={48}
                  placeholder="输入电话号码"
                  prefix={
                    <Icon
                      name={
                        graphCode === 'isHidden'
                          ? 'login_phone_icon'
                          : 'login_phone_unactive'
                      }
                      size={18}
                    />
                  }
                  suffix={
                    <span
                      className={cs('text-12 text-orange cursor-pointer', {
                        hidden: graphCode === 'isHidden',
                      })}
                      onClick={() => {
                        if (graphCode === 'isShow') {
                          setGraphCode('isHidden');
                          setSubmitting(false);
                        }
                      }}
                    >
                      更换账号
                    </span>
                  }
                  {...field}
                  readOnly={graphCode === 'isShow'}
                />
              )}
              rules={{
                required: '请输入手机号',
                validate: {
                  phone: (v) => isPhone(v) || '手机号码错误',
                },
              }}
            />
            {errors.phone && (
              <p className="text-red absolute text-12">
                {errors.phone.message}
              </p>
            )}
          </div>
          <div className="h-[48px]">
            <div
              className={cs('mb-[24px] flex justify-between items-center', {
                hidden: graphCode === 'isHidden',
              })}
            >
              <div className="flex-auto mr-1">
                <Controller
                  control={control}
                  name="code"
                  render={({ field }) => (
                    <Input
                      height={48}
                      placeholder="请输入右侧字符"
                      {...field}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors.code && (
                  <p className="text-red absolute text-12">
                    {errors.code.message}
                  </p>
                )}
              </div>
              <div
                className="flex-none rounded w-[210px] h-[48px] flex items-center justify-center"
                onClick={() => {
                  api.getVerity().then((res) => {
                    setValidImg(res.data.codePic);
                    setCodekey(res.data.codekey);
                  });
                }}
              >
                <img src={validImg} />
              </div>
            </div>
          </div>

          <FormActions className="flex justify-between">
            {/* <ReturnButton
              onClick={() => {
                history.push(`${AUTH_LOGIN_URL}`);
              }}
            >
              返回
            </ReturnButton> */}
            <SubmitButton
              className={cs('font-bold text-16')}
              disabled={submitting}
              onClick={() => {
                handleSubmit(handlePhone);
              }}
              type="submit"
            >
              下一步
            </SubmitButton>
          </FormActions>
        </SpacedSubmitForm>
      );
    }
    if (active === 1) {
      return (
        <SpacedSubmitForm
          id="phone-form"
          method="POST"
          onSubmit={handleSubmit(handleCode)}
        >
          <div className="hidden">
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <Input
                  placeholder="输入手机号"
                  prefix={<Icon name="login_phone_icon" size={16} />}
                  {...field}
                />
              )}
            />
          </div>
          <div className="hidden">
            <Controller
              control={control}
              name="source"
              render={({ field }) => (
                <Input placeholder="来源" {...field} value="WECHAT" />
              )}
            />
          </div>
          <div className="mb-[64px]">
            <div className="text-24 text-[#3D3D3D] text-left mb-[24px]">
              <div className="flex mb-[60px] items-center">
                <Icon
                  name="codeBack"
                  onClick={() => {
                    history.push(`${AUTH_LOGIN_URL}`);
                  }}
                  style={{
                    fontSize: 26,
                    marginRight: '16px',
                    color: '#666666',
                  }}
                />
                <div className="!font-bold">请输入验证码</div>
              </div>

              <div className="text-16 text-content mt-4">
                已发送至
                <span className="text-title-primary font-bold ml-1">
                  {telNumber}
                </span>
              </div>
            </div>
            <div className="codeNumberWrap">
              <OtpInput
                inputStyle={{
                  width: '40px',
                  height: '40px',
                  marginRight: '28px',
                  fontSize: '24px',
                  borderRadius: 4,
                  border: '1px solid rgba(0,0,0,0.3)',
                }}
                isInputNum
                numInputs={6}
                onChange={handleChange}
                separator={''}
                shouldAutoFocus
                value={smsCode}
              />
              <div className="opacity-0">
                <Controller
                  control={control}
                  name="smsCode"
                  render={({ field }) => (
                    <Input
                      height={40}
                      maxLength={6}
                      placeholder="输入验证码"
                      {...field}
                      className="text-24"
                      readOnly
                      value={smsCode}
                    />
                  )}
                  rules={{
                    required: '请输入验证码',
                  }}
                />
              </div>
              <div>
                {showCode ? (
                  <div className="text-14 text-center text-[#999999]">
                    <div className="text-[#1375FF] inline-block">
                      {codeTime}秒
                    </div>
                    后可重新发送短信验证码
                  </div>
                ) : (
                  <div
                    className="mt-[32px] text-center text-14 text-[#1375FF] cursor-pointer"
                    onClick={() => {
                      if (!spin) handSendCodeDelay();
                    }}
                  >
                    {spin ? <IconLoading /> : '重新发送'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </SpacedSubmitForm>
      );
    }
    if (active === 2) {
      return (
        <SpacedSubmitForm
          id="login-form"
          method="POST"
          onSubmit={handleSubmit(handleReset)}
        >
          <div className="text-24 text-black-400 text-left mb-[64px] flex items-center">
            <Icon
              name="codeBack"
              onClick={() => {
                history.push(`${AUTH_LOGIN_URL}`);
              }}
              style={{
                fontSize: 26,
                marginRight: '16px',
                color: '#666666',
              }}
            />
            <div className="font-bold">重置密码</div>
          </div>
          <div className="mb-[24px]">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input.Password
                  height={48}
                  placeholder="请输入新密码"
                  prefix={<Icon name="iconLock" size={16} />}
                  type="password"
                  {...field}
                />
              )}
              rules={{
                required: '请输入新密码',
                validate: {
                  password: (v) => v.length >= 6 || '密码长度不能少于6',
                },
              }}
            />
            {errors.password && (
              <p className="text-red absolute text-12">
                {errors.password.message}
              </p>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="passwordVaild"
              render={({ field }) => (
                <Input.Password
                  height={48}
                  placeholder="再次输入新密码"
                  prefix={<Icon name="iconRetype" size={16} />}
                  type="password"
                  {...field}
                />
              )}
              rules={{
                required: '请确认密码',
                validate: {
                  password: (v) =>
                    v.length > 6 || v.length == 6 || '密码长度不能少于6',
                },
              }}
            />
            {errors.passwordVaild && (
              <p className="text-red absolute text-12">
                {errors.passwordVaild.message}
              </p>
            )}
          </div>
          <FormActions>
            <SubmitButton
              className="font-bold text-16"
              disabled={submitting}
              onClick={handleSubmit(handleReset)}
              type="submit"
            >
              下一步
            </SubmitButton>
          </FormActions>
        </SpacedSubmitForm>
      );
    }
    if (active === 3) {
      return (
        <div className="text-center h-[324px]">
          <Icon
            className="m-auto w-[64px] h-[64px] mb-[40px]"
            name="resetSuccess"
            size="64"
          />
          <div className="text-content text-24 mb-[46px]">
            <div>您的密码已经成功重置</div>
            <div>请返回登录界面重新登录</div>
          </div>
          <SubmitButton
            className="font-bold text-16 w-[264px]"
            onClick={() => {
              history.push('/user/login');
            }}
            type="button"
          >
            返回登录
          </SubmitButton>
        </div>
      );
    }
  }

  return (
    <ResetPwsPageWrap>
      <AuthCardHeader>
        {active === 2 || active === 1 ? (
          ''
        ) : (
          <div className="flex items-center !mb-[48px]">
            <Icon
              name="codeBack"
              onClick={() => {
                history.push(`${AUTH_LOGIN_URL}`);
              }}
              style={{
                fontSize: 26,
                marginRight: '16px',
                color: '#666666',
              }}
            />
            <h1 className="!text-24 text-[#3D3D3D] !font-bold m-0">
              {createMessage(RESET_PASSWORD_PAGE_TITLE)}
            </h1>
          </div>
        )}
      </AuthCardHeader>
      {(showSuccessMessage || showFailureMessage) && (
        <FormMessage {...messageTagProps} />
      )}
      {renderResetForm()}
    </ResetPwsPageWrap>
  );
}

export default connect(
  (state: AppState, props: ResetPasswordProps) => {
    const queryParams = new URLSearchParams(props.location.search);
    return {
      initialValues: {
        token: queryParams.get('token') || undefined,
      },
      isTokenValid: getIsTokenValid(state),
      validatingToken: getIsValidatingToken(state),
    };
  },
  (dispatch: any) => ({
    verifyToken: (token: string) =>
      dispatch({
        type: ReduxActionTypes.RESET_PASSWORD_VERIFY_TOKEN_INIT,
        payload: { token },
      }),
  })
)(
  reduxForm<
    ResetPasswordFormValues,
    {
      verifyToken: (token: string) => void;
      validatingToken: boolean;
      isTokenValid: boolean;
    }
  >({
    validate,
    form: RESET_PASSWORD_FORM_NAME,
    touchOnBlur: true,
  })(withRouter(withTheme(ResetPassword)))
);
