import { AppIconName } from 'components/ads/AppIcon';
import { AppColorCode } from 'constants/DefaultTheme';
import { ReduxActionTypes } from '@appsmith/constants/ReduxActionConstants';
import { SubmissionError } from 'redux-form';
import _ from 'lodash';

import { queryClient } from '../../QueryClient';
import { DeployRecordRes } from 'api/DeployApi';
import history from 'utils/history';
import {
  DEFAULT_DEPLOY_RECORD_CURRENT,
  DEFAULT_DEPLOY_RECORD_PAGE_SIZE,
} from 'pages/Deploy/hooks/useDeployRecords';

export type CreateApplicationFormValues = {
  applicationName: string;
  orgId: string;
  colorCode?: AppColorCode;
  appName?: AppIconName;
};

export const CREATE_APPLICATION_FORM_NAME_FIELD = 'applicationName';

export const createApplicationFormSubmitHandler = (
  values: CreateApplicationFormValues,
  dispatch: any
): Promise<any> => {
  const { applicationName, orgId } = values;
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.CREATE_APPLICATION_INIT,
      payload: {
        resolve,
        reject,
        applicationName,
        orgId,
      },
    });
  }).catch((error) => {
    throw new SubmissionError(error);
  });
};

export const linkToWizardOrDeployRecordListPage = (
  deployAppUrl: string,
  deployRecordsUrl: string
) => {
  const deployRecords = queryClient.getQueryData<DeployRecordRes>([
    'deployRecords',
    DEFAULT_DEPLOY_RECORD_CURRENT,
    DEFAULT_DEPLOY_RECORD_PAGE_SIZE,
  ]);

  if (_.get(deployRecords, 'data.total', 0) >= 1) {
    history.push(deployRecordsUrl);
  } else {
    history.push(deployAppUrl);
  }
};
