import { AxiosPromise, AxiosResponse } from 'axios';

import Api from 'api/Api';
import { isString } from 'lodash';
import { profileDB } from 'models/db';
import {
  ApiInstallResponse,
  ApiResponse,
  PartialMetaApiResponse,
} from './ApiResponses';

import { QueryUserListParams } from '@appInstall/pages/manage/interface';
import { ActiveUserProfile } from '@appInstall/redux/actions';
import { LoginPropertyProps } from 'pages/Editor/AppSysConfigDrawer/LandingPageManage';
import { queryAppInfoInstallMode, saveAppInfoInstallMode } from 'utils/storage';

const SPECIFIC_NEW_PAGE_NAME = '实时数据大屏';

export const APP_PROFILE_STORAGE_KEY_PREFIX = '__APP_PROFILE__';

export type FetfchUserProileResponse = ApiInstallResponse<ActiveUserProfile>;

export function getCurAppId() {
  const parts = location.pathname.match(/\/api\/page\/(.\w+)\/(.\w+)/);

  return parts && parts.length ? parts[1] : '';
}

function getPreviewModeAppId() {
  const hostArr = location.host.split('.');

  return hostArr && hostArr.length ? hostArr[0] : '';
}

type InstallApiResponse<D = any, E = any> = Promise<AxiosResponse<D> & E>;

interface IApiResponseInstall<T> {
  code?: number;
  data?: T;
  msg?: string;
}

class DepApi {
  static url = '';

  // todo: 对接登录
  static login = ({
    captcha = '8dmfm',
    password,
    username,
    uuid = '6dea99b3-46af-4bba-a313-cb2a79536364',
  }): Promise<IApiResponseInstall<{ token: string; expire: string }>> => {
    return Api.post(DepApi.url + '/login', {
      username,
      password,
      uuid,
      captcha,
    });
  };

  static getUserInfo(): AxiosPromise<FetfchUserProileResponse> {
    return Api.get(DepApi.url + '/user/info');
  }

  static mockData = async (url: string) => {
    try {
      const res = await fetch(url);
      return await res.json();
    } catch (err) {
      return;
    }
  };

  static getAppJson = async (params?: any) => {
    const appId = getCurAppId();
    // todo: remove
    // return await DepApi.mockData(`/mocks/cw/app.json`);

    const cacheKey = `APP_PROFILE_STORAGE_KEY_PREFIX`;

    if (appId && appId !== 'manage') {
      const appJson = localStorage.getItem(cacheKey);
      if (appJson) {
        return JSON.parse(appJson);
      }
    }

    try {
      // @ts-ignore
      const resp: ApiResponse = await Api.get(`${DepApi.url}/application/info`);

      const validJson =
        typeof resp.data === 'string' ? JSON.parse(resp.data) : resp.data;

      if (resp.data && validJson) {
        appId &&
          appId !== 'manage' &&
          localStorage.setItem(cacheKey, resp.data);
        return validJson;
      }
    } catch (err) {
      throw err;
    }
  };

  static getAppInfoInstallMode = async (_: unknown) => {
    const appId = getCurAppId();

    const storeKey = `${APP_PROFILE_STORAGE_KEY_PREFIX}${appId}`;
    const isValidAppId = appId && appId !== 'manage';

    if (isValidAppId) {
      const curAppInfo = await queryAppInfoInstallMode(storeKey);

      if (curAppInfo) {
        if (isString(curAppInfo)) {
          return JSON.parse(curAppInfo);
        }

        return curAppInfo;
      }
    }

    try {
      // @ts-ignore
      const resp: ApiResponse = await Api.get(`${DepApi.url}/application/info`);
      const remoteAppData = resp?.data;

      const formatAppInfo = isString(remoteAppData)
        ? JSON.parse(remoteAppData)
        : remoteAppData;

      if (formatAppInfo) {
        if (isValidAppId) {
          await saveAppInfoInstallMode(storeKey, formatAppInfo);
        }

        return formatAppInfo;
      }
    } catch (err) {
      console.error(
        `An error occurred when invoking getAppInfoInstallMode: `,
        err
      );

      // throw err;
    }
  };

  static getPreviewModeAppJson = async () => {
    // const appId = '63e59f30f9e9d5415a20a77d';
    const appId = getPreviewModeAppId();

    try {
      const appProfiles = await profileDB.appProfile.where({ id: appId });

      const appInfo = await appProfiles.toArray();

      return appInfo[0];
    } catch (err) {
      console.error(err);
    }
  };

  static getAppMenus = async () => {
    // const appId = getCurAppId();

    // todo: remove
    // return await DepApi.mockData(`/mocks/cw/menus.json`);

    // const cacheKey = ['ft-app-menus', appId].join('_');

    // if (appId) {
    //   const menus = localStorage.getItem(cacheKey);
    //   if (menus) {
    //     return JSON.parse(menus);
    //   }
    // }

    try {
      // @ts-ignore
      const resp: ApiResponse = await Api.get(DepApi.url + `/menu/list`, {});
      let menuData = resp.data;

      if (menuData) {
        menuData = menuData.map((menu) => {
          if (menu.name === SPECIFIC_NEW_PAGE_NAME) {
            menu.isScreen = true;
          }

          return menu;
        });
        // if (appId && appId !== 'manage') {
        //   localStorage.setItem(cacheKey, JSON.stringify(resp.data));
        // }
      }

      return menuData || [];
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  static userPage = ({
    current,
    formParams,
    pageSize,
  }: QueryUserListParams) => {
    return Api.get(DepApi.url + `/user/page/${current}/${pageSize}`, {
      ...formParams,
    });
  };

  static roleList = () => {
    return Api.get(DepApi.url + `/role/list`, {});
  };

  static rolePage = ({ current, formParams, pageSize }) => {
    return Api.get(DepApi.url + `/role/page/${current}/${pageSize}`, {
      ...formParams,
    });
  };

  static logPage = ({ current, formParams, pageSize }) => {
    return Api.get(DepApi.url + `/log/page/${current}/${pageSize}`, {
      ...formParams,
    });
  };

  static roleAdd = (
    params
  ): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => {
    return Api.post(DepApi.url + `/role/add`, { ...params }, {});
  };

  static updateRole = (
    params
  ): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => {
    return Api.post(DepApi.url + `/role/edit`, { ...params }, {});
  };

  //   manage/role/page/1/10 /menu/list  /role/add /role/getMenus/1522870877308350466
  static getRoleMenuById = (id: string) => {
    return Api.get(DepApi.url + `/role/getMenus/${id}`, {});
  };

  static deleteRole = (
    id: string | number
  ): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => {
    return Api.post(DepApi.url + `/role/remove/${id}`, {}, {});
  };

  static getRoleMenus(): Promise<ApiResponse> {
    return Api.get(`${DepApi.url}/menus`);
  }

  static treeMenu = (): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => {
    return Api.get(DepApi.url + `/menu/getTree`, {});
  };

  static allMenu = () => {
    return Api.get(DepApi.url + `/menu/getAll`, {});
  };

  // /manage/menu/getAll
  static addUser = (
    params
  ): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => {
    return Api.post(DepApi.url + `/user/add`, { ...params }, {});
  };

  static updateUser = (
    params
  ): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => {
    return Api.post(DepApi.url + `/user/edit`, { ...params }, {});
  };

  static deleteUser = (
    id: string | number
  ): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => {
    return Api.post(DepApi.url + `/user/remove/${id}`, {}, {});
  };

  static getAppLicenseToken = (
    info: string
  ): Promise<PartialMetaApiResponse> => {
    return Api.get(DepApi.url + `/v1/license/save`, { info });
  };

  static checkAppLicenseToken = (
    info: string
  ): Promise<PartialMetaApiResponse> => {
    return Api.get(`${DepApi.url}/v1/license/check`, { info });
  };

  static getAppLicense = (
    code: string
  ): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => Api.get(DepApi.url + `/license/create`, { code });

  static logout = (): InstallApiResponse<
    any,
    {
      code?: number;
      msg?: string;
    }
  > => {
    return Api.post(DepApi.url + '/logout');
    // return Api.post(DepApi.url + '/logout');
  };

  /**
   * Department
   */
  static queryDepartmentList(params: QueryDepartmentList) {
    return Api.get(`${DepApi.url}/dept/list`, params);
  }

  static createDepartment(
    data: CreateDepartmentDTO
  ): Promise<PartialMetaApiResponse> {
    return Api.post(`${DepApi.url}/dept/add`, data);
  }

  static deleteDepartmentById(id: string): Promise<PartialMetaApiResponse> {
    return Api.delete(`${DepApi.url}/dept/delete/${id}`);
  }

  static async loginPageTheme() {
    try {
      const data = await Api.get(`${DepApi.url}/application/loginView`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  // TODO move exclusive apis to @/app-install directory
  static validateExternalToken(
    UUID: string
  ): Promise<IApiResponseInstall<{ token: string; expire: string }>> {
    return Api.get(`${DepApi.url}/application/ddLogin`, { UUID });
  }

  static getLoginPropertyConfigs(id: string) {
    return Api.get(`${DepApi.url}/v1/var/getLoginVar`, {
      applicationId: id,
    });
  }

  static updateLoginPropertyConfigs(
    data: LoginPropertyProps & {
      applicationId: string;
    }
  ): Promise<PartialMetaApiResponse> {
    return Api.post(`${DepApi.url}/v1/var/saveLoginVar`, {
      applicationId: data.applicationId,
      varValue: data.enableSSO,
    });
  }
}

export default DepApi;

/**
 * NOTE: Below codes are dedicated to XiangYang Hospital, if possible, please refactor them.
 */
export const thirdPartyLogoutWithToken = (token: string) => {
  if (!token) return;

  const logoutUrl = 'http://10.10.31.194:5002/auth/token/logout';
  const logoutUrlForProd = 'http://10.20.10.47:5002/auth/token/logout';
  const logoutUrlForHospitalTesting =
    'http://10.20.10.51:5002/auth/token/logout';
  return Api.delete(logoutUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

interface AppIdDTO {
  applicationId: string;
}

interface CommonListResponse<T = any> {
  records: T[];
  current: number;
  total: number;
  size: number;
}

type QueryDepartmentList = {
  name?: string;
};

interface CreateDepartmentDTO {
  deptId: string;
  deptName: string;
  parentId?: string; // Default value: '0'
  hosCode?: string;
  customFields: {
    id?: string;
    fieldId: string;
    fieldValueId: string;
    fieldValue: string;
  }[];
}

interface DelDepartmentDTO {
  applicationId: string;
  deptId: string;
}
