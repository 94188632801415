import { updateAndSaveLayout, WidgetAddChild } from 'actions/pageActions';
import {
  ReduxAction,
  ReduxActionTypes,
  WidgetReduxActionTypes,
} from '@appsmith/constants/ReduxActionConstants';
import { RenderModes } from 'constants/WidgetConstants';
import { ENTITY_TYPE } from 'entities/AppsmithConsole';
import {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from 'reducers/entityReducers/canvasWidgetsReducer';
import { WidgetBlueprint } from 'reducers/entityReducers/widgetConfigReducer';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import AppsmithConsole from 'utils/AppsmithConsole';
import { getNextEntityName } from 'utils/AppsmithUtils';
import { generateWidgetProps } from 'utils/WidgetPropsUtils';
import { getWidget, getWidgets } from './selectors';
import {
  buildWidgetBlueprint,
  executeWidgetBlueprintOperations,
  traverseTreeAndExecuteBlueprintChildOperations,
} from './WidgetBlueprintSagas';
import { getParentBottomRowAfterAddingWidget } from './WidgetOperationUtils';
import { getDataTree } from 'selectors/dataTreeSelectors';
import { generateReactKey } from 'utils/generators';
import { WidgetProps } from 'utils/widgetBridge';
import WidgetFactory from 'utils/WidgetFactory';
import omit from 'lodash/omit';
import produce from 'immer';
import { GRID_DENSITY_MIGRATION_V1 } from 'utils/widgetBridge';
import _ from 'lodash';

const WidgetTypes = WidgetFactory.widgetTypes;

type GeneratedWidgetPayload = {
  widgetId: string;
  widgets: { [widgetId: string]: FlattenedWidgetProps };
};

type WidgetAddTabChild = {
  tabs: any;
  widgetId: string;
};

type WidgetDynamicTabs = WidgetAddTabChild & {
  onSuccess?: (widgetId: string) => void;
};

const getChildTabData = (
  tabProps: WidgetProps,
  tab: {
    id: string;
    label: string;
    widgetId: string;
  }
) => {
  const columns =
    (tabProps.rightColumn - tabProps.leftColumn) * tabProps.parentColumnSpace;
  // GRID_DENSITY_MIGRATION_V1 used to adjust code as per new scaled canvas.
  const rows =
    (tabProps.bottomRow - tabProps.topRow - GRID_DENSITY_MIGRATION_V1) *
    tabProps.parentRowSpace;
  return {
    type: WidgetTypes.CANVAS_WIDGET,
    columns: columns,
    rows: rows,
    topRow: 1,
    newWidgetId: tab.widgetId,
    widgetId: tabProps.widgetId,
    leftColumn: 0,
    rightColumn:
      (tabProps.rightColumn - tabProps.leftColumn) * tabProps.parentColumnSpace,
    bottomRow: (tabProps.bottomRow - tabProps.topRow) * tabProps.parentRowSpace,
    props: {
      tabId: tab.id,
      tabName: tab.label,
      containerStyle: 'none',
      canExtend: false,
      detachFromLayout: true,
      children: [],
    },
  };
};

function* getEntityNames() {
  const evalTree = yield select(getDataTree);
  return Object.keys(evalTree);
}

function* getChildWidgetProps(
  parent: FlattenedWidgetProps,
  params: WidgetAddChild,
  widgets: { [widgetId: string]: FlattenedWidgetProps },
  widgetIds: string[]
) {
  const { leftColumn, newWidgetId, topRow, type } = params;
  let {
    columns,
    parentColumnSpace,
    parentRowSpace,
    props,
    rows,
    widgetName,
  } = params;
  let minHeight = undefined;
  const restDefaultConfig = omit(WidgetFactory.widgetConfigMap.get(type), [
    'blueprint',
  ]);
  if (!widgetName) {
    if (widgetIds.includes(newWidgetId)) {
      const serial = _.findIndex(widgetIds, (id) => id === newWidgetId) + 1;
      widgetName = `${restDefaultConfig.widgetName}${serial}`;
    } else {
      const widgetNames = Object.keys(widgets).map(
        (w) => widgets[w].widgetName
      );
      const entityNames: string[] = yield call(getEntityNames);

      widgetName = getNextEntityName(restDefaultConfig.widgetName, [
        ...widgetNames,
        ...entityNames,
      ]);
    }
  }
  if (type === 'CANVAS_WIDGET') {
    columns =
      (parent.rightColumn - parent.leftColumn) * parent.parentColumnSpace;
    parentColumnSpace = 1;
    rows = (parent.bottomRow - parent.topRow) * parent.parentRowSpace;
    parentRowSpace = 1;
    minHeight = rows;
    // if (props) props.children = [];

    if (props) {
      props = produce(props, (draft: WidgetProps) => {
        if (!draft.children || !Array.isArray(draft.children)) {
          draft.children = [];
        }
      });
    }
  }

  const widgetProps = {
    ...restDefaultConfig,
    ...props,
    columns,
    rows,
    minHeight,
    widgetId: newWidgetId,
    renderMode: RenderModes.CANVAS,
  };
  const widget = generateWidgetProps(
    parent,
    type,
    leftColumn,
    topRow,
    parentRowSpace,
    parentColumnSpace,
    widgetName,
    widgetProps,
    restDefaultConfig.version
  );

  widget.widgetId = newWidgetId;
  return widget;
}

function* generateChildWidgets(
  widgetIds: string[],
  parent: FlattenedWidgetProps,
  params: WidgetAddChild,
  widgets: { [widgetId: string]: FlattenedWidgetProps },
  propsBlueprint?: WidgetBlueprint
): any {
  // Get the props for the widget
  const widget = yield getChildWidgetProps(parent, params, widgets, widgetIds);

  // Add the widget to the canvasWidgets
  // We need this in here as widgets will be used to get the current widget
  widgets[widget.widgetId] = widget;

  // Get the default config for the widget from WidgetConfigResponse
  const defaultConfig = { ...WidgetFactory.widgetConfigMap.get(widget.type) };

  // If blueprint is provided in the params, use that
  // else use the blueprint available in WidgetConfigResponse
  // else there is no blueprint for this widget
  const blueprint =
    propsBlueprint || { ...defaultConfig?.blueprint } || undefined;

  // If there is a blueprint.view
  // We need to generate the children based on the view
  if (blueprint && blueprint.view) {
    // Get the list of children props in WidgetAddChild format
    const childWidgetList: WidgetAddChild[] = yield call(
      buildWidgetBlueprint,
      blueprint,
      widget.widgetId
    );
    // For each child props
    const childPropsList: GeneratedWidgetPayload[] = yield all(
      childWidgetList.map((props: WidgetAddChild) => {
        // Generate full widget props
        // Notice that we're passing the blueprint if it exists.
        return generateChildWidgets(
          widgetIds,
          widget,
          props,
          widgets,
          props.props?.blueprint
        );
      })
    );
    // Start children array from scratch
    widget.children = [];
    childPropsList.forEach((props: GeneratedWidgetPayload) => {
      // Push the widgetIds of the children generated above into the widget.children array
      widget.children.push(props.widgetId);
      // Add the list of widgets generated into the canvasWidgets
      widgets = props.widgets;
    });
  }

  // Finally, add the widget to the canvasWidgets
  // This is different from above, as this is the final widget props with
  // a fully populated widget.children property
  widgets[widget.widgetId] = widget;

  // Some widgets need to run a few operations like modifying props or adding an action
  // these operations can be performed on the parent of the widget we're adding
  // therefore, we pass all widgets to executeWidgetBlueprintOperations
  // blueprint.operations contain the set of operations to perform to update the canvasWidgets
  if (blueprint && blueprint.operations && blueprint.operations.length > 0) {
    // Finalize the canvasWidgets with everything that needs to be updated
    widgets = yield call(
      executeWidgetBlueprintOperations,
      blueprint.operations,
      widgets,
      widget.widgetId
    );
  }

  // Add the parentId prop to this widget
  widget.parentId = parent.widgetId;
  // Remove the blueprint from the widget (if any)
  // as blueprints are not useful beyond this point.
  delete widget.blueprint;

  // deleting propertyPaneEnchancements too as it shouldn't go in dsl because
  // function can't be cloned into dsl

  // instead of passing whole enhancments function in widget props, we are just setting
  // enhancments as true so that we know this widget contains enhancments
  if ('enhancements' in widget) {
    widget.enhancements = true;
  }

  return { widgetId: widget.widgetId, widgets };
}

// widgetAdditionSaga 中为单个添加tab，生成组件实体后添加到tabs组件属性，并渲染dom
// 动态渲染tabs 需注意tabs的children属性包含所有tab item 的widgetId「由generateReactKey()生成」
// getEntityNames方法获取页面上所有组件和实体names，需保证已生成未注册的tab item有独立的widgetName
function* getUpdateDslAfterBatchCreateChildren(
  addChildPayload: WidgetAddChild,
  widgetIds: string[]
) {
  // NOTE: widgetId here is the parentId of the dropped widget ( we should rename it to avoid confusion )
  const { widgetId } = addChildPayload;
  // Get the current parent widget whose child will be the new widget.
  const stateParent: FlattenedWidgetProps = yield select(getWidget, widgetId);
  // const parent = Object.assign({}, stateParent);
  // Get all the widgets from the canvasWidgetsReducer
  const stateWidgets = yield select(getWidgets);
  const widgets = Object.assign({}, stateWidgets);
  // Generate the full WidgetProps of the widget to be added.
  const childWidgetPayload: GeneratedWidgetPayload = yield generateChildWidgets(
    // 批量添加的tab item widgetId
    widgetIds,
    stateParent,
    addChildPayload,
    widgets,
    // sending blueprint for onboarding usecase
    addChildPayload.props?.blueprint
  );

  const newWidget = childWidgetPayload.widgets[childWidgetPayload.widgetId];

  const parentBottomRow = getParentBottomRowAfterAddingWidget(
    stateParent,
    newWidget
  );

  // Update widgets to put back in the canvasWidgetsReducer
  // TODO(abhinav): This won't work if dont already have an empty children: []
  const parent = {
    ...stateParent,
    bottomRow: parentBottomRow,
    children: [...(stateParent.children || []), childWidgetPayload.widgetId],
  };

  widgets[parent.widgetId] = parent;
  AppsmithConsole.info({
    text: '创建组件',
    source: {
      type: ENTITY_TYPE.WIDGET,
      id: childWidgetPayload.widgetId,
      name: childWidgetPayload.widgets[childWidgetPayload.widgetId].widgetName,
    },
  });
  yield put({
    type: WidgetReduxActionTypes.WIDGET_CHILD_ADDED,
    payload: {
      widgetId: childWidgetPayload.widgetId,
      type: addChildPayload.type,
    },
  });
  // some widgets need to update property of parent if the parent have CHILD_OPERATIONS
  // so here we are traversing up the tree till we get to MAIN_CONTAINER_WIDGET_ID
  // while traversing, if we find any widget which has CHILD_OPERATION, we will call the fn in it
  const updatedWidgets: CanvasWidgetsReduxState = yield call(
    traverseTreeAndExecuteBlueprintChildOperations,
    parent,
    addChildPayload.newWidgetId,
    widgets
  );
  return updatedWidgets;
}

function* updateTabsFromSourceSaga(
  updateTabsAction: ReduxAction<WidgetDynamicTabs>
) {
  const { onSuccess, tabs, widgetId } = updateTabsAction.payload;

  const newTabs = tabs.reduce((acc, cur, index) => {
    const generateTabId = generateReactKey();
    acc[cur.id] = {
      index,
      isVisible: true,
      widgetId: generateTabId,
      ...cur,
    };
    return acc;
  }, {});
  const unRegisterTabWidgetIds = Object.values(newTabs).map(
    (tab: any) => tab.widgetId
  );

  const tabProps: WidgetProps = yield select(getWidget, widgetId);
  const updatedWidgetList = yield all(
    Object.values(newTabs).map((tab: any) => {
      const newTabProps: any = getChildTabData(tabProps, tab);
      return getUpdateDslAfterBatchCreateChildren(
        newTabProps,
        unRegisterTabWidgetIds
      );
    })
  );

  const mergedWidgets = updatedWidgetList.reduce((acc, cur) => {
    return { ...acc, ...cur };
  }, {});

  const updatedWidgets: any = Object.keys(mergedWidgets).reduce(
    (acc, cur: any) => {
      const curWidget = mergedWidgets[cur];
      const isOldChild =
        curWidget.parentId === widgetId &&
        !unRegisterTabWidgetIds.includes(cur);
      if (!isOldChild) acc[cur] = curWidget;
      return acc;
    },
    {}
  );

  updatedWidgets[widgetId]['tabsObj'] = newTabs;
  updatedWidgets[widgetId]['children'] = [...unRegisterTabWidgetIds];
  yield put(updateAndSaveLayout(updatedWidgets));

  onSuccess(unRegisterTabWidgetIds[0]);
}

export default function* widgetDynamicTabsSagas() {
  yield all([
    takeEvery(
      ReduxActionTypes.WIDGET_DYNAMIC_TABS_FROM_SOUCE,
      updateTabsFromSourceSaga
    ),
  ]);
}
