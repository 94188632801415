import { ReduxActionTypes } from '@appsmith/constants/ReduxActionConstants';
import {
  filterCategories,
  RecentEntity,
  SearchCategory,
  SEARCH_CATEGORY_ID,
} from 'components/editorComponents/GlobalSearch/utils';
import { SysConfigMenuName } from 'pages/Editor/Explorer/GlobalSysConfig/constants';

export const setGlobalSearchQuery = (query: string) => ({
  type: ReduxActionTypes.SET_GLOBAL_SEARCH_QUERY,
  payload: query,
});

export const toggleShowGlobalSearchModal = (
  category: SearchCategory = filterCategories[SEARCH_CATEGORY_ID.DOCUMENTATION]
) => ({
  type: ReduxActionTypes.TOGGLE_SHOW_GLOBAL_SEARCH_MODAL,
  payload: category,
});

export const createTempleteQueryParams = (payload: {
  applicationSlug: string;
  pageSlug: string;
  applicationId: string;
}) => ({
  type: ReduxActionTypes.CREATE_TEMPLETE_QUERY_PARAMS,
  payload,
});

export const editGraphVisible = (payload: boolean) => ({
  type: ReduxActionTypes.GRAPH_VISIBLE,
  payload,
});

export const FIRST_GUIDE = (payload: boolean | number) => ({
  type: ReduxActionTypes.FIRST_GUIDE,
  payload,
});

export const setGraphLoading = (payload: boolean) => ({
  type: ReduxActionTypes.Graph_Loading,
  payload,
});

export const createTempleteQueryId = (payload: string) => ({
  type: ReduxActionTypes.CREATE_TEMPLETE_QUERY_ID,
  payload,
});

export const setQueryDrawerWrapperRef = (payload: HTMLDivElement) => ({
  type: ReduxActionTypes.SET_QUERY_DRAWER_WRAPPER_REF,
  payload,
});

export const controlQueryDrawer = (payload: boolean) => ({
  type: ReduxActionTypes.SHOW_EDIT_QUERY_DRAWER,
  payload,
});

export const controlAppSysConfigDrawer = (payload: boolean) => ({
  type: ReduxActionTypes.SHOW_APP_SYS_CONFIG_DRAWER,
  payload,
});

export const controlAppSysConfigPage = (payload: SysConfigMenuName) => ({
  type: ReduxActionTypes.SET_APP_SYS_CONFIG_PAGE,
  payload,
});

export const controlQueryNameEditor = (payload: boolean) => ({
  type: ReduxActionTypes.TOGGLE_EDIT_QUERY_NAME_EDITOR,
  payload,
});

export const controlCreateQuery = (payload: boolean) => ({
  type: ReduxActionTypes.SHOW_CREATE_QUERY,
  payload,
});

export const modifyQueryDrawerType = (payload: 'api' | 'js' | 'sql') => ({
  type: ReduxActionTypes.MODIFY_QUERY_DRAWER_TYPE,
  payload,
});

export const cancelSnippet = () => ({
  type: ReduxActionTypes.CANCEL_SNIPPET,
});

export const evaluateSnippet = (payload: {
  expression: string;
  dataType?: string;
  isTrigger?: boolean;
}) => ({
  type: ReduxActionTypes.EVALUATE_SNIPPET,
  payload,
});

export const evaluateArgument = (payload: {
  name: string;
  type: string;
  value: string;
}) => ({
  type: ReduxActionTypes.EVALUATE_ARGUMENT,
  payload,
});

export const setEvaluatedSnippet = (payload: any) => ({
  type: ReduxActionTypes.SET_EVALUATED_SNIPPET,
  payload,
});

export const setEvaluatedArgument = (payload: any) => ({
  type: ReduxActionTypes.SET_EVALUATED_ARGUMENT,
  payload,
});

export const unsetEvaluatedArgument = () => ({
  type: ReduxActionTypes.UNSET_EVALUATED_ARGUMENT,
});

export const insertSnippet = (payload: string) => ({
  type: ReduxActionTypes.INSERT_SNIPPET,
  payload,
});

export const setGlobalSearchFilterContext = (payload: any) => ({
  type: ReduxActionTypes.SET_SEARCH_FILTER_CONTEXT,
  payload,
});

export const updateRecentEntity = (payload: RecentEntity) => ({
  type: ReduxActionTypes.UPDATE_RECENT_ENTITY,
  payload,
});

export const restoreRecentEntitiesRequest = (payload: {
  applicationId: string;
  branch?: string;
}) => ({
  type: ReduxActionTypes.RESTORE_RECENT_ENTITIES_REQUEST,
  payload,
});

export const restoreRecentEntitiesSuccess = () => ({
  type: ReduxActionTypes.RESTORE_RECENT_ENTITIES_SUCCESS,
});

export const resetRecentEntities = () => ({
  type: ReduxActionTypes.RESET_RECENT_ENTITIES,
});

export const setRecentEntities = (payload: Array<RecentEntity>) => ({
  type: ReduxActionTypes.SET_RECENT_ENTITIES,
  payload,
});
