import React, { CSSProperties } from 'react';
import { ControlIcons } from 'icons/ControlIcons';
import Icon, { IconSize } from 'components/icon';
import { Colors } from 'constants/Colors';
import styled from 'styled-components';
import { Tooltip, Classes } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { snipingModeSelector } from 'selectors/editorSelectors';
import SvgIcon from 'components/icon';

// I honestly can't think of a better name for this enum
export enum Activities {
  HOVERING,
  SELECTED,
  ACTIVE,
  NONE,
}
const StyledTooltip = styled(Tooltip)`
  .${Classes.POPOVER_TARGET} {
    height: 100%;
  }
`;
const SettingsWrapper = styled.div`
  justify-self: flex-end;
  height: 100%;
  padding: 2px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & {
    pre {
      margin: 0 5px 0 0;
      font-size: ${(props) => props.theme.fontSizes[3]}px;
      height: ${(props) => props.theme.fontSizes[3]}px;
      line-height: ${(props) => props.theme.fontSizes[3] - 1}px;
    }
  }
  border-radius: 2px;
`;

const WidgetName = styled.span`
  /* margin-right: ${(props) => props.theme.spaces[1] + 1}px;
  margin-left: ${(props) => props.theme.spaces[3]}px; */
  margin: 0 4px;
  white-space: nowrap;
`;

const StyledErrorIcon = styled(Icon)`
  &:hover {
    svg {
      path {
        fill: ${Colors.WHITE};
      }
    }
  }
  margin-right: ${(props) => props.theme.spaces[1]}px;
`;

type SettingsControlProps = {
  toggleSettings: (e: any) => void;
  activity: Activities;
  name: string;
  errorCount: number;
};

const BindDataIcon = ControlIcons.BIND_DATA_CONTROL;
const SettingsIcon = ControlIcons.SETTINGS_CONTROL;

const getStyles = (
  activity: Activities,
  errorCount: number,
  isSnipingMode: boolean
): CSSProperties | undefined => {
  if (isSnipingMode) {
    return {
      background: Colors.DANUBE,
      color: Colors.WHITE,
    };
  } else if (errorCount > 0) {
    return {
      background: 'red',
      color: Colors.WHITE,
    };
  }

  switch (activity) {
    case Activities.ACTIVE:
      return {
        background: Colors.SLATE_GRAY,
        color: Colors.WHITE,
      };
    case Activities.HOVERING:
      return {
        background: Colors.WATUSI,
        color: Colors.WHITE,
      };
    case Activities.SELECTED:
      return {
        background: Colors.SLATE_GRAY,
        color: Colors.WHITE,
      };
  }
};

export function SettingsControl(props: SettingsControlProps) {
  const isSnipingMode = useSelector(snipingModeSelector);
  const settingsIcon = (
    <SettingsIcon
      color={
        // !!props.errorCount
        //   ? Colors.WHITE
        //   : props.activity === Activities.HOVERING
        //   ? Colors.WHITE
        // :
        Colors.WHITE
      }
      height={14}
      width={12}
    />
  );
  const errorIcon = (
    <StyledErrorIcon
      className="text-[12px]"
      fillColor={Colors.WHITE}
      name="warning"
    />
  );

  return (
    <StyledTooltip
      content={isSnipingMode ? `绑定到组件 ${props.name}` : '编辑组件属性'}
      hoverOpenDelay={500}
      position="top-right"
    >
      <SettingsWrapper
        className="t--widget-propertypane-toggle"
        data-testid="t--widget-propertypane-toggle"
        onClick={props.toggleSettings}
        style={getStyles(props.activity, props.errorCount, isSnipingMode)}
      >
        {!!props.errorCount && !isSnipingMode ? (
          <>
            {errorIcon}
            <span className="t--widget-error-count text-12">
              {props.errorCount}
            </span>
          </>
        ) : (
          <SvgIcon className="text-[10px]" name="draghandler" />
        )}
        {isSnipingMode && (
          <BindDataIcon color={Colors.WHITE} height={16} width={12} />
        )}
        <WidgetName className="t--widget-name text-12">
          {isSnipingMode ? `绑定到 ${props.name}` : props.name}
        </WidgetName>
        {!isSnipingMode && settingsIcon}
      </SettingsWrapper>
    </StyledTooltip>
  );
}

export default SettingsControl;
