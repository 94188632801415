import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Progress } from '@arco-design/web-react';
import Icon from 'components/icon';
import { useQuery } from 'react-query';
import { UserCenterApi } from 'api/UserCenterApi';
import OrdinaryBg from 'assets/images/accountInterests_bg.svg';
import MemberBg from 'assets/images/accountInterests_bg2.svg';
import SpecialtyBg from 'assets/images/accountInterests_bg3.svg';
import FlagshipBg from 'assets/images/accountInterests_bg4.svg';
import commonBg from 'assets/images/commonBg.svg';

import { get, keyBy } from 'lodash';

const AccountHead = styled.div`
  /* background-color: pink; */
  /* background: url(${commonBg}) no-repeat; */
  /* background-size: 100% 100%;
  background-size: contain; */
  height: 96px;
  width: 100%;
  border-radius: 12px;
  /* padding: 16px 24px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
  position: relative;
  overflow: hidden;
`;

const PermissionContainer = styled.div`
  .arco-progress-large {
    margin-right: 0 !important;
  }
  .arco-progress-circle-wrapper {
    width: 120px !important;
    height: 120px !important;
  }
`;
const CompareContainer = styled.div`
  ul > li {
    width: 196px;
    height: 110px;
    padding: 16px 0 16px 24px;
    border-radius: 16px 16px 0px 0px;
    color: #fff;
    /* margin-left: 16px; */
  }
  ul > :nth-child(1) {
    background: url(${OrdinaryBg}) no-repeat;
  }
  ul > :nth-child(2) {
    background: url(${MemberBg}) no-repeat;
  }
  ul > :nth-child(3) {
    background: url(${SpecialtyBg}) no-repeat;
  }
  ul > :nth-child(4) {
    background: url(${FlagshipBg}) no-repeat;
  }
`;

const detailDate = [
  {
    title: '基础能力',
    children: [
      {
        name: '创建应用数量',
        value: ['10个', '20个', '130个', '300个'],
      },
      {
        name: '可创建页面数量',
        value: ['200个', '1000个', '2000个', '不限制'],
      },
      {
        name: '团队成员数量',
        value: ['0个', '0个', '5个', '15个'],
      },
      {
        name: '数据占用空间',
        value: ['100M', '1GB', '8GB', '20GB'],
      },
    ],
  },
];
const detailDate2 = [
  {
    title: '使用模板',
    children: [
      {
        name: '应用模板（限免）',
        value: [true, true, true, true],
      },
      {
        name: '页面模板',
        value: [true, true, true, true],
      },
    ],
  },
  {
    title: '数据连接能力',
    children: [
      {
        name: '各类数据库连接',
        value: [true, true, true, true],
      },
      {
        name: 'NEEDEL工具',
        value: [false, true, true, true],
      },
      {
        name: '可视化SQL工具',
        value: [false, true, true, true],
      },
      {
        name: 'API连接工具',
        value: [false, true, true, true],
      },
    ],
  },
  {
    title: '部署应用',
    children: [
      {
        name: '部署应用',
        value: [false, true, true, true],
      },
    ],
  },
  {
    title: '平台服务',
    children: [
      {
        name: '平台服务',
        value: [true, true, true, true],
      },
      {
        name: '24h服务群',
        value: [false, true, true, true],
      },
      {
        name: '专属技术指导',
        value: [false, false, true, true],
      },
      {
        name: '协助实现功能需求',
        value: [false, false, false, true],
      },
    ],
  },
];
export default function AccountInterests() {
  const [title, setTitle] = useState('');
  const res = useQuery('Interestlist', UserCenterApi.getUserInterest);
  const { data } = useQuery('info', UserCenterApi.getInterestInfo);
  useEffect(() => {
    setTitle(data?.data?.title);
  }, [data]);

  const versionInfo = useMemo(() => {
    return res ? keyBy(get(res, 'data.data', []), (v) => v.title) : {};
  }, [res]);

  // console.log(res, versionInfo[title], '444');
  // console.log(title, 'title');

  const permissionData = [
    {
      category: '应用数量',
      remainder: data?.data?.count || 3,
      upperLimit: versionInfo[title]?.count || 10,
    },
    {
      category: '页面数量',
      remainder: data?.data?.pages || 100,
      upperLimit: versionInfo[title]?.pages || 200,
    },
    {
      category: '数据占用空间',
      remainder: 99,
      upperLimit:
        versionInfo[title]?.data === 100
          ? versionInfo[title]?.data
          : versionInfo[title]?.data / 1024 || 100,
    },
    {
      category: '账户（成员）数量',
      remainder: 0,
      upperLimit: versionInfo[title]?.numbers || 0,
    },
  ];
  function UserInterest() {
    switch (title) {
      case '普通版':
        return (
          <div className="h-[96px] w-full rounded-[12px] flex justify-between items-center mb-[64px] overflow-hidden relative">
            <img src={OrdinaryBg} width="100%" />
            <div className="w-full h-[100%] absolute left-0 top-0 py-[16px] px-[24px] flex justify-between items-center">
              <div className="text-[#fff]">
                <div className="mb-[16px] flex items-center">
                  <div className="text-20 font-bold mr-[16px]">普通版</div>
                  <div className="w-[52px] h-[24px] border-[#fff] border-1 rounded-12 flex items-center justify-center font-hm-14">
                    试用
                  </div>
                </div>
                <div className=" font-hm-14">
                  截止日期 {data?.data?.expireTime}
                </div>
              </div>
              <div
                className="w-[110px] h-[40px] rounded-[20px] text-[#FAE0A5] font-hm-14 flex items-center justify-center cursor-pointer"
                style={{
                  background:
                    ' linear-gradient(90deg, #454952 0%, #272A33 100%)',
                }}
              >
                联系销售升级
              </div>
            </div>
          </div>
        );
      case '会员版':
        return (
          <div className="h-[96px] w-full rounded-[8px] flex justify-between items-center mb-[64px] overflow-hidden relative">
            <img src={MemberBg} width="100%" />
            <div className="w-full h-[100%] absolute left-0 top-0 py-[16px] px-[24px] flex justify-between items-center">
              <div className="text-[#fff]">
                <div className="mb-[16px] flex items-center">
                  <div className="text-20 font-bold mr-[16px]">会员版</div>
                </div>
                <div className=" font-hm-14">
                  截止日期 {data?.data?.expireTime}
                </div>
              </div>
              <div
                className="w-[110px] h-[40px] rounded-[20px] text-[#FAE0A5] font-hm-14 flex items-center justify-center cursor-pointer"
                style={{
                  background:
                    ' linear-gradient(90deg, #454952 0%, #272A33 100%)',
                }}
              >
                联系销售升级
              </div>
            </div>
          </div>
        );
      case '专业版':
        return (
          <div className="h-[96px] w-full rounded-[8px] flex justify-between items-center mb-[64px]  overflow-hidden relative">
            <img src={SpecialtyBg} width="100%" />
            <div className="w-full h-[100%] absolute left-0 top-0 py-[16px] px-[24px] flex justify-between items-center">
              <div className="text-[#fff]">
                <div className="mb-[16px] flex items-center">
                  <div className="text-20 font-bold mr-[16px]">专业版</div>
                </div>
                <div className=" font-hm-14">
                  截止日期 {data?.data?.expireTime}
                </div>
              </div>
              <div
                className="w-[110px] h-[40px] rounded-[20px] text-[#FAE0A5] font-hm-14 flex items-center justify-center cursor-pointer"
                style={{
                  background:
                    ' linear-gradient(90deg, #454952 0%, #272A33 100%)',
                }}
              >
                联系销售升级
              </div>
            </div>
          </div>
        );
      case '旗舰版':
        return (
          <div className="h-[96px] w-full rounded-[8px] flex justify-between items-center mb-[64px] overflow-hidden relative">
            <img src={FlagshipBg} width="100%" />
            <div className="w-full h-[100%] absolute left-0 top-0 py-[16px] px-[24px] flex justify-between items-center">
              <div className="text-[#fff]">
                <div className="mb-[16px] flex items-center">
                  <div className="text-20 font-bold mr-[16px]">旗舰版</div>
                </div>
                <div className=" font-hm-14">
                  截止日期 {data?.data?.expireTime}
                </div>
              </div>
            </div>
          </div>
        );
    }
  }
  return (
    <div className="p-[24px]">
      <div className="bg-[#fff] h-[428px] rounded-[16px] p-[24px] mb-[24px]">
        {data?.data?.title ? (
          UserInterest()
        ) : (
          <AccountHead>
            <img src={commonBg} width="100%" />
            <div className="w-full h-[100%] absolute left-0 top-0 py-[16px] px-[24px] flex justify-between items-center">
              <div className="text-[#fff]">
                <div className="mb-[16px] flex items-center">
                  <div className="text-20 font-bold mr-[16px]">普通版</div>
                  <div className="w-[52px] h-[24px] border-[#fff] border-1 rounded-12 flex items-center justify-center font-hm-14">
                    试用
                  </div>
                </div>
                <div className=" font-hm-14">截止日期 2022.10.23</div>
              </div>
              <div
                className="w-[110px] h-[40px] rounded-[20px] text-[#FAE0A5] font-hm-14 flex items-center justify-center cursor-pointer"
                style={{
                  background:
                    ' linear-gradient(90deg, #454952 0%, #272A33 100%)',
                }}
              >
                联系销售升级
              </div>
            </div>
          </AccountHead>
        )}
        <PermissionContainer className="flex justify-around">
          {permissionData.map((item) => {
            return (
              <div className="flex items-center flex-col" key={item.category}>
                <div className="font-hm-14 text-[#333] mb-[16px]">
                  {item.category}
                </div>
                <div>
                  <Progress
                    color="#E8C292"
                    formatText={() => {
                      return item.upperLimit === -1 ? (
                        <div className="text-14 font-bold text-[#000]">
                          100%
                        </div>
                      ) : (
                        <div>
                          <div className="font-bold text-14 text-[#000] mb-[8px]">
                            {item.remainder}
                            <span className="text-12 text-[#999] ml-[2px]">
                              {item.category === '数据占用空间'
                                ? item.upperLimit === 100
                                  ? 'M'
                                  : 'GB'
                                : '个'}
                            </span>
                          </div>
                          <div className="text-12 text-[#999]">剩余</div>
                        </div>
                      );
                    }}
                    percent={
                      (item.upperLimit === -1
                        ? 100
                        : item.remainder / item.upperLimit) * 100
                    }
                    size="large"
                    style={{ marginRight: 80 }}
                    type="circle"
                  />
                </div>
                {item.upperLimit === -1 ? (
                  <div className="pt-[16px] font-hm-14 text-[#666]">不限制</div>
                ) : (
                  <div className="pt-[16px] font-hm-14 text-[#666]">
                    上限{item.upperLimit}
                    <span>
                      {item.category === '数据占用空间'
                        ? item.upperLimit === 100
                          ? 'M'
                          : 'GB'
                        : '个'}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </PermissionContainer>
      </div>
      <CompareContainer className="bg-[#fff] h-[1541px] rounded-[16px] px-[80px] py-[40px]">
        <div className="h-[110px] flex items-center mb-[24px]">
          <div className="text-20 text-[#333] font-bold mr-[18px] felx">
            版本权益比较
          </div>
          <ul className="flex justify-around flex-1">
            <li>
              <div className="text-20 font-bold mb-[16px]">
                普通版
                <span className="text-12 font-normal ml-[10px]">
                  限时免费(90天)
                </span>
              </div>
              <div className="text-14">
                ￥<span className="text-24 font-bold">999</span>
                /年
              </div>
            </li>
            <li>
              <div className="text-20 font-bold mb-[16px]">会员版</div>
              <div className="text-14">
                ￥<span className="text-24 font-bold">5000</span>
                /年
              </div>
            </li>
            <li>
              <div className="text-20 font-bold mb-[16px]">专业版</div>
              <div className="text-14">
                ￥<span className="text-24 font-bold">19999</span>
                /年
              </div>
            </li>
            <li>
              <div className="text-20 font-bold mb-[16px]">旗舰版</div>
              <div className="text-14">
                ￥<span className="text-24 font-bold">59999</span>
                /年
              </div>
            </li>
          </ul>
        </div>
        <div className="mb-[24px]">
          {detailDate.map((item, index) => {
            return (
              <div key={item.title + index}>
                <div className="font-bold text-16 leading-[52px] border-b-1 border-[#eee]">
                  {item.title}
                </div>
                {item.children.map((item2, index2) => {
                  return (
                    <div
                      className="h-[62px] flex  justify-between border-b-1 border-[#eee] items-center"
                      key={item2.name + index2}
                    >
                      <div className="font-hm-14 text-[#666] w-[154px] flex text-left">
                        {item2.name}
                      </div>
                      <div className="flex justify-between flex-1">
                        {item2.value.map((item3, index3) => {
                          return (
                            <div
                              className="font-hm-14 text-[#333] w-[196px] text-center"
                              key={item3 + index3}
                            >
                              {item3}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div>
          {detailDate2.map((item) => (
            <div className="mb-[24px]" key={item.title}>
              <div className="font-bold text-16 leading-[52px] border-b-1 border-[#eee]">
                {item.title}
              </div>
              {item.children.map((item2) => (
                <div
                  className="h-[62px] flex justify-between border-b-1 border-[#eee] items-center"
                  key={item2.name}
                >
                  <div className="font-hm-14 text-[#666] w-[154px] flex">
                    {item2.name}
                  </div>
                  <div className="flex justify-between flex-1">
                    {item2.value.map((item3, index) => (
                      <div
                        className="font-hm-14 text-[#333] w-[196px] flex items-center justify-center"
                        key={`${item2.name}-${index}`}
                      >
                        {item3 ? (
                          <Icon name="trueIcon" />
                        ) : (
                          <Icon name="falseIcon" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </CompareContainer>
    </div>
  );
}
