import { DropdownOption } from 'components/ads/Dropdown';
import { DatasourceTable } from 'entities/Datasource';
import { IconName } from 'components/icon';

export type DropdownOptions = Array<DropdownOption>;

export interface DatasourceTableDropdownOption extends DropdownOption {
  data: DatasourceTable;
  extra?: DatasourceTable;
}

export const PLUGIN_PACKAGE_NAME = {
  MONGO_DB: 'mongo-plugin',
  S3: 'amazons3-plugin',
  GOOGLE_SHEETS: 'google-sheets-plugin',
  FIRESTORE: 'firestore-plugin',
};

export const MAX_SEARCH_COLUMN_NUM = 6;

export const PluginFormInputFieldMap: Record<
  string,
  { DATASOURCE: string; TABLE: string; COLUMN: string }
> = {
  [PLUGIN_PACKAGE_NAME.MONGO_DB]: {
    DATASOURCE: 'MongoDB',
    TABLE: 'collection',
    COLUMN: 'field',
  },
  [PLUGIN_PACKAGE_NAME.S3]: {
    DATASOURCE: 'S3',
    TABLE: 'bucket',
    COLUMN: 'keys',
  },
  [PLUGIN_PACKAGE_NAME.GOOGLE_SHEETS]: {
    DATASOURCE: 'Google Sheets',
    TABLE: 'spreadsheet',
    COLUMN: 'keys',
  },
  DEFAULT: {
    DATASOURCE: 'SQL Based',
    TABLE: 'table',
    COLUMN: 'column',
  },
};

export const DROPDOWN_DIMENSION = {
  HEIGHT: '36px',
  WIDTH: '404px',
};

export const DEFAULT_DROPDOWN_OPTION = {
  id: '- Select -',
  label: '- Select -',
  value: undefined,
  onSelect: () => null,
  data: {},
};

export const ALLOWED_SEARCH_DATATYPE = [
  'text',
  'string',
  'char',
  'varchar',
  'varchar2', // NOTEl: To support ORACLE which string type is "varchar2"
  'character',
  'text string',
  // support SQLServer
  'nvarchar',
  'ntext',
];

/**
 * GeneratePageForm
 */

export const datasourceIcon: IconName = 'tables';

export const columnIcon: IconName = 'column';

export const GENERATE_PAGE_MODE = {
  NEW: 'NEW', // a new page is created for the template. (new pageId created)
  REPLACE_EMPTY: 'REPLACE_EMPTY', // current page's content (DSL) is updated to template DSL. (same pageId)
};
