import React, { JSXElementConstructor } from 'react';
import { IconProps, IconWrapper } from 'constants/IconConstants';
import Icon from 'components/icon';

import IncreaseV2Icon from 'remixicon-react/AddLineIcon';
import PlayIcon from 'assets/icons/control/play-icon.png';
import CopyIcon from 'remixicon-react/FileCopyLineIcon';
import QuestionIcon from 'remixicon-react/QuestionLineIcon';
import SettingsIcon from 'remixicon-react/Settings5LineIcon';
import EyeIcon from 'remixicon-react/EyeLineIcon';
import EyeOffIcon from 'remixicon-react/EyeOffLineIcon';
// import CloseIcon from 'remixicon-react/CloseLineIcon';

export const ControlIcons: {
  [id: string]: JSXElementConstructor<IconProps>;
} = {
  DELETE_CONTROL: (props: IconProps) => <Icon {...props} name="delete" />,
  MOVE_CONTROL: (props: IconProps) => <Icon {...props} name={'move'} />,
  EDIT_CONTROL: (props: IconProps) => <Icon {...props} name={'edit'} />,
  VIEW_CONTROL: (props: IconProps) => <Icon {...props} name={'view'} />,
  MORE_VERTICAL_CONTROL: (props: IconProps) => (
    <Icon {...props} name={'more-vertical'} />
  ),
  MORE_HORIZONTAL_CONTROL: (props: IconProps) => (
    <Icon {...props} name={'overflow-menu'} />
  ),
  JS_TOGGLE: (props: IconProps) => <Icon {...props} name={'js-toggle'} />,
  INCREASE_CONTROL: (props: IconProps) => <Icon {...props} name={'increase'} />,
  DECREASE_CONTROL: (props: IconProps) => <Icon {...props} name={'decrease'} />,
  DRAGGABLE_CONTROL: (props: IconProps) => (
    <Icon {...props} name={'draggable'} />
  ),
  CLOSE_CONTROL: (props: IconProps) => (
    <Icon {...props} name={'close-circle'} />
  ),
  CLOSE_CIRCLE_CONTROL: (props: IconProps) => (
    <Icon {...props} name={'close-circle'} />
  ),
  ADD_CIRCLE_CONTROL: (props: IconProps) => (
    <Icon {...props} name={'add-circle'} />
  ),
  PICK_MY_LOCATION_SELECTED_CONTROL: (props: IconProps) => (
    <Icon {...props} name={'pick-location-selected'} />
  ),
  SETTINGS_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <SettingsIcon />
    </IconWrapper>
  ),
  HELP_CONTROL: (props: IconProps) => <Icon {...props} name={'help'} />,
  PLAY_VIDEO: (props: IconProps) => (
    <IconWrapper {...props}>
      <img
        alt="Datasource"
        src={PlayIcon}
        style={{ height: '30px', width: '30px' }}
      />
    </IconWrapper>
  ),
  REMOVE_CONTROL: (props: IconProps) => <Icon {...props} name={'remove'} />,
  DRAG_CONTROL: (props: IconProps) => <Icon {...props} name={'drag'} />,
  COLLAPSE_CONTROL: (props: IconProps) => <Icon />,
  SORT_CONTROL: (props: IconProps) => <Icon {...props} name={'sort-icon'} />,
  EDIT_WHITE: (props: IconProps) => <Icon {...props} name={'edit-white'} />,
  LAUNCH_CONTROL: (props: IconProps) => <Icon {...props} name={'launch'} />,
  BACK_CONTROL: (props: IconProps) => <Icon {...props} name={'back'} />,
  SHOW_COLUMN: (props: IconProps) => (
    <IconWrapper {...props}>
      <EyeIcon />
    </IconWrapper>
  ),
  HIDE_COLUMN: (props: IconProps) => (
    <IconWrapper {...props}>
      <EyeOffIcon />
    </IconWrapper>
  ),
  DELETE_COLUMN: (props: IconProps) => (
    <Icon {...props} name={'delete-column'} />
  ),
  BOLD_FONT: (props: IconProps) => <Icon {...props} name={'bold'} />,
  UNDERLINE: (props: IconProps) => <Icon {...props} name={'underline'} />,
  ITALICS_FONT: (props: IconProps) => <Icon {...props} name={'italics'} />,
  LEFT_ALIGN: (props: IconProps) => <Icon {...props} name={'align_left'} />,
  CENTER_ALIGN: (props: IconProps) => <Icon {...props} name={'align_center'} />,
  RIGHT_ALIGN: (props: IconProps) => <Icon {...props} name={'align_right'} />,
  HORIZONTAL: (props: IconProps) => <Icon {...props} name={'horizontal'} />,
  VERTICAL: (props: IconProps) => <Icon {...props} name={'vertical'} />,
  VERTICAL_RIGHT: (props: IconProps) => (
    <Icon {...props} name={'align_right'} />
  ),
  VERTICAL_LEFT: (props: IconProps) => <Icon {...props} name={'align_left'} />,
  VERTICAL_TOP: (props: IconProps) => (
    <Icon {...props} name={'vertical_align_top'} />
  ),
  VERTICAL_CENTER: (props: IconProps) => (
    <Icon {...props} name={'vertical_align_center'} />
  ),
  VERTICAL_BOTTOM: (props: IconProps) => (
    <Icon {...props} name={'vertical_align_bottom'} />
  ),
  COPY_CONTROL: (props: IconProps) => (
    <IconWrapper {...props}>
      <CopyIcon />
    </IconWrapper>
  ),
  COPY2_CONTROL: (props: IconProps) => <Icon {...props} name={'copy2'} />,
  CUT_CONTROL: (props: IconProps) => <Icon {...props} name={'cut'} />,
  GROUP_CONTROL: (props: IconProps) => <Icon {...props} name={'group'} />,
  HEADING_ONE: (props: IconProps) => <Icon {...props} name={'heading_1'} />,
  HEADING_TWO: (props: IconProps) => <Icon {...props} name={'heading_2'} />,
  HEADING_THREE: (props: IconProps) => <Icon {...props} name={'heading_3'} />,
  PARAGRAPH: (props: IconProps) => <Icon {...props} name={'paragraph'} />,
  PARAGRAPH_TWO: (props: IconProps) => <Icon {...props} name={'paragraph_2'} />,
  BULLETS: (props: IconProps) => <Icon {...props} name={'bullets'} />,
  DIVIDER_CAP_RIGHT: (props: IconProps) => (
    <Icon {...props} name={'divider_cap_right'} />
  ),
  DIVIDER_CAP_LEFT: (props: IconProps) => (
    <Icon {...props} name={'divider_cap_left'} />
  ),
  DIVIDER_CAP_ALL: (props: IconProps) => (
    <Icon {...props} name={'divider_cap_all'} />
  ),
  BIND_DATA_CONTROL: (props: IconProps) => (
    <Icon {...props} name={'trending-flat'} />
  ),
  ICON_ALIGN_LEFT: (props: IconProps) => (
    <Icon {...props} name={'align_left'} />
  ),
  ICON_ALIGN_RIGHT: (props: IconProps) => (
    <Icon {...props} name={'align_right'} />
  ),
  BORDER_RADIUS_SHARP: (props: IconProps) => (
    <Icon {...props} name={'border-radius-sharp'} />
  ),
  BORDER_RADIUS_ROUNDED: (props: IconProps) => (
    <Icon {...props} name={'border-radius-rounded'} />
  ),
  BORDER_RADIUS_CIRCLE: (props: IconProps) => (
    <Icon {...props} name={'border-radius-circle'} />
  ),
  BOX_SHADOW_NONE: (props: IconProps) => (
    <Icon {...props} name={'box-shadow-none'} />
  ),
  BOX_SHADOW_VARIANT1: (props: IconProps) => (
    <Icon {...props} name={'box-shadow-variant1'} />
  ),
  BOX_SHADOW_VARIANT2: (props: IconProps) => (
    <Icon {...props} name={'box-shadow-variant2'} />
  ),
  BOX_SHADOW_VARIANT3: (props: IconProps) => (
    <Icon {...props} name={'box-shadow-variant3'} />
  ),
  BOX_SHADOW_VARIANT4: (props: IconProps) => (
    <Icon {...props} name={'box-shadow-variant4'} />
  ),
  BOX_SHADOW_VARIANT5: (props: IconProps) => (
    <Icon {...props} name={'box-shadow-variant5'} />
  ),
  INCREASE_CONTROL_V2: (props: IconProps) => (
    <IconWrapper {...props}>
      <IncreaseV2Icon />
    </IconWrapper>
  ),
  QUESTION: (props: IconProps) => (
    <IconWrapper {...props}>
      <QuestionIcon />
    </IconWrapper>
  ),
};

export type ControlIconName = keyof typeof ControlIcons;
