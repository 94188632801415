import React from 'react';
import styled from 'constants/DefaultTheme';
import { ISwitchProps, Switch } from '@blueprintjs/core';
import { Colors } from 'constants/Colors';
import { replayHighlightClass } from 'globalStyles/portals';

const StyledSwitch = styled(Switch)`
  &&&&& input ~ span {
    &::before {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
  }
  &&&&& input:checked ~ span {
    background: linear-gradient(
      121deg,
      #ff71ad 3%,
      #ff71ad 3%,
      #ffa852 94%,
      #ffa852 94%
    );
    &::before {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
  }

  & input:focus + .bp3-control-indicator {
    box-shadow: none !important;
  }
`;

export default function AdsSwitch(props: ISwitchProps) {
  return (
    <StyledSwitch
      {...props}
      className={
        props.className
          ? props.className + ' ' + replayHighlightClass
          : replayHighlightClass
      }
    />
  );
}
