export const API_EDITOR_FORM_NAME = 'ApiEditorForm';
export const CREATE_APPLICATION_FORM_NAME = 'CreateApplicationForm';
export const INVITE_USERS_TO_ORG_FORM = 'InviteUsersToOrgForm';
export const LOGIN_FORM_NAME = 'LoginForm';

export const LOGIN_FORM_EMAIL_FIELD_NAME = 'username';
export const LOGIN_FORM_PASSWORD_FIELD_NAME = 'password';

export const SIGNUP_FORM_NAME = 'SignupForm';
export const SIGNUP_FORM_EMAIL_FIELD_NAME = 'email';
export const FORGOT_PASSWORD_FORM_NAME = 'ForgotPasswordForm';
export const RESET_PASSWORD_FORM_NAME = 'ResetPasswordForm';
export const CREATE_PASSWORD_FORM_NAME = 'CreatePasswordForm';

export const CREATE_ORGANIZATION_FORM_NAME = '创建团队';
export const CURL_IMPORT_FORM = 'CurlImportForm';

export const QUERY_EDITOR_FORM_NAME = 'QueryEditorForm';
export const API_HOME_SCREEN_FORM = 'APIHomeScreenForm';
export const SAAS_EDITOR_FORM = 'SaaSEditorForm';

export const DATASOURCE_DB_FORM = 'DatasourceDBForm';
export const DATASOURCE_REST_API_FORM = 'DatasourceRestAPIForm';
export const DATASOURCE_SAAS_FORM = 'DatasourceSaaSForm';

export const WELCOME_FORM_NAME = 'WelcomeSetupForm';
export const WELCOME_FORM_NAME_FIELD_NAME = 'name';
export const WELCOME_FORM_EMAIL_FIELD_NAME = 'email';
export const WELCOME_FORM_PASSWORD_FIELD_NAME = 'password';
export const WELCOME_FORM_VERIFY_PASSWORD_FIELD_NAME = 'verify_password';
export const WELCOME_FORM_ROLE_FIELD_NAME = 'role';
export const WELCOME_FORM_ROLE_NAME_FIELD_NAME = 'role_name';
export const WELCOME_FORM_USECASE_FIELD_NAME = 'useCase';
export const WELCOME_FORM_CUSTOM_USECASE_FIELD_NAME = 'custom_useCase';

export const SETTINGS_FORM_NAME = 'SettingsForm';
export const WELCOME_NON_SUPER_FORM_NAME = 'WelcomeNonSuperSetupForm';
export const REDIRECT_URL_FORM = 'RedirectURLForm';
export const ENTITYID_URL_FORM = 'EntityIdURLForm';

/**
 * TODO: The follows variables are temporary, please to delete them after November 10 2022.
 */
export const TEMPORARY_CUSTOM_FIELD_PROPERTY = 'actionConfigurationOutMap';

// ====== end ======
