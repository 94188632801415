import { Layers } from 'constants/Layers';
import React, { useState, useEffect, RefObject, useCallback } from 'react';
import styled, { css } from 'styled-components';

export const ResizerCSS = css`
  width: 100%;
  z-index: ${Layers.debugger};
  position: relative;
`;

const Top = styled.div`
  position: absolute;
  cursor: ns-resize;
  height: 4px;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
`;

type ResizerProps = {
  panelRef: RefObject<HTMLDivElement>;
  setContainerDimensions?: (height: number) => void;
};

function Resizer(props: ResizerProps) {
  const [mouseDown, setMouseDown] = useState(false);

  const handleResize = useCallback((movementY: number) => {
    const panel = props.panelRef.current;
    if (!panel) return;

    const { height } = panel.getBoundingClientRect();
    const updatedHeight = height - movementY;
    const headerHeightNumber = 35;
    const minHeight = parseInt(
      window.getComputedStyle(panel).minHeight.replace('px', '')
    );

    if (
      updatedHeight < window.outerHeight - headerHeightNumber &&
      updatedHeight > minHeight
    ) {
      panel.style.height = `${height - movementY}px`;
      props.setContainerDimensions &&
        props.setContainerDimensions(height - movementY);
    }
  }, []);
  const handleMouseUp = useCallback(() => setMouseDown(false), []);
  const handleMouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault();
    handleResize(e.movementY);
  }, []);

  useEffect(() => {
    if (mouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseDown]);

  useEffect(() => {
    handleResize(0);

    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseDown = () => {
    setMouseDown(true);
  };

  return (
    <Top
      className="bg-transparent hover:bg-gray-300 transition "
      onMouseDown={handleMouseDown}
    />
  );
}

export default Resizer;
