import { PREVIEW_SEARCH_PARAM } from '@appInstall/constants';

export const SUB_APP_READY_FLAG = 'ok';

// NOTE: To check the sub application url is available. The unit is second.
export const MAX_CHECK_URL_AVAILABLE = 2 * 60;

// NOTE: To check the sub application is completely load. The unit is second.
export const MAX_CHECK_SUB_APP_READY = 2 * 60;

export const PARENT_POLL_PAGE_LOADED_MESSAGE = 'APP_IS_LOADED';

export const CHILD_REPLY_PAGE_LOADED_MESSAGE = 'REPLY_IS_OK';

export const CHILD_REPLY_STORE_APP_PROFILE = 'REPLY_STORE_PROFILE_SUCCESS';

export const getCheckSubAppUrl = (subUrl: string) =>
  `https://${subUrl}/api/ping`;

export const getSubAppUrl = (subUrl: string, appName = '未命名应用', theme) => {
  return `http://${subUrl}/api/page/login?mode=${PREVIEW_SEARCH_PARAM}`;
};
