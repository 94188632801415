export const MAX_LICENSE_SIZE = 2 * 1024 * 1024;

export const ORG_TYPES = [
  {
    value: 'hospital',
    label: '医院',
  },
  {
    value: 'enterprise',
    label: '企业',
  },
];

export const enum AuthStatusEnum {
  'init' = '0',
  'authenticating' = '1',
  'authenticated' = '2',
  'failed' = '3',
}

export const AUTH_STATUS = {
  [AuthStatusEnum.init]: {
    label: '待认证',
    textColor: '#999',
    bgColor: '#E5E5E5',
  },
  [AuthStatusEnum.authenticating]: {
    label: '认证中',
    textColor: '#FF5800',
    bgColor: '#FFEDDA',
  },
  [AuthStatusEnum.authenticated]: {
    label: '已认证',
    textColor: '#B56100',
    bgColor: 'linear-gradient(90deg, #FEDB9F 0%, #F8D289 100%)',
  },
  [AuthStatusEnum.failed]: {
    label: '认证失败',
    textColor: '#FD3939',
    bgColor: '#FFE1E1',
  },
};

export const PATH_MAP_MENU_KEY = {
  '/user-center': 'profile',
  '/user-center/order': 'order',
  '/user-center/enterprise-auth': 'auth',
  '/user-center/accountInterests': 'interests',
};
