import React from 'react';
import { useQuery } from 'react-query';
// import { useSelector } from 'react-redux';
// import dayjs from 'dayjs';
import { Table } from '@arco-design/web-react';

// import { getCurrentUser } from 'selectors/usersSelectors';
// import PriceApi from 'api/Price';
import { OrderContainer, OrderTitle } from '../styles';
import { UserCenterApi } from 'api/UserCenterApi';

const columns = [
  {
    title: '账号',
    dataIndex: 'userName',
  },
  {
    title: '订单编号',
    dataIndex: 'id',
  },
  {
    title: '订单发放时间',
    dataIndex: 'createdAt',
  },
  {
    title: '应用是否可定制',
    dataIndex: 'orderAmount',
    render(value) {
      if (value == true) {
        return '是';
      }
      return '否';
    },
  },

  // {
  //   title: '订单支付时间',
  //   dataIndex: 'paidTime',
  //   render(value) {
  //     if (!value) return null;

  //     return dayjs(value).format('YYYY/MM/DD HH:mm:ss');
  //   },
  // },
];

function OrderManage() {
  // const user = useSelector(getCurrentUser);

  // const { data } = useQuery([user], () => {
  //   if (!user) return;
  //   return PriceApi.getOrderList(user.keyId);
  // });

  // const tableData = data?.data || [];
  const { data } = useQuery('orders', UserCenterApi.getOrderList);
  const tableData = data?.data || [];

  return (
    <OrderContainer>
      <OrderTitle>订单管理</OrderTitle>
      <div className="mt-6">
        <Table
          border={false}
          borderCell={false}
          columns={columns}
          data={tableData}
          style={{
            height: '500px',
          }}
        />
      </div>
    </OrderContainer>
  );
}

export default OrderManage;
