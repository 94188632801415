import React, { Suspense, useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import Icon from 'components/icon';
import { debounce } from 'lodash';

const initWidth = screen.width;

type ModalProps = {
  url: string;
};

type Iprops = {
  className?: string;
  children: React.ReactChild;
};

function AppPreviewLogin(props: Iprops) {
  // const styles = useSpring({
  //   config: { duration: 2000 },
  //   loop: { reverse: true },
  //   from: {
  //     background: `linear-gradient(118deg, #FF71AD 0%, #FF71AD 0%, #FFA852 99%, #FFA852 100%)`,
  //   },
  //   to: {
  //     background: `linear-gradient(30deg, #FFA852 0%, #FFA852 0%, #FF71AD 99%, #FF71AD 100%)`,
  //   },
  // });
  const [pageSize, setSize] = useState(initWidth);

  useEffect(() => {
    const listener = debounce(() => {
      setSize(document.body.offsetWidth);
    });
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return (
    <div className="w-[100%] h-[100%] relative overflow-hidden bg-[#f5f9ff]">
      {/* <Canvas
        className={props.className}
        dpr={[1.5, 2]}
        linear
        shadows
        style={{ height: '100vh' }}
      >
        <fog args={['#272730', 16, 30]} attach="fog" />
        <ambientLight intensity={0.85} />
        <PerspectiveCamera fov={75} makeDefault position={[0, 0, 16]}>
          <pointLight intensity={1} position={[-10, -25, -10]} />
          <spotLight
            angle={0.2}
            castShadow
            intensity={2.25}
            penumbra={1}
            position={[-25, 20, -15]}
            shadow-bias={-0.0001}
            shadow-mapSize={[1024, 1024]}
          />
        </PerspectiveCamera>
        <OrbitControls
          addEventListener={undefined}
          autoRotate
          autoRotateSpeed={0.2}
          dispatchEvent={undefined}
          enablePan
          enableZoom
          hasEventListener={undefined}
          maxDistance={22}
          maxPolarAngle={Math.PI / 2}
          minDistance={12}
          minPolarAngle={Math.PI / 2}
          removeEventListener={undefined}
        />
        <Suspense fallback={null}>
          <Model url="/scene.glb" />
        </Suspense>
        <Stars count={1000} depth={50} factor={10} radius={500} />
      </Canvas>

      <Loader />  bg-gradient-[45deg] from-[#FF71AD] to-[#FFA852]*/}
      <animated.div
        className="w-full h-screen flex justify-center items-center"
        // style={{ ...styles }}
      >
        {pageSize <= 640 ? null : (
          <div className="w-[50%]  h-screen flex  flex-col">
            <div className="flex p-[32px] pb-0">
              <Icon className="!w-[80px] !h-[80px]" name="logo-bg" />
              <div className="ml-[24px]">
                <div className="text-[26px] leading-[40px] font-[500] mb-[4px]">
                  紫微医院软件定制平台
                </div>
                <div className="text-[#5D6F8C] text-[16px] leading-[24px]">
                  面向业务人员的医院软件定制开发平台
                </div>
              </div>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <div className="text-center">
                <div className="text-[48px] text-[#5D6F8C] leading-[64px] mb-[12px]">
                  零开发基础
                </div>
                <div className="mb-[32px]">
                  <span className="mr-[3px]">/</span>
                  <span className="text-[#1375FF]">5分钟</span>
                  即可定制部署医院信息化系统
                  <span className="ml-[3px]">/</span>
                </div>
                <div className="flex justify-center items-center">
                  <img className="w-[83%]" src="/gif/login-bg.gif" />
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`h-screen bg-[#fff]  flex justify-center items-center rounded-l-[16px] ${
            pageSize > 640 ? 'w-[50%]' : 'w-[100%]'
          }`}
        >
          {props.children}
        </div>
      </animated.div>
      {/* <div className=" h-[80px] bg-[#000000] text-[#fff] fixed bottom-0 left-0 right-0 text-center leading-[80px]">
        Copyright © 2022 FUSIONTECH. All rights reserved.
      </div> */}
      {/* <div className="absolute right-[-400px] top-[-400px] w-[861px] h-[861px]  rounded-full shadow-3xl opacity-[0.28]" />
      <div className="absolute right-[calc(50%-300px)] top-[-180px] w-[600px] h-[361px] shadow-3xl opacity-[0.28]" />
      <div className="absolute left-[-300px] top-[calc(50%-320px)] w-[600px] h-[600px] shadow-3xl opacity-[0.28] transform rotate-[45deg]" /> */}
    </div>
  );
}

export default AppPreviewLogin;
