import Icon from 'components/icon';
import { get } from 'lodash';
import React from 'react';
import { useReactFlow } from 'react-flow-renderer';
import { useStore } from 'react-redux';
import * as menu from './nodeMenuConfig';

export default function NodeMenu({ data, meta, nodeMenu }) {
  const reactFlowInstance = useReactFlow();
  const store = useStore();
  meta.store = store;

  return (
    <div className="flex max-w-[120px] flex-wrap">
      {(get(menu, data.type + 'Menu') || nodeMenu).map((v) => (
        <span
          className="text-[12px] m-[5px] cursor-pointer hover:text-link flex justify-center flex-col items-center"
          key={v.text}
          onClick={() => v.callBack(reactFlowInstance, data, meta)}
          title={v.text}
        >
          <Icon name={v.icon} />
          <span className="mt-[5px]">{v.text}</span>
        </span>
      ))}
    </div>
  );
}
