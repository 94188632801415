import { invisible, theme } from 'constants/DefaultTheme';
import { WIDGET_PADDING } from 'constants/WidgetConstants';
import styled, { css } from 'styled-components';
import { Layers } from 'constants/Layers';

const EDGE_RESIZE_HANDLE_WIDTH = 8;
const CORNER_RESIZE_HANDLE_WIDTH = 10;

export const VisibilityContainer = styled.div<{
  visible: boolean;
  padding: number;
}>`
  ${(props) => (!props.visible ? invisible : '')}
  height: 100%;
  width: 100%;
`;

const ResizeIndicatorStyle = css<{
  showLightBorder: boolean;
}>`
  /* &::after {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${(props) =>
    props.showLightBorder
      ? theme.colors.widgetLightBorder
      : theme.colors.widgetBorder};
    top: calc(50% - 2px);
    left: calc(50% - 2px);
  } */
`;

export const EdgeHandleStyles = css<{
  showAsBorder: boolean;
  showLightBorder: boolean;
  isWidgetFocused?: boolean;
}>`
  position: absolute;
  width: ${EDGE_RESIZE_HANDLE_WIDTH}px;
  height: ${EDGE_RESIZE_HANDLE_WIDTH}px;
  z-index: ${Layers.contextMenu};
  &::before {
    position: absolute;
    background: ${(props) => {
      if (props.isWidgetFocused) return theme.colors.widgetBorder;

      if (props.showLightBorder) return theme.colors.widgetLightBorder;

      if (props.showAsBorder) return theme.colors.widgetMultiSelectBorder;

      return theme.colors.widgetBorder;
    }};
    content: '';
  }
  ${(props) => (!props.showAsBorder ? ResizeIndicatorStyle : '')}
`;

export const VerticalHandleStyles = css<{
  showAsBorder: boolean;
  showLightBorder: boolean;
  paddingOffset: number;
  isWidgetFocused?: boolean;
}>`
  ${EdgeHandleStyles}
  top:  ${({ paddingOffset = WIDGET_PADDING }) =>
    `${(paddingOffset - 1) * -1}px`};
  height: ${({ paddingOffset = WIDGET_PADDING }) =>
    `calc(100% + ${2 * paddingOffset - 1}px)`};
  ${(props) => (!props.showAsBorder ? 'cursor: col-resize;' : '')}
  &:before {
    left: 50%;
    bottom: 0px;
    top: 0;
    width: ${(props) => (props.isWidgetFocused ? '2px' : '1px')};
  }

  &&&:hover:after {
    background-color: ${theme.colors.widgetBorder};
  }

  &:after {
    content: '';
    width: 8px;
    height: 70%;
    min-height: 10px;
    max-height: 80%;

    // background-color: #fff;
    border: 2px solid ${theme.colors.widgetBorder};
    border-radius: 4px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 4%);
    pointer-events: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
`;

export const HorizontalHandleStyles = css<{
  showAsBorder: boolean;
  showLightBorder: boolean;
  paddingOffset: number;
  isWidgetFocused?: boolean;
}>`
  ${EdgeHandleStyles}
  left:${({ paddingOffset = WIDGET_PADDING }) => `-${paddingOffset}px`};
  width: ${({ paddingOffset = WIDGET_PADDING }) =>
    `calc(100% + ${2 * paddingOffset}px)`};
  ${(props) => (!props.showAsBorder ? 'cursor: row-resize;' : '')}
  &:before {
    top: 50%;
    right: 0px;
    left: 0px;
    height: ${(props) => (props.isWidgetFocused ? '2px' : '1px')};
  }
`;

export const LeftHandleStyles = styled.div<{
  paddingOffset?: number;
  isShowHorizonResizeHandler?: boolean;
  isDragging?: boolean;
}>`
  ${VerticalHandleStyles}
  left: ${({ paddingOffset = WIDGET_PADDING }) =>
    `${-EDGE_RESIZE_HANDLE_WIDTH / 2 - paddingOffset}px`};

  &:after {
    visibility: ${(props) =>
      props.isShowHorizonResizeHandler ? 'visible' : 'hidden'};
    background-color: ${(props) =>
      props.isDragging ? theme.colors.widgetBorder : '#fff'};
    left: calc(50% + 1px);
  }
`;

export const RightHandleStyles = styled.div<{
  paddingOffset?: number;
  resizeMode?: 'corner' | 'rail';
  isShowHorizonResizeHandler?: boolean;
  isDragging?: boolean;
}>`
  ${VerticalHandleStyles};
  right: ${({ paddingOffset = WIDGET_PADDING }) =>
    `${-EDGE_RESIZE_HANDLE_WIDTH / 2 - paddingOffset + 1}px`};
  height: ${({ paddingOffset = WIDGET_PADDING }) =>
    `calc(100% + ${2 * paddingOffset - 1}px)`};

  &:before {
    left: ${(props) =>
      props.isShowHorizonResizeHandler ? 'calc(50% - 1px)' : '50%'};
  }
  &:after {
    visibility: ${(props) =>
      props.isShowHorizonResizeHandler ? 'visible' : 'hidden'};
    background-color: ${(props) =>
      props.isDragging ? theme.colors.widgetBorder : '#fff'};
  }
`;

export const TopHandleStyles = styled.div<{
  paddingOffset?: number;
  isWidgetFocused?: boolean;
}>`
  ${HorizontalHandleStyles};
  top: ${({ paddingOffset = WIDGET_PADDING }) =>
    `${-EDGE_RESIZE_HANDLE_WIDTH / 2 - paddingOffset}px`};
`;

export const BottomHandleStyles = styled.div<{
  paddingOffset?: number;
  isWidgetFocused?: boolean;
}>`
  ${HorizontalHandleStyles};
  bottom: ${({ paddingOffset = WIDGET_PADDING }) =>
    `${-EDGE_RESIZE_HANDLE_WIDTH / 2 - paddingOffset}px`};
  &:before {
    transform: translateY(-1px);
  }
`;

export const CornerHandleStyles = css<{
  showAsBorder: boolean;
  showLightBorder?: boolean;
  isDragging?: boolean;
}>`
  position: absolute;
  width: ${CORNER_RESIZE_HANDLE_WIDTH}px;
  height: ${CORNER_RESIZE_HANDLE_WIDTH}px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isDragging ? theme.colors.widgetBorder : '#fff'};
  z-index: ${Layers.contextMenu};
  border: ${(props) => {
    const border = '2px solid ';
    if (props.showLightBorder) {
      return border + theme.colors.widgetLightBorder;
    }

    if (props.showAsBorder) {
      return border + theme.colors.widgetMultiSelectBorder;
    }

    return border + theme.colors.widgetBorder;
  }};
  &:hover {
    background: ${(props) => {
      if (props.showLightBorder) return theme.colors.widgetLightBorder;

      if (props.showAsBorder) return theme.colors.widgetMultiSelectBorder;

      return theme.colors.widgetBorder;
    }};
  }
`;

export const BottomRightHandleStyles = styled.div<{
  showAsBorder: boolean;
  paddingOffset?: number;
}>`
  ${CornerHandleStyles};
  bottom: ${(props) => -(props.paddingOffset + EDGE_RESIZE_HANDLE_WIDTH / 2)}px;
  right: ${(props) => -(props.paddingOffset + EDGE_RESIZE_HANDLE_WIDTH / 2)}px;
  ${(props) => (!props.showAsBorder ? 'cursor: se-resize;' : '')}
`;

export const BottomLeftHandleStyles = styled.div<{
  showAsBorder: boolean;
  paddingOffset?: number;
}>`
  ${CornerHandleStyles};
  left: ${(props) => -(props.paddingOffset + EDGE_RESIZE_HANDLE_WIDTH / 2)}px;
  bottom: ${(props) => -(props.paddingOffset + EDGE_RESIZE_HANDLE_WIDTH / 2)}px;
  ${(props) => (!props.showAsBorder ? 'cursor: sw-resize;' : '')}
`;
export const TopLeftHandleStyles = styled.div<{
  showAsBorder: boolean;
  paddingOffset?: number;
}>`
  ${CornerHandleStyles};
  left: ${(props) => -(props.paddingOffset + EDGE_RESIZE_HANDLE_WIDTH / 2)}px;
  top: ${(props) => -(props.paddingOffset + EDGE_RESIZE_HANDLE_WIDTH / 2)}px;
  ${(props) => (!props.showAsBorder ? 'cursor: nw-resize;' : '')}
`;
export const TopRightHandleStyles = styled.div<{
  showAsBorder: boolean;
  paddingOffset?: number;
}>`
  ${CornerHandleStyles};
  right: ${(props) => -(props.paddingOffset + EDGE_RESIZE_HANDLE_WIDTH / 2)}px;
  top: ${(props) => -(props.paddingOffset + EDGE_RESIZE_HANDLE_WIDTH / 2)}px;
  ${(props) => (!props.showAsBorder ? 'cursor: ne-resize;' : '')}
`;
