import { createReducer } from 'utils/AppsmithUtils';
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorPayload,
} from '@appsmith/constants/ReduxActionConstants';
import { ERROR_CODES } from '@appsmith/constants/ApiConstants';
import _ from 'lodash';

const initialState: ErrorReduxState = {
  safeCrash: false,
  isAppCrashed: false,
  safeCrashCode: undefined,
  currentError: { sourceAction: '', message: '' },
};

const errorReducer = createReducer(initialState, {
  [ReduxActionTypes.SAFE_CRASH_APPSMITH]: (
    state: ErrorReduxState,
    action: ReduxAction<ReduxActionErrorPayload>
  ) => ({
    ...state,
    safeCrash: true,
    safeCrashCode: _.get(action, 'payload.code', 502), // when the server is not responding
  }),
  [ReduxActionTypes.SAFE_CRASH_APP]: (state: ErrorReduxState) => ({
    ...state,
    isAppCrashed: true,
  }),
  [ReduxActionTypes.REPORT_ERROR]: (
    state: ErrorReduxState,
    action: ReduxAction<ReduxActionErrorPayload>
  ) => {
    return {
      ...state,
      currentError: {
        sourceAction: action.payload.source,
        message: action.payload.message,
      },
    };
  },
  [ReduxActionTypes.FLUSH_ERRORS]: () => {
    return initialState;
  },
});

export interface ErrorReduxState {
  safeCrash: boolean;
  isAppCrashed: boolean;
  safeCrashCode?: ERROR_CODES;
  currentError: {
    sourceAction?: string;
    message?: string;
  };
}

export default errorReducer;
