import React from 'react';
import { Modal } from '@arco-design/web-react';
import { get, uniqueId } from 'lodash';
import {
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowInstance,
} from 'react-flow-renderer';
import { removeNode } from './utils';
import { queryClient } from 'QueryClient';
import FilterContent from './form/filter';
import { QueryClientProvider } from 'react-query';
import IntersectionContent from './form/intersection';
import UnionContent from './form/union';
import DifferenceContent from './form/difference';
import GroupContent from './form/group';
import SortContent from './form/sort';
import DuplicateRemovalContent from './form/duplicateRemoval';
import DeviationContent from './form/deviation';
import DataProcessingContent from './form/dataProcessing';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { getCurrentThemeDetails } from 'selectors/themeSelectors';
import { AppState } from 'reducers';

let nodeIdMap = {};

export function getNodeMapid(id) {
  const mapID = uniqueId('node_map_id_' + uniqueId());
  nodeIdMap[mapID] = id;
  return mapID;
}

function ThemeProviderWrapper(props) {
  const currentTheme = useSelector((state: AppState) =>
    getCurrentThemeDetails(state)
  );

  return <ThemeProvider theme={currentTheme}>{props.children}</ThemeProvider>;
}

function StoreWrapper(props) {
  return (
    <Provider store={props.store}>
      <ThemeProviderWrapper {...props} />
    </Provider>
  );
}

export function getNodeIdMap() {
  return nodeIdMap;
}
export function setNodeIdMap(map) {
  nodeIdMap = map;
}
// 居中
const cH = (59 - 35) / 2;
export const filterMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const filterNodeId = data.id;
      const queryNodeId = getNodeMapid(filterNodeId + uniqueId('_query'));
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId && data.queryGengeratorLineId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            reactFlowInstance.setEdges(
              applyEdgeChanges(
                [
                  {
                    id: data.queryGengeratorLineId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getEdges()
              )
            );
            setTimeout(() => {
              reactFlowInstance.addNodes([
                {
                  id: data.queryGengeratorId,
                  type: 'query',
                  data: {
                    type: 'query',
                    icon: 'query',
                    id: data.queryGengeratorId,
                    previousData: data,
                    ...position,
                    queryParams,
                  },
                  position,
                },
              ]);
              reactFlowInstance.addEdges([
                {
                  id: data.queryGengeratorLineId,
                  source: filterNodeId,
                  sourceHandle: 'B',
                  target: data.queryGengeratorId,
                  label: '结果',
                },
              ]);
            }, 50);
          } else {
            const LineId = filterNodeId + uniqueId('_line');
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: LineId,
              source: filterNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
            data.queryGengeratorLineId = LineId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <StoreWrapper store={meta.store}>
            <QueryClientProvider client={queryClient}>
              <FilterContent
                data={data}
                fields={get(
                  meta,
                  'tableMeta.data.data',
                  get(meta, 'tableMeta')
                )}
                meta={meta}
                modal={modal}
              />
            </QueryClientProvider>
          </StoreWrapper>
        ),
        style: {
          width: 1000 + 'px',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const intersectionMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const intersectionNodeId = data.id;
      const queryNodeId = getNodeMapid(intersectionNodeId + uniqueId('_query'));
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            setTimeout(
              () =>
                reactFlowInstance.addNodes([
                  {
                    id: data.queryGengeratorId,
                    type: 'query',
                    data: {
                      type: 'query',
                      icon: 'query',
                      id: data.queryGengeratorId,
                      previousData: data,
                      ...position,
                      queryParams,
                    },
                    position,
                  },
                ]),
              50
            );
          } else {
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: intersectionNodeId + uniqueId('_line'),
              source: intersectionNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <QueryClientProvider client={queryClient}>
            <IntersectionContent
              data={data}
              fields={get(meta, 'tableMeta.data.data', get(meta, 'tableMeta'))}
              meta={meta}
              modal={modal}
            />
          </QueryClientProvider>
        ),
        style: {
          width: 1000 + 'px',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const unionMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const unionNodeId = data.id;
      const queryNodeId = getNodeMapid(unionNodeId + uniqueId('_query'));
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            setTimeout(
              () =>
                reactFlowInstance.addNodes([
                  {
                    id: data.queryGengeratorId,
                    type: 'query',
                    data: {
                      type: 'query',
                      icon: 'query',
                      id: data.queryGengeratorId,
                      previousData: data,
                      ...position,
                      queryParams,
                    },
                    position,
                  },
                ]),
              50
            );
          } else {
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: unionNodeId + uniqueId('_line'),
              source: unionNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <QueryClientProvider client={queryClient}>
            <UnionContent
              data={data}
              fields={get(meta, 'tableMeta.data.data', get(meta, 'tableMeta'))}
              meta={meta}
              modal={modal}
            />
          </QueryClientProvider>
        ),
        style: {
          width: 1000 + 'px',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const differenceMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const differenceNodeId = data.id;
      const queryNodeId = getNodeMapid(differenceNodeId + uniqueId('_query'));
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            setTimeout(
              () =>
                reactFlowInstance.addNodes([
                  {
                    id: data.queryGengeratorId,
                    type: 'query',
                    data: {
                      type: 'query',
                      icon: 'query',
                      id: data.queryGengeratorId,
                      previousData: data,
                      ...position,
                      queryParams,
                    },
                    position,
                  },
                ]),
              50
            );
          } else {
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: differenceNodeId + uniqueId('_line'),
              source: differenceNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <QueryClientProvider client={queryClient}>
            <DifferenceContent
              data={data}
              fields={get(meta, 'tableMeta.data.data', get(meta, 'tableMeta'))}
              meta={meta}
              modal={modal}
            />
          </QueryClientProvider>
        ),
        style: {
          width: 1000 + 'px',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const groupMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const filterNodeId = data.id;
      const queryNodeId = getNodeMapid(filterNodeId + uniqueId('_query'));
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            setTimeout(
              () =>
                reactFlowInstance.addNodes([
                  {
                    id: data.queryGengeratorId,
                    type: 'query',
                    data: {
                      type: 'query',
                      icon: 'query',
                      id: data.queryGengeratorId,
                      previousData: data,
                      ...position,
                      queryParams,
                    },
                    position,
                  },
                ]),
              50
            );
          } else {
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: filterNodeId + uniqueId('_line'),
              source: filterNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <QueryClientProvider client={queryClient}>
            <GroupContent
              data={data}
              fields={get(meta, 'tableMeta.data.data', get(meta, 'tableMeta'))}
              meta={meta}
              modal={modal}
            />
          </QueryClientProvider>
        ),
        style: {
          width: 1000 + 'px',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const sortMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const filterNodeId = data.id;
      const queryNodeId = getNodeMapid(filterNodeId + uniqueId('_query'));
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            setTimeout(
              () =>
                reactFlowInstance.addNodes([
                  {
                    id: data.queryGengeratorId,
                    type: 'query',
                    data: {
                      type: 'query',
                      icon: 'query',
                      id: data.queryGengeratorId,
                      previousData: data,
                      ...position,
                      queryParams,
                    },
                    position,
                  },
                ]),
              50
            );
          } else {
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: filterNodeId + uniqueId('_line'),
              source: filterNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <QueryClientProvider client={queryClient}>
            <SortContent
              data={data}
              fields={get(meta, 'tableMeta.data.data', get(meta, 'tableMeta'))}
              meta={meta}
              modal={modal}
            />
          </QueryClientProvider>
        ),
        style: {
          width: 1000 + 'px',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const duplicateRemovalMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const filterNodeId = data.id;
      const queryNodeId = getNodeMapid(filterNodeId + uniqueId('_query'));
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            setTimeout(
              () =>
                reactFlowInstance.addNodes([
                  {
                    id: data.queryGengeratorId,
                    type: 'query',
                    data: {
                      type: 'query',
                      icon: 'query',
                      id: data.queryGengeratorId,
                      previousData: data,
                      ...position,
                      queryParams,
                    },
                    position,
                  },
                ]),
              50
            );
          } else {
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: filterNodeId + uniqueId('_line'),
              source: filterNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <QueryClientProvider client={queryClient}>
            <DuplicateRemovalContent
              data={data}
              fields={get(meta, 'tableMeta.data.data', get(meta, 'tableMeta'))}
              meta={meta}
              modal={modal}
            />
          </QueryClientProvider>
        ),
        style: {
          width: 1000 + 'px',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const deviationMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const intersectionNodeId = data.id;
      const queryNodeId = getNodeMapid(intersectionNodeId + uniqueId('_query'));
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            setTimeout(
              () =>
                reactFlowInstance.addNodes([
                  {
                    id: data.queryGengeratorId,
                    type: 'query',
                    data: {
                      type: 'query',
                      icon: 'query',
                      id: data.queryGengeratorId,
                      previousData: data,
                      ...position,
                      queryParams,
                    },
                    position,
                  },
                ]),
              50
            );
          } else {
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: intersectionNodeId + uniqueId('_line'),
              source: intersectionNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <StoreWrapper store={meta.store}>
            <QueryClientProvider client={queryClient}>
              <DeviationContent
                data={data}
                fields={get(
                  meta,
                  'tableMeta.data.data',
                  get(meta, 'tableMeta')
                )}
                meta={meta}
                modal={modal}
              />
            </QueryClientProvider>
          </StoreWrapper>
        ),
        style: {
          width: 1000 + 'px',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const dataProcessingMenu = [
  {
    icon: 'config1',
    text: '配置',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const dataProcessingNodeId = data.id;
      const queryNodeId = getNodeMapid(
        dataProcessingNodeId + uniqueId('_query')
      );
      const position = { x: data.x + 150, y: data.y - cH };
      const modal = {
        current: null,
        ok: (queryParams) => {
          if (data.queryGengeratorId) {
            reactFlowInstance.setNodes(
              applyNodeChanges(
                [
                  {
                    id: data.queryGengeratorId,
                    type: 'remove',
                  },
                ],
                reactFlowInstance.getNodes()
              )
            );
            setTimeout(
              () =>
                reactFlowInstance.addNodes([
                  {
                    id: data.queryGengeratorId,
                    type: 'query',
                    data: {
                      type: 'query',
                      icon: 'query',
                      id: data.queryGengeratorId,
                      previousData: data,
                      ...position,
                      queryParams,
                    },
                    position,
                  },
                ]),
              50
            );
          } else {
            reactFlowInstance.addNodes({
              id: queryNodeId,
              type: 'query',
              data: {
                type: 'query',
                icon: 'query',
                id: queryNodeId,
                previousData: data,
                ...position,
                queryParams,
              },
              position,
            });
            reactFlowInstance.addEdges({
              id: dataProcessingNodeId + uniqueId('_line'),
              source: dataProcessingNodeId,
              sourceHandle: 'B',
              target: queryNodeId,
              label: '结果',
            });
            data.queryGengeratorId = queryNodeId;
          }
        },
      };
      modal.current = Modal.confirm({
        title: null,
        footer: null,
        icon: null,
        content: (
          <QueryClientProvider client={queryClient}>
            <DataProcessingContent
              data={data}
              fields={get(meta, 'tableMeta.data.data', get(meta, 'tableMeta'))}
              meta={meta}
              modal={modal}
            />
          </QueryClientProvider>
        ),
        style: {
          width: 90 + 'vw',
          height: 'min(80vh,800px)',
        },
      });
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.id, reactFlowInstance),
  },
];

export const tableNodeMenu = [
  {
    icon: 'filter1',
    text: '过滤',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const filterNodeId = getNodeMapid(data.dataId + uniqueId('_filter'));
      const position = { x: data.x + 150, y: data.y + cH };
      reactFlowInstance.addNodes([
        {
          id: filterNodeId,
          type: 'operations',
          data: {
            id: filterNodeId,
            type: 'filter',
            icon: 'filter1',
            menu: filterMenu,
            meta,
            previousData: data,
            ...position,
          },
          position,
        },
      ]);
      const filterNodeLineId = filterNodeId + uniqueId('_line');
      reactFlowInstance.addEdges([
        {
          id: filterNodeLineId,
          source: filterNodeId,
          sourceHandle: 'A',
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          target: data.dataId,
          label: '过滤',
        },
      ]);
    },
  },
  {
    icon: 'intersection',
    text: '交集',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const intersectionNodeId = getNodeMapid(
        data.dataId + uniqueId('_intersection')
      );
      const placeholderNodeID = getNodeMapid(
        intersectionNodeId + uniqueId('_placeholder')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      const placeholderNodePosition = { x: data.x, y: data.y + 140 };

      reactFlowInstance.addNodes([
        {
          id: intersectionNodeId,
          type: 'operations',
          data: {
            id: intersectionNodeId,
            type: 'intersection',
            icon: 'intersection',
            menu: intersectionMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
        {
          id: placeholderNodeID,
          type: 'placeholderNode',
          data: { type: 'placeholder', icon: 'placeholder' },
          position: placeholderNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: intersectionNodeId + uniqueId('_line'),
          target: data.dataId,
          source: intersectionNodeId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          sourceHandle: 'A',
          label: '交集',
        },
        {
          id: intersectionNodeId + uniqueId('_line'),
          source: intersectionNodeId,
          target: placeholderNodeID,
          sourceHandle: 'A',
          animated: true,
          label: '交集',
        },
      ]);
    },
  },
  {
    icon: 'Union',
    text: '并集',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const unionNodeId = getNodeMapid(data.dataId + uniqueId('_intersection'));
      const placeholderNodeID = getNodeMapid(
        unionNodeId + uniqueId('_placeholder')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      const placeholderNodePosition = { x: data.x, y: data.y + 140 };

      reactFlowInstance.addNodes([
        {
          id: unionNodeId,
          type: 'operations',
          data: {
            id: unionNodeId,
            type: 'union',
            icon: 'Union',
            menu: unionMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
        {
          id: placeholderNodeID,
          type: 'placeholderNode',
          data: { type: 'placeholder', icon: 'placeholder' },
          position: placeholderNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: unionNodeId + uniqueId('_line'),
          target: data.dataId,
          source: unionNodeId,
          sourceHandle: 'A',
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          label: '并集',
        },
        {
          id: unionNodeId + uniqueId('_line'),
          source: unionNodeId,
          target: placeholderNodeID,
          sourceHandle: 'A',
          animated: true,
          label: '并集',
        },
      ]);
    },
  },
  {
    icon: 'other',
    text: '差集',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const differenceNodeId = getNodeMapid(
        getNodeMapid(data.dataId + uniqueId('_intersection'))
      );
      const placeholderNodeID = getNodeMapid(
        differenceNodeId + uniqueId('_placeholder')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      const placeholderNodePosition = { x: data.x, y: data.y + 140 };

      reactFlowInstance.addNodes([
        {
          id: differenceNodeId,
          type: 'operations',
          data: {
            id: differenceNodeId,
            type: 'difference',
            icon: 'other',
            menu: differenceMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
        {
          id: placeholderNodeID,
          type: 'placeholderNode',
          data: { type: 'placeholder', icon: 'placeholder' },
          position: placeholderNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: differenceNodeId + uniqueId('_line'),
          target: data.dataId,
          source: differenceNodeId,
          sourceHandle: 'A',
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          label: '差集',
        },
        {
          id: differenceNodeId + uniqueId('_line'),
          source: differenceNodeId,
          target: placeholderNodeID,
          sourceHandle: 'A',
          animated: true,
          label: '差集',
        },
      ]);
    },
  },
  {
    icon: 'group1',
    text: '分组',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const groupNodeId = getNodeMapid(data.dataId + uniqueId('_group'));
      const position = { x: data.x + 150, y: data.y + cH };
      reactFlowInstance.addNodes([
        {
          id: groupNodeId,
          type: 'operations',
          data: {
            id: groupNodeId,
            type: 'group',
            icon: 'group1',
            menu: groupMenu,
            meta,
            previousData: data,
            ...position,
          },
          position,
        },
      ]);
      const filterNodeLineId = groupNodeId + uniqueId('_line');
      reactFlowInstance.addEdges([
        {
          id: filterNodeLineId,
          source: groupNodeId,
          sourceHandle: 'A',
          target: data.dataId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          label: '分组',
        },
      ]);
    },
  },
  {
    icon: 'sort1',
    text: '排序',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const sortNodeId = getNodeMapid(data.dataId + uniqueId('_sort'));
      const position = { x: data.x + 150, y: data.y + cH };
      reactFlowInstance.addNodes([
        {
          id: sortNodeId,
          type: 'operations',
          data: {
            id: sortNodeId,
            type: 'sort',
            icon: 'sort1',
            menu: sortMenu,
            meta,
            previousData: data,
            ...position,
          },
          position,
        },
      ]);
      const sortNodeLineId = sortNodeId + uniqueId('_line');
      reactFlowInstance.addEdges([
        {
          id: sortNodeLineId,
          source: sortNodeId,
          sourceHandle: 'A',
          target: data.dataId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          label: '排序',
        },
      ]);
    },
  },
  // {
  //   icon: 'duplicateRemoval1',
  //   text: '去重',
  //   callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
  //     const duplicateRemovalNodeId = getNodeMapid(
  //       data.dataId + uniqueId('_sort')
  //     );
  //     const position = { x: data.x + 150, y: data.y + 70 };
  //     reactFlowInstance.addNodes([
  //       {
  //         id: duplicateRemovalNodeId,
  //         type: 'operations',
  //         data: {
  //           id: duplicateRemovalNodeId,
  //           type: 'duplicateRemoval',
  //           icon: 'duplicateRemoval1',
  //           menu: duplicateRemovalMenu,
  //           meta,
  //           previousData: data,
  //           ...position,
  //         },
  //         position,
  //       },
  //     ]);
  //     const duplicateRemovalNodeLineId =
  //       duplicateRemovalNodeId + uniqueId('_line');
  //     reactFlowInstance.addEdges([
  //       {
  //         id: duplicateRemovalNodeLineId,
  //         source: duplicateRemovalNodeId,
  //         sourceHandle: 'A',
  //         targetHandle: data.type === 'query_table' ? 'B' : undefined,
  //         target: data.dataId,
  //         label: '去重',
  //       },
  //     ]);
  //   },
  // },
  {
    icon: 'deviation1',
    text: '偏差',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const deviationNodeId = getNodeMapid(
        data.dataId + uniqueId('_intersection')
      );
      const placeholderNodeID = getNodeMapid(
        deviationNodeId + uniqueId('_placeholder')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      const placeholderNodePosition = { x: data.x, y: data.y + 140 };

      reactFlowInstance.addNodes([
        {
          id: deviationNodeId,
          type: 'operations',
          data: {
            id: deviationNodeId,
            type: 'deviation',
            icon: 'deviation1',
            menu: deviationMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
        {
          id: placeholderNodeID,
          type: 'placeholderNode',
          data: { type: 'placeholder', icon: 'placeholder' },
          position: placeholderNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: deviationNodeId + uniqueId('_line'),
          target: data.dataId,
          source: deviationNodeId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          sourceHandle: 'A',
          label: '偏差',
        },
        {
          id: deviationNodeId + uniqueId('_line'),
          source: deviationNodeId,
          target: placeholderNodeID,
          sourceHandle: 'A',
          animated: true,
          label: '偏差',
        },
      ]);
    },
  },
  {
    icon: 'dataProcessing1',
    text: '数据加工',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const dataProcessingNodeId = getNodeMapid(
        data.dataId + uniqueId('_intersection')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      reactFlowInstance.addNodes([
        {
          id: dataProcessingNodeId,
          type: 'operations',
          data: {
            id: dataProcessingNodeId,
            type: 'dataProcessing',
            icon: 'dataProcessing1',
            menu: dataProcessingMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: dataProcessingNodeId + uniqueId('_line'),
          target: data.dataId,
          source: dataProcessingNodeId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          sourceHandle: 'A',
          label: '数据加工',
        },
      ]);
    },
  },
  {
    icon: 'query-search',
    text: '查看数据',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      data.previewData && data.previewData();
      data.setPerviewLoading && data.setPerviewLoading();
    },
  },
];

export const queryNodeMenu = [
  {
    icon: 'filter1',
    text: '过滤',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const filterNodeId = getNodeMapid(data.dataId + uniqueId('_filter'));
      const position = { x: data.x + 150, y: data.y + cH };
      reactFlowInstance.addNodes([
        {
          id: filterNodeId,
          type: 'operations',
          data: {
            id: filterNodeId,
            type: 'filter',
            icon: 'filter1',
            menu: filterMenu,
            meta,
            previousData: data,
            ...position,
          },
          position,
        },
      ]);
      const filterNodeLineId = filterNodeId + uniqueId('_line');
      reactFlowInstance.addEdges([
        {
          id: filterNodeLineId,
          source: filterNodeId,
          sourceHandle: 'A',
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          target: data.dataId,
          label: '过滤',
        },
      ]);
    },
  },
  {
    icon: 'intersection',
    text: '交集',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const intersectionNodeId = getNodeMapid(
        data.dataId + uniqueId('_intersection')
      );
      const placeholderNodeID = getNodeMapid(
        intersectionNodeId + uniqueId('_placeholder')
      );

      const intersectionNodePosition = {
        x: data.x + 150,
        y: data.y + cH,
      };
      const placeholderNodePosition = { x: data.x, y: data.y + 140 };

      reactFlowInstance.addNodes([
        {
          id: intersectionNodeId,
          type: 'operations',
          data: {
            id: intersectionNodeId,
            type: 'intersection',
            icon: 'intersection',
            menu: intersectionMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
        {
          id: placeholderNodeID,
          type: 'placeholderNode',
          data: { type: 'placeholder', icon: 'placeholder' },
          position: placeholderNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: intersectionNodeId + uniqueId('_line'),
          target: data.dataId,
          source: intersectionNodeId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          sourceHandle: 'A',
          label: '交集',
        },
        {
          id: intersectionNodeId + uniqueId('_line'),
          source: intersectionNodeId,
          target: placeholderNodeID,
          sourceHandle: 'A',
          animated: true,
          label: '交集',
        },
      ]);
    },
  },
  {
    icon: 'Union',
    text: '并集',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const unionNodeId = getNodeMapid(data.dataId + uniqueId('_intersection'));
      const placeholderNodeID = getNodeMapid(
        unionNodeId + uniqueId('_placeholder')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      const placeholderNodePosition = { x: data.x, y: data.y + 140 };

      reactFlowInstance.addNodes([
        {
          id: unionNodeId,
          type: 'operations',
          data: {
            id: unionNodeId,
            type: 'union',
            icon: 'Union',
            menu: unionMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
        {
          id: placeholderNodeID,
          type: 'placeholderNode',
          data: { type: 'placeholder', icon: 'placeholder' },
          position: placeholderNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: unionNodeId + uniqueId('_line'),
          target: data.dataId,
          source: unionNodeId,
          sourceHandle: 'A',
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          label: '并集',
        },
        {
          id: unionNodeId + uniqueId('_line'),
          source: unionNodeId,
          target: placeholderNodeID,
          sourceHandle: 'A',
          animated: true,
          label: '并集',
        },
      ]);
    },
  },
  {
    icon: 'other',
    text: '差集',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const differenceNodeId = getNodeMapid(
        getNodeMapid(data.dataId + uniqueId('_intersection'))
      );
      const placeholderNodeID = getNodeMapid(
        differenceNodeId + uniqueId('_placeholder')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      const placeholderNodePosition = { x: data.x, y: data.y + 140 };

      reactFlowInstance.addNodes([
        {
          id: differenceNodeId,
          type: 'operations',
          data: {
            id: differenceNodeId,
            type: 'difference',
            icon: 'other',
            menu: differenceMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
        {
          id: placeholderNodeID,
          type: 'placeholderNode',
          data: { type: 'placeholder', icon: 'placeholder' },
          position: placeholderNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: differenceNodeId + uniqueId('_line'),
          target: data.dataId,
          source: differenceNodeId,
          sourceHandle: 'A',
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          label: '差集',
        },
        {
          id: differenceNodeId + uniqueId('_line'),
          source: differenceNodeId,
          target: placeholderNodeID,
          sourceHandle: 'A',
          animated: true,
          label: '差集',
        },
      ]);
    },
  },
  {
    icon: 'group1',
    text: '分组',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const groupNodeId = getNodeMapid(data.dataId + uniqueId('_group'));
      const position = { x: data.x + 150, y: data.y + cH };
      reactFlowInstance.addNodes([
        {
          id: groupNodeId,
          type: 'operations',
          data: {
            id: groupNodeId,
            type: 'group',
            icon: 'group1',
            menu: groupMenu,
            meta,
            previousData: data,
            ...position,
          },
          position,
        },
      ]);
      const filterNodeLineId = groupNodeId + uniqueId('_line');
      reactFlowInstance.addEdges([
        {
          id: filterNodeLineId,
          source: groupNodeId,
          sourceHandle: 'A',
          target: data.dataId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          label: '分组',
        },
      ]);
    },
  },
  {
    icon: 'sort1',
    text: '排序',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const sortNodeId = getNodeMapid(data.dataId + uniqueId('_sort'));
      const position = { x: data.x + 150, y: data.y + cH };
      reactFlowInstance.addNodes([
        {
          id: sortNodeId,
          type: 'operations',
          data: {
            id: sortNodeId,
            type: 'sort',
            icon: 'sort1',
            menu: sortMenu,
            meta,
            previousData: data,
            ...position,
          },
          position,
        },
      ]);
      const sortNodeLineId = sortNodeId + uniqueId('_line');
      reactFlowInstance.addEdges([
        {
          id: sortNodeLineId,
          source: sortNodeId,
          sourceHandle: 'A',
          target: data.dataId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          label: '排序',
        },
      ]);
    },
  },
  // {
  //   icon: 'duplicateRemoval1',
  //   text: '去重',
  //   callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
  //     const duplicateRemovalNodeId = getNodeMapid(
  //       data.dataId + uniqueId('_sort')
  //     );
  //     const position = { x: data.x + 150, y: data.y + 70 };
  //     reactFlowInstance.addNodes([
  //       {
  //         id: duplicateRemovalNodeId,
  //         type: 'operations',
  //         data: {
  //           id: duplicateRemovalNodeId,
  //           type: 'duplicateRemoval',
  //           icon: 'duplicateRemoval1',
  //           menu: duplicateRemovalMenu,
  //           meta,
  //           previousData: data,
  //           ...position,
  //         },
  //         position,
  //       },
  //     ]);
  //     const duplicateRemovalNodeLineId =
  //       duplicateRemovalNodeId + uniqueId('_line');
  //     reactFlowInstance.addEdges([
  //       {
  //         id: duplicateRemovalNodeLineId,
  //         source: duplicateRemovalNodeId,
  //         sourceHandle: 'A',
  //         targetHandle: data.type === 'query_table' ? 'B' : undefined,
  //         target: data.dataId,
  //         label: '去重',
  //       },
  //     ]);
  //   },
  // },
  {
    icon: 'deviation1',
    text: '偏差',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const deviationNodeId = getNodeMapid(
        data.dataId + uniqueId('_intersection')
      );
      const placeholderNodeID = getNodeMapid(
        deviationNodeId + uniqueId('_placeholder')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      const placeholderNodePosition = { x: data.x, y: data.y + 140 };

      reactFlowInstance.addNodes([
        {
          id: deviationNodeId,
          type: 'operations',
          data: {
            id: deviationNodeId,
            type: 'deviation',
            icon: 'deviation1',
            menu: deviationMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
        {
          id: placeholderNodeID,
          type: 'placeholderNode',
          data: { type: 'placeholder', icon: 'placeholder' },
          position: placeholderNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: deviationNodeId + uniqueId('_line'),
          target: data.dataId,
          source: deviationNodeId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          sourceHandle: 'A',
          label: '偏差',
        },
        {
          id: deviationNodeId + uniqueId('_line'),
          source: deviationNodeId,
          target: placeholderNodeID,
          sourceHandle: 'A',
          animated: true,
          label: '偏差',
        },
      ]);
    },
  },
  {
    icon: 'dataProcessing1',
    text: '数据加工',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      const dataProcessingNodeId = getNodeMapid(
        data.dataId + uniqueId('_intersection')
      );
      const intersectionNodePosition = { x: data.x + 150, y: data.y + cH };
      reactFlowInstance.addNodes([
        {
          id: dataProcessingNodeId,
          type: 'operations',
          data: {
            id: dataProcessingNodeId,
            type: 'dataProcessing',
            icon: 'dataProcessing1',
            menu: dataProcessingMenu,
            meta,
            previousData: data,
            associatedNode: [],
            ...intersectionNodePosition,
          },
          position: intersectionNodePosition,
        },
      ]);
      reactFlowInstance.addEdges([
        {
          id: dataProcessingNodeId + uniqueId('_line'),
          target: data.dataId,
          source: dataProcessingNodeId,
          targetHandle: data.type === 'query_table' ? 'B' : undefined,
          sourceHandle: 'A',
          label: '数据加工',
        },
      ]);
    },
  },
  {
    icon: 'remove1',
    text: '删除',
    callBack: (reactFlowInstance: ReactFlowInstance, data) =>
      removeNode(data.dataId, reactFlowInstance),
  },
  {
    icon: 'query-search',
    text: '查看数据',
    callBack: (reactFlowInstance: ReactFlowInstance, data, meta) => {
      data.previewData && data.previewData();
      data.setPerviewLoading && data.setPerviewLoading();
    },
  },
];
