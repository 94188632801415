import { Colors } from './Colors';
import * as FontFamilies from './Fonts';

import { typography } from './typography';
import { getColorWithOpacity } from './theme/utils';
import { dark } from './theme/dark';
import {
  IntentColors,
  Intent,
  IntentIcons,
  lightShades,
  darkShades,
} from './theme/common';
import style, { appColors, iconSizes, Skin, Theme } from './theme/constant';
export type FontFamily = typeof FontFamilies[keyof typeof FontFamilies];
export { light } from './theme/light';
export { dark, IntentColors, getColorWithOpacity };
export type { Intent, IntentIcons };
export * from './theme/constant';

export const theme: Theme = {
  radii: [0, 4, 8, 10, 20, 50],
  fontSizes: [0, 10, 12, 14, 16, 18, 24, 28, 32, 48, 64],
  spaces: [0, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 30, 36, 38, 40, 42, 44],
  fontWeights: [0, 400, 500, 700],
  typography: typography,
  iconSizes: iconSizes,
  propertyPane: {
    width: 270,
    titleHeight: 40,
    connectionsHeight: 30,
    height: 600,
    dividerColor: Colors.MAKO,
  },
  evaluatedValuePopup: {
    width: 300,
    height: 500,
  },
  drawerWidth: '80%',
  colors: {
    tooltip: {
      lightBg: lightShades[0],
      lightText: lightShades[10],
      darkBg: lightShades[10],
      darkText: lightShades[0],
    },
    appBackground: '#fff',
    artboard: '#f2f3f5',
    primaryOld: Colors.GREEN,
    primaryDarker: Colors.JUNGLE_GREEN,
    primaryDarkest: Colors.JUNGLE_GREEN_DARKER,
    secondary: Colors.GEYSER_LIGHT,
    secondaryDarker: Colors.CONCRETE,
    secondaryDarkest: Colors.MERCURY,
    error: Colors.ERROR_RED,
    infoOld: Colors.SLATE_GRAY,
    errorMessage: Colors.ERROR_RED,
    hover: Colors.POLAR,
    inputActiveBorder: Colors.HIT_GRAY,
    inputInactiveBG: Colors.AQUA_HAZE,
    textDefault: Colors.BLACK_PEARL,
    textOnDarkBG: Colors.WHITE,
    textOnGreyBG: Colors.CHARCOAL,
    textOnWhiteBG: Colors.CODE_GRAY,
    textAnchor: Colors.PURPLE,
    border: Colors.GEYSER,
    paneCard: Colors.SHARK,
    paneInputBG: Colors.SHARK,
    paneBG: Colors.OUTER_SPACE,
    paneText: Colors.GRAY_CHATEAU,
    paneTextBG: Colors.DEEP_SPACE,
    paneTextUnderline: Colors.LIGHT_GREYISH_BLUE,
    paneSectionLabel: Colors.CADET_BLUE,
    navBG: Colors.SHARK,
    grid: Colors.ALTO2,
    containerBorder: Colors.FRENCH_PASS,
    menuButtonBGInactive: Colors.JUNGLE_MIST,
    menuIconColorInactive: Colors.OXFORD_BLUE,
    bodyBG: Colors.ATHENS_GRAY,
    builderBodyBG: Colors.WHITE,
    widgetMultiSelectBorder: Colors.MALIBU,
    widgetBorder: Colors.SLATE_GRAY,
    widgetLightBorder: Colors.WHITE_SMOKE,
    widgetSecondaryBorder: Colors.MERCURY,
    messageBG: Colors.CONCRETE,
    paneIcon: Colors.TROUT,
    notification: Colors.JAFFA,
    bindingTextDark: Colors.BINDING_COLOR,
    bindingText: Colors.BINDING_COLOR_LT,
    cmBacground: Colors.BLUE_CHARCOAL,
    lightningborder: Colors.ALABASTER,
    formButtonColor: Colors.WHITE,
    appCardColors: appColors,
    dataTypeBg: {
      function: '#BDB2FF',
      object: '#FFD6A5',
      unknown: '#4bb',
      array: '#CDFFA5',
      number: '#FFB2B2',
    },
    scrollbarLight: getColorWithOpacity(Colors.CHARCOAL, 0.5),
    scrollbarLightBG: getColorWithOpacity(Colors.WHITE, 0.5),
    scrollbarDark: getColorWithOpacity(Colors.LIGHT_GREY, 0.5),
    scrollbarDarkBG: getColorWithOpacity(Colors.CODE_GRAY, 0.5),
    dropdownIconBg: Colors.ALTO2,
    welcomeTourStickySidebarColor: Colors.WHITE,
    welcomeTourStickySidebarBackground: '#F86A2B',
    dropdownIconDarkBg: Colors.DARK_GRAY,
    dropdownGreyBg: Colors.Gallery,
  },

  lineHeights: [0, 14, 16, 18, 22, 24, 28, 36, 48, 64, 80],
  fonts: {
    text: FontFamilies.TextFonts,
    code: FontFamilies.CodeFonts,
  },
  borders: [
    {
      thickness: 1,
      style: 'dashed',
      color: Colors.FRENCH_PASS,
    },
    {
      thickness: 2,
      style: 'solid',
      color: Colors.FRENCH_PASS,
    },
    {
      thickness: 1,
      style: 'solid',
      color: Colors.GEYSER_LIGHT,
    },
    {
      thickness: 1,
      style: 'solid',
      color: Colors.FRENCH_PASS,
    },
    {
      thickness: 3,
      style: 'solid',
      color: Colors.MYSTIC,
    },
  ],
  sidebarWidth: '250px',
  homePage: {
    header: 48,
    leftPane: {
      width: 240,
      leftPadding: 16,
      rightMargin: 12,
    },
    main: {
      marginLeft: 112,
    },
    search: {
      height: 81,
      paddingTop: 30,
    },
    sidebar: 320,
  },
  headerHeight: '48px',
  smallHeaderHeight: '32px',
  defaultHeaderHeight: '48px',
  bottomBarHeight: '34px',
  integrationsPageUnusableHeight: '182px',
  backBanner: '30px',
  canvasBottomPadding: 100,
  navbarMenuHeight: '35px',
  navbarMenuLineHeight: '25px',
  sideNav: {
    maxWidth: 220,
    minWidth: 50,
    bgColor: Colors.OXFORD_BLUE,
    fontColor: Colors.WHITE,
    activeItemBGColor: Colors.SHARK,
    navItemHeight: 42,
  },
  card: {
    minWidth: 310,
    minHeight: 240,
    titleHeight: 48,
    divider: {
      thickness: 1,
      style: 'solid',
      color: Colors.GEYSER_LIGHT,
    },
  },
  dropdown: {
    [Skin.LIGHT]: {
      hoverBG: lightShades[2],
      hoverText: lightShades[10],
      inActiveBG: lightShades[2],
      inActiveText: lightShades[10],
      border: Colors.WHITE,
      background: Colors.WHITE,
    },
    [Skin.DARK]: {
      hoverBG: darkShades[0],
      hoverText: darkShades[9],
      inActiveBG: darkShades[2],
      inActiveText: darkShades[7],
      border: Colors.TROUT_DARK,
      background: darkShades[4],
    },
  },
  authCard: {
    width: 440,
    dividerSpacing: 32,
    formMessageWidth: 370,
  },
  shadows: [
    /* 0. tab */
    `inset -1px 0px 0px ${Colors.ATHENS_GRAY}, inset 1px 0px 0px ${Colors.ATHENS_GRAY}, inset 0px 4px 0px ${Colors.GREEN}`,
    /* 1. first tab */
    `inset -1px 0px 0px ${Colors.ATHENS_GRAY}, inset 0px 0px 0px ${Colors.ATHENS_GRAY}, inset 0px 4px 0px ${Colors.GREEN}`,
    /* 2. container */
    `0 1px 1px 0 rgba(60,75,100,.14) ,0 2px 1px -1px rgba(60,75,100,.12), 0 1px 3px 0 rgba(60,75,100,.2)`,
  ],
  widgets: {
    tableWidget: {
      selectHighlightColor: Colors.GEYSER_LIGHT,
    },
  },
  pageContentWidth: 1224,
  tabPanelHeight: 34,
  actionsBottomTabInitialHeight: '40%',
  alert: {
    info: {
      color: Colors.AZURE_RADIANCE,
    },
    success: {
      color: Colors.OCEAN_GREEN,
    },
    error: {
      color: Colors.ERROR_RED,
    },
    warning: {
      color: Colors.BUTTER_CUP,
    },
  },
  lightningMenu: {
    [Skin.DARK]: {
      default: {
        color: darkShades[7],
        background: 'transparent',
      },
      active: {
        color: darkShades[9],
        background: dark.info.main,
      },
      hover: {
        color: darkShades[9],
        background: darkShades[7],
      },
      none: {
        color: 'transparent',
        background: 'transparent',
      },
    },
    [Skin.LIGHT]: {
      default: {
        color: lightShades[7],
        background: 'transparent',
      },
      active: {
        color: lightShades[11],
        background: dark.info.light,
      },
      hover: {
        color: lightShades[11],
        background: lightShades[7],
      },
      none: {
        color: 'transparent',
        background: 'transparent',
      },
    },
  },
  actionSidePane: {
    width: 265,
  },
  onboarding: {
    statusBarHeight: 92,
  },
  settings: {
    footerHeight: 84,
    footerShadow: '0px 0px 18px -6px rgb(0, 0, 0, 0.25)',
    linkBg: lightShades[2],
  },
};

export default style;
