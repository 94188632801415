// import API from 'api/Api';

import axios from 'axios';
import { debounce } from 'lodash';
import { stringify } from 'qs';

class NoSqlApi {
  static url = 'tactics/taskTip/list';
  static getTableList(id: string) {
    return axios.get(
      `/tactics/resource/list?${stringify({
        queryId: id,
        status: 1,
      })}`
    );
  }
  static getTablemeta(id: string) {
    return axios.get(`/tactics/tablemeta/${id}`);
  }

  static getTableTypes() {
    return axios.get(
      `/tactics/dict/types?types=model_type,analysis_type,string_condition,field_condition,column_type,column_condition`
    );
  }

  // 获取预览结果
  static getPreview({ compress, ...extras }: Record<string, any>) {
    const str = compress ? `?${stringify({ compress })}` : '';
    return axios.post(`/tactics/models/queryPreviewAndSql${str}`, extras);
  }

  static getNodes(id: string) {
    return axios.get(`/api/v1/nodeModel/${id}`);
  }

  static setNodes = debounce((id: string, nodeData) => {
    return axios.post(`/api/v1/nodeModel/save`, {
      queryId: id,
      nodeData: nodeData,
    });
  }, 500);
}

export default NoSqlApi;
