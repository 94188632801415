enum ArcoButtonType {
  DEFAULT = '默认',
  PRIMARY = '主要',
  SECONDARY = '次要',
  COLOUR = '彩色',
  DASHED = '虚线',
  TEXT = '文字',
  OUTLINE = 'outline',
}

enum RepeatType {
  REPEAT = '平铺',
  'NO_REPEAT' = '不平铺',
  ROUND = '水平方向',
  SPACE = '垂直方向',
  'REPEAT_Y' = '围绕',
  'REPEAT_X' = '空间方向',
}

enum ArcoButtonStatus {
  WARNING = '警告',
  DANGER = '危险',
  SUCCESS = '成功',
  DEFAULT = '默认',
}

// 支持作用域常量
export default {
  ArcoButtonType,
  RepeatType,
  ArcoButtonStatus,
};
