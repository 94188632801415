import React, { useEffect, useState } from 'react';
import Icon from 'components/icon';
import { Input, Checkbox } from '@arco-design/web-react';
import styled from 'styled-components';
import { SpacedSubmitForm, FormActions } from 'pages/UserAuth/StyledComponents';
import { Controller, useForm } from 'react-hook-form';
import { LOGIN_SUBMIT_PATH } from '@appsmith/constants/ApiConstants';
import cs from 'classnames';
import { isEmail, isPhone } from 'utils/formhelpers';
import { getIsSafeRedirectURL } from 'utils/helpers';

const LoginStyle = styled.div`
  @keyframes shaking {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .checkBox-animate {
    animation: shaking 300ms ease-in-out;
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent !important;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
    -webkit-text-fill-color: #333 !important;
  }
`;

const SubmitButton = styled.button`
  height: 48px;
  align-self: center;
  border-radius: 4px;
  color: #fff;
  font-size: 17px;
  margin-top: 12px;
  background: var(--primary-linear-gradient);

  &:hover {
    background: var(--primary-gradient-hover);
  }

  &:active {
    background: var(--primary-gradient-active);
  }
  &.bg-disable {
    background: var(--disable) !important;
  }
`;

const CheckboxWrap = styled.div`
  .arco-checkbox {
    padding-left: 0;
  }
  .arco-checkbox-mask-icon {
    width: 12px;
    height: 10px;
  }
  .arco-checkbox-mask-wrapper .arco-checkbox-mask {
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
  .arco-icon-hover:hover::before {
    background-color: transparent !important;
  }
`;

type AccountType = 'account' | 'password';

export default function MobileLogin({ className }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
  });

  const [submitting, setSubmitting] = useState(false);
  const [isShowShake, setShowShake] = useState(false);
  const [radio, setRadio] = useState(false);
  const [accountActive, setAccountActive] = useState<AccountType>('account');
  const [loginUrl, setLoginUrl] = useState('/api/v1/' + LOGIN_SUBMIT_PATH);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const redirectUrl = queryParams.get('redirectUrl');
    if (redirectUrl != null && getIsSafeRedirectURL(redirectUrl)) {
      const encodedRedirectUrl = encodeURIComponent(redirectUrl);
      setLoginUrl(loginUrl + `?redirectUrl=${encodedRedirectUrl}`);
    }
  }, []);
  function handleEmailLogin() {
    const form = document.getElementById('login-form');
    if (!radio) {
      setShowShake(true);
      const timer = setTimeout(() => {
        setShowShake(false);
        clearTimeout(timer);
      }, 300);
    } else {
      setShowShake(false);
      // if (accountActive === 'account') {
      //   setAccountActive('password');
      // } else {
      setSubmitting(true);
      (form as HTMLFormElement).submit();
      setSubmitting(false);
      // }
    }
  }

  return (
    <LoginStyle className={`${className} w-[100%] h-[100vh]`}>
      <div className="w-full h-[1.1rem] flex items-center">
        <div className="flex items-center">
          <Icon className="!w-[0.7rem] !h-[0.7rem] mr-[.1rem]" name="logo-bg" />
          <span className="font-bold text-[16px]">紫微</span>
        </div>
      </div>
      <h2 className="mb-[.48rem] min-h-[.56rem ] text-[.48rem] font-bold pt-[24px]">
        欢迎使用紫微
      </h2>
      <SpacedSubmitForm
        action={loginUrl}
        id="login-form"
        method="post"
        onSubmit={handleSubmit(handleEmailLogin)}
      >
        <div>
          <div className="text-20 text-[#999] mb-[37px] text-left">
            手机号登录
          </div>
          <div className="mb-[24px]">
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <Input
                  height={40}
                  placeholder="输入账号"
                  prefix={
                    <Icon
                      name={
                        accountActive === 'account'
                          ? 'login_phone_icon'
                          : 'login_phone_unactive'
                      }
                      size={16}
                    />
                  }
                  suffix={
                    <span
                      className={cs('text-12 text-orange cursor-pointer', {
                        hidden: accountActive === 'account',
                      })}
                      onClick={() => {
                        if (accountActive === 'password') {
                          setAccountActive('account');
                          setSubmitting(false);
                        }
                      }}
                    >
                      更换账号
                    </span>
                  }
                  {...field}
                  readOnly={accountActive === 'password'}
                />
              )}
              rules={{
                required: '输入账号',
                validate: {
                  username: (v) =>
                    isPhone(v) || isEmail(v) || '请输入正确的手机号或邮箱',
                },
              }}
            />
            {errors.username && (
              <p className="text-red absolute text-12">
                {errors.username.message}
              </p>
            )}
          </div>
          <div className="h-[76.5px]">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input.Password
                  height={40}
                  id="nextInput"
                  placeholder="输入密码"
                  prefix={<Icon name="iconLock" size={16} />}
                  type="password"
                  {...field}
                />
              )}
              rules={{
                required: '请输入密码',
                validate: {
                  password: (v) =>
                    v.length > 6 || v.length == 6 || '密码长度不能少于6',
                },
              }}
            />
            {errors.password && (
              <p className="text-red absolute text-12">
                {errors.password.message}
              </p>
            )}
          </div>
          <FormActions>
            <SubmitButton
              className={cs('font-bold text-16', {
                'bg-disable': submitting,
              })}
              disabled={submitting}
              onClick={handleSubmit(handleEmailLogin)}
              type="submit"
            >
              登录
            </SubmitButton>
          </FormActions>
          <div className={cs('my-[17px] h-[24px]')}>
            <div>
              <Controller
                control={control}
                name="radio"
                render={({ field }) => (
                  <CheckboxWrap
                    className={cs({ 'checkBox-animate': isShowShake })}
                  >
                    <Checkbox
                      className="text-12"
                      {...field}
                      onChange={(val, event) => {
                        setRadio(event.target.checked);
                      }}
                      value={radio}
                    >
                      我已阅读
                      <span
                        className="text-[#FF5800] mx-1 font-[700] cursor-pointer text-12"
                        onClick={() => {
                          const features =
                            'height=800, width=800, top=300, left=500, toolbar=no, menubar=no,scrollbars=yes,resizable=no, location=yes, status=no,fullscreen=no';
                          window.open('/support', 'protocol', features);
                        }}
                      >
                        用户隐私协议
                      </span>
                      并明确违规所带来的法律风险
                    </Checkbox>
                  </CheckboxWrap>
                )}
                rules={{
                  required: false,
                }}
              />
            </div>
          </div>
        </div>
      </SpacedSubmitForm>
    </LoginStyle>
  );
}
