import React from 'react';
import { isArray } from 'lodash';
import CodeMirror from 'codemirror';
import { HintHelper } from 'components/editorComponents/CodeEditor/EditorConfig';
import {
  AutocompleteDataType,
  CommandsCompletion,
} from 'utils/autocomplete/TernServer';
import { Command, generateQuickCommands } from './generateQuickCommands';
import { Datasource } from 'entities/Datasource';
import AnalyticsUtil from 'utils/AnalyticsUtil';
import log from 'loglevel';
import { DataTree, ENTITY_TYPE } from 'entities/DataTree/dataTreeFactory';
import { checkIfCursorInsideBinding } from 'components/editorComponents/CodeEditor/codeEditorUtils';
import { SlashCommandPayload } from 'entities/Action';
import ReactDOM from 'react-dom';
import { datasourceColumnIcon } from 'pages/Editor/Explorer/ExplorerIcons';

export const commandsHelper: HintHelper = (
  editor,
  data: DataTree,
  additional,
  mode
) => {
  let entitiesForSuggestions = Object.values(data).filter(
    (entity: any) =>
      entity.ENTITY_TYPE && entity.ENTITY_TYPE !== ENTITY_TYPE.APPSMITH
  );

  const tableHints = {};
  if (mode) {
    isArray(additional) &&
      additional.forEach((v) => {
        if (v) {
          tableHints[v.name] = v.columns.map((v) => v.name);
        }
      });
  }

  let perPick = '';

  return {
    showHint: (
      editor: CodeMirror.Editor & {
        doc: any;
      },
      { entityId, entityType, expectedType, propertyPath },
      {
        datasources,
        executeCommand,
        pluginIdToImageLocation,
        recentEntities,
        update,
      }: {
        datasources: Datasource[];
        executeCommand: (payload: SlashCommandPayload) => void;
        pluginIdToImageLocation: Record<string, string>;
        recentEntities: string[];
        update: (value: string) => void;
        entityId: string;
      }
    ): boolean => {
      const currentEntityType =
        entityType || ENTITY_TYPE.ACTION || ENTITY_TYPE.JSACTION;
      entitiesForSuggestions = entitiesForSuggestions.filter((entity: any) => {
        return currentEntityType === ENTITY_TYPE.WIDGET
          ? entity.ENTITY_TYPE !== ENTITY_TYPE.WIDGET
          : entity.ENTITY_TYPE !== ENTITY_TYPE.ACTION;
      });
      const cursorBetweenBinding = checkIfCursorInsideBinding(editor);
      const value = editor.getValue();
      const slashIndex = value.lastIndexOf('/');
      const shouldShowBinding = !cursorBetweenBinding && slashIndex > -1;
      if (shouldShowBinding || mode) {
        const searchText = value.substring(slashIndex + 1);

        let currentSelection: CommandsCompletion = {
          origin: '',
          type: AutocompleteDataType.UNKNOWN,
          data: {
            doc: '',
          },
          text: '',
          shortcut: '',
        };
        const cursor = editor.getCursor();
        if (mode === 'sql') {
          const temp = editor.doc.modeOption;
          editor.doc.modeOption = 'sql';
          // console.log('perPick', perPick, tableHints);
          const hints = (CodeMirror as any).hint.sql(editor, {
            tables: tableHints,
            defaultTable: perPick,
          });
          hints.list = hints.list.map((v) => {
            if (typeof v !== 'object') {
              return;
            }
            v.render = (element: HTMLElement, self: any, data: any) => {
              let icon;
              if (v.className.search('CodeMirror-hint-table') > -1) {
                icon = (
                  <span className="w-[15px] h-[15px] text-center font-hm-12-bold text-white inline-flex justify-center items-center">
                    {datasourceColumnIcon}
                  </span>
                );
              }
              if (v.className.search('CodeMirror-hint-default-table') > -1) {
                icon = (
                  <span className="w-[15px] h-[15px] text-center font-hm-12-bold text-white inline-flex justify-center items-center">
                    {datasourceColumnIcon}
                  </span>
                );
              }
              if (v.className.search('CodeMirror-hint-keyword') > -1) {
                icon = (
                  <span className="bg-orange w-[15px] h-[15px] text-center font-hm-12-bold text-white inline-flex justify-center items-center content-center">
                    K
                  </span>
                );
              }
              ReactDOM.render(
                <Command icon={icon} name={data.text} />,
                element
              );
            };
            v.className = `${v.className} CodeMirror-commands`;
            return v;
          });
          editor.showHint({
            hint: () => {
              CodeMirror.on(hints, 'pick', (selected: CommandsCompletion) => {
                if (selected.className.search('CodeMirror-hint-table') > -1) {
                  if (
                    selected.className.search(
                      'CodeMirror-hint-default-table'
                    ) === -1
                  ) {
                    perPick = selected.text;
                  }
                }

                update(value.slice(0, slashIndex) + selected.text);
                setTimeout(() => {
                  editor.focus();
                  if (
                    selected.action &&
                    typeof selected.action === 'function'
                  ) {
                    selected.action();
                  } else {
                    selected.triggerCompletionsPostPick &&
                      CodeMirror.signal(editor, 'postPick');
                  }
                });
                try {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { data, render, ...rest } = selected;
                  const { ENTITY_TYPE, name, pluginType } = data as any;
                  AnalyticsUtil.logEvent('SLASH_COMMAND', {
                    ...rest,
                    ENTITY_TYPE,
                    name,
                    pluginType,
                  });
                } catch (e) {
                  log.debug(e, 'Error logging slash command');
                }
              });
              CodeMirror.on(hints, 'select', (selected: CommandsCompletion) => {
                currentSelection = selected;
              });
              return hints;
            },
            extraKeys: {
              Up: (cm: CodeMirror.Editor, handle: any) => {
                handle.moveFocus(-1);
                if (currentSelection.isHeader === true) {
                  handle.moveFocus(-1);
                }
              },
              Down: (cm: CodeMirror.Editor, handle: any) => {
                handle.moveFocus(1);
                if (currentSelection.isHeader === true) {
                  handle.moveFocus(1);
                }
              },
            },
            completeSingle: false,
          });
          editor.doc.modeOption = temp;
        } else if (shouldShowBinding) {
          const list = generateQuickCommands(
            entitiesForSuggestions,
            currentEntityType,
            searchText,
            {
              datasources,
              executeCommand,
              pluginIdToImageLocation,
              recentEntities,
            },
            expectedType || 'string',
            entityId,
            propertyPath
          );
          const hints = {
            list,
            from: {
              ch: cursor.ch - searchText.length - 1,
              line: cursor.line,
            },
            to: editor.getCursor(),
            selectedHint: 1,
          };
          editor.showHint({
            hint: () => {
              CodeMirror.on(hints, 'pick', (selected: CommandsCompletion) => {
                perPick = selected.text;
                update(value.slice(0, slashIndex) + selected.text);
                setTimeout(() => {
                  editor.focus();
                  editor.setCursor({
                    line: editor.lineCount() - 1,
                    ch: editor.getLine(editor.lineCount() - 1).length - 2,
                  });
                  if (
                    selected.action &&
                    typeof selected.action === 'function'
                  ) {
                    selected.action();
                  } else {
                    selected.triggerCompletionsPostPick &&
                      CodeMirror.signal(editor, 'postPick');
                  }
                });
                try {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { data, render, ...rest } = selected;
                  const { ENTITY_TYPE, name, pluginType } = data as any;
                  AnalyticsUtil.logEvent('SLASH_COMMAND', {
                    ...rest,
                    ENTITY_TYPE,
                    name,
                    pluginType,
                  });
                } catch (e) {
                  log.debug(e, 'Error logging slash command');
                }
              });
              CodeMirror.on(hints, 'select', (selected: CommandsCompletion) => {
                currentSelection = selected;
              });
              return hints;
            },
            extraKeys: {
              Up: (cm: CodeMirror.Editor, handle: any) => {
                handle.moveFocus(-1);
                if (currentSelection.isHeader === true) {
                  handle.moveFocus(-1);
                }
              },
              Down: (cm: CodeMirror.Editor, handle: any) => {
                handle.moveFocus(1);
                if (currentSelection.isHeader === true) {
                  handle.moveFocus(1);
                }
              },
            },
            completeSingle: false,
          });
        }

        return true;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No types available
      editor.closeHint();
      return false;
    },
  };
};
