import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import { playWelcomeAnimation } from 'utils/helpers';
import {
  createMessage,
  WELCOME_BODY,
  WELCOME_HEADER,
} from '@appsmith/constants/messages';
import NonSuperUserForm, { SuperUserForm } from './GetStarted';
import TextGradient from 'components/ads/TextGradient';
import { Button } from '@arco-design/web-react';

const LandingPageWrapper = styled.div<{ hide: boolean }>`
  width: ${(props) => props.theme.pageContentWidth}px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  opacity: ${(props) => (props.hide ? 0 : 1)};
`;

// const LandingPageContent = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   position: relative;
//   z-index: 100;
// `;

// const StyledTextBanner = styled.div`
//   min-width: ${(props) => props.theme.pageContentWidth * 0.55}px;
//   padding-left: 64px;
// `;

const StyledBannerHeader = styled.h1`
  font-family: 'Paytone One', sans-serif;
  font-size: 72px;
  margin: 0px 0px;
`;

const StyledBannerBody = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  margin: ${(props) => props.theme.spaces[7]}px 0px;
  width: 400px;
`;

const LandingPageContent = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;

const StyledRoundBanner = styled.div`
  position: absolute;
  right: 1059px;
  top: 488px;
  width: 944px;
  height: 944px;
  border-radius: 50%;
  transform: rotate(90deg);
  background: linear-gradient(
    135deg,
    #ff71ad 0%,
    #ff71ad 0%,
    #ffa852 99%,
    #ffa852 100%
  );
`;

const StyledTextBanner = styled.div`
  width: 240px;
  top: 308px;
  left: 260px;

  p {
    width: 240px;
    height: 58px;
    font-size: 20px;
    font-weight: normal;
    color: #333333;
    margin: 32px 0;
  }
`;

const StyledButtonBanner = styled(Button)`
  width: 128px;
  height: 40px;
  border-radius: 4px;
  /* background: linear-gradient(107deg, #FF71AD 0%, #FF71AD 0%, #FFA852 99%, #FFA852 100%); */
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
`;

const StyledImage = styled.img``;

// const getWelcomeImage = () => `${ASSETS_CDN_URL}/welcome-banner.svg`;

type LandingPageProps = {
  onGetStarted?: () => void;
  forSuperUser: boolean;
};

const WELCOME_PAGE_ANIMATION_CONTAINER = 'welcome-page-animation-container';

const includeFonts = () => {
  const preconnectGoogleapis = document.createElement('link');
  preconnectGoogleapis.rel = 'preconnect';
  preconnectGoogleapis.href = 'https://fonts.googleapis.com';
  document.head.appendChild(preconnectGoogleapis);

  const preconnectGstatic = document.createElement('link') as any;
  preconnectGstatic.rel = 'preconnect';
  preconnectGstatic.href = 'https://fonts.gstatic.com';
  preconnectGstatic.crossorigin = 'crossorigin';
  document.head.appendChild(preconnectGstatic);

  const fonts = document.createElement('link');
  fonts.rel = 'stylesheet';
  fonts.href =
    'https://fonts.googleapis.com/css2?family=Montserrat&family=Paytone+One&display=swap';
  document.head.appendChild(fonts);
};

function Banner() {
  return (
    <>
      <StyledBannerHeader>{createMessage(WELCOME_HEADER)}</StyledBannerHeader>
      <StyledBannerBody>{createMessage(WELCOME_BODY)}</StyledBannerBody>
    </>
  );
}

export default memo(function LandingPage(props: LandingPageProps) {
  const [fontsInjected, setFontsInjected] = useState(false);
  useEffect(() => {
    includeFonts();
    playWelcomeAnimation(`#${WELCOME_PAGE_ANIMATION_CONTAINER}`);
    //wait for the fonts to be loaded
    setTimeout(() => {
      setFontsInjected(true);
    }, 100);
  }, []);
  return (
    <LandingPageWrapper
      hide={!fontsInjected}
      id={WELCOME_PAGE_ANIMATION_CONTAINER}
    >
      <LandingPageContent className="flex justify-around items-center">
        <StyledRoundBanner />
        <StyledTextBanner className=" font-hm">
          <TextGradient className="text-4xl leading-[58px] block mb-[32px]">
            欢迎
          </TextGradient>
          <p>在设置好您的账户之后， 即可开始愉快的搭建系统啦</p>
          <StyledButtonBanner
            className="fs-btn-primary relative z-[999]"
            onClick={props.onGetStarted}
            type="primary"
          >
            开始
          </StyledButtonBanner>
        </StyledTextBanner>
        <StyledImage src="/welcome1.png" />
      </LandingPageContent>

      {/* <LandingPageContent>
        <StyledTextBanner>
          <Banner />
          {props.forSuperUser ? (
            <SuperUserForm onGetStarted={props.onGetStarted} />
          ) : (
            <NonSuperUserForm onGetStarted={props.onGetStarted} />
          )}
        </StyledTextBanner>
        <StyledImageBanner>
          <StyledImage src={getWelcomeImage()} />
        </StyledImageBanner>
      </LandingPageContent> */}
    </LandingPageWrapper>
  );
});
