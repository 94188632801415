import React from 'react';
import styled from 'styled-components';
import { CommonComponentProps } from './common';
import Text, { Case, TextType } from './Text';

export type TabProp<T> = {
  key: string;
  title: T;
  panelComponent: JSX.Element;
};

const TabList = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.multiSwitch.bg};
  width: fit-content;
  margin-bottom: 12px;
  background-color: #f7f7f7;
  padding: 0 2px;
`;

const TabContent = styled.div`
  height: calc(100% - 24px);
`;

const Tab = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  ${(props) =>
    props.selected
      ? `
		background-color: ${props.theme.colors.multiSwitch.selectedBg};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .08);
    height: 28px;
		`
      : `
      height:32px;
      `};
  border-radius: 2px;
  font-size: 12px;
  .cs-text {
    color: ${(props) => (props.selected ? '#333333' : '#999999')};
  }
`;

const TabHeader = styled.div<{ stickyTabHeader?: boolean }>`
  ${({ stickyTabHeader }) =>
    stickyTabHeader &&
    `
      background-color: white;
      position: sticky;
      top: -10px;
      z-index: 10;
      padding-top: 10px;
      padding-bottom: 5px;
      overflow: hidden;
    `}
`;

type MultiSwitchProps<T> = CommonComponentProps & {
  tabs: Array<TabProp<T>>;
  selected: { title: T; value: string };
  stickyTabHeader?: boolean;
  customStyle?: Record<string, string>;
  onSelect: (title: string) => void;
};

export default function MultiSwitch<T>(props: MultiSwitchProps<T>) {
  const selectedTab = props.tabs.find(
    (tab) => tab.key === props.selected.value
  );

  return (
    <div data-cy={props.cypressSelector}>
      <TabHeader
        stickyTabHeader={props.stickyTabHeader}
        style={props.customStyle}
      >
        <TabList>
          {props.tabs.map((tab) => (
            <Tab
              data-cy={`tab--${tab.title}`}
              key={tab.key}
              onClick={() => props.onSelect(tab.key)}
              selected={props.selected.value === tab.key}
            >
              <Text case={Case.UPPERCASE} type={TextType.P3}>
                {tab.title}
              </Text>
            </Tab>
          ))}
        </TabList>
      </TabHeader>
      {selectedTab && <TabContent>{selectedTab.panelComponent}</TabContent>}
    </div>
  );
}
