import {
  Button,
  Checkbox,
  Input,
  Message,
  Switch,
  Tag,
} from '@arco-design/web-react';
import {
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  includes,
  map,
  nth,
  uniqueId,
} from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Select } from '@arco-design/web-react';
import { IconMinus, IconPlus } from '@arco-design/web-react/icon';
import CodeEditor from 'components/editorComponents/CodeEditor';
import {
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from 'components/editorComponents/CodeEditor/EditorConfig';
import {
  getDynamicBindings,
  getDynamicStringSegments,
} from 'utils/DynamicBindingUtils';
import { useSelector } from 'react-redux';
import { getDataTreeForAutocomplete } from 'selectors/dataTreeSelectors';

const Option = Select.Option;
const InputSearch = Input.Search;

class Group {
  op;
  groupOp;
  list: GroupList[];
  id;
  constructor() {
    this.op = 'and';
    this.groupOp = '';
    this.list = [new GroupList()];
    this.id = uniqueId();
  }
}

class GroupList {
  field;
  alias;
  op;
  column;
  columnEnabled;
  timeunit;
  value;
  type;
  id;
  constructor() {
    this.id = uniqueId();
  }
}
export default function FilterContent({ data, fields, meta, modal }) {
  const typesData = get(meta, 'typesData');
  const { fields: tableFields, sql, templateSQL } = get(meta, 'tableData');
  const { id, type } = data.previousData.nodeProperty;

  const [fieldsList, setFieldsList] = useState(cloneDeep(tableFields));

  useEffect(() => {
    if (meta.FormInfo?.selectedFields) {
      const selectedFieldsMap = [];
      forEach(meta.FormInfo?.selectedFields, (v) => {
        selectedFieldsMap.push(v.field);
      });
      forEach(fieldsList, (v) => {
        // includes(meta.FormInfo?.selectedFields, {});
        if (includes(selectedFieldsMap, v.field)) {
          v.checked = true;
        }
      });
      setFieldsList([...fieldsList]);
    }
  }, []);
  const selectedFields = useMemo(
    () => filter(fieldsList, (v) => v.checked && !v.hidden),
    [fieldsList]
  );

  const filterID = useRef(meta.FormInfo?.filterID || uniqueId('util_')).current;
  const [groupData, setGroupData] = useState(
    meta.FormInfo?.groupData || [new Group()]
  );
  const { name } = fields;
  const [isColumnsAndValues, setIsColumnsAndValues] = useState(false);
  const dataTere = useSelector(getDataTreeForAutocomplete);

  const queryParams = {
    inputs: [
      {
        id: id,
        sql: sql,
        tableName: name,
        templateSQL: templateSQL,
        type: type,
      },
    ],
    current: {
      type: 'filter',
      id: filterID,
      settings: JSON.stringify({
        select: selectedFields.map((v) => {
          const field = filterID + '.' + v.field;
          return {
            ...v,
            field,
          };
        }),
        select2: [],
        where: groupData,
      }),
    },
  };

  useEffect(() => {
    meta.FormInfo = {
      groupData,
      selectedFields,
      filterID,
    };
  }, [groupData, selectedFields]);
  const [sreachText, setSreachText] = useState('');

  return (
    <div className="filter-content">
      <div className="font-hm-16-bold pb-[20px]">过滤</div>
      <div className="flex">
        <div className="group w-[60%]">
          <div className="font-hm-16-bold pb-[20px]">条件设置</div>

          <div className="tool">
            <Button onClick={() => setGroupData([...groupData, new Group()])}>
              添加组
            </Button>
          </div>
          {groupData.map((v, i) => (
            <div
              className="flex item my-[10px] flex-col border mr-[10px] rounded"
              key={v.id}
            >
              <div className="m-[10px]">
                <span className="mr-[10px]">组内关系：</span>
                <Select
                  defaultValue={v.op || 'and'}
                  onChange={(vlaue) => {
                    v.op = vlaue;
                    setGroupData([...groupData]);
                  }}
                  placeholder="请选择"
                  style={{ width: 154 }}
                >
                  {[
                    {
                      name: '且',
                      code: 'and',
                    },
                    {
                      name: '或',
                      code: 'or',
                    },
                  ].map((r) => (
                    <Option key={r.code} value={r.code}>
                      {r.name}
                    </Option>
                  ))}
                </Select>
                {i ? (
                  <>
                    <span className="ml-[10px]">与上组关系：</span>
                    <Select
                      defaultValue={v.groupOp || 'and'}
                      onChange={(vlaue) => {
                        v.groupOp = vlaue;
                        setGroupData([...groupData]);
                      }}
                      placeholder="请选择"
                      style={{ width: 154 }}
                    >
                      {[
                        {
                          name: '且',
                          code: 'and',
                        },
                        {
                          name: '或',
                          code: 'or',
                        },
                      ].map((r) => (
                        <Option key={r.code} value={r.code}>
                          {r.name}
                        </Option>
                      ))}
                    </Select>
                  </>
                ) : null}
              </div>
              {v.list.map((group) => {
                const { field_condition } = typesData;
                const defaultValue =
                  find(fieldsList, {
                    field: nth(group.field?.split('.') || [], -1),
                  }) || get(fieldsList, '[0]');
                group.field = filterID + '.' + defaultValue.field;
                group.alias = defaultValue.alias;
                group.alias = defaultValue.type;
                group.op = group.op || get(field_condition, '[0].code');
                return (
                  <div className="group-Item flex m-[10px]" key={group.id}>
                    <div className="condition flex w-[70%]">
                      <Select
                        defaultValue={defaultValue}
                        onChange={(value) => {
                          group.field = filterID + '.' + value.field;
                          group.alias = value.alias;
                          group.type = value.type;
                          setGroupData([...groupData]);
                        }}
                        placeholder="请选择"
                        style={{ width: 154 }}
                      >
                        {fieldsList.map((option) => (
                          <Option key={option.field} value={option}>
                            {option.alias}
                          </Option>
                        ))}
                      </Select>
                      <Select
                        defaultValue={
                          group.op || get(field_condition, '[0].code')
                        }
                        onChange={(vlaue) => {
                          group.op = vlaue;
                          setGroupData([...groupData]);
                        }}
                        placeholder="请选择"
                        style={{ width: 154 }}
                      >
                        {field_condition.map((r) => (
                          <Option key={r.code} value={r.code}>
                            {r.name}
                          </Option>
                        ))}
                      </Select>
                      {isColumnsAndValues ? (
                        <Select
                          // defaultValue={
                          //   find(fieldsList, {
                          //     field: nth(group.field?.split('.') || [], -1),
                          //   }) || get(tableFields, '[0].name')
                          // }
                          placeholder="请选择"
                          style={{ width: 154 }}
                        >
                          {tableFields.map((option) => (
                            <Option key={option.name} value={option.name}>
                              {option.alias}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <div style={{ width: 154 }}>
                          <Input
                            hidden
                            placeholder="请输入"
                            value={group.value || ''}
                          />
                          <CodeEditor
                            className="max-w-[100px]"
                            // dataTreePath={
                            //   getDynamicBindings(group.value || '')
                            //     .jsSnippets[0]
                            // }
                            evaluatedValue={get(
                              dataTere,
                              get(
                                getDynamicBindings(group.value || ''),
                                'jsSnippets[0]'
                              )
                            )}
                            hideEvaluatedValue={false}
                            hoverInteraction
                            input={{
                              value: group.value,
                              onChange: (value) => {
                                group.value = value;
                                setGroupData([...groupData]);
                              },
                            }}
                            mode={EditorModes.TEXT_WITH_BINDING}
                            size={EditorSize.EXTENDED}
                            tabBehaviour={TabBehaviour.INDENT}
                            theme={EditorTheme.LIGHT}
                          />
                        </div>
                      )}
                    </div>
                    <div className="config-group flex w-[30%]">
                      <div className="item">
                        <Switch
                          checked={isColumnsAndValues}
                          onChange={(value) => {
                            setIsColumnsAndValues(value);
                            group.columnEnabled = value;
                          }}
                          size="small"
                        />
                      </div>
                      {/* <div className="item">
                        <IconSettings />
                      </div> */}
                      <div className="item">
                        <IconPlus
                          onClick={() => {
                            v.list.push(new GroupList());
                            setGroupData(
                              filter(groupData, (v) => v.list.length) as Group[]
                            );
                          }}
                        />
                      </div>
                      <div className="item">
                        <IconMinus
                          onClick={() => {
                            if (groupData.length <= 1) {
                              return Message.warning('至少保留一个条件');
                            }
                            v.list = filter(
                              v.list,
                              (item) => group.id !== item.id
                            );
                            setGroupData(
                              filter(groupData, (v) => v.list.length) as Group[]
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="w-[40%] font-hm-16-bold ">
          <div>
            <span>显示列设置</span>
          </div>
          <div>
            <p className="mt-[10px]">{name}</p>
            <div className="flex items-center">
              <InputSearch
                // mode={EditorModes.TEXT_WITH_BINDING}
                onChange={(val) => setSreachText(val)}
                onSearch={() => {
                  setFieldsList(
                    fieldsList.map((v) => {
                      return {
                        ...v,
                        hidden: !v.alias.includes(sreachText),
                      };
                    })
                  );
                }}
                placeholder="搜素字段名"
                searchButton
                style={{ width: 200, margin: 12 }}
              />
              <Checkbox
                onChange={(flag: boolean) => {
                  setFieldsList(
                    map(fieldsList, (v) => ({ ...v, checked: flag }))
                  );
                }}
              >
                全选
              </Checkbox>
              <Checkbox
                onChange={() => {
                  setFieldsList(
                    map(fieldsList, (v) => ({
                      ...v,
                      checked: !v.checked,
                    }))
                  );
                }}
              >
                反选
              </Checkbox>
            </div>
          </div>
          <div className="filed-h my-[10px]">
            {fieldsList.map((v) =>
              v.hidden ? null : (
                <Tag
                  checkable
                  checked={v.checked}
                  className="m-[5px] border border-primary"
                  color="#FF5800"
                  key={v.field}
                  onCheck={(flag) => {
                    v.checked = flag;
                    setFieldsList([...fieldsList]);
                  }}
                >
                  {v.alias}
                </Tag>
              )
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          className="mr-[30px]"
          onClick={() => {
            if (!get(selectedFields, 'length')) {
              return Message.warning('至少需要选中一个字段');
            }
            modal.current.close();
            modal.ok(queryParams);
          }}
          type="primary"
        >
          提交
        </Button>
        <Button
          onClick={() => {
            modal.current.close();
          }}
          type="secondary"
        >
          取消
        </Button>
      </div>
    </div>
  );
}
