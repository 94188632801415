import { useQuery } from 'react-query';

import DeployApi from 'api/DeployApi';

export const DEFAULT_DEPLOY_RECORD_CURRENT = 1;

export const DEFAULT_DEPLOY_RECORD_PAGE_SIZE = 10;

export const useDeployRecords = (
  current = DEFAULT_DEPLOY_RECORD_CURRENT,
  pageSize = DEFAULT_DEPLOY_RECORD_PAGE_SIZE,
  appId
) => {
  return useQuery(['deployRecords', current, pageSize], async () => {
    const result = await DeployApi.deployRecord({
      current,
      pageSize,
      appId,
    });

    return result;
  });
};
