import React, { memo, useState } from 'react';
import styled from 'styled-components';
import Toggle from 'components/ads/Toggle';
import { ControlWrapper } from 'components/propertyControls/StyledControls';
import {
  AllowToggle,
  AllowToggleLabel,
  AllowToggleWrapper,
  FormBodyWrapper,
  FormHeaderIndex,
  FormHeaderLabel,
  FormHeaderSubtext,
  FormHeaderWrapper,
  StyledLink as Link,
} from './common';
import { TELEMETRY_URL } from 'constants/ThirdPartyConstants';
import {
  createMessage,
  WELCOME_FORM_DATA_COLLECTION_BODY,
  WELCOME_FORM_DATA_COLLECTION_HEADER,
  WELCOME_FORM_DATA_COLLECTION_LABEL_DISABLE,
  WELCOME_FORM_DATA_COLLECTION_LABEL_ENABLE,
  WELCOME_FORM_DATA_COLLECTION_LINK,
} from '@appsmith/constants/messages';

const DataCollectionFormWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-left: ${(props) => props.theme.spaces[17] * 2}px;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 8px;
`;

const StyledFormHeader = styled.div`
  width: 100%;
  h4 {
    width: 313px;
    height: 28px;
    font-size: 12px;
    color: #999999;
  }
`;

const StyleSwitchbutton = styled(Toggle)`
  width: 46px;
  &&&& input:checked + .slider {
    background: linear-gradient(
      106deg,
      #ff71ad 11%,
      #ff71ad 11%,
      #ffa852 95%,
      #ffa852 95%
    );
  }
`;
export default memo(function DataCollectionForm() {
  const [allowCollection, setAllowCollection] = useState(true);
  return (
    <DataCollectionFormWrapper>
      <StyledFormHeader className=" font-hm">
        <h4 className="mb-[24px]">
          使用数据首选项(共享匿名使用数据以帮助改进产品)
        </h4>
        <div className="flex justify-between items-center">
          <h3>共享数据，让紫微平台变得更好</h3>
          <ControlWrapper>
            <AllowToggleWrapper className="flex-row-reverse">
              <AllowToggle>
                <StyleSwitchbutton
                  className="t--welcome-form-datacollection"
                  name="allowCollectingAnonymousData"
                  onToggle={(value) => setAllowCollection(value)}
                  value={allowCollection}
                />
              </AllowToggle>
            </AllowToggleWrapper>
          </ControlWrapper>
        </div>
      </StyledFormHeader>

      {/* <FormHeaderWrapper className="relative flex flex-col items-start">
        <FormHeaderIndex className="absolute -left-6">2.</FormHeaderIndex>
        <FormHeaderLabel>
          {createMessage(WELCOME_FORM_DATA_COLLECTION_HEADER)}
        </FormHeaderLabel>
        <FormHeaderSubtext>
          {createMessage(WELCOME_FORM_DATA_COLLECTION_BODY)}
          <br />
          <StyledLink href={TELEMETRY_URL} target="_blank">
            {createMessage(WELCOME_FORM_DATA_COLLECTION_LINK)}
          </StyledLink>
        </FormHeaderSubtext>
      </FormHeaderWrapper> */}
      {/* <FormBodyWrapper>
        <ControlWrapper>
          <AllowToggleWrapper>
            <AllowToggle>
              <Toggle
                className="t--welcome-form-datacollection"
                name="allowCollectingAnonymousData"
                onToggle={(value) => setAllowCollection(value)}
                value={allowCollection}
              />
            </AllowToggle>
            <AllowToggleLabel>
              {allowCollection
                ? createMessage(WELCOME_FORM_DATA_COLLECTION_LABEL_ENABLE)
                : createMessage(WELCOME_FORM_DATA_COLLECTION_LABEL_DISABLE)}
            </AllowToggleLabel>
          </AllowToggleWrapper>
        </ControlWrapper>
      </FormBodyWrapper> */}
    </DataCollectionFormWrapper>
  );
});
