import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const Wrapper = styled.section`
  flex: 1;
  /* margin-top: ${(props) => props.theme.homePage.header}px; */
  && .fade {
    position: relative;
  }
  && .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  && .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    display: none;
    opacity: 0;
  }
`;

const PageBody = styled.div`
  display: grid;
  grid-template-columns: none;
  grid-auto-columns: auto 1fr;
  width: 100%;
  height: 100vh;
  // padding-top: ${(props) => props.theme.spaces[12]}px;
  margin: 0 auto;
  & > * {
    width: 100%;
  }
`;

type PageWrapperProps = {
  children?: ReactNode;
  displayName?: string;
};

export function PageWrapper(props: PageWrapperProps) {
  return (
    <Wrapper>
      <Helmet>
        <title>{`${
          props.displayName ? `${props.displayName} | ` : ''
        } 福鑫科创`}</title>
      </Helmet>
      <PageBody>{props.children}</PageBody>
    </Wrapper>
  );
}

export default PageWrapper;
