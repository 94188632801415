import { isNumber } from 'lodash';
import Api from 'api/Api';
import axios, { AxiosResponse } from 'axios';

export interface DeployRecordRes {
  data: {
    records: any[];
    total: number;
    current: number;
    pageSize: number;
    pages: number;
  };
  responseMeta: {
    status: number;
    success: boolean;
  };
}

class DeployApi extends Api {
  static url = 'v1/publish';

  static deployRecord = (params): Promise<AxiosResponse<DeployRecordRes>> => {
    return Api.post(DeployApi.url + `/records`, { ...params });
  };

  static deployOpera = (params) => {
    return Api.post(DeployApi.url + `/deploy`, { ...params });
  };

  static fetchPage = (appId) => {
    return Api.get(`v1/pages/${appId}`);
  };

  static uploadFile = (file) => {
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }
    return Api.post('/file/upload', formData, null, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static plugList = () => {
    return Api.get('v1/publishPlugin/list');
  };

  static downloadFile = (id) => {
    return Api.get(DeployApi.url + `/deploy/${id}`);
  };

  static downloadPackageByStream = (id: string, setInstallProgress: any) => {
    // return Api.get(DeployApi.url + `/deploy/${id}`, null, {
    //   responseType: 'blob',
    // });
    return axios.get(`/api/${DeployApi.url}/deploy/${id}`, {
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
      onDownloadProgress: function (progressEvent) {
        // 处理原生进度事件
        const { loaded, total } = progressEvent;
        if (isNumber(loaded) && isNumber(total)) {
          setInstallProgress(Math.floor((loaded / total) * 100));
        }
      },
    });
  };
}

export default DeployApi;
