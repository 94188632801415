export const pagefields = [
  {
    title: '医院工单管理页面',
    description: '包含提交人、工单内容、提交时间',
    views: '8942',
  },
  {
    title: 'IT软件巡检管理页面',
    description: '包含巡检人、巡检软件、巡检内容、巡检状态、巡检备注',
    views: '7092',
  },
  {
    title: '机房巡检页面',
    description:
      '包含环境情况、排风机情况、新风机情况、消防系统情况、空调运行情况、UPS运行情况、网络设备运行情况、服务器运行情况、存在问题、解决情况',
    views: '6321',
  },
  {
    title: '医院资产登记页面',
    description:
      '包含资产名称、资产类型、管理科室、资产金额、折旧年限、折旧金额',
    views: '4819',
  },
  {
    title: 'IP地址白名单管理页面',
    description: '包含使用单位、IP、到期时间、创建人、创建时间',
    views: '4358',
  },
  {
    title: '病历邮寄记管理页面',
    description:
      '包含患者名称、住院号、复印费、收件人、收件地址、手机号码、邮寄状态',
    views: '4267',
  },
  {
    title: '收受红包管理页面',
    description:
      '包含收受红包类型、职工名称、职工ID、职工科室、收受金额、收受时间、备注',
    views: '3689',
  },
  {
    title: '病床租借管理页面',
    description: '包含租借人、联系方式、租借病房、租借病床、押金金额、状态',
    views: '2280',
  },
  {
    title: '医院护理职工管理页面',
    description: '包含职工名称、职工ID、职工类型、职工职称、归属病区、手机号码',
    views: '1280',
  },
];
