import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'constants/DefaultTheme';
import { appInitializer } from 'utils/AppsmithUtils';
import { Slide } from 'react-toastify';
import { LayersContext, Layers } from 'constants/Layers';
import { getCurrentThemeDetails, ThemeMode } from 'selectors/themeSelectors';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import { setThemeMode } from 'actions/themeActions';
import { StyledToastContainer } from 'components/ads/Toast';
import localStorage from 'utils/localStorage';
import { QueryClient, QueryClientProvider } from 'react-query';

import './assets/styles/layers/base.css';
import '@arco-design/web-react/dist/css/arco.css';
import './index.css';
import './assets/styles/index.css';
import './polyfills/corejs-add-on';
import { Inspector } from 'react-dev-inspector';
// enable autofreeze only in development
import { setAutoFreeze } from 'immer';
import { RecoilRoot } from 'recoil';

import AppRouter from './AppRouter';
import store from './store';
import useGlobalCtx, { Ctx } from './guide/guide';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import AppErrorBoundary from './AppErrorBoundary';
import GlobalStyles from 'globalStyles';
import '@fontsource/red-hat-display';
import { queryClient } from 'QueryClient';
import { ParallaxProvider } from 'react-scroll-parallax';
import registerServiceWorker from 'serviceWorkerRegistration';
import { Message } from '@arco-design/web-react';
import { getAppStore } from 'utils/storage';

const shouldAutoFreeze = process.env.NODE_ENV === 'development';
setAutoFreeze(shouldAutoFreeze);
appInitializer();

const isDev = process.env.NODE_ENV === 'development';
const InspectorWrapper = isDev ? Inspector : React.Fragment;

function App() {
  const { guide, hightLight, reset, setGuide, startAppGuide } = useGlobalCtx();

  return (
    <ParallaxProvider>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <InspectorWrapper>
            <Provider store={store}>
              <LayersContext.Provider value={Layers}>
                <Ctx.Provider
                  value={{ hightLight, guide, startAppGuide, reset, setGuide }}
                >
                  <ThemedAppWithProps />
                </Ctx.Provider>
              </LayersContext.Provider>
            </Provider>
          </InspectorWrapper>
        </QueryClientProvider>
      </RecoilRoot>
    </ParallaxProvider>
  );
}

function ThemedApp(props: {
  currentTheme: any;
  setTheme: (themeMode: ThemeMode) => void;
}): JSX.Element {
  useEffect(() => {
    if (localStorage.getItem('THEME') === 'LIGHT') {
      props.setTheme(ThemeMode.LIGHT);
    }
    const AppStore = getAppStore();
    AppStore.getItem('tip-device', (_, value) => {
      if (!value) {
        const clear = Message.normal({
          duration: 60 * 60 * 24 * 1000,
          content: (
            <div className="font-hm-16-bold leading-[32px]">
              建议使用最新版的谷歌浏览器，
              <a
                className="text-content"
                href="https://webcdn.m.qq.com/spcmgr/download/110.0.5481.178_chrome_installer.exe"
                rel="noreferrer"
                target="_blank"
              >
                点击下载
              </a>
              <span
                className="text-highlight border-l-1 border-border-color pl-[15px] ml-[15px] cursor-pointer "
                onClick={() => {
                  clear();
                  AppStore.setItem('tip-device', 'true');
                }}
              >
                我知道了
              </span>
            </div>
          ),
          position: 'bottom',
          className: ' rounded-full px-[30px]',
        });
      }
    });
  }, []);

  return (
    <ThemeProvider theme={props.currentTheme}>
      <StyledToastContainer
        autoClose={5000}
        closeButton={false}
        draggable={false}
        hideProgressBar
        pauseOnHover={false}
        transition={Slide}
      />
      <GlobalStyles />
      <AppErrorBoundary>
        <AppRouter />
      </AppErrorBoundary>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: AppState) => ({
  currentTheme: getCurrentThemeDetails(state),
});
const mapDispatchToProps = (dispatch: any) => ({
  setTheme: (mode: ThemeMode) => {
    dispatch(setThemeMode(mode));
  },
});

const ThemedAppWithProps = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemedApp);

// handler child router
if (/^\/activity-1\/.+/.test(location.pathname)) {
  location.replace('/activity-1/');
} else if (/^\/activity-2\/.+/.test(location.pathname)) {
  location.replace('/activity-2/');
} else {
  // const reactDOMRoot = ReactDOM.createRoot(document.getElementById('root'));
  // reactDOMRoot.render(<App />); // 不支持回调
  ReactDOM.render(<App />, document.getElementById('root'));
}

if (!isDev) {
  registerServiceWorker();
}

// location.replace('/maintenance.html');

// expose store when run in Cypress
// disable cypress currently
// if ((window as any).Cypress) {
//   (window as any).store = store;
// }
