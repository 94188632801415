import {
  ReduxAction,
  ReduxActionTypes,
  PageListPayload,
  ClonePageSuccessPayload,
  ReduxActionErrorTypes,
} from '@appsmith/constants/ReduxActionConstants';
import { createReducer } from 'utils/AppsmithUtils';
import { GenerateCRUDSuccess } from 'actions/pageActions';
import { flatten } from 'components/sortTreeList/utilities';

const initialState: PageListReduxState = {
  pages: [],
  isGeneratingTemplatePage: false,
  applicationId: '',
  currentPageId: '',
  defaultPageId: '',
};

export const pageListReducer = createReducer(initialState, {
  [ReduxActionTypes.DELETE_PAGE_INIT]: (
    state: PageListReduxState,
    action: ReduxAction<{ id: string }>
  ) => {
    if (state.defaultPageId !== action.payload.id) {
      const filterData = (page) => {
        const newData = page.filter((item) => item.id !== action.payload.id);
        newData.forEach(
          (item) => item.children && (item.children = filterData(item.children))
        );
        return newData;
      };
      const pages = [...filterData(state.pages)];
      return {
        ...state,
        pages,
      };
    }
    return state;
  },
  [ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{ pages: PageListPayload; applicationId: string }>
  ) => {
    const flattenMenus = flatten([...action.payload.pages]);
    return {
      ...state,
      ...action.payload,
      defaultPageId:
        flattenMenus.find((page) => page.isDefault)?.id ||
        action.payload.pages[0]?.id,
    };
  },
  [ReduxActionTypes.CREATE_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{
      pageName: string;
      pageId: string;
      layoutId: string;
      isDefault: boolean;
      isScreen: boolean;
      isPrint: boolean;
      pageType: 'PAGE' | 'DIR';
      parentId: string;
      tempPageId: string;
    }>
  ) => {
    const _state = state;
    _state.pages = state.pages.map((page) => ({ ...page, latest: false }));
    _state.pages.push({ ...action.payload, latest: true });
    //       pageType: action.payload.page.pageType,
    // parentId: action.payload.page.parentId,
    return { ..._state };
  },
  [ReduxActionTypes.CLONE_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<ClonePageSuccessPayload>
  ): PageListReduxState => {
    return {
      ...state,
      pages: state.pages
        .map((page) => ({ ...page, latest: false }))
        .concat([{ ...action.payload, latest: true }]),
    };
  },
  [ReduxActionTypes.SET_DEFAULT_APPLICATION_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{ pageId: string; applicationId: string }>
  ) => {
    if (
      state.applicationId === action.payload.applicationId &&
      state.defaultPageId !== action.payload.pageId
    ) {
      const pageList = state.pages.map((page) => {
        if (page.pageId === state.defaultPageId) page.isDefault = false;
        if (page.pageId === action.payload.pageId) page.isDefault = true;
        return { ...page };
      });
      return {
        ...state,
        pages: pageList,
        defaultPageId: action.payload.pageId,
      };
    }
    return state;
  },
  [ReduxActionTypes.SET_APPLICATION_PAGE_FULLSCREEN_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{
      pageId: string;
      applicationId: string;
      flag: boolean;
    }>
  ) => {
    if (state.applicationId !== action.payload.applicationId) return;
    const pages = state.pages.map((page) => {
      if (page.pageId === action.payload.pageId)
        page.isScreen = action.payload.flag;
      return { ...page };
    });

    return {
      ...state,
      pages,
      defaultPageId: action.payload.pageId,
    };
  },
  [ReduxActionTypes.SWITCH_CURRENT_PAGE_ID]: (
    state: PageListReduxState,
    action: ReduxAction<{ id: string }>
  ) => ({
    ...state,
    currentPageId: action.payload.id,
  }),
  [ReduxActionTypes.UPDATE_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{
      id: string;
      name: string;
      isHidden?: boolean;
      icon?: string;
      slug: string;
    }>
  ) => {
    const pages = [...state.pages];
    const loop = (pages: any) => {
      return pages.map((item: any) => {
        return {
          ...(() => {
            return item.id === action.payload.id
              ? {
                  ...item,
                  icon: action.payload.icon,
                  name: action.payload.name,
                  isHidden: !!action.payload.isHidden,
                  slug: action.payload.slug,
                }
              : { ...item };
          })(),
          children:
            Array.isArray(item.children) && item.children.length
              ? loop(item.children)
              : item.children,
        };
      });
    };
    const newPages = loop(pages);
    return { ...state, pages: newPages };
  },
  [ReduxActionTypes.GENERATE_TEMPLATE_PAGE_INIT]: (
    state: PageListReduxState
  ) => {
    return { ...state, isGeneratingTemplatePage: true };
  },
  [ReduxActionTypes.GENERATE_TEMPLATE_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<GenerateCRUDSuccess>
  ) => {
    const _state = state;

    if (action.payload.isNewPage) {
      _state.pages = state.pages.map((page) => ({ ...page, latest: false }));
      const newPage = {
        pageName: action.payload.page.name,
        pageId: action.payload.page.id,
        layoutId: action.payload.page.layouts[0].id,
        isDefault: !!action.payload.page.isDefault,
        pageType: action.payload.page.pageType,
        parentId: action.payload.page.parentId,
      };
      _state.pages.push({ ...newPage, latest: true });
    }
    return {
      ..._state,
      isGeneratingTemplatePage: false,
    };
  },
  [ReduxActionErrorTypes.GENERATE_TEMPLATE_PAGE_ERROR]: (
    state: PageListReduxState
  ) => {
    return { ...state, isGeneratingTemplatePage: false };
  },
  [ReduxActionTypes.SET_PAGE_ORDER_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{
      pages: {
        id: string;
      }[];
    }>
  ) => {
    // const sortingOrder = action.payload.pages.map((page) => page.id);
    // const sortedPages = sortBy(state.pages, (page) => {
    //   return sortingOrder.indexOf(page.pageId);
    // });

    return { ...state, pages: action.payload.pages };
  },
  [ReduxActionTypes.SET_PREV_PAGE_AFTER_GO_PRINT]: (
    state: PageListReduxState,
    action: ReduxAction<{
      pageId: string;
    }>
  ) => {
    return { ...state, prevPageId: action.payload.pageId };
  },
});

export type SupportedLayouts =
  | 'DESKTOP'
  | 'TABLET_LARGE'
  | 'TABLET'
  | 'MOBILE'
  | 'FLUID';
export interface AppLayoutConfig {
  type: SupportedLayouts;
}

export interface PageListReduxState {
  pages: PageListPayload;
  applicationId: string;
  defaultPageId: string;
  currentPageId: string;
  prevPageId: string;
  appLayout?: AppLayoutConfig;
  isGeneratingTemplatePage?: boolean;
}

export default pageListReducer;
