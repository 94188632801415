import { WidgetCardProps, WidgetProps } from 'utils/widgetBridge';
import { PageAction } from 'constants/AppsmithActionConstants/ActionConstants';
import { Org } from 'constants/orgConstants';
import { ERROR_CODES } from '@appsmith/constants/ApiConstants';
import { AppLayoutConfig } from 'reducers/entityReducers/pageListReducer';
import {
  ApplicationPagePayload,
  GitApplicationMetadata,
} from 'api/ApplicationApi';
import { ApplicationVersion } from 'actions/applicationActions';

export const ReduxSagaChannels = {
  WEBSOCKET_APP_LEVEL_WRITE_CHANNEL: 'WEBSOCKET_APP_LEVEL_WRITE_CHANNEL',
  WEBSOCKET_PAGE_LEVEL_WRITE_CHANNEL: 'WEBSOCKET_PAGE_LEVEL_WRITE_CHANNEL',
};

export const ReduxActionTypes = {
  UPDATE_DATA_TREE: 'UPDATE_DATA_TREE',
  UPDATE_PAGE_LIST: 'UPDATE_PAGE_LIST',
  WIDGETS_CLEAR: 'WIDGETS_CLEAR',
  UPDATE_URL_DATA: 'UPDATE_URL_DATA',
  WIDGETS_LOADING: 'WIDGETS_LOADING',
  WIDGETS_REGISTED: 'WIDGETS_REGISTED',
  DIRECT_UPDATE_LAYOUT: 'DirectUpdateLayout',
  HANDLE_ALIGN_WIDGETS: 'HANDLE_ALIGN_WIDGETS',
  Graph_Loading: 'Graph_Loading',
  FIRST_GUIDE: 'FIRST_GUIDE',
  PREVIEW_QUERY_TABLE_DATA: 'PREVIEW_QUERY_TABLE_DATA',
  FETCH_ALLDATASOURCES_SUCCESS: 'FETCH_ALLDATASOURCES_SUCCESS',
  FETCH_ALLDATASOURCES: 'FETCH_ALLDATASOURCES',
  UPDATE_SIDE_NAV_COLLAPSED: 'UPDATE_SIDE_NAV_COLLAPSED',
  FETCH_TEMPLATE_APPLICATIONS_RESPONSE_ORGS_SUCCESS:
    'FETCH_TEMPLATE_APPLICATIONS_RESPONSE_ORGS_SUCCESS',
  UPDATE_PREVIEW_MENU_WIDTH: 'UPDATE_PREVIEW_MENU_WIDTH',
  CHANGE_COLLAPSED_STATE: 'CHANGE_COLLAPSED_STATE',
  SHOW_RECONNECT_DATASOURCE_MODAL: 'SHOW_RECONNECT_DATASOURCE_MODAL',
  RESET_UNCONCONFIGURED_DATASOURCES_LIST_DURING_IMPORT:
    'RESET_UNCONCONFIGURED_DATASOURCES_LIST_DURING_IMPORT',
  RESET_DATASOURCE_CONFIG_FETCHED_FOR_IMPORT_FLAG:
    'RESET_DATASOURCE_CONFIG_FETCHED_FOR_IMPORT_FLAG',
  SET_UNCONFIGURED_DATASOURCES: 'SET_UNCONFIGURED_DATASOURCES',
  SET_ORG_ID_FOR_IMPORT: 'SET_ORG_ID_FOR_IMPORT',
  RESET_SSH_KEY_PAIR: 'RESET_SSH_KEY_PAIR',
  GIT_INFO_INIT: 'GIT_INFO_INIT',
  IMPORT_APPLICATION_FROM_GIT_ERROR: 'IMPORT_APPLICATION_FROM_GIT_ERROR',
  IMPORT_APPLICATION_FROM_GIT_SUCCESS: 'IMPORT_APPLICATION_FROM_GIT_SUCCESS',
  UPDATE_DATASOURCE_IMPORT_SUCCESS: 'UPDATE_DATASOURCE_IMPORT_SUCCESS',
  INIT_DATASOURCE_CONNECTION_DURING_IMPORT_REQUEST:
    'INIT_DATASOURCE_CONNECTION_DURING_IMPORT_REQUEST',
  INIT_DATASOURCE_CONNECTION_DURING_IMPORT_SUCCESS:
    'INIT_DATASOURCE_CONNECTION_DURING_IMPORT_SUCCESS',
  SET_ENTITY_INFO: 'SET_ENTITY_INFO',
  UPDATE_META_STATE: 'UPDATE_META_STATE',
  DISCONNECT_GIT: 'DISCONNECT_GIT',
  SHOW_CONNECT_GIT_MODAL: 'SHOW_CONNECT_GIT_MODAL',
  SET_SHOULD_SHOW_REPO_LIMIT_ERROR_MODAL:
    'SET_SHOULD_SHOW_REPO_LIMIT_ERROR_MODAL',
  SET_SHOULD_SHOW_DISCONNECT_GIT_MODAL: 'SET_SHOULD_SHOW_DISCONNECT_GIT_MODAL',
  RESET_PULL_MERGE_STATUS: 'RESET_PULL_MERGE_STATUS',
  GIT_PULL_INIT: 'GIT_PULL_INIT',
  GIT_PULL_SUCCESS: 'GIT_PULL_SUCCESS',
  SET_APP_VERSION_ON_WORKER: 'SET_APP_VERSION_ON_WORKER',
  CLEAR_COMMIT_SUCCESSFUL_STATE: 'CLEAR_COMMIT_SUCCESSFUL_STATE',
  FETCH_MERGE_STATUS_INIT: 'FETCH_MERGE_STATUS_INIT',
  FETCH_MERGE_STATUS_SUCCESS: 'FETCH_MERGE_STATUS_SUCCESS',
  RESET_MERGE_STATUS: 'RESET_MERGE_STATUS',
  MERGE_BRANCH_INIT: 'MERGE_BRANCH_INIT',
  MERGE_BRANCH_SUCCESS: 'MERGE_BRANCH_SUCCESS',
  FETCH_GIT_STATUS_INIT: 'FETCH_GIT_STATUS_INIT',
  FETCH_GIT_STATUS_SUCCESS: 'FETCH_GIT_STATUS_SUCCESS',
  UPDATE_BRANCH_LOCALLY: 'UPDATE_BRANCH_LOCALLY',
  FETCH_BRANCHES_INIT: 'FETCH_BRANCHES_INIT',
  FETCH_BRANCHES_SUCCESS: 'FETCH_BRANCHES_SUCCESS',
  APP_COLLAB_SET_CONCURRENT_PAGE_EDITORS:
    'APP_COLLAB_SET_CONCURRENT_PAGE_EDITORS',
  FETCH_SSH_KEY_PAIR_INIT: 'FETCH_SSH_KEY_PAIR_INIT',
  INIT_SSH_KEY_PAIR_WITH_NULL: 'INIT_SSH_KEY_PAIR_WITH_NULL',
  FETCH_SSH_KEY_PAIR_SUCCESS: 'FETCH_SSH_KEY_PAIR_SUCCESS',
  SET_IS_IMPORT_APP_VIA_GIT_MODAL_OPEN: 'SET_IS_IMPORT_APP_VIA_GIT_MODAL_OPEN',
  FETCH_GLOBAL_GIT_CONFIG_INIT: 'FETCH_GLOBAL_GIT_CONFIG_INIT',
  FETCH_GLOBAL_GIT_CONFIG_SUCCESS: 'FETCH_GLOBAL_GIT_CONFIG_SUCCESS',
  UPDATE_GLOBAL_GIT_CONFIG_INIT: 'UPDATE_GLOBAL_GIT_CONFIG_INIT',
  UPDATE_GLOBAL_GIT_CONFIG_SUCCESS: 'UPDATE_GLOBAL_GIT_CONFIG_SUCCESS',
  FETCH_LOCAL_GIT_CONFIG_INIT: 'FETCH_LOCAL_GIT_CONFIG_INIT',
  FETCH_LOCAL_GIT_CONFIG_SUCCESS: 'FETCH_LOCAL_GIT_CONFIG_SUCCESS',
  UPDATE_LOCAL_GIT_CONFIG_INIT: 'UPDATE_LOCAL_GIT_CONFIG_INIT',
  UPDATE_LOCAL_GIT_CONFIG_SUCCESS: 'UPDATE_LOCAL_GIT_CONFIG_SUCCESS',
  SHOW_CREATE_GIT_BRANCH_POPUP: 'SHOW_CREATE_GIT_BRANCH_POPUP',
  SHOW_ERROR_POPUP: 'SHOW_ERROR_POPUP',

  CONNECT_TO_GIT_INIT: 'CONNECT_TO_GIT_INIT',
  CONNECT_TO_GIT_SUCCESS: 'CONNECT_TO_GIT_SUCCESS',

  CREATE_NEW_BRANCH_INIT: 'CREATE_NEW_BRANCH_INIT',
  SWITCH_GIT_BRANCH_INIT: 'SWITCH_GIT_BRANCH_INIT',
  COMMIT_TO_GIT_REPO_INIT: 'COMMIT_TO_GIT_REPO_INIT',
  COMMIT_TO_GIT_REPO_SUCCESS: 'COMMIT_TO_GIT_REPO_SUCCESS',
  PUSH_TO_GIT_INIT: 'PUSH_TO_GIT_INIT',
  PUSH_TO_GIT_SUCCESS: 'PUSH_TO_GIT_SUCCESS',
  UPDATE_THREAD_DRAFT_COMMENT: 'UPDATE_THREAD_DRAFT_COMMENT',
  UPDATE_UNPUBLISHED_THREAD_DRAFT_COMMENT:
    'UPDATE_UNPUBLISHED_THREAD_DRAFT_COMMENT',
  FETCH_COMMENT_THREADS_INIT: 'FETCH_COMMENT_THREADS_INIT',
  SET_DRAGGING_COMMENT_THREAD: 'SET_DRAGGING_COMMENT_THREAD',
  SET_HAS_DROPPED_COMMENT_THREAD: 'SET_HAS_DROPPED_COMMENT_THREAD',
  SET_IS_GIT_SYNC_MODAL_OPEN: 'SET_IS_GIT_SYNC_MODAL_OPEN',
  DELETE_COMMENT_EVENT: 'DELETE_COMMENT_EVENT',
  DELETE_COMMENT_THREAD_EVENT: 'DELETE_COMMENT_THREAD_EVENT',
  APP_COLLAB_LIST_EDITORS: 'APP_COLLAB_LIST_EDITORS',
  APP_COLLAB_RESET_EDITORS: 'APP_COLLAB_RESET_EDITORS',
  APP_COLLAB_SET_EDITORS_POINTER_DATA: 'APP_COLLAB_SET_EDITORS_POINTER_DATA',
  APP_COLLAB_UNSET_EDITORS_POINTER_DATA:
    'APP_COLLAB_UNSET_EDITORS_POINTER_DATA',
  APP_COLLAB_RESET_EDITORS_POINTER_DATA:
    'APP_COLLAB_RESET_EDITORS_POINTER_DATA',
  SET_SEARCH_FILTER_CONTEXT: 'SET_SEARCH_FILTER_CONTEXT',
  INSERT_SNIPPET: 'INSERT_SNIPPET',
  CANCEL_SNIPPET: 'CANCEL_SNIPPET',
  FETCH_FEATURE_FLAGS_INIT: 'FETCH_FEATURE_FLAGS_INIT',
  FETCH_FEATURE_FLAGS_SUCCESS: 'FETCH_FEATURE_FLAGS_SUCCESS',
  BIND_DATA_TO_WIDGET: 'BIND_DATA_TO_WIDGET',
  BIND_DATA_ON_CANVAS: 'BIND_DATA_ON_CANVAS',
  MARK_NOTIFICATION_AS_READ_REQUEST: 'MARK_NOTIFICATION_AS_READ_REQUEST',
  MARK_NOTIFICATION_AS_READ_SUCCESS: 'MARK_NOTIFICATION_AS_READ_SUCCESS',
  FETCH_UNREAD_NOTIFICATIONS_COUNT_REQUEST:
    'FETCH_UNREAD_NOTIFICATIONS_COUNT_REQUEST',
  FETCH_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
    'FETCH_UNREAD_NOTIFICATIONS_COUNT_SUCCESS',
  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
  MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST:
    'MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST',
  MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
    'MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS',
  SET_IS_NOTIFICATIONS_LIST_VISIBLE: 'SET_IS_NOTIFICATIONS_LIST_VISIBLE',
  NEW_NOTIFICATION_EVENT: 'NEW_NOTIFICATION_EVENT',
  FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  SET_SHOW_APP_INVITE_USERS_MODAL: 'SET_SHOW_APP_INVITE_USERS_MODAL',
  UPDATE_COMMENT_EVENT: 'UPDATE_COMMENT_EVENT',
  ADD_COMMENT_REACTION: 'ADD_COMMENT_REACTION',
  REMOVE_COMMENT_REACTION: 'REMOVE_COMMENT_REACTION',
  UPLOAD_PROFILE_PHOTO: 'UPLOAD_PROFILE_PHOTO',
  REMOVE_PROFILE_PHOTO: 'REMOVE_PROFILE_PHOTO',
  UPDATE_PHOTO_ID: 'UPDATE_PHOTO_ID',
  UPDATE_USERS_COMMENTS_ONBOARDING_STATE:
    'UPDATE_USERS_COMMENTS_ONBOARDING_STATE',
  HIDE_COMMENTS_INTRO_CAROUSEL: 'HIDE_COMMENTS_INTRO_CAROUSEL',
  SHOW_COMMENTS_INTRO_CAROUSEL: 'SHOW_COMMENTS_INTRO_CAROUSEL',
  PROCEED_TO_NEXT_TOUR_STEP: 'PROCEED_TO_NEXT_TOUR_STEP',
  SET_ACTIVE_TOUR: 'SET_ACTIVE_TOUR',
  RESET_ACTIVE_TOUR: 'RESET_ACTIVE_TOUR',
  SET_ACTIVE_TOUR_INDEX: 'SET_ACTIVE_TOUR_INDEX',
  SET_ARE_COMMENTS_ENABLED: 'SET_ARE_COMMENTS_ENABLED',
  DELETE_THREAD_REQUEST: 'DELETE_THREAD_REQUEST',
  DELETE_THREAD_SUCCESS: 'DELETE_THREAD_SUCCESS',
  EDIT_COMMENT_REQUEST: 'EDIT_COMMENT_REQUEST',
  EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
  MARK_THREAD_AS_READ_REQUEST: 'MARK_THREAD_AS_READ_REQUEST',
  SET_VISIBLE_THREAD: 'SET_VISIBLE_THREAD',
  RESET_VISIBLE_THREAD: 'RESET_VISIBLE_THREAD',
  SET_APP_COMMENTS_FILTER: 'SET_APP_COMMENTS_FILTER',
  SET_SHOULD_SHOW_RESOLVED_COMMENTS: 'SET_SHOULD_SHOW_RESOLVED_COMMENTS',
  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  PIN_COMMENT_THREAD_REQUEST: 'PIN_COMMENT_THREAD_REQUEST',
  PIN_COMMENT_THREAD_SUCCESS: 'PIN_COMMENT_THREAD_SUCCESS',
  INIT_APP_LEVEL_SOCKET_CONNECTION: 'INIT_APP_LEVEL_SOCKET_CONNECTION',
  RETRY_APP_LEVEL_WEBSOCKET_CONNECTION: 'RETRY_APP_LEVEL_WEBSOCKET_CONNECTION',
  INIT_PAGE_LEVEL_SOCKET_CONNECTION: 'INIT_PAGE_LEVEL_SOCKET_CONNECTION',
  RETRY_PAGE_LEVEL_WEBSOCKET_CONNECTION:
    'RETRY_PAGE_LEVEL_WEBSOCKET_CONNECTION',
  SET_IS_APP_LEVEL_WEBSOCKET_CONNECTED: 'SET_IS_APP_LEVEL_WEBSOCKET_CONNECTED',
  SET_IS_PAGE_LEVEL_WEBSOCKET_CONNECTED:
    'SET_IS_PAGE_LEVEL_WEBSOCKET_CONNECTED',
  DRAG_COMMENT_THREAD: 'DRAG_COMMENT_THREAD',
  UPDATE_COMMENT_THREAD_EVENT: 'UPDATE_COMMENT_THREAD_EVENT',
  UPDATE_COMMENT_THREAD_SUCCESS: 'UPDATE_COMMENT_THREAD_SUCCESS',
  SET_COMMENT_THREAD_RESOLUTION_REQUEST:
    'SET_COMMENT_THREAD_RESOLUTION_REQUEST',
  SET_COMMENT_THREAD_RESOLUTION_SUCCESS:
    'SET_COMMENT_THREAD_RESOLUTION_SUCCESS',
  NEW_COMMENT_EVENT: 'NEW_COMMENT_EVENT',
  NEW_COMMENT_THREAD_EVENT: 'NEW_COMMENT_THREAD_EVENT',
  FETCH_APPLICATION_COMMENTS_SUCCESS: 'FETCH_APPLICATION_COMMENTS_SUCCESS',
  FETCH_APPLICATION_COMMENTS_REQUEST: 'FETCH_APPLICATION_COMMENTS_REQUEST',
  UNRESOLVE_COMMENT_THREAD: 'UNRESOLVE_COMMENT_THREAD',
  RESOLVE_COMMENT_THREAD: 'RESOLVE_COMMENT_THREAD',
  SET_IS_COMMENT_THREAD_VISIBLE: 'SET_IS_COMMENT_THREAD_VISIBLE',
  SET_COMMENT_MODE: 'SET_COMMENT_MODE',
  SET_SNIPING_MODE: 'SET_SNIPING_MODE',
  RESET_SNIPING_MODE: 'RESET_SNIPING_MODE',
  HANDLE_PATH_UPDATED: 'HANDLE_PATH_UPDATED',
  RESET_EDITOR_REQUEST: 'RESET_EDITOR_REQUEST',
  RESET_EDITOR_SUCCESS: 'RESET_EDITOR_SUCCESS',
  ADD_COMMENT_TO_THREAD_SUCCESS: 'ADD_COMMENT_TO_THREAD_SUCCESS',
  ADD_COMMENT_TO_THREAD_REQUEST: 'ADD_COMMENT_TO_THREAD_REQUEST',
  CREATE_COMMENT_THREAD_REQUEST: 'CREATE_COMMENT_THREAD_REQUEST',
  CREATE_COMMENT_THREAD_SUCCESS: 'CREATE_COMMENT_THREAD_SUCCESS',
  UNSUBSCRIBE_COMMENT_THREAD_REQUEST: 'UNSUBSCRIBE_COMMENT_THREAD_REQUEST',
  UNSUBSCRIBE_COMMENT_THREAD_SUCCESS: 'UNSUBSCRIBE_COMMENT_THREAD_SUCCESS',
  REMOVE_UNPUBLISHED_COMMENT_THREAD_REQUEST:
    'REMOVE_UNPUBLISHED_COMMENT_THREAD_REQUEST',
  CREATE_UNPUBLISHED_COMMENT_THREAD_REQUEST:
    'CREATE_UNPUBLISHED_COMMENT_THREAD_REQUEST',
  CREATE_UNPUBLISHED_COMMENT_THREAD_SUCCESS:
    'CREATE_UNPUBLISHED_COMMENT_THREAD_SUCCESS',
  SET_COMMENT_THREADS_SUCCESS: 'SET_COMMENT_THREADS_SUCCESS', // todo remove (for dev)
  SET_COMMENT_THREADS_REQUEST: 'SET_COMMENT_THREADS_REQUEST', // todo remove (for dev)
  INIT_COMMENT_THREADS: 'INIT_COMMENT_THREADS', // todo remove (for dev)
  INITIALIZE_EDITOR: 'INITIALIZE_EDITOR',
  INITIALIZE_EDITOR_SUCCESS: 'INITIALIZE_EDITOR_SUCCESS',
  EDITOR_QUERY_SWITCH_LOADING: 'EDITOR_QUERY_SWITCH_LOADING',
  REPORT_ERROR: 'REPORT_ERROR',
  FLUSH_ERRORS: 'FLUSH_ERRORS',
  FLUSH_AND_REDIRECT: 'FLUSH_AND_REDIRECT',
  REDIRECT_TO_NEW_INTEGRATIONS: 'REDIRECT_TO_NEW_INTEGRATIONS',
  SAFE_CRASH_APPSMITH: 'SAFE_CRASH_APPSMITH',
  SAFE_CRASH_APP: 'SAFE_CRASH_APP', // NOTE: when app has crashed, we hope the exception will be captured by AppErrorBoundary
  SAFE_CRASH_APPSMITH_REQUEST: 'SAFE_CRASH_APPSMITH_REQUEST',
  INIT_CANVAS_LAYOUT: 'INIT_CANVAS_LAYOUT',
  FETCH_CANVAS: 'FETCH_CANVAS',
  CLEAR_CANVAS: 'CLEAR_CANVAS',
  FETCH_PAGE_INIT: 'FETCH_PAGE_INIT',
  FETCH_PAGE_SUCCESS: 'FETCH_PAGE_SUCCESS',
  DROP_WIDGET_CANVAS: 'DROP_WIDGET_CANVAS',
  REMOVE_WIDGET_CANVAS: 'REMOVE_WIDGET_CANVAS',
  LOAD_WIDGET_PANE: 'LOAD_WIDGET_PANE',
  ZOOM_IN_CANVAS: 'ZOOM_IN_CANVAS',
  ZOOM_OUT_CANVAS: 'ZOOM_OUT_CANVAS',
  SET_ZOOM_LEVEL: 'SET_ZOOM_LEVEL',
  START_CANVAS_SELECTION_FROM_EDITOR: 'START_CANVAS_SELECTION_FROM_EDITOR',
  STOP_CANVAS_SELECTION_FROM_EDITOR: 'STOP_CANVAS_SELECTION_FROM_EDITOR',
  START_CANVAS_SELECTION: 'START_CANVAS_SELECTION',
  STOP_CANVAS_SELECTION: 'STOP_CANVAS_SELECTION',
  UNDO_CANVAS_ACTION: 'UNDO_CANVAS_ACTION',
  REDO_CANVAS_ACTION: 'REDO_CANVAS_ACTION',
  LOAD_WIDGET_CONFIG: 'LOAD_WIDGET_CONFIG',
  LOAD_PROPERTY_CONFIG: 'LOAD_PROPERTY_CONFIG',
  UPDATE_APPLICATION: 'UPDATE_APPLICATION',
  UPDATE_APP_LAYOUT: 'UPDATE_APP_LAYOUT',
  UPDATE_APPLICATION_SUCCESS: 'UPDATE_APPLICATION_SUCCESS',
  PUBLISH: 'PUBLISH',
  DEBUGGER_LOG: 'DEBUGGER_LOG',
  DEBUGGER_LOG_INIT: 'DEBUGGER_LOG_INIT',
  DEBUGGER_ERROR_ANALYTICS: 'DEBUGGER_ERROR_ANALYTICS',
  DEBUGGER_ADD_ERROR_LOG: 'DEBUGGER_ADD_ERROR_LOG',
  DEBUGGER_DELETE_ERROR_LOG: 'DEBUGGER_DELETE_ERROR_LOG',
  DEBUGGER_ADD_ERROR_LOG_INIT: 'DEBUGGER_ADD_ERROR_LOG_INIT',
  DEBUGGER_DELETE_ERROR_LOG_INIT: 'DEBUGGER_DELETE_ERROR_LOG_INIT',
  CLEAR_DEBUGGER_LOGS: 'CLEAR_DEBUGGER_LOGS',
  SHOW_DEBUGGER: 'SHOW_DEBUGGER',
  HIDE_DEBUGGER_ERRORS: 'HIDE_DEBUGGER_ERRORS',
  SET_CURRENT_DEBUGGER_TAB: 'SET_CURRENT_DEBUGGER_TAB',
  SET_THEME: 'SET_THEME',
  FETCH_WIDGET_CARDS: 'FETCH_WIDGET_CARDS',
  FETCH_WIDGET_CARDS_SUCCESS: 'FETCH_WIDGET_CARDS_SUCCESS',
  ADD_PAGE_WIDGET: 'ADD_PAGE_WIDGET',
  REMOVE_PAGE_WIDGET: 'REMOVE_PAGE_WIDGET',
  LOAD_API_RESPONSE: 'LOAD_API_RESPONSE',
  LOAD_QUERY_RESPONSE: 'LOAD_QUERY_RESPONSE',
  RUN_ACTION_SHORTCUT_REQUEST: 'RUN_ACTION_SHORTCUT_REQUEST',
  RUN_ACTION_REQUEST: 'RUN_ACTION_REQUEST',
  RUN_ACTION_CANCELLED: 'RUN_ACTION_CANCELLED',
  RUN_ACTION_SUCCESS: 'RUN_ACTION_SUCCESS',
  CLEAR_ACTION_RESPONSE: 'CLEAR_ACTION_RESPONSE',
  INIT_API_PANE: 'INIT_API_PANE',
  API_PANE_CHANGE_API: 'API_PANE_CHANGE_API',
  EXECUTE_TRIGGER_REQUEST: 'EXECUTE_TRIGGER_REQUEST',
  LOAD_CANVAS_ACTIONS: 'LOAD_CANVAS_ACTIONS',
  SAVE_PAGE_INIT: 'SAVE_PAGE_INIT',
  SAVE_PAGE_SUCCESS: 'SAVE_PAGE_SUCCESS',
  UPDATE_LAYOUT: 'UPDATE_LAYOUT',
  WIDGET_ADD_CHILD: 'WIDGET_ADD_CHILD',
  WIDGET_CHILD_ADDED: 'WIDGET_CHILD_ADDED',
  WIDGET_REMOVE_CHILD: 'WIDGET_REMOVE_CHILD',
  WIDGETS_MOVE: 'WIDGETS_MOVE',
  WIDGETS_ADD_CHILD_AND_MOVE: 'WIDGETS_ADD_CHILD_AND_MOVE',
  WIDGET_RESIZE: 'WIDGET_RESIZE',
  WIDGET_DELETE: 'WIDGET_DELETE',
  WIDGET_BULK_DELETE: 'WIDGET_BULK_DELETE',
  WIDGET_SINGLE_DELETE: 'WIDGET_SINGLE_DELETE',
  SHOW_PROPERTY_PANE: 'SHOW_PROPERTY_PANE',
  UPDATE_CANVAS_LAYOUT: 'UPDATE_CANVAS_LAYOUT',
  UPDATE_WIDGET_PROPERTY_REQUEST: 'UPDATE_WIDGET_PROPERTY_REQUEST',
  UPDATE_WIDGET_PROPERTY: 'UPDATE_WIDGET_PROPERTY',
  UPDATE_WIDGET_DYNAMIC_PROPERTY: 'UPDATE_WIDGET_DYNAMIC_PROPERTY',
  BATCH_UPDATE_WIDGET_PROPERTY: 'BATCH_UPDATE_WIDGET_PROPERTY',
  BATCH_UPDATE_MULTIPLE_WIDGETS_PROPERTY:
    'BATCH_UPDATE_MULTIPLE_WIDGETS_PROPERTY',
  DELETE_WIDGET_PROPERTY: 'DELETE_WIDGET_PROPERTY',
  FETCH_PROPERTY_PANE_CONFIGS_INIT: 'FETCH_PROPERTY_PANE_CONFIGS_INIT',
  FETCH_PROPERTY_PANE_CONFIGS_SUCCESS: 'FETCH_PROPERTY_PANE_CONFIGS_SUCCESS',
  FETCH_CONFIGS_INIT: 'FETCH_CONFIGS_INIT',
  ADD_WIDGET_REF: 'ADD_WIDGET_REF',
  CREATE_ACTION_INIT: 'CREATE_ACTION_INIT',
  CREATE_ACTION_SUCCESS: 'CREATE_ACTION_SUCCESS',
  FETCH_ACTIONS_INIT: 'FETCH_ACTIONS_INIT',
  FETCH_ACTIONS_SUCCESS: 'FETCH_ACTIONS_SUCCESS',
  UPDATE_ACTION_INIT: 'UPDATE_ACTION_INIT',
  UPDATE_ACTION_SUCCESS: 'UPDATE_ACTION_SUCCESS',
  DELETE_ACTION_INIT: 'DELETE_ACTION_INIT',
  SET_DATASOURCE_EDITOR_MODE: 'SET_DATASOURCE_EDITOR_MODE',
  DELETE_ACTION_SUCCESS: 'DELETE_ACTION_SUCCESS',
  SHOW_ACTION_MODAL: 'SHOW_ACTION_MODAL',
  CANCEL_ACTION_MODAL: 'CANCEL_ACTION_MODAL',
  CONFIRM_ACTION_MODAL: 'CONFIRM_ACTION_MODAL',
  CREATE_QUERY_INIT: 'CREATE_QUERY_INIT',
  ONBOARDING_CREATE_APPLICATION: 'ONBOARDING_CREATE_APPLICATION',
  SET_IS_RECONNECTING_DATASOURCES_MODAL_OPEN:
    'SET_IS_RECONNECTING_DATASOURCES_MODAL_OPEN',
  FETCH_DATASOURCES_INIT: 'FETCH_DATASOURCES_INIT',
  FETCH_DATASOURCES_SUCCESS: 'FETCH_DATASOURCES_SUCCESS',
  FETCH_MOCK_DATASOURCES_INIT: 'FETCH_MOCK_DATASOURCES_INIT',
  FETCH_MOCK_DATASOURCES_SUCCESS: 'FETCH_MOCK_DATASOURCES_SUCCESS',
  ADD_MOCK_DATASOURCES_INIT: 'ADD_MOCK_DATASOURCES_INIT',
  ADD_MOCK_DATASOURCES_SUCCESS: 'ADD_MOCK_DATASOURCES_SUCCESS',
  SAVE_DATASOURCE_NAME: 'SAVE_DATASOURCE_NAME',
  SAVE_DATASOURCE_NAME_SUCCESS: 'SAVE_DATASOURCE_NAME_SUCCESS',
  CREATE_DATASOURCE_INIT: 'CREATE_DATASOURCE_INIT',
  CREATE_DATASOURCE_SUCCESS: 'CREATE_DATASOURCE_SUCCESS',
  CREATE_DATASOURCE_BY_PLUGIN: 'CREATE_DATASOURCE_BY_PLUGIN',
  CREATE_DATASOURCE_FROM_FORM_INIT: 'CREATE_DATASOURCE_FROM_FORM_INIT',
  UPDATE_DATASOURCE_INIT: 'UPDATE_DATASOURCE_INIT',
  UPDATE_DATASOURCE_SUCCESS: 'UPDATE_DATASOURCE_SUCCESS',
  COPY_DATASOURCE_INIT: 'COPY_DATASOURCE_INIT',
  COPY_DATASOURCE_SUCCESS: 'COPY_DATASOURCE_SUCCESS',
  FETCH_FAIL: 'FETCH_FAIL',
  CHANGE_DATASOURCE: 'CHANGE_DATASOURCE',
  FETCH_DATASOURCE_STRUCTURE_INIT: 'FETCH_DATASOURCE_STRUCTURE_INIT',
  FETCH_DATASOURCE_STRUCTURE_SUCCESS: 'FETCH_DATASOURCE_STRUCTURE_SUCCESS',
  REFRESH_DATASOURCE_STRUCTURE_INIT: 'REFRESH_DATASOURCE_STRUCTURE_INIT',
  REFRESH_DATASOURCE_STRUCTURE_SUCCESS: 'REFRESH_DATASOURCE_STRUCTURE_SUCCESS',
  EXPAND_DATASOURCE_ENTITY: 'EXPAND_DATASOURCE_ENTITY',
  TEST_DATASOURCE_INIT: 'TEST_DATASOURCE_INIT',
  TEST_DATASOURCE_SUCCESS: 'TEST_DATASOURCE_SUCCESS',
  FETCH_UNCONFIGURED_DATASOURCE_LIST: 'FETCH_UNCONFIGURED_DATASOURCE_LIST',
  SET_MASTER_DATASOURCE: 'SET_MASTER_DATASOURCE',
  DELETE_DATASOURCE_DRAFT: 'DELETE_DATASOURCE_DRAFT',
  UPDATE_DATASOURCE_DRAFT: 'UPDATE_DATASOURCE_DRAFT',
  FETCH_PUBLISHED_PAGE_INIT: 'FETCH_PUBLISHED_PAGE_INIT',
  FETCH_PUBLISHED_PAGE_SUCCESS: 'FETCH_PUBLISHED_PAGE_SUCCESS',
  REDIRECT_AUTHORIZATION_CODE: 'REDIRECT_AUTHORIZATION_CODE',
  REDIRECT_WINDOW_LOCATION: 'REDIRECT_WINDOW_LOCATION',
  HISTORY_PUSH: 'HISTORY_PUSH',
  DELETE_DATASOURCE_INIT: 'DELETE_DATASOURCE_INIT',
  DELETE_DATASOURCE_SUCCESS: 'DELETE_DATASOURCE_SUCCESS',
  DELETE_DATASOURCE_CANCELLED: 'DELETE_DATASOURCE_CANCELLED',
  STORE_AS_DATASOURCE_INIT: 'STORE_AS_DATASOURCE_INIT',
  STORE_AS_DATASOURCE_UPDATE: 'STORE_AS_DATASOURCE_UPDATE',
  STORE_AS_DATASOURCE_COMPLETE: 'STORE_AS_DATASOURCE_COMPLETE',
  PUBLISH_APPLICATION_INIT: 'PUBLISH_APPLICATION_INIT',
  PUBLISH_APPLICATION_SUCCESS: 'PUBLISH_APPLICATION_SUCCESS',
  CHANGE_APPVIEW_ACCESS_INIT: 'CHANGE_APPVIEW_ACCESS_INIT',
  CHANGE_APPVIEW_ACCESS_SUCCESS: 'CHANGE_APPVIEW_ACCESS_SUCCESS',
  CREATE_PAGE_INIT: 'CREATE_PAGE_INIT',
  CREATE_PAGE_SUCCESS: 'CREATE_PAGE_SUCCESS',
  FETCH_PAGE_LIST_INIT: 'FETCH_PAGE_LIST_INIT',
  FETCH_PAGE_LIST_SUCCESS: 'FETCH_PAGE_LIST_SUCCESS',
  INITIALIZE_PAGE_VIEWER: 'INITIALIZE_PAGE_VIEWER',
  INITIALIZE_PAGE_VIEWER_SUCCESS: 'INITIALIZE_PAGE_VIEWER_SUCCESS',
  FETCH_APPLICATION_INIT: 'FETCH_APPLICATION_INIT',
  FETCH_APPLICATION_SUCCESS: 'FETCH_APPLICATION_SUCCESS',
  INVITED_USERS_TO_ORGANIZATION: 'INVITED_USERS_TO_ORGANIZATION',
  CREATE_APPLICATION_INIT: 'CREATE_APPLICATION_INIT',
  CREATE_APPLICATION_SUCCESS: 'CREATE_APPLICATION_SUCCESS',
  UPDATE_WIDGET_PROPERTY_VALIDATION: 'UPDATE_WIDGET_PROPERTY_VALIDATION',
  HIDE_PROPERTY_PANE: 'HIDE_PROPERTY_PANE',
  INIT_DATASOURCE_PANE: 'INIT_DATASOURCE_PANE',
  INIT_QUERY_PANE: 'INIT_QUERY_PANE',
  QUERY_PANE_CHANGE: 'QUERY_PANE_CHANGE',
  UPDATE_ROUTES_PARAMS: 'UPDATE_ROUTES_PARAMS',
  SET_EXTRA_FORMDATA: 'SET_EXTRA_FORMDATA',
  PERSIST_USER_SESSION: 'PERSIST_USER_SESSION',
  LOGIN_USER_INIT: 'LOGIN_USER_INIT',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  CREATE_USER_INIT: 'CREATE_USER_INIT',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  RESET_USER_PASSWORD_INIT: 'RESET_USER_PASSWORD_INIT',
  RESET_USER_PASSWORD_SUCCESS: 'RESET_USER_PASSWORD_SUCCESS',
  FETCH_PLUGINS_REQUEST: 'FETCH_PLUGINS_REQUEST',
  FETCH_PLUGINS_SUCCESS: 'FETCH_PLUGINS_SUCCESS',
  FETCH_PLUGIN_FORM_CONFIGS_REQUEST: 'FETCH_PLUGIN_FORM_CONFIGS_REQUEST',
  FETCH_PLUGIN_FORM_CONFIGS_SUCCESS: 'FETCH_PLUGIN_FORM_CONFIGS_SUCCESS',
  FETCH_PLUGIN_FORM_SUCCESS: 'FETCH_PLUGIN_FORM_SUCCESS',
  INVITE_USERS_TO_ORG_INIT: 'INVITE_USERS_TO_ORG_INIT',
  INVITE_USERS_TO_ORG_SUCCESS: 'INVITE_USERS_TO_ORG_SUCCESS',
  FORGOT_PASSWORD_INIT: 'FORGOT_PASSWORD_INIT',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  RESET_PASSWORD_VERIFY_TOKEN_SUCCESS: 'RESET_PASSWORD_VERIFY_TOKEN_SUCCESS',
  RESET_PASSWORD_VERIFY_TOKEN_INIT: 'RESET_PASSWORD_VERIFY_TOKEN_INIT',
  EXECUTE_PAGE_LOAD_ACTIONS: 'EXECUTE_PAGE_LOAD_ACTIONS',
  SWITCH_ORGANIZATION_INIT: 'SWITCH_ORGANIZATION_INIT',
  SWITCH_ORGANIZATION_SUCCESS: 'SWITCH_ORGANIZATION_SUCCESS',
  FETCH_ORG_ROLES_INIT: 'FETCH_ORG_ROLES_INIT',
  FETCH_ORG_ROLES_SUCCESS: 'FETCH_ORG_ROLES_SUCCESS',
  FETCH_ORG_INIT: 'FETCH_ORG_INIT',
  FETCH_ORG_SUCCESS: 'FETCH_ORG_SUCCESS',
  FETCH_ORGS_SUCCESS: 'FETCH_ORGS_SUCCES',
  FETCH_ORGS_INIT: 'FETCH_ORGS_INIT',
  SAVE_ORG_INIT: 'SAVE_ORG_INIT',
  SAVE_ORG_SUCCESS: 'SAVE_ORG_SUCCESS',
  UPLOAD_ORG_LOGO: 'UPLOAD_ORG_LOGO',
  REMOVE_ORG_LOGO: 'REMOVE_ORG_LOGO',
  SAVING_ORG_INFO: 'SAVING_ORG_INFO',
  SET_LAST_UPDATED_TIME: 'SET_LAST_UPDATED_TIME',
  SET_CURRENT_ORG: 'SET_CURRENT_ORG',
  SET_CURRENT_ORG_ID: 'SET_CURRENT_ORG_ID',
  FETCH_CURRENT_ORG: 'FETCH_CURRENT_ORG',
  STORE_DATASOURCE_REFS: 'STORE_DATASOURCE_REFS',
  UPDATE_DATASOURCE_REFS: 'UPDATE_DATASOURCE_REFS',
  FETCH_USER_INIT: 'FETCH_USER_INIT',
  PROP_PANE_MOVED: 'PROP_PANE_MOVED',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  SET_CURRENT_USER_INIT: 'SET_CURRENT_USER_INIT',
  SET_CURRENT_USER_SUCCESS: 'SET_CURRENT_USER_SUCCESS',
  LOGOUT_USER_INIT: 'LOGOUT_USER_INIT',
  LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
  VERIFY_INVITE_INIT: 'VERIFY_INVITE_INIT',
  VERIFY_INVITE_SUCCESS: 'VERIFY_INVITE_SUCCESS',
  INVITED_USER_SIGNUP_SUCCESS: 'INVITED_USER_SIGNUP_SUCCESS',
  INVITED_USER_SIGNUP_INIT: 'INVITED_USER_SIGNUP_INIT',
  DISABLE_WIDGET_DRAG: 'DISABLE_WIDGET_DRAG',
  SELECT_WIDGET_INIT: 'SELECT_WIDGET_INIT',
  SHIFT_SELECT_WIDGET_INIT: 'SHIFT_SELECT_WIDGET_INIT',
  SELECT_WIDGET: 'SELECT_WIDGET',
  SELECT_MULTIPLE_WIDGETS: 'SELECT_MULTIPLE_WIDGETS',
  SELECT_MULTIPLE_WIDGETS_INIT: 'SELECT_MULTIPLE_WIDGETS_INIT',
  DESELECT_MULTIPLE_WIDGETS_INIT: 'DESELECT_MULTIPLE_WIDGETS_INIT',
  SELECT_ALL_WIDGETS_IN_CANVAS_INIT: 'SELECT_ALL_WIDGETS_IN_CANVAS_INIT',
  DESELECT_WIDGETS: 'DESELECT_WIDGETS',
  SELECT_WIDGETS: 'SELECT_WIDGETS',
  FOCUS_WIDGET: 'FOCUS_WIDGET',
  SET_WIDGET_DRAGGING: 'SET_WIDGET_DRAGGING',
  SET_DRAGGING_CANVAS: 'SET_DRAGGING_CANVAS',
  SET_NEW_WIDGET_DRAGGING: 'SET_NEW_WIDGET_DRAGGING',
  SET_WIDGET_RESIZING: 'SET_WIDGET_RESIZING',
  ADD_SUGGESTED_WIDGET: 'ADD_SUGGESTED_WIDGET',
  SEARCH_APPLICATIONS: 'SEARCH_APPLICATIONS',
  UPDATE_PAGE_INIT: 'UPDATE_PAGE_INIT',
  UPDATE_PAGE_SUCCESS: 'UPDATE_PAGE_SUCCESS',
  MOVE_ACTION_INIT: 'MOVE_ACTION_INIT',
  MOVE_ACTION_SUCCESS: 'MOVE_ACTION_SUCCESS',
  COPY_ACTION_INIT: 'COPY_ACTION_INIT',
  COPY_ACTION_SUCCESS: 'COPY_ACTION_SUCCESS',
  DELETE_APPLICATION_INIT: 'DELETE_APPLICATION_INIT',
  DELETE_APPLICATION_SUCCESS: 'DELETE_APPLICATION_SUCCESS',
  DUPLICATE_APPLICATION_INIT: 'DUPLICATE_APPLICATION_INIT',
  RELEASE_TEMPLATE_APPLICATION_INIT: 'RELEASE_TEMPLATE_APPLICATION_INIT',
  FETCH_TEMPLATE_APPLICATIONS: 'FETCH_TEMPLATE_APPLICATIONS',
  RELEASE_TEMPLATE_APPLICATION_SUCCESS: 'DELETE_APPLICATION_SUCCESS',
  DUPLICATE_APPLICATION_SUCCESS: 'DUPLICATE_APPLICATION_SUCCESS',
  DELETE_PAGE_INIT: 'DELETE_PAGE_INIT',
  DELETE_PAGE_SUCCESS: 'DELETE_PAGE_SUCCESS',
  CLONE_PAGE_INIT: 'CLONE_PAGE_INIT',
  CLONE_PAGE_SUCCESS: 'CLONE_PAGE_SUCCESS',
  SET_DEFAULT_APPLICATION_PAGE_INIT: 'SET_DEFAULT_APPLICATION_PAGE_INIT',
  SET_DEFAULT_APPLICATION_PAGE_SUCCESS: 'SET_DEFAULT_APPLICATION_PAGE_SUCCESS',
  SET_APPLICATION_PAGE_FULLSCREEN_INIT: 'SET_APPLICATION_PAGE_FULLSCREEN_INIT',
  SET_APPLICATION_PAGE_FULLSCREEN_SUCCESS:
    'SET_APPLICATION_PAGE_FULLSCREEN_SUCCESS',
  CREATE_ORGANIZATION_INIT: 'CREATE_ORGANIZATION_INIT',
  CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
  ADD_USER_TO_ORG_INIT: 'ADD_USER_TO_ORG_INIT',
  ADD_USER_TO_ORG_SUCCESS: 'ADD_USER_TO_ORG_ERROR',
  SET_META_PROP: 'SET_META_PROP',
  RESET_CHILDREN_WIDGET_META: 'RESET_CHILDREN_WIDGET_META',
  RESET_WIDGET_META: 'RESET_WIDGET_META',
  RESET_WIDGET_META_EVALUATED: 'RESET_WIDGET_META_EVALUATED',
  UPDATE_WIDGET_NAME_INIT: 'UPDATE_WIDGET_NAME_INIT',
  UPDATE_WIDGET_NAME_SUCCESS: 'UPDATE_WIDGET_NAME_SUCCESS',
  FETCH_ACTIONS_FOR_PAGE_INIT: 'FETCH_ACTIONS_FOR_PAGE_INIT',
  FETCH_ACTIONS_FOR_PAGE_SUCCESS: 'FETCH_ACTIONS_FOR_PAGE_SUCCESS',
  EXECUTE_PLUGIN_ACTION_REQUEST: 'EXECUTE_PLUGIN_ACTION_REQUEST',
  EXECUTE_PLUGIN_ACTION_SUCCESS: 'EXECUTE_PLUGIN_ACTION_SUCCESS',
  SET_ACTION_RESPONSE_DISPLAY_FORMAT: 'SET_ACTION_RESPONSE_DISPLAY_FORMAT',
  FETCH_IMPORTED_COLLECTIONS_INIT: 'FETCH_IMPORTED_COLLECTIONS_INIT',
  FETCH_IMPORTED_COLLECTIONS_SUCCESS: 'FETCH_IMPORTED_COLLECTIONS_SUCCESS',
  SEARCH_APIORPROVIDERS_INIT: 'SEARCH_APIORPROVIDERS_INIT',
  SEARCH_APIORPROVIDERS_SUCCESS: 'SEARCH_APIORPROVIDERS_SUCCESS',
  FETCH_PROVIDERS_INIT: 'FETCH_PROVIDERS_INIT',
  FETCH_PROVIDERS_SUCCESS: 'FETCH_PROVIDERS_SUCCESS',
  SUBMIT_CURL_FORM_INIT: 'SUBMIT_CURL_FORM_INIT',
  SUBMIT_CURL_FORM_SUCCESS: 'SUBMIT_CURL_FORM_SUCCESS',
  SET_WIDGET_DYNAMIC_PROPERTY: 'SET_WIDGET_DYNAMIC_PROPERTY',
  FETCH_PROVIDER_TEMPLATES_INIT: 'FETCH_PROVIDER_TEMPLATES_INIT',
  FETCH_PROVIDER_TEMPLATES_SUCCESS: 'FETCH_PROVIDER_TEMPLATES_SUCCESS',
  ADD_API_TO_PAGE_INIT: 'ADD_API_TO_PAGE_INIT',
  REFACTOR_ACTION_NAME: 'REFACTOR_ACTION_NAME',
  ADD_API_TO_PAGE_SUCCESS: 'ADD_API_TO_PAGE_SUCCESS',
  CLEAR_PREVIOUSLY_EXECUTED_QUERY: 'CLEAR_PREVIOUSLY_EXECUTED_QUERY',
  FETCH_PROVIDERS_CATEGORIES_INIT: 'FETCH_PROVIDERS_CATEGORIES_INIT',
  FETCH_PROVIDERS_CATEGORIES_SUCCESS: 'FETCH_PROVIDERS_CATEGORIES_SUCCESS',
  FETCH_PROVIDERS_WITH_CATEGORY_INIT: 'FETCH_PROVIDERS_WITH_CATEGORY_INIT',
  FETCH_PROVIDERS_WITH_CATEGORY_SUCCESS:
    'FETCH_PROVIDERS_WITH_CATEGORY_SUCCESS',
  SHOW_MODAL: 'SHOW_MODAL',
  SHOW_DATASOURCE_MODAL: 'SHOW_DATASOURCE_MODAL',
  SHOW_MODAL_BY_NAME: 'SHOW_MODAL_BY_NAME',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CREATE_MODAL_INIT: 'CREATE_MODAL_INIT',
  CREATE_MODAL_SUCCESS: 'CREATE_MODAL_SUCCESS',
  UPDATE_CANVAS_SIZE: 'UPDATE_CANVAS_SIZE',
  SWITCH_CURRENT_PAGE_ID: 'SWITCH_CURRENT_PAGE_ID',
  CLEAR_PROVIDERS: 'CLEAR_PROVIDERS',
  BATCHED_UPDATE: 'BATCHED_UPDATE',
  EXECUTE_BATCH: 'EXECUTE_BATCH',
  FETCH_ALL_PUBLISHED_PAGES: 'FETCH_ALL_PUBLISHED_PAGES',
  CREATE_NEW_API_ACTION: 'CREATE_NEW_API_ACTION',
  CREATE_NEW_QUERY_ACTION: 'CREATE_NEW_QUERY_ACTION',
  SET_CURRENT_CATEGORY: 'SET_CURRENT_CATEGORY',
  SET_LAST_USED_EDITOR_PAGE: 'SET_LAST_USED_EDITOR_PAGE',
  SET_LAST_SELECTED_PAGE_PAGE: 'SET_LAST_SELECTED_PAGE_PAGE',
  FETCH_PROVIDER_DETAILS_BY_PROVIDER_ID_INIT:
    'FETCH_PROVIDER_DETAILS_BY_PROVIDER_ID_INIT',
  FETCH_PROVIDER_DETAILS_BY_PROVIDER_ID_SUCCESS:
    'FETCH_PROVIDER_DETAILS_BY_PROVIDER_ID_SUCCESS',
  SET_PROVIDERS_LENGTH: 'SET_PROVIDERS_LENGTH',
  GET_ALL_APPLICATION_INIT: 'GET_ALL_APPLICATION_INIT',
  FETCH_USER_APPLICATIONS_ORGS_SUCCESS: 'FETCH_USER_APPLICATIONS_ORGS_SUCCESS',
  FETCH_USER_DETAILS_SUCCESS: 'FETCH_USER_DETAILS_SUCCESS',
  FETCH_ALL_USERS_SUCCESS: 'FETCH_ALL_USERS_SUCCESS',
  FETCH_ALL_USERS_INIT: 'FETCH_ALL_USERS_INIT',
  FETCH_ALL_ROLES_SUCCESS: 'FETCH_ALL_ROLES_SUCCESS',
  FETCH_ALL_ROLES_INIT: 'FETCH_ALL_ROLES_INIT',
  DELETE_ORG_USER_INIT: 'DELETE_ORG_USER_INIT',
  DELETE_ORG_USER_SUCCESS: 'DELETE_ORG_USER_SUCCESS',
  LEAVE_ORG_INIT: 'LEAVE_ORG_INIT',
  CHANGE_ORG_USER_ROLE_INIT: 'CHANGE_ORG_USER_ROLE_INIT',
  CHANGE_ORG_USER_ROLE_SUCCESS: 'CHANGE_ORG_USER_ROLE_SUCCESS',
  UPDATE_USER_DETAILS_INIT: 'UPDATE_USER_DETAILS_INIT',
  UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
  SET_DEFAULT_REFINEMENT: 'SET_DEFAULT_REFINEMENT',
  SET_HELP_MODAL_OPEN: 'SET_HELP_MODAL_OPEN',
  SAVE_ACTION_NAME_INIT: 'SAVE_ACTION_NAME_INIT',
  SAVE_EDIT_ACTION_NAME_INIT: 'SAVE_EDIT_ACTION_NAME_INIT',
  SAVE_ACTION_NAME_SUCCESS: 'SAVE_ACTION_NAME_SUCCESS',
  UPDATE_API_NAME_DRAFT: 'UPDATE_API_NAME_DRAFT',
  SET_ACTION_PROPERTY: 'SET_ACTION_PROPERTY',
  UPDATE_ACTION_PROPERTY: 'UPDATE_ACTION_PROPERTY',
  SWITCH_DATASOURCE: 'SWITCH_DATASOURCE',
  INIT_EXPLORER_ENTITY_NAME_EDIT: 'INIT_EXPLORER_ENTITY_NAME_EDIT',
  FETCH_ACTIONS_VIEW_MODE_INIT: 'FETCH_ACTIONS_VIEW_MODE_INIT',
  FETCH_ACTIONS_VIEW_MODE_SUCCESS: 'FETCH_ACTIONS_VIEW_MODE_SUCCESS',
  END_EXPLORER_ENTITY_NAME_EDIT: 'END_EXPLORER_ENTITY_NAME_EDIT',
  POPULATE_PAGEDSLS_INIT: 'POPULATE_PAGEDSLS_INIT',
  POPULATE_PAGEDSLS_SUCCESS: 'POPULATE_PAGEDSLS_SUCCESS',
  FETCH_PAGE_DSLS_SUCCESS: 'FETCH_PAGE_DSLS_SUCCESS',
  FETCH_PAGE_DSL_SUCCESS: 'FETCH_PAGE_DSL_SUCCESS',
  SET_URL_DATA: 'SET_URL_DATA',
  SET_APP_MODE: 'SET_APP_MODE',
  TOGGLE_PROPERTY_PANE_PINNED_STATUS: 'TOGGLE_PROPERTY_PANE_PINNED_STATUS',
  TOGGLE_PROPERTY_PANE_WIDGET_NAME_EDIT:
    'TOGGLE_PROPERTY_PANE_WIDGET_NAME_EDIT',
  UPDATE_APP_PERSISTENT_STORE: 'UPDATE_APP_PERSISTENT_STORE',
  UPDATE_APP_TRANSIENT_STORE: 'UPDATE_APP_TRANSIENT_STORE',
  UPDATE_APP_STORE_EVALUATED: 'UPDATE_APP_STORE_EVALUATED',
  SET_ACTION_TO_EXECUTE_ON_PAGELOAD: 'SET_ACTION_TO_EXECUTE_ON_PAGELOAD',
  TOGGLE_ACTION_EXECUTE_ON_LOAD_SUCCESS:
    'TOGGLE_ACTION_EXECUTE_ON_LOAD_SUCCESS',
  TOGGLE_ACTION_EXECUTE_ON_LOAD_INIT: 'TOGGLE_ACTION_EXECUTE_ON_LOAD_INIT',
  COPY_SELECTED_WIDGET_SUCCESS: 'COPY_SELECTED_WIDGET_SUCCESS',
  COPY_SELECTED_WIDGET_INIT: 'COPY_SELECTED_WIDGET_INIT',
  ADD_CANVAS_WIDGET: 'ADD_CANVAS_WIDGET',
  PASTE_COPIED_WIDGET_INIT: 'PASTE_COPIED_WIDGET_INIT',
  PASTE_COPIED_WIDGET_SUCCESS: 'PASTE_COPIED_WIDGET_SUCCESS',
  CUT_SELECTED_WIDGET: 'CUT_SELECTED_WIDGET',
  SET_EVALUATED_TREE: 'SET_EVALUATED_TREE',
  SET_EVALUATION_INVERSE_DEPENDENCY_MAP:
    'SET_EVALUATION_INVERSE_DEPENDENCY_MAP',
  BATCH_UPDATES_SUCCESS: 'BATCH_UPDATES_SUCCESS',
  UPDATE_CANVAS_STRUCTURE: 'UPDATE_CANVAS_STRUCTURE',
  SET_SELECTED_WIDGET_ANCESTORY: 'SET_SELECTED_WIDGET_ANCESTORY',
  START_EVALUATION: 'START_EVALUATION',
  CURRENT_APPLICATION_NAME_UPDATE: 'CURRENT_APPLICATION_NAME_UPDATE',
  CURRENT_APPLICATION_LAYOUT_UPDATE: 'CURRENT_APPLICATION_LAYOUT_UPDATE',
  FORK_APPLICATION_INIT: 'FORK_APPLICATION_INIT',
  FORK_APPLICATION_SUCCESS: 'FORK_APPLICATION_SUCCESS',
  IMPORT_APPLICATION_INIT: 'IMPORT_APPLICATION_INIT',
  IMPORT_APPLICATION_FROM_GIT_INIT: 'IMPORT_APPLICATION_FROM_GIT_INIT',
  IMPORT_APPLICATION_SUCCESS: 'IMPORT_APPLICATION_SUCCESS',
  SET_WIDGET_LOADING: 'SET_WIDGET_LOADING',
  SET_GLOBAL_SEARCH_QUERY: 'SET_GLOBAL_SEARCH_QUERY',
  TOGGLE_SHOW_GLOBAL_SEARCH_MODAL: 'TOGGLE_SHOW_GLOBAL_SEARCH_MODAL',
  SHOW_EDIT_QUERY_DRAWER: 'SHOW_EDIT_QUERY_DRAWER',
  /**
   * Configurations of app
   */
  SHOW_APP_SYS_CONFIG_DRAWER: 'SHOW_APP_SYS_CONFIG_DRAWER',
  SET_APP_SYS_CONFIG_PAGE: 'SET_APP_SYS_CONFIG_PAGE',
  SHOW_EDIT_QUERY_DRAWER_CREATE: 'SHOW_EDIT_QUERY_DRAWER_CREATE',
  TOGGLE_EDIT_QUERY_NAME_EDITOR: 'TOGGLE_EDIT_QUERY_NAME_EDITOR',
  SHOW_CREATE_QUERY: 'SHOW_CREATE_QUERY',
  MODIFY_QUERY_DRAWER_TYPE: 'MODIFY_QUERY_DRAWER_TYPE',
  SET_QUERY_DRAWER_WRAPPER_REF: 'SET_QUERY_DRAWER_WRAPPER_REF',
  CREATE_TEMPLETE_QUERY_PARAMS: 'CREATE_TEMPLETE_QUERY_PARAMS',
  CREATE_TEMPLETE_QUERY_ID: 'CREATE_TEMPLETE_QUERY_ID',
  GRAPH_VISIBLE: 'GRAPH_VISIBLE',
  FETCH_RELEASES_SUCCESS: 'FETCH_RELEASES_SUCCESS',
  RESET_UNREAD_RELEASES_COUNT: 'RESET_UNREAD_RELEASES_COUNT',
  SET_FORM_EVALUATION: 'SET_FORM_EVALUATION',
  INIT_FORM_EVALUATION: 'INIT_FORM_EVALUATION',
  RUN_FORM_EVALUATION: 'RUN_FORM_EVALUATION',
  SET_LOADING_ENTITIES: 'SET_LOADING_ENTITIES',
  RESET_CURRENT_APPLICATION: 'RESET_CURRENT_APPLICATION',
  SELECT_WIDGETS_IN_AREA: 'SELECT_WIDGETS_IN_AREA',
  RESET_APPLICATION_WIDGET_STATE_REQUEST:
    'RESET_APPLICATION_WIDGET_STATE_REQUEST',
  SAAS_GET_OAUTH_ACCESS_TOKEN: 'SAAS_GET_OAUTH_ACCESS_TOKEN',
  GET_OAUTH_ACCESS_TOKEN: 'GET_OAUTH_ACCESS_TOKEN',
  UPDATE_RECENT_ENTITY: 'UPDATE_RECENT_ENTITY',
  RESTORE_RECENT_ENTITIES_REQUEST: 'RESTORE_RECENT_ENTITIES_REQUEST',
  RESTORE_RECENT_ENTITIES_SUCCESS: 'RESTORE_RECENT_ENTITIES_SUCCESS',
  SET_RECENT_ENTITIES: 'SET_RECENT_ENTITIES',
  RESET_RECENT_ENTITIES: 'RESET_RECENT_ENTITIES',
  UPDATE_API_ACTION_BODY_CONTENT_TYPE: 'UPDATE_API_ACTION_BODY_CONTENT_TYPE',
  ADD_WIDGET_CONFIG: 'ADD_WIDGET_CONFIG',
  ADD_PDF_PRINT_INFO: 'ADD_PDF_PRINT_INFO',
  GENERATE_TEMPLATE_PAGE_INIT: 'GENERATE_TEMPLATE_PAGE_INIT',
  GENERATE_TEMPLATE_PAGE_SUCCESS: 'GENERATE_TEMPLATE_PAGE_SUCCESS',
  SHOW_TABLE_FILTER_PANE: 'SHOW_TABLE_FILTER_PANE',
  HIDE_TABLE_FILTER_PANE: 'HIDE_TABLE_FILTER_PANE',
  TABLE_PANE_MOVED: 'TABLE_PANE_MOVED',
  FETCH_JS_ACTIONS_INIT: 'FETCH_JS_ACTIONS_INIT',
  FETCH_JS_ACTIONS_SUCCESS: 'FETCH_JS_ACTIONS_SUCCESS',
  CREATE_NEW_JS_ACTION: 'CREATE_NEW_JS_ACTION',
  CREATE_JS_ACTION_INIT: 'CREATE_JS_ACTION_INIT',
  CREATE_JS_ACTION_SUCCESS: 'CREATE_JS_ACTION_SUCCESS',
  COPY_JS_ACTION_INIT: 'COPY_JS_ACTION_INIT',
  COPY_JS_ACTION_SUCCESS: 'COPY_JS_ACTION_SUCCESS',
  MOVE_JS_ACTION_INIT: 'MOVE_JS_ACTION_INIT',
  MOVE_JS_ACTION_SUCCESS: 'MOVE_JS_ACTION_SUCCESS',
  DELETE_JS_ACTION_INIT: 'DELETE_JS_ACTION_INIT',
  DELETE_JS_ACTION_SUCCESS: 'DELETE_JS_ACTION_SUCCESS',
  PARSE_UPDATE_JS_ACTION: 'PARSE_UPDATE_JS_ACTION',
  UPDATE_JS_ACTION_INIT: 'UPDATE_JS_ACTION_INIT',
  UPDATE_JS_ACTION_SUCCESS: 'UPDATE_JS_ACTION_SUCCESS',
  EXECUTE_COMMAND: 'EXECUTE_COMMAND',
  SAVE_JS_COLLECTION_NAME_INIT: 'SAVE_JS_COLLECTION_NAME_INIT',
  FETCH_JS_ACTIONS_FOR_PAGE_INIT: 'FETCH_JS_ACTIONS_FOR_PAGE_INIT',
  FETCH_JS_ACTIONS_FOR_PAGE_SUCCESS: 'FETCH_JS_ACTIONS_FOR_PAGE_SUCCESS',
  SAVE_JS_COLLECTION_NAME_SUCCESS: 'SAVE_JS_COLLECTION_NAME_SUCCESS',
  FETCH_JS_ACTIONS_VIEW_MODE_INIT: 'FETCH_JS_ACTIONS_VIEW_MODE_INIT',
  FETCH_JS_ACTIONS_VIEW_MODE_SUCCESS: 'FETCH_JS_ACTIONS_VIEW_MODE_SUCCESS',
  EXECUTE_JS_FUNCTION_INIT: 'EXECUTE_JS_FUNCTION_INIT',
  EXECUTE_JS_FUNCTION_SUCCESS: 'EXECUTE_JS_FUNCTION_SUCCESS',
  GET_PLUGIN_FORM_CONFIG_INIT: 'GET_PLUGIN_FORM_CONFIG_INIT',
  EXECUTE_DATASOURCE_QUERY_INIT: 'EXECUTE_DATASOURCE_QUERY_INIT',
  EXECUTE_DATASOURCE_QUERY_SUCCESS: 'EXECUTE_DATASOURCE_QUERY_SUCCESS',
  UNDO_REDO_OPERATION: 'UNDO_REDO_OPERATION',
  EVALUATE_SNIPPET: 'EVALUATE_SNIPPET',
  EVALUATE_ARGUMENT: 'EVALUATE_ARGUMENT',
  SET_EVALUATED_SNIPPET: 'SET_EVALUATED_SNIPPET',
  SET_EVALUATED_ARGUMENT: 'SET_EVALUATED_ARGUMENT',
  UNSET_EVALUATED_ARGUMENT: 'UNSET_EVALUATED_ARGUMENT',
  SET_CRUD_INFO_MODAL_OPEN: 'SET_CRUD_INFO_MODAL_OPEN',
  SET_PAGE_ORDER_INIT: 'SET_PAGE_ORDER_INIT',
  SET_PAGE_ORDER_SUCCESS: 'SET_PAGE_ORDER_SUCCESS',
  SET_PREV_PAGE_AFTER_GO_PRINT: 'SET_PREV_PAGE_AFTER_GO_PRINT',
  GROUP_WIDGETS_INIT: 'GROUP_WIDGETS_INIT',
  WIDGET_ADD_NEW_TAB_CHILD: 'WIDGET_ADD_NEW_TAB_CHILD',
  WIDGET_DYNAMIC_TABS_FROM_SOUCE: 'WIDGET_DYNAMIC_TABS_FROM_SOUCE',
  WIDGET_DELETE_TAB_CHILD: 'WIDGET_DELETE_TAB_CHILD',
  GENERATE_SSH_KEY_PAIR_INIT: 'GENERATE_SSH_KEY_PAIR_INIT',
  SET_REMOTE_URL_INPUT_VALUE: 'SET_REMOTE_URL_INPUT_VALUE',
  GENERATE_SSH_KEY_PAIR_SUCCESS: 'GENERATE_SSH_KEY_PAIR_SUCCESS',
  REFACTOR_JS_ACTION_NAME: 'REFACTOR_JS_ACTION_NAME',
  REFACTOR_JS_ACTION_NAME_SUCCESS: 'REFACTOR_JS_ACTION_NAME_SUCCESS',
  SET_HEADER_META: 'SET_HEADER_META',
  TOGGLE_ONBOARDING_WIDGET_SELECTION: 'TOGGLE_ONBOARDING_WIDGET_SELECTION',
  SET_ENABLE_FIRST_TIME_USER_ONBOARDING:
    'SET_ENABLE_FIRST_TIME_USER_ONBOARDING',
  SET_FIRST_TIME_USER_ONBOARDING_APPLICATION_ID:
    'SET_FIRST_TIME_USER_ONBOARDING_APPLICATION_ID',
  SET_FIRST_TIME_USER_ONBOARDING_COMPLETE:
    'SET_FIRST_TIME_USER_ONBOARDING_COMPLETE',
  SET_SHOW_FIRST_TIME_USER_ONBOARDING_MODAL:
    'SET_SHOW_FIRST_TIME_USER_ONBOARDING_MODAL',
  SET_CURRENT_PAGE_PANELSTATE: 'SET_CURRENT_PAGE_PANELSTATE',
  SET_FORCE_WIDGET_PANEL_OPEN: 'SET_FORCE_WIDGET_PANEL_OPEN',
  END_FIRST_TIME_USER_ONBOARDING: 'END_FIRST_TIME_USER_ONBOARDING',
  UNDO_END_FIRST_TIME_USER_ONBOARDING: 'UNDO_END_FIRST_TIME_USER_ONBOARDING',
  FETCH_ADMIN_SETTINGS: 'FETCH_ADMIN_SETTINGS',
  FETCH_ADMIN_SETTINGS_SUCCESS: 'FETCH_ADMIN_SETTINGS_SUCCESS',
  FETCH_ADMIN_SETTINGS_ERROR: 'FETCH_ADMIN_SETTINGS_ERROR',
  SAVE_ADMIN_SETTINGS: 'SAVE_ADMIN_SETTINGS',
  SAVE_ADMIN_SETTINGS_SUCCESS: 'SAVE_ADMIN_SETTINGS_SUCCESS',
  SAVE_ADMIN_SETTINGS_ERROR: 'SAVE_ADMIN_SETTINGS_ERROR',
  DOWNLOAD_DOCKER_COMPOSE_FILE: 'DOWNLOAD_DOCKER_COMPOSE_FILE',
  TOGGLE_RELEASE_NOTES: 'TOGGLE_RELEASE_NOTES',
  FETCH_RELEASES: 'FETCH_RELEASES',
  RESTART_SERVER_POLL: 'RESTART_SERVER_POLL',
  RETRY_RESTART_SERVER_POLL: 'RETRY_RESTART_SERVER_POLL',
  SET_EXPLORER_PINNED: 'SET_EXPLORER_PINNED',
  SET_EXPLORER_ACTIVE: 'SET_EXPLORER_ACTIVE',
  SET_PREVIEW_MODE: 'SET_PREVIEW_MODE',
  UPDATE_EXPLORER_WIDTH: 'UPDATE_EXPLORER_WIDTH',
  FIRST_TIME_USER_ONBOARDING_INIT: 'FIRST_TIME_USER_ONBOARDING_INIT',
  SET_USER_ROLE_USECASE: 'SET_USER_ROLE_USECASE',
  UPDATE_JS_ACTION_BODY: 'UPDATE_JS_ACTION_BODY',
  UPDATE_JS_ACTION_BODY_INIT: 'UPDATE_JS_ACTION_BODY_INIT',
  UPDATE_JS_ACTION_BODY_SUCCESS: 'UPDATE_JS_ACTION_BODY_SUCCESS',
  SEND_TEST_EMAIL: 'SEND_TEST_EMAIL',
  SET_FUNCTION_PROPERTY: 'SET_FUNCTION_PROPERTY',
  UPDATE_JS_FUNCTION_PROPERTY_INIT: 'UPDATE_JS_FUNCTION_PROPERTY_INIT',
  UPDATE_JS_FUNCTION_PROPERTY_SUCCESS: 'UPDATE_JS_FUNCTION_PROPERTY_SUCCESS',
  TOGGLE_FUNCTION_EXECUTE_ON_LOAD_INIT: 'TOGGLE_FUNCTION_EXECUTE_ON_LOAD_INIT',
  TOGGLE_FUNCTION_EXECUTE_ON_LOAD_SUCCESS:
    'TOGGLE_FUNCTION_EXECUTE_ON_LOAD_SUCCESS',
  SET_JS_ACTION_TO_EXECUTE_ON_PAGELOAD: 'SET_JS_ACTION_TO_EXECUTE_ON_PAGELOAD',
  ENABLE_GUIDED_TOUR: 'ENABLE_GUIDED_TOUR',
  GUIDED_TOUR_MARK_STEP_COMPLETED: 'GUIDED_TOUR_MARK_STEP_COMPLETED',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_CURRENT_STEP_INIT: 'SET_CURRENT_STEP_INIT',
  GUIDED_TOUR_ADD_WIDGET: 'GUIDED_TOUR_ADD_WIDGET',
  TABLE_WIDGET_WAS_SELECTED: 'TABLE_WIDGET_WAS_SELECTED',
  SET_UP_TOUR_APP: 'SET_UP_TOUR_APP',
  GUIDED_TOUR_TOGGLE_LOADER: 'GUIDED_TOUR_TOGGLE_LOADER',
  TOGGLE_DEVIATION_DIALOG: 'TOGGLE_DEVIATION_DIALOG',
  TOGGLE_END_GUIDED_TOUR_DIALOG: 'TOGGLE_END_GUIDED_TOUR_DIALOG',
  SHOW_POST_COMPLETION_MESSAGE: 'SHOW_POST_COMPLETION_MESSAGE',
  GUIDED_TOUR_FOCUS_WIDGET: 'GUIDED_TOUR_FOCUS_WIDGET',
  GUIDED_TOUR_SET_DATASOURCE_ID: 'GUIDED_TOUR_SET_DATASOURCE_ID',
  FOCUS_WIDGET_PROPERTY: 'FOCUS_WIDGET_PROPERTY',
  SHOW_INFO_MESSAGE: 'SHOW_INFO_MESSAGE',
  FORCE_SHOW_CONTENT: 'FORCE_SHOW_CONTENT',
  UPDATE_BUTTON_WIDGET_TEXT: 'UPDATE_BUTTON_WIDGET_TEXT',
  UPDATE_REPLAY_ENTITY: 'UPDATE_REPLAY_ENTITY',
  DELETE_ORG_INIT: 'DELETE_ORG_INIT',
  DELETE_ORG_SUCCESS: 'DELETE_ORG_SUCCESS',
  SET_USER_CURRENT_GEO_LOCATION: 'SET_USER_CURRENT_GEO_LOCATION',
  SET_DISCONNECTING_GIT_APPLICATION: 'SET_DISCONNECTING_GIT_APPLICATION',
  GET_ALL_TEMPLATES_INIT: 'GET_ALL_TEMPLATES_INIT',
  GET_ALL_TEMPLATES_SUCCESS: 'GET_ALL_TEMPLATES_SUCCESS',
  UPDATE_TEMPLATE_FILTERS: 'UPDATE_TEMPLATE_FILTERS',
  SET_TEMPLATE_SEARCH_QUERY: 'SET_TEMPLATE_SEARCH_QUERY',
  IMPORT_TEMPLATE_TO_ORGANISATION_INIT: 'IMPORT_TEMPLATE_TO_ORGANISATION_INIT',
  IMPORT_TEMPLATE_TO_ORGANISATION_SUCCESS:
    'IMPORT_TEMPLATE_TO_ORGANISATION_SUCCESS',
  SET_TEMPLATE_NOTIFICATION_SEEN: 'SET_TEMPLATE_NOTIFICATION_SEEN',
  GET_TEMPLATE_NOTIFICATION_SEEN: 'GET_TEMPLATE_NOTIFICATION_SEEN',
  GET_SIMILAR_TEMPLATES_INIT: 'GET_SIMILAR_TEMPLATES_INIT',
  GET_SIMILAR_TEMPLATES_SUCCESS:
    'GET_SIMILAR_TEMPLATES_SUCCESS' /* This action constants is for identifying the status of the updates of the entities */,
  ENTITY_UPDATE_STARTED: 'ENTITY_UPDATE_STARTED',
  ENTITY_UPDATE_SUCCESS: 'ENTITY_UPDATE_SUCCESS',
  FETCH_PLUGIN_AND_JS_ACTIONS_SUCCESS: 'FETCH_PLUGIN_AND_JS_ACTIONS_SUCCESS',
  GET_DEFAULT_PLUGINS_REQUEST: 'GET_DEFAULT_PLUGINS_REQUEST',
  GET_DEFAULT_PLUGINS_SUCCESS: 'GET_DEFAULT_PLUGINS_SUCCESS',
  GET_TEMPLATE_INIT: 'GET_TEMPLATES_INIT',
  GET_TEMPLATE_SUCCESS: 'GET_TEMPLATES_SUCCESS',
  START_EXECUTE_JS_FUNCTION: 'START_EXECUTE_JS_FUNCTION',
  CURRENT_APPLICATION_THEME_UPDATE: 'CURRENT_APPLICATION_THEME_UPDATE',
};

export type ReduxActionType = typeof ReduxActionTypes[keyof typeof ReduxActionTypes];

export const ReduxActionErrorTypes = {
  GIT_PULL_ERROR: 'GIT_PULL_ERROR',
  FETCH_MERGE_STATUS_ERROR: 'FETCH_MERGE_STATUS_ERROR',
  MERGE_BRANCH_ERROR: 'MERGE_BRANCH_ERROR',
  FETCH_GIT_STATUS_ERROR: 'FETCH_GIT_STATUS_ERROR',
  CREATE_NEW_BRANCH_ERROR: 'CREATE_NEW_BRANCH_ERROR',
  CHECKOUT_BRANCH_ERROR: 'CHECKOUT_BRANCH_ERROR',
  FETCH_BRANCHES_ERROR: 'FETCH_BRANCHES_ERROR',
  FETCH_LOCAL_GIT_CONFIG_ERROR: 'FETCH_LOCAL_GIT_CONFIG_ERROR',
  UPDATE_LOCAL_GIT_CONFIG_ERROR: 'UPDATE_LOCAL_GIT_CONFIG_ERROR',
  PUSH_TO_GIT_ERROR: 'PUSH_TO_GIT_ERROR',
  FETCH_SSH_KEY_PAIR_ERROR: 'FETCH_SSH_KEY_PAIR_ERROR',
  UPDATE_GLOBAL_GIT_CONFIG_ERROR: 'UPDATE_GLOBAL_GIT_CONFIG_ERROR',
  FETCH_GLOBAL_GIT_CONFIG_ERROR: 'FETCH_GLOBAL_GIT_CONFIG_ERROR',
  CONNECT_TO_GIT_ERROR: 'CONNECT_TO_GIT_ERROR',
  DISCONNECT_TO_GIT_ERROR: 'DISCONNECT_TO_GIT_ERROR',
  COMMIT_TO_GIT_REPO_ERROR: 'COMMIT_TO_GIT_REPO_ERROR',
  FETCH_FEATURE_FLAGS_ERROR: 'FETCH_FEATURE_FLAGS_ERROR',
  FETCH_NOTIFICATIONS_ERROR: 'FETCH_NOTIFICATIONS_ERROR',
  MARK_ALL_NOTIFICAIONS_AS_READ_ERROR: 'MARK_ALL_NOTIFICAIONS_AS_READ_ERROR',
  FETCH_UNREAD_NOTIFICATIONS_COUNT_ERROR:
    'FETCH_UNREAD_NOTIFICATIONS_COUNT_ERROR',
  MARK_NOTIFICATION_AS_READ_ERROR: 'MARK_NOTIFICATION_AS_READ_ERROR',
  CREATE_COMMENT_THREAD_ERROR: 'CREATE_COMMENT_THREAD_ERROR',
  ADD_COMMENT_TO_THREAD_ERROR: 'ADD_COMMENT_TO_THREAD_ERROR',
  FETCH_APPLICATION_COMMENTS_ERROR: 'FETCH_APPLICATION_COMMENTS_ERROR',
  SET_COMMENT_RESOLUTION_ERROR: 'SET_COMMENT_RESOLUTION_ERROR',
  PIN_COMMENT_THREAD_ERROR: 'PIN_COMMENT_THREAD_ERROR',
  DELETE_COMMENT_ERROR: 'DELETE_COMMENT_ERROR',
  MARK_THREAD_AS_READ_ERROR: 'MARK_THREAD_AS_READ_ERROR',
  EDIT_COMMENT_ERROR: 'EDIT_COMMENT_ERROR',
  DELETE_COMMENT_THREAD_ERROR: 'DELETE_COMMENT_THREAD_ERROR',
  ADD_COMMENT_REACTION_ERROR: 'ADD_COMMENT_REACTION_ERROR',
  DELETE_COMMENT_REACTION_ERROR: 'DELETE_COMMENT_REACTION_ERROR',
  INITIALIZE_APPSMITH_ERROR: 'INITIALIZE_APPSMITH_ERROR',
  API_ERROR: 'API_ERROR',
  WIDGET_DELETE_ERROR: 'WIDGET_DELETE_ERROR',
  UPDATE_APPLICATION_ERROR: 'UPDATE_APPLICATION_ERROR',
  UPDATE_APP_LAYOUT_ERROR: 'UPDATE_APP_LAYOUT_ERROR',
  WIDGET_MOVE_ERROR: 'WIDGET_MOVE_ERROR',
  WIDGET_RESIZE_ERROR: 'WIDGET_RESIZE_ERROR',
  WIDGET_REMOVE_CHILD_ERROR: 'WIDGET_REMOVE_CHILD_ERROR',
  WIDGET_ADD_CHILD_ERROR: 'WIDGET_ADD_CHILD_ERROR',
  FETCH_PAGE_ERROR: 'FETCH_PAGE_ERROR',
  SAVE_PAGE_ERROR: 'SAVE_PAGE_ERROR',
  FETCH_WIDGET_CARDS_ERROR: 'FETCH_WIDGET_CARDS_ERROR',
  WIDGET_OPERATION_ERROR: 'WIDGET_OPERATION_ERROR',
  WIDGET_SELECTION_ERROR: 'WIDGET_SELECTION_ERROR',
  FETCH_PROPERTY_PANE_CONFIGS_ERROR: 'FETCH_PROPERTY_PANE_CONFIGS_ERROR',
  FETCH_CONFIGS_ERROR: 'FETCH_CONFIGS_ERROR',
  PROPERTY_PANE_ERROR: 'PROPERTY_PANE_ERROR',
  FETCH_ACTIONS_ERROR: 'FETCH_ACTIONS_ERROR',
  FETCH_JS_ACTIONS_ERROR: 'FETCH_JS_ACTIONS_ERROR',
  UPDATE_WIDGET_PROPERTY_ERROR: 'UPDATE_WIDGET_PROPERTY_ERROR',
  CREATE_ACTION_ERROR: 'CREATE_ACTION_ERROR',
  UPDATE_ACTION_ERROR: 'UPDATE_ACTION_ERROR',
  DELETE_ACTION_ERROR: 'DELETE_ACTION_ERROR',
  RUN_ACTION_ERROR: 'RUN_ACTION_ERROR',
  EXECUTE_PLUGIN_ACTION_ERROR: 'EXECUTE_PLUGIN_ACTION_ERROR',
  FETCH_DATASOURCES_ERROR: 'FETCH_DATASOURCES_ERROR',
  FETCH_MOCK_DATASOURCES_ERROR: 'FETCH_MOCK_DATASOURCES_ERROR',
  ADD_MOCK_DATASOURCES_ERROR: 'ADD_MOCK_DATASOURCES_ERROR',
  SEARCH_APIORPROVIDERS_ERROR: 'SEARCH_APIORPROVIDERS_ERROR',
  UPDATE_DATASOURCE_ERROR: 'UPDATE_DATASOURCE_ERROR',
  SAVE_DATASOURCE_NAME_ERROR: 'SAVE_DATASOURCE_NAME_ERROR',
  CREATE_DATASOURCE_ERROR: 'CREATE_DATASOURCE_ERROR',
  DELETE_DATASOURCE_ERROR: 'DELETE_DATASOURCE_ERROR',
  FETCH_DATASOURCE_STRUCTURE_ERROR: 'FETCH_DATASOURCE_STRUCTURE_ERROR',
  REFRESH_DATASOURCE_STRUCTURE_ERROR: 'REFRESH_DATASOURCE_STRUCTURE_ERROR',
  CREATE_ONBOARDING_DBQUERY_ERROR: 'CREATE_ONBOARDING_DBQUERY_ERROR',
  FETCH_PUBLISHED_PAGE_ERROR: 'FETCH_PUBLISHED_PAGE_ERROR',
  PUBLISH_APPLICATION_ERROR: 'PUBLISH_APPLICATION_ERROR',
  FETCH_USER_DETAILS_ERROR: 'FETCH_USER_DETAILS_ERROR',
  CREATE_PAGE_ERROR: 'CREATE_PAGE_ERROR',
  FETCH_PAGE_LIST_ERROR: 'FETCH_PAGE_LIST_ERROR',
  FETCH_APPLICATION_ERROR: 'FETCH_APPLICATION_ERROR',
  CREATE_APPLICATION_ERROR: 'CREATE_APPLICATION_ERROR',
  LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  RESET_USER_PASSWORD_ERROR: 'RESET_USER_PASSWORD_ERROR',
  CHANGE_ORG_USER_ROLE_ERROR: 'CHANGE_ORG_USER_ROLE_ERROR',
  SAVE_JS_EXECUTION_RECORD: 'SAVE_JS_EXECUTION_RECORD',
  FETCH_PLUGINS_ERROR: 'FETCH_PLUGINS_ERROR',
  FETCH_PLUGIN_FORM_CONFIGS_ERROR: 'FETCH_PLUGIN_FORM_CONFIGS_ERROR',
  UPDATE_ORG_NAME_ERROR: 'UPDATE_ORG_NAME_ERROR',
  SWITCH_ORGANIZATION_ERROR: 'SWITCH_ORGANIZATION_ERROR',
  TEST_DATASOURCE_ERROR: 'TEST_DATASOURCE_ERROR',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  RESET_PASSWORD_VERIFY_TOKEN_ERROR: 'RESET_PASSWORD_VERIFY_TOKEN_ERROR',
  FETCH_ORG_ROLES_ERROR: 'FETCH_ORG_ROLES_ERROR',
  INVITE_USERS_TO_ORG_ERROR: 'INVITE_USERS_TO_ORG_ERROR',
  SAVE_ORG_ERROR: 'SAVE_ORG_ERROR',
  FETCH_ORG_ERROR: 'FETCH_ORG_ERROR',
  FETCH_ORGS_ERROR: 'FETCH_ORGS_ERROR',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  SET_CURRENT_USER_ERROR: 'SET_CURRENT_USER_ERROR',
  LOGOUT_USER_ERROR: 'LOGOUT_USER_ERROR',
  VERIFY_INVITE_ERROR: 'VERIFY_INVITE_ERROR',
  UPDATE_PAGE_ERROR: 'UPDATE_PAGE_ERROR',
  MOVE_ACTION_ERROR: 'MOVE_ACTION_ERROR',
  COPY_ACTION_ERROR: 'COPY_ACTION_ERROR',
  DELETE_PAGE_ERROR: 'DELETE_PAGE_ERROR',
  CLONE_PAGE_ERROR: 'CLONE_PAGE_ERROR',
  DELETE_APPLICATION_ERROR: 'DELETE_APPLICATION_ERROR',
  DUPLICATE_APPLICATION_ERROR: 'DUPLICATE_APPLICATION_ERROR',
  SET_DEFAULT_APPLICATION_PAGE_ERROR: 'SET_DEFAULT_APPLICATION_PAGE_ERROR',
  SET_APPLICATION_PAGE_FULLSCREEN_ERROR:
    'SET_APPLICATION_PAGE_FULLSCREEN_ERROR',
  CREATE_ORGANIZATION_ERROR: 'CREATE_ORGANIZATION_ERROR',
  ADD_USER_TO_ORG_ERROR: 'ADD_USER_TO_ORG_ERROR',
  UPDATE_WIDGET_NAME_ERROR: 'UPDATE_WIDGET_NAME_ERROR',
  FETCH_ACTIONS_FOR_PAGE_ERROR: 'FETCH_ACTIONS_FOR_PAGE_ERROR',
  FETCH_IMPORTED_COLLECTIONS_ERROR: 'FETCH_IMPORTED_COLLECTIONS_ERROR',
  FETCH_PROVIDERS_ERROR: 'FETCH_PROVIDERS_ERROR',
  SUBMIT_CURL_FORM_ERROR: 'SUBMIT_CURL_FORM_ERROR',
  FETCH_PROVIDER_TEMPLATES_ERROR: 'FETCH_PROVIDER_TEMPLATES_ERROR',
  ADD_API_TO_PAGE_ERROR: 'ADD_API_TO_PAGE_ERROR',
  FETCH_PROVIDERS_CATEGORIES_ERROR: 'FETCH_PROVIDERS_CATEGORIES_ERROR',
  FETCH_PROVIDERS_WITH_CATEGORY_ERROR: 'FETCH_PROVIDERS_WITH_CATEGORY_ERROR',
  CREATE_MODAL_ERROR: 'CREATE_MODAL_ERROR',
  FETCH_PROVIDER_DETAILS_BY_PROVIDER_ID_ERROR:
    'FETCH_PROVIDER_DETAILS_BY_PROVIDER_ID_ERROR',
  SAVE_ACTION_NAME_ERROR: 'SAVE_ACTION_NAME_ERROR',
  FETCH_USER_APPLICATIONS_ORGS_ERROR: 'FETCH_USER_APPLICATIONS_ORGS_ERROR',
  FORK_APPLICATION_ERROR: 'FORK_APPLICATION_ERROR',
  IMPORT_APPLICATION_ERROR: 'IMPORT_APPLICATION_ERROR',
  FETCH_ALL_USERS_ERROR: 'FETCH_ALL_USERS_ERROR',
  FETCH_ALL_ROLES_ERROR: 'FETCH_ALL_ROLES_ERROR',
  UPDATE_USER_DETAILS_ERROR: 'UPDATE_USER_DETAILS_ERROR',
  FETCH_ACTIONS_VIEW_MODE_ERROR: 'FETCH_ACTION_VIEW_MODE_ERROR',
  SAVE_API_NAME_ERROR: 'SAVE_API_NAME_ERROR',
  POPULATE_PAGEDSLS_ERROR: 'POPULATE_PAGEDSLS_ERROR',
  FETCH_PAGE_DSL_ERROR: 'FETCH_PAGE_DSL_ERROR',
  TOGGLE_ACTION_EXECUTE_ON_LOAD_ERROR: 'TOGGLE_ACTION_EXECUTE_ON_LOAD_ERROR',
  COPY_SELECTED_WIDGET_ERROR: 'COPY_SELECTED_WIDGET_ERROR',
  PASTE_COPIED_WIDGET_ERROR: 'PASTE_COPIED_WIDGET_ERROR',
  WIDGET_ADD_CHILDREN_ERROR: 'WIDGET_ADD_CHILDREN_ERROR',
  FAILED_CORRECTING_BINDING_PATHS: 'FAILED_CORRECTING_BINDING_PATHS',
  CREATE_JS_ACTION_ERROR: 'CREATE_JS_ACTION_ERROR',
  UPDATE_JS_ACTION_ERROR: 'UPDATE_JS_ACTION_ERROR',
  COPY_JS_ACTION_ERROR: 'COPY_JS_ACTION_ERROR',
  MOVE_JS_ACTION_ERROR: 'MOVE_JS_ACTION_ERROR',
  DELETE_ORG_USER_ERROR: 'DELETE_ORG_USER_ERROR',
  CHANGE_APPVIEW_ACCESS_ERROR: 'CHANGE_APPVIEW_ACCESS_ERROR',
  SAVE_JS_COLLECTION_NAME_ERROR: 'SAVE_JS_COLLECTION_NAME_ERROR',
  FETCH_JS_ACTIONS_FOR_PAGE_ERROR: 'FETCH_JS_ACTIONS_FOR_PAGE_ERROR',
  FETCH_JS_ACTIONS_VIEW_MODE_ERROR: 'FETCH_JS_ACTIONS_VIEW_MODE_ERROR',
  GENERATE_TEMPLATE_PAGE_ERROR: 'GENERATE_TEMPLATE_PAGE_ERROR',
  FETCH_PLUGIN_FORM_ERROR: 'FETCH_PLUGIN_FORM_ERROR',
  EXECUTE_DATASOURCE_QUERY_ERROR: 'EXECUTE_DATASOURCE_QUERY_ERROR',
  INVITED_USER_SIGNUP_ERROR: 'INVITED_USER_SIGNUP_ERROR',
  SET_PAGE_ORDER_ERROR: 'SET_PAGE_ORDER_ERROR',
  DELETE_JS_ACTION_ERROR: 'DELETE_JS_ACTION_ERROR',
  REFACTOR_JS_ACTION_NAME_ERROR: 'REFACTOR_JS_ACTION_NAME_ERROR',
  FETCH_RELEASES_ERROR: 'FETCH_RELEASES_ERROR',
  RESTART_SERVER_ERROR: 'RESTART_SERVER_ERROR',
  UPDATE_JS_ACTION_BODY_ERROR: 'UPDATE_JS_ACTION_BODY_ERROR',
  UPDATE_JS_FUNCTION_PROPERTY_ERROR: 'UPDATE_JS_FUNCTION_PROPERTY_ERROR',
  DELETE_ORG_ERROR: 'DELETE_ORG_ERROR',
  REFLOW_BETA_FLAGS_INIT_ERROR: 'REFLOW_BETA_FLAGS_INIT_ERROR',
  GET_ALL_TEMPLATES_ERROR: 'GET_ALL_TEMPLATES_ERROR',
  GET_SIMILAR_TEMPLATES_ERROR: 'GET_SIMILAR_TEMPLATES_ERROR',
  IMPORT_TEMPLATE_TO_ORGANISATION_ERROR:
    'IMPORT_TEMPLATE_TO_ORGANISATION_ERROR',
  GET_DEFAULT_PLUGINS_ERROR: 'GET_DEFAULT_PLUGINS_ERROR',
  GET_TEMPLATE_ERROR: 'GET_TEMPLATE_ERROR',
};

export const ReduxFormActionTypes = {
  VALUE_CHANGE: '@@redux-form/CHANGE',
  UPDATE_FIELD_ERROR: '@@redux-form/UPDATE_SYNC_ERRORS',
  ARRAY_REMOVE: '@@redux-form/ARRAY_REMOVE',
  ARRAY_PUSH: '@@redux-form/ARRAY_PUSH',
};

export enum ReplayReduxActionTypes {
  UNDO = 'undo',
  REDO = 'redo',
}

export const ReflowReduxActionTypes = {
  STOP_REFLOW: 'STOP_REFLOW',
  REFLOW_MOVE: 'REFLOW_MOVE',
  ENABLE_REFLOW: 'ENABLE_REFLOW',
  ONBOARDING_UPDATE: 'ONBOARDING_UPDATE',
  CLOSE_ONBOARDING_CARD: 'CLOSE_ONBOARDING_CARD',
};

export const WidgetReduxActionTypes: { [key: string]: string } = {
  WIDGET_ADD_CHILD: 'WIDGET_ADD_CHILD',
  WIDGET_CHILD_ADDED: 'WIDGET_CHILD_ADDED',
  WIDGET_REMOVE_CHILD: 'WIDGET_REMOVE_CHILD',
  WIDGET_RESIZE: 'WIDGET_RESIZE',
  WIDGET_MODAL_RESIZE: 'WIDGET_MODAL_RESIZE',
  WIDGET_DELETE: 'WIDGET_DELETE',
  WIDGET_BULK_DELETE: 'WIDGET_BULK_DELETE',
  WIDGET_SINGLE_DELETE: 'WIDGET_SINGLE_DELETE',
  WIDGET_ADD_CHILDREN: 'WIDGET_ADD_CHILDREN',
  WIDGET_UPDATE_PROPERTY: 'WIDGET_UPDATE_PROPERTY',
};

export type ReduxActionErrorType = typeof ReduxActionErrorTypes[keyof typeof ReduxActionErrorTypes];

export interface ReduxAction<T> {
  type: ReduxActionType | ReduxActionErrorType;
  payload: T;
}

export type ReduxActionWithoutPayload = Pick<ReduxAction<undefined>, 'type'>;

export interface ReduxActionWithMeta<T, M> extends ReduxAction<T> {
  meta: M;
}

export interface ReduxActionWithCallbacks<T, S, E> extends ReduxAction<T> {
  onSuccess?: ReduxAction<S>;
  onError?: ReduxAction<E>;
  onSuccessCallback?: (response: S) => void;
  onErrorCallback?: (error: E) => void;
}

export type AnyReduxAction = ReduxAction<unknown> | ReduxActionWithoutPayload;

export interface EvaluationReduxAction<T> extends ReduxAction<T> {
  postEvalActions?: Array<AnyReduxAction>;
}

export interface PromisePayload {
  reject: any;
  resolve: any;
}

export interface ReduxActionWithPromise<T> extends ReduxAction<T> {
  payload: T & PromisePayload;
}

export interface ReduxActionErrorPayload {
  message: string;
  source?: string;
  code?: ERROR_CODES;
}

export interface UpdateCanvasPayload {
  pageWidgetId?: string;
  widgets: { [widgetId: string]: WidgetProps };
  currentLayoutId: string;
  currentPageId: string;
  currentPageName: string;
  currentApplicationId: string;
  pageActions: PageAction[][];
}

export interface ShowPropertyPanePayload {
  widgetId: string;
  callForDragOrResize: boolean;
  force: boolean;
}

export interface Page {
  pageName?: string;
  pageId?: string;
  isDefault?: boolean;
  latest?: boolean;
  isHidden?: boolean;
  isManagePage?: boolean;
  slug?: string;
  pageType?: 'PAGE' | 'DIR';
  parentId?: string;
  children?: Array<Page>;
  id?: string;
  name?: string;
  outId?: string;
  isScreen?: boolean;
  icon?: string;
  order?: number;
  isPrint?: boolean;
  tempPageId?: string; // 从模版复制应用时取自模版的pageId
  [key: string]: unknown;
}

export interface ClonePageSuccessPayload {
  name: string;
  id: string;
  layoutId: string;
  isDefault: boolean;
  pageName: string;
  pageId: string;
  pageType: 'PAGE' | 'DIR';
  parentId: string;
}

export type PageListPayload = Array<Page>;

export interface ApplicationPayload {
  appCover: string;
  id: string;
  name: string;
  color?: string;
  icon?: string;
  organizationId: string;
  defaultPageId: string;
  isPublic?: boolean;
  userPermissions?: string[];
  appIsExample: boolean;
  slug?: string;
  forkingEnabled?: boolean;
  appLayout?: AppLayoutConfig;
  gitApplicationMetadata?: GitApplicationMetadata;
  lastDeployedAt?: string;
  applicationId?: string;
  modifiedBy?: string;
  modifiedAt?: string;
  pages: ApplicationPagePayload[];
  applicationVersion: ApplicationVersion;
  isAutoUpdate?: boolean;
  isManualUpdate?: boolean;
  type: number;
  coverUrl?: string;
  logoUrl?: string;
  isCustom?: boolean;
  themeType?: string;
  coverFileName?: string;
  logoFileName?: string;
  [x: string]: unknown;
  configExtension?: {
    channel?: string;
    customizable?: boolean;
    customTo?: number;
  };
  /**
   * NOTE: new app layout and menu style configs
   */
  appMenuBg?: string; // 应用菜单背景色
  appMenuTextColor?: string; // 应用菜单文本字体色
  arrangementLayout?: 'leftToRight' | 'topToBottom' | 'minimalism'; // 应用的布局，「极简布局」暂不支持
  menuActiveTextColor?: string; // 选中文字色
  menuActiveBgColor?: string; // 选中背景色
  appNameTextColor?: string; // 应用字体色
}

export type OrganizationDetails = {
  organization: Org;
  applications: any[];
};

export interface LoadWidgetEditorPayload {
  widgets: WidgetProps[];
}

export interface LoadWidgetSidebarPayload {
  cards: { [id: string]: WidgetCardProps[] };
}
