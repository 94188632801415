import React from 'react';
import {
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  BrowserRouter,
  matchPath,
} from 'react-router-dom';
import Login from 'ce/pages/UserAuth/Login';
import BindTel from 'ce/pages/UserAuth/BindTel';
import { AuthCard, AuthCardContainer, AuthContainer } from './StyledComponents';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import PageNotFound from 'pages/common/PageNotFound';
import FooterLinks from './FooterLinks';
import { requiresUnauth } from './requiresAuthHOC';
import { useSelector } from 'react-redux';
import { getThemeDetails, ThemeMode } from 'selectors/themeSelectors';
import { AppState } from 'reducers';
import { ThemeProvider } from 'styled-components';
import Switcher from 'components/ads/Switcher';
import { FORGOT_PASSWORD_URL, SETUP, SIGN_UP_URL } from 'constants/routes';
import { getIsSafeRedirectURL } from 'utils/helpers';
import cs from 'classnames';
import { LOGIN_SUBMIT_PATH } from '@appsmith/constants/ApiConstants';
import { IconSubscribe, IconUser } from '@arco-design/web-react/icon';
// import history from 'utils/history';
import styled from 'styled-components';
import classNames from 'classnames';

const AuthCardStyle = styled(AuthCard)`
  background: linear-gradient(180deg, #e2eeff 0%, rgba(255, 255, 255, 0) 30%);
  padding: 48px !important;
  width: 496px;
  height: 596px;
  @media (max-width: 640px) {
    background: #fff;
    height: auto !important;
    padding: 20px !important;
  }
`;

export function UserAuth(props) {
  const { path } = useRouteMatch();
  const location = useLocation();
  const lightTheme = useSelector((state: AppState) =>
    getThemeDetails(state, ThemeMode.LIGHT)
  );
  const queryParams = new URLSearchParams(location.search);
  const actTab = location.pathname === '/user/signup' ? 'signup' : 'login';
  // const [actTab, setActTab] = React.useState('login');
  let loginURL = '/api/v1/' + LOGIN_SUBMIT_PATH;
  let signupURL = SIGN_UP_URL;
  const redirectUrl = queryParams.get('redirectUrl');
  if (redirectUrl !== null && getIsSafeRedirectURL(redirectUrl)) {
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);
    loginURL += `?redirectUrl=${encodedRedirectUrl}`;
    signupURL += `?redirectUrl=${encodedRedirectUrl}`;
  }
  const isResetPasswordPage = !!matchPath(location.pathname, {
    path: `${path}/resetPassword`,
  });
  // console.log(isResetPasswordPage, 'isResetPasswordPage');

  return (
    <ThemeProvider theme={lightTheme}>
      <AuthContainer>
        <AuthCardStyle
          className={classNames({
            '!w-[496px] !h-[424px]': isResetPasswordPage,
          })}
        >
          <Switch location={location}>
            <Route component={Login} exact path={`${path}/login`} />
            <Route component={BindTel} exact path={`${path}/bindTel`} />
            <Route
              component={ResetPassword}
              exact
              path={`${path}/resetPassword`}
            />
            <Route component={PageNotFound} />
          </Switch>
        </AuthCardStyle>
      </AuthContainer>
    </ThemeProvider>
  );
}

export default requiresUnauth(UserAuth);
