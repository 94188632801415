import React, { ReactNode, useState, useEffect, useRef, useMemo } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { WIDGET_PADDING } from 'constants/WidgetConstants';
import { animated, Spring } from 'react-spring';
import PerformanceTracker, {
  PerformanceTransactionName,
} from 'utils/PerformanceTracker';
import { useReflow } from 'utils/hooks/useReflow';
import {
  getReflowSelector,
  isReflowEnabled,
} from 'selectors/widgetReflowSelectors';
import { shallowEqual, useSelector } from 'react-redux';
import { OccupiedSpace } from 'constants/CanvasEditorConstants';
import {
  GridProps,
  MovementLimitMap,
  ReflowDirection,
  ReflowedSpace,
} from 'reflow/reflowTypes';
import { getNearestParentCanvas } from 'utils/generators';
import { getOccupiedSpaces } from 'selectors/editorSelectors';
import { isDropZoneOccupied } from 'utils/WidgetPropsUtils';
import { debounce } from 'lodash';
import { useDrag } from 'react-use-gesture';

const ResizeWrapper = styled(animated.div)<{ $prevents: boolean }>`
  display: block;
  & {
    * {
      pointer-events: ${(props) => !props.$prevents && 'none'};
    }
  }
`;

/**
 * @description 根据网格四舍五入大小
 */
const getSnappedValues = (
  x: number,
  y: number,
  snapGrid: { x: number; y: number }
) => {
  return {
    x: Math.round(x / snapGrid.x) * snapGrid.x,
    y: Math.round(y / snapGrid.y) * snapGrid.y,
  };
};

export type DimensionProps = {
  width: number;
  height: number;
  x: number; // 偏移量实际大小px
  y: number; // 偏移量实际大小px
  reset?: boolean;
  direction: ReflowDirection;
  X?: number;
  Y?: number;
};

type IStyledHandlerProps = StyledComponent<
  'div',
  any,
  Record<string, unknown>,
  never
>;

type ResizableHandleProps = {
  allowResize: boolean;
  scrollParent: HTMLDivElement | null;
  checkForCollision: (widgetNewSize: {
    left: number;
    top: number;
    bottom: number;
    right: number;
  }) => boolean;
  dragCallback: (x: number, y: number) => void;
  component: IStyledHandlerProps;
  onStart: () => void;
  onStop: () => void;
  snapGrid: {
    x: number;
    y: number;
  };
  paddingOffset: number;
  isWidgetFocused: boolean;
  resizeMode?: 'rail' | 'corner';
};

/**
 * @description handle resize component and size logic
 */

function ResizableHandle(props: ResizableHandleProps) {
  const [isDragging, setIsDragging] = useState(false);

  const debounceStop = debounce(props.onStop, 800);
  const bind = useDrag((state) => {
    const {
      first,
      last,
      dragging,
      memo,
      movement: [mx, my],
    } = state;
    if (!props.allowResize) {
      return;
    }

    setIsDragging(dragging);

    const scrollParent = getNearestParentCanvas(props.scrollParent);

    const initialScrollTop = memo ? memo.scrollTop : 0;
    const currentScrollTop = scrollParent?.scrollTop || 0;

    const deltaScrolledHeight = currentScrollTop - initialScrollTop;
    const deltaY = my + deltaScrolledHeight;
    const snapped = getSnappedValues(mx, deltaY, props.snapGrid);
    if (first) {
      props.onStart();
      // debounceStop(); //避免没有执行onStop页面，出现卡死
      return { scrollTop: currentScrollTop, snapped };
    }
    const { snapped: snappedMemo } = memo;

    if (
      dragging &&
      snappedMemo &&
      (snapped.x !== snappedMemo.x || snapped.y !== snappedMemo.y)
    ) {
      props.dragCallback(snapped.x, snapped.y);
    }
    if (last) {
      props.onStop();
    }

    return { ...memo, snapped };
  });

  // 边框线表现ui
  const propsToPass = {
    ...bind(),
    showAsBorder: !props.allowResize,
    paddingOffset: props.paddingOffset,
    isWidgetFocused: props.isWidgetFocused,
    isShowHorizonResizeHandler:
      props.resizeMode === 'rail' && props.isWidgetFocused,
    isDragging,
  };

  return <props.component {...propsToPass} />;
}

type ResizableProps = {
  handles: {
    left?: IStyledHandlerProps;
    top?: IStyledHandlerProps;
    bottom?: IStyledHandlerProps;
    right?: IStyledHandlerProps;
    bottomRight?: IStyledHandlerProps;
    topLeft?: IStyledHandlerProps;
    topRight?: IStyledHandlerProps;
    bottomLeft?: IStyledHandlerProps;
  };
  componentWidth: number;
  componentHeight: number;
  children: ReactNode;
  updateBottomRow: (bottomRow: number) => void;
  getResizedPositions: (
    size: { width: number; height: number },
    position: { x: number; y: number }
  ) => {
    canResizeHorizontally: boolean;
    canResizeVertically: boolean;
    resizedPositions?: OccupiedSpace;
  };
  originalPositions: OccupiedSpace;
  onStart: () => void;
  onStop: (
    size: { width: number; height: number },
    position: { x: number; y: number }
  ) => void;
  snapGrid: { x: number; y: number };
  enable: boolean;
  className?: string;
  parentId?: string;
  widgetId: string;
  gridProps: GridProps;
  zWidgetType?: string;
  zWidgetId?: string;
  paddingOffset?: number;
  isDragging?: boolean;
  isWidgetFocused?: boolean;
  resizeMode?: 'corner' | 'rail';
  padding?: string;
};

export function ReflowResizable(props: ResizableProps) {
  const resizableRef = useRef<HTMLDivElement>(null);
  const [isResizing, setResizing] = useState(false);
  const reflowEnabled = useSelector(isReflowEnabled);

  const occupiedSpaces = useSelector(getOccupiedSpaces, shallowEqual);
  const occupiedSpacesBySiblingWidgets = useMemo(() => {
    return occupiedSpaces && props.parentId && occupiedSpaces[props.parentId]
      ? occupiedSpaces[props.parentId]
      : undefined;
  }, [occupiedSpaces, props.parentId]);
  const checkForCollision = (widgetNewSize: {
    left: number;
    top: number;
    bottom: number;
    right: number;
  }) => {
    return isDropZoneOccupied(
      widgetNewSize,
      props.widgetId,
      occupiedSpacesBySiblingWidgets
    );
  };
  // Performance tracking start
  const sentryPerfTags = props.zWidgetType
    ? [{ name: 'widget_type', value: props.zWidgetType }]
    : [];
  PerformanceTracker.startTracking(
    PerformanceTransactionName.SHOW_RESIZE_HANDLES,
    { widgetId: props.zWidgetId },
    true,
    sentryPerfTags
  );
  const reflowSelector = getReflowSelector(props.widgetId);

  const equal = (
    reflowA: ReflowedSpace | undefined,
    reflowB: ReflowedSpace | undefined
  ) => {
    if (
      reflowA?.width !== reflowB?.width ||
      reflowA?.height !== reflowB?.height
    )
      return false;

    return true;
  };

  const reflowedPosition = useSelector(reflowSelector, equal);
  // console.log('位置信息🐑', reflowedPosition);

  const reflow = useReflow(
    [props.originalPositions],
    props.parentId || '',
    props.gridProps
  );

  useEffect(() => {
    PerformanceTracker.stopTracking(
      PerformanceTransactionName.SHOW_RESIZE_HANDLES
    );
  }, []);
  //end
  const [pointerEvents, togglePointerEvents] = useState(true);
  const [newDimensions, set] = useState<DimensionProps>({
    width: props.componentWidth,
    height: props.componentHeight,
    x: 0,
    y: 0,
    reset: false,
    direction: ReflowDirection.UNSET,
  });

  const setNewDimensions = (rect: DimensionProps) => {
    const { direction, height, width, x, y } = rect;

    //是否能resize
    const {
      canResizeHorizontally,
      canResizeVertically,
      resizedPositions,
    } = props.getResizedPositions({ width, height }, { x, y });

    const canResize = canResizeHorizontally || canResizeVertically;

    if (canResize) {
      set((prevState) => {
        let newRect = { ...rect };

        let canVerticalMove = true,
          canHorizontalMove = true,
          bottomMostRow = 0,
          movementLimitMap: MovementLimitMap | undefined = {};

        if (!reflowEnabled && resizedPositions) {
          const isColliding = checkForCollision(resizedPositions);
          if (isColliding) {
            return prevState;
          }
        }
        if (resizedPositions) {
          //calling reflow to update movements of reflowing widgets and get movementLimit of current resizing widget
          ({ bottomMostRow, movementLimitMap } = reflow(
            [resizedPositions],
            direction,
            true
          ));
        }
        if (
          resizedPositions &&
          movementLimitMap &&
          movementLimitMap[resizedPositions.id]
        ) {
          ({ canHorizontalMove, canVerticalMove } = movementLimitMap[
            resizedPositions.id
          ]);
        }

        //if it should not resize horizontally, we keep keep the previous horizontal dimensions
        // console.log(canHorizontalMove, canResizeHorizontally, 'rect🐑');

        if (!canHorizontalMove || !canResizeHorizontally) {
          newRect = {
            ...newRect,
            width: prevState.width,
            x: prevState.x,
            X: prevState.X,
          };
        }

        //if it should not resize vertically, we keep keep the previous vertical dimensions
        if (!canVerticalMove || !canResizeVertically) {
          newRect = {
            ...newRect,
            height: prevState.height,
            y: prevState.y,
            Y: prevState.Y,
          };
        }

        if (bottomMostRow) {
          props.updateBottomRow(bottomMostRow);
        }
        // console.log(newRect, 'rect🐑1');

        return newRect;
      });
    }
  };

  useEffect(() => {
    set((prevDimensions) => {
      return {
        ...prevDimensions,
        width: props.componentWidth,
        height: props.componentHeight,
        x: 0,
        y: 0,
        reset: true,
      };
    });
  }, [props.componentHeight, props.componentWidth, isResizing]);

  const handles = [];

  if (props.handles.left) {
    handles.push({
      dragCallback: (x: number) => {
        setNewDimensions({
          width: props.componentWidth - x,
          height: newDimensions.height,
          x: x,
          y: newDimensions.y,
          direction: ReflowDirection.LEFT,
          X: x,
        });
      },
      component: props.handles.left,
      orient: 'left',
    });
  }

  if (props.handles.top) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: newDimensions.width,
          height: props.componentHeight - y,
          y: y,
          x: newDimensions.x,
          direction: ReflowDirection.TOP,
          Y: y,
        });
      },
      component: props.handles.top,
      orient: 'top',
    });
  }

  if (props.handles.right) {
    handles.push({
      dragCallback: (x: number) => {
        setNewDimensions({
          width: props.componentWidth + x,
          height: newDimensions.height,
          x: newDimensions.x,
          y: newDimensions.y,
          direction: ReflowDirection.RIGHT,
          X: x,
        });
      },
      component: props.handles.right,
      orient: 'right',
    });
  }

  if (props.handles.bottom) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: newDimensions.width,
          height: props.componentHeight + y,
          x: newDimensions.x,
          y: newDimensions.y,
          direction: ReflowDirection.BOTTOM,
          Y: y,
        });
      },
      component: props.handles.bottom,
      orient: 'bottom',
    });
  }

  if (props.handles.topLeft) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: props.componentWidth - x,
          height: props.componentHeight - y,
          x: x,
          y: y,
          direction: ReflowDirection.TOPLEFT,
          X: x,
          Y: y,
        });
      },
      component: props.handles.topLeft,
      orient: 'topLeft',
    });
  }

  if (props.handles.topRight) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: props.componentWidth + x,
          height: props.componentHeight - y,
          x: newDimensions.x,
          y: y,
          direction: ReflowDirection.TOPRIGHT,
          X: x,
          Y: y,
        });
      },
      component: props.handles.topRight,
      orient: 'topRight',
    });
  }

  if (props.handles.bottomRight) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: props.componentWidth + x,
          height: props.componentHeight + y,
          x: newDimensions.x,
          y: newDimensions.y,
          direction: ReflowDirection.BOTTOMRIGHT,
          X: x,
          Y: y,
        });
      },
      component: props.handles.bottomRight,
      orient: 'bottomRight',
    });
  }

  if (props.handles.bottomLeft) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: props.componentWidth - x,
          height: props.componentHeight + y,
          x,
          y: newDimensions.y,
          direction: ReflowDirection.BOTTOMLEFT,
          X: x,
          Y: y,
        });
      },
      component: props.handles.bottomLeft,
      orient: 'bottomLeft',
    });
  }
  const onResizeStop = () => {
    togglePointerEvents(true);
    props.onStop(
      {
        width: newDimensions.width,
        height: newDimensions.height,
      },
      {
        x: newDimensions.x,
        y: newDimensions.y,
      }
    );
    setResizing(false);
  };

  const { paddingOffset = WIDGET_PADDING } = props;

  const renderHandles = handles.map((handle, index) => {
    const CORNERS_ORIENT = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];
    const { isWidgetFocused, resizeMode = 'corner' } = props;

    // 当前组件未选中时禁用拖拽
    if (!isWidgetFocused && CORNERS_ORIENT.includes(handle.orient)) return;

    const isHandleResizable =
      isWidgetFocused &&
      ((resizeMode === 'rail' && ['left', 'right'].includes(handle.orient)) ||
        (resizeMode === 'corner' && CORNERS_ORIENT.includes(handle.orient)));
    return (
      <ResizableHandle
        {...handle}
        allowResize={isHandleResizable}
        checkForCollision={checkForCollision}
        isWidgetFocused={props.isWidgetFocused}
        key={index}
        onStart={() => {
          togglePointerEvents(false);
          props.onStart();
          setResizing(true);
        }}
        onStop={onResizeStop}
        paddingOffset={paddingOffset}
        resizeMode={props.resizeMode}
        scrollParent={resizableRef.current}
        snapGrid={props.snapGrid}
      />
    );
  });

  const widgetWidth =
    reflowedPosition?.width === undefined
      ? newDimensions.width
      : reflowedPosition.width - 2 * paddingOffset;
  const widgetHeight =
    reflowedPosition?.height === undefined
      ? newDimensions.height
      : reflowedPosition.height - 2 * paddingOffset;
  // console.log(widgetHeight, props.componentWidth, 'paddingOffset🐑');

  return (
    <Spring
      config={{
        clamp: true,
        friction: 0,
        tension: 999,
      }}
      from={{
        width: props.componentWidth,
        height: props.componentHeight,
      }}
      immediate={newDimensions.reset ? true : false}
      to={{
        width: widgetWidth,
        height: widgetHeight,
        transform: `translate3d(${newDimensions.x}px,${newDimensions.y}px,0)`,
      }}
    >
      {(_props) => (
        <ResizeWrapper
          $prevents={pointerEvents}
          className={props.className}
          ref={resizableRef}
          style={_props}
        >
          {/* TODO */}
          {isResizing ? (
            <div className="bg-[#6871ef99] w-full h-full" />
          ) : (
            props.children
          )}
          {props.enable && renderHandles}
        </ResizeWrapper>
      )}
    </Spring>
  );
}

export default ReflowResizable;
