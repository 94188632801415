import { AppState } from 'reducers';

export const getSafeCrash = (state: AppState) => {
  return state.ui.errors.safeCrash;
};

export const getAppIsSafeCrashed = (state: AppState) =>
  state.ui.errors.isAppCrashed;

export const getSafeCrashCode = (state: AppState) => {
  return state.ui.errors.safeCrashCode;
};
