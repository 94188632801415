import { createSelector } from 'reselect';
import { AppState } from 'reducers';
import { MenuItem } from './actions';
import { Page } from 'ce/constants/ReduxActionConstants';
import { flattenDeep } from 'lodash';

// TODO remove installMode from redux, use webpack-env __INSTALL_MODE__ instead
export const isInstallMode = (state: AppState) =>
  !!state.ui.appInstall.installMode;

export const isAppInitializing = (state: AppState) =>
  state.ui.appInstall.isAppInitializing;

export const applicationId = (state: AppState) =>
  state.ui.appInstall.applicationId;

export const defaultPageId = (state: AppState) =>
  state.ui.appInstall.defaultPageId;

export const getInstallAppName = (state: AppState) =>
  state.ui.applications?.currentApplication?.name;

export const previewSubAppMode = (state: AppState) =>
  !!state.ui.appInstall.previewSubAppMode;

export const getDepAppMenus = (state: AppState) => state.ui.appInstall.appMenus;

export const getDepAppJson = (state: AppState) => state.ui.appInstall.appJson;

export const getCurrentPageId = (state: AppState) =>
  state.entities.pageList.currentPageId;

export const getActiveUserProfile = (state: AppState) =>
  state.ui.appInstall.userProfile;

export const getActionList = createSelector(getDepAppJson, (app) => {
  return (app?.actionList || [])
    .map((v) => {
      const { defaultResources, pluginId, pluginType, publishedAction } = v;
      // 后端把独立部署的 actionList 的字段做了调整
      return {
        ...publishedAction,
        id: v.id || v._id,
        pluginId,
        pluginType,
        applicationId: defaultResources?.applicationId,
        pageName: publishedAction.name || publishedAction.pageId,
        pageId: publishedAction.pageId || publishedAction._pageId,
      };
    })
    .filter(Boolean);
});

export const getJsActions = createSelector(
  getDepAppJson,
  (app) => app?.actionCollectionList || []
);

/**
 * NOTE: format page list
 */
const transformPageList = (
  v: MenuItem & {
    _id?: string;
    _pageId?: string;
    order?: number;
  }
): Page => {
  const item: Page = {
    id: v.id || v._id || v.outId,
    pageId: v.id || v._pageId || v.outId,
    parentId: v.pid,
    name: v.name,
    order: v.order,
    pageName: v.title || v.name,
    icon: v.icon || 'file',
    isScreen: v.isScreen || false,
    isPrint: v.isPrint || false,
    pageType: Array.isArray(v.children) && v.children.length ? 'DIR' : 'PAGE',
    slug: v.slug || v.path || '',
    isManagePage: v.realType === 2,
    isHidden: v.isHidden,
    outId: v.outId, // only dynamic page has outId, use outId to find page dsl in app.json
    tempPageId: v.tempPageId || v.id || v._pageId || v.outId,
  };
  if (Array.isArray(v.children) && v.children.length) {
    item.children = v.children.map(transformPageList).filter(Boolean);
  }

  // if dynamic page, should have outId
  if ((!item.isManagePage && item.outId) || item.isManagePage) {
    return item;
  }
};

// transform menu items
export const getDepPageList = createSelector(
  getDepAppMenus,
  (menus: MenuItem[]): Page[] => {
    return menus.map(transformPageList).filter(Boolean);
  }
);

// export const getCurAppAllPagesInstallMode = createSelector(
//   getDepAppJson,
//   (app) => {
//     return app?.pageList?.map(transformPageList)?.filter(Boolean) ?? [];
//   }
// );

// get menu items for page tabs
export const getInstallModePageList = createSelector(
  getDepPageList,
  getCurrentPageId,
  (pages: Page[], curId?: string) => {
    if (curId) {
      if (curId.startsWith('manage/')) {
        // todo: handle manage page by slug
      }
      const currentPage = pages.find((page) =>
        [page.pageId, page.id, page.outId].includes(curId)
      );
      if (!!currentPage?.isHidden) {
        return [currentPage];
      }

      return pages.filter((page) => !page.isHidden);
    }

    return [];
  }
);

export function flattenPages(pages = []): Page[] {
  return pages.map((p) => {
    if (p.children && p.children.length) {
      return flattenPages(p.children);
    }
    return p;
  });
}

// get first dynamic page
export const getDepDefaultPageId = createSelector(getDepPageList, (pages) => {
  const allPages = flattenDeep(flattenPages(pages));
  // @ts-ignore
  const pg = allPages.find((p) => p.outId && p.pageType === 'PAGE');
  // @ts-ignore
  return pg ? pg.outId : '';
});

// page detail config in app.json
type PublishedPage = Page & { layouts: any[] }; // fixme

interface PageDetail {
  id: string;
  userPermissions: string[];
  gitSyncId: string;
  unpublishedPage: PublishedPage;
  publishedPage: PublishedPage;
  new: boolean;
}

const findPage = (id: string, pages: Page[]) => {
  let found;

  function find(items) {
    if (found) return;

    for (let i = 0, len = items.length; i < len; i++) {
      const cur = items[i];
      if ([cur.id, cur.pageId, cur.outId].includes(id)) {
        found = cur;
        break;
      }

      if (Array.isArray(cur.children) && cur.children.length) {
        find(cur.children);
      }
    }
  }

  find(pages);

  return found;
};

const findPageDetail = (id: string, pages: PageDetail[]) => {
  let found;

  function find(items) {
    if (found) return;

    for (let i = 0, len = items.length; i < len; i++) {
      const cur = items[i];
      // change origin id => _id
      if (cur._id === id) {
        found = cur;
        break;
      }

      if (
        Array.isArray(cur.publishedPage.children) &&
        cur.publishedPage.children.length
      ) {
        find(cur.publishedPage.children);
      }
    }
  }

  find(pages);

  return found;
};

export const getCurrentPage = (page_id?: string) => {
  return createSelector(getDepAppJson, getDepPageList, (app, pages: Page[]) => {
    const curPage = findPage(page_id, pages);
    if (!curPage) {
      return;
    }

    const { outId } = curPage;
    return findPageDetail(outId, app.pageList || []);
  });
};
