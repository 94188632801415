import { WidgetType } from 'constants/WidgetConstants';
import {
  EvaluationReduxAction,
  ReduxAction,
  ReduxActionTypes,
  UpdateCanvasPayload,
  ReduxActionErrorTypes,
  AnyReduxAction,
  WidgetReduxActionTypes,
  ReplayReduxActionTypes,
} from '@appsmith/constants/ReduxActionConstants';
import AnalyticsUtil from 'utils/AnalyticsUtil';
import { WidgetOperation } from 'utils/widgetBridge';
import { FetchPageRequest, PageLayout, SavePageResponse } from 'api/PageApi';
import { UrlDataState } from 'reducers/entityReducers/appReducer';
import { APP_MODE } from 'entities/App';
import { CanvasWidgetsReduxState } from 'reducers/entityReducers/canvasWidgetsReducer';
import { GenerateTemplatePageRequest } from 'api/PageApi';
import { ENTITY_TYPE } from 'entities/AppsmithConsole';
import { Replayable } from 'entities/Replay/ReplayEntity/ReplayEditor';
import { ToggleFullScreenRequest } from 'api/ApplicationApi';

export interface FetchPageListPayload {
  applicationId: string;
  mode: APP_MODE;
}

export interface ClonePageActionPayload {
  id: string;
  blockNavigation?: boolean;
}

export interface CreatePageActionPayload {
  applicationId: string;
  name: string;
  layouts: Partial<PageLayout>[];
  blockNavigation?: boolean;
  onSuccess?: CallableFunction;
}

export const fetchPage = (
  pageId: string,
  isFirstLoad = false
): ReduxAction<FetchPageRequest> => {
  return {
    type: ReduxActionTypes.FETCH_PAGE_INIT,
    payload: {
      id: pageId,
      isFirstLoad,
    },
  };
};

export const fetchPublishedPage = (
  pageId: string,
  bustCache = false,
  applicationId?: string,
  model?: number
) => ({
  type: ReduxActionTypes.FETCH_PUBLISHED_PAGE_INIT,
  payload: {
    pageId,
    bustCache,
    applicationId,
    model,
  },
});

export const fetchPageSuccess = (
  postEvalActions: Array<AnyReduxAction>
): EvaluationReduxAction<undefined> => {
  return {
    type: ReduxActionTypes.FETCH_PAGE_SUCCESS,
    postEvalActions,
    payload: undefined,
  };
};

export const fetchPublishedPageSuccess = (
  postEvalActions: Array<AnyReduxAction>
): EvaluationReduxAction<undefined> => ({
  type: ReduxActionTypes.FETCH_PUBLISHED_PAGE_SUCCESS,
  postEvalActions,
  payload: undefined,
});

export const updateCurrentPage = (id: string, slug?: string) => ({
  type: ReduxActionTypes.SWITCH_CURRENT_PAGE_ID,
  payload: { id, slug },
});

export const initCanvasLayout = (
  payload: UpdateCanvasPayload
): ReduxAction<UpdateCanvasPayload> => {
  return {
    type: ReduxActionTypes.INIT_CANVAS_LAYOUT,
    payload,
  };
};

export const setLastUpdatedTime = (payload: number): ReduxAction<number> => ({
  type: ReduxActionTypes.SET_LAST_UPDATED_TIME,
  payload,
});

export const savePageSuccess = (payload: SavePageResponse) => {
  return {
    type: ReduxActionTypes.SAVE_PAGE_SUCCESS,
    payload,
  };
};

export const updateWidgetNameSuccess = () => {
  return {
    type: ReduxActionTypes.UPDATE_WIDGET_NAME_SUCCESS,
  };
};

export const deletePageSuccess = () => {
  return {
    type: ReduxActionTypes.DELETE_PAGE_SUCCESS,
  };
};
// 🐑 更新dsl的action
export const updateAndSaveLayout = (
  widgets: CanvasWidgetsReduxState,
  isRetry?: boolean,
  shouldReplay?: boolean
) => {
  return {
    type: ReduxActionTypes.UPDATE_LAYOUT,
    payload: { widgets, isRetry, shouldReplay },
  };
};

export const saveLayout = (
  isRetry?: boolean,
  replaceName?: string,
  replaceValue?: any,
  widgetId?: string
) => {
  return {
    type: ReduxActionTypes.SAVE_PAGE_INIT,
    payload: { isRetry, replaceName, replaceValue, widgetId },
  };
};

export const createPage = ({
  applicationId,
  blockNavigation,
  isPrint,
  isScreen,
  layouts,
  onSuccess,
  pageName,
  pageType,
  parentId,
}: {
  applicationId: string;
  pageName: string;
  pageType: 'DIR' | 'PAGE';
  parentId: string;
  layouts: Partial<PageLayout>[];
  blockNavigation?: boolean;
  onSuccess?: (data: any) => any;
  isPrint?: boolean;
  isScreen?: boolean;
}) => {
  AnalyticsUtil.logEvent('CREATE_PAGE', {
    pageName,
  });
  return {
    type: ReduxActionTypes.CREATE_PAGE_INIT,
    payload: {
      applicationId,
      name: pageName,
      layouts,
      parentId,
      isPrint,
      isScreen,
      blockNavigation,
      pageType,
      onSuccess,
    },
  };
};

/**
 * action to clone page
 *
 * @param pageId
 * @param blockNavigation
 * @returns
 */
export const clonePageInit = (pageId: string, blockNavigation?: boolean) => {
  return {
    type: ReduxActionTypes.CLONE_PAGE_INIT,
    payload: {
      id: pageId,
      blockNavigation,
    },
  };
};

export const clonePageSuccess = (payload: {
  id: string;
  layoutId: string;
  name: string;
  pageId: string;
  pageName: string;
  pageType: 'PAGE' | 'DIR';
  parentId: string;
  slug: string;
  isDefault: boolean;
}) => {
  return {
    type: ReduxActionTypes.CLONE_PAGE_SUCCESS,
    payload: {
      ...payload,
      name: payload.name,
      id: payload.id,
      layoutId: payload.layoutId,
      isDefault: payload.isDefault,
    },
  };
};

export const updatePage = (
  id: string,
  name: string,
  isHidden?: boolean,
  icon?: string,
  pageIndex?: any
) => {
  return {
    type: ReduxActionTypes.UPDATE_PAGE_INIT,
    payload: {
      id,
      name,
      isHidden,
      icon,
      pageIndex,
    },
  };
};

export type WidgetAddChild = {
  widgetId: string;
  widgetName?: string;
  type: WidgetType;
  leftColumn: number;
  topRow: number;
  columns: number;
  rows: number;
  parentRowSpace: number;
  parentColumnSpace: number;
  newWidgetId: string;
  tabId: string;
  props?: Record<string, any>;
};

export type WidgetRemoveChild = {
  widgetId: string;
  childWidgetId: string;
};

export type WidgetDelete = {
  widgetId?: string;
  parentId?: string;
  disallowUndo?: boolean;
  isShortcut?: boolean;
};

export type MultipleWidgetDeletePayload = {
  widgetIds: string[];
  disallowUndo?: boolean;
  isShortcut?: boolean;
};

export type WidgetResize = {
  widgetId: string;
  parentId: string;
  leftColumn: number;
  rightColumn: number;
  topRow: number;
  bottomRow: number;
  snapColumnSpace: number;
  snapRowSpace: number;
};

export type ModalWidgetResize = {
  height: number;
  width: number;
  widgetId: string;
  canvasWidgetId: string;
};

export type WidgetAddChildren = {
  widgetId: string;
  children: Array<{
    type: WidgetType;
    widgetId: string;
    parentId: string;
    parentRowSpace: number;
    parentColumnSpace: number;
    leftColumn: number;
    rightColumn: number;
    topRow: number;
    bottomRow: number;
    isLoading: boolean;
  }>;
};

export type WidgetUpdateProperty = {
  widgetId: string;
  propertyPath: string;
  propertyValue: any;
};

//🐑 页面组件的增删
export const updateWidget = (
  operation: WidgetOperation,
  widgetId: string,
  payload: any
): ReduxAction<
  | WidgetAddChild
  | WidgetResize
  | WidgetDelete
  | WidgetAddChildren
  | WidgetUpdateProperty
> => {
  return {
    type: WidgetReduxActionTypes['WIDGET_' + operation],
    payload: { widgetId, ...payload },
  };
};

export const setUrlData = (
  payload: UrlDataState
): ReduxAction<UrlDataState> => {
  return {
    type: ReduxActionTypes.SET_URL_DATA,
    payload,
  };
};

export const updateUrlData = (
  payload: Partial<UrlDataState>
): ReduxAction<Partial<UrlDataState>> => {
  return {
    type: ReduxActionTypes.UPDATE_URL_DATA,
    payload,
  };
};

export const setAppMode = (payload: APP_MODE): ReduxAction<APP_MODE> => {
  return {
    type: ReduxActionTypes.SET_APP_MODE,
    payload,
  };
};

export const updateAppTransientStore = (
  payload: Record<string, unknown>
): EvaluationReduxAction<Record<string, unknown>> => ({
  type: ReduxActionTypes.UPDATE_APP_TRANSIENT_STORE,
  payload,
  postEvalActions: [
    {
      type: ReduxActionTypes.UPDATE_APP_STORE_EVALUATED,
    },
  ],
});

export const updateAppPersistentStore = (
  payload: Record<string, unknown>
): EvaluationReduxAction<Record<string, unknown>> => {
  return {
    type: ReduxActionTypes.UPDATE_APP_PERSISTENT_STORE,
    payload,
    postEvalActions: [
      {
        type: ReduxActionTypes.UPDATE_APP_STORE_EVALUATED,
      },
    ],
  };
};

export interface ReduxActionWithExtraParams<T> extends ReduxAction<T> {
  extraParams: Record<any, any>;
}

export type GenerateCRUDSuccess = {
  page: {
    layouts: Array<any>;
    id: string;
    name: string;
    isDefault?: boolean;
    parentId: string;
    pageType: 'PAGE' | 'DIR';
  };
  isNewPage: boolean;
};

export const generateTemplateSuccess = (payload: GenerateCRUDSuccess) => {
  return {
    type: ReduxActionTypes.GENERATE_TEMPLATE_PAGE_SUCCESS,
    payload,
  };
};

export const generateTemplateError = () => {
  return {
    type: ReduxActionErrorTypes.GENERATE_TEMPLATE_PAGE_ERROR,
  };
};

export const generateTemplateToUpdatePage = ({
  applicationId,
  columns,
  datasourceId,
  mode,
  pageId,
  pluginSpecificParams,
  searchColumn,
  tableName,
}: GenerateTemplatePageRequest): ReduxActionWithExtraParams<GenerateTemplatePageRequest> => {
  return {
    type: ReduxActionTypes.GENERATE_TEMPLATE_PAGE_INIT,
    payload: {
      pageId,
      tableName,
      datasourceId,
      applicationId,
      columns,
      searchColumn,
      pluginSpecificParams,
    },
    extraParams: {
      mode,
    },
  };
};

export function updateReplayEntity(
  entityId: string,
  entity: Replayable,
  entityType: ENTITY_TYPE
) {
  return {
    type: ReduxActionTypes.UPDATE_REPLAY_ENTITY,
    payload: { entityId, entity, entityType },
  };
}

export function undoAction() {
  return {
    type: ReduxActionTypes.UNDO_REDO_OPERATION,
    payload: {
      operation: ReplayReduxActionTypes.UNDO,
    },
  };
}

export function redoAction() {
  return {
    type: ReduxActionTypes.UNDO_REDO_OPERATION,
    payload: {
      operation: ReplayReduxActionTypes.REDO,
    },
  };
}
/**
 * action for delete page
 *
 * @param pageId
 * @param pageName
 * @returns
 */
export const deletePage = (pageId: string) => {
  return {
    type: ReduxActionTypes.DELETE_PAGE_INIT,
    payload: {
      id: pageId,
    },
  };
};

/**
 * action for set page as default
 *
 * @param pageId
 * @param applicationId
 * @returns
 */
export const setPageAsDefault = (pageId: string, applicationId?: string) => {
  return {
    type: ReduxActionTypes.SET_DEFAULT_APPLICATION_PAGE_INIT,
    payload: {
      id: pageId,
      applicationId,
    },
  };
};

export const togglePageFullscreen = (payload: ToggleFullScreenRequest) => {
  return {
    type: ReduxActionTypes.SET_APPLICATION_PAGE_FULLSCREEN_INIT,
    payload,
  };
};

/**
 * action for updating order of a page
 *
 * @param pageId
 * @param applicationId
 * @returns
 */
export const setPageOrder = (
  applicationId: string,
  pageId: string,
  order: number,
  parentId: string
) => {
  return {
    type: ReduxActionTypes.SET_PAGE_ORDER_INIT,
    payload: {
      pageId: pageId,
      order: order,
      applicationId,
      parentId,
    },
  };
};
