import React, { Suspense, lazy, useEffect, useState } from 'react';
import history from 'utils/history';
import AppHeader from 'pages/common/AppHeader';
import {
  matchPath,
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
} from 'react-router-dom';
import {
  APPLICATIONS_URL,
  BASE_URL,
  BUILDER_PATH,
  WEB_SITE,
  ORG_URL,
  SIGNUP_SUCCESS_URL,
  USER_AUTH_URL,
  TEMPLATE_DETAIL_PATH,
  TEMPLATE_HELP,
  APPLICATIONS_HELP,
  VIDEO_HELP,
  // PROFILE,
  USER_CENTER,
  SETUP,
  VIEWER_PATH,
  ADMIN_SETTINGS_PATH,
  ADMIN_SETTINGS_CATEGORY_PATH,
  ADMIN_SETTINGS_CATEGORY_DEFAULT_PATH,
  BUILDER_PATH_DEPRECATED,
  COMMUNITY,
  DOCUMENTS,
  VIEWER_PATH_DEPRECATED,
  APP_DEPLOY_PATH,
  CONFIRM_APP_START_PATH,
} from 'constants/routes';
import LandingScreen from './LandingScreen';
import UserAuth from 'pages/UserAuth';
import ErrorPage from 'pages/common/ErrorPage';
import PageNotFound from 'pages/common/PageNotFound';
import PageLoadingBar from 'pages/common/PageLoadingBar';
import ErrorPageHeader from 'pages/common/ErrorPageHeader';
import { getCurrentThemeDetails, ThemeMode } from 'selectors/themeSelectors';
import { AppState } from 'reducers';
import { setThemeMode } from 'actions/themeActions';
import { connect } from 'react-redux';

import { trimTrailingSlash } from 'utils/helpers';
import { getSafeCrash, getSafeCrashCode } from 'selectors/errorSelectors';
// import UserProfile from 'pages/UserProfile';
import { ServiceCenter } from 'pages/ServiceCenter';
import { getCurrentUser } from 'actions/authActions';
import { selectFeatureFlags } from 'selectors/usersSelectors';
import Setup from 'pages/setup';
import Settings from 'pages/Settings';
import SignupSuccess from 'pages/setup/SignupSuccess';
import { Theme } from 'constants/DefaultTheme';
import { ERROR_CODES } from '@appsmith/constants/ApiConstants';
import { fetchFeatureFlagsInit } from 'actions/userActions';
import FeatureFlags from 'entities/FeatureFlags';
import PrivacyPolicy from 'ce/pages/UserAuth/PrivacyPolicy';
import { getCurrentUser as _getCurrentUser } from 'selectors/usersSelectors';
import { ANONYMOUS_USERNAME } from 'constants/userConstants';
import { Message, Spin } from '@arco-design/web-react';
import PhysicalExaminationGuideSheet from 'pages/print/physical-examination-guide-sheet';

// pages, no need retry with promise, just use react.lazy
const Applications = lazy(() => import('./pages/Applications'));
const Organization = lazy(() => import('./pages/organization'));
const Community = lazy(() => import('./pages/Community'));
const Documents = lazy(() => import('./pages/Documents'));
const Editor = lazy(() => import('./pages/Editor'));
const AppViewerMirror = lazy(() => import('./pages/AppViewer'));
const ConfirmAppStart = lazy(() => import('./pages/confirm-app-start'));
const TemplateDetail = lazy(() => import('pages/Templates/detail'));
const TemplateHelp = lazy(() => import('pages/Templates/Feedback'));
const ApplicationsHelp = lazy(() => import('pages/HelpCenter/DocumentHelp'));
const VideoHelp = lazy(() => import('pages/HelpCenter/VideoHelp'));

function changeAppBackground(currentTheme: any) {
  if (
    trimTrailingSlash(window.location.pathname) === '/applications' ||
    window.location.pathname.indexOf('/settings/') !== -1 ||
    trimTrailingSlash(window.location.pathname) === '/profile' ||
    trimTrailingSlash(window.location.pathname) === '/signup-success'
  ) {
    document.body.style.backgroundColor =
      currentTheme.colors.homepageBackground;
  } else {
    document.body.style.backgroundColor = currentTheme.colors.appBackground;
  }
}

globalThis.hotLoad = null;
globalThis.repaceUrl = null;

function AppRouter(props: {
  safeCrash: boolean;
  getCurrentUser: () => void;
  getFeatureFlags: () => void;
  currentTheme: Theme;
  safeCrashCode?: ERROR_CODES;
  featureFlags: FeatureFlags;
  setTheme: (theme: ThemeMode) => void;
  user: { email: string };
}) {
  const { getCurrentUser, getFeatureFlags } = props;
  useEffect(() => {
    const stopListener = history.listen(() => {
      changeAppBackground(props.currentTheme);
    });

    getCurrentUser();
    getFeatureFlags();

    return stopListener;
  }, []);

  const [load, startHotLoad] = useState(false);
  const [loadContent, setLoadContent] = useState(false);
  const [url, setUrl] = useState('');
  useEffect(() => {
    url && history.replace(url);
  }, [url]);

  const AppViewer = loadContent
    ? () => (
        <Spin
          className="w-full h-full mt-[200px]"
          loading
          tip="内容有更新..."
        />
      )
    : AppViewerMirror;

  useEffect(() => {
    globalThis.hotLoad = startHotLoad;
    globalThis.setUrl = setUrl;
  }, [startHotLoad, setUrl]);

  useEffect(() => {
    if (load) {
      Message.info('内容有更新,即将刷新');
    } else {
      setLoadContent(true);
    }
  }, [load]);

  useEffect(() => {
    if (loadContent) {
      setLoadContent(false);
    }
  }, [loadContent]);

  useEffect(() => {
    changeAppBackground(props.currentTheme);
  }, [props.currentTheme]);

  const isAuthPage = !!matchPath(location.pathname, {
    path: USER_AUTH_URL,
  });

  return (
    <Router history={history}>
      <Suspense fallback={<PageLoadingBar />}>
        {props.safeCrash &&
        props.safeCrashCode &&
        props.user.email === ANONYMOUS_USERNAME &&
        !isAuthPage ? (
          <>
            <ErrorPageHeader />
            <ErrorPage code={props.safeCrashCode} />
          </>
        ) : (
          <>
            <AppHeader />
            <Switch>
              <Route component={LandingScreen} exact path={BASE_URL} />
              <Route component={Organization} path={ORG_URL} />
              {/*<Route component={Users} exact path={USERS_URL} />*/}
              <Route component={UserAuth} path={USER_AUTH_URL} />
              <Route component={TemplateDetail} path={TEMPLATE_DETAIL_PATH} />
              <Route component={TemplateHelp} path={TEMPLATE_HELP} />
              <Route component={ApplicationsHelp} path={APPLICATIONS_HELP} />
              <Route component={VideoHelp} path={VIDEO_HELP} />
              <Route component={Applications} path={APPLICATIONS_URL} />
              {/* <Route component={Site} path={WEB_SITE} /> */}
              <Route component={PrivacyPolicy} exact path={`/support`} />
              <Route
                component={ConfirmAppStart}
                path={CONFIRM_APP_START_PATH}
              />
              <Route
                component={SignupSuccess}
                exact
                path={SIGNUP_SUCCESS_URL}
              />
              <Route component={ServiceCenter} path={USER_CENTER} />
              <Route component={Setup} exact path={SETUP} />
              <Route
                component={Settings}
                exact
                path={ADMIN_SETTINGS_CATEGORY_PATH}
              />
              <Route component={Editor} path={BUILDER_PATH} />
              <Route component={Editor} path={APP_DEPLOY_PATH} />
              <Route component={Editor} path={BUILDER_PATH_DEPRECATED} />
              <Route component={Community} path={COMMUNITY} />
              <Route component={Documents} path={DOCUMENTS} />
              <Route component={AppViewer} path={VIEWER_PATH} />
              <Route component={AppViewer} path={VIEWER_PATH_DEPRECATED} />
              <Redirect
                exact
                from={ADMIN_SETTINGS_PATH}
                to={ADMIN_SETTINGS_CATEGORY_DEFAULT_PATH}
              />
              <Route component={PageNotFound} />
            </Switch>
          </>
        )}

        {/* pdf 打印模版 */}
        <PhysicalExaminationGuideSheet />
      </Suspense>
    </Router>
  );
}

const mapStateToProps = (state: AppState) => ({
  currentTheme: getCurrentThemeDetails(state),
  safeCrash: getSafeCrash(state),
  safeCrashCode: getSafeCrashCode(state),
  featureFlags: selectFeatureFlags(state),
  user: _getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setTheme: (mode: ThemeMode) => {
    dispatch(setThemeMode(mode));
  },
  getCurrentUser: () => dispatch(getCurrentUser()),
  getFeatureFlags: () => dispatch(fetchFeatureFlagsInit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
