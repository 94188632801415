enum PANELSTATE {
  COMPONENTS = 'components',
  QUERY = 'query',
  DATA = 'data',
  OVERVIEW = 'overview',
  ATTRIBUTES = 'attributes',
  PAGE = 'PAGE',
  ASSETS = 'Assets',
  RESOURCE = 'Resource',
  GLOBAL_SYS_CONFIG = 'GlobalSysConfig',
  MODEL = 'MODEL',
}

export { PANELSTATE };
