import { clearCookie, redirectLoginPage } from '@appInstall/utils';
import {
  API_STATUS_CODES,
  ERROR_CODES,
  errorCode,
  SERVER_ERROR_CODES,
} from '@appsmith/constants/ApiConstants';
import {
  createMessage,
  ERROR_0,
  ERROR_401,
  ERROR_500,
  SERVER_API_TIMEOUT_ERROR,
} from '@appsmith/constants/messages';
import { logoutUser } from 'actions/userActions';
import { ActionExecutionResponse } from 'api/ActionAPI';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AUTH_LOGIN_URL } from 'constants/routes';
import { get } from 'lodash';
import log from 'loglevel';
import { getCurrentGitBranch } from 'selectors/gitSyncSelectors';
import store from 'store';
import getQueryParamsObject from 'utils/getQueryParamsObject';
import history from 'utils/history';

const executeActionRegex = /actions\/execute/;
const downloadRegex = /publish\/download/;
const timeoutErrorRegex = /timeout of (\d+)ms exceeded/;
export const axiosConnectionAbortedCode = 'ECONNABORTED';

export const axiosController = new AbortController();

const makeExecuteActionResponse = (response: any): ActionExecutionResponse => ({
  ...response.data,
  clientMeta: {
    size: response.headers['content-length'],
    duration: Number(performance.now() - response.config.timer).toFixed(),
  },
});

const is404orAuthPath = () => {
  const pathName = window.location.pathname;
  return /^\/404/.test(pathName) || /^\/user\/\w+/.test(pathName);
};

// Request interceptor will add a timer property to the request.
// this will be used to calculate the time taken for an action
// execution request
export const apiRequestInterceptor = (config: AxiosRequestConfig) => {
  const branch =
    getCurrentGitBranch(store.getState()) || getQueryParamsObject().branch;
  if (branch) {
    config.headers.branchName = branch;
  }
  if (config.url?.indexOf('/git/') !== -1) {
    config.timeout = 1000 * 120; // increase timeout for git specific APIs
  }

  return { ...config, timer: performance.now() };
};

// On success of an API, if the api is an action execution,
// add the client meta object with size and time taken info
// otherwise just return the data
export const apiSuccessResponseInterceptor = (
  response: AxiosResponse
): AxiosResponse['data'] => {
  const resCode = parseInt(get(response, 'data.code')) || 200;
  const appName = get(response, 'data.appName');

  if (__INSTALL_MODE__) {
    if (resCode === API_STATUS_CODES.APP_NOT_REGISTERED) {
      // install mode, app not have license
      // get license meta info from api response, redirect to verify page
      const { info, openUrl } = response.data.data || {};
      const verifyPageUrl = `/api/page/verify?payload=${encodeURIComponent(
        info
      )}&prefix=${openUrl}`;

      setTimeout(() => {
        // window.location.replace(verifyPageUrl);
        history.push(verifyPageUrl);
      }, 500);

      return Promise.reject({
        code: API_STATUS_CODES.APP_NOT_REGISTERED,
        message:
          get(response, 'data.message') ||
          errorCode[API_STATUS_CODES.APP_NOT_REGISTERED],
        show: false,
      });
    }

    if (resCode === API_STATUS_CODES.REQUEST_NOT_AUTHORISED) {
      /**
       * NOTE: fixed the follow issue:
       * when the cookie is invalid, the page will link to login page.
       * however, the login page only verify the cookie exist, which lead to circular jump between two pages.
       */
      // debugger;
      clearCookie();

      setTimeout(() => {
        redirectLoginPage();
      }, 0);

      return Promise.reject({
        code: ERROR_CODES.REQUEST_NOT_AUTHORISED,
        message: response.data.msg || '接口认证失败，请重新登录',
        show: false,
      });
    }

    if (resCode === API_STATUS_CODES.DATA_SOURCE_UNAVAILABLE) {
      setTimeout(() => {
        history.push('/api/page/datasource-configuration');
      }, 500);

      return Promise.reject({
        code: API_STATUS_CODES.DATA_SOURCE_UNAVAILABLE,
        message:
          get(response, 'data.message') ||
          get(response, 'data.msg') ||
          errorCode[API_STATUS_CODES.DATA_SOURCE_UNAVAILABLE],
        show: true,
      });
    }
  }

  if (response.config.url) {
    if (response.config.url.match(executeActionRegex)) {
      return makeExecuteActionResponse(response);
    }

    if (response.config.url.match(downloadRegex)) {
      const fileName = response.headers['content-disposition'].match(
        /fileName=(.*)/
      )[1];
      return {
        data: {
          file: response.data,
          fileName,
        },
      };
    }

    if (resCode === API_STATUS_CODES.REQUEST_NOT_AUTHORISED) {
      // auth failed
      return Promise.reject({
        code: ERROR_CODES.REQUEST_NOT_AUTHORISED,
        message: response.data.msg || '接口认证失败，请重新登录',
        show: false,
      });
      // return response.data;
    }
  }
  return response.data;
};

// Handle different api failure scenarios
export const apiFailureResponseInterceptor = (error: any) => {
  const statusCode = parseInt(get(error, 'response.status', 200));

  // Return error when there is no internet
  if (!window.navigator.onLine) {
    return Promise.reject({
      ...error,
      message: createMessage(ERROR_0),
    });
  }

  // Return if the call was cancelled via cancel token
  if (axios.isCancel(error)) {
    return error;
  }

  // Return modified response if action execution failed
  if (error.config && error.config.url.match(executeActionRegex)) {
    return makeExecuteActionResponse(error.response);
  }
  // Return error if any timeout happened in other api calls
  if (
    error.code === axiosConnectionAbortedCode &&
    error.message &&
    error.message.match(timeoutErrorRegex)
  ) {
    return Promise.reject({
      ...error,
      message: createMessage(SERVER_API_TIMEOUT_ERROR),
      code: ERROR_CODES.REQUEST_TIMEOUT,
    });
  }

  if (error.response) {
    if (error.response.status === API_STATUS_CODES.SERVER_ERROR) {
      return Promise.reject({
        ...error,
        code: ERROR_CODES.SERVER_ERROR,
        message: createMessage(ERROR_500),
      });
    }

    const responseMeta = get(error, 'response.data.responseMeta', {
      error: {},
    });

    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (!is404orAuthPath()) {
      const currentUrl = `${window.location.href}`;
      if (error.response.status === API_STATUS_CODES.REQUEST_NOT_AUTHORISED) {
        // Redirect to login and set a redirect url.
        const redirectUrl = `${AUTH_LOGIN_URL}?redirectUrl=${encodeURIComponent(
          currentUrl
        )}`;
        try {
          store.dispatch(
            logoutUser({
              redirectURL: redirectUrl,
            })
          );
        } catch (err) {
          // if above dispatch failed, force redirect to login page
          window.location.href = redirectUrl;
        }

        return Promise.reject({
          code: ERROR_CODES.REQUEST_NOT_AUTHORISED,
          message: responseMeta.error.message || createMessage(ERROR_401),
          show: false,
        });
      }
      if (
        responseMeta.status === API_STATUS_CODES.RESOURCE_NOT_FOUND &&
        (responseMeta.error.code === SERVER_ERROR_CODES.RESOURCE_NOT_FOUND ||
          responseMeta.error.code === SERVER_ERROR_CODES.UNABLE_TO_FIND_PAGE)
      ) {
        return Promise.reject({
          code: ERROR_CODES.PAGE_NOT_FOUND,
          message: '请求资源不存在',
          show: false,
        });
      }
    }
    if (responseMeta) {
      return Promise.resolve(error.response.data);
    }
    return Promise.reject(error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    log.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    log.error('Error', error.message);
  }
  log.debug(error.config);
  return Promise.resolve(error);
};
