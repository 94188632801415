import React from 'react';
import { useDrag } from '@use-gesture/react';
import {
  IDimensions,
  IPositionBorder,
  IResizerProps,
  IStyledResizerComp,
  RESIZE_POSITIONS_BORDER,
} from './constants';
import { StyledBorderResizer, StyledCornerResizer } from './styles';
import SvgIcon from 'components/icon';

function Resizer(props: IResizerProps) {
  const { height, width, x, y } = props.dimensions;
  const bind: any = useDrag<any>(
    (state) => {
      const {
        first,
        last,
        dragging,
        offset: [ox, oy],
        delta: [dx, dy],
      } = state;
      if (first) props.onResizeStart && props.onResizeStart();
      if (last) props.onResizeEnd && props.onResizeEnd();

      if (dragging) {
        let dimensions: IDimensions = {};
        switch (props.position) {
          case 'TOP':
            dimensions = { height: height.get() - dy, y: y.get() + dy };
            break;
          case 'RIGHT':
            dimensions = { width: ox };
            break;
          case 'BOTTOM':
            dimensions = { height: oy };
            break;
          case 'LEFT':
            dimensions = { width: width.get() - dx, x: x.get() + dx };
            break;
          case 'TOPRIGHT':
            dimensions = {
              height: height.get() - dy,
              y: y.get() + dy,
              width: ox,
            };
            break;
          case 'BOTTOMRIGHT':
            dimensions = { width: ox, height: oy };
            break;
          case 'BOTTOMLEFT':
            dimensions = {
              width: width.get() - dx,
              x: x.get() + dx,
              height: oy,
            };
            break;
          case 'TOPLEFT':
            dimensions = {
              width: width.get() - dx,
              x: x.get() + dx,
              height: height.get() - dy,
              y: y.get() + dy,
            };
            break;
          default:
            break;
        }
        if (
          width.get() <= 250 &&
          dx >= 0 &&
          ['TOPLEFT', 'LEFT', 'BOTTOMLEFT'].includes(props.position)
        ) {
          dimensions = { ...dimensions, width: 250, x: x.get() };
        }
        if (
          height.get() <= 250 &&
          dy >= 0 &&
          ['TOPLEFT', 'TOP', 'TOPRIGHT'].includes(props.position)
        ) {
          dimensions = { ...dimensions, height: 250, y: y.get() };
        }
        props.onResizing && props.onResizing(dimensions);
      }
    },
    {
      from: () => [width.get(), height.get(), x.get(), y.get()],
    }
  );

  const StyledResizeHandler: IStyledResizerComp = RESIZE_POSITIONS_BORDER.includes(
    props.position as IPositionBorder
  )
    ? StyledBorderResizer
    : StyledCornerResizer;

  return (
    <StyledResizeHandler {...bind()} $position={props.position}>
      {props.position === 'BOTTOMRIGHT' && (
        <SvgIcon
          className="text-[#cfcfcf] ml-[-3px] mt-[-3px]"
          name="resizable"
        />
      )}
    </StyledResizeHandler>
  );
}

export default Resizer;
