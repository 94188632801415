import { Tooltip } from '@arco-design/web-react';
import { IconLoading } from '@arco-design/web-react/icon';
import { runAction } from 'actions/pluginActionActions';
import NoSqlApi from 'api/NosqlAPI';
import { ReduxActionTypes } from 'ce/constants/ReduxActionConstants';
import Icon from 'components/icon';
import { QUERY_EDITOR_FORM_NAME } from 'constants/forms';
import { get } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  getIncomers,
  getOutgoers,
  Handle,
  Position,
  useReactFlow,
  useStoreApi,
} from 'react-flow-renderer';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import NodeMenu from '../NodeMenu';
import { tableNodeMenu } from '../nodeMenuConfig';

function TableNode(props) {
  const {
    data,
    data: { isFristTable, name, nodeProperty: propertyData, setQuery },
    selected,
  } = props;
  const tableId = get(data, 'id') || get(data, 'nodeProperty.id');
  const dispatch = useDispatch();
  const createTempleteQueryId = useSelector(
    (state: AppState) => state.ui.globalSearch.createTempleteQueryId
  );
  // const previewData = useCallback(
  //   () => dispatch(runAction(createTempleteQueryId)),
  //   [dispatch, createTempleteQueryId]
  // );

  const [tableSql, setTableSql] = useState('');
  const [tableData, setTableData] = useState({});
  const [perviewLoading, setPerviewLoading] = useState(false);

  const sql = get(
    useSelector((state) => getFormValues(QUERY_EDITOR_FORM_NAME)(state)),
    'actionConfiguration.body'
  );
  const isRunning = useSelector(
    (state: AppState) =>
      state.ui.queryPane.isRunning[state.ui.globalSearch.createTempleteQueryId]
  );

  useEffect(() => {
    data.previewData = () => {
      dispatch({
        type: ReduxActionTypes.PREVIEW_QUERY_TABLE_DATA,
        payload: {
          actionIdAction: runAction(createTempleteQueryId),
          tableSqlActicon: change(
            QUERY_EDITOR_FORM_NAME,
            'actionConfiguration.body',
            tableSql
          ),
          fxParmas: change(
            QUERY_EDITOR_FORM_NAME,
            'actionConfiguration.body',
            sql
          ),
        },
      });
      setTimeout(() => {
        setPerviewLoading(true);
        dispatch(runAction(createTempleteQueryId));
      }, 500);
    };
    // data.setPerviewLoading = () => {
    //   setTimeout(() => setPerviewLoading(false), 500);
    // };
  }, [tableSql, createTempleteQueryId, sql, data]);

  useEffect(() => {
    if (!isRunning && perviewLoading) {
      setPerviewLoading(false);
    }
  }, [isRunning]);

  const { data: tableMeta, isSuccess } = useQuery(
    props.id,
    () => NoSqlApi.getTablemeta(tableId),
    {
      onSuccess: async (data) => {
        const list = get(data, 'data.data.fields', []).map((item) => {
          const field = {
            field: `${propertyData.id}.${item.name}`,
            alias: item.alias,
            type: item.type,
          };
          return field;
        });
        propertyData.request.current.settings.select = list;
        const settings = JSON.stringify(propertyData.request.current.settings);
        const current = { ...propertyData.request?.current, settings };
        const params = {
          inputs: [],
          current,
          dbType: currentDbType,
        };
        const res = (await NoSqlApi.getPreview(params)) || {};
        setQuery && setQuery(params);
        setTableSql(get(res, 'data.data.templateSQL'));
        setTableData(get(res, 'data.data'));
      },
    }
  );
  const { getState } = useStoreApi();
  const typesData = get(getState(), 'typesData.data.data.data');
  const currentDbType = get(getState(), 'currentDbType');
  const meta = { tableMeta, typesData, tableData };
  data.mate = meta;
  const reactFlowInstance = useReactFlow();
  const {
    position: { x, y },
  } = reactFlowInstance.getNode(props.id);
  const isShowMenu =
    getIncomers(
      reactFlowInstance.getNode(props.id),
      reactFlowInstance.getNodes(),
      reactFlowInstance.getEdges()
    ).length === 0;
  const MenuWrapper = Tooltip;
  return (
    <div className="table-node text-center">
      <MenuWrapper
        content={
          <NodeMenu
            data={{
              ...data,
              x,
              y,
            }}
            meta={meta}
            nodeMenu={
              isShowMenu && !isFristTable
                ? tableNodeMenu
                : tableNodeMenu.slice(-1)
            }
          />
        }
        position="bottom"
        trigger="hover"
      >
        <div
          className={`relative cursor-move w-[56px] h-[56px] bg-white rounded flex justify-center items-center m-auto border-1 border-solid ${
            selected ? 'border-link' : ''
          }`}
        >
          <span className="text-link text-[24px]">
            {perviewLoading ? <IconLoading /> : <Icon name={'table'} />}
          </span>
          <Handle position={Position.Right} type={'target'} />
          <Handle position={Position.Right} type="source" />
        </div>
        <div className="mt-[8px] font-hm-12">{name}</div>
      </MenuWrapper>
    </div>
  );
}

export default TableNode;
