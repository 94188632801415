import React, { ReactNode, useContext, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components';
import Icon, { IconName, IconSize } from './Icon';
import { Classes, CommonComponentProps } from './common';
import { useEffect } from 'react';
import { Indices } from 'constants/Layers';
import { DebuggerContext } from 'components/editorComponents/Debugger/DebuggerTabs';
import SvgIcon from 'components/icon';

export type TabProp = {
  key: string;
  title: string;
  count?: number;
  panelComponent?: JSX.Element;
  icon?: IconName;
  iconSize?: IconSize;
};

const TabsWrapper = styled.div<{
  shouldOverflow?: boolean;
  vertical?: boolean;
  responseViewer?: boolean;
}>`
  border-radius: 0px;
  height: 100%;
  overflow: hidden;
  .react-tabs {
    height: 100%;
  }
  .react-tabs__tab-panel {
    height: calc(100% - 36px);
    overflow: auto;
  }
  .react-tabs__tab-list {
    margin: 0px;
    display: flex;
    flex-direction: ${(props) => (!!props.vertical ? 'column' : 'row')};
    align-items: ${(props) => (!!props.vertical ? 'stretch' : 'center')};
    border-bottom: none;
    color: ${(props) => props.theme.colors.tabs.normal};
    path {
      fill: ${(props) => props.theme.colors.tabs.icon};
    }
    ${(props) =>
      props.shouldOverflow &&
      `
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
    `}

    ${(props) =>
      props.responseViewer &&
      `
      margin-left: 30px;
      display: flex;
      align-items: center;
      height: 24px;
      background-color: ${props.theme.colors.multiSwitch.bg} !important;
      width: fit-content;
      padding-left: 1px;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    `}
  }
  .react-tabs__tab {
    align-items: center;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    border-color: transparent;
    position: relative;
    padding: 0px 3px;
    margin-right: ${(props) =>
      !props.vertical ? `${props.theme.spaces[12] - 3}px` : 0};

    ${(props) =>
      props.responseViewer &&
      `

      `}
  }

  .react-tabs__tab,
  .react-tabs__tab:focus {
    box-shadow: none;
    border: none;
    &:after {
      content: none;
    }

    ${(props) =>
      props.responseViewer &&
      `
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 22px;
        padding: 0 12px;
        border: 1px solid ${props.theme.colors.multiSwitch.border};
        margin-right: -1px;
        margin-left: -1px;
        margin-top: -2px;
        height: 100%;
      `}
  }

  .react-tabs__tab--selected {
    .tab-title {
      color: var(--title-primary);
      font-weight: bold;
    }
    background-color: transparent;
    path {
      fill: var(--title-primary);
    }

    ${(props) =>
      props.responseViewer &&
      `
        background-color: ${props.theme.colors.multiSwitch.selectedBg};
        border: 1px solid ${props.theme.colors.multiSwitch.border};
        border-radius: 0px;
        font-weight: normal;
      `}
  }

  ${(props) =>
    props.responseViewer &&
    `
      padding: 0px;
      margin-top: 10px;
  `}
`;

export const TabTitle = styled.span<{ responseViewer?: boolean }>`
  font-size: 12px;
  /* font-weight: ${(props) => props.theme.typography.h5.fontWeight}; */
  line-height: ${(props) => props.theme.typography.h5.lineHeight - 3}px;
  letter-spacing: ${(props) => props.theme.typography.h5.letterSpacing}px;
  margin: 0;
  display: flex;
  align-items: center;

  ${(props) =>
    props.responseViewer &&
    `
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-transform: uppercase;
    color: ${props.theme.colors.text.normal};
    `}
`;

export const TabCount = styled.div`
  border-radius: 8px;
  min-width: 16px;
  height: 16px;
  font-size: 9px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  background-color: #fcdcca;
  color: #fc5800;
`;

const TabTitleWrapper = styled.div<{
  selected: boolean;
  vertical: boolean;
  responseViewer?: boolean;
}>`
  display: flex;
  width: 100%;
  padding: ${(props) => props.theme.spaces[3] - 1}px
    ${(props) => (props.vertical ? `${props.theme.spaces[4] - 1}px` : 0)}
    ${(props) => props.theme.spaces[4] - 1}px
    ${(props) => (props.vertical ? `${props.theme.spaces[4] - 1}px` : 0)};
  color: var(--content);
  font-weight: 400 !important;
  &:hover {
    color: var(--title-primary);
    .${Classes.ICON} {
      svg {
        fill: var(--title-primary);
        path {
          fill: var(--title-primary);
        }
      }
    }
  }

  ${(props) =>
    props.responseViewer &&
    `
      padding: 0px;
    `}

  .${Classes.ICON} {
    margin-right: ${(props) => props.theme.spaces[1]}px;
    border-radius: 50%;
    svg {
      width: 16px;
      height: 16px;
      margin: auto;
      fill: ${(props) => props.theme.colors.tabs.normal};
      path {
        fill: ${(props) => props.theme.colors.tabs.normal};
      }
    }
  }

  ${(props) =>
    props.selected
      ? `
  background-color: transparent;
  color: ${props.theme.colors.tabs.hover};
  .${Classes.ICON} {
    svg {
      fill: ${props.theme.colors.tabs.icon};
      path {
        fill: ${props.theme.colors.tabs.icon};
      }
    }
  }

  .tab-title {
    font-weight: 700;
    ${
      props.responseViewer &&
      `
        font-weight: normal;
      `
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: ${props.vertical ? `${props.theme.spaces[1] - 2}px` : '100%'};
    bottom: ${props.vertical ? '0%' : `${props.theme.spaces[0] - 1}px`};
    top: ${
      props.vertical ? `${props.theme.spaces[0] - 1}px` : 'calc(100% - 2px)'
    };
    left: ${props.theme.spaces[0]}px;
    height: ${props.vertical ? '100%' : `${props.theme.spaces[1] - 2}px`};
    background-color: ${props.theme.colors.info.main};
    border-radius: 8px;
    z-index: ${Indices.Layer3};

    ${
      props.responseViewer &&
      `
        display: none;
      `
    }
  }
  `
      : ''}
`;

export type TabItemProps = {
  tab: TabProp;
  selected: boolean;
  vertical: boolean;
  responseViewer?: boolean;
  spin?: boolean;
};

function DefaultTabItem(props: TabItemProps) {
  const { responseViewer, selected, spin, tab, vertical } = props;
  return (
    <TabTitleWrapper
      className={`${spin ? 'after:!bg-transparent' : ''}`}
      responseViewer={responseViewer}
      selected={selected}
      style={{ background: spin ? 'var(--color-fill-2)' : null }}
      vertical={vertical}
    >
      {tab.icon ? (
        <Icon
          name={tab.icon}
          size={tab.iconSize ? tab.iconSize : IconSize.XXXL}
        />
      ) : null}
      <TabTitle
        className="tab-title"
        responseViewer={responseViewer}
        style={{ color: spin ? 'var(--color-fill-2)' : null }}
      >
        {tab.title}
      </TabTitle>
      {tab.count && tab.count > 0 ? <TabCount>{tab.count}</TabCount> : null}
    </TabTitleWrapper>
  );
}

export type TabbedViewComponentType = CommonComponentProps & {
  tabs: Array<TabProp>;
  selectedIndex?: number;
  onSelect?: (tabIndex: number) => void;
  overflow?: boolean;
  vertical?: boolean;
  tabItemComponent?: (props: TabItemProps) => JSX.Element;
  responseViewer?: boolean;
  tabRightContent?: ReactNode;
  spin?: boolean;
  skeleton?: JSX.Element;
};

export function TabComponent(props: TabbedViewComponentType) {
  const TabItem = props.tabItemComponent || DefaultTabItem;
  // for setting selected state of an uncontrolled component
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex || 0);

  useEffect(() => {
    if (typeof props.selectedIndex === 'number')
      setSelectedIndex(props.selectedIndex);
  }, [props.selectedIndex]);

  const { bind, dragRef, from } = useContext(DebuggerContext);

  return (
    <TabsWrapper
      data-cy={props.cypressSelector}
      responseViewer={props.responseViewer}
      shouldOverflow={props.overflow}
      vertical={props.vertical}
    >
      <Tabs
        onSelect={(index: number, _, e) => {
          e.stopPropagation();
          props.onSelect && props.onSelect(index);
          setSelectedIndex(index);
        }}
        selectedIndex={props.selectedIndex}
      >
        <div
          className="flex items-center justify-between react-tabs__tab-list-wrap"
          ref={dragRef}
          {...(bind ? bind() : {})}
        >
          <TabList>
            {from === 'DEBUGGER' && (
              <SvgIcon className="text-12 mx-[2px]" name="draghandler" />
            )}
            {props.tabs?.map((tab, index) => (
              <Tab
                data-cy={`t--tab-${tab.key}`}
                data-replay-id={tab.key}
                key={tab.key}
              >
                <TabItem
                  responseViewer={props.responseViewer}
                  selected={
                    index === props.selectedIndex || index === selectedIndex
                  }
                  spin={props.spin}
                  tab={tab}
                  vertical={!!props.vertical}
                />
              </Tab>
            ))}
          </TabList>
          {props.tabRightContent}
        </div>
        {props.tabs.map((tab) => (
          <TabPanel key={tab.key}>
            {props.spin
              ? props.skeleton ?? tab.panelComponent
              : tab.panelComponent}
          </TabPanel>
        ))}
      </Tabs>
    </TabsWrapper>
  );
}
