import styled from 'styled-components';

import enterpriseAuthBg from 'assets/images/enterprise_auth_bg.svg';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
`;

export const MenuContainer = styled.div`
  .arco-menu-inner {
    padding: 4px 0;

    .arco-menu-item {
      padding: 0 12px 0 24px;

      &:hover {
        background-color: var(--menu-bg-selected);
      }
    }

    .arco-menu-selected {
      background-color: var(--menu-bg-selected);
    }
  }
`;

export const EnterpriseAuthContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AuthHeader = styled.div`
  width: 100%;
`;

export const AuthBrand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background: url(${enterpriseAuthBg}) no-repeat;
  background-size: cover;
`;

export const AuthMain = styled.main`
  flex: 1;
  padding: 24px;
`;

export const AuthContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;
`;

export const UploadWrapper = styled.div`
  .arco-upload-list-item-picture,
  .arco-upload-trigger-picture {
    height: 160px;
  }

  .arco-upload-list-item-picture {
    width: 160px;
    border: 1px solid #eee;
  }

  .arco-upload-list-item-picture-mask {
    line-height: 160px;

    .arco-icon {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;

/**
 * User Center
 */
export const UserContainer = styled.div`
  margin: 24px;
  padding: 32px;
  min-height: 494px;
  background-color: #fff;
  border-radius: 16px;
`;

export const ProfileTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #3d3d3d;
`;

export const ProfileWrapper = styled.div`
  margin-top: 44px;
`;

export const ProfileLabel = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #666666;
`;

export const FieldErrorTips = styled.div`
  position: absolute;
  left: 0;
  bottom: -20px;
`;

/**
 * Order management
 */

export const OrderContainer = styled(UserContainer)``;

export const OrderTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #3d3d3d;
`;
