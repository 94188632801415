export const APP_SHARE = () => '分享';
export const APP_PROPERTIES = () => '属性';
export const APP_CONNECT_REPOSITORY = () => '下载';
export const APP_CURRENT_VERSION = () => '预览';
export const APP_PUBLIC_APP = () => '公开此应用';
export const APP_SHAREABLE_APP = () => '获取此应用程序的可共享链接';
export const APP_INVITE_USER = () => '邀请用户到';
export const APP_EDIT_PROFILE = () => '修改个人信息';
export const APP_ORDER = () => '订单记录';
export const APP_SIGN_OUT = () => '退出';
export const APP_PROFILE = () => '个人信息';
export const APP_UNTITLED = () => '未命名应用';
// "Untitled application "
