import React from 'react';
import MarkdownIt from 'markdown-it';
import mdHighlight from 'markdown-it-highlightjs';
import './index.css';
import doMarkdownit from '@digitalocean/do-markdownit';
import Prism from '@digitalocean/do-markdownit/vendor/prismjs';
import prismTools from '@digitalocean/do-markdownit/vendor/prismjs/plugins/toolbar/prism-toolbar';
import prismCopyToClipboard from '@digitalocean/do-markdownit/vendor/prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';

import './index.scss';
import { debounce } from 'lodash';
import { ReactElement } from 'v17/shared/ReactElementType';
// import style manually

export interface ChatMessage {
  role: 'system' | 'user' | 'assistant';
  content: string;
}

interface Props {
  role: ChatMessage['role'];
  message: string;
  menu?: ReactElement;
  isTem?: boolean;
}

// Finish!
const addPlugins = debounce(() => {
  prismTools(Prism);
  prismCopyToClipboard(Prism);
  Prism.highlightAll();
}, 50);

export default function MessageItem({ isTem, menu, message, role }: Props) {
  const htmlString = () => {
    const md = MarkdownIt()
      .use(mdHighlight)
      .use(doMarkdownit, {
        fence_environment: {
          allowedEnvironments: '*',
        },
        fence_classes: {
          allowedClasses: false,
        },
        callout: {
          allowedClasses: ['note', 'warning', 'info', 'draft'],
        },
      });
    if (!isTem) {
      addPlugins();
    }

    if (typeof message === 'function') {
      return md.render(message());
    } else if (typeof message === 'string') {
      return md.render(message);
    }
    return '';
  };
  // role === 'user';
  return (
    <>
      {message && (
        <div
          className={`flex w-[400px] gap-3 p-4 box-border rounded transition-colors mt-[10px] font-hm ${
            role === 'user' ? 'bg-[#9092FF] text-white' : 'bg-[#fff]'
          }`}
        >
          <div
            className="message prose text-slate break-words overflow-hidden"
            dangerouslySetInnerHTML={{
              __html: htmlString(),
            }}
          />
        </div>
      )}
      {menu}
    </>
  );
}
