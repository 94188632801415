import React, { useCallback, useEffect, useRef, useState } from 'react';
import { get, forEach, filter, uniqueId, pick, set } from 'lodash';
import { useDrop } from 'react-dnd';
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  getConnectedEdges,
  MiniMap,
  ReactFlowProvider,
  useReactFlow,
  useStoreApi,
} from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import NoSqlApi from 'api/NosqlAPI';
import tableNode from './customNode/table';

import './index.css';
import { AlterInitObj, NodeTypes } from './constants';
import { AppState } from 'reducers';
import PlaceholderNode from './customNode/Placeholder';
import Operations from './customNode/operation';
import QueryNode from './customNode/query';
import { createPortal } from 'react-dom';
import Tool from './Tool';
import { Message } from '@arco-design/web-react';
import { getNodeIdMap, getNodeMapid, setNodeIdMap } from './nodeMenuConfig';
import styled from 'styled-components';
import { IconLayout, IconLoading } from '@arco-design/web-react/icon';
import { getAction } from 'selectors/entitiesSelector';
import FSGuide from 'components/ads/FSGuide';
import dagre from 'dagre';

export interface NoSqlEditorProps {
  className: string;
  setShowTipNoNode: (flag: boolean) => any;
}

const nodeTypes = {
  tableNode: tableNode,
  placeholderNode: PlaceholderNode,
  operations: Operations,
  query: QueryNode,
};

const step_guide3_1 = {
  selector: '#entity-DatasourceStructure',
  title: '拖动数据表',
  content: <div>将数据表拖入至左侧操作画布</div>,
  placement: 'bottom',
  parent: 'body',
  offset: {
    x: 20,
    y: -10,
  },
};

const step_guide3_2 = {
  selector: '#step-guide3-2',
  title: '运行查看数据',
  content: '数据库表拖入画布后点击运行，即可查看到查询数据',
  placement: 'bottom',
  offset: {
    x: 0,
    y: 0,
  },
};

const steps = [step_guide3_1, step_guide3_2];

function Flow({ nodes = [], setNodes, setEdges, edges, queryId }) {
  const reactFlowInstance = useReactFlow();

  //hanlders
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const { getState } = useStoreApi();
  useEffect(() => {
    NoSqlApi.setNodes(
      queryId,
      JSON.stringify({
        nodes,
        edges,
        store: pick(getState(), 'currentDbType'),
        nodeIdMap: getNodeIdMap(),
      })
    );
  }, [nodes, edges]);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <ReactFlow
      deleteKeyCode={[]}
      // fitView
      edges={edges}
      nodeTypes={nodeTypes}
      nodes={nodes}
      onConnect={onConnect}
      onEdgesChange={onEdgesChange}
      onNodesChange={onNodesChange}
      // defaultNodes={nodes}
    />
  );
}

function NoSqlEditor(props: NoSqlEditorProps) {
  const { className, setShowTipNoNode } = props;

  const queryId = useSelector(
    (state: AppState) => state.ui.globalSearch.createTempleteQueryId
  );

  const actionDBId = get(
    useSelector((state: AppState) => getAction(state, queryId)),
    'datasource.id'
  );

  const typesData = useQuery(
    queryId + 'typesData',
    () => queryId && NoSqlApi.getTableTypes()
  );

  const { data, isSuccess } = useQuery(
    queryId,
    () => queryId && NoSqlApi.getTableList(queryId)
  );
  const { setState } = useStoreApi();
  setState({
    typesData,
  } as any);

  const mapData = {};
  if (isSuccess) {
    forEach(get(data, 'data.data[0].children', []), (v) => {
      mapData[v.name] = v;
    });
  }

  const { data: initData, isSuccess: initGetNodeSuccess } = useQuery(
    queryId + 'getNodes',
    () => queryId && NoSqlApi.getNodes(queryId)
  );

  useEffect(() => {
    if (initData) {
      const initDataObj = JSON.parse(get(initData, 'data.data.nodeData', '[]'));
      setNodes(get(initDataObj, 'nodes', []));
      setEdges(get(initDataObj, 'edges', []));
      setState(get(initDataObj, 'store', []));
      setNodeIdMap(get(initDataObj, 'nodeIdMap', {}));
    }
  }, [initGetNodeSuccess, initData]);

  useEffect(
    () => () => {
      setNodes([]);
      setEdges([]);
    },
    []
  );

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const reactFlowInstance = useReactFlow();
  const ref = useRef(null);
  useEffect(() => {
    const isNoNode =
      !reactFlowInstance.getNodes().length &&
      !reactFlowInstance.getEdges().length;
    setShowTipNoNode(isNoNode);
  }, [nodes, edges]);
  const [collectedProps, drop] = useDrop({
    accept: ['query', 'replace'],
    drop: (
      item: {
        props: Record<string, any>;
        type: 'query' | 'replace';
        id?: string;
      },
      monitor
    ) => {
      const {
        props: { name },
      } = item;
      if (item.props.datasourceId !== actionDBId) {
        return Message.info('数据表所在数据源和action的选择的数据源不一致!');
      }
      const { alias, englishName, externalDatabaseName, id } =
        mapData[name] || {};
      const isAdd = item.type === 'query';
      const isUpdate = item.type === 'replace';
      let hoverClientY, hoverClientX;
      const { id: nodeId } = item;

      if (isAdd) {
        if (reactFlowInstance.getNodes().length > 0) {
          return Message.info('只允许存在一条query');
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const clientOffset = monitor.getSourceClientOffset();
        setState({
          currentDbType: externalDatabaseName,
        } as any);

        // Determine mouse position
        // Get pixels to the top
        hoverClientY = Math.max(clientOffset.y - hoverBoundingRect.top, 0);
        hoverClientX = Math.max(clientOffset.x - hoverBoundingRect.left, 0);
      }

      if (isUpdate) {
        const {
          position: { x, y },
        } = reactFlowInstance.getNode(nodeId);
        hoverClientX = x;
        hoverClientY = y;
      }

      //基本属性
      const uid = uniqueId(`${name}_`);
      const ukey = uniqueId(`key_${name}_`);
      const nodeProperty = {
        id: uid,
        key: ukey,
        type: 'table', // 用于判断在画布渲染的时候是什么组件
        width: 60,
        height: 60,
        name: name,
        alias: alias,
        srcTableId: `${id}`,
        data_name: englishName,
      };
      const { data_name, height, srcTableId, type, width } = nodeProperty;

      //最后的节点属性
      const newInfo = {
        id: nodeProperty.id,
        name,
        alias,
        type,
        width,
        height,
        x: hoverClientX,
        y: hoverClientY,
        srcTableId: srcTableId || id,
        data_name: data_name || name,
        // icon: icon || 'icon-zf-system',
        tableName: name, // 在最开始的表名设置为空
        // iconColor,
        input: [],
        out: [],
        sql: '',
        templateSQL: '',
        fields: [],
        // points: [],
        request: {
          // 用于请求的参数对象
          inputs: [],
          current: {
            id: nodeProperty.id,
            type,
            sql: '',
            templateSQL: '',
            tableName: type === NodeTypes.table ? name : '', // 在最开始的表名设置为空
            srcTableId: type === NodeTypes.table ? srcTableId : '',
            settings: type === NodeTypes.alter ? [AlterInitObj] : {},
          },
        },
      };

      const addNode = {
        ...mapData[name],
        nodeProperty: newInfo,
        dataId: newInfo.id,
        x: hoverClientX,
        y: hoverClientY,
      };

      const dropAddNodeID = addNode.dataId;

      //update
      if (isUpdate) {
        const old = getConnectedEdges(
          [reactFlowInstance.getNode(nodeId)],
          reactFlowInstance.getEdges()
        )[0];
        const operateNode = reactFlowInstance.getNode(old.source);
        // const queryNodeId = operateNode.id + uniqueId('_query');
        if (get(operateNode, 'data.associatedNode')) {
          operateNode.data.associatedNode.push(addNode);
        }
        setNodes([
          ...filter(nodes, (v) => v.id !== nodeId),
          {
            id: dropAddNodeID,
            type: 'tableNode',
            position: { x: addNode.x, y: addNode.y },
            data: addNode,
          },
          // {
          //   id: queryNodeId,
          //   type: 'query',
          //   data: { type: 'query', icon: 'query' },
          //   position: {
          //     x: operateNode.position.x + 100,
          //     y: operateNode.position.y,
          //   },
          // },
        ]);

        const edges = reactFlowInstance.getEdges();
        reactFlowInstance.setEdges([
          ...filter(edges, (v) => v.id !== old.id),
          {
            id: old.id,
            source: old.source,
            sourceHandle: old.sourceHandle,
            target: dropAddNodeID,
            targetHandle: old.targetHandle,
            label: old.label,
          },
          // {
          //   id: operateNode.id + uniqueId('_line'),
          //   source: operateNode.id,
          //   sourceHandle: 'B',
          //   target: queryNodeId,
          //   label: '过滤',
          // },
        ]);

        return;
      }

      // add
      const intersectionNodeId = dropAddNodeID;
      const queryNodeId = getNodeMapid(intersectionNodeId + uniqueId('_query'));
      const position = { x: addNode.x + 150, y: addNode.y };
      setNodes([
        ...nodes,
        {
          id: dropAddNodeID,
          type: 'tableNode',
          position: { x: addNode.x, y: addNode.y },
          data: {
            ...addNode,
            isFristTable: true,
            setQuery: (queryParams) => {
              reactFlowInstance.addNodes({
                id: queryNodeId,
                type: 'query',
                data: {
                  type: 'query',
                  icon: 'query',
                  id: queryNodeId,
                  previousData: addNode,
                  ...position,
                  queryParams,
                },
                position,
              });
              reactFlowInstance.addEdges({
                id: intersectionNodeId + uniqueId('_line'),
                source: intersectionNodeId,
                // sourceHandle: 'B',
                target: queryNodeId,
                label: '结果',
              });
            },
          },
        },
      ]);
    },
  });
  return (
    <div
      className={`nosql-editor ${className}`}
      ref={(el) => {
        ref.current = el;
        drop(el);
      }}
    >
      {
        <Flow
          edges={edges}
          nodes={nodes}
          queryId={queryId}
          setEdges={setEdges}
          setNodes={setNodes}
        />
      }
    </div>
  );
}

const MiniMapWrapper = styled.div`
  width: 0;
  height: 0;
  .react-flow__minimap {
    top: 20px;
    bottom: unset;
  }
  .react-flow__controls {
    top: 25px;
    bottom: unset;
  }
  .react-flow__background {
    height: 100vh !important;
  }
`;

function AutoLayout() {
  const reactFlowInstance = useReactFlow();
  return (
    <div
      className="react-flow__controls-button react-flow__controls-fitview bg-transparent"
      onClick={() => {
        const g = new dagre.graphlib.Graph();
        g.setGraph({
          rankdir: 'LR',
          align: 'UR',
          minlen: 2,
        });
        g.setDefaultEdgeLabel(function () {
          return {};
        });
        const nodeMap = {};
        reactFlowInstance.getNodes().forEach((node) => {
          g.setNode(node.id, node);
          nodeMap[node.id] = node;
        });
        reactFlowInstance.getEdges().forEach((edge) => {
          nodeMap[edge.source].type === 'operations' &&
          edge.sourceHandle === 'A'
            ? g.setEdge(edge.target, edge.source, {
                labelpos: 'c',
              })
            : g.setEdge(edge.source, edge.target, { labelpos: 'c' });
        });
        dagre.layout(g);
        const newNodes = g.nodes().map(function (v) {
          const node = g.node(v);
          return {
            ...node,
            position: {
              x: node.x,
              y: node.type === 'operations' ? node.y - 55 : node.y,
            },
            x: undefined,
            y: undefined,
          };
        });
        reactFlowInstance.setNodes(newNodes);
        requestAnimationFrame(() => {
          reactFlowInstance.fitView();
        });
      }}
    >
      <IconLayout />
    </div>
  );
}

export default function FlowWithProvider(props) {
  const container = useRef(null);
  const [showTipNoNode, setShowTipNoNode] = useState(true);

  const { loading, showGraph } = useSelector(
    (state: AppState) => state.ui.globalSearch
  );

  if (!showGraph) return null;

  if (!container.current) {
    container.current = document.getElementById('flowTool');
  }

  return (
    <ReactFlowProvider>
      <FSGuide
        afterStepChange={(nextIndex, nextStep) => {
          /* do sth */
        }}
        localKey="step-guide3"
        mask={false}
        modalClassName="fs-guide"
        nextText="知道了"
        okText="我知道了"
        onClose={() => {
          /* do sth */
        }}
        prevText="上一步"
        showPreviousBtn
        stepText={(stepIndex, stepCount) => `${stepIndex}/${stepCount}`}
        steps={steps}
      />
      <MiniMapWrapper>
        {showTipNoNode && !loading && (
          <p className="tip-container flex justify-center items-center font-hm-16-bold text-link whitespace-nowrap absolute inset-0 m-auto text-center ">
            将左边的数据表拖进这里!
          </p>
        )}
        <Controls>
          <AutoLayout />
        </Controls>
        <MiniMap
          nodeBorderRadius={2}
          nodeColor={(n): any => {
            if (n.style?.background) return n.style.background;

            return 'var(--link)';
          }}
          nodeStrokeColor={(n): any => {
            if (n.style?.background) return n.style.background;
            if (n.type === 'input') return '#0041d0';
            if (n.type === 'output') return '#ff0072';
            if (n.type === 'default') return '#1a192b';

            return 'var(--link)';
          }}
        />
        <Background />
      </MiniMapWrapper>
      {loading ? (
        <div className=" justify-center mt-[50px] flex h-full items-center w-full">
          <IconLoading />
        </div>
      ) : (
        <NoSqlEditor {...props} setShowTipNoNode={setShowTipNoNode} />
      )}
      {container.current && createPortal(<Tool />, container.current)}
    </ReactFlowProvider>
  );
}
