import React from 'react';

import { ERROR_CODES } from '@appsmith/constants/ApiConstants';
import PageNotFound from 'pages/common/PageNotFound';
import PageError from 'components/error-page';

interface ErrorPageProps {
  code: ERROR_CODES;
}

function ErrorPage(props: ErrorPageProps) {
  const { code } = props;

  switch (code) {
    case ERROR_CODES.PAGE_NOT_FOUND:
      return <PageNotFound />;
    default:
      return <PageError />;
  }
}

export default ErrorPage;
