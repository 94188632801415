export const PLUGIN_NAME_POSTGRES = 'PostgresDbPlugin';
export const PLUGIN_NAME_MONGODB = ' MongoDBPlugin';
export const PLUGIN_NAME_DBS = [PLUGIN_NAME_POSTGRES, PLUGIN_NAME_MONGODB];
export const QUERY_BODY_FIELD = 'actionConfiguration.body';
export const PLUGIN_PACKAGE_POSTGRES = 'postgres-plugin';
export const PLUGIN_PACKAGE_MONGO = 'mongo-plugin';
export const PLUGIN_PACKAGE_DBS = [
  PLUGIN_PACKAGE_POSTGRES,
  PLUGIN_PACKAGE_MONGO,
];

export enum EDITOR_TABS {
  QUERY = 'QUERY',
  SETTINGS = 'SETTINGS',
}
